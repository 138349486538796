// @flow
import * as React from "react";
import { useStoreActions } from "../../../../store/store";
import type { FreezeOrDeleteFormDef } from "./FreezeOrDeleteSection";
import FreezeOrDeleteSection from "./FreezeOrDeleteSection";
import { FormActions, useForm } from "../../../../hooks/useForm";
import { requiresMoreDetails } from "../../../../util/freezeOrDeleteReasons.util";
import * as profileService from "../../../../services/profile.service";
import * as subscriptionService from "../../../../services/subscription.service";
import isEmpty from "lodash/isEmpty";

const FORM_FIELDS: FreezeOrDeleteFormDef = {
  freezeOrDeleteReason: {
    type: "object",
  },
  freezeOrDeleteReasonExtended: {
    type: "string",
  },
};

const FORM_INITIAL = {
  freezeOrDeleteReason: null,
  freezeOrDeleteReasonExtended: "",
};

const FreezeOrDeleteSectionContainer: React.ComponentType<{}> = () => {
  const actions = useStoreActions();
  const form = useForm(FORM_FIELDS, FORM_INITIAL);

  const showExplainDeleteReason = requiresMoreDetails(
    form.state.freezeOrDeleteReason.value?.value
  );
  const activeReason = showExplainDeleteReason
    ? form.state.freezeOrDeleteReasonExtended.value
    : form.state.freezeOrDeleteReason.value?.value;

  const handleDelete = async () => {
    if (FormActions.validate(form)) {
      profileService.requestDeleteCurrentUser({ reason: activeReason });
    }
  };

  const handleFreeze = async () => {
    if (FormActions.validate(form)) {
      subscriptionService.freezeSubscription(actions)({
        frozen: true,
        reason: activeReason,
      });
    }
  };

  return (
    <FreezeOrDeleteSection
      form={form}
      onDelete={handleDelete}
      onFreeze={handleFreeze}
      showExplainDeleteReason={showExplainDeleteReason}
      formDisabled={isEmpty(activeReason)}
    />
  );
};

export default FreezeOrDeleteSectionContainer;
