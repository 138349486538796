// @flow
import * as React from "react";
import { getChannelIcon } from "./channels";
import { getChannel } from "../../../../models/channels";
import type { Slug } from "../../../../types";
import type { Channel } from "../../../../models/channels/channel.model";
import { useTheme } from "@mui/material";
import Sticker from "../Sticker";
import type { StickerProps } from "../Sticker";
import { propMap } from "../../utils/props";
import type { CSSProps } from "../../../../reactTypes";

type ColorVariant = "inherit" | "channel" | "disabled" | "channel-alt" | string;
type SizeVariant = StickerProps["size"];

type Props = {
  channel?: Slug,
  color?: ColorVariant,
  size?: SizeVariant,
  ...CSSProps,
};

const useColors = (color: ColorVariant, channel: ?Channel) => {
  const theme = useTheme();

  switch (color) {
    case "inherit":
      return ["transparent", "inherit"];
    case "disabled":
      return ["transparent", "grey"];
    case "channel":
      return [theme.palette.common.white, channel?.color];
    case "channel-alt":
      return [channel?.color, theme.palette.common.white];
    default:
      return ["transparent", color];
  }
};

const getSize = propMap(
  { xlarge: 42, large: 26, medium: 22, small: 18, xsmall: 16 },
  "medium"
);

const ChannelIcon: React.ComponentType<Props> = React.memo(
  ({ channel, color = "inherit", size, ...props }) => {
    const channelObj = getChannel(channel);
    const [backgroundColor, fColor] = useColors(color, channelObj);
    const Icon = getChannelIcon(channel);
    const iconSize = getSize(size);

    return (
      <Sticker size={size} color={backgroundColor} {...props}>
        <Icon
          htmlColor={fColor}
          style={{ width: iconSize, height: iconSize }}
        />
      </Sticker>
    );
  }
);

export default ChannelIcon;
