// @flow
import * as React from "react";
import type { Props as SettingsSectionProps } from "../../../layout/SettingsSection";
import SettingsSection from "../../../layout/SettingsSection";
import type { Form, StringFormFieldDef } from "../../../../hooks/useForm";
import { FormActions } from "../../../../hooks/useForm";
import {
  ColumnStack,
  ResponsiveInlineRowStack,
} from "../../../lib/layout/stacks";
import FormAvatarInput from "../../../lib/inputs/form/FormAvatarInput";
import FormTextInput from "../../../lib/inputs/form/FormTextInput";
import type { User } from "../../../../models/user.model";
import type { Subscription } from "../../../../models/subscription.model";
import { isSubscriptionActive } from "../../../../models/subscription.model";
import { Alert } from "@mui/material";
import useTranslate from "../../../../hooks/useTranslate";

export type GeneralTabFormDef = {
  avatar: StringFormFieldDef,
  first_name: StringFormFieldDef,
  last_name: StringFormFieldDef,
  email: StringFormFieldDef,
};

type Props = {
  ...Omit<SettingsSectionProps, "title" | "children">,
  form: Form<GeneralTabFormDef>,
  user: ?User,
  subscription: ?Subscription,
};

const readOnlyInputProps = { readOnly: true, disableUnderline: true };

const GeneralSection: React.ComponentType<Props> = ({
  form,
  subscription,
  user,
  ...props
}) => {
  const t = useTranslate();
  const InputProps = !props.editing ? readOnlyInputProps : undefined;

  return (
    <SettingsSection title="global.general" loading={!user} {...props}>
      <ResponsiveInlineRowStack>
        <FormAvatarInput
          disabled={!props.editing}
          formField={form.state.avatar}
          onChange={FormActions.onChangeField(form.set, "avatar")}
          size={96}
          variant="circular"
        />
        <ColumnStack>
          {user?.is_child && (
            <Alert severity="info">
              {t("global.delegateAccountRestrictedView")}
            </Alert>
          )}
          <FormTextInput
            InputProps={InputProps}
            formField={form.state.first_name}
            onChange={FormActions.onChangeField(form.set, "first_name")}
            label={t("global.firstName")}
          />
          <FormTextInput
            InputProps={InputProps}
            formField={form.state.last_name}
            onChange={FormActions.onChangeField(form.set, "last_name")}
            label={t("global.lastName")}
          />
          {isSubscriptionActive(subscription?.status) && (
            <FormTextInput
              InputProps={InputProps}
              formField={form.state.email}
              onChange={FormActions.onChangeField(form.set, "email")}
              label={t("global.email")}
              // sm up, 300 will set the size of all inputs in the stack
              // on xs we want the default full width.
              sx={{ width: { sm: 300 } }}
            />
          )}
        </ColumnStack>
      </ResponsiveInlineRowStack>
    </SettingsSection>
  );
};

export default GeneralSection;
