// @flow
import * as React from "react";
import { useAccountReducer } from "./reducers/account.reducer";
import { usePortfolioReducer } from "./reducers/portfolio.reducer";
import type { Provider } from "../reactTypes";
import type { AccountActions, AccountState } from "./reducers/account.reducer";
import type {
  PortfolioActions,
  PortfolioState,
} from "./reducers/portfolio.reducer";
import { useAnalyticsReducer } from "./reducers/analytics.reducer";
import { useApprovalRequestReducer } from "./reducers/approvalRequest.reducer";
import { useEngagementReducer } from "./reducers/engagement.reducer";
import { useGlobalReducer } from "./reducers/global.reducer";
import { useHashtagGroupReducer } from "./reducers/hashtagGroup.reducer";
import { useIntegrationReducer } from "./reducers/integrations.reducer";
import { useMessagingReducer } from "./reducers/messaging.reducer";
import { useNotificationReducer } from "./reducers/notification.reducer";
import { usePostReducer } from "./reducers/post.reducer";
import { useProfileReducer } from "./reducers/profile.reducer";
import { useSnackReducer } from "./reducers/snacks.reducer";
import { useSubscriptionReducer } from "./reducers/subscription.reducer";
import type { ProfileActions, ProfileState } from "./reducers/profile.reducer";
import type { GlobalActions, GlobalState } from "./reducers/global.reducer";
import type { PostActions, PostState } from "./reducers/post.reducer";
import type {
  AnalyticsActions,
  AnalyticsState,
} from "./reducers/analytics.reducer";
import type {
  NotificationActions,
  NotificationState,
} from "./reducers/notification.reducer";
import type {
  HashtagGroupActions,
  HashtagGroupState,
} from "./reducers/hashtagGroup.reducer";
import type {
  SubscriptionActions,
  SubscriptionState,
} from "./reducers/subscription.reducer";
import type {
  EngagementActions,
  EngagementState,
} from "./reducers/engagement.reducer";
import type {
  MessagingActions,
  MessagingState,
} from "./reducers/messaging.reducer";
import type {
  IntegrationActions,
  IntegrationState,
} from "./reducers/integrations.reducer";
import type { SnackActions, SnackState } from "./reducers/snacks.reducer";
import type {
  ApprovalRequestActions,
  ApprovalRequestState,
} from "./reducers/approvalRequest.reducer";

/**
 * The state part of the store.
 */
export type StoreState = {
  profile: ProfileState,
  global: GlobalState,
  portfolio: PortfolioState,
  account: AccountState,
  post: PostState,
  analytics: AnalyticsState,
  notification: NotificationState,
  hashtagGroup: HashtagGroupState,
  subscription: SubscriptionState,
  engagement: EngagementState,
  approvalRequest: ApprovalRequestState,
  snacks: SnackState,
  messaging: MessagingState,
  integration: IntegrationState,
};

/**
 * The actions part of the store.
 */
export type StoreActions = {
  profile: ProfileActions,
  global: GlobalActions,
  portfolio: PortfolioActions,
  account: AccountActions,
  post: PostActions,
  analytics: AnalyticsActions,
  notification: NotificationActions,
  hashtagGroup: HashtagGroupActions,
  subscription: SubscriptionActions,
  engagement: EngagementActions,
  approvalRequest: ApprovalRequestActions,
  snacks: SnackActions,
  messaging: MessagingActions,
  integration: IntegrationActions,
};

/**
 * The whole store context, state and actions.
 */
type Store = [StoreState, StoreActions];

const StoreContext = React.createContext<?Store>();

/**
 * Store state and actions provider.
 */
const _useStoreState = (): Store => {
  const [account, accountActions] = useAccountReducer();
  const [analytics, analyticsActions] = useAnalyticsReducer();
  const [approvalRequest, approvalRequestActions] = useApprovalRequestReducer();
  const [engagement, engagementActions] = useEngagementReducer();
  const [global, globalActions] = useGlobalReducer();
  const [hashtagGroup, hashtagGroupActions] = useHashtagGroupReducer();
  const [integration, integrationActions] = useIntegrationReducer();
  const [messaging, messagingActions] = useMessagingReducer();
  const [notification, notificationActions] = useNotificationReducer();
  const [portfolio, portfolioActions] = usePortfolioReducer();
  const [post, postActions] = usePostReducer();
  const [profile, profileActions] = useProfileReducer();
  const [snacks, snacksActions] = useSnackReducer();
  const [subscription, subscriptionActions] = useSubscriptionReducer();

  const state = {
    profile,
    global,
    portfolio,
    account,
    post,
    analytics,
    notification,
    hashtagGroup,
    subscription,
    engagement,
    approvalRequest,
    snacks,
    messaging,
    integration,
  };

  const actions = React.useMemo(
    () => ({
      profile: profileActions,
      global: globalActions,
      portfolio: portfolioActions,
      account: accountActions,
      post: postActions,
      analytics: analyticsActions,
      notification: notificationActions,
      hashtagGroup: hashtagGroupActions,
      subscription: subscriptionActions,
      engagement: engagementActions,
      approvalRequest: approvalRequestActions,
      snacks: snacksActions,
      messaging: messagingActions,
      integration: integrationActions,
    }),
    [
      profileActions,
      globalActions,
      portfolioActions,
      accountActions,
      postActions,
      analyticsActions,
      notificationActions,
      hashtagGroupActions,
      subscriptionActions,
      engagementActions,
      approvalRequestActions,
      snacksActions,
      messagingActions,
      integrationActions,
    ]
  );

  return [state, actions];
};

/**
 * The App-wide store provider.
 * There should be one instance of this.
 */
export const StoreProvider: Provider<Store> = ({ children }) => {
  return (
    <StoreContext.Provider value={_useStoreState()}>
      {children}
    </StoreContext.Provider>
  );
};

/**
 * Return the current instance of the store, provided
 * caller is inside the Store provider.
 */
export const useStore = (): Store => {
  const context = React.useContext<?Store>(StoreContext);
  if (!context)
    throw Error("Cannot use Store context outside of the store provider.");
  return context;
};

/**
 * Get the state part of the store.
 */
export const useStoreState = (): StoreState => useStore()[0];
/**
 * Get the actions part of the store.
 */
export const useStoreActions = (): StoreActions => useStore()[1];
