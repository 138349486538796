// @flow

import type { MediaItem } from "../../lib/inputs/MediaInput/useHeadlessMediaInput";
import * as React from "react";
import { getAttachmentSrc } from "../../../models/attachment.model";
import { cast } from "../../../types";
import type { PostAttachment } from "../../../models/post.model";
import { usePostUploadProgresses } from "../../../store/selectors";
import { createTrackUploadLocalAttachment } from "../../../services/post.service";
import { urlToPostAttachment } from "../../../models/post.model";
import { useStoreActions } from "../../../store/store";
import type { Milliseconds } from "../../../types";

const useAttachmentsToMediaItemsAdapter = (
  attachments: PostAttachment[],
  onUpdateAttachments: (PostAttachment[]) => any,
  firstAttachmentVideoOffset?: ?Milliseconds
): ({
  mediaItems: MediaItem[],
  onChangeMedia: (MediaItem[]) => any,
  onMediaAdded: (MediaItem) => MediaItem,
}) => {
  const actions = useStoreActions();
  const uploadProgresses = usePostUploadProgresses();
  const mediaItems: MediaItem[] = React.useMemo(
    () =>
      attachments.map((att, idx) => ({
        src: getAttachmentSrc(att),
        videoOffset: idx === 0 ? firstAttachmentVideoOffset : undefined,
        uploadProgress: att.id ? uploadProgresses[att.id] : undefined,
        tag: att,
      })),
    [firstAttachmentVideoOffset, uploadProgresses, attachments]
  );

  const handleChangeMedia = React.useCallback(
    // Becase handleMediaAdded has added a tag, we just extract it back here.
    (items: MediaItem[]) =>
      onUpdateAttachments(items.map(({ tag }) => cast<PostAttachment>(tag))),
    [onUpdateAttachments]
  );

  const handleMediaAdded = React.useCallback(
    // When a media is added we create a new attachment and add it to
    // the tag property of the media item.
    (mediaItem: MediaItem) => {
      const src = mediaItem.src;
      const tag =
        src instanceof File
          ? // Most probable. pre-upload the file before the owner is committed.
            createTrackUploadLocalAttachment(src, actions)
          : // Just wrap the src in an attachment.
            urlToPostAttachment(src);
      return { ...mediaItem, tag };
    },
    [actions]
  );

  return {
    mediaItems,
    onChangeMedia: handleChangeMedia,
    onMediaAdded: handleMediaAdded,
  };
};

export default useAttachmentsToMediaItemsAdapter;
