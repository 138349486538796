// @flow
import * as React from "react";
import { InlineRowStack, RowStack } from "../../lib/layout/stacks";
import Autocomplete from "../../lib/inputs/Autocomplete";
import useTranslate from "../../../hooks/useTranslate";
import type { TimezoneOption } from "../../../util/timezoneOptions.util";
import {
  getTimezoneFromOption,
  timezoneOptions,
} from "../../../util/timezoneOptions.util";
import {
  usePortfolioAccounts,
  usePostsInitialised,
} from "../../../store/selectors";
import type { Props as CalendarProps } from "./Calendar";
import Calendar from "./Calendar";
import type { Portfolio } from "../../../models/portfolio.model";
import { styled } from "@mui/material/styles";
import PostFilterInputs from "../../inputs/PostFilterInputs";
import { LinearProgress, List, Paper } from "@mui/material";
import useIsMobile from "../../../hooks/useIsMobile";
import MiniPostsCalendarWidget from "../MiniPostsCalendarWidget";
import { useStoreState } from "../../../store/store";
import useFilteredPosts from "../../../hooks/useFilteredPosts";
import PleaseWaitProgress from "../../feedback/PleaseWaitProgress";
import moment from "moment";
import type { Moment } from "../../../types";
import { getPostSnippet, getTime } from "../../../models/post.model";
import { NavigationListItem } from "../../lib/display/listItems";
import PostMediaThumbnail from "../../display/PostMediaThumbnail";
import MultiChannelIcon from "../../lib/display/icons/MultiChannelIcon";
import { NO_XS_HORIZ_MARGIN } from "../../layout/PagePanel";
import useRedirectToEditPost from "../../../hooks/useRedirectToEditPost";
import PostStatusChipAndTimezone from "../../display/PostStatusChipAndTimezone";

type Props = {
  timezone: TimezoneOption,
  onSetTimezone: (TimezoneOption) => any,
  portfolio: Portfolio,
};

const XsPostMediaThumbnail = styled(PostMediaThumbnail)({
  width: 52,
  minHeight: 52,
  flexShrink: 0,
});

const DaysPostsRoot = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  borderTop: `solid 1px ${theme.palette.divider}`,
  padding: theme.spacing(0.5),
}));

const XsCalendar: React.ComponentType<CalendarProps> = ({ portfolio }) => {
  const posts = useFilteredPosts(portfolio.id);
  const [selectedDay, setSelectedDay] = React.useState<Moment>(moment());
  const handleEditPost = useRedirectToEditPost(portfolio.id);
  const storeState = useStoreState();
  const daysPosts = React.useMemo(
    () => posts.filter((p) => !!getTime(p)?.isSame(selectedDay, "day")),
    [posts, selectedDay]
  );

  if (storeState.post.locked || !storeState.post.initialised) {
    return <PleaseWaitProgress />;
  }

  return (
    <>
      <MiniPostsCalendarWidget
        posts={posts}
        view="month"
        onDayClick={setSelectedDay}
        anchorToNow
      />
      {daysPosts.length > 0 && (
        <DaysPostsRoot className={NO_XS_HORIZ_MARGIN}>
          <List dense disablePadding>
            {daysPosts.map((p) => (
              <NavigationListItem
                key={p.id}
                avatar={<XsPostMediaThumbnail post={p} adorned={false} />}
                primary={getPostSnippet(p)}
                secondary={
                  <InlineRowStack>
                    {p.channels && (
                      <MultiChannelIcon
                        channels={p.channels}
                        limit={2}
                        size="xsmall"
                      />
                    )}
                    <PostStatusChipAndTimezone dense post={p} />
                  </InlineRowStack>
                }
                onClick={() => handleEditPost(p.id)}
              />
            ))}
          </List>
        </DaysPostsRoot>
      )}
    </>
  );
};

const PostFilterInputsContainer = styled(RowStack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    "& > *": {
      width: "calc(50% - 4px)",
    },
  },
}));

const CalendarWidget: React.ComponentType<Props> = ({
  timezone,
  onSetTimezone,
  portfolio,
}) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const accounts = usePortfolioAccounts(portfolio.id);
  const initState = usePostsInitialised();
  const CalendarComponent = isMobile ? XsCalendar : Calendar;
  return (
    <>
      <PostFilterInputsContainer>
        <PostFilterInputs
          accounts={accounts}
          size={isMobile ? "small" : undefined}
        />
        {!isMobile && (
          <Autocomplete
            sx={{ width: { xs: undefined, sm: 250, md: 300 } }}
            value={timezone}
            onChange={(e, value) => onSetTimezone(value)}
            optionPrimaryLabel="shortLabel"
            optionSecondaryLabel="label"
            TextFieldProps={{
              label: t("global.timezone"),
            }}
            options={timezoneOptions}
            groupBy={(opt: TimezoneOption) => opt.group}
            disableClearable
          />
        )}
      </PostFilterInputsContainer>
      {initState !== "all" && <LinearProgress />}
      <CalendarComponent
        portfolio={portfolio}
        accounts={accounts}
        timezone={getTimezoneFromOption(timezone)}
      />
    </>
  );
};

export default CalendarWidget;
