// @flow
import * as React from "react";
import EngagementHubConversations from "./EngagementHubConversations";
import { useScopedAccount } from "../../../scopes/scopes";
import type { ModelID } from "../../../types";
import EngagementHubContext from "../../../contexts/engagementHub";
import {
  useAccountConversations,
  useConversationSummaries,
  useMessagingInitialised,
} from "../../../store/selectors";
import { useNavigate, useParams } from "react-router-dom";
import { useStoreActions } from "../../../store/store";
import * as messagingService from "../../../services/messaging.service";
import routes, { getRoutePath } from "../../../config/routes.config";
import { getId, parseModelId } from "../../../models/base.model";
import type { Conversation } from "../../../models/conversation.model";
import type { ConversationSummary } from "../../../models/conversationSummary.model";
import orderBy from "lodash/orderBy";

const filterConversations = (
  conversations: Conversation[],
  summaries: { [ModelID]: ConversationSummary },
  unreadItemsOnly: boolean,
  selectedConversationId: ?ModelID
) => {
  if (unreadItemsOnly) {
    conversations = conversations.filter(
      (conversation) =>
        conversation.id === selectedConversationId ||
        summaries[conversation.id]?.unread_messages > 0
    );
  }
  return orderBy(
    conversations,
    (c) => summaries[c.id]?.updated_at || getId(c),
    "desc"
  );
};

const EngagementHubConversationsContainer: React.ComponentType<{}> = () => {
  const account = useScopedAccount();
  const actions = useStoreActions();
  const navigate = useNavigate();
  const { unreadItemsOnly } = React.useContext(EngagementHubContext);
  const accountConversations = useAccountConversations(account?.id);
  const summaries = useConversationSummaries();
  const { conversationId: rawSelectedConversationId } = useParams();
  const selectedConversationId = parseModelId(rawSelectedConversationId);
  const initialised = useMessagingInitialised();

  const conversations = React.useMemo(
    () =>
      filterConversations(
        accountConversations,
        summaries,
        unreadItemsOnly,
        selectedConversationId
      ),
    [accountConversations, summaries, unreadItemsOnly, selectedConversationId]
  );

  if (!account) return null;

  const handleSelectConversationId = (conversationId: ModelID) => {
    if (conversationId) {
      messagingService.markConversationAsRead(actions)(conversationId);
      navigate(
        getRoutePath(
          routes.app.nested.portfolios.nested.engagement.nested.account.nested
            .conversations.nested.conversation.nested.messages,
          {
            portfolioId: account.portfolio_id,
            accountId: account.id,
            conversationId,
          }
        )
      );
    }
  };

  return (
    <EngagementHubConversations
      conversationsInitialised={initialised}
      conversations={conversations}
      summaries={summaries}
      onSelectConversation={handleSelectConversationId}
      selectedConversationId={selectedConversationId}
    />
  );
};

export default EngagementHubConversationsContainer;
