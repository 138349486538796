// @flow
import * as React from "react";
import LogoIcon from "../lib/display/icons/LogoIcon";
import type { CSSSize } from "../../types";
import { getPixelSize } from "../../util/size.util";
import { withStyleProps } from "../lib/utils/hoc";

type Props = {
  size?: CSSSize,
  bounceIn?: boolean,
  htmlColor?: string,
};

const Logo: React.ComponentType<Props> = withStyleProps<Props>(
  LogoIcon,
  ["size", "htmlColor"],
  ({ theme, size = 32, htmlColor }) => {
    size = getPixelSize(size);
    return {
      width: size,
      height: size,
      color: htmlColor ?? theme.palette.primary.main,
    };
  }
);

export default Logo;
