// @flow
import * as React from "react";
import type {
  CellComponent,
  Column,
  TableSelection,
} from "../../lib/display/DataTable/base";
import type { Post } from "../../../models/post.model";
import { getTime } from "../../../models/post.model";
import { Body2 } from "../../lib/display/Text";
import type { TextProps } from "../../lib/display/Text";
import VirtualDataTable from "../../lib/display/DataTable/VirtualDataTable";
import type { ModelID } from "../../../types";
import { cast } from "../../../types";
import { makeSkeletonTable } from "../../lib/display/DataTable/makeSkeletonTable";
import MultiChannelIcon from "../../lib/display/icons/MultiChannelIcon";
import PostStatusChip from "../../display/PostStatusChip";
import PostDescriptors from "../../display/PostDescriptors";
import { styled } from "@mui/material/styles";
import PostErrorAlert from "../../display/PostErrorAlert";
import PostMediaThumbnail from "../../display/PostMediaThumbnail";
import type {
  TableToolbarActionComponent,
  TableToolbarActionConfig,
} from "../../lib/display/DataTable/TableAction";
import {
  makeDeleteAction,
  makeDuplicateAction,
  makeEditAction,
  TableAction,
} from "../../lib/display/DataTable/TableAction";
import { byId, parseModelId } from "../../../models/base.model";
import { next } from "../../../util/iterable";
import {
  ApprovalRounded,
  CheckRounded,
  RestoreRounded,
} from "@mui/icons-material";
import { ColumnStack, InlineRowStack, RowStack } from "../../lib/layout/stacks";
import PostTitle from "../../display/PostTitle";
import TimezoneCaption from "../../display/TimezoneCaption";
import type {
  PostBreakdown,
  PostMetric,
  PostsBreakdown,
} from "../../../models/analytics.model";
import { isPostMetricRelevant } from "../../../models/analytics.model";
import type { MetricInfo } from "../../../config/metrics.config";
import { POST_METRICS } from "../../../config/metrics.config";
import toPairs from "lodash/toPairs";
import Numeral from "../../lib/display/Numeral";
import PostStatusChipAndTimezone from "../../display/PostStatusChipAndTimezone";
import { DEFAULT_PAPER_SLOT_PROPS } from "../../lib/display/DataTable/base";
import useIsMobile from "../../../hooks/useIsMobile";

const Channels: CellComponent<Post> = React.memo(
  ({ row }) =>
    row.channels && <MultiChannelIcon channels={row.channels} limit={2} />
);

const Media: CellComponent<Post> = React.memo(({ row }) => (
  <PostMediaThumbnail post={row} />
));

export const TextPreview: React.ComponentType<{
  whiteSpace?: "no-wrap",
  ...TextProps,
}> = styled(Body2)(({ theme, whiteSpace }) => ({
  // High enough for two lines.
  maxHeight: 40,
  textOverflow: "ellipsis",
  overflow: "hidden",
  marginTop: theme.spacing(0.5),
  whiteSpace,
}));

const Content: CellComponent<Post> = React.memo(({ row }) => {
  return (
    <>
      <PostDescriptors post={row} />
      <TextPreview color="textSecondary">{row.content}</TextPreview>
      <PostErrorAlert {...row} />
    </>
  );
});

const MiniContent: CellComponent<Post> = React.memo(({ row }) => {
  return (
    <ColumnStack>
      <RowStack>
        <PostTitle title={row.title} />
        <TextPreview color="textSecondary" whiteSpace="no-wrap">
          {row.content}
        </TextPreview>
      </RowStack>
      <RowStack>
        {row.channels && (
          <MultiChannelIcon channels={row.channels} limit={3} size="small" />
        )}
        <PostStatusChip
          status={row.status}
          time={getTime(row)}
          postId={row.id}
          channel={row.channel}
          autopublishDisabled={row.disable_autopublish}
        />
        <TimezoneCaption timezone={row.timezone} />
      </RowStack>
    </ColumnStack>
  );
});

const Status: CellComponent<Post> = React.memo(({ row }) => (
  <PostStatusChipAndTimezone post={row} />
));

const XsPostMediaThumbnail = styled(PostMediaThumbnail)({
  width: 52,
  minHeight: 52,
  flexShrink: 0,
});

export const XsColumn: CellComponent<Post> = React.memo(({ row }) => (
  <ColumnStack spacing={2}>
    <PostDescriptors post={row} />
    <RowStack>
      <XsPostMediaThumbnail post={row} adorned={false} />
      <TextPreview color="textSecondary" whiteSpace="no-wrap">
        {row.content}
      </TextPreview>
    </RowStack>
    <InlineRowStack>
      {row.channels && (
        <MultiChannelIcon channels={row.channels} limit={3} size="xsmall" />
      )}
      <PostStatusChip
        status={row.status}
        dense
        time={getTime(row)}
        postId={row.id}
        channel={row.channel}
        autopublishDisabled={row.disable_autopublish}
      />
      <TimezoneCaption timezone={row.timezone} short />
    </InlineRowStack>
  </ColumnStack>
));

const columns: Column<Post, void>[] = [
  {
    label: "global.channel",
    Component: Channels,
    // Enough for 3 icons.
    headCellProps: { sx: { width: 102 } },
  },
  {
    label: "global.media",
    Component: Media,
    headCellProps: { sx: { width: 90 } },
    bodyCellProps: { sx: { padding: 0 } },
  },
  { label: "global.content", Component: Content },
  {
    label: "global.status",
    Component: Status,
    headCellProps: { sx: { width: 194 } },
  },
];

const xsColumns: Column<Post, void>[] = [
  {
    label: "",
    Component: XsColumn,
    bodyCellProps: { sx: { padding: 1 } },
  },
];

const miniColumns: Column<Post, void>[] = [
  {
    label: "global.media",
    Component: Media,
    headCellProps: { sx: { width: 80 } },
    bodyCellProps: { sx: { padding: 0 } },
  },
  {
    label: "global.content",
    Component: MiniContent,
  },
];

type BreakdownRow = {
  ...Post,
  metrics: PostBreakdown,
};

const getBreakdownColumns = (
  breakdown: PostsBreakdown
): Column<BreakdownRow, void>[] => [
  {
    label: "global.channel",
    Component: Channels,
    headCellProps: { sx: { width: 80 } },
  },
  {
    label: "global.media",
    Component: Media,
    headCellProps: { sx: { width: 90 } },
    bodyCellProps: { sx: { padding: 0 } },
  },
  {
    label: "global.content",
    Component: Content,
    headCellProps: { sx: { width: 500 } },
  },
  ...toPairs(POST_METRICS)
    .filter(([key]) => isPostMetricRelevant(breakdown)(key))
    .map(([key, metricInfo]: [PostMetric, MetricInfo]) => ({
      label: metricInfo.name,
      getData: (row: BreakdownRow) => (
        <Numeral
          display="compact"
          fontWeight="bold"
          variant="body1"
          value={row.metrics[key]}
        />
      ),
      headCellProps: { sx: { width: 120 } },
    })),
];

type MiniProps = {
  rows: Post[],
  loading?: boolean,
  defaultSelection?: Iterable<ModelID>,
  onChangeSelection?: ($ReadOnlySet<ModelID>) => any,
};

type BreakdownProps = {
  posts: Post[],
  breakdown: PostsBreakdown,
  loading?: boolean,
};

type Props = {
  ...MiniProps,
  onDelete: (TableSelection<ModelID>) => any,
  onCancelDelete: (TableSelection<ModelID>) => any,
  onEdit: (ModelID) => any,
  onDuplicate: (ModelID) => any,
  onMarkAsPublished: (ModelID) => any,
  onCreateApprovalRequest: ($ReadOnlySet<ModelID>) => any,
};

const SLOT_PROPS = {
  paper: DEFAULT_PAPER_SLOT_PROPS,
  toolbar: {
    title: "global.posts",
  },
  table: {
    size: "small",
  },
  tableContainer: {
    sx: {
      maxHeight: {
        xs: "calc(100% - 56px)",
        sm: "calc(100% - 64px)",
      },
    },
  },
  tableRow: {
    sx: {
      borderTop: "solid 1px rgba(0, 0, 0, 0.1)",
      borderBottom: "solid 1px rgba(0, 0, 0, 0.1)",
    },
  },
};

const hideIfAnyPublishedOrDeleted =
  (rows: Post[]) =>
  (selection: TableSelection<ModelID>): boolean => {
    for (const postId of selection.selection) {
      const postStatus = rows.find(byId(postId))?.status;
      if (["published", "deleted"].includes(postStatus)) {
        return true;
      }
    }
    return false;
  };

const hideIfAnyNotDeleted =
  (rows: Post[]) =>
  (selection: TableSelection<ModelID>): boolean => {
    for (const postId of selection.selection) {
      const postStatus = rows.find(byId(postId))?.status;
      if (postStatus !== "deleted") {
        return true;
      }
    }
    return false;
  };

const RequestApprovalAction: TableToolbarActionComponent<ModelID> = (props) => (
  <TableAction
    {...props}
    title="global.requestApproval"
    Icon={ApprovalRounded}
  />
);

const MarkAsPublishAction: TableToolbarActionComponent<ModelID> = (props) => (
  <TableAction
    {...props}
    title="global.markAsPublished"
    Icon={CheckRounded}
    single={true}
  />
);

const CancelDeleteAction: TableToolbarActionComponent<ModelID> = (props) => (
  <TableAction
    {...props}
    title="global.cancelDelete"
    Icon={RestoreRounded}
    single={true}
  />
);

const PostsTable: React.ComponentType<Props> = ({
  rows,
  onDelete,
  onEdit,
  onMarkAsPublished,
  onCreateApprovalRequest,
  onCancelDelete,
  onDuplicate,
  ...props
}) => {
  const isMobile = useIsMobile();

  const hideActionIfAnyPublished = React.useMemo(
    () => hideIfAnyPublishedOrDeleted(rows),
    [rows]
  );
  const hideActionIfAnyNotDeleted = React.useMemo(
    () => hideIfAnyNotDeleted(rows),
    [rows]
  );

  const actions: TableToolbarActionConfig<ModelID>[] = React.useMemo(
    () => [
      makeDuplicateAction(onDuplicate),
      {
        key: "cancel-delete",
        onAction: onCancelDelete,
        Component: CancelDeleteAction,
        hideIf: hideActionIfAnyNotDeleted,
      },
      {
        key: "create-approval-request",
        onAction: (selection: TableSelection<ModelID>) =>
          onCreateApprovalRequest(selection.selection),
        Component: RequestApprovalAction,
        hideIf: hideActionIfAnyPublished,
      },
      {
        key: "mark-as-published",
        onAction: (selection: TableSelection<ModelID>) =>
          onMarkAsPublished(next(selection.selection.values())),
        Component: MarkAsPublishAction,
        hideIf: hideActionIfAnyPublished,
      },
      makeEditAction(onEdit, hideActionIfAnyPublished),
      makeDeleteAction(onDelete),
    ],
    [
      onDuplicate,
      onCreateApprovalRequest,
      onEdit,
      onMarkAsPublished,
      onDelete,
      onCancelDelete,
      hideActionIfAnyPublished,
      hideActionIfAnyNotDeleted,
    ]
  );

  return (
    <VirtualDataTable
      rows={rows}
      selectable
      columns={isMobile ? xsColumns : columns}
      actions={actions}
      slotProps={SLOT_PROPS}
      {...props}
    />
  );
};

const MINI_SLOT_PROPS = {
  paper: {
    sx: {
      flexGrow: 1,
      overflow: "hidden",
      width: "100%",
    },
  },
  toolbar: {
    title: "global.posts",
    variant: "dense",
  },
  table: {
    size: "small",
  },
  tableContainer: {
    sx: { minHeight: 350, minWidth: 400 },
  },
  tableRow: {
    sx: {
      borderTop: "solid 1px rgba(0, 0, 0, 0.1)",
      borderBottom: "solid 1px rgba(0, 0, 0, 0.1)",
    },
  },
};

const BREAKDOWN_SLOT_PROPS = {
  paper: {
    sx: { minHeight: 350, overflow: "hidden" },
  },
  table: {
    size: "small",
  },
  tableContainer: {
    sx: { minHeight: 350 },
  },
  tableRow: {
    sx: {
      borderTop: "solid 1px rgba(0, 0, 0, 0.1)",
      borderBottom: "solid 1px rgba(0, 0, 0, 0.1)",
    },
  },
};

export const PostsMiniTable: React.ComponentType<MiniProps> = ({
  rows,
  ...props
}) => {
  const isMobile = useIsMobile();
  return (
    <VirtualDataTable
      rows={rows}
      selectable
      columns={isMobile ? xsColumns : miniColumns}
      slotProps={MINI_SLOT_PROPS}
      {...props}
    />
  );
};

export const SkeletonPostsTable: React.ComponentType<{}> = makeSkeletonTable({
  columns,
  slotProps: SLOT_PROPS,
});

export const PostBreakdownTable: React.ComponentType<BreakdownProps> = ({
  posts,
  breakdown,
  ...props
}) => {
  const rows = React.useMemo(
    () =>
      Object.keys(breakdown).reduce((res: BreakdownRow[], key) => {
        const postId = parseModelId(key) ?? 0;
        const post = cast<Post>(posts.find(byId(postId)));
        res.push({ ...post, metrics: breakdown[postId] });
        return res;
      }, []),
    [posts, breakdown]
  );

  const columns = React.useMemo(
    () => getBreakdownColumns(breakdown),
    [breakdown]
  );

  return (
    <VirtualDataTable
      rows={rows}
      columns={columns}
      slotProps={BREAKDOWN_SLOT_PROPS}
      {...props}
    />
  );
};

export default PostsTable;
