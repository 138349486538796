// @flow
import * as React from "react";
import SelectPlanWidget from "../../../widgets/SelectPlanWidget";
import SubscriptionStateAlert from "../../SubscriptionStateAlert";
import { isIOS } from "../../../../lib/platform.lib";
import type { Callback, ModelID } from "../../../../types";
import { Collapse, Container } from "@mui/material";
import Section from "../../../layout/Section";
import type { Form } from "../../../../hooks/useForm";
import type { BillingInfoFormDef } from "../../../widgets/BillingInfoWidget/BillingInfoWidget";
import BillingInfoWidget from "../../../widgets/BillingInfoWidget/BillingInfoWidget";
import SettingsSection from "../../../layout/SettingsSection";
import FormDialog from "../../../lib/feedback/FormDialog";
import { ColumnStack } from "../../../lib/layout/stacks";
import PaymentDetailsWidget from "../../../widgets/PaymentDetailsWidget/PaymentDetailsWidget";

type Props = {
  open?: boolean,
  isAdd: boolean,
  selectedFlavourId: ?ModelID,
  onChangeSelectedFlavourId: (ModelID) => any,
  billingForm: Form<BillingInfoFormDef>,
  onSubmit: Callback,
};
const SubscriptionActionRequiredDialog: React.ComponentType<Props> = ({
  open,
  isAdd,
  selectedFlavourId,
  onChangeSelectedFlavourId,
  billingForm,
  onSubmit,
}) => (
  <FormDialog
    name="subscription-action-required-dialog"
    fullScreen
    onSubmit={onSubmit}
    open={open}
    PaperComponent={Container}
    PaperProps={{
      maxWidth: "lg",
    }}
    formProps={{
      disabled: !selectedFlavourId,
    }}
  >
    <ColumnStack>
      <SubscriptionStateAlert />
      {!isIOS && isAdd && (
        <Section title="global.plan">
          <SelectPlanWidget
            selectedFlavourId={selectedFlavourId}
            onSelect={(flavour) => onChangeSelectedFlavourId(flavour.id)}
          />
        </Section>
      )}
      <Container>
        {(!isAdd || selectedFlavourId) && (
          <SettingsSection title="global.billing" Component="div">
            <BillingInfoWidget form={billingForm} editing />
          </SettingsSection>
        )}
        {!isIOS && open && (
          <Collapse in={!!selectedFlavourId}>
            <SettingsSection Component="div" headless>
              <PaymentDetailsWidget isAdd={isAdd} />
            </SettingsSection>
          </Collapse>
        )}
      </Container>
    </ColumnStack>
  </FormDialog>
);

export default SubscriptionActionRequiredDialog;
