// @flow
import * as React from "react";
import {
  SettingsSectionHead,
  SettingsSectionRoot,
} from "../../../layout/SettingsSection";
import useTranslate from "../../../../hooks/useTranslate";
import type { Callback } from "../../../../types";
import useConfirmDialog from "../../../lib/feedback/useConfirmDialog";
import type {
  Form,
  ObjectFormFieldDef,
  StringFormFieldDef,
} from "../../../../hooks/useForm";
import { FormActions } from "../../../../hooks/useForm";
import HTMLForm from "../../../lib/inputs/form/HTMLForm";
import FormAutocomplete from "../../../lib/inputs/form/FormAutocomplete";
import freezeOrDeleteReasons from "../../../../util/freezeOrDeleteReasons.util";
import { Collapse } from "@mui/material";
import FormTextInput from "../../../lib/inputs/form/FormTextInput";
import { ColumnStack } from "../../../lib/layout/stacks";
import { Body1 } from "../../../lib/display/Text";
import Translate from "../../../lib/display/Translate";

export type FreezeOrDeleteFormDef = {
  freezeOrDeleteReason: ObjectFormFieldDef,
  freezeOrDeleteReasonExtended: StringFormFieldDef,
};

type Props = {
  onDelete: Callback,
  onFreeze: Callback,
  form: Form<FreezeOrDeleteFormDef>,
  showExplainDeleteReason?: boolean,
  formDisabled?: boolean,
};

const FreezeOrDeleteSection: React.ComponentType<Props> = ({
  onFreeze,
  onDelete,
  form,
  showExplainDeleteReason,
  formDisabled,
}) => {
  const t = useTranslate();
  const [handleDelete, deleteDlg] = useConfirmDialog(
    {
      DialogProps: {
        name: "delete-account",
        title: "Page.SettingsSection.confirmDeleteAccount.title",
        message: "Page.SettingsSection.confirmDeleteAccount.message",
        confirmLabel: "Page.SettingsSection.confirmDeleteAccount.yes",
        cancelLabel: "Page.SettingsSection.confirmDeleteAccount.no",
      },
    },
    onDelete
  );
  const [handleFreeze, freezeDlg] = useConfirmDialog(
    {
      DialogProps: {
        name: "freeze-account",
        title: "Page.SettingsSection.confirmFreezePlan.title",
        message: "Page.SettingsSection.confirmFreezePlan.message",
      },
    },
    onFreeze
  );
  return (
    <>
      {deleteDlg}
      {freezeDlg}
      <SettingsSectionRoot>
        <HTMLForm
          onDelete={handleDelete}
          onSubmit={handleFreeze}
          disabled={formDisabled}
          submitTextId="Page.SettingsSection.freeze"
          deleteTextId="Page.SettingsSection.deleteMyAccount"
        >
          <SettingsSectionHead
            title={t("Page.SettingsSection.freezeDeleteTitle")}
          />
          <Body1 color="textSecondary" paragraph>
            <Translate id="Page.SettingsSection.freezeDeleteIntro" />
          </Body1>
          <ColumnStack>
            <FormAutocomplete
              formField={form.state.freezeOrDeleteReason}
              onChange={FormActions.onChangeField(
                form.set,
                "freezeOrDeleteReason"
              )}
              options={freezeOrDeleteReasons}
              optionPrimaryLabel="label"
              TextFieldProps={{
                label: t("Page.SettingsSection.freezeOrDeleteReasonLabel"),
              }}
              translateLabel
            />
            <Collapse in={showExplainDeleteReason}>
              <FormTextInput
                fullWidth
                label={t(
                  "Page.SettingsSection.freezeOrDeleteReasonExtendedLabel"
                )}
                formField={form.state.freezeOrDeleteReasonExtended}
                onChange={FormActions.onChangeField(
                  form.set,
                  "freezeOrDeleteReasonExtended"
                )}
              />
            </Collapse>
          </ColumnStack>
        </HTMLForm>
      </SettingsSectionRoot>
    </>
  );
};

export default FreezeOrDeleteSection;
