// @flow
import * as React from "react";
import PortfolioWidget from "./PortfolioWidget";
import type { Portfolio } from "../../../models/portfolio.model";
import {
  checkPortfolioDescription,
  checkPortfolioName,
} from "../../../util/validation.util";
import type { PortfolioFormDef } from "./PortfolioWidget";
import { FormActions, useForm } from "../../../hooks/useForm";
import { useStoreActions } from "../../../store/store";
import * as portfolioService from "../../../services/portfolio.service";
import { useNavigate } from "react-router-dom";
import { API as backend } from "../../../services/backend.service";
import { getOptionFromValue } from "../../../util/timezoneOptions.util";
import { difference } from "../../../lib/lodashex.lib";
import { useEffect } from "react";
import routes, {
  getBackToResourceListRelPath,
  getRoutePath,
} from "../../../config/routes.config";

type Props = {
  portfolio: ?Portfolio,
};

const FORM_DEF: PortfolioFormDef = {
  name: {
    type: "string",
    validate: checkPortfolioName,
    maxLength: 50,
    preventOverflow: true,
  },
  avatar: {
    type: "string",
  },
  timezone: { type: "object" },
  description: {
    type: "string",
    maxLength: 300,
    validate: checkPortfolioDescription,
  },
  color: {
    type: "string",
  },
  is_calendar_public: {
    type: "boolean",
  },
};

const PortfolioWidgetContainer: React.ComponentType<Props> = ({
  portfolio,
}) => {
  const isEdit = Boolean(portfolio);
  const navigate = useNavigate();
  const actions = useStoreActions();
  const initial = React.useMemo(
    () => ({
      name: portfolio?.name ?? "",
      avatar: portfolio?.avatar ?? "",
      timezone: getOptionFromValue(portfolio?.timezone),
      description: portfolio?.description ?? "",
      color: portfolio?.color ?? "",
      is_calendar_public: portfolio?.is_calendar_public ?? false,
    }),
    [portfolio]
  );

  const form = useForm<PortfolioFormDef>(FORM_DEF, initial);

  useEffect(() => {
    if (initial) {
      FormActions.reset(form.set, initial);
    }
  }, [form.set, initial]);

  const handleCancel = React.useCallback(() => {
    navigate(isEdit ? "../dashboard" : "..", { relative: "path" });
  }, [navigate, isEdit]);

  const handleConfirm = () => {
    if (FormActions.validate(form)) {
      const formData = { ...FormActions.collect(form.state) };
      if (portfolio) {
        const baseData = {
          ...difference(initial, formData),
          id: portfolio.id,
        };
        return portfolioService
          .editPortfolio(actions)({
            ...baseData,
            timezone: formData.timezone?.value ?? null,
          })
          .then(() => {
            navigate(
              getRoutePath(routes.app.nested.portfolios.nested.dashboard, {
                portfolioId: portfolio.id,
              })
            );
          });
      } else {
        return portfolioService
          .addPortfolio(actions)({
            ...formData,
            timezone: formData.timezone?.value ?? null,
          })
          .then((pf) => {
            navigate(
              getRoutePath(routes.app.nested.portfolios.nested.socialAccounts, {
                portfolioId: pf.id,
              })
            );
          });
      }
    }
  };

  return (
    <PortfolioWidget
      form={form}
      portfolio={portfolio}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onDelete={
        portfolio
          ? () =>
              portfolioService
                .deletePortfolio(actions)(portfolio.id)
                .then(() =>
                  navigate(getBackToResourceListRelPath(true), {
                    relative: "path",
                  })
                )
          : undefined
      }
      onClickICalButton={
        isEdit
          ? () => backend.portfolio.downloadPortfolioIcal(portfolio)
          : undefined
      }
    />
  );
};

export default PortfolioWidgetContainer;
