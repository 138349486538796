// @flow
import {
  IMAGE_RESIZING_FACTOR,
  RECOMMENDED_IMAGE_MAX_HEIGHT,
  RECOMMENDED_IMAGE_MAX_WIDTH,
} from "./files.lib";
import { createElement } from "./html.lib";
import type { DataURI, Pixels, StrColor } from "../types";

export type ImageSrc = Blob | DataURI;
type ImageOrImageSrc = HTMLImageElement | DataURI;
interface CropRectangle {
  x: Pixels;
  y: Pixels;
  width: Pixels;
  height: Pixels;
}

interface ImageSize {
  width: Pixels;
  height: Pixels;
}

interface CreateCanvasProps {
  width: Pixels;
  height: Pixels;
}

/**
 * Create a new Image from a URL.
 * The promise resolves when the image is ready to use.
 */
export const createImageFromSrc = (src: DataURI): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = src;
  });

const createCanvas = (props: Partial<CreateCanvasProps>): HTMLCanvasElement =>
  createElement<HTMLCanvasElement>("canvas", props);

export const createSolidCanvas = (
  width: Pixels,
  height: Pixels,
  color: StrColor
): HTMLCanvasElement => {
  const canvas = createCanvas({ width, height });
  const context = canvas.getContext("2d");
  context.fillStyle = color;
  context.fillRect(0, 0, width, height);
  return canvas;
};

const withTempImage = async <T>(
  fileOrSrc: ImageSrc,
  onReady: (HTMLImageElement) => T | Promise<T>
): Promise<T> => {
  if (typeof fileOrSrc !== "string") {
    const src = URL.createObjectURL(fileOrSrc);
    const result = await onReady(await createImageFromSrc(src));
    URL.revokeObjectURL(src);
    return result;
  }
  return onReady(await createImageFromSrc(fileOrSrc));
};

const ensureImage = async (
  imgOrURL: ImageOrImageSrc
): Promise<HTMLImageElement> =>
  typeof imgOrURL === "string" ? await createImageFromSrc(imgOrURL) : imgOrURL;

const drawRotated = async (
  src: ImageOrImageSrc,
  direction: "left" | "right"
): Promise<DataURI> => {
  const image = await ensureImage(src);
  // noinspection JSSuspiciousNameCombination
  const canvas = createCanvas({
    width: image.height,
    height: image.width,
  });

  // move to the center of the canvas
  // rotate the canvas to the specified degrees
  // since the context is rotated, the image will be rotated also
  const context = canvas.getContext("2d");
  context.translate(canvas.width / 2, canvas.height / 2);
  context.rotate(((direction === "left" ? -90 : 90) * Math.PI) / 180);
  context.drawImage(image, -image.width / 2, -image.height / 2);

  return canvas.toDataURL("image/jpeg");
};

const drawClipped = (
  src: CanvasImageSource,
  sWidth: Pixels,
  sHeight: Pixels,
  context: CanvasRenderingContext2D,
  dx: Pixels,
  dy: Pixels,
  dWidth: Pixels,
  dHeight: Pixels,
  cWidth: Pixels,
  cHeight: Pixels
) => {
  context.drawImage(
    src, // src
    sWidth / 2 - cWidth / 2, // sx
    sHeight / 2 - cHeight / 2, // sy
    cWidth, // sWidth
    cHeight, // sHeight
    dx, // dx
    dy, // dy
    dWidth, // dWidth
    dHeight // dHeight
  );
};

export const drawVideoFrame = async (
  video: HTMLVideoElement,
  width?: Pixels,
  height?: Pixels
): Promise<HTMLImageElement> => {
  width = width ?? video.videoWidth;
  height = height ?? video.videoHeight;

  const canvas = createCanvas({ width, height });
  const context = canvas.getContext("2d");
  context.fillStyle = "#EFEFEF";
  context.fillRect(0, 0, width, height);
  const clipSize = Math.min(video.videoWidth, video.videoHeight);
  drawClipped(
    video,
    video.videoWidth,
    video.videoHeight,
    context,
    0,
    0,
    width,
    height,
    clipSize,
    clipSize
  );
  return await createImageFromSrc(canvas.toDataURL("image/jpeg"));
};

export const cropImage = (
  image: HTMLImageElement,
  crop: CropRectangle
): DataURI => {
  const canvas = createCanvas({
    width: crop.width,
    height: crop.height,
  });
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas
    .getContext("2d")
    .drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

  return canvas.toDataURL("image/jpeg");
};

/**
 * Resizes provided image to new dimensions
 * @param {HTMLImageElement|string} image Can be an image or a data URL.
 * @param {number} width The new width
 * @param {number} height The new height
 */
export const resizeImage = async (
  image: ImageOrImageSrc,
  width: Pixels,
  height: Pixels
): Promise<DataURI> => {
  image = await ensureImage(image);
  const canvas = createCanvas({ width, height });
  const context = canvas.getContext("2d");

  // draw the image
  context.drawImage(
    image, // src
    0, // dx
    0, // dy
    width, // dWidth
    height // dHeight
  );

  return canvas.toDataURL("image/jpeg");
};

/**
 * Rotates provided image to the left
 * @param {File|string|Blob} image Can be a file, a data URL or a blob.
 * @return {Promise<string>} The src of the fixed image.
 */
export const rotateLeft = (image: ImageOrImageSrc): Promise<DataURI> =>
  drawRotated(image, "left");

/**
 * Rotates provided image to the right
 * @param {File|string|Blob} image Can be a file, a data URL or a blob.
 * @return {Promise<string>} The src of the fixed image.
 */
export const rotateRight = (image: ImageOrImageSrc): Promise<DataURI> =>
  drawRotated(image, "right");

/**
 * Obtains dimensions of an image file.
 * @param {File|string} fileOrSrc the file or source to check.
 * @return {Promise<{width: number, height: number}>} A Promise resolved with an object containing width and height.
 */
export const getImageSize = async (fileOrSrc: ImageSrc): Promise<ImageSize> =>
  fileOrSrc
    ? await withTempImage<ImageSize>(fileOrSrc, (img) => ({
        width: img.naturalWidth,
        height: img.naturalHeight,
      }))
    : { width: 0, height: 0 };

/**
 * Extracts the image from a file and ensures it complies with provided maximum
 * dimensions, resizing it if necessary.
 * @param {number} maxWidth Maximum width of the resulting image.
 * @param {number} maxHeight Maximum height of the resulting image.
 * @return {function(*=): Promise<string>}
 */
const fileToNormalizedDataURL =
  (maxWidth: Pixels, maxHeight: Pixels) =>
  async (file: File): Promise<DataURI> =>
    await withTempImage(file, async (img) => {
      let width = img.naturalWidth;
      let height = img.naturalHeight;

      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          // landscape mode
          height = (maxWidth * height) / width;
          width = maxWidth;
        } else {
          // portrait mode
          width = (maxHeight * width) / height;
          height = maxHeight;
        }
      }
      return resizeImage(img, width, height);
    });

export const fileToImageDataURL: (File) => Promise<DataURI> =
  fileToNormalizedDataURL(
    RECOMMENDED_IMAGE_MAX_WIDTH * IMAGE_RESIZING_FACTOR,
    RECOMMENDED_IMAGE_MAX_HEIGHT * IMAGE_RESIZING_FACTOR
  );
