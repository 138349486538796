// @flow
import type { SupportsID } from "./base.model";
import { byProp } from "./base.model";
import type { Amount, CurrencyCode, FindIteratee, URLStr } from "../types";

/**
 * Stands for a version of an add-on for a specific currency.
 *
 * This should be a 1 to 1 binding with a pricing defined within our
 * payment provider.
 */
export type AddOnPricing = SupportsID & {
  /**
   * Whether the pricing is active.
   */
  active: boolean,

  /**
   * The currency code.
   */
  currency: CurrencyCode,

  /**
   * The monthly price, in the currency.
   */
  monthly_price: Amount,
};

/**
 * Stands for an available AddOn to customize a subscription.
 */
export type AddOn = SupportsID & {
  /**
   * Whether the add-on is active.
   */
  active: boolean,

  /**
   * The name of the add-on.
   */
  name: string,

  /**
   * The description of the add-on.
   */
  description: string,

  /**
   * The image URL of the add-on.
   */
  image_url: ?URLStr,

  /**
   * Whether the add-on can be purchased several times.
   */
  unique: boolean,

  /**
   * The name of this add-on in all supported languages.
   */
  localized_names: Record<string, string>,

  /**
   * The description of this add-on in all supported languages.
   */
  localized_descriptions: Record<string, string>,

  /**
   * The pricing in each supported currency.
   */
  pricing: AddOnPricing[],
};

export const pricingByCurrency = (
  currency: CurrencyCode
): FindIteratee<AddOnPricing> => byProp<AddOnPricing>("currency", currency);
