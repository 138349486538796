// @flow
import * as React from "react";
import type { DataURI } from "../../../types";
import FormDialog from "./FormDialog";
import type { Props as FormDialogProps } from "./FormDialog";
import type { MediaItemSrc } from "../inputs/MediaInput/MediaItem";
import CropImageWidget from "../../widgets/CropImageWidget";
import { dataURLToFile } from "../../../lib/files.lib";
import useIsMobile from "../../../hooks/useIsMobile";

type Props = {
  ...Omit<FormDialogProps, "name">,
  src: MediaItemSrc,
  onSubmit: (MediaItemSrc) => any,
};

const srcName = (src: MediaItemSrc) =>
  src instanceof File ? src.name : "cropped-image.jpg";

const CropImageDialog: React.ComponentType<Props> = ({
  src,
  onSubmit,
  ...props
}) => {
  const [croppedImage, setCroppedImage] = React.useState<?DataURI>();
  const isMobile = useIsMobile();

  const handleSubmit = () => {
    onSubmit(croppedImage ? dataURLToFile(croppedImage, srcName(src)) : src);
  };

  return (
    <FormDialog
      {...props}
      onSubmit={handleSubmit}
      maxWidth="md"
      fullScreen={isMobile}
      name="crop-dialog"
      title="CropImageDialog.title"
    >
      <CropImageWidget
        src={src}
        onImageCropped={setCroppedImage}
        slotProps={{
          /*Make the image fullWidth (counter dialog content padding) */
          ...(isMobile && {
            root: { style: { marginLeft: -24, marginRight: -24 } },
            controls: {
              style: { marginLeft: 8, marginRight: 8, marginBottom: 24 },
            },
          }),
        }}
      />
    </FormDialog>
  );
};

export default CropImageDialog;
