// @flow
import type { Palette } from "../models/userPreferences.model";
import keyBy from "lodash/keyBy";
import type { Slug, StringDict } from "../types";

export const BOBCAAT_PALETTE: Palette = {
  name: "Bobcaat (default)",
  slug: "bobcaat",
  palette: {
    primary: { main: "#A39B84", contrastText: "#FFFFFF" },
    secondary: {
      main: "#F2DBBF",
      contrastText: "#542C13",
    },
  },
};
export const CUSTOM_PALETTE: Palette = {
  name: "Custom...",
  slug: "custom",
  palette: {
    primary: { main: "#221d3f" },
    secondary: { main: "#ffffff" },
  },
};
const PALETTE_1: Palette = {
  name: "Autumn glow",
  slug: "autumn_glow",
  palette: {
    primary: { main: "#EADEBD" },
    secondary: { main: "#F2DBBF" },
  },
};
const PALETTE_2: Palette = {
  name: "Feeling peachy",
  slug: "feeling_peachy",
  palette: {
    primary: { main: "#F2DBBF" },
    secondary: { main: "#F7E7CE" },
  },
};
const PALETTE_3: Palette = {
  name: "Golden hour",
  slug: "golden_hour",
  palette: {
    primary: { main: "#F9E1B7" },
    secondary: { main: "#F2DBBF" },
  },
};
const PALETTE_4: Palette = {
  name: "Delicate spring",
  slug: "delicate_spring",
  palette: {
    primary: { main: "#F2E6CE" },
    secondary: { main: "#EADEBD" },
  },
};
const PALETTE_5: Palette = {
  name: "Feeling bold",
  slug: "feeling_bold",
  palette: {
    primary: { main: "#C5BB9A" },
    secondary: { main: "#F2E6CE" },
  },
};

const palettes: Palette[] = [
  BOBCAAT_PALETTE,
  PALETTE_1,
  PALETTE_2,
  PALETTE_3,
  PALETTE_4,
  PALETTE_5,
  CUSTOM_PALETTE,
];

const PALETTES_BY_SLUG: StringDict<Palette> = keyBy(palettes, "slug");

export const getPaletteBySlug = (slug: Slug): ?Palette =>
  PALETTES_BY_SLUG[slug];

export const getPaletteName = (palette: Palette): string => palette.name;

export const getPaletteValue = (palette: Palette): string => palette.slug;

export default palettes;
