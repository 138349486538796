// @flow
import * as React from "react";
import moment from "moment";
import type { AttachmentSrc } from "../../../models/attachment.model";
import type { Callback, Milliseconds } from "../../../types";
import FormDialog from "../../lib/feedback/FormDialog";
import useTranslate from "../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import Video from "../../lib/display/Video";
import { LinearProgress, Slider } from "@mui/material";

type Props = {
  video: AttachmentSrc,
  videoDuration: Milliseconds,
  onClose: Callback,
  offset: Milliseconds,
  onChangeOffset: (Milliseconds) => any,
  onValidate: Callback,
  videoRef: React.Ref<any>,
  onLoadedMetadata: (SyntheticEvent<HTMLVideoElement>) => any,
};

const formatOffset = (milliseconds: number): string => {
  const duration = moment.duration(milliseconds, "milliseconds");
  return `${duration.minutes().toString().padStart(2, "0")}:${duration
    .seconds()
    .toString()
    .padStart(2, "0")}`;
};

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(4),
}));

const VideoThumbnailPickerDialog: React.ComponentType<Props> = ({
  video,
  videoDuration,
  onClose,
  offset,
  onChangeOffset,
  onValidate,
  videoRef,
  onLoadedMetadata,
}) => {
  const t = useTranslate();
  const marks = [
    { value: 0, label: formatOffset(0) },
    { value: videoDuration, label: formatOffset(videoDuration) },
  ];
  return (
    <FormDialog
      name="video-thumbnail-picker"
      title={t("VideoThumbnailPickerDialog.title")}
      onSubmit={onValidate}
      onClose={onClose}
    >
      <Root>
        <Video
          onLoadedMetadata={onLoadedMetadata}
          ref={videoRef}
          src={video}
          height={350}
        />
      </Root>
      {isNaN(videoDuration) || videoDuration === 0 ? (
        <LinearProgress />
      ) : (
        <Slider
          value={offset}
          marks={marks}
          step={1000}
          color="secondary"
          valueLabelDisplay="auto"
          valueLabelFormat={formatOffset}
          onChange={(e, value) => onChangeOffset(value)}
          max={videoDuration}
        />
      )}
    </FormDialog>
  );
};

export default VideoThumbnailPickerDialog;
