// @flow
/**
 * Encapsulation of session storage.
 * Helpers around token management.
 */
const USER_TOKEN_KEY = "appToken";
const VISITOR_TOKEN_KEY = "visitorToken";

export type TokenManager = {
  isValid: () => boolean,
  get: () => ?string,
  set: (token: string) => void,
  unset: () => void,
  /** The Window event triggered when the managed token in set. */
  setEvent: string,
  /** The Window event triggered when the managed token in unset. */
  unsetEvent: string,
};

const makeTokenManager = (
  key: string,
  setEvent: string,
  unsetEvent: string
): TokenManager => ({
  isValid: () => key in localStorage,
  get: () => localStorage.getItem(key),
  set: (token: string) => {
    localStorage.setItem(key, token);
    window.dispatchEvent(new Event((setEvent: string)));
  },
  unset: () => {
    localStorage.removeItem(key);
    window.dispatchEvent(new Event((unsetEvent: string)));
  },
  setEvent,
  unsetEvent,
});

export const UserToken: TokenManager = makeTokenManager(
  USER_TOKEN_KEY,
  "usertokenchanged",
  "usertokendeleted"
);

export const VisitorToken: TokenManager = makeTokenManager(
  VISITOR_TOKEN_KEY,
  "visitortokenchanged",
  "visitortokendeleted"
);
export const hasAnyValidToken = (): boolean =>
  UserToken.isValid() || VisitorToken.isValid();

export const getAvailableToken = (): ?string =>
  UserToken.get() ?? VisitorToken.get();
