// @flow
import ajax from "../../lib/ajax.lib";
import type { ModelID } from "../../types";
import type {
  APINotification,
  Notification,
} from "../../models/notification.model";
import { fromAPIResult } from "../../models/notification.model";
import type { APINoContentResult, APIResult, Pagination } from "./types";

export const deleteNotification = (
  notificationId: ModelID
): Promise<APINoContentResult> =>
  ajax.delete<APINoContentResult>(`/notification/${notificationId}`);

export const deleteAllNotifications = (): Promise<APINoContentResult> =>
  ajax.delete<APINoContentResult>(`/notification`);

export const getNotifications = (params: Pagination): Promise<Notification[]> =>
  ajax
    .get<APIResult<APINotification[]>>(
      `/notification?min_id=0&page=${params.page ?? 1}&pagesize=${
        params.pagesize ?? 30
      }`
    )
    .then((resp) => resp.data.data.map(fromAPIResult));

export const markAllNotificationsAsRead = (): Promise<APINoContentResult> =>
  ajax.post<void, APINoContentResult>(`/notification/read`);

export const markNotificationAsRead = (
  notificationId: ModelID
): Promise<APINoContentResult> =>
  ajax.post<void, APINoContentResult>(`/notification/${notificationId}/read`);

export const markNotificationAsUnread = (
  notificationId: ModelID
): Promise<APINoContentResult> =>
  ajax.post<void, APINoContentResult>(`/notification/${notificationId}/unread`);
