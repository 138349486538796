// @flow
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar as CalendarImpl } from "react-big-calendar";
import { styled } from "@mui/material/styles";

const DragAndDropCalendar: typeof CalendarImpl = styled(
  withDragAndDrop(CalendarImpl)
)(({ theme }) => ({
  minHeight: 500,
  height: "auto",
  width: "100%",
  flexGrow: 1,
  fontFamily: theme.typography.fontFamily,
  "& .rbc-toolbar": {
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    marginBottom: "0",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },

    "& button": {
      color: theme.palette.text.primary,
      cursor: "pointer",
      margin: "0",
      display: "inline-flex",
      outline: "none",
      position: "relative",
      alignItems: "center",
      userSelect: "none",
      verticalAlign: "middle",
      MozAppearance: "none",
      justifyContent: "center",
      textDecoration: "none",
      backgroundColor: "transparent",
      WebkitAppearance: "none",
      WebkitTapHighlightColor: "transparent",
      fontSize: "0.875rem",
      minWidth: "64px",
      boxSizing: "border-box",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      fontFamily: '"Raleway",  sans-serif',
      fontWeight: "bold",
      lineHeight: "1.75",
      borderRadius: "4px",
      borderWidth: 0,
      borderColor: theme.palette.divider,
      padding: "5px 16px",
      opacity: "0.8",
      "&:active": {
        backgroundColor: "transparent",
        boxShadow: "none",
        borderColor: theme.palette.divider,
      },
      "&:focus": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:hover, &:active:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.rbc-active, &.rbc-active:focus, &.rbc-active:hover": {
        boxShadow: "none",
        opacity: "1",
        borderColor: theme.palette.divider,
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      },
    },
  },

  /* All views */
  "& .rbc-overlay": {
    backgroundColor: theme.palette.background.default,
  },
  "& .rbc-toolbar-label": {
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      order: -1,
      width: "100%",
    },
  },
  "& .rbc-button-group": {
    [theme.breakpoints.down("xs")]: {
      margin: 4,
      display: "inline-block",
      "& button": {
        fontSize: "10pt !important",
      },
    },
  },
  "& .rbc-today": {
    backgroundColor: "initial",
  },
  "& .rbc-date-cell": {
    "& button": {
      display: "inline-block",
      width: "22px",
      height: "22px",
      textAlign: "center",
      margin: "1px",
    },
    "&.rbc-now button": {
      borderRadius: "32px",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  "& .rbc-current-time-indicator": {
    height: "2px",
    backgroundColor: theme.palette.secondary.main,
  },
  "& .rbc-event": {
    borderRadius: "3px",
    padding: "0",
    "&:active, &:focus": {
      outline: "none",
    },
  },
  "& .rbc-event-icon": {
    height: "20px !important",
    width: "20px !important",
    margin: "1px",
  },
  "& .rbc-show-more": {
    color: theme.palette.secondary.main,
    marginLeft: "2px",
  },

  /* Month view */
  "& .rbc-off-range-bg": {
    backgroundColor: theme.palette.background.default,
  },
  "& .rbc-month-header": {
    border: "none",
  },
  "& .rbc-month-view": {
    border: "none",
    height: "auto",
    "& *": {
      borderColor: theme.palette.divider,
    },
  },
  "& .rbc-month-row": {
    backgroundColor: theme.palette.background.paper,
    borderLeft: `solid 1px ${theme.palette.divider}`,
    borderRight: `solid 1px ${theme.palette.divider}`,
    "&:nth-child(2)": {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      borderTop: `solid 1px ${theme.palette.divider}`,
    },
    "&:last-child": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },

  /* Week view */
  "& .rbc-time-view": {
    border: "none",
    "& *": {
      borderColor: theme.palette.divider,
    },
  },
  "& .rbc-time-header-content": {
    border: "none",
    display: "initial",
  },
  "& .rbc-time-content": {
    border: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
  },
  "& .rbc-event-label": {
    paddingLeft: 4,
  },
  "& .rbc-day-slot": {
    "& .rbc-event": {
      border: `solid 1px ${theme.palette.divider}`,
    },
    "& .rbc-event-icon": {
      height: "18px !important",
      width: "18px !important",
    },
  },

  /* day view */
  "& .rbc-time-slot": {
    textAlign: "right",
    paddingTop: "4px",
  },
  "& .rbc-allday-cell": {
    display: "none",
  },

  /* agenda view */
  "& .rbc-agenda-view": {
    "& table.rbc-agenda-table": {
      "& tbody > tr > td": {
        paddingRight: "5px",
      },
      "& thead > tr > th": {
        padding: theme.spacing(1),
      },
    },
    "& .rbc-event-icon": {
      height: "20px !important",
      width: "20px !important",
    },
  },

  /* View headers */
  "& .rbc-header": {
    borderLeft: "none !important",
    borderRight: "none !important",
    borderBottom: "none !important",
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    fontWeight: "bold",
    "&.rbc-today": {
      color: theme.palette.secondary.main,
    },
  },
  "& .rbc-agenda-empty": {
    display: "block",
    width: "100%",
    paddingTop: "100px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "14pt",
    color: theme.palette.secondary.main,
  },

  /* Drag and drop */
  "& .rbc-addons-dnd": {
    "& .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon": {
      borderWidth: 0,
    },
    "& .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon": {
      borderWidth: 0,
    },
  },
}));

export default DragAndDropCalendar;
