import { loginOAuth2 } from "./oauth.service";
import * as env from "../../config/env.config";

const SIGN_IN_URL =
  "https://accounts.google.com/o/oauth2/v2/auth?" +
  "response_type=code&" +
  "access_type=offline&" +
  // Could be "select_account" but in that case we don't get a refresh token again.
  "prompt=consent&" +
  `client_id=${env.GOOGLE_CLIENT_ID}&` +
  `scope=${encodeURIComponent(env.GMB_PERMISSIONS)}&` +
  `redirect_uri=${env.API_URL}${env.GMB_REDIRECT_ENDPOINT}&` +
  `state=${env.GMB_STATE}`;

const POPUP_FEATURES =
  "height=800,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no," +
  "location=no,directories=no,status=yes";

export const login = () => loginOAuth2(SIGN_IN_URL, POPUP_FEATURES);

export const wasLoginCancelled = (e) =>
  ["access_denied", "popup_closed_by_user"].includes(e.error);
