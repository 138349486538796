// @flow
import * as React from "react";
import {
  Alert,
  Collapse,
  FormHelperText,
  InputBase,
  Paper,
  SvgIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { Props as TextFieldProps } from "../../../stubs/mui/TextField";
import { InlineRowStack } from "../../lib/layout/stacks";
import { SmallTextButton } from "../../lib/inputs/buttons";
import {
  CommentRounded,
  GifRounded,
  PhotoRounded,
  PollRounded,
  ReplyAllRounded,
  SentimentSatisfiedRounded,
} from "@mui/icons-material";
import { Caption } from "../../lib/display/Text";
import type { I18nKey } from "../../../types";
import type { SyntheticEventListener } from "../../../reactTypes";
import Translate from "../../lib/display/Translate";
import EmojiPicker from "../../lib/inputs/pickers/EmojiPicker";
import HashtagGroupPicker from "../HashtagGroupPicker";
import Hashtag from "../../lib/display/icons/Hashtag";
import useHeadlessMediaInput from "../../lib/inputs/MediaInput/useHeadlessMediaInput";
import type { Props as BaseMediaInputProps } from "../../lib/inputs/MediaInput/useHeadlessMediaInput";
import type { ValidationError } from "../../../util/validation.util";
import FilePicker from "../../lib/inputs/pickers/FilePicker";
import noop from "lodash/noop";
import FileDropContainer from "../../lib/inputs/FileDropContainer";
import useTranslate from "../../../hooks/useTranslate";
import useIsMobile from "../../../hooks/useIsMobile";
import omit from "lodash/omit";

type MediaInputProps = Omit<BaseMediaInputProps, "error" | "onError">;

export type Props = {
  fieldProps?: ?Omit<TextFieldProps, "variant">,
  mediaInputProps?: MediaInputProps,
  onInsertEmoji?: (content: string) => any,
  onInsertHashtagGroup?: (content: string) => any,
  onAddPoll?: ?SyntheticEventListener,
  onAddThread?: ?SyntheticEventListener,
  onAddFirstComment?: ?SyntheticEventListener,
  onInsertGIF?: ?SyntheticEventListener,
};

const Root = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.input,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  padding: theme.spacing(1),
}));

const ContentActionButtonRoot = styled(SmallTextButton)({
  flexDirection: "column",
  gap: 4,
});

type ContentActionButtonProp = {
  label: I18nKey,
  Icon: typeof SvgIcon,
  onClick?: ?SyntheticEventListener,
  disabled?: boolean,
};

const ContentActionButton: React.ComponentType<ContentActionButtonProp> =
  React.forwardRef(({ label, Icon, ...props }, ref) => (
    <ContentActionButtonRoot {...props} ref={ref}>
      <Icon />
      <Caption>
        <Translate id={label} />
      </Caption>
    </ContentActionButtonRoot>
  ));

const Missing_MEDIA_INPUT_PROPS: MediaInputProps = {
  disabled: true,
  items: [],
  // Note that onChange: noop means hide the attachments button.
  onChange: noop,
};

const RichContentInput: React.ComponentType<Props> = ({
  fieldProps,
  mediaInputProps = Missing_MEDIA_INPUT_PROPS,
  onInsertEmoji,
  onInsertGIF,
  onInsertHashtagGroup,
  onAddPoll,
  onAddThread,
  onAddFirstComment,
}) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const inputElRef = React.useRef<?HTMLInputElement>();
  const [mediaError, setMediaError] = React.useState<?ValidationError>(null);
  const { onSelect, fileDropContainerProps, validatorProps } =
    useHeadlessMediaInput({
      error: !!mediaError,
      onError: setMediaError,
      ...mediaInputProps,
    });

  return (
    <>
      <Root>
        {fieldProps && (
          <FileDropContainer {...fileDropContainerProps}>
            <InputBase
              {...omit(fieldProps, ["error", "helperText"])}
              fullWidth
              inputRef={inputElRef}
            />
            <FormHelperText error={fieldProps.error}>
              {fieldProps.helperText || " "}
            </FormHelperText>
          </FileDropContainer>
        )}
        <InlineRowStack>
          {mediaInputProps.onChange !== noop && (
            <FilePicker
              {...validatorProps}
              onError={setMediaError}
              onSelect={onSelect}
              disabled={mediaInputProps.disabled}
            >
              <ContentActionButton
                Icon={PhotoRounded}
                label="global.media"
                disabled={mediaInputProps.disabled}
              />
            </FilePicker>
          )}
          {onInsertGIF && (
            <ContentActionButton
              Icon={GifRounded}
              label="global.gif"
              onClick={onInsertGIF}
            />
          )}
          {!isMobile && onInsertEmoji && (
            <EmojiPicker
              inputEl={inputElRef.current}
              onChangeInputValue={onInsertEmoji}
              popperProps={{
                placement: "bottom-start",
              }}
            >
              <ContentActionButton
                Icon={SentimentSatisfiedRounded}
                label="global.emoji"
              />
            </EmojiPicker>
          )}
          {onInsertHashtagGroup && (
            <HashtagGroupPicker
              inputEl={inputElRef.current}
              onChangeInputValue={onInsertHashtagGroup}
            >
              <ContentActionButton Icon={Hashtag} label="global.hashtags" />
            </HashtagGroupPicker>
          )}
          <Collapse orientation="horizontal" in={!!onAddPoll}>
            <ContentActionButton
              Icon={PollRounded}
              label="global.poll"
              onClick={onAddPoll}
            />
          </Collapse>
          <Collapse orientation="horizontal" in={!!onAddThread}>
            <ContentActionButton
              Icon={ReplyAllRounded}
              label="global.thread"
              onClick={onAddThread}
            />
          </Collapse>
          <Collapse orientation="horizontal" in={!!onAddFirstComment}>
            <ContentActionButton
              Icon={CommentRounded}
              label="global.comment"
              onClick={onAddFirstComment}
            />
          </Collapse>
        </InlineRowStack>
      </Root>
      <Collapse in={!!mediaError} unmountOnExit>
        <Alert severity="warning" onClose={() => setMediaError(null)}>
          {mediaError && t(mediaError.error, mediaError.data)}
        </Alert>
      </Collapse>
    </>
  );
};

export default RichContentInput;
