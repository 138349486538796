// @flow
import * as React from "react";
import type { Post, SelfReply } from "../../models/post.model";
import AttachmentPreview from "./AttachmentPreview";
import {
  Filter1Rounded,
  Filter2Rounded,
  Filter3Rounded,
  Filter4Rounded,
  Filter5Rounded,
  Filter6Rounded,
  Filter7Rounded,
  Filter8Rounded,
  Filter9PlusRounded,
  Filter9Rounded,
  FilterRounded,
  ImageRounded,
  LinkRounded,
  NoPhotographyRounded,
  PictureAsPdfRounded,
  VideocamRounded,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Box, Tooltip } from "@mui/material";
import Translate from "../lib/display/Translate";
import InsetAdornment from "../lib/layout/InsetAdornment";
import {
  isAttachmentImage,
  isAttachmentPDF,
  isAttachmentVideo,
} from "../../models/attachment.model";
import IconInSticker from "../lib/display/IconInSticker";
import LinkPreview from "../lib/display/LinkPreview";
import type { CSSProps } from "../../reactTypes";

type Props = {
  post: Post | SelfReply,
  adorned?: boolean,
  ...CSSProps,
};

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  color: theme.palette.divider,
}));

const FILTER_ICONS = [
  FilterRounded,
  Filter1Rounded,
  Filter2Rounded,
  Filter3Rounded,
  Filter4Rounded,
  Filter5Rounded,
  Filter6Rounded,
  Filter7Rounded,
  Filter8Rounded,
  Filter9Rounded,
];

const getAttachmentsIcon = (attachments: Post["attachments"]) => {
  switch (attachments.length) {
    case 0:
      return null;
    case 1:
      if (isAttachmentImage(attachments[0])) {
        return ImageRounded;
      } else if (isAttachmentVideo(attachments[0])) {
        return VideocamRounded;
      } else if (isAttachmentPDF(attachments[0])) {
        return PictureAsPdfRounded;
      } else {
        return Filter1Rounded;
      }
    default:
      return FILTER_ICONS[attachments.length] ?? Filter9PlusRounded;
  }
};

const ZoomHoverIconInSticker = styled(IconInSticker)({
  transition: "transform ease 0.3s",
  "&:hover": {
    transform: "scale(1.5)",
  },
});

const AttachmentsAdornment = ({ attachments }: Pick<Post, "attachments">) => {
  const Icon = getAttachmentsIcon(attachments);
  return Icon ? (
    <InsetAdornment>
      <ZoomHoverIconInSticker Icon={Icon} size="small" />
    </InsetAdornment>
  ) : null;
};

const PostMediaThumbnail: React.ComponentType<Props> = ({
  post,
  adorned = true,
  ...props
}) => {
  if (!!post.attachments?.length) {
    return (
      <Root {...props}>
        {adorned && <AttachmentsAdornment attachments={post.attachments} />}
        <AttachmentPreview
          videoOffset={post.thumbnail_video_offset}
          attachment={post.attachments[0]}
        />
      </Root>
    );
  }
  if (post.link)
    return (
      <Tooltip title={post.link}>
        <Root {...props}>
          {adorned && (
            <InsetAdornment>
              <ZoomHoverIconInSticker Icon={LinkRounded} size="small" />
            </InsetAdornment>
          )}

          <LinkPreview
            variant="image"
            link={post.link}
            preview={
              post.thumbnail_url
                ? {
                    image: post.thumbnail_url,
                    title: post.thumbnail_title,
                  }
                : undefined
            }
          />
        </Root>
      </Tooltip>
    );

  return (
    <Root {...props}>
      <Box padding={1} alignSelf="stretch">
        <Tooltip title={<Translate id="global.noMedia" />}>
          <NoPhotographyRounded fontSize="medium" color="default" />
        </Tooltip>
      </Box>
    </Root>
  );
};

export const ListAvatarPostMediaThumbnail: typeof PostMediaThumbnail = styled(
  PostMediaThumbnail
)({
  width: 48,
  maxHeight: 48,
  minHeight: 48,
  borderRadius: 3,
  overflow: "hidden",
});

export default PostMediaThumbnail;
