// @flow
import * as React from "react";
import WidgetHead from "../../inputs/WidgetHead";
import type { Callback } from "../../../types";
import useTranslate from "../../../hooks/useTranslate";
import { Alert, List } from "@mui/material";
import {
  CircularSkeletonListItem,
  NavigationListItem,
} from "../../lib/display/listItems";
import type { ApprovalRequest } from "../../../models/approvalRequest.model";
import Pagination from "../../display/Pagination";
import ApprovalRequestStatusPie from "../../display/ApprovalRequestStatusPie";

type Props = {
  page: number,
  pages: number,
  approvalRequests: ApprovalRequest[],
  onPreviousPage: ?Callback,
  onNextPage: ?Callback,
  onSelect: (ApprovalRequest) => any,
  totalCount: number,
  locked?: boolean,
};

const MiniApprovalRequestsWidget: React.ComponentType<Props> = ({
  page,
  pages,
  approvalRequests,
  onPreviousPage,
  onNextPage,
  onSelect,
  totalCount,
  locked,
}) => {
  const t = useTranslate();
  return (
    <div>
      <WidgetHead
        primary={t("global.approvals")}
        secondary={t("global.approvalsToComplete", { count: totalCount })}
        paginated={{
          onPreviousPage,
          onNextPage,
        }}
      />
      {!locked && approvalRequests.length === 0 ? (
        <Alert severity="info">{t("global.noResultFound")}</Alert>
      ) : (
        <List dense>
          {!locked &&
            approvalRequests.map((request) => (
              <NavigationListItem
                key={request.id}
                avatar={<ApprovalRequestStatusPie request={request} />}
                primary={request.title}
                onClick={() => onSelect(request)}
                secondary={request.deadline?.fromNow()}
              />
            ))}
          {locked && (
            <>
              <CircularSkeletonListItem />
              <CircularSkeletonListItem />
            </>
          )}
        </List>
      )}
      <Pagination page={page} pages={pages} />
    </div>
  );
};

export default MiniApprovalRequestsWidget;
