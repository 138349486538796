// @flow
import type { ModelID, Timestamp, URLStr } from "../types";
import { Moment } from "../types";
import type { AttachmentTypes } from "./attachment.model";
import moment from "moment/moment";

export type MessageAttachmentTypes = "share" | "story_mention" | "story_reply";

export type MessageAttachment = {
  id: ModelID,
  url: URLStr,
  type: AttachmentTypes | MessageAttachmentTypes,
};

export type Message = {
  id: ModelID,
  conversation_id: ModelID,
  native_sender_id: string,
  native_recipient_id: string,
  created_at: Moment,
  text: string,
  reactions: string[],
  read: boolean,
  deleted: boolean,
  reply_to_id: ?ModelID,
  attachments: MessageAttachment[],
};

export type APIMessage = {
  ...Omit<Message, "created_at">,
  created_at: Timestamp,
};

export const fromAPIResult = (message: APIMessage): Message => ({
  ...message,
  created_at: moment.unix(message.created_at),
});
