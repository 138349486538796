// @flow
import * as React from "react";
import type { Channel } from "../../../../../../models/channels/channel.model";
import type { Callback } from "../../../../../../types";
import useTranslate from "../../../../../../hooks/useTranslate";
import { IconButton } from "@mui/material";
import type { ThemeColor } from "../../../../../../stubs/mui/theming";
import { RowStack } from "../../../../../lib/layout/stacks";
import { ReplyRounded, ThumbUpRounded } from "@mui/icons-material";

type ActionState = "off" | "on" | "focus";
type ActionColor = ThemeColor | "action" | "default";

const COLORS: Record<ActionState, ActionColor> = {
  off: "action",
  on: "info",
  focus: "primary",
};

type Props = {
  channel: Channel,
  liked?: boolean,
  locked?: boolean,
  isReplying?: boolean,
  onPressReply: Callback,
  onPressLike: Callback,
};

const CommentActions: React.ComponentType<Props> = ({
  channel,
  liked,
  locked,
  onPressLike,
  isReplying,
  onPressReply,
}) => {
  const t = useTranslate();
  return (
    <RowStack spacing={0.5}>
      {channel.features?.commentManagement?.likes && (
        <IconButton
          disabled={locked}
          onClick={onPressLike}
          title={t(
            "EngagementHubSection.CommentsPreview." +
              (liked ? "postLiked" : "postNotLiked")
          )}
        >
          <ThumbUpRounded
            fontSize="small"
            color={locked ? "disabled" : COLORS[liked ? "on" : "off"]}
          />
        </IconButton>
      )}
      <IconButton
        disabled={locked}
        onClick={onPressReply}
        title={t(
          "EngagementHubSection.CommentsPreview." +
            (isReplying ? "cancelReply" : "beginReply")
        )}
      >
        <ReplyRounded
          fontSize="small"
          color={COLORS[isReplying ? "focus" : "off"]}
        />
      </IconButton>
    </RowStack>
  );
};

export default CommentActions;
