// @flow
import * as React from "react";
import type { ObjectURLSrc } from "../../../hooks/useObjectURL";
import useObjectURL from "../../../hooks/useObjectURL";
import type { Milliseconds, URLStr } from "../../../types";
import { isMobileApple } from "../../../lib/platform.lib";
import type { CSSProps } from "../../../reactTypes";
import { useRef } from "react";

export type Props = {
  src?: ObjectURLSrc,
  onLoadedMetadata?: (SyntheticEvent<HTMLVideoElement>) => any,
  controls?: boolean,
  videoOffset?: Milliseconds,
  poster?: ?URLStr,
  preload?: "auto" | "none" | "metadata",
  height?: number,
  ...CSSProps,
};

const updateVideoOffset = (
  node: ?HTMLVideoElement,
  videoOffset: ?Milliseconds
) => {
  if (node && typeof videoOffset === "number") {
    node.currentTime = videoOffset / 1000;
  }
};

const Video: React.ComponentType<Props> = React.forwardRef<
  Props,
  ?HTMLVideoElement
>(({ src, videoOffset, preload = "auto", ...props }, ref) => {
  const internalRef = useRef<?HTMLVideoElement>();
  const videoURL = useObjectURL(src);

  React.useEffect(() => {
    if (ref && typeof ref !== "function") {
      updateVideoOffset(ref.current, videoOffset);
    } else if (internalRef.current) {
      updateVideoOffset(internalRef.current, videoOffset);
    }
  }, [videoOffset, ref]);

  return (
    <video
      preload={preload}
      playsInline
      muted={isMobileApple}
      src={videoURL}
      ref={(node: ?HTMLVideoElement) => {
        updateVideoOffset(node, videoOffset);
        internalRef.current = node;
        if (!ref) return;
        if (typeof ref !== "function") {
          ref.current = node;
        } else {
          ref(node);
        }
      }}
      {...props}
    />
  );
});

export default Video;
