// @flow
import * as React from "react";
import WidgetHead from "../../inputs/WidgetHead";
import useTranslate from "../../../hooks/useTranslate";
import { List } from "@mui/material";
import { NavigationListItem } from "../../lib/display/listItems";
import type { InboxSummary } from "../../../store/selectors";
import { CommentRounded, EmailRounded } from "@mui/icons-material";
import IconInSticker from "../../lib/display/IconInSticker";

type Props = {
  inboxSummary: InboxSummary,
  onSelect: ("messages" | "comments") => any,
};

const InboxWidget: React.ComponentType<Props> = ({
  inboxSummary,
  onSelect,
}) => {
  const t = useTranslate();
  return (
    <div>
      <WidgetHead primary={t("global.inbox")} />
      <List dense>
        <NavigationListItem
          icon={<IconInSticker Icon={EmailRounded} color="secondary" />}
          primary={
            inboxSummary.unreadMessages
              ? t("global.newMessagesCount", {
                  count: inboxSummary.unreadMessages,
                })
              : t("global.noNewMessage")
          }
          onClick={() => onSelect("messages")}
          secondary={inboxSummary.latestMessage?.fromNow()}
        />
        <NavigationListItem
          icon={<IconInSticker Icon={CommentRounded} color="secondary" />}
          primary={
            inboxSummary.unreadComments
              ? t("global.newCommentsCount", {
                  count: inboxSummary.unreadComments,
                })
              : t("global.noNewComment")
          }
          onClick={() => onSelect("comments")}
          secondary={inboxSummary.latestComment?.fromNow()}
        />
      </List>
    </div>
  );
};

export default InboxWidget;
