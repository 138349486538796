// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Body2, Caption } from "../../lib/display/Text";
import type { Callback, Moment } from "../../../types";
import { Button } from "@mui/material";

type Props = {
  day: Moment,
  badge?: React.Node,
  onClick?: Callback,
  ...Object,
};

const Root = styled(Button, {
  shouldForwardProp: (prop) => prop !== "day",
})(({ theme, day }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  alignItems: "center",
  justifyContent: "flex-start",
  minWidth: 48,
  minHeight: 80,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: day.isSame(new Date(), "day")
    ? theme.palette.background.paper
    : undefined,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.5),
    gap: theme.spacing(0),
    height: 72,
    minHeight: 72,
  },
}));

const Dot = styled("div")(({ theme }) => ({
  borderRadius: "50%",
  display: "inline-flex",
  minWidth: 8,
  minHeight: 8,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
}));

const MiniCalendarDay: React.ComponentType<Props> = ({
  day,
  badge,
  onClick,
  ...props
}) => {
  return (
    <Root day={day} {...props} disabled={!onClick} onClick={onClick}>
      <Body2 color="textSecondary">{day.format("ddd")}</Body2>
      <Body2 fontWeight="bold" color={onClick ? "textPrimary" : undefined}>
        {day.format("D")}
      </Body2>
      <div>
        {!!badge && (
          <Dot>{badge === true ? undefined : <Caption>{badge}</Caption>}</Dot>
        )}
      </div>
    </Root>
  );
};

export default MiniCalendarDay;
