// @flow

type CreateScriptProps = {
  async: boolean,
  defer: boolean,
  src: string,
  textContent: string,
  onload: () => void,
  crossOrigin?: "anonymous",
};

/**
 * Create a new element and set immediately some attributes and
 * listeners.
 */
export const createElement = <E: Element>(
  tagName: string,
  attributes?: Object
): E => Object.assign(document.createElement(tagName), attributes);

/**
 * Add a script tag at the end of head.
 * @param {Partial<HTMLScriptElement>} attributes
 */
export const addScript = (attributes: Partial<CreateScriptProps>): void => {
  document.head?.appendChild(
    createElement<HTMLScriptElement>("script", attributes)
  );
};
