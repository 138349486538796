// @flow
import { useState } from "react";
import { en_gb, getLocale } from "../config/locales.config";
import type { Locale } from "../config/locales.config";
import { useTranslation } from "react-i18next";

const useLocale = (): Locale => {
  const { i18n } = useTranslation();
  const [cachedLocale, setCachedLocale] = useState<?Locale>(null);

  // We cache our version of the locale to avoid searching it each time.
  // But we need to update it when the actual locale changes.
  if (!i18n.language) {
    return en_gb;
  }
  if (!cachedLocale || cachedLocale.slug !== i18n.language) {
    const locale = getLocale(i18n.language) ?? en_gb;
    setCachedLocale(locale);
    return locale;
  }
  return cachedLocale;
};

export default useLocale;
