// @flow
import * as React from "react";
import { useStoreState } from "./store";
import { BOBCAAT_PALETTE } from "../config/palettes.config";
import type { Palette } from "../models/userPreferences.model";
import type { User } from "../models/user.model";
import compact from "lodash/compact";
import take from "lodash/take";
import sumBy from "lodash/sumBy";
import type { ModalAlert } from "../models/alerts.model";
import type { Subscription } from "../models/subscription.model";
import type { Portfolio } from "../models/portfolio.model";
import type { Notification } from "../models/notification.model";
import type { HashtagGroup } from "../models/hashtagGroup.model";
import type { Integer, ModelID, Slug } from "../types";
import { Moment } from "../types";
import type { BobcaatAccount } from "../models/account.model";
import {
  byAccountId,
  byId,
  byMaybeProp,
  byPortfolioId,
  byUpdatedAt,
  getId,
} from "../models/base.model";
import type { Post } from "../models/post.model";
import {
  getTime,
  isPostEngageable,
  isScheduledForward,
  postCanBeVizPlanned,
  postTimeBeforeEndOfDay,
  postTimeSameOrAfter,
} from "../models/post.model";
import type { Integration } from "../models/integration.model";
import type { AddOn } from "../models/addOn.model";
import type { PlanFlavour } from "../models/plan.model";
import type { ApprovalRequest } from "../models/approvalRequest.model";
import type { Conversation } from "../models/conversation.model";
import type { PostComment } from "../models/postComment.model";
import type { ConversationSummary } from "../models/conversationSummary.model";
import type { InitStatus } from "./reducers/lib/init.state";
import type { SocialActionSummary } from "../models/socialActionSummary.model";
import type { Message } from "../models/message.model";
import type { AttachmentUploadProgress } from "../models/attachment.model";
import type { MappingState } from "./reducers/lib/mapping.reducer";
import type { AnalyticsReport } from "../models/analytics.model";
import { getAccountChannel } from "../models/account.model";
import maxBy from "lodash/maxBy";
import { hasUnreadMessages } from "../models/conversationSummary.model";
import values from "lodash/values";
import {
  byLatestCommentAt,
  hasUnreadComments,
} from "../models/socialActionSummary.model";
import moment from "moment";
import type { PortfolioSummary } from "../models/portfolioSummary.model";
import sortBy from "lodash/sortBy";
import {
  getPortfolioDisplayOrder,
  getPortfolioName,
} from "../models/portfolio.model";
import { sortPortfolioBySummaries } from "../models/portfolioSummary.model";
import { userPrefersDarkColorScheme } from "../util/themes.util";
import orderBy from "lodash/orderBy";

export const useForceLogOut = (): boolean =>
  useStoreState().global.disconnected;

export const useModalAlert = (): ?ModalAlert =>
  useStoreState().global.modalAlert;

export const useCurrentUser = (): ?User => useStoreState().profile.currentUser;

export const useCurrentSubscription = (): ?Subscription =>
  useStoreState().subscription.subscription;

export const useSubscriptionLocked = (): boolean =>
  useStoreState().subscription.locked;

export const useSubscriptionInitialised = (): boolean =>
  // There is no "some" for subscription.
  useStoreState().subscription.initialised === "all";

export const usePlanFlavours = (): PlanFlavour[] =>
  useStoreState().subscription.planFlavours;

export const useAddOns = (): AddOn[] => useStoreState().subscription.addOns;

export const usePurchasedAddOns = (): AddOn[] => {
  const state = useStoreState();
  const addOns = state.subscription.addOns;
  const subscription = state.subscription.subscription;
  if (!subscription) return [];
  return compact(
    // Look for the add on that has a pricing matching the ID.
    subscription.add_ons.map((pricingId) =>
      addOns.find((addOn) => !!addOn.pricing.find(byId(pricingId)))
    )
  );
};

export const useAddOnPurchasedCount = (addOnId: ModelID): number => {
  return usePurchasedAddOns().filter(byId(addOnId)).length;
};

export const useNotifications = (): Notification[] =>
  useStoreState().notification.notifications;

export const useIntegrations = (): Integration[] =>
  useStoreState().integration.integrations;

export const useCurrentThemeName = (): string =>
  useCurrentUser()?.preferences.theme ??
  (userPrefersDarkColorScheme() ? "dark" : "light");

export const useCurrentPalette = (): Palette =>
  useCurrentUser()?.preferences.palette ?? BOBCAAT_PALETTE;

export const useUnreadNotificationsCount = (): number => {
  const state = useStoreState();
  return React.useMemo(
    () => sumBy(state.notification.notifications, (n) => (n.read ? 0 : 1)),
    [state.notification.notifications]
  );
};

export const usePortfolios = (): Portfolio[] =>
  useStoreState().portfolio.portfolios;

export const useSortedPortfolios = (): Portfolio[] => {
  const portfolios = useStoreState().portfolio.portfolios;
  return React.useMemo(
    () => sortBy(portfolios, getPortfolioDisplayOrder, getPortfolioName),
    [portfolios]
  );
};

export const usePortfoliosLocked = (): boolean =>
  useStoreState().portfolio.locked;

export const useSortedPortfoliosForHomePage = (): Portfolio[] => {
  const portfolios = useSortedPortfolios();
  const summaries = usePortfolioSummaries();
  return React.useMemo(
    () => sortBy(portfolios, sortPortfolioBySummaries(summaries)),
    [portfolios, summaries]
  );
};

export const usePortfolioSummaries = (): { [ModelID]: PortfolioSummary } =>
  // $FlowFixMe[incompatible-return]
  useStoreState().portfolio.summaries;

export const usePortfolioSummary = (pfId: ?ModelID): ?PortfolioSummary =>
  // $FlowFixMe[incompatible-return]
  useStoreState().portfolio.summaries[pfId ?? 0];

export const usePortfolioAccounts = (
  portfolioId: ?ModelID
): BobcaatAccount[] => {
  const accounts = useStoreState().account.accounts;
  return React.useMemo(
    () => accounts.filter(byPortfolioId(portfolioId)),
    [accounts, portfolioId]
  );
};

export const usePortfolioAccountsForAnalytics = (
  portfolioId: ?ModelID
): BobcaatAccount[] => {
  const accounts = useStoreState().account.accounts;
  return React.useMemo(
    () =>
      accounts
        .filter(byPortfolioId(portfolioId))
        .filter((acc) => !!getAccountChannel(acc).features?.analytics),
    [accounts, portfolioId]
  );
};

export const useAccount = (accountId: ?ModelID): ?BobcaatAccount =>
  useStoreState().account.accounts.find(byId(accountId ?? 0));

export const usePortfolioChannelAccount = (
  pfId: ?ModelID,
  channel: Slug
): ?BobcaatAccount =>
  useStoreState().account.accounts.find(
    (acc) => acc.portfolio_id === pfId && acc.channel === channel
  );

export const usePostsInitialised = (): InitStatus =>
  useStoreState().post.initialised;
export const useMessagingInitialised = (): InitStatus =>
  useStoreState().messaging.initialised;

export const usePosts = (): Post[] => useStoreState().post.posts;

export const usePostLock = (): boolean => useStoreState().post.locked;

export const usePostsLocked = (): boolean => useStoreState().post.locked;

export const useVizPosts = (
  accountId: ?ModelID,
  minDate: ?Moment,
  maxDate: ?Moment
): Post[] => {
  const posts = usePosts();
  return React.useMemo(
    () =>
      take(
        orderBy(
          posts
            .filter(byAccountId(accountId ?? 0))
            .filter(postCanBeVizPlanned)
            .filter(postTimeSameOrAfter(minDate))
            // User expects the final date to be included, not excluded.
            .filter(postTimeBeforeEndOfDay(maxDate)),
          getTime,
          "desc"
        ),
        !!minDate || !!maxDate ? 99 : 33
      ),
    [accountId, minDate, maxDate, posts]
  );
};

export const useRemainingAccountCapacity = (): [number, number] => {
  const state = useStoreState();
  const user = state.profile.currentUser;
  const accountCount = state.account.accounts.length;
  const maxAccounts = user?.limits?.max_accounts ?? 999;
  return [maxAccounts - accountCount, maxAccounts];
};

export const useHashtagGroups = (): HashtagGroup[] =>
  useStoreState().hashtagGroup.hashtagGroups;

export const useApprovalRequestsLocked = (): boolean =>
  useStoreState().approvalRequest.locked;

export const usePortfolioApprovalRequests = (
  portfolioId: ?ModelID
): ApprovalRequest[] => {
  const items = useStoreState().approvalRequest.approvalRequests;
  return React.useMemo(
    () =>
      items
        .filter((request) => request.status !== "completed")
        .filter(byMaybeProp("portfolio_id", portfolioId)),
    [items, portfolioId]
  );
};

export const useAccountEngagementUnreadCount = (
  accountId: ModelID,
  feature?: "comments" | "messages"
): number => {
  const { post, messaging, engagement } = useStoreState();
  const postIds = React.useMemo(() => {
    if (feature && feature !== "comments") return ([]: ModelID[]);
    return post.posts.filter(byAccountId(accountId)).map(getId);
  }, [feature, post.posts, accountId]);
  const conversationIds = React.useMemo(() => {
    if (feature && feature !== "messages") return ([]: ModelID[]);
    return messaging.conversations.filter(byAccountId(accountId)).map(getId);
  }, [feature, messaging.conversations, accountId]);
  return React.useMemo(() => {
    return (
      sumBy(
        conversationIds.map((id) => messaging.summaries[id]),
        "unread_messages"
      ) +
      sumBy(
        postIds.map((id) => engagement.summaries[id]),
        "unread_comments"
      )
    );
  }, [postIds, conversationIds, engagement.summaries, messaging.summaries]);
};

export const useAccountPosts = (accountId: ?ModelID): Post[] => {
  const posts = useStoreState().post.posts;
  return React.useMemo(
    () => posts.filter(byAccountId(accountId ?? 0)),
    [posts, accountId]
  );
};

export const usePortfolioPosts = (
  pfId: ?ModelID,
  fromToday?: boolean = false
): Post[] => {
  const posts = useStoreState().post.posts;
  return React.useMemo(() => {
    let psts = posts.filter(byPortfolioId(pfId ?? 0));
    if (fromToday) psts = psts.filter(isScheduledForward);
    return psts;
  }, [posts, pfId, fromToday]);
};

export const useAccountConversations = (
  accountId: ?ModelID
): Conversation[] => {
  const conversations = useStoreState().messaging.conversations;
  return React.useMemo(
    () => conversations.filter(byAccountId(accountId ?? 0)),
    [conversations, accountId]
  );
};

export const useAccountEngageablePosts = (accountId: ?ModelID): Post[] => {
  const posts = useAccountPosts(accountId);
  return React.useMemo(() => posts.filter(isPostEngageable), [posts]);
};

export const useCommentSummaries = (): { [ModelID]: SocialActionSummary } =>
  // $FlowFixMe[incompatible-return]
  useStoreState().engagement.summaries;

export const useCommentSummary = (postId: ?ModelID): ?SocialActionSummary =>
  useStoreState().engagement.summaries[postId ?? 0];

export const useConversationSummaries = (): {
  [ModelID]: ConversationSummary,
} =>
  // $FlowFixMe[incompatible-return]
  useStoreState().messaging.summaries;

export const useConversationSummary = (
  conversationId: ?ModelID
): ?ConversationSummary =>
  useStoreState().messaging.summaries[conversationId ?? 0];

export const useComments = (): { [ModelID]: PostComment[] } =>
  useStoreState().engagement.comments;

export type InboxSummary = {
  unreadMessages: Integer,
  unreadComments: Integer,
  latestMessage: ?Moment,
  latestComment: ?Moment,
};

export const useInboxSummary = (portfolioId: ?ModelID): InboxSummary => {
  const state = useStoreState();
  return React.useMemo(() => {
    const pfSummaryAccounts =
      state.portfolio.summaries[portfolioId ?? 0]?.accounts ?? [];
    const msgSummaries = values(state.messaging.summaries);
    const cmtSummaries = values(state.engagement.summaries);
    const latest_comment_at = maxBy(
      cmtSummaries.filter(hasUnreadComments),
      byLatestCommentAt
    )?.latest_comment_at;

    return {
      unreadMessages: sumBy(pfSummaryAccounts, (s) => s.unread_messages_count),
      unreadComments: sumBy(pfSummaryAccounts, (s) => s.unread_comments_count),
      latestMessage: maxBy(msgSummaries.filter(hasUnreadMessages), byUpdatedAt)
        ?.updated_at,
      latestComment: latest_comment_at
        ? moment.unix(latest_comment_at)
        : latest_comment_at,
    };
  }, [state, portfolioId]);
};

export const useMessages = (): Message[] => useStoreState().messaging.messages;

export const useAccountsLocked = (): boolean => useStoreState().account.locked;

export const useAnalyticsReport = (accountId: ?ModelID): ?AnalyticsReport =>
  useStoreState().analytics.reports[accountId ?? 0];

export const useAnalyticsLocked = (): boolean =>
  useStoreState().analytics.locked;

export const useMessageHistoryRemains = (): boolean =>
  useStoreState().messaging.messageHistoryRemains;

export const usePostUploadProgresses =
  (): MappingState<AttachmentUploadProgress> => useStoreState().post.uploads;

export const useIsNavigationDrawerOpen = (): boolean =>
  useStoreState().global.drawerOpen;
