import * as React from "react";
import Message from "./Message";
import { styled } from "@mui/material/styles";
import { Center } from "../../../../lib/layout/placement";
import { Body2 } from "../../../../lib/display/Text";
import Translate from "../../../../lib/display/Translate";
import { byId } from "../../../../../models/base.model";
import LoadMoreMarker from "../../../../lib/feedback/LoadMoreMarker";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column-reverse",
  height: "calc(100vh - 72px)", // minus conversation header
  overflow: "auto",
  width: "100%",
  gap: 8,
  [theme.breakpoints.down("sm")]: {
    height: `calc(100vh - 250px)`, // minus surroundings
  },
}));

const List = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: 16,
  flexGrow: 1,
});

const RepliedToMessage = styled(Message)(({ theme }) => ({
  border: `solid 1px ${theme.palette.info.main}`,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.info.main,
}));

const ReplyThreadContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "fromUser",
})(({ fromUser }) => ({
  display: "flex",
  flexDirection: fromUser ? "row-reverse" : "row",
}));

const ReplyThread = styled("div")(({ theme }) => ({
  height: 40,
  width: 4,
  backgroundColor: theme.palette.info.main,
  borderRadius: 4,
  marginLeft: theme.spacing(12),
  marginRight: theme.spacing(12),
}));

const MessagesPreview = ({
  userNativeId,
  messages,
  onFetchOlderMessages,
  conversation,
  allMessagesLoaded,
}) => (
  <Root>
    <List>
      {messages.map((message) => {
        const fromUser = message.native_sender_id === userNativeId;

        return (
          <React.Fragment key={message.id}>
            {message.reply_to_id && (
              <>
                <RepliedToMessage
                  message={messages.find(byId(message.reply_to_id))}
                  conversation={conversation}
                />
                <ReplyThreadContainer>
                  <ReplyThread fromUser={fromUser} />
                </ReplyThreadContainer>
              </>
            )}
            <Message
              message={message}
              conversation={conversation}
              fromUser={fromUser}
            />
          </React.Fragment>
        );
      })}
    </List>
    {!allMessagesLoaded && <LoadMoreMarker onVisible={onFetchOlderMessages} />}
    {allMessagesLoaded && (
      <Center>
        <Body2 color="textSecondary">
          <Translate id="EngagementHubSection.topOfConversationReached" />
        </Body2>
      </Center>
    )}
  </Root>
);

export default MessagesPreview;
