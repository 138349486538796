// @flow
import * as React from "react";
import { ResponsiveInlineRowStack } from "../lib/layout/stacks";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import type { Timezone } from "../../models/timezone.model";
import type { Moment } from "../../types";
import useTranslate from "../../hooks/useTranslate";

type Props = {
  timezone: Timezone,
  selectedDate: ?Moment,
  onDateChange: (?Moment) => any,
  onTimeChange: (?Moment) => any,
  fieldProps?: {
    size?: "small" | "medium",
  },
};

const ScheduleTimePicker: React.ComponentType<Props> = ({
  selectedDate,
  onDateChange,
  onTimeChange,
  timezone,
  fieldProps,
}) => {
  const t = useTranslate();
  const selectedDateTimezoneAware =
    selectedDate && timezone
      ? moment(selectedDate).tz(timezone.name, true)
      : selectedDate;
  const error = !!selectedDateTimezoneAware?.isBefore(moment());
  return (
    <ResponsiveInlineRowStack flexWrap="nowrap" spacing={1}>
      <DatePicker
        disablePast
        label={t("global.datePicker")}
        value={selectedDate}
        onChange={onDateChange}
        slotProps={{
          field: {
            ...fieldProps,
            error,
            helperText: error
              ? t("ScheduleDialog.scheduleIsInThePast")
              : undefined,
          },
        }}
      />
      <TimePicker
        label={t("global.timePicker")}
        value={selectedDate}
        onChange={onTimeChange}
        slotProps={{ field: { error, ...fieldProps } }}
      />
    </ResponsiveInlineRowStack>
  );
};

export default ScheduleTimePicker;
