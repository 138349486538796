// @flow
import * as React from "react";
import PostBuilderContext from "../../../../contexts/postBuilder";
import { EMPTY_SELF_REPLY } from "../../../../models/post.model";
import { splice, withoutIndex } from "../../../../lib/lodashex.lib";
import ThreadWidget from "./ThreadWidget";

const ThreadWidgetContainer: React.ComponentType<{}> = () => {
  const {
    post: { self_replies, id },
    onUpdatePost,
    channel,
  } = React.useContext(PostBuilderContext);
  const [activeReplyIndex, setActiveReplyIndex] = React.useState(0);

  React.useEffect(() => {
    setActiveReplyIndex(self_replies.length - 1);
  }, [self_replies.length]);

  const handleAddSelfReply = () => {
    onUpdatePost({ self_replies: [...self_replies, EMPTY_SELF_REPLY] });
  };

  const handleDeleteSelfReply = (idx: number) => {
    onUpdatePost({ self_replies: withoutIndex(self_replies, idx) });
  };

  if (!channel) return null;

  return (
    <ThreadWidget
      isEdit={!!id}
      selfReplies={self_replies}
      activeReplyIndex={activeReplyIndex}
      onChangeReplyContent={(idx, content) =>
        onUpdatePost({
          self_replies: splice(self_replies, idx, 1, [
            { ...self_replies[idx], content },
          ]),
        })
      }
      onChangeReplyAttachments={(idx, attachments) =>
        onUpdatePost({
          self_replies: splice(self_replies, idx, 1, [
            { ...self_replies[idx], attachments },
          ]),
        })
      }
      onAddSelfReply={handleAddSelfReply}
      onSetActiveReplyIndex={setActiveReplyIndex}
      onDeleteSelfReply={handleDeleteSelfReply}
      channel={channel}
    />
  );
};

export default ThreadWidgetContainer;
