// @flow
import * as React from "react";
import type { Dispatcher, KeyBase, Reducer } from "./types";
import { replaceItem } from "../../../lib/lodashex.lib";

export type BucketState<TKey: KeyBase, TItem> = { [TKey]: TItem[] };
type AddAction<TItem> = { type: "ADD", data: TItem };
type SetBucketAction<TKey, TItem> = {
  type: "SET_BUCKET",
  data: { key: TKey, items: TItem[] },
};
type AddAllAction<TItem> = { type: "ADD_ALL", data: TItem[] };
type AddOrReplaceAction<TItem> = { type: "ADD_OR_REPLACE", data: TItem };
type RemoveAction<TItem> = { type: "REMOVE", data: TItem };
type ClearAction = { type: "CLEAR" };

/**
 * Actions supported by a Bucket reducer.
 */
export type BucketAction<TKey: KeyBase, TItem> =
  | AddAction<TItem>
  | AddAllAction<TItem>
  | SetBucketAction<TKey, TItem>
  | RemoveAction<TItem>
  | AddOrReplaceAction<TItem>
  | ClearAction;

export type BucketActions<TKey: KeyBase, TItem> = {
  /**
   * Add an item to the bucket.
   * @param item
   */
  add: (item: TItem) => void,
  /**
   * Remove all items from the bucket.
   */
  clear: () => void,
  /**
   * Add items to the bucket.
   * @param items
   */
  addAll: (items: TItem[]) => void,
  /**
   * Set one bucket of the bucket state. All items are assumed to have the same key.
   * @param key The key.
   * @param items
   */
  setBucket: (key: TKey, items: TItem[]) => void,
  /**
   * Remove the item.
   * @param item
   */
  remove: (item: TItem) => void,
  /**
   * Replace the item whose bucket key / key is identical in the bucket.
   * @param item
   */
  addOrReplace: (item: TItem) => void,
};

/**
 * Factories to create Bucket actions.
 *
 * @template TItem The type of the items in the bucket.
 */
export const makeBucketActions = <TKey: KeyBase, TItem: Object>(
  dispatch: (BucketAction<TKey, TItem>) => void
): BucketActions<TKey, TItem> => ({
  add: (item: TItem) => dispatch({ type: "ADD", data: item }),
  addAll: (items: TItem[]) => dispatch({ type: "ADD_ALL", data: items }),
  setBucket: (key: TKey, items: TItem[]) =>
    dispatch({ type: "SET_BUCKET", data: { key, items } }),
  remove: (item: TItem) => dispatch({ type: "REMOVE", data: item }),
  addOrReplace: (item: TItem) =>
    dispatch({ type: "ADD_OR_REPLACE", data: item }),
  clear: () => dispatch({ type: "CLEAR" }),
});

interface Props<TItem> {
  key: $Keys<TItem>;
  bucketKey: $Keys<TItem>;
  transform?: (item: TItem) => TItem;
}

export type BucketReducer<TKey: KeyBase, TItem> = Reducer<
  BucketState<TKey, TItem>,
  BucketAction<TKey, TItem>
>;

const makeAddOne =
  <TKey: KeyBase, TItem: Object>(
    getBucketKey: (item: TItem) => TKey,
    transform?: (item: TItem) => TItem
  ): ((BucketState<TKey, TItem>, TItem) => BucketState<TKey, TItem>) =>
  (state, item) => {
    const bucketKey: TKey = getBucketKey(item);
    item = transform ? transform(item) : item;
    const bucket = [...(state[bucketKey] ?? []), item];
    return { ...state, [(bucketKey: any)]: bucket };
  };

/**
 * Create a bucket reducer.
 *
 * @template TItem The type of the items in the bucket.
 * @param params Bucket reducer parameters.
 * @param params.key The resource main key name.
 * @param params.bucketKey The resource secondary key name, to put it in buckets.
 * @param [params.transform] A transformer to apply on all loaded items.
 */
export const bucketReducer = <TKey: KeyBase, TItem: Object>({
  key,
  bucketKey,
  transform,
}: Props<TItem>): BucketReducer<TKey, TItem> => {
  const getKey = (item: TItem) => item[key];
  const byKey = (itemKey: TKey) => (item: TItem) => getKey(item) === itemKey;
  const byItemKey = (item: TItem) => byKey(getKey(item));
  const getBucketKey = (item: TItem) => item[bucketKey];

  const addOne = makeAddOne(getBucketKey, transform);

  return (
    state: BucketState<TKey, TItem>,
    action: BucketAction<TKey, TItem>
  ) => {
    switch (action.type) {
      case "ADD": {
        action = (action: AddAction<TItem>);
        return addOne(state, action.data);
      }

      case "ADD_ALL": {
        action = (action: AddAllAction<TItem>);
        const items = transform ? action.data.map(transform) : action.data;
        return items.reduce(addOne, state);
      }

      case "SET_BUCKET": {
        action = (action: SetBucketAction<TKey, TItem>);
        const items = transform
          ? action.data.items.map(transform)
          : action.data.items;
        // Flow mishap here. key is either a string or a number
        // and defined as such but Flow can't detect it.
        // $FlowFixMe[invalid-computed-prop]
        return { ...state, [action.data.key]: items };
      }

      case "REMOVE": {
        const item = (action: RemoveAction<TItem>).data;
        const bucketKey = getBucketKey(item);
        const bucket = state[bucketKey] ?? [];
        return { ...state, [bucketKey]: bucket.filter(byItemKey(item)) };
      }

      case "ADD_OR_REPLACE": {
        const item = transform ? transform(action.data) : action.data;
        const bucketKey = getBucketKey(item);
        const bucket = state[bucketKey] ?? [];
        return {
          ...state,
          [bucketKey]: replaceItem(bucket, byItemKey(item), item, true),
        };
      }

      case "CLEAR":
        return {};

      default:
        return state;
    }
  };
};

/**
 * Typed wrapper around React native useReducer.
 */
export const useBucketReducer = <TKey: KeyBase, TItem: Object>(
  reducer: BucketReducer<TKey, TItem>
): [BucketState<TKey, TItem>, Dispatcher<BucketAction<TKey, TItem>>] =>
  React.useReducer<BucketState<TKey, TItem>, BucketAction<TKey, TItem>>(
    reducer,
    {}
  );
