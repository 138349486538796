// @flow
import useLocale from "../../hooks/useLocale";
import * as React from "react";
import DefaultAddOnImage from "../../assets/images/box.svg";
import PaidPrice from "./PaidPrice";
import { styled } from "@mui/material/styles";
import type { AddOn } from "../../models/addOn.model";
import { pricingByCurrency } from "../../models/addOn.model";
import type { CurrencyCode, ModelID } from "../../types";
import { useAddOnPurchasedCount } from "../../store/selectors";
import { Paper } from "@mui/material";
import Image from "../lib/display/Image";
import { Body1, Body2, Caption } from "../lib/display/Text";
import Translate from "../lib/display/Translate";
import { InlineRowStack } from "../lib/layout/stacks";
import { SmallDeleteButton, SmallPrimaryButton } from "../lib/inputs/buttons";
import withConfirmDialog from "../lib/feedback/withConfirmDialog";
import StatusChip from "../lib/display/StatusChip";
import { SellRounded } from "@mui/icons-material";

type Props = {
  addOn: AddOn,
  locked?: boolean,
  onPurchase?: (ModelID) => any,
  onCancel?: (ModelID) => any,
  dense?: boolean,
  currency: CurrencyCode,
};

const Root = styled(Paper, { shouldForwardProp: (prop) => prop !== "dense" })(
  ({ theme, dense }) => ({
    display: "flex",
    position: "relative",
    overflow: "hidden",
    alignItems: "space-between",
    maxWidth: 550,
    height: dense ? 76 : 128,
    border: dense ? `solid 1px ${theme.palette.divider}` : undefined,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  })
);

const Content = styled("div")(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

const Pricing = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

const Buttons = styled(InlineRowStack)(({ theme }) => ({
  marginTop: theme.spacing(1),
  justifyContent: "flex-end",
}));

const PurchaseButton = withConfirmDialog(SmallPrimaryButton, {
  DialogProps: {
    name: "confirm-addon-purchase",
    title: "Page.SubscriptionPanel.confirmPurchaseAddOn.title",
    message: "Page.SubscriptionPanel.confirmPurchaseAddOn.message",
  },
});

const CancelButton = withConfirmDialog(SmallDeleteButton, {
  DialogProps: {
    name: "confirm-addon-cancellation",
    title: "Page.SubscriptionPanel.confirmCancelAddOn.title",
    message: "Page.SubscriptionPanel.confirmCancelAddOn.message",
  },
});

const StyledImage = styled(Image)(({ theme }) => ({
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const AddOnItem: React.ComponentType<Props> = ({
  addOn,
  locked,
  onPurchase,
  onCancel,
  dense,
  currency,
}) => {
  const locale = useLocale();
  const pricing = React.useMemo(
    () => addOn.pricing.find(pricingByCurrency(currency)),
    [addOn, currency]
  );
  const purchasedCount = useAddOnPurchasedCount(addOn.id);
  const cancelText =
    purchasedCount === 0 ? null : purchasedCount === 1 ? "global.cancel" : "-1";
  const purchaseText = purchasedCount === 0 ? "global.purchase" : "+1";

  const purchasedLabel = purchasedCount > 0 && (
    <StatusChip severity="info" icon={SellRounded}>
      {purchasedCount}
    </StatusChip>
  );

  return (
    <Root dense={dense}>
      <StyledImage
        src={addOn.image_url ?? DefaultAddOnImage}
        alt={addOn.name}
      />
      <Content>
        <InlineRowStack spacing={2} alignItems="center">
          <div>
            <Body1 fontWeight="bold">
              {addOn.localized_names[locale.slug] ?? addOn.name}
            </Body1>
            <Body2 color="textSecondary">
              {addOn.localized_descriptions[locale.slug] ?? addOn.description}
            </Body2>
            {addOn.unique && (
              <Caption style={{ fontStyle: "italic", marginTop: 8 }}>
                <Translate id="SubscriptionPanel.uniqueItemWarning" />
              </Caption>
            )}
          </div>
          {dense && purchasedLabel}
        </InlineRowStack>
        {!dense && (
          <Buttons>
            {onCancel && cancelText && (
              <CancelButton
                loading={locked}
                onClick={pricing ? () => onCancel(pricing.id) : undefined}
              >
                <Translate id={cancelText} />
              </CancelButton>
            )}
            {purchasedLabel}
            {onPurchase && (
              <PurchaseButton
                disabled={purchasedCount > 0 && addOn.unique}
                loading={locked}
                onClick={pricing ? () => onPurchase(pricing.id) : undefined}
              >
                <Translate id={purchaseText} />
              </PurchaseButton>
            )}
          </Buttons>
        )}
      </Content>
      <Pricing>
        {pricing && (
          <PaidPrice
            price={pricing.monthly_price}
            currency={pricing.currency}
          />
        )}
      </Pricing>
    </Root>
  );
};

export default AddOnItem;
