// @flow
import * as React from "react";
import type { Portfolio } from "../../models/portfolio.model";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import type { LockActions, LockState } from "./lib/lock.state";
import { useLockState } from "./lib/lock.state";
import type { MappingActions, MappingState } from "./lib/mapping.reducer";
import type { PortfolioSummary } from "../../models/portfolioSummary.model";
import {
  makeMappingActions,
  mappingReducer,
  useMappingReducer,
} from "./lib/mapping.reducer";

const portfolioReducer = sequenceReducer<Portfolio>({
  key: "id",
  transform: (pf: Portfolio) => ({
    ...pf,
    initials: pf.name.substring(0, 2).toUpperCase(),
  }),
});

const portfolioSummaryReducer = mappingReducer<PortfolioSummary>({
  key: "id",
});

export type PortfolioState = {
  ...LockState,
  portfolios: SequenceState<Portfolio>,
  summaries: MappingState<PortfolioSummary>,
};
export type PortfolioActions = {
  ...LockActions,
  ...SequenceActions<Portfolio>,
  summaries: MappingActions<PortfolioSummary>,
};

export const usePortfolioReducer = (): [PortfolioState, PortfolioActions] => {
  const [portfolios, dispatch] = useSequenceReducer(portfolioReducer);
  const [summaries, summariesDispatch] = useMappingReducer(
    portfolioSummaryReducer
  );
  // initially locked, because we need an initial load before it is ready.
  const [locked, lockActions] = useLockState(true);

  const baseActions = React.useMemo(
    () => makeSequenceActions(dispatch),
    [dispatch]
  );

  const summariesActions = React.useMemo(
    () => makeMappingActions(summariesDispatch),
    [summariesDispatch]
  );
  const actions = React.useMemo(
    () => ({
      ...baseActions,
      summaries: summariesActions,
      ...lockActions,
    }),
    [baseActions, summariesActions, lockActions]
  );
  return [{ portfolios, summaries, locked }, actions];
};
