import { styled } from "@mui/material/styles";
import type { CSSProps } from "../../../reactTypes";

type Props = {
  spacing?: number,
  ...CSSProps,
};

const Spacer: React.ComponentType<Props> = styled("div", {
  shouldForwardProp: (prop) => prop !== "spacing",
})(({ theme, spacing = 1 }) => ({
  "& > *": {
    marginTop: theme.spacing(spacing),
    marginRight: theme.spacing(spacing),
  },
}));

export default Spacer;
