// @flow
import * as React from "react";
import type { FormField, OnChangeField } from "../../../../hooks/useForm";
import { useFormFieldError } from "../../../../hooks/useForm";
import Autocomplete from "../Autocomplete";
import type { Props as AutocompleteProps } from "../Autocomplete";

type Props<T> = {
  ...Omit<AutocompleteProps<T>, "value" | "onChange">,
  formField: FormField<>,
  onChange: OnChangeField<>,
};

const FormAutocomplete = <T: { [string]: any }>({
  formField,
  onChange,
  TextFieldProps,
  ...props
}: Props<T>): React.Node => {
  const helperText = useFormFieldError(formField) ?? formField.helperText;
  return (
    <Autocomplete
      value={formField.value}
      onChange={(e, value) => onChange(value)}
      TextFieldProps={{
        helperText,
        error: !!formField.error,
        name: formField.name,
        ...TextFieldProps,
      }}
      {...props}
    />
  );
};

export default FormAutocomplete;
