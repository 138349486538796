// @flow
import * as React from "react";
import { Collapse, FormControlLabel, Switch } from "@mui/material";
import type { Children } from "../../../reactTypes";
import { styled } from "@mui/material/styles";

export type Props = {
  label: React.Node,
  children?: Children,
  disabled?: boolean,
  checked?: boolean,
  onChange?: (e: SyntheticEvent<>, checked: boolean) => any,
  /**
   * Name attribute of the `input` element.
   */
  name?: string,
  slotProps?: Object,
};

const Root = styled(FormControlLabel)({
  marginLeft: 0,
  display: "flex",
  justifyContent: "space-between",
});

const SwitchField: React.ComponentType<Props> = ({
  children,
  checked,
  name,
  ...props
}) => (
  <div>
    <Root
      checked={checked}
      name={name}
      control={<Switch color="primary" />}
      labelPlacement="start"
      {...props}
    />
    {children && <Collapse in={checked}>{children}</Collapse>}
  </div>
);

export default SwitchField;
