// @flow
import * as React from "react";
import StatusChip from "../lib/display/StatusChip";
import type { ApprovalRequestStatuses } from "../../models/approvalRequest.model";
import { propMap } from "../lib/utils/props";
import type { Severity } from "../../models/alerts.model";
import useTranslate from "../../hooks/useTranslate";
import { ApprovalRounded } from "@mui/icons-material";
import type { Callback } from "../../types";

type Props = {
  status: ApprovalRequestStatuses,
  onClick?: Callback,
};

const _STATUS_TO_SEVERITY: Record<ApprovalRequestStatuses, Severity> = {
  pending: "info",
  completed: "success",
  reviewed: "warning",
};

export const getSeverity: (ApprovalRequestStatuses) => Severity = propMap(
  _STATUS_TO_SEVERITY,
  "pending"
);

const ApprovalRequestStatusChip: React.ComponentType<Props> = React.memo(
  ({ status, onClick }) => {
    const t = useTranslate();
    return (
      <StatusChip
        severity={getSeverity(status)}
        icon={ApprovalRounded}
        onClick={onClick}
      >
        {t(`refer.approvalRequestStatus.${status}`)}
      </StatusChip>
    );
  }
);

export default ApprovalRequestStatusChip;
