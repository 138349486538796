// @flow
import * as React from "react";
import type { BobcaatAccount } from "../../../models/account.model";
import { isIndividualAccount } from "../../../models/account.model";
import PostFiltersContext from "../../../contexts/postFilters";
import head from "lodash/head";
import { useAnalyticsReport } from "../../../store/selectors";
import { byId } from "../../../models/base.model";
import { getChannel } from "../../../models/channels";
import AccountAnalyticsWidget from "./AccountAnalyticsWidget";
import type { EntityMetric } from "../../../models/analytics.model";
import { isEntityMetricRelevant } from "../../../models/analytics.model";
import { PAGE_METRICS_KEYS } from "../../../config/metrics.config";
import AnalyticsPlaceholder from "./AnalyticsPlaceholder";

type Props = {
  accounts: BobcaatAccount[],
};

const AccountAnalyticsWidgetContainer: React.ComponentType<Props> = ({
  accounts,
}) => {
  const { accountIds } = React.useContext(PostFiltersContext);
  const accountId = head(accountIds);
  const analyticsReport = useAnalyticsReport(accountId);
  const account = accounts.find(byId(accountId));
  const channel = getChannel(account?.channel);
  const [selectedMetric, setSelectedMetric] =
    React.useState<EntityMetric>("impressions");

  // When a new report is loaded, make sure the selected metric is valid.
  React.useEffect(() => {
    // Don't change if report disappears.
    if (!analyticsReport) return;
    setSelectedMetric((oldMetric) => {
      const iteratee = isEntityMetricRelevant(analyticsReport);
      // Try not to change metric if it is valid.
      if (iteratee(oldMetric)) return oldMetric;
      // Find the first compatible one but keep current if none can be found.
      return PAGE_METRICS_KEYS.find(iteratee) ?? oldMetric;
    });
  }, [analyticsReport]);

  if (!analyticsReport) {
    return (
      <AnalyticsPlaceholder
        text={"AnalyticsSection.AnalyticsTab.noAnalytics"}
      />
    );
  }
  if (isIndividualAccount(account) && !channel?.features?.analytics?.personal) {
    return (
      <AnalyticsPlaceholder
        text={"AnalyticsSection.AnalyticsTab.noPersonalAnalytics"}
      />
    );
  }
  return (
    <AccountAnalyticsWidget
      report={analyticsReport}
      selectedMetric={selectedMetric}
      onChangeSelectedMetric={setSelectedMetric}
    />
  );
};

export default AccountAnalyticsWidgetContainer;
