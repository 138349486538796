// @flow
import * as React from "react";
import type { Props as SettingsSectionProps } from "../../../layout/SettingsSection";
import SettingsSection from "../../../layout/SettingsSection";
import { Collapse } from "@mui/material";
import PaymentDetailsWidget from "../../PaymentDetailsWidget/PaymentDetailsWidget";
import { CurrentCardPreview } from "../../PaymentDetailsWidget/CurrentCardPreview";

type Props = {
  ...Omit<SettingsSectionProps, "title" | "children">,
};

const UpdatePaymentDetailsSection: React.ComponentType<Props> = (props) => {
  return (
    <SettingsSection
      title="Page.SettingsSection.PaymentDetailsTab.currentPaymentDetails"
      {...props}
    >
      <CurrentCardPreview />
      <Collapse in={props.editing}>
        <PaymentDetailsWidget isAdd={false} />
      </Collapse>
    </SettingsSection>
  );
};

export default UpdatePaymentDetailsSection;
