// @flow
import * as React from "react";
import useVideoAttachments from "./useVideoAttachments";
import useImageAttachments from "./useImageAttachments";
import {
  isAttachmentGif,
  isAttachmentNotGif,
} from "../../models/attachment.model";
import type { Channel } from "../../models/channels/channel.model";
import type { PostAttachment } from "../../models/post.model";
import type { DiagnosticError } from "../../util/diagnostics.util";
import compact from "lodash/compact";
import pinterest from "../../models/channels/pinterest.model";
import PostBuilderContext from "../../contexts/postBuilder";
import type { Integer } from "../../types";
import type { ContentTypes } from "../../models/contentTypes.model";
import instagram from "../../models/channels/instagram.model";

const EMPTY: Array<DiagnosticError> = [];

export const useContextlessAttachmentsDiagnostic = (
  channel: ?Channel,
  videos: PostAttachment[],
  allImages: PostAttachment[],
  thumbnail_video_offset: ?Integer,
  contentType?: ContentTypes
): DiagnosticError[] => {
  const images = React.useMemo(
    () => allImages.filter(isAttachmentNotGif),
    [allImages]
  );
  const gifs = React.useMemo(
    () => allImages.filter(isAttachmentGif),
    [allImages]
  );
  const hasImages = images.length > 0 ? 1 : 0;
  const hasVideos = videos.length > 0 ? 1 : 0;
  const hasGifs = gifs.length > 0 ? 1 : 0;
  return React.useMemo(() => {
    if (!channel) {
      return EMPTY;
    }
    const media = channel.contentSpecs.media;
    return compact([
      videos.length + images.length > (media.overall.maxCount ?? Infinity) &&
        "tooManyMedia",
      videos.length + images.length < (media.overall.minCount ?? -Infinity) &&
        "notEnoughMedia",
      videos.length > (media.video?.maxCount ?? Infinity) && "tooManyVideos",
      images.length > (media.image?.maxCount ?? Infinity) && "tooManyImages",
      images.length < (media.image?.minCount ?? -Infinity) && "notEnoughImages",
      gifs.length > (media.gif?.maxCount ?? Infinity) && "tooManyGifs",
      media.overall.oneMediaTypeOnly &&
        hasImages + hasVideos + hasGifs > 1 &&
        "unsupportedMixedContent",
      channel.slug === pinterest.slug &&
        videos.length === 1 &&
        !thumbnail_video_offset &&
        "PinterestMissingVideoThumbnail",
      channel.slug === instagram.slug &&
        contentType === "reel" &&
        images.length + gifs.length > 0 &&
        "instagramUnsupportedImagesInReel",
    ]);
  }, [
    images,
    videos,
    gifs,
    channel,
    hasImages,
    hasVideos,
    hasGifs,
    thumbnail_video_offset,
    contentType,
  ]);
};

const useAttachmentsDiagnostic = (channel: ?Channel): DiagnosticError[] => {
  const videos = useVideoAttachments();
  const allImages = useImageAttachments();
  const {
    post: { thumbnail_video_offset, content_type },
  } = React.useContext(PostBuilderContext);
  return useContextlessAttachmentsDiagnostic(
    channel,
    videos,
    allImages,
    thumbnail_video_offset,
    content_type
  );
};

export default useAttachmentsDiagnostic;
