// @flow
import type { Integer, ModelID, Timestamp } from "../types";
import type { AccountLifecycleState, BobcaatAccount } from "./account.model";
import type { Post } from "./post.model";
import moment from "moment/moment";
import type { Portfolio } from "./portfolio.model";
import countBy from "lodash/countBy";
import { getAccountLifecycleState } from "./account.model";
import some from "lodash/some";
import type { Severity } from "./alerts.model";

export type PortfolioSummaryAccount = {
  ...Pick<
    BobcaatAccount,
    "id" | "status_code" | "entity_name" | "access_token_expiry" | "channel"
  >,
  unread_messages_count: Integer,
  unread_comments_count: Integer,
};

export type PortfolioSummaryPost = Pick<Post, "id">;

export type PortfolioSummary = {
  id: ModelID,
  accounts: PortfolioSummaryAccount[],
  posts: PortfolioSummaryPost[],
};

export type APIPortfolioSummary = {
  ...PortfolioSummary,
  accounts: {
    ...Omit<PortfolioSummaryAccount, "access_token_expiry">,
    access_token_expiry: Timestamp,
  }[],
};

/**
 * Enrich a raw summary received from the server.
 */
export const fromAPIResult = (
  summary: APIPortfolioSummary
): PortfolioSummary => ({
  ...summary,
  accounts: summary.accounts.map((acc) => ({
    ...acc,
    access_token_expiry: moment.unix(acc.access_token_expiry),
  })),
});

export type PortfolioSummaryAccountCounts = {
  ...Record<AccountLifecycleState, number>,
  errors: number,
};

export const getSummaryAccountsCounts = (
  accounts: PortfolioSummaryAccount[]
): PortfolioSummaryAccountCounts => ({
  ...countBy(accounts, getAccountLifecycleState),
  errors: accounts.filter((a) => a.status_code !== 0).length,
});

export const summaryAccountHasUnreadEngagement = (
  account: PortfolioSummaryAccount
): boolean =>
  !!(account.unread_messages_count || account.unread_comments_count);

export const sortPortfolioBySummaries =
  (summaries: { [ModelID]: PortfolioSummary }): ((Portfolio) => number) =>
  (pf) => {
    switch (getPortfolioSummarySeverity(summaries[pf.id])) {
      case "error":
        return 0;
      case "warning":
        return 1;
      case "info":
        return 2;
      default:
        return 3;
    }
  };

export const getPortfolioSummarySeverity = (
  sm: ?PortfolioSummary
): ?Severity => {
  if (!sm) return null;
  const { errors, expired, expires_soon } = getSummaryAccountsCounts(
    sm.accounts
  );
  if (sm.posts.length > 0 || errors > 0 || expired > 0) return "error";
  if (expires_soon > 0) return "warning";
  if (some(sm.accounts, summaryAccountHasUnreadEngagement)) return "info";
  return null;
};
