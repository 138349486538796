// @flow
import * as React from "react";
import type { Props as SettingsSectionProps } from "../../../layout/SettingsSection";
import SettingsSection from "../../../layout/SettingsSection";
import type { Form, StringFormFieldDef } from "../../../../hooks/useForm";
import { FormActions } from "../../../../hooks/useForm";
import { InlineRowStack } from "../../../lib/layout/stacks";
import FormTextInput from "../../../lib/inputs/form/FormTextInput";
import useTranslate from "../../../../hooks/useTranslate";
import type { PromotionCode } from "../../../../models/promotionCode.model";
import AppliedPromocode from "../../../display/AppliedPromocode";

export type PromoCodeFormDef = {
  promoCode: StringFormFieldDef,
};

type Props = {
  ...Omit<SettingsSectionProps, "title" | "children">,
  form: Form<PromoCodeFormDef>,
  appliedPromocodes: PromotionCode[],
};

const PromoCodeSection: React.ComponentType<Props> = ({
  form,
  appliedPromocodes,
  ...props
}) => {
  const t = useTranslate();
  return (
    <>
      <SettingsSection
        title="Page.SettingsSection.SubscriptionTab.promoCodes"
        collapsed={!props.editing}
        actionId={"global.add"}
        submitId={"global.apply"}
        {...props}
      >
        <FormTextInput
          formField={form.state.promoCode}
          onChange={FormActions.onChangeField(form.set, "promoCode")}
          label={t("global.promoCode")}
          name="promoCode"
          required
          sx={{ width: 300 }}
        />
      </SettingsSection>
      <InlineRowStack>
        {appliedPromocodes.map((code) => (
          <AppliedPromocode key={code.id} promocode={code} />
        ))}
      </InlineRowStack>
    </>
  );
};

export default PromoCodeSection;
