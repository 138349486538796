// @flow
import * as React from "react";
import type { HashtagGroup } from "../../../models/hashtagGroup.model";
import { useStoreActions } from "../../../store/store";
import type { Portfolio } from "../../../models/portfolio.model";
import { FormActions, useForm } from "../../../hooks/useForm";
import type { HashtagGroupFormDef } from "./HashtagGroupWidget";
import { checkHashtagGroupName } from "../../../util/validation.util";
import { useEffect } from "react";
import HashtagGroupWidget from "./HashtagGroupWidget";
import * as hashtagGroupService from "../../../services/hashtagGroup.service";
import useNavigateBackToResourceList from "../../../hooks/useNavigateBackToResourceList";
import { getChannel } from "../../../models/channels";

type Props = {
  hashtagGroup: ?HashtagGroup,
  portfolio: Portfolio,
};

const FORM_DEF: HashtagGroupFormDef = {
  name: {
    type: "string",
    validate: checkHashtagGroupName,
    maxLength: 50,
    preventOverflow: true,
  },
  hashtags: { type: "string[]" },
  channel: { type: "object" },
};

const HashtagGroupWidgetContainer: React.ComponentType<Props> = ({
  hashtagGroup,
  portfolio,
}) => {
  const navigateBackToList = useNavigateBackToResourceList(
    Boolean(hashtagGroup)
  );
  const actions = useStoreActions();
  const initial = React.useMemo(
    () => ({
      name: hashtagGroup?.name ?? "",
      hashtags: hashtagGroup?.hashtags ?? [],
      channel: getChannel(hashtagGroup?.channel),
    }),
    [hashtagGroup]
  );

  const form = useForm<HashtagGroupFormDef>(FORM_DEF, initial);

  useEffect(() => {
    if (initial) {
      FormActions.reset(form.set, initial);
    }
  }, [form.set, initial]);

  const handleConfirm = () => {
    if (FormActions.validate(form)) {
      const base = FormActions.collect(form.state);
      const payload = {
        ...base,
        // Need to apply some backward compatibility here.
        // In the previous version, hashtag groups existed
        // outside of any portfolio. That is not the case anymore
        // but the ones created before that shouldn't be updated.
        portfolio_id: hashtagGroup?.portfolio_id ?? portfolio.id,
        channel: base.channel?.slug,
      };
      if (hashtagGroup) {
        return hashtagGroupService
          .editHashtagGroup(actions)({ ...payload, id: hashtagGroup.id })
          .then(navigateBackToList);
      } else {
        return hashtagGroupService
          .addHashtagGroup(actions)(payload)
          .then(navigateBackToList);
      }
    }
  };

  return (
    <HashtagGroupWidget
      form={form}
      onConfirm={handleConfirm}
      onCancel={navigateBackToList}
      onDelete={
        hashtagGroup
          ? () =>
              hashtagGroupService
                .deleteHashtagGroup(actions)(hashtagGroup.id)
                .then(navigateBackToList)
          : undefined
      }
    />
  );
};

export default HashtagGroupWidgetContainer;
