// @flow
import type { I18nKey, Integer } from "../types";

export type PeriodicityUnit = "d" | "w" | "m" | "y";

export type Periodicity = {
  unit: PeriodicityUnit,
  quantity: Integer,
};

export type PeriodicityUnitOption = {
  value: PeriodicityUnit,
  label: I18nKey,
};

export const periodicityFromString = (value: ?string): ?Periodicity => {
  if (!value) return null;
  const unit = ((value[value.length - 1]: any): PeriodicityUnit);
  const quantity = parseInt(value.slice(0, value.length - 1));
  return { unit, quantity };
};

export const periodicityToString = (value: ?Periodicity): ?string => {
  if (!value) return null;
  return `${value.quantity}${value.unit}`;
};

export const toMomentUnit = (unit: PeriodicityUnit): string =>
  // m is minutes, not month!
  unit === "m" ? "M" : unit;

export const PERIODICITY_UNIT_OPTIONS: PeriodicityUnitOption[] = [
  { value: "d", label: "global.daily" },
  { value: "w", label: "global.weekly" },
  { value: "m", label: "global.monthly" },
  { value: "y", label: "global.yearly" },
];

export const getOptionValue = (opt: PeriodicityUnitOption): PeriodicityUnit =>
  opt.value;
export const getOptionLabel = (opt: PeriodicityUnitOption): I18nKey =>
  opt.label;
