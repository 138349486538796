// @flow
import * as React from "react";
import MimeTypeBasedPreview from "./MimeTypeBasedPreview";
import type { Props as MimeTypeBasedPreviewProps } from "./MimeTypeBasedPreview";
import { getMimetypeType } from "../../../lib/files.lib";

export type Props = {
  ...MimeTypeBasedPreviewProps,
  src: File,
};

const FilePreview: React.ComponentType<Props> = React.forwardRef(
  ({ src, ...props }, ref) => (
    <MimeTypeBasedPreview
      src={src}
      mimetypeType={getMimetypeType(src.type)}
      alt={src.name}
      ref={ref}
      {...props}
    />
  )
);

export default FilePreview;
