// @flow
import * as React from "react";
import type { I18nKey } from "../../../types";
import type { Props as DialogProps } from "./Dialog";
import Dialog from "./Dialog";
import Translate from "../display/Translate";
import { PrimaryButton } from "../inputs/buttons";
import useTranslate from "../../../hooks/useTranslate";

export type Props = {
  ...Omit<DialogProps, "children" | "text" | "actions">,
  title?: I18nKey,
  message: I18nKey | React.Node,
  yesLabel?: I18nKey,
};

const AlertDialog: React.ComponentType<Props> = ({
  message,
  title,
  onClose,
  yesLabel = "global.ok",
  ...props
}) => {
  const t = useTranslate();
  return (
    <Dialog
      onClose={onClose}
      title={title ? t(title) : undefined}
      text={typeof message === "string" ? <Translate id={message} /> : message}
      actions={
        <PrimaryButton onClick={onClose} autoFocus>
          {t(yesLabel)}
        </PrimaryButton>
      }
      {...props}
    />
  );
};

export default AlertDialog;
