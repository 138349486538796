import useInterval from "../../../hooks/useInterval";
import interval from "../../../lib/interval.lib";
import React from "react";
import { useStoreActions } from "../../../store/store";
import * as portfolioService from "../../../services/portfolio.service";
import { UserToken } from "../../../lib/session.lib";

const FOREGROUND_INTERVAL = 1 * interval.minute.as.milliseconds;

const usePortfolioSummaryBeat = () => {
  const actions = useStoreActions();
  const isTokenValid = UserToken.isValid();

  const callback = React.useCallback(() => {
    if (isTokenValid) portfolioService.getPortfolioSummaries(actions)();
  }, [actions, isTokenValid]);

  // if something has changed in callback definition. Call it now.
  React.useEffect(callback, [callback]);

  useInterval(callback, FOREGROUND_INTERVAL, true);
};

export default usePortfolioSummaryBeat;
