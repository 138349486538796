// @flow
import * as React from "react";
import InsetAdornment from "../../../../lib/layout/InsetAdornment";
import { IconButton, Tooltip } from "@mui/material";
import LocaleDateTime from "../../../../lib/display/LocaleDateTime";
import { getPostStatusIconAndColor } from "../../../../display/postStatusIcons";
import Sticker from "../../../../lib/display/Sticker";
import type { Post, PostStatus } from "../../../../../models/post.model";
import { getTime, isPublished } from "../../../../../models/post.model";
import type { Callback, Moment } from "../../../../../types";
import PostMediaThumbnail from "../../../../display/PostMediaThumbnail";
import { styled } from "@mui/material/styles";
import { CloseRounded } from "@mui/icons-material";
import type { CSSProps } from "../../../../../reactTypes";

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  userSelect: "none",
  overflow: "hidden",
  [theme.breakpoints.up("sm")]: {
    "& .visibleOnHover": {
      opacity: 0,
      transition: theme.transitions.create(["opacity"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    "&:hover .visibleOnHover": {
      opacity: 1,
    },
  },
}));

const NoEventsPostMediaThumbnail = styled(PostMediaThumbnail)({
  // Below is an absolute mandatory for videos, otherwise they will capture
  // DOM events and prevent dragging.
  pointerEvents: "none",
});

type StatusAdornmentProps = {
  status: PostStatus,
  time: ?Moment,
};

const StatusAdorment: React.ComponentType<StatusAdornmentProps> = ({
  status,
  time,
}) => {
  const [StatusIcon, color] = getPostStatusIconAndColor(status);

  return (
    <InsetAdornment
      anchorOrigin={{ vertical: "bottom" }}
      className="visibleOnHover"
    >
      <Tooltip title={time ? <LocaleDateTime value={time} /> : ""}>
        <Sticker>
          <StatusIcon color={color} size="small" />
        </Sticker>
      </Tooltip>
    </InsetAdornment>
  );
};

type Props = {
  post: Post,
  onDelete: Callback,
  onDoubleClick: Callback,
  ...CSSProps,
};

const VizGridItem: React.ComponentType<Props> = React.memo(
  ({ post, onDelete, onDoubleClick, ...props }) => {
    return (
      <Root onDoubleClick={onDoubleClick} {...props}>
        {!isPublished(post) && (
          <InsetAdornment
            className="visibleOnHover"
            anchorOrigin={{ horizontal: "left" }}
          >
            <Sticker>
              <IconButton onClick={onDelete}>
                <CloseRounded />
              </IconButton>
            </Sticker>
          </InsetAdornment>
        )}
        <StatusAdorment status={post.status} time={getTime(post)} />
        <NoEventsPostMediaThumbnail post={post} />
      </Root>
    );
  }
);

export default VizGridItem;
