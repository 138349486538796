// @flow
import type { FindIteratee, ModelID, Moment, Slug, Timestamp } from "../types";
import moment from "moment";
import { DIGITS_ONLY } from "../lib/patterns.lib";

/**
 * An object that provides a `created_at` property.
 */
export interface SupportsCreatedAt {
  /**
   * Creation time of the object.
   */
  created_at: Moment;
}

/**
 * An object that provides an `updated_at` property.
 */
export interface SupportsUpdatedAt {
  /**
   * Last update time of the object.
   */
  updated_at: Moment;
}

/**
 * An object that supports both `created_at` and `updated_at` properties.
 */
export interface SupportsCreatedUpdatedAt
  extends SupportsCreatedAt,
    SupportsUpdatedAt {}

/**
 * An identified object with an `id` property.
 */
export interface SupportsID {
  /**
   * Unique ID of the object among its type.
   */
  id: ModelID;
}

/**
 * An object with a `slug` property.
 */
export interface SupportsSlug {
  /**
   * Readable unique identifier of the object.
   */
  slug: Slug;
}

/**
 * An object with a `native_id` property.
 */
export interface SupportsNativeID {
  /**
   * native identifier of the object.
   */
  native_id: string;
}

/**
 * An object that is realted to a channel.
 */
export interface ChannelRelated {
  /**
   * The channel this object refers to.
   */
  channel: Slug;
}

/**
 * An object that is related to a portfolio.
 */
export interface PortfolioRelated {
  portfolio_id: ModelID;
}

/**
 * An object that is related to an account.
 */
export interface AccountRelated {
  account_id: ModelID;
}

/**
 * Base interface for all models of the bobcaat API.
 */
export type BaseModel = {
  /**
   * Unique ID of the object among its type.
   */
  id: ModelID,
  /**
   * Creation time of the object.
   */
  created_at: Moment,
  /**
   * Last update time of the object.
   */
  updated_at: Moment,
};

/**
 * Stands for an interval of observation [start, end] expressed with UNIX timestamps.
 */
export type UnixInterval = {
  /** The beginning of the interval, included. */
  start: Timestamp,
  /** The end of the interval, included. */
  end: Timestamp,
};

/**
 * Stands for an interval of observation [start, end] expressed with moments.
 */
export type MomentInterval = {
  /** The beginning of the interval, included. */
  start: Moment,
  /** The end of the interval, included. */
  end: Moment,
};

/** A string representing a media sent to the server. */
export opaque type MediaRef = string;

/**
 * Get the ID of a model.
 */
export const getId = (model: SupportsID): ModelID => model.id;

/**
 * Get the ID of a model, as a string
 */
export const getIdAsString = (model: SupportsID): string => model.id.toString();

/**
 * Create an iteratee that returns true if an element has the specified
 * value for a property.
 */
export const byProp =
  <T: Object>(prop: $Keys<T>, value: any): FindIteratee<T> =>
  (iteratee) =>
    iteratee[prop] === value;

/**
 * Create an iteratee that returns true if an element has a truthy value
 * for the specified attribute.
 */
export const byTruthyProp =
  <T: Object>(prop: $Keys<T>): FindIteratee<T> =>
  (iteratee) =>
    !!iteratee[prop];

/**
 * Create an iteratee that returns true if an element has a falsy value
 * for the specified attribute.
 */
export const byFalsyProp =
  <T: Object>(prop: $Keys<T>): FindIteratee<T> =>
  (iteratee) =>
    !iteratee[prop];

/**
 * Create an iteratee that returns true if an element has the specified
 * value for a property, or null / undefined.
 */
export const byMaybeProp =
  <T: Object>(prop: $Keys<T>, value: T[$Keys<T>]): FindIteratee<T> =>
  (iteratee) => {
    const propValue = iteratee[prop];
    return propValue === undefined || propValue === null || propValue === value;
  };

/**
 * Return an iteratee to select an object by its id.
 */
export const byId = <T: SupportsID>(id: ModelID): FindIteratee<T> =>
  byProp<T>("id", id);

/**
 * Return an iteratee to select an object by its id from a list
 */
export const byIds =
  <T: SupportsID>(ids: ModelID[]): FindIteratee<T> =>
  (iteratee) =>
    ids.includes(iteratee.id);

/**
 * Return an iteratee to select an object by its slug.
 */
export const bySlug = <T: SupportsSlug>(slug: Slug): FindIteratee<T> =>
  byProp<T>("slug", slug);

/**
 * Return an iteratee to select an object by its channel.
 */
export const byChannel = <T: ChannelRelated>(channel: Slug): FindIteratee<T> =>
  byProp<T>("channel", channel);

/**
 * Return an iteratee to select an object by its channel.
 */
export const byNativeID = <T: SupportsNativeID>(
  nativeID: string
): FindIteratee<T> => byProp<T>("native_id", nativeID);

/**
 * Return an iteratee to select an object by its portfolio ID.
 */
export const byPortfolioId = <T: PortfolioRelated>(
  portfolio_id: ?ModelID
): FindIteratee<T> => byProp<T>("portfolio_id", portfolio_id);

/**
 * Return an iteratee to select an object by its account ID.
 */
export const byAccountId = <T: AccountRelated>(
  account_id: ModelID
): FindIteratee<T> => byProp<T>("account_id", account_id);

export const unixToMomentInterval = (
  interval: UnixInterval
): MomentInterval => ({
  start: moment.unix(interval.start),
  end: moment.unix(interval.end),
});

/**
 * Parse a maybe string into a model ID and verifies the value.
 */
export const parseModelId = (id: ?string): ?ModelID => {
  if (id === null || id === undefined) return undefined;

  // check if it is a valid number.
  if (DIGITS_ONLY.test(id)) {
    return Number(id);
  }

  // check if it is an hexadecimal number.
  const maybeHexa = Number.parseInt(id, 16);
  if (isFinite(maybeHexa) && maybeHexa > 0) return maybeHexa;

  return undefined;
};

export const byUpdatedAt = (o: SupportsUpdatedAt): Timestamp =>
  o.updated_at.unix();
