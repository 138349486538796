// @flow
import * as React from "react";
import type { SelectPlanWidgetFormDef } from "./SelectPlanWidget";
import SelectPlanWidget from "./SelectPlanWidget";
import {
  useCurrentSubscription,
  usePlanFlavours,
  useSubscriptionLocked,
} from "../../../store/selectors";
import { FormActions, useForm } from "../../../hooks/useForm";
import { DEFAULT_CURRENCY } from "../../../config/currencies.config";
import { planFlavourByPlanAndCurrency } from "../../../config/plans.config";
import type { Plan, PlanFlavour } from "../../../models/plan.model";
import type { ModelID } from "../../../types";
import { cast } from "../../../types";

const FORM_FIELDS: SelectPlanWidgetFormDef = {
  currency: {
    type: "string",
  },
};

type Props = {
  onSelect: (PlanFlavour) => any,
  selectedFlavourId?: ?ModelID,
};

const SelectPlanWidgetContainer: React.ComponentType<Props> = ({
  selectedFlavourId,
  onSelect,
}) => {
  const planFlavours = usePlanFlavours();
  const subscription = useCurrentSubscription();
  const locked = useSubscriptionLocked();

  const initial = React.useMemo(
    () => ({
      currency: subscription?.plan.currency ?? DEFAULT_CURRENCY.name,
    }),
    [subscription?.plan]
  );

  const form = useForm(FORM_FIELDS, initial);

  React.useEffect(() => {
    FormActions.reset(form.set, initial);
  }, [form.set, initial]);

  const handleSelect = (plan: Plan) =>
    onSelect(
      cast<PlanFlavour>(
        planFlavours.find(
          planFlavourByPlanAndCurrency(plan.slug, form.state.currency.value)
        )
      )
    );

  return (
    <SelectPlanWidget
      locked={locked}
      noCurrencyChange={!!subscription}
      selectedFlavourId={selectedFlavourId}
      subscriptionFlavourId={subscription?.plan.id}
      planFlavours={planFlavours}
      form={form}
      onConfirm={handleSelect}
    />
  );
};

export default SelectPlanWidgetContainer;
