// @flow
import * as React from "react";
import type { Props as MessageLayoutProps } from "../../../MessageLayout";
import MessageLayout from "../../../MessageLayout";
import type { Portfolio } from "../../../../../../models/portfolio.model";
import type { PostComment } from "../../../../../../models/postComment.model";
import { PortfolioAvatar } from "../../../../../display/avatars";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import useMessageColor from "../../../useMessageColor";

type Props = {
  portfolio: Portfolio,
  comment: PostComment,
  ...Pick<MessageLayoutProps, "fromUser" | "children" | "secondaryAction">,
};

const Comment: React.ComponentType<Props> = ({
  portfolio,
  fromUser,
  comment,
  ...props
}) => {
  const isMobile = useIsMobile();
  const color = useMessageColor(portfolio);
  return (
    <MessageLayout
      authorName={comment.author_username}
      createdAt={comment.created_at}
      avatar={
        fromUser ? (
          <PortfolioAvatar size={isMobile ? 32 : 48} portfolio={portfolio} />
        ) : undefined
      }
      fromUser={fromUser}
      indent={!!comment.parent_id ? 1 : 0}
      color={color}
      content={comment.message}
      {...props}
    />
  );
};

export default Comment;
