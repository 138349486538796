// @flow
import * as React from "react";
import { Body1 } from "../../lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import type { CurrencyCode, ModelID } from "../../../types";
import type { AddOn } from "../../../models/addOn.model";
import AddOnItem from "../../display/AddOnItem";

type Props = {
  currency: CurrencyCode,
  locked?: boolean,
  addOns: AddOn[],
  onPurchase: (ModelID) => any,
  onCancel: (ModelID) => any,
};

const AddOnsBlock = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ManageAddOnsWidget: React.ComponentType<Props> = ({
  currency,
  locked,
  addOns,
  onPurchase,
  onCancel,
}) => {
  const t = useTranslate();
  return (
    <div>
      <Body1 color="textSecondary" paragraph>
        {t("Page.SubscriptionPanel.addOnsDescription")}
      </Body1>
      <AddOnsBlock>
        {addOns.map((addOn) => (
          <AddOnItem
            key={addOn.id}
            addOn={addOn}
            onCancel={onCancel}
            locked={locked}
            onPurchase={onPurchase}
            currency={currency}
          />
        ))}
      </AddOnsBlock>
    </div>
  );
};

export default ManageAddOnsWidget;
