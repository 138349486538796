import * as React from "react";
import PostBuilderContext from "../../contexts/postBuilder";
import { isAttachmentVideo } from "../../models/attachment.model";

const useVideoAttachments = () => {
  const {
    post: { attachments },
  } = React.useContext(PostBuilderContext);
  return React.useMemo(
    () => attachments.filter(isAttachmentVideo),
    [attachments]
  );
};

export default useVideoAttachments;
