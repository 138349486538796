// @flow
import * as React from "react";
import { LargePrimaryButton } from "../../../components/lib/inputs/buttons";
import { getFirstDiagnosticErrorDescription } from "../../../util/diagnostics.util";
import usePublishHandler from "../../../hooks/postCreator/usePublishHandler";
import useTranslate from "../../../hooks/useTranslate";
import { usePostLock } from "../../../store/selectors";
import PostBuilderContext from "../../../contexts/postBuilder";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import routes, { getRoutePath } from "../../../config/routes.config";
import { useScopedPortfolio } from "../../../scopes/scopes";
import usePostDiagnostic from "../../../hooks/postCreator/usePostDiagnostic";

const PublishButton: React.ComponentType<{}> = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const pf = useScopedPortfolio();
  const onPublish = usePublishHandler();
  const locked = usePostLock();
  const {
    post: { schedule_time, account_id },
    channel,
  } = React.useContext(PostBuilderContext);
  const postDiagnostics = usePostDiagnostic(channel);

  return (
    <LargePrimaryButton
      fullWidth
      loading={locked}
      onClick={() =>
        onPublish(() =>
          navigate(
            getRoutePath(routes.app.nested.portfolios.nested.creator, {
              portfolioId: pf?.id,
            })
          )
        )
      }
      disabled={
        !account_id ||
        !!getFirstDiagnosticErrorDescription(postDiagnostics) ||
        schedule_time?.isBefore(moment())
      }
    >
      {t("global.go")}
    </LargePrimaryButton>
  );
};

export default PublishButton;
