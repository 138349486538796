// @flow
import * as React from "react";
import Avatar from "../lib/display/Avatar";
import type { Props as AvatarProps } from "../lib/display/Avatar";
import type { PortfolioDisplay } from "../../models/portfolio.model";
import type { UserDisplay } from "../../models/user.model";
import type { Conversation } from "../../models/conversation.model";
import { getInitials } from "../../models/user.model";
import { initials } from "../../lib/lodashex.lib";
import type { Inexact } from "../../types";

export type PortfolioAvatarProps = {
  ...AvatarProps,
  portfolio: ?Inexact<PortfolioDisplay>,
};

export type UserAvatarProps = {
  ...AvatarProps,
  user: ?Inexact<UserDisplay>,
};

export type ConversationAvatarProps = {
  ...AvatarProps,
  conversation: ?Conversation,
};

export const PortfolioAvatar: React.ComponentType<PortfolioAvatarProps> =
  React.memo(({ portfolio, ...props }) => (
    <Avatar
      src={portfolio?.avatar}
      bgColor={portfolio?.color}
      variant="rounded"
      {...props}
    >
      {portfolio?.initials}
    </Avatar>
  ));

export const UserAvatar: React.ComponentType<UserAvatarProps> = React.memo(
  ({ user, ...props }) => (
    <Avatar src={user?.avatar ?? undefined} variant="circular" {...props}>
      {user && getInitials(user)}
    </Avatar>
  )
);

export const ConversationAvatar: React.ComponentType<ConversationAvatarProps> =
  React.memo(({ conversation, ...props }) => {
    const interlocutor = conversation?.interlocutors[0];
    return (
      <Avatar src={interlocutor?.avatar ?? undefined} {...props}>
        {interlocutor?.name && initials(interlocutor.name)}
      </Avatar>
    );
  });
