// @flow
import * as React from "react";
import {
  patchItem,
  removeItemBy,
  replaceItem,
} from "../../../lib/lodashex.lib";
import type { Dispatcher, KeyBase, Reducer } from "./types";
import type { FindIteratee, Hasher } from "../../../types";
import { negate } from "lodash/function";
import sortBy from "lodash/sortBy";

export type SequenceState<TItem> = TItem[];
type AppendAction<TItem> = { type: "APPEND", data: TItem };
type PrependAction<TItem> = { type: "PREPEND", data: TItem };
type ExtendAction<TItem> = { type: "EXTEND", data: TItem[] };
type ExtendOrReplaceAction<TItem> = {
  type: "EXTEND_OR_REPLACE",
  data: TItem[],
};
type LeftExtendAction<TItem> = { type: "LEFT_EXTEND", data: TItem[] };
type PatchAction<TItem> = {
  type: "PATCH",
  data: { key: KeyBase, updates: Partial<TItem> },
};
type PatchAllAction<TItem> = { type: "PATCH_ALL", data: Partial<TItem> };
type ReplaceAllAction<TItem> = { type: "REPLACE_ALL", data: TItem[] };
type RemoveAction = { type: "REMOVE", data: KeyBase };
type RemoveByAction<TItem> = { type: "REMOVE_BY", data: FindIteratee<TItem> };
type ReplaceAction<TItem> = { type: "REPLACE", data: TItem };
type SortAction<TItem> = { type: "SORT", data: Hasher<TItem> };
type AppendOrReplaceAction<TItem> = { type: "APPEND_OR_REPLACE", data: TItem };
type ClearAction = { type: "CLEAR" };

/**
 * Actions supported by a Sequence reducer.
 */
export type SequenceAction<TItem> =
  | AppendAction<TItem>
  | PrependAction<TItem>
  | ExtendAction<TItem>
  | ExtendOrReplaceAction<TItem>
  | PatchAction<TItem>
  | PatchAllAction<TItem>
  | LeftExtendAction<TItem>
  | ReplaceAllAction<TItem>
  | RemoveAction
  | RemoveByAction<TItem>
  | ReplaceAction<TItem>
  | SortAction<TItem>
  | AppendOrReplaceAction<TItem>
  | ClearAction;

export type SequenceActions<TItem> = {
  /**
   * Append an item to the sequence.
   * @param item
   */
  append: (item: TItem) => void,
  /**
   * Prepend an item to the sequence.
   * @param item
   */
  prepend: (item: TItem) => void,
  /**
   * Append the item to or replace it in the sequence.
   * @param item
   */
  appendOrReplace: (item: TItem) => void,
  /**
   * Update an item partially, using its key.
   * @param item
   */
  patch: (key: KeyBase, updates: Partial<TItem>) => void,
  /**
   * Update all items partially.
   * @param item
   */
  patchAll: (updates: Partial<TItem>) => void,
  /**
   * Remove all items from the sequence.
   */
  clear: () => void,
  /**
   * Add a sequence of items at the end of the sequence.
   * @param items
   */
  extend: (items: TItem[]) => void,
  /**
   * Add a sequence of items at the end of the sequence, or replace
   *existing items.
   * @param items
   */
  extendOrReplace: (items: TItem[]) => void,
  /**
   * Add a sequence of items at the beginning of the sequence.
   * @param items
   */
  leftExtend: (items: TItem[]) => void,
  /**
   * Remove the item whose key is provided, using the reducer key config.
   * @param key
   */
  remove: (key: KeyBase) => void,
  /**
   * Remove all items that satisfy the provided condition.
   * @param cond
   */
  removeBy: (cond: FindIteratee<TItem>) => void,
  /**
   * Replace the item whose key is identical in the sequence.
   * @param item
   */
  replace: (item: TItem) => void,
  /**
   * Replace the entire content of the sequence with the provided items.
   * @param items
   */
  replaceAll: (items: TItem[]) => void,
  /**
   * Sort the array using a hasher to get a key.
   * @param comparer
   */
  sort: (hasher: Hasher<TItem>) => void,
};

/**
 * Factories to create Sequence actions.
 *
 * @template TItem The type of the items in the sequence.
 */
export const makeSequenceActions = <TItem: Object>(
  dispatch: (SequenceAction<TItem>) => void
): SequenceActions<TItem> => ({
  append: (item: TItem) => dispatch({ type: "APPEND", data: item }),
  prepend: (item: TItem) => dispatch({ type: "PREPEND", data: item }),
  patch: (key: KeyBase, updates: Partial<TItem>) =>
    dispatch({ type: "PATCH", data: { key, updates } }),
  patchAll: (updates: Partial<TItem>) =>
    dispatch({ type: "PATCH_ALL", data: updates }),
  extend: (items: TItem[]) => dispatch({ type: "EXTEND", data: items }),
  extendOrReplace: (items: TItem[]) =>
    dispatch({ type: "EXTEND_OR_REPLACE", data: items }),
  leftExtend: (items: TItem[]) =>
    dispatch({ type: "LEFT_EXTEND", data: items }),
  remove: (key: KeyBase) => dispatch({ type: "REMOVE", data: key }),
  removeBy: (cond: FindIteratee<TItem>) =>
    dispatch({ type: "REMOVE_BY", data: cond }),
  replace: (item: TItem) => dispatch({ type: "REPLACE", data: item }),
  sort: (hasher: Hasher<TItem>) => dispatch({ type: "SORT", data: hasher }),
  appendOrReplace: (item: TItem) =>
    dispatch({ type: "APPEND_OR_REPLACE", data: item }),
  replaceAll: (items: TItem[]) =>
    dispatch({ type: "REPLACE_ALL", data: items }),
  clear: () => dispatch({ type: "CLEAR" }),
});

interface Props<TItem> {
  key: $Keys<TItem>;
  sortKey?: $Keys<TItem>;
  transform?: (item: TItem) => TItem;
}

export type SequenceReducer<TItem> = Reducer<
  SequenceState<TItem>,
  SequenceAction<TItem>
>;

/**
 * Create a sequence reducer.
 *
 * @template TItem The type of the items in the sequence.
 * @param params Sequence reducer parameters.
 * @param params.key The resource main key name.
 * @param params.sortKey The key used to sort items. Optional.
 * @param [params.transform] A transformer to apply on all loaded items.
 */
export const sequenceReducer = <TItem: Object>({
  key,
  sortKey,
  transform,
}: Props<TItem>): SequenceReducer<TItem> => {
  const getKey = (item: TItem) => item[key];
  const byKey = (itemKey: KeyBase) => (item: TItem) => getKey(item) === itemKey;
  const byItemKey = (item: TItem) => byKey(getKey(item));

  const ensureOrder = sortKey
    ? (state: SequenceState<TItem>): SequenceState<TItem> =>
        sortBy(state, sortKey)
    : (state: SequenceState<TItem>): SequenceState<TItem> => state;

  return (state: SequenceState<TItem>, action: SequenceAction<TItem>) => {
    switch (action.type) {
      case "APPEND": {
        action = (action: AppendAction<TItem>);
        const item = transform ? transform(action.data) : action.data;
        return ensureOrder([...state, item]);
      }

      case "PREPEND": {
        action = (action: PrependAction<TItem>);
        const item = transform ? transform(action.data) : action.data;
        return ensureOrder([item, ...state]);
      }

      case "PATCH": {
        action = (action: PatchAction<TItem>);
        return ensureOrder(
          patchItem(state, byKey(action.data.key), action.data.updates)
        );
      }

      case "PATCH_ALL": {
        const updates = (action: PatchAllAction<TItem>).data;
        return state.map((item) => ({ ...item, ...updates }));
      }

      case "EXTEND":
        action = (action: ExtendAction<TItem>);
        const items = transform ? action.data.map(transform) : action.data;
        return ensureOrder([...state, ...items]);

      case "EXTEND_OR_REPLACE": {
        action = (action: ExtendOrReplaceAction<TItem>);
        const items = transform ? action.data.map(transform) : action.data;
        return ensureOrder(
          items.reduce(
            (newState: SequenceState<TItem>, item: TItem) => {
              const idx = newState.findIndex(byKey(item));
              if (idx >= 0) {
                newState[idx] = item;
              } else {
                newState.push(item);
              }
              return newState;
            },
            [...state]
          )
        );
      }

      case "LEFT_EXTEND": {
        action = (action: LeftExtendAction<TItem>);
        const items = transform ? action.data.map(transform) : action.data;
        return ensureOrder([...items, ...state]);
      }

      case "REMOVE":
        return removeItemBy(state, byKey((action: RemoveAction).data));

      case "REMOVE_BY":
        return state.filter(negate((action: RemoveByAction<TItem>).data));

      case "REPLACE": {
        action = (action: ReplaceAction<TItem>);
        const item = transform ? transform(action.data) : action.data;
        return ensureOrder(replaceItem(state, byItemKey(item), item));
      }

      case "APPEND_OR_REPLACE": {
        action = (action: AppendOrReplaceAction<TItem>);
        const item = transform ? transform(action.data) : action.data;
        return ensureOrder(replaceItem(state, byItemKey(item), item, true));
      }

      case "REPLACE_ALL":
        action = (action: ReplaceAllAction<TItem>);
        return ensureOrder(
          transform ? action.data.map(transform) : action.data
        );

      case "CLEAR":
        return [];

      case "SORT":
        return sortBy(state, action.data);

      default:
        return state;
    }
  };
};

/**
 * Typed wrapper around React native useReducer.
 */
export const useSequenceReducer = <TItem: Object>(
  reducer: SequenceReducer<TItem>
): [SequenceState<TItem>, Dispatcher<SequenceAction<TItem>>] =>
  React.useReducer<SequenceState<TItem>, SequenceAction<TItem>>(reducer, []);
