// @flow
import * as React from "react";
import type { BrowserRoute } from "../../config/routes.config";
import { styled } from "@mui/material/styles";
import { PrimaryPageHeader, SecondaryPageHeader } from "./PageHeader";
import type { Children, CSSProps } from "../../reactTypes";
import { Slide } from "@mui/material";
import { propMap } from "../lib/utils/props";
import type { Callback } from "../../types";
import type { ThemeColor } from "../../stubs/mui/theming";
import useIsSmallOrSmaller from "../../hooks/useIsSmallOrSmaller";
import noop from "lodash/noop";
import SwipeableEdgeDrawer from "../lib/navigation/SwipeableEdgeDrawer";
import useSwitch from "../../hooks/useSwitch";

type Size = "single" | "fill" | "dense" | "large";
export const NO_XS_HORIZ_MARGIN = "xs-no-h-mg";

const getSize = propMap(
  { dense: 560, single: 600, large: 800, fill: "100%" },
  "single"
);

const getBackgroundProp = propMap(
  { primary: "alt", secondary: "default" },
  "primary"
);

const Root = styled("div", {
  shouldForwardProp: (prop) => !["variant", "color"].includes(prop),
})(({ theme, variant, color }) => ({
  backgroundColor:
    theme.palette.background[color ?? getBackgroundProp(variant)],
  flexGrow: variant === "primary" ? 1 : 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
}));

const Container = styled("div", {
  shouldForwardProp: (prop) => !["align", "size"].includes(prop),
})(({ size, align, theme }) => ({
  maxWidth: getSize(size),
  margin: align === "flex-start" ? undefined : "auto",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(2),
  padding: theme.spacing(0, 4, 2, 4),
  position: "relative",
  overflow: "auto",
  transition: "padding 0.3s ease",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(0, 3, 2, 3),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1, 0, 0, 0),
    // On mobile, use a margin on all direct children, as long
    // as they are not preventively tagged.
    [`& > *:not(.${NO_XS_HORIZ_MARGIN})`]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

type PagePanelLayoutProps = {
  variant: "primary" | "secondary",
  align?: "flex-start" | "center",
  color?: ThemeColor | "alt" | "default",
  size?: Size,
  absoluteHeader?: boolean,
  children?: Children,
  header?: React.Node,
  footer?: React.Node,
  ...CSSProps,
};

export const PagePanel: React.ComponentType<PagePanelLayoutProps> =
  React.forwardRef(
    (
      { size, children, header, footer, align, absoluteHeader, ...props },
      ref
    ) => (
      <Root ref={ref} align={align} {...props}>
        {!absoluteHeader && header}
        <Container size={size} align={align}>
          {absoluteHeader &&
            // put header here if it is absolute, so that it scrolls with
            // the container
            header}
          {children}
        </Container>
        {footer}
      </Root>
    )
  );

type PrimaryPagePanelProps = {
  ...Pick<
    PagePanelLayoutProps,
    "size" | "children" | "align" | "style" | "className"
  >,
  subtitleData?: Object,
  titleData?: Object,
  isHome?: boolean,
  route: BrowserRoute,
};

type SecondaryPagePanelProps = {
  ...Omit<PrimaryPagePanelProps, "isHome">,
  onClose?: Callback,
  absoluteHeader?: boolean,
  /**
   * Content ID is used on small devices to force the drawer to reopen
   * when the target has changed.
   */
  contentKey?: string | number,
};
export const PrimaryPagePanel: React.ComponentType<PrimaryPagePanelProps> = ({
  route,
  children,
  subtitleData,
  titleData,
  isHome,
  ...layoutProps
}) => (
  <PagePanel
    variant="primary"
    header={
      <PrimaryPageHeader
        isHome={isHome}
        title={route.label ?? ""}
        titleData={titleData}
        subtitle={route.subtitle}
        subtitleData={subtitleData}
        prevPath={route.prevPath}
      />
    }
    {...layoutProps}
  >
    {children}
  </PagePanel>
);

const SecondaryPagePanelContent: React.ComponentType<SecondaryPagePanelProps> =
  React.forwardRef(
    (
      {
        route,
        children,
        onClose,
        absoluteHeader,
        titleData,
        subtitleData,
        contentKey,
        ...layoutProps
      },
      ref
    ) => (
      <PagePanel
        ref={ref}
        variant="secondary"
        header={
          <SecondaryPageHeader
            title={route.label ?? ""}
            subtitle={route.subtitle}
            subtitleData={subtitleData}
            titleData={titleData}
            onClose={onClose}
            absoluteHeader={absoluteHeader}
          />
        }
        {...layoutProps}
      >
        {children}
      </PagePanel>
    )
  );

const MediumUpSecondaryPagePanel: React.ComponentType<SecondaryPagePanelProps> =
  (props) => (
    <Slide direction="left" in>
      <SecondaryPagePanelContent {...props} />
    </Slide>
  );

const SmallDownSecondaryPagePanel: React.ComponentType<SecondaryPagePanelProps> =
  ({ onClose = noop, contentKey, ...props }) => {
    const [expanded, expand, collapse] = useSwitch(false);

    // Open the drawer when mounted or when the content key changes.
    React.useEffect(() => {
      setTimeout(expand, 50);
    }, [expand, contentKey]);

    // Animate the exit.
    const handleClose = React.useCallback(() => {
      collapse();
      setTimeout(onClose, 200);
    }, [collapse, onClose]);

    return (
      <SwipeableEdgeDrawer
        open={expanded}
        onClose={collapse}
        onOpen={expand}
        color="default"
      >
        <SecondaryPagePanelContent onClose={handleClose} {...props} />
      </SwipeableEdgeDrawer>
    );
  };

export const SecondaryPagePanel: React.ComponentType<SecondaryPagePanelProps> =
  (props) =>
    useIsSmallOrSmaller() ? (
      <SmallDownSecondaryPagePanel {...props} />
    ) : (
      <MediumUpSecondaryPagePanel {...props} />
    );
