// @flow
import * as React from "react";
import { PostCreatorCollapsibleSection } from "../../layout/PostCreatorSection";
import { Collapse, TextField, Tooltip } from "@mui/material";
import PostBuilderContext from "../../../contexts/postBuilder";
import LinkPreview from "../../lib/display/LinkPreview";
import { styled } from "@mui/material/styles";
import useLinkPreview from "../../../pages/AppPage/hooks/useLinkPreview";
import { ColumnStack } from "../../lib/layout/stacks";
import ImagePicker from "../../lib/inputs/pickers/ImagePicker";
import { fileToImageDataURL } from "../../../lib/images.lib";
import useTranslate from "../../../hooks/useTranslate";

const LinkPreviewBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "canEdit",
})(({ canEdit }) => ({
  cursor: canEdit ? "pointer" : undefined,
  maxWidth: 400,
  margin: "auto",
}));

const PostLinkWidget: React.ComponentType<{}> = () => {
  const {
    post: { link: maybeLink, attachments, thumbnail_title, thumbnail_url, id },
    onUpdatePost,
    channel,
  } = React.useContext(PostBuilderContext);
  const t = useTranslate();
  const link = maybeLink ?? "";
  const canEdit = !!channel?.features?.content?.thumbnail?.link;
  const [preview, requestPreview] = useLinkPreview({
    image: thumbnail_url,
    title: thumbnail_title,
  });

  React.useEffect(() => {
    if (link.length > 0) {
      requestPreview(link);
    }
  }, [link, requestPreview]);

  const handleUpdateImage = React.useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        fileToImageDataURL(files[0]).then((thumbnail_url) =>
          onUpdatePost({ thumbnail_url })
        );
      }
    },
    [onUpdatePost]
  );

  return (
    <Collapse
      in={!!link && attachments.length === 0}
      mountOnEnter
      unmountOnExit
    >
      <PostCreatorCollapsibleSection
        title="global.link"
        defaultCollapsed={!!id}
      >
        <ColumnStack>
          <ImagePicker disabled={!canEdit} onSelect={handleUpdateImage}>
            <Tooltip
              title={t("PostLinkWidget.linkPreviewTooltip")}
              followCursor
            >
              <LinkPreviewBox canEdit={canEdit}>
                <LinkPreview link={link} preview={preview} />
              </LinkPreviewBox>
            </Tooltip>
          </ImagePicker>
          {canEdit && (
            <TextField
              label={t("PostLinkWidget.linkTitle")}
              value={preview.title || ""}
              onChange={(e) =>
                onUpdatePost({ thumbnail_title: e.target.value })
              }
            />
          )}
        </ColumnStack>
      </PostCreatorCollapsibleSection>
    </Collapse>
  );
};

export default PostLinkWidget;
