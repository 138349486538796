// @flow
import type { Amount, CurrencyCode } from "../types";
import type { PlanFlavour } from "../models/plan.model";

export type Currency = {
  name: CurrencyCode,
  symbol: string,
  prepend: boolean,
};

const currencies: Currency[] = [
  { name: "GBP", symbol: "£", prepend: true },
  { name: "EUR", symbol: "€", prepend: false },
  { name: "USD", symbol: "$", prepend: true },
];

const prependCurrency = (price: Amount, symbol: string) => `${symbol}${price}`;
const appendCurrency = (price: Amount, symbol: string) => `${price}${symbol}`;

const _formats: Record<CurrencyCode, (Amount) => string> = {};

currencies.forEach(({ name, symbol, prepend }) => {
  const strFormat = prepend ? prependCurrency : appendCurrency;
  _formats[name] = (price) => strFormat(price, symbol);
});

export const formatPrice = (currency: CurrencyCode, price: Amount): string =>
  price && currency ? _formats[currency](price) : "";

export const formatPlanPrice = (planFlavour: PlanFlavour): string =>
  formatPrice(planFlavour?.currency, planFlavour?.monthly_price);

export const DEFAULT_CURRENCY = currencies[0];

export default currencies;
