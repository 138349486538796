// @flow
import * as React from "react";
import type { CSSSize } from "../../../types";
import { styled } from "@mui/material/styles";
import { getPixelSize } from "../../../util/size.util";

type BoxProps = React.ElementProps<"div">;
type ColorBoxProps = {
  ...BoxProps,
  width: CSSSize,
  height: CSSSize,
  color: string,
};
type BiColorBoxProps = {
  ...ColorBoxProps,
  color2: string,
};

export const ColorBox: React.ComponentType<ColorBoxProps> = styled("div")(
  ({ theme, width, height, color }) => `
    width: ${getPixelSize(width)};
    height: ${getPixelSize(height)};
    border-radius: 8px;
    background-color: ${color};
    border: solid 1px ${theme.palette.divider};
    margin-right: 4px;
    display: inline-block;
    transition: background-color ease 0.3s;
  `
);

const BiColorBoxRoot = styled("div")(({ width, height }) => ({
  width: getPixelSize(width),
  height: getPixelSize(height),
  display: "inline-block",
  position: "relative",
  marginRight: 4,
  "& > div:first-of-type": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: 0,
    width: "50%",
    height: "100%",
  },
  "& > div:last-of-type": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginRight: 0,
    width: "50%",
    height: "100%",
  },
}));

export const BiColorBox: React.ComponentType<BiColorBoxProps> = ({
  color,
  color2,
  width,
  height,
  ...props
}) => (
  <BiColorBoxRoot width={width} height={height}>
    <ColorBox {...props} color={color} />
    <ColorBox {...props} color={color2} />
  </BiColorBoxRoot>
);
