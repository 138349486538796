// @flow
import * as React from "react";
import moment from "moment";
import identity from "lodash/identity";
import toPairs from "lodash/toPairs";
import interval from "../../../lib/interval.lib";
import useInterval from "../../../hooks/useInterval";
import { useStoreActions } from "../../../store/store";
import { useCurrentUser } from "../../../store/selectors";
import type { Moment } from "../../../types";
import type { StoreActions } from "../../../store/store";
import type { RefLike } from "../../../reactTypes";
import type { User } from "../../../models/user.model";
import * as engagementService from "../../../services/engagement.service";
import * as messagingService from "../../../services/messaging.service";
import * as globalService from "../../../services/global.service";
import { TrackingContext } from "../../../contexts/tracking";
import type { OnBeat } from "../../../contexts/tracking";
import type { PostComment } from "../../../models/postComment.model";
import type { Conversation } from "../../../models/conversation.model";
import { UserToken } from "../../../lib/session.lib";
import * as userModel from "../../../models/user.model";
import * as accountModel from "../../../models/account.model";
import * as postModel from "../../../models/post.model";
import * as notificationModel from "../../../models/notification.model";
import * as postCommentModel from "../../../models/postComment.model";
import * as conversationModel from "../../../models/conversation.model";
import * as approvalRequestModel from "../../../models/approvalRequest.model";
import noop from "lodash/noop";

const FOREGROUND_INTERVAL = 5 * interval.second.as.milliseconds;
const BACKGROUND_INTERVAL = 10 * interval.minute.as.seconds;
const CATCHUP_WINDOW = 1 * interval.hour.as.seconds;

const BEAT_STRATEGY = {
  user: {
    fromAPIResult: userModel.fromAPIResult,
    updated: (storeActions: StoreActions) => storeActions.profile.load,
  },
  portfolio: {
    created: (storeActions: StoreActions) =>
      storeActions.portfolio.appendOrReplace,
    updated: (storeActions: StoreActions) => storeActions.portfolio.replace,
    deleted: (storeActions: StoreActions) => storeActions.portfolio.remove,
  },
  account: {
    fromAPIResult: accountModel.fromAPIResult,
    created: (storeActions: StoreActions) =>
      storeActions.account.appendOrReplace,
    updated: (storeActions: StoreActions) => storeActions.account.replace,
    deleted: (storeActions: StoreActions) => storeActions.account.remove,
  },
  post: {
    fromAPIResult: postModel.fromAPIResult,
    created: (storeActions: StoreActions) =>
      storeActions.post.posts.appendOrReplace,
    updated: (storeActions: StoreActions) => storeActions.post.posts.replace,
    deleted: (storeActions: StoreActions) => storeActions.post.posts.remove,
  },
  notification: {
    fromAPIResult: notificationModel.fromAPIResult,
    created: (storeActions: StoreActions) =>
      storeActions.notification.appendOrReplace,
    updated: (storeActions: StoreActions) => storeActions.notification.replace,
    deleted: (storeActions: StoreActions) => storeActions.notification.remove,
  },
  hashtag_group: {
    created: (storeActions: StoreActions) =>
      storeActions.hashtagGroup.appendOrReplace,
    updated: (storeActions: StoreActions) => storeActions.hashtagGroup.replace,
    deleted: (storeActions: StoreActions) => storeActions.hashtagGroup.remove,
  },
  post_comment: {
    fromAPIResult: postCommentModel.fromAPIResult,
    created: (storeActions: StoreActions) => (comment: PostComment) =>
      engagementService.getPostSocialActionSummary(storeActions)(
        comment.post_id
      ),
    updated: (storeActions: StoreActions) => (comment: PostComment) =>
      engagementService.getPostSocialActionSummary(storeActions)(
        comment.post_id
      ),
    // deleted: in case of delete, do nothing: A deleted comment will not be
    // unread and no one care of the summary for that.
    deleted: noop,
  },
  approval_request: {
    fromAPIResult: approvalRequestModel.fromAPIResult,
    created: (storeActions: StoreActions) =>
      storeActions.approvalRequest.approvalRequests.appendOrReplace,
    updated: (storeActions: StoreActions) =>
      storeActions.approvalRequest.approvalRequests.replace,
    deleted: (storeActions: StoreActions) =>
      storeActions.approvalRequest.approvalRequests.remove,
  },
  conversation: {
    fromAPIResult: conversationModel.fromAPIResult,
    created: (storeActions: StoreActions) => (conversation: Conversation) => {
      storeActions.messaging.conversations.appendOrReplace(conversation);
      messagingService.getConversationSummary(storeActions)(conversation.id);
    },
    updated: (storeActions: StoreActions) => (conversation: Conversation) => {
      storeActions.messaging.conversations.replace(conversation);
      messagingService.getConversationSummary(storeActions)(conversation.id);
    },
  },
};
const RESOURCES = Object.keys(BEAT_STRATEGY);

const checkForUpdates = (
  user: User,
  storeActions: StoreActions,
  lastBeatRef: RefLike<Moment>,
  onBeat: OnBeat
) => {
  const newBeat = moment();
  const secondsSinceLastBeat = newBeat.diff(lastBeatRef.current, "second");

  // In background, sleep longer.
  if (!document.hasFocus() && secondsSinceLastBeat < BACKGROUND_INTERVAL) {
    return;
  }

  // No token, nothing to beat.
  if (!UserToken.isValid()) {
    return;
  }

  // If it has been too long... Refresh all.
  if (secondsSinceLastBeat > CATCHUP_WINDOW) {
    globalService.reload(storeActions)(user);
    lastBeatRef.current = newBeat;
    return;
  }
  console.log("beat.");

  onBeat(lastBeatRef.current, (changes) => {
    lastBeatRef.current = newBeat;

    // Keep only affected sources from the response.
    // Map each event objects to their handler.
    RESOURCES.forEach((resource) => {
      const resourceChanges = changes[resource];
      if (resourceChanges) {
        const { fromAPIResult, ...resourceChangeHandlers } =
          BEAT_STRATEGY[resource];
        // Find all events in the change (e.g.: created, updated, deleted)
        toPairs(resourceChanges).forEach(([event, targetObjects]) =>
          targetObjects
            .map(fromAPIResult ?? identity)
            .forEach(resourceChangeHandlers[event](storeActions))
        );
      }
    });
  });
};

/**
 * ONLY USE ONCE PER APP.
 */
const useHeartBeat = () => {
  const lastBeatRef = React.useRef<Moment>(moment());
  const storeActions = useStoreActions();
  const user = useCurrentUser();
  const { onBeat } = React.useContext(TrackingContext);

  const callback = React.useCallback(() => {
    if (user) checkForUpdates(user, storeActions, lastBeatRef, onBeat);
  }, [user, storeActions, onBeat]);

  useInterval(callback, FOREGROUND_INTERVAL, false);
};

export default useHeartBeat;
