// @flow
import * as React from "react";
import useTitleUpdater from "./hooks/useTitleUpdater";
import useAppSetupForUser from "./hooks/useAppSetupForUser";
import useTokenManager from "./hooks/useTokenManager";
import AppPage from "./AppPage";
import useHeartBeat from "./hooks/useHeartBeat";
import useAjaxErrorInterceptor from "../../hooks/useAjaxInterceptor";
import { AppVersionProvider } from "../../contexts/version";
import { useCurrentUser } from "../../store/selectors";
import ErrorBoundary from "../../components/feedback/ErrorBoundary";
import usePortfolioSummaryBeat from "./hooks/usePortfolioSummaryBeat";

const AppPageContainer: React.ComponentType<{}> = () => {
  useTitleUpdater();
  // It is important to install the interceptor BEFORE the token
  // manager, as the latter can raise exception in case the token is
  // invalid.
  useAjaxErrorInterceptor();
  useTokenManager();
  useHeartBeat();
  const user = useCurrentUser();
  useAppSetupForUser(user);
  // Reload regularly the summary of all portfolio, to display in the
  // navigation and the home page.
  usePortfolioSummaryBeat();

  return (
    <AppVersionProvider>
      <ErrorBoundary>
        <AppPage />
      </ErrorBoundary>
    </AppVersionProvider>
  );
};

export default AppPageContainer;
