// @flow
import * as React from "react";
import PostBuilderContext from "../../contexts/postBuilder";
import type { Channel } from "../../models/channels/channel.model";
import type { DiagnosticError } from "../../util/diagnostics.util";
import compact from "lodash/compact";

const EMPTY: Array<DiagnosticError> = [];

const useTargetDiagnostics = (channel: ?Channel): DiagnosticError[] => {
  const {
    post: { subchannel_id },
  } = React.useContext(PostBuilderContext);
  return React.useMemo(() => {
    if (!channel) {
      return EMPTY;
    }
    return compact([
      !subchannel_id && !!channel.requires?.subchannel && "missingSurface",
    ]);
  }, [channel, subchannel_id]);
};

export default useTargetDiagnostics;
