// @flow
import * as React from "react";
import WidgetHead from "../../inputs/WidgetHead";
import type { Callback } from "../../../types";
import type { Post } from "../../../models/post.model";
import { getPostSnippet } from "../../../models/post.model";
import useTranslate from "../../../hooks/useTranslate";
import { Alert, List } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavigationListItem } from "../../lib/display/listItems";
import { InlineRowStack } from "../../lib/layout/stacks";
import { ListAvatarPostMediaThumbnail } from "../../display/PostMediaThumbnail";
import MultiChannelIcon from "../../lib/display/icons/MultiChannelIcon";
import PostStatusChipAndTimezone from "../../display/PostStatusChipAndTimezone";
import Pagination from "../../display/Pagination";

type Props = {
  page: number,
  pages: number,
  posts: Post[],
  onPreviousPage: ?Callback,
  onNextPage: ?Callback,
  onSelect: (Post) => any,
};

const PostsList = styled(List)({
  maxWidth: 460,
});

const UpcomingPostsWidget: React.ComponentType<Props> = ({
  page,
  pages,
  posts,
  onPreviousPage,
  onNextPage,
  onSelect,
}) => {
  const t = useTranslate();
  return (
    <div>
      <WidgetHead
        primary={t("global.postsToday")}
        paginated={{
          onPreviousPage,
          onNextPage,
        }}
      />
      {posts.length === 0 ? (
        <Alert severity="info">{t("global.noPostToday")}</Alert>
      ) : (
        <PostsList dense>
          {posts.map((p) => (
            <NavigationListItem
              key={p.id}
              avatar={<ListAvatarPostMediaThumbnail post={p} adorned={false} />}
              primary={getPostSnippet(p)}
              onClick={() => onSelect(p)}
              secondary={
                <InlineRowStack>
                  <MultiChannelIcon
                    channels={p.channels}
                    limit={2}
                    size="xsmall"
                  />
                  <PostStatusChipAndTimezone post={p} dense />
                </InlineRowStack>
              }
            />
          ))}
        </PostsList>
      )}
      <Pagination page={page} pages={pages} />
    </div>
  );
};

export default UpcomingPostsWidget;
