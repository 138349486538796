// @flow
import type { Integer } from "../types";
import compact from "lodash/compact";

export type PollOption = string;

/** Stands for a poll that can be published on social networks. */
export type Poll = {
  question: string,
  duration_minutes: Integer,
  /** Max number of allowed picks. */
  selects: Integer,
  options: PollOption[],
};

export const normalisePoll = (poll: ?Poll): ?Poll =>
  poll ? { ...poll, options: compact(poll.options) } : poll;
