// @flow
import * as React from "react";
import Logo from "./Logo";
import { Subheader } from "../lib/display/Text";
import { styled } from "@mui/material/styles";
import { RowStack } from "../lib/layout/stacks";

const Title = styled(Subheader)`
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledLogo = styled(Logo)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const LogoTitle: React.ComponentType<{}> = () => {
  return (
    <RowStack spacing={0.5} padding={1}>
      <StyledLogo size={48} />
      <Title>bobcaat</Title>
    </RowStack>
  );
};

export default LogoTitle;
