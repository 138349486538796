// @flow
import * as React from "react";
import CommentsPreview from "./CommentsPreview";
import type { Post } from "../../../../models/post.model";
import type { Callback } from "../../../../types";
import { SecondaryPagePanel } from "../../../layout/PagePanel";
import routes from "../../../../config/routes.config";
import PostCard from "../../../display/PostCard";
import type { BobcaatAccount } from "../../../../models/account.model";
import MessageInput from "../MessageInput";
import { styled } from "@mui/material/styles";

type Props = {
  post: Post,
  account: BobcaatAccount,
  onSendNewComment: (string) => any,
  onClose: Callback,
};

const Root = styled(SecondaryPagePanel)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    minWidth: 450,
    maxWidth: 600,
  },
}));

const CommentEngagementEditor: React.ComponentType<Props> = ({
  post,
  account,
  onSendNewComment,
  onClose,
}) => {
  return (
    <Root
      contentKey={post.id}
      absoluteHeader
      size="single"
      onClose={onClose}
      route={
        routes.app.nested.portfolios.nested.engagement.nested.account.nested
          .posts.nested.post
      }
    >
      <PostCard post={post} account={account}>
        <CommentsPreview />
      </PostCard>
      <MessageInput onSubmit={onSendNewComment} />
    </Root>
  );
};

export default CommentEngagementEditor;
