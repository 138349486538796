// @flow
import * as React from "react";
import type { Props as SettingsSectionProps } from "../../../layout/SettingsSection";
import SettingsSection from "../../../layout/SettingsSection";
import type { Form, ObjectFormFieldDef } from "../../../../hooks/useForm";
import { FormActions } from "../../../../hooks/useForm";
import {
  ColumnStack,
  ResponsiveInlineRowStack,
} from "../../../lib/layout/stacks";
import type { User } from "../../../../models/user.model";
import useTranslate from "../../../../hooks/useTranslate";
import FormSelect from "../../../lib/inputs/form/FormSelect";
import locales, {
  getLocaleLongName,
  getLocaleSlug,
} from "../../../../config/locales.config";
import {
  getOptionLabel,
  getOptionValue,
  themeOptions,
} from "../../../../util/themes.util";
import palettes, {
  BOBCAAT_PALETTE,
  CUSTOM_PALETTE,
  getPaletteName,
  getPaletteValue,
} from "../../../../config/palettes.config";
import FormDialog from "../../../lib/feedback/FormDialog";
import ColorInput from "../../../lib/inputs/ColorInput";
import { PaletteAdornment, ThemeAdornment } from "../../../display/Adornments";

export type PreferencesTabFormDef = {
  locale: ObjectFormFieldDef,
  theme: ObjectFormFieldDef,
  palette: ObjectFormFieldDef,
};

type CoreProps = {
  form: Form<PreferencesTabFormDef>,
  user: ?User,
};

type Props = {
  ...Omit<SettingsSectionProps, "title" | "children">,
  ...CoreProps,
};

const CustomPaletteForm = ({ form, user }: CoreProps) => {
  const t = useTranslate();
  const onSetPalette = FormActions.onChangeField(form.set, "palette");

  const [open, setOpen] = React.useState(false);
  const [primary, setPrimary] = React.useState("");
  const [secondary, setSecondary] = React.useState("");
  const currentPaletteIsCustom = form.state.palette.value === CUSTOM_PALETTE;

  // Trigger the opening when custom is selected.
  React.useEffect(() => {
    setOpen((prev) => prev || currentPaletteIsCustom);
  }, [currentPaletteIsCustom]);

  if (!user) {
    return false;
  }

  return (
    <FormDialog
      name="pick-custom-colours"
      title="global.choosePalette"
      open={open}
      onClose={() => setOpen(false)}
      onCancel={() => onSetPalette(user.preferences.palette ?? BOBCAAT_PALETTE)}
      onSubmit={() => {
        setOpen(false);
        onSetPalette({
          name: user.first_name,
          slug: user.first_name,
          palette: {
            primary: { main: primary },
            secondary: { main: secondary },
          },
        });
      }}
      formProps={{
        submitTextId: "global.submitPalette",
      }}
    >
      <ColumnStack>
        <ColorInput
          value={primary}
          onChange={setPrimary}
          label={t("global.primary")}
          width={300}
        />
        <ColorInput
          value={secondary}
          onChange={setSecondary}
          label={t("global.secondary")}
          width={300}
        />
      </ColumnStack>
    </FormDialog>
  );
};

const PreferencesSection: React.ComponentType<Props> = ({
  form,
  user,
  ...props
}) => {
  const t = useTranslate();
  const paletteOptions = React.useMemo(
    () =>
      !palettes.includes(form.state.palette.value)
        ? [form.state.palette.value, ...palettes]
        : palettes,
    [form.state.palette.value]
  );

  return (
    <>
      <SettingsSection title="global.preferences" loading={!user} {...props}>
        <ResponsiveInlineRowStack>
          <FormSelect
            id="select-language"
            width={{ sm: 200 }}
            options={locales}
            formField={form.state.locale}
            onChange={FormActions.onChangeField(form.set, "locale")}
            getOptionLabel={getLocaleLongName}
            getOptionValue={getLocaleSlug}
            label={t("global.language")}
            readOnly={!props.editing}
          />
          <FormSelect
            id="select-theme"
            width={{ sm: 200 }}
            options={themeOptions}
            formField={form.state.theme}
            onChange={FormActions.onChangeField(form.set, "theme")}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            label={t("global.theme")}
            readOnly={!props.editing}
            translateLabel
            adornOptions
            AdornmentComponent={ThemeAdornment}
          />
          <FormSelect
            id="select-palette"
            width={{ sm: 220 }}
            options={paletteOptions}
            formField={form.state.palette}
            onChange={FormActions.onChangeField(form.set, "palette")}
            getOptionLabel={getPaletteName}
            getOptionValue={getPaletteValue}
            label={t("global.palette")}
            readOnly={!props.editing}
            translateLabel
            adornOptions
            AdornmentComponent={PaletteAdornment}
          />
        </ResponsiveInlineRowStack>
      </SettingsSection>
      <CustomPaletteForm form={form} user={user} />
    </>
  );
};

export default PreferencesSection;
