// @flow
import * as React from "react";
import type {
  CellComponent,
  Column,
  TableSelection,
} from "../../lib/display/DataTable/base";
import { DEFAULT_PAPER_SLOT_PROPS } from "../../lib/display/DataTable/base";
import type { ApprovalRequest } from "../../../models/approvalRequest.model";
import { Body2 } from "../../lib/display/Text";
import VirtualDataTable from "../../lib/display/DataTable/VirtualDataTable";
import RouterLink from "../../lib/navigation/RouterLink";
import type { ModelID } from "../../../types";
import { makeSkeletonTable } from "../../lib/display/DataTable/makeSkeletonTable";
import {
  makeDeleteAction,
  makeEditAction,
} from "../../lib/display/DataTable/TableAction";
import Translate from "../../lib/display/Translate";
import { ColumnStack, InlineRowStack, RowStack } from "../../lib/layout/stacks";
import ApprovalRequestStatusChip from "../../display/ApprovalRequestStatusChip";
import routes, { getRoutePath } from "../../../config/routes.config";
import useApprovalRequestCounts from "../../../hooks/useApprovalRequestCounts";
import ApprovalRequestStatusPie from "../../display/ApprovalRequestStatusPie";
import useIsMobile from "../../../hooks/useIsMobile";

const Due: CellComponent<ApprovalRequest> = ({ row }) => (
  <Body2 color="textSecondary">
    {row.deadline ? row.deadline.fromNow() : "—"}
  </Body2>
);

const Title: CellComponent<ApprovalRequest> = ({ row }) => {
  return (
    <RouterLink
      target="_blank"
      rel="noopener noreferrer"
      to={getRoutePath(routes.approval, { hashId: row.hash_id })}
      sx={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "block",
      }}
    >
      {row.title}
    </RouterLink>
  );
};

const Status: CellComponent<ApprovalRequest> = ({ row }) => {
  return <ApprovalRequestStatusChip status={row.status} />;
};

const Progress: CellComponent<ApprovalRequest> = ({ row }) => {
  const countsByStatus = useApprovalRequestCounts(row);

  return (
    <RowStack alignItems="center">
      <ApprovalRequestStatusPie request={row} diameter={24} />
      <Body2 color="textSecondary" gutterBottom>
        <Translate id="global.approvalRequestSummary" data={countsByStatus} />
      </Body2>
    </RowStack>
  );
};

const XsColumn: CellComponent<ApprovalRequest> = ({ row }) => (
  <ColumnStack>
    <InlineRowStack>
      <Title row={row} />
      <Status row={row} />
    </InlineRowStack>
    <Progress row={row} />
  </ColumnStack>
);

const columns: Column<ApprovalRequest, void>[] = [
  {
    label: "global.title",
    Component: Title,
    headCellProps: { sx: { width: 160 } },
  },
  {
    label: "global.due",
    Component: Due,
    headCellProps: { sx: { width: 120 } },
  },
  {
    label: "global.status",
    Component: Status,
    headCellProps: { sx: { width: 180 } },
  },
  { label: "global.progress", Component: Progress },
];

const xsColumns: Column<ApprovalRequest, void>[] = [
  {
    label: "global.content",
    Component: XsColumn,
    bodyCellProps: { sx: { padding: 1 } },
  },
];

type Props = {
  rows: ApprovalRequest[],
  onDelete: (TableSelection<ModelID>) => any,
  onEdit: (ModelID) => any,
};

const SLOT_PROPS = {
  paper: DEFAULT_PAPER_SLOT_PROPS,
  toolbar: {
    title: "global.yourApprovalRequests",
  },
  table: {
    size: "small",
  },
  tableContainer: {
    sx: {
      maxHeight: {
        xs: "calc(100% - 56px)",
        sm: "calc(100% - 64px)",
      },
    },
  },
  tableRow: {
    sx: {
      borderTop: { xs: "solid 1px rgba(0, 0, 0, 0.1)", sm: "none" },
      borderBottom: { xs: "solid 1px rgba(0, 0, 0, 0.1)", sm: "none" },
    },
  },
};

const ApprovalRequestsTable: React.ComponentType<Props> = ({
  rows,
  onDelete,
  onEdit,
}) => {
  const isMobile = useIsMobile();
  return (
    <VirtualDataTable
      rows={rows}
      selectable
      columns={isMobile ? xsColumns : columns}
      actions={[makeEditAction(onEdit), makeDeleteAction(onDelete)]}
      slotProps={SLOT_PROPS}
    />
  );
};

export const SkeletonApprovalRequestsTable: React.ComponentType<{}> =
  makeSkeletonTable({ columns, slotProps: SLOT_PROPS });

export default ApprovalRequestsTable;
