// @flow
import * as React from "react";

/**
 * An effect that will only be triggered once.
 */
const useOneTimeEffect = (callback: () => any) => {
  const firstPass = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (!firstPass.current) {
      callback();
      firstPass.current = true;
    }
  }, [callback]);
};

export default useOneTimeEffect;
