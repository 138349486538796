// @flow
import * as React from "react";
import * as Sentry from "@sentry/react";
import { styled } from "@mui/material/styles";
import { Body1, Jumbo } from "../../lib/display/Text";
import { Paper } from "@mui/material";
import useTranslate from "../../../hooks/useTranslate";
import { RowStack } from "../../lib/layout/stacks";
import { NeutralTextButton, PrimaryTextButton } from "../../lib/inputs/buttons";
import Translate from "../../lib/display/Translate";
import type { Children } from "../../../reactTypes";
import StatusChip from "../../lib/display/StatusChip";

const FallbackComponentContentRoot = styled("div")(
  ({ theme }) => `
  min-height: 100vh;
  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.background.alt};
`
);

const Title = styled(Jumbo)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(3)};
`
);

const Body = styled(Body1)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(2)};
`
);

const CodeBlock = styled(Paper)(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)};
  border-left: solid 4px ${theme.palette.error.light};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  max-width: 1000px;
  overflow-x: auto;
`
);

const Code = styled("code")`
  white-space: pre;
`;

type FallbackComponentProps = {
  eventId: string,
  resetError: () => any,
  error: string,
  componentStack: string,
};

const FallbackComponent = ({
  eventId,
  resetError,
  error,
  componentStack,
}: FallbackComponentProps) => {
  const t = useTranslate();
  return (
    <FallbackComponentContentRoot>
      <Title>{t("hoc.ErrorBoundary.title")}</Title>
      <Body>
        <Translate id="hoc.ErrorBoundary.subtitle" />
      </Body>
      <RowStack>
        <PrimaryTextButton
          onClick={() => {
            Sentry.showReportDialog({ eventId: eventId });
          }}
        >
          {t("hoc.ErrorBoundary.cta")}
        </PrimaryTextButton>
        <NeutralTextButton onClick={resetError}>
          {t("global.close")}
        </NeutralTextButton>
      </RowStack>
      <StatusChip severity="error">{error.toString()}</StatusChip>
      <CodeBlock>
        <Code>{componentStack}</Code>
      </CodeBlock>
    </FallbackComponentContentRoot>
  );
};

type Props = {
  children: Children,
};

const ErrorBoundary: React.ComponentType<Props> = ({ children }) => (
  // We have to use a function for fallback, otherwise we can't use
  // hooks in FallbackComponent.
  <Sentry.ErrorBoundary fallback={(props) => <FallbackComponent {...props} />}>
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
