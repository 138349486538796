// @flow
import * as React from "react";
import type { Children, CSSProps } from "../../reactTypes";
import { styled } from "@mui/material/styles";
import { Subheader } from "../lib/display/Text";
import useTranslate from "../../hooks/useTranslate";
import type { I18nKey } from "../../types";
import type { Variant } from "../lib/display/Text";

type Props = {
  children: Children,
  title?: I18nKey,
  titleVariant?: Variant,
  ...CSSProps,
};

const Root = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(4),
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(2),
  },
}));

const Section: React.ComponentType<Props> = React.forwardRef(
  ({ title, children, titleVariant, ...props }, ref) => {
    const t = useTranslate();

    return (
      <Root ref={ref} {...props}>
        {title && <Subheader variant={titleVariant}>{t(title)}</Subheader>}
        {children}
      </Root>
    );
  }
);

export default Section;
