// @flow
import type { HashtagGroup } from "../models/hashtagGroup.model";
import { API as backend } from "./backend.service";
import * as snacks from "../models/alerts.model";
import type { AsyncAction, AsyncVoidAction } from "./types";
import type { ModelID, Transient } from "../types";

export const addHashtagGroup: AsyncAction<
  Promise<void>,
  Transient<HashtagGroup>
> = (actions) => (hashtagGroup) =>
  backend.hashtagGroup
    .addHashtagGroup(hashtagGroup)
    .then(actions.hashtagGroup.append)
    .then(() => {
      actions.snacks.append(
        snacks.localSuccess({ message: "store.hashtagGroup.created" })
      );
    });

export const editHashtagGroup: AsyncAction<Promise<void>, HashtagGroup> =
  (actions) => (hashtagGroup) =>
    backend.hashtagGroup
      .editHashtagGroup(hashtagGroup)
      .then(actions.hashtagGroup.replace)
      .then(() => {
        actions.snacks.append(
          snacks.localSuccess({ message: "store.hashtagGroup.updated" })
        );
      });

export const getHashtagGroups: AsyncVoidAction<void> = (actions) => () =>
  backend.hashtagGroup.getHashtagGroups().then(actions.hashtagGroup.replaceAll);

export const deleteHashtagGroup: AsyncAction<Promise<void>, ModelID> =
  (actions) => (hashtagGroupId) =>
    backend.hashtagGroup.deleteHashtagGroup(hashtagGroupId).then(() => {
      actions.hashtagGroup.remove(hashtagGroupId);
      actions.snacks.append(
        snacks.localSuccess({ message: "store.hashtagGroup.deleted" })
      );
    });
