// @flow
import * as React from "react";
import Avatar from "../display/Avatar";
import type { Props as AvatarProps } from "../display/Avatar";
import useObjectURL from "../../../hooks/useObjectURL";
import type { ObjectURLSrc } from "../../../hooks/useObjectURL";
import ImageIcon from "@mui/icons-material/Image";
import ImagePicker from "./pickers/ImagePicker";

export type Props = {
  ...AvatarProps,
  src?: ObjectURLSrc,
  onChange: (File) => any,
  initials?: string,
  disabled?: boolean,
  size?: number,
};

const AvatarInput: React.ComponentType<Props> = ({
  onChange,
  src,
  disabled,
  initials,
  style,
  size = 48,
  ...props
}) => {
  const imageURL = useObjectURL(src ?? "");
  return (
    <ImagePicker
      maxFiles={1}
      disabled={disabled}
      onSelect={(images) => onChange(images[0])}
    >
      <Avatar
        src={imageURL}
        size={size}
        style={{ cursor: disabled ? undefined : "pointer", ...style }}
        {...props}
      >
        {initials ?? (
          <ImageIcon style={{ color: "inherit", fontSize: size / 2 }} />
        )}
      </Avatar>
    </ImagePicker>
  );
};

export default AvatarInput;
