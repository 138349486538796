// @flow
import * as React from "react";
import PostBuilderContext from "../../../contexts/postBuilder";
import useTranslate from "../../../hooks/useTranslate";
import { TextField } from "@mui/material";
import type { Channel } from "../../../models/channels/channel.model";
import type { PostAttachment } from "../../../models/post.model";
import {
  isAttachmentImage,
  isAttachmentVideo,
} from "../../../models/attachment.model";
import { every } from "lodash";

const getTitleLimit = (
  channel: ?Channel,
  attachments: PostAttachment[]
): number => {
  const title = channel?.features?.content?.title;
  if (!title) return 300;
  if (every(attachments, isAttachmentImage))
    return title.maxCharsIfImages ?? 300;
  if (every(attachments, isAttachmentVideo))
    return title.maxCharsIfVideo ?? 300;
  return 300;
};

const PostTitleInput: React.ComponentType<{}> = () => {
  const {
    post: { title, attachments },
    onUpdatePost,
    channel,
  } = React.useContext(PostBuilderContext);
  const supportsTitle = !!channel?.features?.content?.title;
  const titleLimit = getTitleLimit(channel, attachments);
  const t = useTranslate();
  return (
    <TextField
      value={title}
      size="small"
      onChange={(e) => onUpdatePost({ title: e.currentTarget.value })}
      helperText={
        supportsTitle
          ? `${title?.length ?? 0}/${titleLimit}`
          : t("PostCreator.postTitleHelper")
      }
      label={
        supportsTitle ? t("global.title") : t("PostCreator.postTitleLabel")
      }
    />
  );
};

export default PostTitleInput;
