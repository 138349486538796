// @flow
import * as React from "react";
import VizGrid from "./VizGrid";
import type { Post } from "../../../../models/post.model";
import { getTime, isPublished } from "../../../../models/post.model";
import ReorderPostsDialog from "./ReorderPostsDialog";
import moment from "moment";
import { useStoreActions } from "../../../../store/store";
import * as snacks from "../../../../models/alerts.model";
import orderBy from "lodash/orderBy";
import { replaceItem } from "../../../../lib/lodashex.lib";
import { byId } from "../../../../models/base.model";

type Props = {
  posts: Post[],
};

const getNewScheduleFromUnpublishedSource = (
  source: Post,
  offsetToNowIfPast: number,
  step: number
) =>
  // if the post is not published we try to take its time, but we need
  // to make sure it is in the future as well.
  source.schedule_time?.isBefore(moment())
    ? moment().add(step * offsetToNowIfPast, "h")
    : moment(source.schedule_time);

const calculatePostNewSchedule = (
  posts: Post[],
  postIndex: number,
  endIndex: number,
  step: number
) => {
  if (isPublished(posts[postIndex + step])) {
    // If the next post is published we can't swap times,
    // so try to respect the order by adding an offset respecting the step.
    // At the minimum the post should be scheduled in the future.
    return moment
      .max(moment(), moment(posts[postIndex + step].schedule_time))
      .add(step * Math.abs(endIndex - postIndex), "h");
  }

  return getNewScheduleFromUnpublishedSource(
    posts[postIndex + step],
    // +1 to reserve 1 hour offset when dealing with endIndex.
    Math.abs(postIndex - endIndex) + 1,
    step
  );
};

function* collectMovedPostsWithNewScheduleTime(
  posts: Post[],
  startIndex: number,
  endIndex: number,
  step: number
): Iterable<Post> {
  // This algorithm assumes we always start with the moved post
  // at index startIndex.
  for (let postIndex = startIndex; postIndex !== endIndex; postIndex += step) {
    // For the vast majority of posts, just copy the schedule of the
    // post at the next step. Skip published posts (they are not moved
    // and we can't use their schedule_time either.
    if (isPublished(posts[postIndex])) {
      continue;
    }
    yield {
      ...posts[postIndex],
      schedule_time: calculatePostNewSchedule(posts, postIndex, endIndex, step),
    };
  }
  // The last one needs to take the schedule of the post that was
  // originally moved and is now at startIndex
  // Note that we shouldn't reach here if we yielded the moved
  // post schedule_time on the last step.
  if (!isPublished(posts[endIndex])) {
    yield {
      ...posts[endIndex],
      schedule_time: getNewScheduleFromUnpublishedSource(
        posts[startIndex],
        1,
        step
      ),
    };
  }
}

const VizGridContainer: React.ComponentType<Props> = ({ posts }) => {
  const [movedPosts, setMovedPosts] = React.useState<Post[]>([]);
  const storeActions = useStoreActions();
  const [tempPosts, setTempPosts] = React.useState<?(Post[])>();

  const handleItemsReordered = (
    reorderedPosts: Post[],
    movedPost: Post,
    fromIndex: number,
    toIndex: number
  ) => {
    if (
      toIndex > fromIndex &&
      moment().isAfter(reorderedPosts[toIndex - 1].schedule_time)
    ) {
      // Cannot move a post in the past.
      storeActions.snacks.append(
        snacks.localWarning({
          message: "VizPlannerSection.cannotChangeThePast",
        })
      );
      return;
    }

    if (movedPost.status !== "published") {
      // step = 1: target post has been brought forward (in the future)
      // step =-1: target post has been pushed back (in the past)
      const step = toIndex < fromIndex ? 1 : -1;
      setTempPosts(reorderedPosts);
      setMovedPosts(
        orderBy(
          [
            ...collectMovedPostsWithNewScheduleTime(
              reorderedPosts,
              toIndex,
              fromIndex,
              step
            ),
          ],
          getTime,
          "desc"
        )
      );
    }
  };

  return (
    <>
      <ReorderPostsDialog
        onUpdatePost={(post) => {
          setMovedPosts((oldMovedPosts) =>
            replaceItem(oldMovedPosts, byId(post.id), post)
          );
        }}
        onClose={() => {
          setMovedPosts([]);
          setTempPosts(null);
        }}
        posts={movedPosts}
      />
      <VizGrid
        onItemsReordered={handleItemsReordered}
        posts={tempPosts ?? posts}
      />
    </>
  );
};

export default VizGridContainer;
