// @flow
import * as React from "react";
import useTranslate from "../../../hooks/useTranslate";
import { appendOrRemove } from "../../../lib/lodashex.lib";
import { Grow } from "@mui/material";
import SwitchField from "./SwitchField";
import { styled } from "@mui/material/styles";
import { WarningRounded } from "@mui/icons-material";
import { Body1, Body2 } from "../display/Text";
import { RowStack } from "../layout/stacks";

export type Props<Option, Value> = {
  value: Value[],
  onChange: (Value[]) => any,
  options: $ReadOnlyArray<Option>,
  getOptionLabel?: (Option) => string,
  getOptionValue: (Option) => Value,
  AdornmentComponent?: React.ComponentType<{
    value: ?Option,
    disabled?: boolean,
  }>,
  translateLabel?: boolean,
  excludeIf?: (Option) => boolean,
  disableIf?: (Option) => boolean | string,
};

const WarningBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: 2,
  gap: 4,
}));

const Item = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const SwitchGroup = <Option: Object | string | number, Value: number | string>({
  value,
  onChange,
  options,
  translateLabel,
  getOptionLabel: userGetOptionLabel,
  getOptionValue,
  AdornmentComponent,
  excludeIf,
  disableIf,
}: Props<Option, Value>): React.Node => {
  const t = useTranslate();

  const getOptionLabel = React.useCallback(
    (opt: Option) => {
      return userGetOptionLabel ? userGetOptionLabel(opt) : opt.toString();
    },
    [userGetOptionLabel]
  );

  const getOptionLabelT = React.useMemo(
    () =>
      translateLabel
        ? (opt: Option) => t(getOptionLabel(opt))
        : (opt: Option) => getOptionLabel(opt),
    [translateLabel, t, getOptionLabel]
  );

  const Adornment = React.useCallback(
    (props: { value: ?Option, disabled?: boolean }) =>
      AdornmentComponent ? <AdornmentComponent {...props} /> : undefined,
    [AdornmentComponent]
  );

  const handleToggle = (item: Option) =>
    onChange(appendOrRemove(value, getOptionValue(item)));

  return (
    <div>
      {options
        .map((item) => ({
          item,
          selected: value.includes(getOptionValue(item)),
        }))
        .map(({ item, selected }) => {
          const disabledReason = disableIf && disableIf(item);
          return (
            <Grow
              in={!excludeIf || !excludeIf(item)}
              unmountOnExit={true}
              key={getOptionValue(item)}
            >
              <Item>
                <SwitchField
                  checked={selected}
                  disabled={!!disabledReason}
                  onChange={() => handleToggle(item)}
                  label={
                    <RowStack spacing={1}>
                      <Adornment value={item} disabled={!!disabledReason} />
                      <Body1>{getOptionLabelT(item)}</Body1>
                    </RowStack>
                  }
                />
                {disabledReason && (
                  <WarningBox>
                    <WarningRounded htmlColor="orange" size="small" />
                    <Body2>{disabledReason}</Body2>
                  </WarningBox>
                )}
              </Item>
            </Grow>
          );
        })}
    </div>
  );
};

export default SwitchGroup;
