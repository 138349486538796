// @flow
import * as React from "react";
import type {
  Form,
  NumberListFormFieldDef,
  StringFormFieldDef,
} from "../../../hooks/useForm";
import { FormActions } from "../../../hooks/useForm";
import type { Callback, ModelID } from "../../../types";
import HTMLForm from "../../lib/inputs/form/HTMLForm";
import Section from "../../layout/Section";
import FormTextInput from "../../lib/inputs/form/FormTextInput";
import { Body2 } from "../../lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import PostSearchBar from "../PostSearchBar";
import useFilteredPosts from "../../../hooks/useFilteredPosts";
import { PostsMiniTable } from "../PostsTable/PostsTable";
import { StickToTheTop } from "../../lib/layout/placement";
import {
  isFitForApprovalRequest,
  POST_APPROVAL_CANDIDATE_STATUSES,
} from "../../../models/post.model";
import type { ApprovalRequest } from "../../../models/approvalRequest.model";
import { styled } from "@mui/material/styles";
import useIsSmallOrSmaller from "../../../hooks/useIsSmallOrSmaller";
import { Stack } from "@mui/material";

export type ApprovalRequestFormDef = {
  title: StringFormFieldDef,
  description: StringFormFieldDef,
  posts: NumberListFormFieldDef,
};

type Props = {
  portfolioId: ModelID,
  approvalRequest?: ?ApprovalRequest,
  form: Form<ApprovalRequestFormDef>,
  onConfirm: Callback,
  onDelete?: Callback,
  onCancel: Callback,
};

const StyledForm = styled(HTMLForm)({
  overflow: "auto",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
});

const ApprovalRequestWidget: React.ComponentType<Props> = ({
  form,
  onConfirm,
  onCancel,
  onDelete,
  portfolioId,
  approvalRequest,
}) => {
  const isSmallOrSmaller = useIsSmallOrSmaller();
  const posts = useFilteredPosts(portfolioId).filter(
    isFitForApprovalRequest(approvalRequest)
  );
  const t = useTranslate();
  return (
    <StyledForm
      onSubmit={onConfirm}
      onCancel={onCancel}
      submitTextId="global.save"
      onDelete={onDelete}
      cancelTextId="global.dropChanges"
    >
      <Stack
        direction={isSmallOrSmaller ? "column" : "row"}
        spacing={isSmallOrSmaller ? 0 : 3}
        alignItems="stretch"
        flexGrow={1}
      >
        <StickToTheTop
          minWidth={200}
          maxWidth={isSmallOrSmaller ? undefined : 450}
        >
          <Section title="global.general">
            <Body2 color="textSecondary">
              {t("ApprovalRequestForm.details")}
            </Body2>
            <FormTextInput
              required
              formField={form.state.title}
              onChange={FormActions.onChangeField(form.set, "title")}
              label={t("global.title")}
            />
            <FormTextInput
              multiline
              formField={form.state.description}
              onChange={FormActions.onChangeField(form.set, "description")}
              label={t("global.description")}
              rows={5}
            />
          </Section>
        </StickToTheTop>
        <Section title="ApprovalRequestForm.posts" style={{ flexGrow: 1 }}>
          <PostSearchBar
            noCompose
            portfolioId={portfolioId}
            statusOptions={POST_APPROVAL_CANDIDATE_STATUSES}
            size="small"
          />
          <PostsMiniTable
            rows={posts}
            defaultSelection={form.state.posts.value}
            onChangeSelection={(selection) =>
              FormActions.onChangeField(form.set, "posts")([...selection])
            }
          />
        </Section>
      </Stack>
    </StyledForm>
  );
};

export default ApprovalRequestWidget;
