// @flow
import * as React from "react";
import type { AttachmentTypes } from "../../../../../../models/attachment.model";
import type {
  MessageAttachment,
  MessageAttachmentTypes,
} from "../../../../../../models/message.model";
import { Body2, Caption } from "../../../../../lib/display/Text";
import Translate from "../../../../../lib/display/Translate";
import { Link, Paper } from "@mui/material";
import { RowStack } from "../../../../../lib/layout/stacks";
import { BrokenImageRounded } from "@mui/icons-material";
import Spacer from "../../../../../lib/layout/Spacer";
import ErrorFallback from "../../../../../lib/feedback/ErrorFallback";
import type { Theme } from "../../../../../../stubs/mui/theming";
import { styled } from "@mui/material/styles";
import Video from "../../../../../lib/display/Video";
import File from "../../../../../lib/display/File";
import Image from "../../../../../lib/display/Image";
import type { CSSProps } from "../../../../../../reactTypes";

type AttachmentCaptionProps = {
  attachmentType: AttachmentTypes | MessageAttachmentTypes,
  author: string,
};

const getAttachmentTypeLabelId = (
  attachmentType: AttachmentCaptionProps["attachmentType"]
): ?string => {
  switch (attachmentType) {
    case "share":
      return "AttachmentCaption.theySharedWithYou";
    case "story_reply":
      return "AttachmentCaption.theyRepliedToYourStory";
    case "story_mention":
      return "AttachmentCaption.theyMentionedYouInTheirStory";
    default:
      return null;
  }
};
const AttachmentCaption: React.ComponentType<AttachmentCaptionProps> = ({
  attachmentType,
  author,
}) => {
  const label = getAttachmentTypeLabelId(attachmentType);
  return label ? (
    <Caption color="textSecondary" display="block">
      <Translate id={label} data={{ author }} />
    </Caption>
  ) : null;
};

const MEDIA_MIXIN = ({ theme }: { theme: Theme }) => ({
  maxWidth: 400,
  maxHeight: 300,
  borderRadius: 8,
  [theme.breakpoints.down("xs")]: {
    maxWidth: "100%",
  },
});

const ImageMedia = styled(Image)(MEDIA_MIXIN);
const VideoMedia = styled(Video)(MEDIA_MIXIN);
const AudioPlayer = styled("audio")(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    maxWidth: 260,
  },
}));
const Share = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));
const EllipsisLink = styled(Link)({
  maxWidth: 200,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "block",
});

const MEDIA_REPLACEMENT = (
  <Paper variant="outlined">
    <RowStack padding={1}>
      <BrokenImageRounded color="action" fontSize="large" />
      <Body2 color="textSecondary">
        <Translate id="EngagementHubSection.missingMediaAttachmentFallback" />
      </Body2>
    </RowStack>
  </Paper>
);

type MessageAttachmentPreviewProps = {
  attachment: MessageAttachment,
  interlocutorName: string,
};

const MessageAttachmentPreview: React.ComponentType<MessageAttachmentPreviewProps> =
  React.memo(({ attachment, interlocutorName }) => {
    switch (attachment.type) {
      case "image":
      case "story_reply":
      case "story_mention":
        return (
          <div>
            <AttachmentCaption
              author={interlocutorName}
              attachmentType={attachment.type}
            />
            <ErrorFallback fallback={MEDIA_REPLACEMENT}>
              <ImageMedia src={attachment.url} />
            </ErrorFallback>
          </div>
        );
      case "video":
        // use preload="none" to prevent blink effect if a video is
        // not available.
        return (
          <ErrorFallback fallback={MEDIA_REPLACEMENT}>
            <VideoMedia preload="metadata" controls src={attachment.url} />
          </ErrorFallback>
        );
      case "file":
        return <File src={attachment.url} width={64} openOnClick />;
      case "audio":
        return (
          <ErrorFallback fallback={MEDIA_REPLACEMENT}>
            <AudioPlayer src={attachment.url} controls />
          </ErrorFallback>
        );
      case "share":
        return (
          <div>
            <AttachmentCaption
              author={interlocutorName}
              attachmentType={attachment.type}
            />
            <Share>
              <EllipsisLink target="_blank" href={attachment.url}>
                {attachment.url}
              </EllipsisLink>
            </Share>
          </div>
        );
      default:
        return null;
    }
  });

type Props = {
  attachments: MessageAttachment[],
  interlocutorName: string,
  ...CSSProps,
};

const MessageAttachments: React.ComponentType<Props> = ({
  attachments,
  interlocutorName,
  ...props
}) => (
  <Spacer spacing={0.5} {...props}>
    {attachments.map((attachment) => (
      <MessageAttachmentPreview
        key={attachment.id}
        attachment={attachment}
        interlocutorName={interlocutorName}
      />
    ))}
  </Spacer>
);

export default MessageAttachments;
