// @flow
import * as React from "react";
import PostBuilderContext from "../../../../contexts/postBuilder";
import { TextField } from "@mui/material";
import useTranslate from "../../../../hooks/useTranslate";

const PinterestContentEditor: React.ComponentType<{}> = () => {
  const {
    post: { link },
    onUpdatePost,
  } = React.useContext(PostBuilderContext);
  const t = useTranslate();

  return (
    <TextField
      fullWidth
      value={link ?? ""}
      onChange={(e) => onUpdatePost({ link: e.currentTarget.value })}
      label={t("global.link")}
    />
  );
};

export default PinterestContentEditor;
