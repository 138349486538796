// @flow
import ajax, { webInstance, uploadFile as doUploadFile } from "../lib/ajax.lib";
import type { MediaRef } from "../models/base.model";
import type { URLStr } from "../types";
import type { APIResult } from "./backend.service/types";

export type OnUploadFileProgress = (progress: ProgressEvent, file: File) => any;

export const uploadFile = (
  file: File,
  publicRead: boolean,
  onProgress?: OnUploadFileProgress
): Promise<MediaRef> =>
  ajax
    .get<APIResult<{ url: URLStr, media_ref: MediaRef }>>(
      `/misc/media/register?name=${encodeURIComponent(
        file.name
      )}&public=${publicRead.toString()}`
    )
    .then((resp) =>
      doUploadFile({
        url: resp.data.data.url,
        file: file,
        onProgress: onProgress
          ? (progress) => onProgress(progress, file)
          : undefined,
        instance: webInstance,
        headers: publicRead ? { "x-amz-acl": "public-read" } : undefined,
      }).then(() => resp.data.data.media_ref)
    );
