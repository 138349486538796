// @flow
import * as React from "react";
import type { Callback } from "../../types";
import { InlineRowStack, RowStack } from "../lib/layout/stacks";
import { Body2, Subheader } from "../lib/display/Text";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import type { Children } from "../../reactTypes";

type Props = {
  primary: React.Node,
  secondary?: React.Node,
  actions?: Children,
  paginated?:
    | false
    | {
        onPreviousPage?: ?Callback,
        onNextPage?: ?Callback,
      },
};

const Secondary = styled(Body2)`
  flex-grow: 1;
`;

const WidgetHead: React.ComponentType<Props> = ({
  primary,
  secondary,
  paginated,
  actions,
}) => (
  <RowStack padding={0.5} spacing={3}>
    <Subheader>{primary}</Subheader>
    <Secondary color="textSecondary">{secondary}</Secondary>
    <InlineRowStack>
      {!!paginated && (
        <>
          <IconButton
            disabled={!paginated.onPreviousPage}
            onClick={paginated.onPreviousPage}
          >
            <KeyboardArrowLeftRounded />
          </IconButton>
          <IconButton
            disabled={!paginated.onNextPage}
            onClick={paginated.onNextPage}
          >
            <KeyboardArrowRightRounded />
          </IconButton>
        </>
      )}
      {actions}
    </InlineRowStack>
  </RowStack>
);

export default WidgetHead;
