// @flow
import * as React from "react";
import { Rating } from "@mui/material";
import { ColumnStack } from "../../lib/layout/stacks";
import useTranslate from "../../../hooks/useTranslate";
import FormTextInput from "../../lib/inputs/form/FormTextInput";
import type {
  Form,
  NumberFormFieldDef,
  StringFormFieldDef,
} from "../../../hooks/useForm";
import FormDialog from "../../lib/feedback/FormDialog";
import { FormActions } from "../../../hooks/useForm";

export type RatingFormDef = {
  feedback: StringFormFieldDef,
  rating: NumberFormFieldDef,
};

type Props = {
  onCancel: () => any,
  onClose: () => any,
  onSubmit: () => any,
  form: Form<RatingFormDef>,
  open?: boolean,
};

const RateTheAppDialog: React.ComponentType<Props> = ({
  form,
  onCancel,
  onClose,
  onSubmit,
  open,
}) => {
  const t = useTranslate();
  return (
    <FormDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      onCancel={onCancel}
      title="RateTheAppDialog.title"
      text="RateTheAppDialog.body"
      formProps={{ disabled: form.state.rating.value <= 0 }}
      name="rate-the-app"
      maxWidth="xs"
    >
      <ColumnStack spacing={2} alignItems="center">
        <Rating
          name="rate-the-app-rating"
          size="large"
          precision={0.5}
          value={form.state.rating.value}
          onChange={(e, val) =>
            FormActions.onChangeField(form.set, "rating")(val)
          }
        />
        <FormTextInput
          multiline
          fullWidth
          rows={3}
          formField={form.state.feedback}
          onChange={FormActions.onChangeField(form.set, "feedback")}
          label={t("global.feedback")}
          placeholder={t("RateTheAppDialog.feedbackPlaceholder")}
        />
      </ColumnStack>
    </FormDialog>
  );
};

export default RateTheAppDialog;
