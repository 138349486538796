// @flow
import * as React from "react";
import type { LockActions, LockState } from "./lib/lock.state";
import { useLockState } from "./lib/lock.state";
import type { MappingActions, MappingState } from "./lib/mapping.reducer";
import {
  makeMappingActions,
  mappingReducer,
  useMappingReducer,
} from "./lib/mapping.reducer";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import type { Conversation } from "../../models/conversation.model";
import type { ConversationSummary } from "../../models/conversationSummary.model";
import type { Message } from "../../models/message.model";
import last from "lodash/last";
import reverse from "lodash/reverse";
import type { InitActions, InitState } from "./lib/init.state";
import { useInitState } from "./lib/init.state";

export type MessagingActions = {
  ...LockActions,
  ...InitActions,
  conversations: SequenceActions<Conversation>,
  summaries: MappingActions<ConversationSummary>,
  messages: {
    ...SequenceActions<Message>,
    loadOlderMessages: (messages: Message[], allLoaded: boolean) => void,
    loadNewerMessages: (messages: Message[]) => void,
    appendAndSyncSummary: (messages: Message) => void,
    extendAndSyncSummary: (messages: Message[]) => void,
  },
};

export type MessagingState = {
  ...LockState,
  ...InitState,
  messageHistoryRemains: boolean,
  conversations: SequenceState<Conversation>,
  summaries: MappingState<ConversationSummary>,
  messages: SequenceState<Message>,
};

const conversationsReducer = sequenceReducer<Conversation>({
  key: "id",
});
const summariesReducer = mappingReducer<ConversationSummary>({
  key: "conversation_id",
});
const messagesReducer = sequenceReducer<Message>({
  key: "id",
});

export const useMessagingReducer = (): [MessagingState, MessagingActions] => {
  // Reducers first
  const [conversations, conversationsDispatch] =
    useSequenceReducer(conversationsReducer);
  const [messages, messagesDispatch] = useSequenceReducer(messagesReducer);
  const [summaries, summariesDispatch] = useMappingReducer(summariesReducer);
  const [locked, lockActions] = useLockState();
  const [initialised, initActions] = useInitState();
  const [messageHistoryRemains, setMessageHistoryRemains] =
    React.useState(true);

  // Then make actions
  const conversationsActions = React.useMemo(
    () => makeSequenceActions(conversationsDispatch),
    [conversationsDispatch]
  );
  const messagesActions = React.useMemo(
    () => makeSequenceActions(messagesDispatch),
    [messagesDispatch]
  );
  const summariesActions = React.useMemo(
    () => makeMappingActions(summariesDispatch),
    [summariesDispatch]
  );

  const actions = React.useMemo(
    () => ({
      conversations: conversationsActions,
      summaries: summariesActions,
      messages: {
        ...messagesActions,
        clear: () => {
          messagesActions.clear();
          setMessageHistoryRemains(true);
        },
        loadOlderMessages: (
          messages: Message[],
          allLoaded: boolean = false
        ) => {
          messagesActions.leftExtend(reverse(messages));
          setMessageHistoryRemains(!allLoaded);
        },
        loadNewerMessages: (messages: Message[]) => {
          // Reverse ordering to keep them all in descending creation order.
          messagesActions.extend(messages);
        },
        appendAndSyncSummary: (message: Message) => {
          messagesActions.append(message);
          summariesActions.patch(message.conversation_id, {
            updated_at: message.created_at,
            head: message.text,
          });
        },
        extendAndSyncSummary: (messages: Message[]) => {
          const lastMessage = last(messages);
          messagesActions.extend(messages);
          summariesActions.patch(lastMessage.conversation_id, {
            updated_at: lastMessage.created_at,
            head: lastMessage.text,
          });
        },
      },
      ...lockActions,
      ...initActions,
    }),
    [
      conversationsActions,
      summariesActions,
      messagesActions,
      lockActions,
      initActions,
    ]
  );

  return [
    {
      conversations,
      messages,
      summaries,
      locked,
      initialised,
      messageHistoryRemains,
    },
    actions,
  ];
};
