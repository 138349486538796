// @flow
import * as React from "react";
import useSwitch from "../../../hooks/useSwitch";
import noop from "lodash/noop";
import AlertDialog from "./AlertDialog";
import type { Callback } from "../../../types";
import type { Props as AlertDialogProps } from "./AlertDialog";

export type Options = {
  DialogProps: Omit<AlertDialogProps, "onClose" | "open">,
};

const useAlertDialog = (options?: Options): [Callback, React.Node] => {
  const [open, setOpen, setClosed] = useSwitch();
  if (!options) {
    return [noop, null];
  }
  return [
    setOpen,
    <AlertDialog onClose={setClosed} open={open} {...options.DialogProps} />,
  ];
};

export default useAlertDialog;
