// @flow
import * as React from "react";
import { insertAtCursorPos } from "../../../lib/text.lib";
import type { HashtagGroup } from "../../../models/hashtagGroup.model";
import { hashtagGroupToString } from "../../../models/hashtagGroup.model";
import HashtagGroupSelector from "../HashtagGroupSelector";
import usePopup from "../../lib/utils/usePopup";
import type { PopperProps } from "../../lib/utils/usePopup";

type Props = {
  inputEl: ?HTMLInputElement,
  onChangeInputValue: (string) => any,
  children: React.Element<any>,
  popperProps?: PopperProps,
};

const HashtagGroupPickerContainer: React.ComponentType<Props> = ({
  inputEl,
  onChangeInputValue,
  children,
  popperProps,
}) => {
  const handleSelectHashtag = React.useCallback(
    (hashtagGroup: HashtagGroup) =>
      inputEl &&
      onChangeInputValue(
        insertAtCursorPos(inputEl, hashtagGroupToString(hashtagGroup))
      ),
    [inputEl, onChangeInputValue]
  );

  const [onClick, popper] = usePopup(
    <HashtagGroupSelector onSelect={handleSelectHashtag} />,
    popperProps
  );
  return (
    <>
      {React.cloneElement(children, { onClick })}
      {popper}
    </>
  );
};

export default HashtagGroupPickerContainer;
