// @flow
import ajax from "../../lib/ajax.lib";
import type { ModelID } from "../../types";
import type {
  APIApprovalRequest,
  ApprovalRequest,
} from "../../models/approvalRequest.model";
import { fromAPIResult } from "../../models/approvalRequest.model";
import type {
  APINoContentResult,
  APIResult,
  APIToken,
  Pagination,
} from "./types";
import omit from "lodash/omit";
import type { PostApproval } from "../../models/postApproval.model";

export type AddApprovalRequestParams = Pick<
  ApprovalRequest,
  "title" | "description" | "posts" | "portfolio_id"
>;

export type UpdateApprovalRequestParams = {
  ...AddApprovalRequestParams,
  id: ModelID,
};

export const searchApprovalRequests = (
  postId: ModelID
): Promise<ApprovalRequest[]> =>
  ajax
    .get<APIResult<APIApprovalRequest[]>>(`/approval/search?post_id=${postId}`)
    .then((resp) => resp.data.data.map(fromAPIResult));

export const addApprovalRequest = (
  request: AddApprovalRequestParams
): Promise<ApprovalRequest> =>
  ajax
    .post<AddApprovalRequestParams, APIResult<APIApprovalRequest>>(
      `/approval`,
      request
    )
    .then((resp) => fromAPIResult(resp.data.data));

export const editApprovalRequest = (
  request: UpdateApprovalRequestParams
): Promise<ApprovalRequest> =>
  ajax
    .put<UpdateApprovalRequestParams, APIResult<APIApprovalRequest>>(
      `/approval/${request.id}`,
      omit(request, ["id"])
    )
    .then((resp) => fromAPIResult(resp.data.data));

export const deleteApprovalRequest = (
  requestId: ModelID
): Promise<APINoContentResult> =>
  ajax.delete<APINoContentResult>(`/approval/${requestId}`);

export const getApprovalRequests = ({
  page,
  pagesize,
}: Pagination = {}): Promise<ApprovalRequest[]> =>
  ajax
    .get<APIResult<APIApprovalRequest[]>>(
      `/approval?page=${page ?? 1}&pagesize=${pagesize ?? 50}`
    )
    .then((resp) => resp.data.data.map(fromAPIResult));

export const getApprovalRequestPostApprovals = (
  approvalRequestId: ModelID
): Promise<PostApproval[]> =>
  ajax
    .get<APIResult<PostApproval[]>>(`/approval/${approvalRequestId}/items`)
    .then((resp) => resp.data.data);

export const getApprovalRequest = (hashId: string): Promise<ApprovalRequest> =>
  ajax
    .get<APIResult<APIApprovalRequest>>(`/approval/${hashId}`)
    .then((resp) => fromAPIResult(resp.data.data));

export const updatePostApproval = (
  postApproval: PostApproval
): Promise<PostApproval> =>
  ajax
    .put<Pick<PostApproval, "status" | "comment">, APIResult<PostApproval>>(
      `/approval/${postApproval.request_id}/items/${postApproval.post_id}`,
      {
        status: postApproval.status,
        comment: postApproval.comment,
      }
    )
    .then((resp) => resp.data.data);

type SignInParams = {
  hashId: string,
  password: string,
};

export const signIn = ({ hashId, password }: SignInParams): Promise<string> =>
  ajax
    .post<{ password: string }, APIResult<APIToken>>(
      `/approval/auth/${hashId}`,
      {
        password,
      }
    )
    .then((r) => r.data.data.token);

export const commitApprovalRequest = (
  approvalRequestId: ModelID
): Promise<APINoContentResult> =>
  ajax.put<void, APINoContentResult>(`/approval/${approvalRequestId}/commit`);
