import { getOAuth1Token, loginOAuth1 } from "./oauth.service";

const AUTHORISE_URL = "https://api.twitter.com/oauth/authorize";

const POPUP_FEATURES =
  "height=485,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no," +
  "location=no,directories=no, status=yes";

const loginCallback = (token) =>
  loginOAuth1(token, AUTHORISE_URL, POPUP_FEATURES);
export const login = () =>
  getOAuth1Token("/oauth/twitter/request").then(loginCallback);
// loginOAuth1(_requestToken, AUTHORISE_URL, POPUP_FEATURES);

export const wasLoginCancelled = () => false;
