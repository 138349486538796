// @flow
import * as React from "react";
import type { Form, ObjectFormFieldDef } from "../../../hooks/useForm";
import { FormActions } from "../../../hooks/useForm";
import { PortfolioAdornment } from "../../display/Adornments";
import { getIdAsString } from "../../../models/base.model";
import type { Callback } from "../../../types";
import type { Portfolio } from "../../../models/portfolio.model";
import { getPortfolioName } from "../../../models/portfolio.model";
import FormDialog from "../../lib/feedback/FormDialog";
import FormSelect from "../../lib/inputs/form/FormSelect";
import useTranslate from "../../../hooks/useTranslate";
import type { BobcaatAccount } from "../../../models/account.model";

export type MoveAccountToPortfolioFormDef = {
  portfolio: ObjectFormFieldDef,
};

type Props = {
  form: Form<MoveAccountToPortfolioFormDef>,
  portfolios: Portfolio[],
  onSubmit: Callback,
  onClose: Callback,
  open?: boolean,
  account: BobcaatAccount,
};

const MoveAccountToPortfolioDialog: React.ComponentType<Props> = ({
  form,
  portfolios,
  onSubmit,
  onClose,
  open,
  account,
}) => {
  const t = useTranslate();
  return (
    <FormDialog
      name="move-account-to-portfolio"
      open={open}
      maxWidth="xs"
      onClose={onClose}
      onSubmit={onSubmit}
      title={t("MoveAccountToPortfolioForm.title", {
        name: account.entity_name,
      })}
      text={t("MoveAccountToPortfolioForm.subtitle")}
      formProps={{
        submitTextId: "MoveAccountToPortfolioForm.submit",
      }}
    >
      <FormSelect
        id="select-portfolios"
        width={400}
        multiple={false}
        formField={form.state.portfolio}
        onChange={FormActions.onChangeField(form.set, "portfolio")}
        getOptionLabel={getPortfolioName}
        getOptionValue={getIdAsString}
        label={t("global.portfolio")}
        options={portfolios}
        adornOptions
        translateLabel
        AdornmentComponent={PortfolioAdornment}
      />
    </FormDialog>
  );
};

export default MoveAccountToPortfolioDialog;
