// @flow
import * as React from "react";
import PageRoot from "../../components/layout/PageRoot";
import LoginPanel from "../../components/widgets/LoginWidget";
import { Center } from "../../components/lib/layout/placement";
import PleaseWaitProgress from "../../components/feedback/PleaseWaitProgress";
import OverviewPanel from "./OverviewPanel";
import DetailsPanel from "./DetailsPanel";
import useTranslate from "../../hooks/useTranslate";

type Props = {
  loading?: boolean,
  loginRequired: boolean,
  onLogin: (username: string, password: string) => any,
};

const ReviewApprovalRequestPage: React.ComponentType<Props> = ({
  loading,
  loginRequired,
  onLogin,
}) => {
  const t = useTranslate();
  if (loginRequired) {
    return (
      <Center>
        <LoginPanel
          noSignupInvite
          nousername
          onValidCredentials={onLogin}
          prompt={t("ApprovalPage.loginSubtitle")}
        />
      </Center>
    );
  }

  if (loading) {
    return <PleaseWaitProgress />;
  }

  return (
    <PageRoot>
      <OverviewPanel />
      <DetailsPanel />
    </PageRoot>
  );
};

export default ReviewApprovalRequestPage;
