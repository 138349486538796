// @flow
import { darken, getLuminance, lighten } from "@mui/material";

export const getContrastColor = (color: ?string): ?string => {
  if (typeof color === "undefined" || color === null || color === "") {
    return undefined;
  }

  try {
    return (getLuminance(color) > 0.4 ? darken : lighten)(color, 0.9);
  } catch (e) {
    return undefined;
  }
};

type MakeGradientParams = {
  startColor: string,
  endColor: string,
  angle?: number,
  startOffset?: number,
  endOffset?: number,
};

export const makeGradient = ({
  startColor,
  endColor,
  angle = 45,
  startOffset = 20,
  endOffset = 80,
}: MakeGradientParams): string =>
  `linear-gradient(${angle}deg, ${startColor} 0 ${startOffset}%, ${endColor} ${endOffset}% 100%)`;
