// @flow
import * as React from "react";
import AvatarImpl from "@mui/material/Avatar";
import { getContrastColor } from "../../../util/colors.util";
import type { Props as AvatarImplProps } from "../../../stubs/mui/avatar";
import get from "lodash/get";
import type { CSSProps } from "../../../reactTypes";
import type { CSSSize } from "../../../types";
import { getPixelSize } from "../../../util/size.util";
import { withStyleProps } from "../utils/hoc";

export type Props = {
  ...CSSProps,
  ...AvatarImplProps,
  size?: CSSSize,
  bgColor?: string,
  color?: string,
};

const Avatar: React.ComponentType<Props> = withStyleProps<Props>(
  AvatarImpl,
  ["size", "bgColor", "color"],
  ({ theme, size, bgColor = "secondary.dark", color }) => {
    size = getPixelSize(size);
    return {
      width: size,
      height: size,
      backgroundColor: get(theme.palette, bgColor, bgColor),
      color:
        get(theme.palette, color) ??
        getContrastColor(bgColor) ??
        theme.palette.secondary.light,
    };
  }
);

export default Avatar;
