// @flow
import * as React from "react";

export type InitStatus = "none" | "some" | "all";

export type InitActions = {
  setSomeInitialised: () => void,
  setAllInitialised: () => void,
  removeInitialised: () => void,
};
export type InitState = { initialised: InitStatus };

export const useInitState = (): [InitStatus, InitActions] => {
  const [state, setState] = React.useState<InitStatus>("none");
  const actions = React.useMemo(
    () => ({
      setSomeInitialised: () => setState("some"),
      setAllInitialised: () => setState("all"),
      removeInitialised: () => setState("none"),
    }),
    []
  );
  return [state, actions];
};
