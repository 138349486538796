// @flow
import * as React from "react";
import PickGifDialog from "./PickGifDialog";
// eslint-disable-next-line
import { GiphyFetch } from "@giphy/js-fetch-api";
import isEmpty from "lodash/isEmpty";
import type { GIF, SearchType } from "./PickGifDialog";
import type { Callback, URLStr } from "../../../../types";
import * as env from "../../../../config/env.config";

type Props = {
  open?: boolean,
  onConfirm: (URLStr) => any,
  onClose: Callback,
  asVideo?: boolean,
};

const BASE_OPTIONS = { limit: 15 };
const SEARCH_TYPES = ["gifs", "stickers", "text"];

const giphyFetch = new GiphyFetch(env.GIPHY_KEY);

const makeOptions = (offset: number, type: SearchType) => ({
  ...BASE_OPTIONS,
  type,
  offset,
});
const fetchTrendingGifs = (type: SearchType) => (offset: number) =>
  giphyFetch.trending(makeOptions(offset, type));
const fetchSearch = (term: string, type: SearchType) => (offset: number) =>
  giphyFetch.search(term, makeOptions(offset, type));

const PickGifDialogContainer: React.ComponentType<Props> = ({
  open,
  onConfirm,
  onClose,
  asVideo,
}) => {
  const [value, setValue] = React.useState("");
  const [searchType, setSearchType] = React.useState(SEARCH_TYPES[0]);
  const handleGifClick = (gif: GIF, e: SyntheticEvent<>) => {
    e.preventDefault();
    onConfirm(
      asVideo ? gif.images.fixed_height.mp4 : gif.images.fixed_height.url
    );
    onClose();
  };

  return (
    <PickGifDialog
      onGifClick={handleGifClick}
      open={open}
      textValue={value}
      onInputChange={setValue}
      fetchGifsHandler={
        isEmpty(value)
          ? fetchTrendingGifs(searchType)
          : fetchSearch(value, searchType)
      }
      searchTypes={SEARCH_TYPES}
      selectedSearchType={searchType}
      onChangeSearchType={setSearchType}
      onClose={onClose}
    />
  );
};

export default PickGifDialogContainer;
