// @flow
import type { BobcaatAccount } from "../../models/account.model";
import type { User } from "../../models/user.model";
import type { Portfolio } from "../../models/portfolio.model";
import portfolioAvatar from "../../assets/images/styleguide/robot.jpg";
import userAvatar from "../../assets/images/styleguide/penguin.png";
import moment from "moment";

export const user: User = {
  first_name: "Charles",
  last_name: "Sergei",
  avatar: userAvatar,
  email: "charles.sergei@bobcaat.com",
  preferences: {
    locale: "en-gb",
    theme: "light",
    hide_inactive_channels: false,
    palette: null,
  },
  limits: {
    can_use_hashtag_manager: false,
    can_use_post_approval: false,
    can_use_comment_manager: false,
    can_use_analytics: true,
    can_use_analytics_export_basic: false,
    can_use_viz_planner: false,
    max_accounts: 10,
    max_children: 5,
    max_schedules_per_account: 300,
  },
  referral_code: "RCCharles0",
  metamorph: true,
  last_login_at: null,
};

export const portfolio: Portfolio = {
  id: 1,
  name: "Charles Sergei Company",
  description: "A company that makes big money selling stuff to cats",
  timezone: 0,
  avatar: portfolioAvatar,
  color: "#BB1476",
  initials: "CS",
  is_calendar_public: false,
  display_order: 1,
  public_hash: null,
};

export const account: BobcaatAccount = {
  id: 1,
  portfolio_id: 1,
  entity_id: "123457876543",
  entity_name: "Charles Sergei",
  is_organisation: false,
  status_code: 0,
  user_name: "Charles Sergei",
  user_id: "1234567890",
  channel: "facebook",
  access_token_expiry: moment.unix(Date.now() / 1000 + 3600),
};
