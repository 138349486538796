// @flow
import expertImage from "../assets/images/plans/expert.png";
import masterImage from "../assets/images/plans/master.png";
import starterImage from "../assets/images/plans/starter.png";
import partnerImage from "../assets/images/plans/partner.png";
import bruceImage from "../assets/images/plans/bruce.jpg";

import keyBy from "lodash/keyBy";
import type { CurrencyCode, FindIteratee, Slug } from "../types";
import type { Plan, PlanFlavour } from "../models/plan.model";

const BRUCE_PlAN: Plan = {
  slug: "bruce",
  name: "Bruce Almighty",
  thumbnail: bruceImage,
  features: [],
  description: "",
};

const PARTNER: Plan = {
  slug: "partner",
  name: "refer.plans.partner.name",
  thumbnail: partnerImage,
  description: "refer.plans.partner.description",
  popular: true,
  features: [
    "refer.plans.partner.features.one",
    "refer.plans.partner.features.two",
    "refer.plans.partner.features.three",
    "refer.plans.partner.features.four",
    "refer.plans.partner.features.five",
    "refer.plans.partner.features.six",
  ],
};

const plans: Plan[] = [
  {
    slug: "starter",
    name: "refer.plans.starter.name",
    thumbnail: starterImage,
    description: "refer.plans.starter.description",
    features: [
      "refer.plans.starter.features.one",
      "refer.plans.starter.features.two",
      "refer.plans.starter.features.three",
    ],
  },
  PARTNER,
  {
    slug: "expert",
    name: "refer.plans.expert.name",
    thumbnail: expertImage,
    description: "refer.plans.expert.description",
    features: [
      "refer.plans.expert.features.one",
      "refer.plans.expert.features.two",
      "refer.plans.expert.features.three",
      "refer.plans.expert.features.four",
    ],
  },
  {
    slug: "master",
    name: "refer.plans.master.name",
    thumbnail: masterImage,
    description: "refer.plans.master.description",
    features: [
      "refer.plans.master.features.one",
      "refer.plans.master.features.two",
      "refer.plans.master.features.three",
    ],
  },
];

const allBySlug = keyBy([...plans, BRUCE_PlAN], "slug");

export const getPlan = (slug: ?Slug): ?Plan => allBySlug[slug];
export const planFlavourByPlanAndCurrency =
  (planId: Slug, currencyId: CurrencyCode): FindIteratee<PlanFlavour> =>
  (f) =>
    f.plan_slug === planId && f.currency === currencyId;

export default plans;
export const DEFAULT_PLAN = PARTNER;
