// @flow
import type { StyledElementType } from "../../../reactTypes";
import { styled } from "@mui/material/styles";

export const Center: StyledElementType<"div"> = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StickToTheTop: StyledElementType<"div"> = styled("div")(
  ({ minWidth, maxWidth }) => ({
    minWidth,
    maxWidth,
    position: "sticky",
    top: 0,
  })
);
