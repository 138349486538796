// @flow
import * as React from "react";
import type { DiagnosticError } from "../../util/diagnostics.util";
import { getDiagnosticErrorDescriptions } from "../../util/diagnostics.util";
import { Alert, Grow } from "@mui/material";
import type { Channel } from "../../models/channels/channel.model";
import useTranslate from "../../hooks/useTranslate";
import { ColumnStack } from "../lib/layout/stacks";

type Props = {
  errors: DiagnosticError[],
  channel: Channel,
};

const DiagnosticErrors: React.ComponentType<Props> = ({ errors, channel }) => {
  const t = useTranslate();
  const descriptions = React.useMemo(
    () => getDiagnosticErrorDescriptions(errors),
    [errors]
  );
  return (
    <ColumnStack>
      {descriptions.map((desc) => (
        <Grow key={desc.msg} in unmountOnExit>
          <Alert severity="info">{t(desc.msg, desc.data(channel))}</Alert>
        </Grow>
      ))}
    </ColumnStack>
  );
};

export default DiagnosticErrors;
