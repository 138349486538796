// @flow
import * as React from "react";
import { PagePanel } from "../../components/layout/PagePanel";
import LogoTitle from "../../components/display/LogoTitle";
import { GuestPageHeader } from "../../components/layout/PageHeader";
import useTranslate from "../../hooks/useTranslate";
import routes from "../../config/routes.config";
import {
  ColumnStack,
  SpaceBetweenRow,
} from "../../components/lib/layout/stacks";
import { UserAvatar } from "../../components/display/avatars";
import { Alert, Paper } from "@mui/material";
import { ReviewApprovalRequestContext } from "../../contexts/reviewApprovalRequest";
import { Body2, Header, Subheader } from "../../components/lib/display/Text";
import { styled } from "@mui/material/styles";
import { byProp } from "../../models/base.model";
import MiniPostsCalendarWidget from "../../components/widgets/MiniPostsCalendarWidget";
import { UserToken } from "../../lib/session.lib";
import CopyActionText from "../../components/lib/display/CopyActionText";
import * as links from "../../util/links.util";
import type { Moment } from "../../types";
import { LargePrimaryButton } from "../../components/lib/inputs/buttons";

const SummaryCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(3),
  alignItems: "flex-start",
  [theme.breakpoints.down("sm")]: {
    borderRadius: 0,
  },
}));

const SubmitButton = styled(LargePrimaryButton)(({ theme }) => ({
  alignSelf: "center",
  marginBottom: theme.spacing(2),
}));

const OverviewPanel: React.ComponentType<{}> = () => {
  const t = useTranslate();
  const isUser = UserToken.isValid();
  const route = routes.approval;
  const { approvalRequest, postApprovals, posts, onSendFeedback } =
    React.useContext(ReviewApprovalRequestContext);
  // Should not happen, the page container makes sure of that.
  if (!approvalRequest) return null;
  const countActionRequired =
    postApprovals.length -
    postApprovals.filter(byProp("status", "approved")).length;

  const handleNavigateToDay = (day: Moment) => {
    document.getElementById(day.unix().toString())?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <PagePanel variant="secondary" size="dense" color="alt">
      <ColumnStack spacing={2}>
        <LogoTitle />
        <GuestPageHeader title={t(route.label)} subtitle={t(route.subtitle)} />
      </ColumnStack>
      <SummaryCard>
        <UserAvatar user={approvalRequest.user} />
        <ColumnStack flexGrow={1} spacing={2}>
          <SpaceBetweenRow>
            <Header>{approvalRequest.title}</Header>
            <Subheader color="textSecondary">
              {t("global.postsCount", {
                count: approvalRequest.posts.length,
              })}
            </Subheader>
          </SpaceBetweenRow>
          <Body2 color="textSecondary">{approvalRequest.description}</Body2>
          <Subheader textAlign="right">
            {t("ApprovalPage.actionRequiredCount", {
              first_name: approvalRequest.user.first_name,
              count: countActionRequired,
            })}
          </Subheader>
        </ColumnStack>
      </SummaryCard>
      {isUser ? (
        <ColumnStack>
          <Body2 color="textSecondary">
            {t("ApprovalPage.userInstructions")}
          </Body2>
          <CopyActionText title={t("global.url")}>
            {links.approvalRequest(approvalRequest)}
          </CopyActionText>
          <CopyActionText title={t("global.password")}>
            {approvalRequest.password}
          </CopyActionText>
        </ColumnStack>
      ) : (
        approvalRequest.status !== "completed" && (
          <Alert severity="info">{t("ApprovalPage.instructions")}</Alert>
        )
      )}
      {approvalRequest.status === "completed" && (
        <Alert severity="info">{t("ApprovalPage.Main.requestClosed")}</Alert>
      )}
      <MiniPostsCalendarWidget posts={posts} onDayClick={handleNavigateToDay} />
      <SubmitButton
        disabled={approvalRequest?.status === "completed"}
        onClick={onSendFeedback}
      >
        {t(
          UserToken.isValid()
            ? "ApprovalPage.Main.readyForReview"
            : "ApprovalPage.Main.sendFeedbackButton"
        )}
      </SubmitButton>
    </PagePanel>
  );
};

export default OverviewPanel;
