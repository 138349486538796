// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Tab, Tabs } from "@mui/material";
import type { Callback } from "../../../types";
import type { PostAttachment } from "../../../models/post.model";
import type { Children } from "../../../reactTypes";
import useTranslate from "../../../hooks/useTranslate";
import FormDialog from "../../lib/feedback/FormDialog";
import FileOrURLPreview from "../../lib/display/FileOrURLPreview";
import { getAttachmentSrc } from "../../../models/attachment.model";

type Props = {
  title: string,
  onClose: Callback,
  onSubmit: Callback,
  onSetActivePage: (number) => any,
  activePage: number,
  attachments: PostAttachment[],
  children?: Children,
};

const Container = styled("div")(({ theme }) => ({
  maxHeight: "60vh",
  maxWidth: "90vw",
  position: "relative",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("xs")]: {
    maxWidth: "100vw",
  },
}));

const TabContent = styled(Paper)({
  height: 122,
  display: "flex",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
});

const StyledTabs = styled(Tabs)({
  maxWidth: 600,
  minWidth: "100%",
});

const AttachmentsEditorDialog: React.ComponentType<Props> = ({
  title,
  onClose,
  onSubmit,
  activePage,
  onSetActivePage,
  attachments,
  children,
}) => {
  const t = useTranslate();
  return (
    <FormDialog
      name="attachments-editor"
      title={t(title)}
      maxWidth="sm"
      onClose={onClose}
      onSubmit={onSubmit}
      disableEscapeKeyDown
    >
      <StyledTabs
        value={activePage}
        variant="scrollable"
        onChange={(e, value) => onSetActivePage(value)}
      >
        {attachments.map((att) => (
          <Tab
            key={att.id}
            label={
              <TabContent variant="outlined">
                <FileOrURLPreview src={getAttachmentSrc(att)} />
              </TabContent>
            }
          />
        ))}
      </StyledTabs>
      <Container>{children}</Container>
    </FormDialog>
  );
};

export default AttachmentsEditorDialog;
