// @flow
import React from "react";
import useAttachmentsDiagnostic from "./useAttachmentsDiagnostic";
import useContentDiagnostic from "./useContentDiagnostic";
import useTargetDiagnostics from "./useTargetDiagnostics";
import usePublishDiagnostics from "./usePublishDiagnostics";
import type { Channel } from "../../models/channels/channel.model";
import type { DiagnosticError } from "../../util/diagnostics.util";
import PostBuilderContext from "../../contexts/postBuilder";

const usePostDiagnostic = (channel: ?Channel): DiagnosticError[] => {
  const {
    post: { status },
  } = React.useContext(PostBuilderContext);
  const attachmentDiagnostics = useAttachmentsDiagnostic(channel);
  const contentDiagnostics = useContentDiagnostic(channel);
  const targetDiagnostics = useTargetDiagnostics(channel);
  const publishDiagnostics = usePublishDiagnostics();
  // Don't validate anything in draft.
  return status === "draft"
    ? []
    : [
        ...attachmentDiagnostics,
        ...contentDiagnostics,
        ...targetDiagnostics,
        ...publishDiagnostics,
      ];
};

export default usePostDiagnostic;
