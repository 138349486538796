// @flow
import React, { useMemo } from "react";
import { useCurrentThemeName, useCurrentPalette } from "../store/selectors";
import themeOptions from "../config/themes.config";
import type { Provider } from "../reactTypes";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";

type Props = {
  components?: Object,
};

/**
 * Used to wrap the whole app with a theme context.
 *
 * @constructor
 */
const ThemeProvider: Provider<mixed, Props> = ({ children, components }) => {
  const palette = useCurrentPalette();
  const themeName = useCurrentThemeName();
  const theme = useMemo(() => {
    const options = themeOptions[themeName];
    // Update the meta tag with the theme background.
    const metaThemeColor = document.getElementsByTagName("meta")["theme-color"];
    if (metaThemeColor) metaThemeColor.content = options.palette.background.alt;
    return responsiveFontSizes(
      createTheme({
        ...options,
        palette: { ...options.palette, ...palette.palette },
        components: { ...options.components, ...components },
      })
    );
  }, [components, palette, themeName]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
