// @flow
import * as React from "react";
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  useTheme,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import type { TextProps } from "./Text";
import type { Children } from "../../../reactTypes";
import useToggle from "../../../hooks/useToggle";
import { styled } from "@mui/material/styles";
import { ChevronRightRounded } from "@mui/icons-material";

type Props = {
  style?: Object,
  avatar?: React.Node,
  icon?: React.Node,
  onClick?: (e: SyntheticEvent<>) => any,
  primary?: React.Node,
  secondary?: React.Node,
  secondaryAction?: (e: SyntheticEvent<>) => any,
  SecondaryActionIcon?: React.ComponentType<any>,
  primaryProps?: Partial<TextProps>,
  secondaryProps?: Partial<TextProps>,
  disabled?: boolean,
  disablePadding?: boolean,
  dense?: boolean,
  children?: Children,
  selected?: boolean,
  disableRipple?: boolean,
};

const StdListItem: React.ComponentType<Props> = React.memo(
  ({
    onClick,
    avatar,
    icon,
    primary,
    primaryProps,
    secondaryProps,
    secondary,
    secondaryAction,
    SecondaryActionIcon,
    disablePadding = true,
    children,
    disabled,
    selected,
    disableRipple,
    ...props
  }) => {
    const content = (
      <>
        {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {primary && (
          <ListItemText
            primary={primary}
            primaryTypographyProps={primaryProps}
            secondary={secondary}
            secondaryTypographyProps={secondaryProps}
          />
        )}
        {children}
      </>
    );

    return (
      <ListItem
        disablePadding={disablePadding}
        secondaryAction={
          SecondaryActionIcon ? (
            <IconButton edge="end" size="small" onClick={secondaryAction}>
              <SecondaryActionIcon />
            </IconButton>
          ) : undefined
        }
        {...props}
      >
        {onClick ? (
          <ListItemButton
            disableRipple={disableRipple}
            disabled={disabled}
            onClick={onClick}
            selected={selected}
          >
            {content}
          </ListItemButton>
        ) : (
          content
        )}
      </ListItem>
    );
  }
);

type CollapsibleStdListItemProps = {
  ...Omit<Props, "secondaryAction" | "SecondaryActionIcon">,
  disabled?: boolean,
  children?: Children,
  collapseOnItemClick?: boolean,
};

export const CollapsibleStdListItem: React.ComponentType<CollapsibleStdListItemProps> =
  React.memo(({ children, disabled, style, collapseOnItemClick, ...props }) => {
    const [open, handleToggle] = useToggle(false);
    const theme = useTheme();
    return (
      <>
        <StdListItem
          style={{
            ...(open && { backgroundColor: theme.palette.background.paper }),
            ...style,
          }}
          onClick={handleToggle}
          {...props}
        >
          {disabled ? undefined : open ? <ExpandLess /> : <ExpandMore />}
        </StdListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div onClick={collapseOnItemClick ? handleToggle : undefined}>
            {children}
          </div>
        </Collapse>
      </>
    );
  });

export const CircularSkeletonListItem: React.ComponentType<{
  animation?: boolean,
  dense?: boolean,
}> = ({ animation, dense }) => (
  <StdListItem
    dense={dense}
    disablePadding={false}
    avatar={
      <Skeleton
        variant="circular"
        height={dense ? 24 : 42}
        width={dense ? 24 : 42}
        animation={animation}
      />
    }
    primary={<Skeleton animation={animation} />}
    primaryProps={{ width: "80%" }}
    secondaryProps={{ width: "80%" }}
  />
);

export const RoundedSkeletonListItem: React.ComponentType<{
  animation?: boolean,
}> = ({ animation }) => (
  <StdListItem
    disablePadding={false}
    avatar={
      <Skeleton
        variant="rounded"
        height={42}
        width={42}
        animation={animation}
      />
    }
    primary={<Skeleton animation={animation} />}
    secondary={<Skeleton animation={animation} />}
    primaryProps={{ width: "80%" }}
    secondaryProps={{ width: "80%" }}
  />
);

const NavigateForwardIcon = styled(ChevronRightRounded)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const FONT_WEIGHT_BOLD = { fontWeight: "bold" };
const TEXT_ELLIPSIS = { textOverflow: "ellipsis", whiteSpace: "nowrap" };
const BOLD_ELLIPSIS = { ...FONT_WEIGHT_BOLD, ...TEXT_ELLIPSIS };

export const NavigationListItem: React.ComponentType<{
  ...Pick<
    Props,
    | "icon"
    | "avatar"
    | "primary"
    | "secondary"
    | "onClick"
    | "style"
    | "selected"
    | "dense"
  >,
  important?: boolean,
}> = ({ important, ...props }) => (
  <StdListItem
    {...props}
    primaryProps={{ style: important ? BOLD_ELLIPSIS : TEXT_ELLIPSIS }}
    secondaryProps={{ component: "span" }}
  >
    <NavigateForwardIcon />
  </StdListItem>
);

export default StdListItem;
