// @flow
import * as React from "react";
import ConversationEngagementEditor from "./ConversationEngagementEditor";
import { useScopedConversation } from "../../../../scopes/scopes";
import { useStoreActions } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import * as messagingService from "../../../../services/messaging.service";
import type { LocalAttachment } from "../../../../models/attachment.model";

const MessagingEngagementEditorContainer: React.ComponentType<{}> = () => {
  const conversation = useScopedConversation();
  const actions = useStoreActions();
  const navigate = useNavigate();

  const handleSendNewMessage = (text: string, media: LocalAttachment[]) => {
    return (
      conversation &&
      messagingService.addMessage(actions)(conversation.id, { text, media })
    );
  };

  const handleClose = React.useCallback(() => {
    navigate("..", { relative: "route" });
  }, [navigate]);

  if (!conversation) {
    return null;
  }

  return (
    <ConversationEngagementEditor
      onSendNewMessage={handleSendNewMessage}
      conversation={conversation}
      onClose={handleClose}
    />
  );
};

export default MessagingEngagementEditorContainer;
