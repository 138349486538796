// @flow
import { useMemo } from "react";

type onChange = (string, ...any) => any;
type HelperText = (string) => string;
type IsError = (string) => boolean;

type Props = {
  maxLength?: number,
  preventOverflow?: boolean,
  onChange: onChange,
};

type ReturnValue = [onChange, HelperText, IsError];
const constantEmptyString = () => "";
const constantFalse = () => false;

const useInputCharLimitHelper = ({
  maxLength,
  preventOverflow,
  onChange,
}: Props): ReturnValue => {
  return useMemo(
    () => [
      // OnChange to call
      maxLength && preventOverflow
        ? (val: string, ...args: any) => {
            val.length <= maxLength && onChange(val, ...args);
          }
        : onChange,
      // helperText builder.
      maxLength
        ? (val: string) => `${val.length} / ${maxLength}`
        : constantEmptyString,
      // isError
      maxLength ? (val: string) => val.length > maxLength : constantFalse,
    ],
    [maxLength, preventOverflow, onChange]
  );
};

export default useInputCharLimitHelper;
