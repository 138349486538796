// @flow
import * as React from "react";
import { NavigationListItem } from "../../lib/display/listItems";
import type { ModelID } from "../../../types";
import type { Conversation } from "../../../models/conversation.model";
import { getConversationTitle } from "../../../models/conversation.model";
import { AutoSizeList } from "../../lib/layout/FixedSizeList";
import type { TFunction } from "../../../hooks/useTranslate";
import useTranslate from "../../../hooks/useTranslate";
import type { ConversationSummary } from "../../../models/conversationSummary.model";
import { ConversationAvatar } from "../../display/avatars";
import { isEmpty } from "lodash";
import PageRootOutlet from "../../layout/PageRootOutlet";
import type { InitStatus } from "../../../store/reducers/lib/init.state";

type Props = {
  conversations: Conversation[],
  selectedConversationId: ?ModelID,
  summaries: { [ModelID]: ConversationSummary },
  onSelectConversation: (ModelID) => any,
  conversationsInitialised: InitStatus,
};

type ConversationItemProps = {
  item: Conversation,
  style: Object,
  selectedConversationId: ?ModelID,
  summaries: { [ModelID]: ConversationSummary },
  onSelectConversation: (ModelID) => any,
  t: TFunction,
};

const ConversationItem: React.ComponentType<ConversationItemProps> = ({
  item,
  selectedConversationId,
  summaries,
  onSelectConversation,
  style,
  t,
}) => {
  const summary = summaries[item.id];
  const unread = summary?.unread_messages ?? 0;
  const prefix = unread ? `(${unread}) ` : "";
  const head = isEmpty(summary?.head)
    ? t("EngagementHubSection.senderSharedAttachment")
    : summary.head;
  return (
    <NavigationListItem
      style={style}
      selected={selectedConversationId === item.id}
      onClick={() => onSelectConversation(item.id)}
      avatar={<ConversationAvatar conversation={item} />}
      primary={prefix + getConversationTitle(item) || <>&nbsp;</>}
      secondary={`${summary?.updated_at?.fromNow()} — ${head}`}
      important={!!unread}
    />
  );
};

const EngagementHubConversations: React.ComponentType<Props> = ({
  conversations,
  conversationsInitialised,
  ...itemProps
}) => {
  const t = useTranslate();
  return (
    <>
      <AutoSizeList
        itemSize={61}
        items={conversations}
        ItemComponent={ConversationItem}
        itemProps={{ ...itemProps, t }}
        itemsInitStatus={conversationsInitialised}
      />
      <PageRootOutlet />
    </>
  );
};

export default EngagementHubConversations;
