// @flow
import * as React from "react";
import { useFilesValidator } from "../../../hooks/useFilesValidator";
import type { Props as FilesValidatorProps } from "../../../hooks/useFilesValidator";
import DropContext, { DropContextProvider } from "../../../contexts/drop";
import { styled } from "@mui/material/styles";
import BlurLayer from "../surfaces/BlurLayer";
import { AttachFileRounded } from "@mui/icons-material";
import { RowStack } from "../layout/stacks";
import { Header } from "../display/Text";
import useTranslate from "../../../hooks/useTranslate";

const Root = styled("div")`
  position: relative;
`;

const Overlay = styled(BlurLayer, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: 0,
  left: 0,
  zIndex: isActive ? 1 : -1,
  opacity: isActive ? 1 : 0,
  transition: "opacity 0.3s ease",
}));

type FileDropInnerProps = {
  children: React.Node,
  disabled?: boolean,
};

const FileDropInner: React.ComponentType<FileDropInnerProps> = ({
  children,
  disabled,
}) => {
  const { isActive, ...rest } = React.useContext(DropContext);
  const t = useTranslate();
  return (
    <Root {...(!disabled && rest)}>
      {children}
      <Overlay isActive={isActive} blur={6}>
        <RowStack>
          <AttachFileRounded /> <Header>{t("global.dropFilesHere")}</Header>
        </RowStack>
      </Overlay>
    </Root>
  );
};

export type Props = {
  ...FilesValidatorProps,
  ...FileDropInnerProps,
  onSelect: (File[]) => any,
};

const FileDropContainer: React.ComponentType<Props> = ({
  onSelect,
  children,
  disabled,
  ...props
}) => {
  const validateFiles = useFilesValidator({
    ...props,
  });
  const handleSelectFiles = React.useCallback(
    (files: File[]) => validateFiles(files).then(onSelect),
    [onSelect, validateFiles]
  );

  return (
    <DropContextProvider onSelectFiles={handleSelectFiles}>
      <FileDropInner disabled={disabled}>{children}</FileDropInner>
    </DropContextProvider>
  );
};

export default FileDropContainer;
