// @flow
import * as React from "react";
import type { Snack } from "../../models/alerts.model";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";

const snackReducer = sequenceReducer<Snack>({
  key: "id",
});
export type SnackActions = SequenceActions<Snack>;
export type SnackState = SequenceState<Snack>;

export const useSnackReducer = (): [SnackState, SnackActions] => {
  const [state, dispatch] = useSequenceReducer(snackReducer);
  const actions = React.useMemo(
    () => makeSequenceActions(dispatch),
    [dispatch]
  );
  return [state, actions];
};
