// @flow
import * as React from "react";
import { useStoreActions } from "../../store/store";
import { useModalAlert } from "../../store/selectors";
import { Check, Info, Warning } from "@mui/icons-material";
import { Error } from "@mui/icons-material";
import { propMap } from "../../components/lib/utils/props";
import { DialogContentText, Fade, Zoom } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Body1, Body2 } from "../../components/lib/display/Text";
import useTranslate from "../../hooks/useTranslate";
import Translate from "../../components/lib/display/Translate";
import type { Severity } from "../../models/alerts.model";
import Dialog from "../../components/lib/feedback/Dialog";

const iconStyleMixin = () => ({
  margin: "auto",
  minHeight: "3rem",
  minWidth: "3rem",
  borderRadius: "1.5rem",
  borderWidth: "3px",
  padding: 6,
  borderStyle: "solid",
});

const ErrorIcon = styled(Error)(iconStyleMixin);
const SuccessIcon = styled(Check)(iconStyleMixin);

const Disclaimer = styled(Body2)(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)};
`
);

const VARIANTS: Record<
  Severity,
  { Icon: React.ComponentType<{}>, color: string }
> = {
  success: { Icon: SuccessIcon, color: "green" },
  error: { Icon: ErrorIcon, color: "red" },
  // Below are not really supported yet.
  info: { Icon: Info, color: "info" },
  warning: { Icon: Warning, color: "orange" },
};

const getVariantProps = propMap(VARIANTS, "success");

const ModalAlert: React.ComponentType<{}> = () => {
  const storeActions = useStoreActions();
  const alert = useModalAlert();
  const t = useTranslate();
  const { Icon, color } = getVariantProps(alert?.severity);

  return (
    alert && (
      <Dialog
        maxWidth="xs"
        onClose={storeActions.global.closeModalAlert}
        name="alert-modal"
        title={
          <>
            <Zoom in timeout={500} style={{ transitionDelay: "200ms" }}>
              <Body1
                align="center"
                style={{ color, borderColor: color }}
                gutterBottom
              >
                <Icon />
              </Body1>
            </Zoom>
            <Fade in timeout={500} style={{ transitionDelay: "1000ms" }}>
              <Body1 align="center" style={{ color, borderColor: color }}>
                {alert.translate
                  ? t(alert.title, alert.titleData)
                  : alert.title}
              </Body1>
            </Fade>
          </>
        }
      >
        <Fade in timeout={500} style={{ transitionDelay: "1000ms" }}>
          <DialogContentText
            align="center"
            component="div"
            style={{ whiteSpace: "pre-line" }}
          >
            {alert.translate ? (
              <Translate
                id={alert.message}
                data={alert.messageData}
                components={alert.messageComponents}
              />
            ) : (
              alert.message
            )}
            {alert.severity === "error" && (
              <Disclaimer display="block">
                <Translate id="StatusDialog.disclaimer" />
              </Disclaimer>
            )}
          </DialogContentText>
        </Fade>
      </Dialog>
    )
  );
};

export default ModalAlert;
