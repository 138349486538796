// @flow
import * as React from "react";
import DurationPickerColumn from "./DurationPickerColumn";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import type { Integer } from "../../../../types";

type Props = {
  onChangeDays?: (seconds: Integer) => any,
  onChangeHours?: (seconds: Integer) => any,
  onChangeMinutes?: (seconds: Integer) => any,
  onChangeSeconds?: (seconds: Integer) => any,
  days: Integer,
  hours: Integer,
  minutes: Integer,
  seconds: Integer,
};

const Root = styled(Paper)({
  display: "flex",
  userSelect: "none",
  height: "105px",
  minWidth: "300px",
  width: "100%",
});

const DurationPicker: React.ComponentType<Props> = ({
  onChangeDays,
  onChangeHours,
  onChangeMinutes,
  onChangeSeconds,
  days,
  hours,
  minutes,
  seconds,
}) => (
  <Root variant="outlined">
    {onChangeDays && (
      <DurationPickerColumn onChange={onChangeDays} unit="days" value={days} />
    )}
    {onChangeHours && (
      <DurationPickerColumn
        onChange={onChangeHours}
        unit="hours"
        value={hours}
      />
    )}
    {onChangeMinutes && (
      <DurationPickerColumn
        onChange={onChangeMinutes}
        unit="minutes"
        value={minutes}
      />
    )}
    {onChangeSeconds && (
      <DurationPickerColumn
        onChange={onChangeSeconds}
        unit="seconds"
        value={seconds}
      />
    )}
  </Root>
);

export default DurationPicker;
