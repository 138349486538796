// @flow
import type { ModelID, Slug } from "../types";
import isEmpty from "lodash/isEmpty";
import { byMaybeProp } from "./base.model";

export type Hashtag = string;

/**
 * A preset of hashtags to insert in posts.
 */
export type HashtagGroup = {
  id: ModelID,
  /**
   * The name of the group.
   */
  name: string,
  /**
   * The optional portfolio this group applies only to.
   */
  portfolio_id: ?ModelID,
  /**
   * The optional channel this group applies only to.
   */
  channel: ?Slug,
  /**
   * The list of hashtags in the group.
   */
  hashtags: Hashtag[],
};

type FilterHashtagGroupsParams = {
  portfolioId?: ?ModelID,
  channel?: ?Slug,
  search?: string,
};

/**
 * Filter hashtag groups based on some parameters.
 * @param hashtagGroups The hashtag groups.
 * @param portfolioId The ID of the portfolio the hashtag group must refer to.
 * @param channel The slug of the channel the hashtag group must refer to.
 * @param search The search string to look for in the name.
 * @return {HashtagGroup[]}
 */
export const filterHashtagGroups = (
  hashtagGroups: HashtagGroup[],
  { portfolioId, channel, search }: FilterHashtagGroupsParams
): HashtagGroup[] => {
  if (!isEmpty(channel)) {
    hashtagGroups = hashtagGroups.filter(
      byMaybeProp<HashtagGroup>("channel", channel)
    );
  }
  if (portfolioId) {
    hashtagGroups = hashtagGroups.filter(
      byMaybeProp<HashtagGroup>("portfolio_id", portfolioId)
    );
  }
  if (search && search.length > 2) {
    const s = search.toLowerCase();
    hashtagGroups = hashtagGroups.filter((g) =>
      g.name.toLowerCase().includes(s)
    );
  }

  return hashtagGroups;
};

export const hashtagGroupToString = (hashtagGroup: HashtagGroup): string =>
  hashtagGroup.hashtags.map((h) => "#" + h).join(" ");
