// @flow
import * as React from "react";
import { useScopedPortfolio } from "../../../scopes/scopes";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import CalendarWidget from "../../../components/widgets/CalendarWidget";
import { styled } from "@mui/material/styles";
import { DRAWER_WIDTH } from "../../../components/navigation/AppNavigation/Drawers/MiniVariantDrawer";

const SizedPanel = styled(PrimaryPagePanel)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    // on large screen, we need to force the max width so that
    // the calendar does not grow with its content.
    width: `calc(100vw - ${DRAWER_WIDTH}px)`,
  },
}));

const CalendarPage: React.ComponentType<empty> = () => {
  const pf = useScopedPortfolio();
  if (!pf) return null;

  return (
    <SizedPanel
      route={routes.app.nested.portfolios.nested.calendar}
      subtitleData={{ portfolio: pf.name }}
      size="fill"
    >
      <CalendarWidget portfolio={pf} />
    </SizedPanel>
  );
};

export default CalendarPage;
