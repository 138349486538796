// @flow
import * as React from "react";
import type { App } from "../../../../../models/integration.model";
import type { Callback } from "../../../../../types";
import StdListItem from "../../../../lib/display/listItems";
import Translate from "../../../../lib/display/Translate";
import Avatar from "../../../../lib/display/Avatar";
import StatusChip from "../../../../lib/display/StatusChip";
import {
  SmallDeleteButton,
  SmallInfoButton,
} from "../../../../lib/inputs/buttons";
import { OpenInNewRounded } from "@mui/icons-material";
import { Link } from "@mui/material";
import withConfirmDialog from "../../../../lib/feedback/withConfirmDialog";
import { styled } from "@mui/material/styles";
import { InlineRowStack } from "../../../../lib/layout/stacks";
import useIsMobile from "../../../../../hooks/useIsMobile";

type Props = {
  app: App,
  connected?: boolean,
  onDelete: Callback,
};

const DeleteIntegrationButton = withConfirmDialog(SmallDeleteButton, {
  DialogProps: {
    name: "delete-integration",
    title: "IntegrationsTab.item.confirmDelete.title",
    message: "IntegrationsTab.item.confirmDelete.message",
  },
});

const ButtonSpacer = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const IntegrationAppItem: React.ComponentType<Props> = ({
  app,
  connected,
  onDelete,
}) => {
  const isMobile = useIsMobile();
  return (
    <StdListItem
      primary={
        <InlineRowStack>
          {app.name}
          {connected && (
            <StatusChip severity="success" dense>
              <Translate id="global.connected" />
            </StatusChip>
          )}
        </InlineRowStack>
      }
      secondary={
        <Translate id={`refer.integrations.${app.slug}.description`} />
      }
      avatar={
        !isMobile ? (
          <Avatar src={app.thumbnail} variant="rounded" bgColor="paper" />
        ) : undefined
      }
    >
      <ButtonSpacer>
        {connected ? (
          <DeleteIntegrationButton onClick={onDelete}>
            <Translate id="global.delete" />
          </DeleteIntegrationButton>
        ) : (
          <SmallInfoButton
            component={Link}
            href={app.connectURL}
            target="_blank"
          >
            <OpenInNewRounded fontSize="small" />{" "}
            {!isMobile && <Translate id="global.connect" />}
          </SmallInfoButton>
        )}
      </ButtonSpacer>
    </StdListItem>
  );
};

export default IntegrationAppItem;
