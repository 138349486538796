// @flow
import * as React from "react";
import type { MoveAccountToPortfolioFormDef } from "./MoveAccountToPortfolioDialog";
import MoveAccountToPortfolioDialog from "./MoveAccountToPortfolioDialog";
import { useStoreActions } from "../../../store/store";
import { FormActions, useForm } from "../../../hooks/useForm";
import { useScopedPortfolio } from "../../../scopes/scopes";
import type { Callback } from "../../../types";
import { useSortedPortfolios } from "../../../store/selectors";
import type { BobcaatAccount } from "../../../models/account.model";
import * as accountService from "../../../services/account.service";

type Props = {
  open?: boolean,
  onClose: Callback,
  account: BobcaatAccount,
};

const FORM_FIELDS: MoveAccountToPortfolioFormDef = {
  portfolio: { type: "object" },
};

const MoveAccountToPortfolioDialogContainer: React.ComponentType<Props> = ({
  open,
  onClose,
  account,
}) => {
  const pf = useScopedPortfolio();
  const portfolios = useSortedPortfolios();
  const actions = useStoreActions();
  const form = useForm(FORM_FIELDS, {
    portfolio: pf,
  });

  const handleSubmit = () => {
    if (FormActions.validate(form)) {
      const payload = { ...FormActions.collect(form.state) };
      return accountService.moveAccount(actions)({
        id: account.id,
        portfolio_id: payload.portfolio.id,
      });
    }
  };

  return (
    <MoveAccountToPortfolioDialog
      open={open}
      portfolios={portfolios}
      onClose={onClose}
      form={form}
      onSubmit={handleSubmit}
      account={account}
    />
  );
};

export default MoveAccountToPortfolioDialogContainer;
