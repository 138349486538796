// @flow
import * as React from "react";
import routes from "../../config/routes.config";
import {
  useScopedApprovalRequest,
  useScopedPortfolio,
} from "../../scopes/scopes";
import ApprovalRequestWidget from "../../components/widgets/ApprovalRequestWidget";
import { PrimaryPagePanel } from "../../components/layout/PagePanel";
import useLocationParams from "../../hooks/useLocationParams";
import compact from "lodash/compact";
import { parseModelId } from "../../models/base.model";

const ApprovalRequestsPage: React.ComponentType<{}> = () => {
  const pf = useScopedPortfolio();
  const approvalRequest = useScopedApprovalRequest();
  const rootDir = routes.app.nested.portfolios.nested;
  const { postIds: postIdsUnsafe } = useLocationParams(["postIds"]);
  const postIds = React.useMemo(
    () => compact((postIdsUnsafe ?? "").split(",").map(parseModelId)),
    [postIdsUnsafe]
  );

  if (!pf) {
    // For the type checker. Should not happen.
    return false;
  }

  return (
    <PrimaryPagePanel
      size="fill"
      route={
        approvalRequest
          ? rootDir.approvalRequest.nested.edit
          : rootDir.addApprovalRequest
      }
    >
      <ApprovalRequestWidget
        approvalRequest={approvalRequest}
        portfolio={pf}
        initialPostIds={postIds}
      />
    </PrimaryPagePanel>
  );
};

export default ApprovalRequestsPage;
