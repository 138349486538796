// @flow
import { API as backend } from "./backend.service";
import * as snacks from "../models/alerts.model";
import type { AsyncVoidAction } from "./types";
import type { ModelID } from "../types";
import type { Pagination } from "./backend.service/types";

export const getNotifications: AsyncVoidAction<?Pagination> =
  (actions) => (pagination) =>
    backend.notification
      .getNotifications(pagination)
      .then(actions.notification.replaceAll);

export const deleteNotification: AsyncVoidAction<ModelID> =
  (actions) => (notificationId) =>
    backend.notification.deleteNotification(notificationId).then(() => {
      actions.notification.remove(notificationId);
      actions.snacks.append(
        snacks.localSuccess({ message: "store.notification.deleted" })
      );
    });

export const deleteAllNotifications: AsyncVoidAction<void> = (actions) => () =>
  backend.notification.deleteAllNotifications().then(() => {
    actions.notification.clear();
    actions.snacks.append(
      snacks.localSuccess({ message: "store.notification.deleted" })
    );
  });

export const markNotificationAsRead: AsyncVoidAction<ModelID> =
  (actions) => (notificationId) =>
    backend.notification
      .markNotificationAsRead(notificationId)
      .then(() => actions.notification.patch(notificationId, { read: true }));

export const markAllNotificationsAsRead: AsyncVoidAction<void> =
  (actions) => () =>
    backend.notification
      .markAllNotificationsAsRead()
      .then(() => actions.notification.patchAll({ read: true }));

export const markNotificationAsUnread: AsyncVoidAction<ModelID> =
  (actions) => (notificationId) =>
    backend.notification
      .markNotificationAsUnread(notificationId)
      .then(() => actions.notification.patch(notificationId, { read: false }));
