// @flow
import * as React from "react";
import URLPreview from "./URLPreview";
import FilePreview from "./FilePreview";
import type { Props as URLPreviewProps } from "./URLPreview";
import type { Props as FilePreviewProps } from "./FilePreview";
import { cast } from "../../../types";

type Props = URLPreviewProps | FilePreviewProps;

const FileOrURLPreview: React.ComponentType<Props> = React.forwardRef(
  (props, ref) =>
    typeof props.src === "string" ? (
      <URLPreview ref={ref} {...cast<URLPreviewProps>(props)} />
    ) : (
      <FilePreview ref={ref} {...cast<FilePreviewProps>(props)} />
    )
);

export default FileOrURLPreview;
