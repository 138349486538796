// @flow
import * as React from "react";
import type { Props as SettingsSectionProps } from "../../../layout/SettingsSection";
import SettingsSection from "../../../layout/SettingsSection";
import type { Form, StringFormFieldDef } from "../../../../hooks/useForm";
import { FormActions } from "../../../../hooks/useForm";
import { ResponsiveInlineRowStack } from "../../../lib/layout/stacks";
import FormTextInput from "../../../lib/inputs/form/FormTextInput";
import { Collapse } from "@mui/material";
import useTranslate from "../../../../hooks/useTranslate";
import useIsMobile from "../../../../hooks/useIsMobile";

export type PasswordTabFormDef = {
  current_password: StringFormFieldDef,
  new_password: StringFormFieldDef,
};

type Props = {
  ...Omit<SettingsSectionProps, "title" | "children">,
  form: Form<PasswordTabFormDef>,
};

const readOnlyInputProps = { readOnly: true, disableUnderline: true };

const PasswordSection: React.ComponentType<Props> = ({ form, ...props }) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const InputProps = !props.editing ? readOnlyInputProps : undefined;

  return (
    <SettingsSection
      title="Page.SettingsSection.changePassword"
      collapsed={!props.editing}
      {...props}
    >
      <ResponsiveInlineRowStack>
        <FormTextInput
          InputProps={InputProps}
          type="password"
          autoComplete="currentPassword"
          name="currentPassword"
          formField={form.state.current_password}
          onChange={FormActions.onChangeField(form.set, "current_password")}
          label={t("Page.SettingsSection.currentPassword")}
          sx={{ width: { sm: 200 } }}
        />
        <Collapse
          orientation={isMobile ? "vertical" : "horizontal"}
          in={!!form.state.current_password.value.length}
        >
          <FormTextInput
            InputProps={InputProps}
            formField={form.state.new_password}
            onChange={FormActions.onChangeField(form.set, "new_password")}
            type="password"
            autoComplete="newPassword"
            name="newPassword"
            label={t("Page.SettingsSection.newPassword")}
            sx={{ width: { xs: "100%", sm: 200 } }}
          />
        </Collapse>
      </ResponsiveInlineRowStack>
    </SettingsSection>
  );
};

export default PasswordSection;
