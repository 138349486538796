// @flow
import * as React from "react";
import { useLockState } from "./lib/lock.state";
import type {
  AnalyticsReport,
  KeyedAnalyticsReports,
} from "../../models/analytics.model";
import keyBy from "lodash/keyBy";
import type { LockActions, LockState } from "./lib/lock.state";

export type AnalyticsActions = {
  ...LockActions,
  addReplace: (AnalyticsReport[]) => void,
};

export type AnalyticsState = {
  ...LockState,
  reports: KeyedAnalyticsReports,
};

export const useAnalyticsReducer = (): [AnalyticsState, AnalyticsActions] => {
  const [reports, setReports] = React.useState<KeyedAnalyticsReports>({});
  const [locked, lockActions] = useLockState();

  const actions = React.useMemo(
    () => ({
      addReplace: (reports: AnalyticsReport[]) =>
        setReports((prev) => ({ ...prev, ...keyBy(reports, "account_id") })),
      ...lockActions,
    }),
    [setReports, lockActions]
  );

  return [{ reports, locked }, actions];
};
