// @flow
import moment from "moment";
import type { I18nKey } from "../../../types";
import { Moment } from "../../../types";

export type DateRangePeriod = "d" | "w" | "M" | "c";

export type DateRangePeriodOption = {
  value: DateRangePeriod,
  label: I18nKey,
};

export const DAY: DateRangePeriodOption = {
  value: "d",
  label: "refer.analyticsPeriods.d",
};
export const WEEK: DateRangePeriodOption = {
  value: "w",
  label: "refer.analyticsPeriods.w",
};
export const MONTH: DateRangePeriodOption = {
  value: "M",
  label: "refer.analyticsPeriods.M",
};
export const CUSTOM: DateRangePeriodOption = {
  value: "c",
  label: "refer.analyticsPeriods.c",
};

const all: DateRangePeriodOption[] = [DAY, WEEK, MONTH, CUSTOM];

export const yesterday = (): Moment =>
  moment().startOf(DAY.value).add(-1, DAY.value);
export const getOptionLabel = (opt: DateRangePeriodOption): string => opt.label;
export const getOptionValue = (opt: DateRangePeriodOption): I18nKey =>
  opt.value;

export default all;
