// @flow
import * as React from "react";
import { withDefaultProps } from "../utils/hoc";
import { styled } from "@mui/material/styles";
import btnGoogleLightDisabled from "../../../assets/images/google/btn_google_light_disabled_ios.svg";
import btnGoogleLightNormal from "../../../assets/images/google/btn_google_light_normal_ios.svg";
import LoadingButton from "@mui/lab/LoadingButton";

type DefaultProps = "variant" | "size" | "color";
type Props = React.ElementProps<typeof LoadingButton>;

const Button = LoadingButton;

export const SmallPrimaryButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "contained",
  color: "primary",
  size: "small",
});

export const SmallSecondaryButton: React.ComponentType<Props> =
  withDefaultProps<Props, DefaultProps>(Button, {
    variant: "contained",
    color: "secondary",
    size: "small",
  });

export const SmallNeutralButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "outlined",
  color: "neutral",
  size: "small",
});

export const SmallTextButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "text",
  color: "neutral",
  size: "small",
});

export const SmallInfoButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "contained",
  color: "info",
  size: "small",
});

export const SmallDeleteButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "contained",
  color: "error",
  size: "small",
});

export const PrimaryButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "contained",
  color: "primary",
  size: "medium",
});

export const SecondaryButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "contained",
  color: "secondary",
  size: "medium",
});

export const NeutralButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "outlined",
  color: "neutral",
  size: "medium",
});

export const DeleteButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "contained",
  color: "error",
  size: "medium",
});

export const PrimaryTextButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "text",
  color: "primary",
  size: "medium",
});

export const NeutralTextButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "text",
  color: "neutral",
  size: "medium",
});

export const LargePrimaryButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "contained",
  color: "primary",
  size: "large",
});

export const LargeTextButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "text",
  color: "neutral",
  size: "large",
});

export const LargeDeleteButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "text",
  color: "error",
  size: "large",
});

export const LargeNeutralButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  DefaultProps
>(Button, {
  variant: "outlined",
  color: "neutral",
  size: "large",
});

const GoogleButtonBase = styled(Button)(({ theme }) => ({
  backgroundColor: "#4285F4",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "-2px center",
  borderRadius: 4,
  backgroundImage: `url('${btnGoogleLightNormal}')`,
  backgroundSize: 62,
  // padding: theme.spacing(1),
  paddingLeft: theme.spacing(9),
  paddingRight: theme.spacing(2),
  fontFamily: "Roboto",
  color: "white",
  fontWeight: 500,
  boxShadow: theme.shadows[1],
  "&:disabled": {
    backgroundColor: "#EBEBEB",
    backgroundImage: `url('${btnGoogleLightDisabled}')`,
  },
  "&:hover": {
    backgroundColor: "#4285F4",
    boxShadow: theme.shadows[3],
  },
  "&:active": {
    backgroundColor: "#3467D6",
  },
}));

export const GoogleButton: React.ComponentType<Props> = withDefaultProps<
  Props,
  "variant" | "size" | "disableRipple" | "disableElevation"
>(GoogleButtonBase, {
  disableElevation: true,
  disableRipple: true,
  variant: "text",
  size: "large",
});
