// @flow
import * as React from "react";
import MetamorphWidget from "./MetamorphWidget";
import { API as backend } from "../../../services/backend.service";
import type { User } from "../../../models/user.model";

const MetamorphWidgetContainer: React.ComponentType<{}> = () => {
  const [user, setSelectedUser] = React.useState<?User>(null);
  const handleConfirm = () => {
    if (user) {
      return backend.auth.metamorph(user.email);
    }
  };

  return (
    <MetamorphWidget
      user={user}
      onChangeSelectedUser={setSelectedUser}
      onConfirm={handleConfirm}
    />
  );
};

export default MetamorphWidgetContainer;
