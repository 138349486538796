// @flow
import * as React from "react";
import StatusChip from "../lib/display/StatusChip";
import type { PostStatus } from "../../models/post.model";
import { isPastScheduleTime } from "../../models/post.model";
import { propMap } from "../lib/utils/props";
import type { Severity } from "../../models/alerts.model";
import useTranslate from "../../hooks/useTranslate";
import { getPostStatusIcon } from "./postStatusIcons";
import type { ModelID, Moment, Slug } from "../../types";
import LocaleDateTime from "../lib/display/LocaleDateTime";
import { Tooltip } from "@mui/material";
import { SmartphoneRounded, WarningRounded } from "@mui/icons-material";
import { isDevice, isMobileApple } from "../../lib/platform.lib";
import instagram from "../../models/channels/instagram.model";

type Props = {
  channel: Slug,
  postId: ModelID,
  status: PostStatus,
  time?: ?Moment,
  dense?: boolean,
  autopublishDisabled?: ?boolean,
};

const _STATUS_TO_SEVERITY: Record<PostStatus, Severity> = {
  scheduled: "info",
  draft: "warning",
  published: "success",
  deleted: "info",
};

const getSeverity = propMap(_STATUS_TO_SEVERITY, "draft");

const handleShareOnDeviceNow = (channel: Slug, postId: ModelID) => () => {
  if (isMobileApple && channel === instagram.slug) {
    window.location.href = `bobcaat:share?target=${channel}&post_id=${postId}`;
  }
};

const getParams = ({
  status,
  time,
  channel,
  postId,
  autopublishDisabled,
}: Props) => {
  if (
    autopublishDisabled &&
    isPastScheduleTime({ schedule_time: time, status })
  ) {
    if (channel === instagram.slug) {
      return {
        icon: SmartphoneRounded,
        severity: "info",
        title:
          "SchedulingSection.PostStatusControl." +
          (isDevice ? "useThisDevice" : "useADevice"),
        fallback: null,
        onClick: handleShareOnDeviceNow(channel, postId),
      };
    }

    return {
      icon: WarningRounded,
      severity: "error",
      title: "SchedulingSection.postSchedulingFailed",
      fallback: null,
    };
  }
  return {
    icon: getPostStatusIcon(status),
    severity: getSeverity(status),
    title: `refer.postStatus.${status}`,
    fallback: `refer.postStatus.${status}`,
  };
};

const PostStatusChip: React.ComponentType<Props> = React.memo(
  ({ status, time, channel, postId, autopublishDisabled, ...props }) => {
    const t = useTranslate();
    const params = getParams({
      status,
      time,
      postId,
      channel,
      autopublishDisabled,
    });
    return (
      <Tooltip title={t(params.title)}>
        <StatusChip
          severity={params.severity}
          icon={params.icon}
          onClick={params.onClick}
          {...props}
        >
          {time ? (
            <LocaleDateTime value={time} />
          ) : (
            params.fallback && t(params.fallback)
          )}
        </StatusChip>
      </Tooltip>
    );
  }
);

export default PostStatusChip;
