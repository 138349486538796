// @flow
import * as React from "react";
import useSwitch from "../../hooks/useSwitch";
import { useCurrentUser } from "../../store/selectors";
import UpgradeRequiredDialog from "./UpgradeRequiredDialog";
import type { LimitSet } from "../../models/limitSet.model";

const useUpgradeRequiredDialog = <ForwardArg = void>(
  callback: (ForwardArg) => any,
  permission: ?$Keys<LimitSet>
): [(ForwardArg) => any, React.Node] => {
  const [open, show, hide] = useSwitch(false);

  const limits = useCurrentUser()?.limits;
  const granted = !permission || !limits || limits[permission];
  return React.useMemo(
    () => [
      granted ? (args: ForwardArg) => callback(args) : show,
      !granted && <UpgradeRequiredDialog open={open} onClose={hide} />,
    ],
    [show, open, granted, hide, callback]
  );
};

export default useUpgradeRequiredDialog;
