// @flow
import * as React from "react";
import MiniCalendar from "../surfaces/MiniCalendar/MiniCalendar";
import type { Moment } from "../../types";
import range from "lodash/range";
import MiniCalendarDay from "../surfaces/MiniCalendar/MiniCalendarDay";
import moment from "moment";

type Props = {
  anchor: Moment,
  getBadge: (day: Moment) => ?React.Node,
  onDayClick?: (Moment) => any,
  month?: number,
};

const MiniWeekCalendar: React.ComponentType<Props> = ({
  anchor,
  getBadge,
  onDayClick,
  month,
}) => (
  <MiniCalendar>
    {range(7).map((offset) => {
      const day = moment(anchor).add(offset, "day");
      const dayDisabled = Number.isInteger(month) && month !== day.month();
      return (
        <MiniCalendarDay
          key={offset}
          day={day}
          badge={getBadge(day)}
          onClick={
            onDayClick && !dayDisabled
              ? () => onDayClick(moment(day))
              : undefined
          }
        />
      );
    })}
  </MiniCalendar>
);

export default MiniWeekCalendar;
