// @flow

import keyBy from "lodash/keyBy";
import type { I18nKey, ModelID, Timestamp } from "../types";
import type { BaseModel, SupportsCreatedUpdatedAt } from "./base.model";
import moment from "moment";
import { Moment } from "../types";
import { toMoment } from "../lib/time.lib";
import type { User } from "./user.model";

/**
 * Specifies the current status of an approval request.
 */
export type ApprovalRequestStatuses = "pending" | "reviewed" | "completed";

/**
 * A representation of an Approval request status.
 */
interface ApprovalRequestStatus {
  slug: ApprovalRequestStatuses;
  variant: string;
  name: I18nKey;
  color: string;
}

/** PENDING ApprovalRequestStatus */
export const PENDING: ApprovalRequestStatus = {
  slug: "pending",
  variant: "pending",
  name: "refer.approvalRequestStatus.pending",
  color: "cornflowerblue",
};

/** REVIEWED ApprovalRequestStatus */
export const REVIEWED: ApprovalRequestStatus = {
  slug: "reviewed",
  variant: "warning",
  name: "refer.approvalRequestStatus.reviewed",
  color: "orange",
};

/** COMPLETED ApprovalRequestStatus */
export const COMPLETED: ApprovalRequestStatus = {
  slug: "completed",
  variant: "done",
  name: "refer.approvalRequestStatus.completed",
  color: "green",
};

/** Array of all approval request statuses */
export const ALL_APPROVAL_REQUEST_STATUSES: ApprovalRequestStatus[] = [
  PENDING,
  REVIEWED,
  COMPLETED,
];

const allBySlug: Record<string, ApprovalRequestStatus> = keyBy(
  ALL_APPROVAL_REQUEST_STATUSES,
  "slug"
);

/**
 * Get an approval request status by its enum value.
 */
export const getApprovalRequestStatus = (
  slug: ApprovalRequestStatuses
): ?ApprovalRequestStatus => allBySlug[slug];

/**
 * Stands for a request sent by a user to a client for approval on a collection of posts.
 */
export type ApprovalRequest = {
  ...BaseModel,
  /**
   * The title of the request.
   */
  title: string,
  /**
   * An optional description of the request.
   */
  description: ?string,
  /**
   * The status of the request.
   */
  status: ApprovalRequestStatuses,
  /**
   * Public ID of the request.
   */
  hash_id: string,
  /**
   * Client password for logging into the request.
   */
  password: string,
  /**
   * IDs of the posts in the request.
   */
  posts: ModelID[],
  /**
   * The portfolio ID this request refers to.
   * null before lorelai version.
   */
  portfolio_id: ?ModelID,
  /**
   * The schedule time of the earliest post in this
   * approval request.
   */
  deadline: ?Moment,
  /**
   * Details about the user authoring this approval request.
   */
  user: Pick<User, "avatar" | "first_name" | "last_name">,
};

export type APIApprovalRequest = Exclude<
  ApprovalRequest,
  SupportsCreatedUpdatedAt
> & {
  created_at: Timestamp,
  updated_at: Timestamp,
  deadline: Timestamp,
};

/**
 * Enrich a raw approval request received from the server.
 */
export const fromAPIResult = (
  request: APIApprovalRequest
): ApprovalRequest => ({
  ...request,
  created_at: moment.unix(request.created_at),
  updated_at: moment.unix(request.updated_at),
  deadline: toMoment(request.deadline),
});
