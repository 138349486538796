// @flow
import * as React from "react";
import type { RatingFormDef } from "./RateTheAppDialog";
import RateTheAppDialog from "./RateTheAppDialog";
import moment from "moment";
import * as snacks from "../../../models/alerts.model";
import { useStoreActions } from "../../../store/store";
import * as feedback from "../../../services/backend.service/feedback";
import { FormActions, useForm } from "../../../hooks/useForm";
import { UserToken } from "../../../lib/session.lib";

const FORM_FIELDS: RatingFormDef = {
  feedback: {
    type: "string",
    maxLength: 3000,
    preventOverflow: false,
  },
  rating: {
    type: "number",
  },
};

const FORM_INITIAL = { feedback: "", rating: 0 };

const RateTheAppDialogContainer: React.ComponentType<{
  forceOpen?: boolean,
  onCancelOrSubmit?: (submitted: boolean) => any,
}> = ({ forceOpen, onCancelOrSubmit }) => {
  const storeActions = useStoreActions();
  // Force argument is for testing only.
  const [open, setOpen] = React.useState(forceOpen);
  const [start, setStart] = React.useState(moment());

  React.useEffect(() => setOpen(forceOpen), [forceOpen]);

  React.useEffect(() => {
    if (UserToken.isValid()) {
      feedback.shouldDisplayAppRating().then((shouldDisplay) => {
        setTimeout(() => {
          setOpen(shouldDisplay);
          setStart(moment());
        }, 2000);
      });
    }
  }, []);

  const form = useForm<RatingFormDef>(FORM_FIELDS, FORM_INITIAL);

  const handleCancelOrSubmit =
    onCancelOrSubmit ??
    ((submitted: boolean) =>
      feedback
        .submitAppRating({
          ...FormActions.collect(form.state),
          submitted,
          duration: parseInt(moment.duration(moment().diff(start)).asSeconds()),
        })
        .then(() => {
          setOpen(false);
          if (submitted) {
            storeActions.snacks.append(
              snacks.localInfo({ message: "RateTheAppDialog.thanks" })
            );
          }
        }));

  return open ? (
    <RateTheAppDialog
      form={form}
      onSubmit={() => handleCancelOrSubmit(true)}
      onCancel={() => handleCancelOrSubmit(false)}
      onClose={() => setOpen(false)}
    />
  ) : null;
};

export default RateTheAppDialogContainer;
