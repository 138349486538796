// @flow
import * as React from "react";
import { Body1, Subheader, Title } from "../lib/display/Text";
import useTranslate from "../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import { InlineRowStack, RowStack } from "../lib/layout/stacks";
import { Badge, IconButton } from "@mui/material";
import {
  ChevronLeftRounded,
  CloseRounded,
  HomeRounded,
  NotificationsRounded,
} from "@mui/icons-material";
import routes, { getRoutePath } from "../../config/routes.config";
import withPopupMenu from "../lib/navigation/withPopupMenu";
import NotificationsWidget from "../widgets/NotificationsWidget";
import { useUnreadNotificationsCount } from "../../store/selectors";
import type { Children } from "../../reactTypes";
import type { Callback } from "../../types";
import { useNavigate } from "react-router-dom";
import ToggleNavMenuIconButton from "../navigation/AppNavigation/ToggleNavMenuIconButton";
import useTemporaryNavigation from "../../hooks/useTemporaryNavigation";
import Logo from "../display/Logo";
import useIsMobile from "../../hooks/useIsMobile";
import type { Variant } from "../lib/display/Text";

type Props = {
  title: string,
  subtitle?: string,
  titleData?: Object,
  subtitleData?: Object,
  prevPath?: string,
  isHome?: boolean,
};

type LayoutProps = {
  ...Props,
  children?: Children,
  absoluteHeader?: boolean,
  prevPath?: string,
  showMenuButton?: boolean,
  slots?: {
    subtitle?: {
      variant?: Variant,
    },
  },
};

type SecondaryHeaderProps = {
  ...Props,
  absoluteHeader?: boolean,
  onClose?: Callback,
};

const TitleAndIcons = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
}));

const NotificationIcon = () => {
  const unreadNotificationCount = useUnreadNotificationsCount();
  return (
    <Badge badgeContent={unreadNotificationCount} color="primary">
      <NotificationsRounded />
    </Badge>
  );
};

const NotificationsIconButton = withPopupMenu(IconButton, {
  children: <NotificationIcon />,
  MenuListProps: { dense: true },
  PaperProps: { style: { width: 500 } },
  anchor: { vertical: "bottom", horizontal: "right" },
});

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "absoluteHeader",
})(({ theme, absoluteHeader }) => ({
  padding: theme.spacing(2, 4, 3, 4),
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(2, 3, 3, 3),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
  ...(absoluteHeader && {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
  }),
}));

const MobilePageHeaderLayout: React.ComponentType<LayoutProps> = (props) => {
  const t = useTranslate();
  const navigate = useNavigate();

  return (
    <Root absoluteHeader={props.absoluteHeader}>
      <RowStack spacing={0}>
        <div>
          {props.showMenuButton && (
            <ToggleNavMenuIconButton variant="temporary" />
          )}
          {props.prevPath && (
            <IconButton
              onClick={() => {
                navigate(props.prevPath, { relative: "path" });
              }}
            >
              <ChevronLeftRounded />
            </IconButton>
          )}
        </div>
        <Subheader sx={{ flexGrow: 1 }} textAlign="center">
          {t(props.title, props.titleData)}
        </Subheader>
        {props.children && <div>{props.children}</div>}
      </RowStack>
      {props.subtitle && (
        <Body1
          color="textSecondary"
          fontWeight="bold"
          sx={{ marginTop: 1, marginLeft: 1 }}
          {...props.slots?.subtitle}
        >
          {t(props.subtitle, props.subtitleData)}
        </Body1>
      )}
    </Root>
  );
};

const PageHeaderLayout: React.ComponentType<LayoutProps> = (props) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  if (isMobile) return <MobilePageHeaderLayout {...props} />;
  return (
    <Root absoluteHeader={props.absoluteHeader}>
      <TitleAndIcons>
        <InlineRowStack>
          {props.showMenuButton && (
            <>
              <ToggleNavMenuIconButton variant="temporary" />
              <Logo />
            </>
          )}
          <Title>{t(props.title, props.titleData)}</Title>
        </InlineRowStack>
        {props.children && <InlineRowStack>{props.children}</InlineRowStack>}
      </TitleAndIcons>
      {props.subtitle && (
        <Subheader
          color="textSecondary"
          sx={{ marginTop: 2, marginLeft: 0 }}
          {...props.slots?.subtitle}
        >
          {t(props.subtitle, props.subtitleData)}
        </Subheader>
      )}
    </Root>
  );
};

export const PrimaryPageHeader: React.ComponentType<Props> = (props) => {
  const navigate = useNavigate();
  const showMenuButton = useTemporaryNavigation();
  return (
    <PageHeaderLayout {...props} showMenuButton={showMenuButton}>
      {!props.isHome && (
        <IconButton
          onClick={() => navigate(getRoutePath(routes.app.nested.home))}
        >
          <HomeRounded />
        </IconButton>
      )}
      <NotificationsIconButton>
        <NotificationsWidget />
      </NotificationsIconButton>
    </PageHeaderLayout>
  );
};

export const SecondaryPageHeader: React.ComponentType<SecondaryHeaderProps> = ({
  onClose,
  ...props
}) => (
  <PageHeaderLayout {...props}>
    {onClose && (
      <IconButton onClick={onClose}>
        <CloseRounded />
      </IconButton>
    )}
  </PageHeaderLayout>
);

export const GuestPageHeader: React.ComponentType<Props> = (props) => (
  <PageHeaderLayout {...props} slots={{ subtitle: { variant: "body2" } }} />
);
