// @flow
import * as React from "react";
/**
 * Returns an object URL for the resource, whether it is already a URL or a File.
 * @return {string}
 */

export type ObjectURLSrc = string | Blob;

const useObjectURL = (urlOrFile?: ObjectURLSrc): string => {
  const [url, setUrl] = React.useState(
    typeof urlOrFile === "string" ? urlOrFile : ""
  );

  React.useEffect(() => {
    if (urlOrFile instanceof Blob) {
      const objectURL = URL.createObjectURL(urlOrFile);
      setUrl(objectURL);

      return () => {
        URL.revokeObjectURL(objectURL);
        setUrl("");
      };
    } else {
      setUrl(urlOrFile ?? "");
    }
  }, [urlOrFile]);

  return url;
};

export default useObjectURL;
