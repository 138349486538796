// @flow
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import AttachmentTagsEditorLayout from "../AttachmentTagsEditorLayout";
import UserTag from "./UserTag";
import type { AttachmentUserTag } from "../../../../models/attachment.model";
import type { PostAttachment } from "../../../../models/post.model";

type Props = {
  attachment: PostAttachment,
  onChangeAttachment: (PostAttachment) => any,
};

const isUserTagEmpty = (tag: AttachmentUserTag) => isEmpty(tag.handle);
const onEditUserTag = (tag: AttachmentUserTag, text: string) => ({
  ...tag,
  handle: trim(text, " @"),
});
const onNewUserTag = (tag: AttachmentUserTag) => ({ ...tag, handle: "" });

const AttachmentUserTagsEditorContainer: React.ComponentType<Props> = ({
  attachment,
  onChangeAttachment,
}) => (
  <AttachmentTagsEditorLayout
    attachment={attachment}
    onChangeAttachment={onChangeAttachment}
    TagComponent={UserTag}
    tagAttribute="user_tags"
    onNewTag={onNewUserTag}
    onEditTag={onEditUserTag}
    isTagEmpty={isUserTagEmpty}
  />
);

export default AttachmentUserTagsEditorContainer;
