// @flow
import * as React from "react";
import type { Callback } from "../../../types";

type Props = {
  fallback: React.Node,
  children: React.Element<React.ComponentType<{ onError: Callback, ... }>>,
};

const MediaErrorFallback: React.ComponentType<Props> = ({
  fallback,
  children,
}) => {
  const [error, setError] = React.useState(false);
  const onError = React.useCallback(() => setError(true), []);
  React.useEffect(() => setError(false), [children]);
  return error ? fallback : React.cloneElement(children, { onError });
};

export default MediaErrorFallback;
