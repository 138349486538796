// @flow
import * as React from "react";
import ColorInput from "../ColorInput";
import type { ColorInputProps } from "../ColorInput";
import type { FormField, OnChangeField } from "../../../../hooks/useForm";
import { useFormFieldError } from "../../../../hooks/useForm";

type Props = {
  ...ColorInputProps,
  formField: FormField<any>,
  onChange: OnChangeField<>,
};

const FormColorInput: React.ComponentType<Props> = ({
  formField,
  onChange,
  ...props
}) => {
  const helperText = useFormFieldError(formField) ?? formField.helperText;
  return (
    <ColorInput
      value={formField.value?.toString()}
      onChange={(value) => onChange(formField.fromString(value))}
      helperText={helperText}
      error={!!formField.error}
      name={formField.name}
      {...props}
    />
  );
};

export default FormColorInput;
