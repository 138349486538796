// @flow
import * as React from "react";
import { useEffect } from "react";
import type { ApprovalRequest } from "../../../models/approvalRequest.model";
import { useStoreActions } from "../../../store/store";
import type { Portfolio } from "../../../models/portfolio.model";
import { FormActions, useForm } from "../../../hooks/useForm";
import type { ApprovalRequestFormDef } from "./ApprovalRequestWidget";
import ApprovalRequestWidget from "./ApprovalRequestWidget";
import { checkApprovalRequestTitle } from "../../../util/validation.util";
import * as approvalRequestService from "../../../services/approval.service";
import useNavigateBackToResourceList from "../../../hooks/useNavigateBackToResourceList";
import type { ModelID } from "../../../types";
import { PostFiltersContextProvider } from "../../../contexts/postFilters";

type Props = {
  approvalRequest: ?ApprovalRequest,
  portfolio: Portfolio,
  initialPostIds?: ModelID[],
};

const FORM_DEF: ApprovalRequestFormDef = {
  title: {
    type: "string",
    validate: checkApprovalRequestTitle,
    maxLength: 50,
    preventOverflow: true,
  },
  description: {
    type: "string",
    maxLength: 1024,
    preventOverflow: true,
  },
  posts: { type: "number[]" },
};

const ApprovalRequestWidgetContainer: React.ComponentType<Props> = ({
  approvalRequest,
  portfolio,
  initialPostIds = [],
}) => {
  const navigateBackToList = useNavigateBackToResourceList(
    Boolean(approvalRequest)
  );
  const actions = useStoreActions();
  const initial = React.useMemo(
    () => ({
      title: approvalRequest?.title ?? "",
      description: approvalRequest?.description ?? "",
      posts: approvalRequest?.posts ?? initialPostIds,
    }),
    [approvalRequest, initialPostIds]
  );

  const form = useForm<ApprovalRequestFormDef>(FORM_DEF, initial);

  useEffect(() => {
    if (initial) {
      FormActions.reset(form.set, initial);
    }
  }, [form.set, initial]);

  const handleConfirm = () => {
    if (FormActions.validate(form)) {
      const payload = {
        ...FormActions.collect(form.state),
        portfolio_id: portfolio.id,
      };
      if (approvalRequest) {
        return approvalRequestService
          .editApprovalRequest(actions)({ ...payload, id: approvalRequest.id })
          .then(navigateBackToList);
      } else {
        return approvalRequestService
          .addApprovalRequest(actions)(payload)
          .then(navigateBackToList);
      }
    }
  };

  const handleDelete = approvalRequest
    ? () =>
        approvalRequestService
          .deleteApprovalRequest(actions)(approvalRequest.id)
          .then(navigateBackToList)
    : undefined;

  return (
    <PostFiltersContextProvider>
      <ApprovalRequestWidget
        portfolioId={portfolio?.id ?? 0}
        approvalRequest={approvalRequest}
        form={form}
        onConfirm={handleConfirm}
        onCancel={navigateBackToList}
        onDelete={handleDelete}
      />
    </PostFiltersContextProvider>
  );
};

export default ApprovalRequestWidgetContainer;
