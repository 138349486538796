// @flow
import { useTheme } from "@mui/material";
import type { Portfolio } from "../../../models/portfolio.model";

const useMessageColor = (portfolio: Portfolio): string => {
  const theme = useTheme();
  return portfolio.color && portfolio.color.length > 0
    ? portfolio.color
    : theme.palette.secondary.main;
};

export default useMessageColor;
