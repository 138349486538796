// @flow
import * as React from "react";
import type { ApprovalRequest } from "../models/approvalRequest.model";
import { usePosts } from "../store/selectors";
import { byIds } from "../models/base.model";
import countBy from "lodash/countBy";
import type { PostApprovalStatuses } from "../models/postApproval.model";

const useApprovalRequestCounts = (
  approvalRequest: ApprovalRequest
): Record<PostApprovalStatuses | "count", number> => {
  const posts = usePosts();
  const filteredPosts = React.useMemo(
    () => posts.filter(byIds(approvalRequest.posts)),
    [posts, approvalRequest.posts]
  );
  const counts = React.useMemo(
    () => countBy(filteredPosts, "approval"),
    [filteredPosts]
  );

  return React.useMemo(
    () => ({
      count: filteredPosts.length,
      pending: 0,
      rejected: 0,
      approved: 0,
      edits_required: 0,
      ...counts,
    }),
    [counts, filteredPosts.length]
  );
};

export default useApprovalRequestCounts;
