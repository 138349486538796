// @flow
import type { Slug } from "../types";
import { getAttr } from "../lib/lodashex.lib";

export type Locale = {
  slug: Slug,
  language: string,
  name: string,
  region: string,
  flag: string,
};

export const en_gb: Locale = {
  slug: "en-gb",
  language: "en",
  name: "English",
  region: "U.K",
  flag: "🇬🇧",
};
export const fr_fr: Locale = {
  slug: "fr-fr",
  language: "fr",
  name: "French",
  region: "France",
  flag: "🇫🇷",
};

const locales: Locale[] = [en_gb, fr_fr];

export const getLocale = (slug: ?Slug): ?Locale =>
  locales.find((locale) => locale.slug === slug);

export const getLocaleName: (Locale) => string = getAttr<Locale>("name");
export const getLocaleRegion: (Locale) => string = getAttr<Locale>("region");
export const getLocaleLongName = (locale: Locale): string =>
  `${locale.flag} ${locale.name} (${locale.region})`;
export const getLocaleSlug: (Locale) => string = getAttr<Locale>("slug");

export default locales;
