// @flow
import * as React from "react";
import GooglePicker from "../pickers/GooglePicker";
import useTranslate from "../../../../hooks/useTranslate";
import { GoogleButton } from "../buttons";
import DropZone from "./DropZone";
import { Collapse, FormHelperText } from "@mui/material";
import MediaCollection from "./MediaCollection";
import type { Props } from "./useHeadlessMediaInput";
import useHeadlessMediaInput from "./useHeadlessMediaInput";
import FileDropContainer from "../FileDropContainer";

const MediaInput: React.ComponentType<Props> = (props) => {
  const {
    items,
    disabled,
    onChange,
    enableGooglePicker,
    accept,
    error,
    helperText,
  } = props;
  const t = useTranslate();
  const {
    cropImageDialog,
    onSelect,
    onSetEditedItem,
    fileDropContainerProps,
    validatorProps,
  } = useHeadlessMediaInput(props);

  return (
    <>
      {cropImageDialog}
      <FileDropContainer {...fileDropContainerProps}>
        <DropZone
          disabled={disabled}
          accept={accept}
          onSelect={onSelect}
          {...validatorProps}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FileDropContainer>
      {enableGooglePicker && (
        <GooglePicker
          onSelect={onSelect}
          disabled={disabled}
          accept={accept}
          {...validatorProps}
        >
          <GoogleButton fullWidth style={{ marginTop: 4 }}>
            {t("global.importFromGoogleDrive")}
          </GoogleButton>
        </GooglePicker>
      )}
      <Collapse in={!!items.length}>
        <MediaCollection
          items={items}
          onChange={onChange}
          onSetEditedItem={onSetEditedItem}
        />
      </Collapse>
    </>
  );
};

export default MediaInput;
