// @flow
import ajax from "../../lib/ajax.lib";
import type { ModelID, Moment } from "../../types";
import type { APIResult } from "./types";

export const beat = (lastBeat: Moment, events: ModelID[]): Promise<Object> =>
  ajax
    .get<APIResult<Object>>(
      `race/beat?since=${lastBeat.unix()}&events=${events.toString()}`,
      {
        disableErrorInterceptor: true,
      }
    )
    .then((response) => response.data.data);
