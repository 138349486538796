// @flow
import * as React from "react";
import type { I18nKey } from "../../../types";
import type { Channel } from "../../../models/channels/channel.model";
import type { BobcaatAccount } from "../../../models/account.model";
import { isExpired } from "../../../models/account.model";
import type { UseBindResult } from "./useBind";
import { Paper, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChannelIcon from "../../lib/display/icons/ChannelIcon";
import { ColumnStack, InlineRowStack, RowStack } from "../../lib/layout/stacks";
import { Body1, Subheader } from "../../lib/display/Text";
import AccountButton from "./AccountButton";
import useTranslate from "../../../hooks/useTranslate";
import {
  AddCircleRounded,
  CalendarTodayRounded,
  LinkOffRounded,
  MoveUpRounded,
  RefreshRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import withConfirmDialog from "../../lib/feedback/withConfirmDialog";
import type { AccountHandlers } from "./useAccountActions";
import useAccountActions from "./useAccountActions";
import AccountStatusChip from "./AccountStatusChip";
import useSwitch from "../../../hooks/useSwitch";
import MoveAccountToAnotherPortfolioDialog from "../../widgets/MoveAccountToAnotherPortfolioDialog";

type Props = {
  channel: Channel,
  onBind: UseBindResult[0],
  account: BobcaatAccount,
};

/**
 * May seem random, but calculated to show 2 columns on an Ipad Air.
 * @type {number}
 */
const CARD_WIDTH = 360;
const CARD_HEIGHT = 200;

const Root = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: CARD_WIDTH,
  height: CARD_HEIGHT,
  overflow: "clip",
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
}));

const Content = styled("div", {
  shouldForwardProp: (prop) => prop !== "channel",
})(({ theme, channel }) => ({
  padding: theme.spacing(2, 2),
  backgroundColor: channel.color,
  color: theme.palette.common.white,
  flexGrow: 1,
}));

export const AccountCardSkeleton: React.ComponentType<{}> = () => (
  <Skeleton
    height={CARD_HEIGHT}
    width={CARD_WIDTH}
    variant="rounded"
    animation={false}
  />
);

const DisconnectButton = withConfirmDialog(AccountButton, {
  DialogProps: {
    name: "disconnect-account",
    title: "DashboardSection.AccountCard.confirmDisconnect.title",
    message: "DashboardSection.AccountCard.confirmDisconnect.message",
    confirmLabel: "DashboardSection.AccountCard.confirmDisconnect.yes",
    cancelLabel: "DashboardSection.AccountCard.confirmDisconnect.no",
  },
});

const ACCOUNT_ACTIONS_DEF: [
  $Keys<Omit<AccountHandlers, "onDisconnect">>,
  I18nKey,
  React.ComponentType<{ style?: any }>
][] = [
  ["onViewPosts", "DashboardSection.AccountCard.viewPosts", VisibilityRounded],
  ["onCreatePost", "DashboardSection.AccountCard.createPost", AddCircleRounded],
  [
    "onViewPostsCalendar",
    "DashboardSection.AccountCard.goToCalendar",
    CalendarTodayRounded,
  ],
];

const StyledAccountStatusChip = styled(AccountStatusChip)({
  borderRadius: 0,
});

const GoToActions = styled(InlineRowStack)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const TextAndGoToActions = styled(ColumnStack)({
  flexGrow: 1,
  justifyContent: "space-between",
});

const AccountCard: React.ComponentType<Props> = React.forwardRef(
  ({ channel, account, onBind }: Props, ref) => {
    const t = useTranslate();
    const { onDisconnect, ...handlers } = useAccountActions(account);
    const [moveDialogOpen, openMoveDialog, closeMoveDialog] = useSwitch();
    return (
      <>
        <MoveAccountToAnotherPortfolioDialog
          onClose={closeMoveDialog}
          open={moveDialogOpen}
          account={account}
        />
        <Root ref={ref}>
          <Content channel={channel}>
            <RowStack alignItems="stretch">
              <ChannelIcon
                channel={channel.slug}
                size="xlarge"
                color={account ? "channel-alt" : "channel"}
              />
              <TextAndGoToActions>
                <div>
                  <Subheader color="inherit">{account.entity_name}</Subheader>
                  <Body1 color="inherit">
                    {account.is_organisation
                      ? t(`global.organisationAccount`)
                      : t(`global.personnalAccount`)}
                  </Body1>
                </div>
                <GoToActions>
                  {ACCOUNT_ACTIONS_DEF.map(([handler, label, Icon]) => (
                    <AccountButton
                      onClick={handlers[handler]}
                      key={handler}
                      title={t(label)}
                    >
                      <Icon />
                    </AccountButton>
                  ))}
                </GoToActions>
              </TextAndGoToActions>
              <ColumnStack>
                <AccountButton
                  onClick={onBind}
                  pulse={account.status_code > 0 || isExpired(account)}
                  title={t("global.refresh")}
                >
                  <RefreshRounded />
                </AccountButton>
                <AccountButton
                  title={t("global.moveToAnotherPortfolio")}
                  onClick={openMoveDialog}
                >
                  <MoveUpRounded />
                </AccountButton>
                <DisconnectButton
                  onClick={onDisconnect}
                  title={t("global.disconnect")}
                >
                  <LinkOffRounded />
                </DisconnectButton>
              </ColumnStack>
            </RowStack>
          </Content>
          <StyledAccountStatusChip account={account} />
        </Root>
      </>
    );
  }
);

export default AccountCard;
