// @flow
import * as React from "react";
import {
  LargeDeleteButton,
  LargePrimaryButton,
  LargeTextButton,
} from "../buttons";
import type { Children, CSSProps } from "../../../../reactTypes";
import { RowStack } from "../../layout/stacks";
import useTranslate from "../../../../hooks/useTranslate";
import useSwitch from "../../../../hooks/useSwitch";
import type { Options as ConfirmDialogOptions } from "../../feedback/withConfirmDialog";
import useConfirmDialog from "../../feedback/useConfirmDialog";
import useIsMobile from "../../../../hooks/useIsMobile";

export type Props = {
  onSubmit?: () => any,
  onCancel?: () => any,
  onDelete?: () => any,

  submitTextId?: string,
  cancelTextId?: string,
  deleteTextId?: string,

  autoComplete?: string,
  disabled?: boolean,
  loading?: boolean,
  alignButtons?: "flex-start" | "center" | "flex-end",
  children: Children,
  confirmDeleteOptions?: ConfirmDialogOptions,
  ...CSSProps,
};

const HTMLForm: React.ComponentType<Props> = ({
  onSubmit,
  onCancel,
  onDelete,
  children,
  loading,
  disabled,
  confirmDeleteOptions,
  alignButtons = "flex-end",
  submitTextId = "global.submit",
  cancelTextId = "global.cancel",
  deleteTextId = "global.delete",
  ...props
}) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const [locked, setLocked, setUnlocked] = useSwitch(false);
  const [onRequestDelete, dlg] = useConfirmDialog(
    confirmDeleteOptions,
    onDelete
  );

  const handleSubmit = React.useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (onSubmit) {
        setLocked();
        // Use Promise.resolve to ensure a Promise result.
        Promise.resolve(onSubmit()).finally(setUnlocked);
      }
    },
    [onSubmit, setLocked, setUnlocked]
  );
  const allButtonProps = { loading: loading ?? locked };

  return (
    <form onSubmit={handleSubmit} {...props}>
      {dlg}
      {children}
      <RowStack
        justifyContent={alignButtons}
        marginTop={2}
        // On mobile the page panel has no bottom margin, so make sure
        // there is room for the buttons.
        marginBottom={isMobile ? 2 : 0}
        spacing={1.5}
      >
        {onDelete && (
          <LargeDeleteButton
            onClick={onRequestDelete}
            disabled={disabled}
            {...allButtonProps}
          >
            {t(deleteTextId)}
          </LargeDeleteButton>
        )}
        {onCancel && (
          <LargeTextButton onClick={onCancel} {...allButtonProps}>
            {t(cancelTextId)}
          </LargeTextButton>
        )}
        {onSubmit && (
          <LargePrimaryButton
            type="submit"
            disabled={disabled}
            {...allButtonProps}
          >
            {t(submitTextId)}
          </LargePrimaryButton>
        )}
      </RowStack>
    </form>
  );
};

export default HTMLForm;
