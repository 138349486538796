// @flow
import * as React from "react";
import Translate from "../../lib/display/Translate";
import Dialog from "../../lib/feedback/Dialog";
import Section from "../../layout/Section";
import DateRangeSelect from "../../inputs/DateRangeSelect";
import type {
  DatetimeFormFieldDef,
  Form,
  NumberListFormFieldDef,
} from "../../../hooks/useForm";
import { FormActions } from "../../../hooks/useForm";
import FormSwitchGroup from "../../lib/inputs/form/FormSwitchGroup";
import { AccountAdornment } from "../../display/Adornments";
import { getId } from "../../../models/base.model";
import type { BobcaatAccount } from "../../../models/account.model";
import { getAccountLongName } from "../../../models/account.model";
import { RowStack } from "../../lib/layout/stacks";
import { NeutralButton } from "../../lib/inputs/buttons";
import type { Callback } from "../../../types";

export type ExportAnalyticsFormDef = {
  accounts: NumberListFormFieldDef,
  fromDate: DatetimeFormFieldDef,
  toDate: DatetimeFormFieldDef,
};

type Props = {
  form: Form<ExportAnalyticsFormDef>,
  accounts: BobcaatAccount[],
  onExportAsCSV: Callback,
  onExportAsExcel: Callback,
  loading?: boolean,
  onClose: Callback,
  open?: boolean,
};

const ExportAnalyticsDialog: React.ComponentType<Props> = ({
  form,
  accounts,
  onExportAsCSV,
  onExportAsExcel,
  loading,
  onClose,
  open,
}) => {
  return (
    <Dialog
      name="export-analytics"
      open={open}
      onClose={onClose}
      title={<Translate id="ExportAnalyticsForm.title" />}
      text={<Translate id="ExportAnalyticsForm.subtitle" />}
    >
      <Section title="global.filters">
        <DateRangeSelect
          fromDate={form.state.fromDate.value}
          toDate={form.state.toDate.value}
          onChangeToDate={FormActions.onChangeField(form.set, "toDate")}
          onChangeFromDate={FormActions.onChangeField(form.set, "fromDate")}
        />
        <FormSwitchGroup
          AdornmentComponent={AccountAdornment}
          getOptionValue={getId}
          getOptionLabel={getAccountLongName}
          formField={form.state.accounts}
          onChange={FormActions.onChangeField(form.set, "accounts")}
          options={accounts}
        />
      </Section>
      <RowStack>
        <NeutralButton
          onClick={onExportAsCSV}
          loading={loading}
          disabled={form.state.accounts.value.length === 0}
        >
          <Translate id="ExportAnalyticsForm.exportAsCSV" />
        </NeutralButton>
        <NeutralButton
          onClick={onExportAsExcel}
          loading={loading}
          disabled={form.state.accounts.value.length === 0}
        >
          <Translate id="ExportAnalyticsForm.exportAsExcel" />
        </NeutralButton>
      </RowStack>
    </Dialog>
  );
};

export default ExportAnalyticsDialog;
