// @flow
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import routes, { getRoutePath } from "../../../config/routes.config";
import { useNavigate } from "react-router-dom";
import type { Notification } from "../../../models/notification.model";
import type { StringDict } from "../../../types";

const TYP_EVENT = "event";
const EVT_INSTAGRAM_POST_ALARM = "INSTAGRAM_POST_ALARM";
const EVT_SCHEDULING_FAILED = "SCHEDULING_FAILED";
const EVT_NEW_COMMENT = "NEW_COMMENT";
const EVT_NEW_MESSAGE = "NEW_MESSAGE";
const EVT_POST_MANUALLY_DELETED = "EVT_POST_MANUALLY_DELETED";

type RedirectCallback = (data: Object) => string;

const REDIRECTS: StringDict<RedirectCallback> = {
  [EVT_INSTAGRAM_POST_ALARM]: ({ postId, portfolioId }) =>
    getRoutePath(routes.app.nested.portfolios.nested.creator, {
      portfolioId,
      postId,
    }),
  [EVT_SCHEDULING_FAILED]: ({ postId, portfolioId }) =>
    getRoutePath(routes.app.nested.portfolios.nested.creator, {
      portfolioId,
      postId,
    }),
  [EVT_POST_MANUALLY_DELETED]: ({ postId, portfolioId }) =>
    getRoutePath(routes.app.nested.portfolios.nested.creator, {
      portfolioId,
      postId,
    }) + "?status=deleted",
  [EVT_NEW_COMMENT]: ({ portfolioId, commentId, postId }) =>
    getRoutePath(routes.app.nested.portfolios.nested.engagement, {
      portfolioId,
      postId,
      commentId,
    }),
  [EVT_NEW_MESSAGE]: ({ portfolioId, messageId, conversationId }) =>
    getRoutePath(routes.app.nested.portfolios.nested.engagement, {
      portfolioId,
      conversationId,
      messageId,
    }),
};

const useNotificationRedirect = (): ((Notification) => any) => {
  const navigate = useNavigate();
  return React.useCallback(
    (notification: Notification) => {
      if (isEmpty(notification.tag) || !notification.tag) {
        return;
      }
      const { type, data } = notification.tag;

      if (type === TYP_EVENT) {
        const { event, ...params } = data;
        if (event in REDIRECTS) {
          navigate(
            REDIRECTS[event]({
              portfolioId: params.portfolio_id,
              postId: params.post_id,
              commentId: params.comment_id,
              messageId: params.message_id,
              conversationId: params.conversation_id,
            })
          );
        }
      }
    },
    [navigate]
  );
};

export default useNotificationRedirect;
