// @flow
import * as React from "react";
import NotificationItem from "./NotificationItem";
import type { Props as ItemProps } from "./NotificationItem";
import type { Callback } from "../../../types";
import useTranslate from "../../../hooks/useTranslate";
import MenuListItem from "../../lib/navigation/MenuListItem";
import { CheckRounded, DeleteRounded } from "@mui/icons-material";
import { Divider } from "@mui/material";
import type { Notification } from "../../../models/notification.model";
import FixedSizeList from "../../lib/layout/FixedSizeList";

type Props = {
  ...Omit<ItemProps, "item" | "style">,
  notifications: Notification[],
  onMarkAllAsRead: Callback,
  onDeleteAll: Callback,
};

const Notifications: React.ComponentType<Props> = ({
  notifications,
  onMarkAllAsRead,
  onDeleteAll,
  ...props
}) => {
  const t = useTranslate();
  return (
    <>
      <MenuListItem
        primary={t("Notifications.markAllAsRead")}
        onClick={onMarkAllAsRead}
        Icon={CheckRounded}
      />
      <MenuListItem
        primary={t("Notifications.deleteAll")}
        iconColor="error"
        onClick={onDeleteAll}
        Icon={DeleteRounded}
      />
      <Divider />
      <FixedSizeList
        height={400}
        items={notifications}
        itemProps={props}
        ItemComponent={NotificationItem}
      />
    </>
  );
};

export default Notifications;
