// @flow
import * as React from "react";
import type { Timezone } from "../../../../models/timezone.model";
import type { Moment } from "../../../../types";
import { Body2, Caption } from "../../../lib/display/Text";
import useTranslate from "../../../../hooks/useTranslate";
import { getTimezoneDescription } from "../../../../util/timezoneOptions.util";
import Translate from "../../../lib/display/Translate";
import { ColumnStack } from "../../../lib/layout/stacks";
import ScheduleTimePicker from "../../../inputs/ScheduleTimePicker";
import SwitchField from "../../../lib/inputs/SwitchField";
import Select from "../../../lib/inputs/Select";
import {
  PERIODICITY_UNIT_OPTIONS,
  getOptionLabel,
  getOptionValue,
} from "../../../../models/periodicity.model";
import type { Periodicity } from "../../../../models/periodicity.model";
import { TextField } from "@mui/material";

type Props = {
  timezone: Timezone,
  selectedDate: ?Moment,
  onDateChange: (?Moment) => any,
  onTimeChange: (?Moment) => any,
  periodicity: ?Periodicity,
  onChangePeriodicity: (?Periodicity) => any,
};

const PostScheduleWidget: React.ComponentType<Props> = ({
  periodicity,
  onChangePeriodicity,
  ...props
}) => {
  const t = useTranslate();
  return (
    <ColumnStack marginTop={1}>
      <Body2 color="textSecondary">
        <Translate id="ScheduleDialog.dateAndTimeSubtitle" />
      </Body2>
      <ScheduleTimePicker {...props} />
      <Caption color="textSecondary">
        {t(getTimezoneDescription(props.timezone))}
      </Caption>
      <SwitchField
        label={t("global.repeat")}
        checked={!!periodicity}
        onChange={(e, checked) =>
          onChangePeriodicity(checked ? { unit: "d", quantity: 1 } : null)
        }
      >
        <ColumnStack>
          <Select
            fullWidth
            multiple={false}
            id="date-range-select"
            onChange={(opt) =>
              onChangePeriodicity({
                quantity: periodicity?.quantity ?? 1,
                unit: opt.value,
              })
            }
            label={t("global.frequency")}
            options={PERIODICITY_UNIT_OPTIONS}
            value={PERIODICITY_UNIT_OPTIONS.find(
              (opt) => opt.value === periodicity?.unit ?? "d"
            )}
            translateLabel
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
          />
          <TextField
            label={t("global.every")}
            type="number"
            value={periodicity?.quantity?.toString() || "0"}
            onChange={(e) =>
              onChangePeriodicity({
                unit: periodicity?.unit ?? "d",
                quantity: parseInt(e.target.value) ?? 1,
              })
            }
          />
        </ColumnStack>
      </SwitchField>
    </ColumnStack>
  );
};

export default PostScheduleWidget;
