// @flow
import * as React from "react";
import TeamsSection from "./TeamsSection";
import { useStoreActions } from "../../../../store/store";
import { API as backend } from "../../../../services/backend.service";
import { useCurrentUser } from "../../../../store/selectors";
import useSwitch from "../../../../hooks/useSwitch";
import type { ChildUser } from "../../../../models/user.model";
import type { ModelID } from "../../../../types";
import * as snacks from "../../../../models/alerts.model";
import flow from "lodash/flow";
import { replaceItem } from "../../../../lib/lodashex.lib";
import { byId } from "../../../../models/base.model";
import AddEditChildDialog from "./AddEditChidDialog";
import type { TableSelection } from "../../../lib/display/DataTable/base";

const TeamsSectionContainer: React.ComponentType<{}> = () => {
  const user = useCurrentUser();
  const [children, setChildren] = React.useState<ChildUser[]>([]);
  const [locked, lock, unlock] = useSwitch(true);
  const actions = useStoreActions();
  const [editedChild, setEditedChild] = React.useState<?ChildUser>(null);
  const [dialogOpen, openDialog, closeDialog] = useSwitch();

  React.useEffect(() => {
    backend.profile.getChildAccounts().then(setChildren).then(unlock);
  }, [actions, unlock]);

  if (!user) {
    return false;
  }

  const handleDelete = (selection: TableSelection<ModelID>) => {
    const idsToRemove = [...selection.selection];
    lock();
    return Promise.all(idsToRemove.map(backend.profile.deleteChildUser))
      .then(() => {
        setChildren((oldChildren) =>
          oldChildren.filter((child) => !idsToRemove.includes(child.id))
        );
        actions.snacks.append(
          snacks.localInfo({
            message: "Page.SettingsSection.ChildrenAccountsTab.deleteSuccess",
          })
        );
      })
      .finally(unlock);
  };

  return (
    <>
      <TeamsSection
        locked={locked}
        limits={user.limits}
        childAccounts={children}
        onDelete={handleDelete}
        onAdd={openDialog}
        onUpdate={flow(setEditedChild, openDialog)}
      />
      <AddEditChildDialog
        open={dialogOpen}
        child={editedChild}
        onClose={() => {
          setEditedChild(null);
          closeDialog();
        }}
        onAdded={(child) =>
          setChildren((oldChildren) => [...oldChildren, child])
        }
        onUpdated={(child) =>
          setChildren((oldChildren) =>
            replaceItem(oldChildren, byId(child.id), child)
          )
        }
      />
    </>
  );
};

export default TeamsSectionContainer;
