// @flow
import * as React from "react";
import NewVersionAlert from "./NewVersionAlert";
import { VersionContext } from "../../contexts/version";
import { isIOS } from "../../lib/platform.lib";

const NewIOSVersionAlert: React.ComponentType<{}> = () => {
  const { iOSVersion } = React.useContext(VersionContext);

  return isIOS ? (
    <NewVersionAlert
      version={iOSVersion}
      titleId="Page.newIOSVersionDialog.title"
      yesId="Page.newIOSVersionDialog.yes"
      messageId="Page.newIOSVersionDialog.message"
    />
  ) : null;
};

export default NewIOSVersionAlert;
