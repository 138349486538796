// @flow
import type { AsyncAction, AsyncVoidAction } from "./types";
import * as profileService from "./profile.service";
import * as portfolioService from "./portfolio.service";
import * as integrationService from "./integration.service";
import * as notificationService from "./notification.service";
import * as subscriptionService from "./subscription.service";
import * as hashtagService from "./hashtagGroup.service";
import * as approvalService from "./approval.service";
import type { User } from "../models/user.model";

const loadPlanDependentUserContent: AsyncVoidAction<User> =
  (actions) => (user: User) => {
    actions.hashtagGroup.clear();
    actions.approvalRequest.approvalRequests.clear();
    actions.messaging.conversations.clear();
    actions.messaging.messages.clear();
    actions.messaging.summaries.clear();
    actions.engagement.comments.clear();
    actions.engagement.summaries.clear();

    if (user.limits.can_use_hashtag_manager) {
      hashtagService.getHashtagGroups(actions)();
    }
    if (user.limits.can_use_post_approval) {
      approvalService.getApprovalRequests(actions)();
    }
  };

const setOrLoadUser: AsyncAction<Promise<User>, ?User> =
  (actions) => (user) => {
    if (user) return Promise.resolve(user);
    return profileService.getCurrentUser(actions)();
  };

export const reload: AsyncVoidAction<?User> = (actions) => (user) => {
  console.log("Reloading for user:", user);
  actions.post.posts.clear();
  setOrLoadUser(actions)(user).then(loadPlanDependentUserContent(actions));

  portfolioService.getPortfolios(actions)();
  integrationService.getIntegrations(actions)();
  notificationService.getNotifications(actions)({ page: 1, pagesize: 30 });
  subscriptionService.getPlanFlavours(actions)();
  subscriptionService.getSubscription(actions)();
};
