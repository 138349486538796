// @flow
import type { ModelID, Moment, Timestamp } from "../types";
import moment from "moment";

/**
 * Specifies the current status of a single post approval.
 */
export type PostApprovalStatuses =
  | "pending"
  | "approved"
  | "edits_required"
  | "rejected";

export const ALL_POST_APPROVAL_STATUSES: PostApprovalStatuses[] = [
  "pending",
  "approved",
  "edits_required",
  "rejected",
];

/**
 * Gathers information about the approval state of a post that is part of a request.
 */
export type PostApproval = {
  updated_at: Moment,
  /** The ID of the post this approval refers to. */
  post_id: ModelID,
  /** The ID of the request this approval refers to. */
  request_id: ModelID,
  /** The status of the approval. */
  status: PostApprovalStatuses,
  /** An optional comment from the client. */
  comment?: string,
};

export type APIPostApproval = {
  ...Omit<PostApproval, "updated_at">,
  updated_at: Timestamp,
};

export const fromAPIResult = (approval: APIPostApproval): PostApproval => ({
  ...approval,
  updated_at: moment.unix(approval.updated_at),
});
