// @flow
import * as React from "react";
import type { Conversation } from "../../../../models/conversation.model";
import { getConversationTitle } from "../../../../models/conversation.model";
import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { ConversationAvatar } from "../../../display/avatars";
import useTranslate from "../../../../hooks/useTranslate";

type Props = {
  conversation: Conversation,
};

const ConversationPreview: React.ComponentType<Props> = ({ conversation }) => {
  const t = useTranslate();
  return (
    <ListItem component="div">
      <ListItemAvatar>
        <ConversationAvatar conversation={conversation} />
      </ListItemAvatar>
      <ListItemText
        primary={getConversationTitle(conversation)}
        secondary={t("global.startedAgo", {
          date: conversation.created_at.fromNow(),
        })}
      />
    </ListItem>
  );
};

export default ConversationPreview;
