// @flow
import * as React from "react";
import type { Portfolio } from "../../models/portfolio.model";
import type {
  PortfolioSummary,
  PortfolioSummaryAccount,
  PortfolioSummaryPost,
} from "../../models/portfolioSummary.model";
import { alpha, Collapse, Link, List, Paper, Skeleton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import StdListItem, {
  CircularSkeletonListItem,
  RoundedSkeletonListItem,
} from "../lib/display/listItems";
import { PortfolioAvatar } from "../display/avatars";
import {
  CheckCircleRounded,
  CommentRounded,
  EmailRounded,
  ErrorRounded,
  InfoRounded,
  WarningRounded,
} from "@mui/icons-material";
import useTranslate from "../../hooks/useTranslate";
import sumBy from "lodash/sumBy";
import useSwitch from "../../hooks/useSwitch";
import { LargeTextButton } from "../lib/inputs/buttons";
import useAccountsSummaryCounts from "../../hooks/useAccountsSummaryCounts";
import ChannelIcon from "../lib/display/icons/ChannelIcon";
import type { AccountLifecycleState } from "../../models/account.model";
import { getAccountLifecycleState } from "../../models/account.model";
import { useNavigate } from "react-router-dom";
import type { Callback } from "../../types";
import routes, { getRoutePath } from "../../config/routes.config";
import { useCurrentUser } from "../../store/selectors";
import type { Severity } from "../../models/alerts.model";

type Props = {
  portfolio: Portfolio,
  summary: ?PortfolioSummary,
};

const CARD_WIDTH = 300;

const Root = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  minWidth: CARD_WIDTH,
}));

const S_ICON = <CheckCircleRounded fontSize="small" color="success" />;
const I_ICON = <InfoRounded fontSize="small" color="info" />;
const W_ICON = <WarningRounded fontSize="small" color="warning" />;
const E_ICON = <ErrorRounded fontSize="small" color="error" />;

const LIFECYCLE_STATE_TO_COLOR: Record<AccountLifecycleState, Severity> = {
  disconnected: "error",
  connected: "success",
  expires_soon: "warning",
  expired: "error",
};

const PostsSummaryListItem: React.ComponentType<{
  posts: PortfolioSummaryPost[],
}> = ({ posts }) => {
  const t = useTranslate();
  const count = posts.length;
  return count === 0 ? (
    <StdListItem icon={S_ICON} primary={t("postsSummary.ok")} />
  ) : (
    <StdListItem icon={E_ICON} primary={t("postsSummary.error", { count })} />
  );
};

const EngagementSummaryListItem: React.ComponentType<{
  comments: number,
  messages: number,
}> = ({ comments, messages }) => {
  const t = useTranslate();
  return comments + messages === 0 ? (
    <StdListItem icon={S_ICON} primary={t("engagementSummary.nothing")} />
  ) : (
    <StdListItem icon={I_ICON} primary={t("engagementSummary.something")} />
  );
};

const AccountsSummaryListItem: React.ComponentType<{
  accounts: PortfolioSummaryAccount[],
}> = ({ accounts }) => {
  const t = useTranslate();
  const counts = useAccountsSummaryCounts(accounts);

  if (counts.errors > 0) {
    return (
      <StdListItem
        icon={E_ICON}
        primary={t("accountsSummary.someErrors", counts)}
      />
    );
  }

  if (counts.expired > 0) {
    return (
      <StdListItem
        icon={E_ICON}
        primary={t("accountsSummary.someExpired", counts)}
      />
    );
  }

  if (counts.expires_soon > 0) {
    return (
      <StdListItem
        icon={W_ICON}
        primary={t("accountsSummary.someAboutToExpire", counts)}
      />
    );
  }

  return <StdListItem icon={S_ICON} primary={t("accountsSummary.ok")} />;
};

const Summary: React.ComponentType<{
  failedPosts: PortfolioSummaryPost[],
  accounts: PortfolioSummaryAccount[],
  unreadComments: number,
  unreadMessages: number,
}> = React.forwardRef(
  ({ failedPosts, accounts, unreadComments, unreadMessages }, ref) => {
    return (
      <List dense ref={ref}>
        <PostsSummaryListItem posts={failedPosts} />
        <AccountsSummaryListItem accounts={accounts} />
        <EngagementSummaryListItem
          comments={unreadComments}
          messages={unreadMessages}
        />
      </List>
    );
  }
);

const ColoredStdListItem = styled(StdListItem, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  backgroundColor: alpha(theme.palette[color].light, 0.15),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(0.5),
}));

const AccountSummaryListItem = ({
  summary,
  onClick,
}: {
  summary: PortfolioSummaryAccount,
  onClick: Callback,
}) => {
  const t = useTranslate();
  const state = getAccountLifecycleState(summary);
  return (
    <ColoredStdListItem
      onClick={onClick}
      color={LIFECYCLE_STATE_TO_COLOR[state]}
      icon={
        <ChannelIcon channel={summary.channel} color="channel" size="medium" />
      }
      primary={summary.entity_name}
      secondary={t(`AccountSummaryListItem.secondary.${state}`, {
        expiresIn: summary.access_token_expiry.fromNow(),
      })}
    ></ColoredStdListItem>
  );
};

const EMPTY_ACCOUNTS: PortfolioSummaryAccount[] = [];
const EMPTY_POSTS: PortfolioSummaryPost[] = [];

const PortfolioCard: React.ComponentType<Props> = React.memo(
  ({ portfolio, summary }) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [isOpen, open, close] = useSwitch();
    const user = useCurrentUser();
    const accountSummaries = summary?.accounts ?? EMPTY_ACCOUNTS;
    const posts = summary?.posts ?? EMPTY_POSTS;
    const unreadMessages = React.useMemo(
      () => sumBy(accountSummaries, (acc) => acc.unread_messages_count),
      [accountSummaries]
    );
    const unreadComments = React.useMemo(
      () => sumBy(accountSummaries, (acc) => acc.unread_comments_count),
      [accountSummaries]
    );

    const handleNavigateToAccounts = React.useCallback(
      () =>
        navigate(
          getRoutePath(routes.app.nested.portfolios.nested.socialAccounts, {
            portfolioId: portfolio.id,
          })
        ),
      [navigate, portfolio.id]
    );

    const handleNavigateToEngagement = React.useCallback(
      () =>
        navigate(
          getRoutePath(
            routes.app.nested.portfolios.nested.engagement,
            { portfolioId: portfolio.id },
            { unreadItemsOnly: "1" }
          )
        ),
      [navigate, portfolio.id]
    );

    const handleNavigateToPosts = React.useCallback(
      () =>
        navigate(
          getRoutePath(
            routes.app.nested.portfolios.nested.creator,
            { portfolioId: portfolio.id },
            { postId: posts.map((p) => p.id).join(",") }
          )
        ),
      [posts, navigate, portfolio.id]
    );

    return (
      <Root>
        <StdListItem
          avatar={<PortfolioAvatar portfolio={portfolio} />}
          primary={
            <Link
              component={RouterLink}
              to={
                accountSummaries === EMPTY_ACCOUNTS
                  ? `portfolios/${portfolio.id}/accounts  `
                  : `portfolios/${portfolio.id}/dashboard`
              }
            >
              {portfolio.name}
            </Link>
          }
          primaryProps={{ fontWeight: "bold" }}
          secondary={portfolio.description || <>&nbsp;</>}
        />
        <Collapse in={!isOpen}>
          <Summary
            failedPosts={posts}
            accounts={accountSummaries}
            unreadMessages={unreadMessages}
            unreadComments={unreadComments}
          />
        </Collapse>
        <LargeTextButton
          onClick={isOpen ? close : open}
          disabled={accountSummaries.length === 0}
        >
          {t(isOpen ? "global.less" : "global.more")}
        </LargeTextButton>
        <Collapse in={isOpen}>
          <List dense disablePadding>
            {accountSummaries.map((acc) => (
              <AccountSummaryListItem
                key={acc.id}
                summary={acc}
                onClick={handleNavigateToAccounts}
              />
            ))}
            {posts.length > 0 && (
              <ColoredStdListItem
                color="error"
                icon={E_ICON}
                primary={t("postsSummary.error", { count: posts.length })}
                onClick={handleNavigateToPosts}
              />
            )}
            {user?.limits.can_use_comment_manager && unreadComments > 0 && (
              <ColoredStdListItem
                icon={<CommentRounded color="info" />}
                color="info"
                primary={t("global.newCommentsCount", {
                  count: unreadComments,
                })}
                primaryProps={{ fontWeight: "bold" }}
                onClick={handleNavigateToEngagement}
              />
            )}
            {user?.limits.can_use_comment_manager && unreadMessages > 0 && (
              <ColoredStdListItem
                icon={<EmailRounded color="info" />}
                color="info"
                primary={t("global.newMessagesCount", {
                  count: unreadMessages,
                })}
                primaryProps={{ fontWeight: "bold" }}
                onClick={handleNavigateToEngagement}
              />
            )}
          </List>
        </Collapse>
      </Root>
    );
  }
);

export const PortfolioCardSkeleton: React.ComponentType<{}> = () => (
  <Root>
    <RoundedSkeletonListItem animation={false} />
    <CircularSkeletonListItem dense animation={false} />
    <CircularSkeletonListItem dense animation={false} />
    <CircularSkeletonListItem dense animation={false} />
    <Skeleton
      variant="rounded"
      animation={false}
      width={CARD_WIDTH - 16}
      height={42}
    />
  </Root>
);

export default PortfolioCard;
