// @flow
import * as React from "react";
import ColoredChip from "../lib/display/ColoredChip";
import { PollRounded } from "@mui/icons-material";
import useTranslate from "../../hooks/useTranslate";
import { Tooltip } from "@mui/material";

const PollChip: React.ComponentType<{}> = React.memo(() => (
  <Tooltip title={useTranslate()("global.poll")}>
    <ColoredChip paletteColor="poll">
      <PollRounded fontSize="inherit" color="inherit" />
    </ColoredChip>
  </Tooltip>
));

export default PollChip;
