// @flow
import * as React from "react";
import type { Post } from "../../models/post.model";
import type {
  PostApproval,
  PostApprovalStatuses,
} from "../../models/postApproval.model";
import PostCard from "../display/PostCard";
import PleaseWaitProgress from "../feedback/PleaseWaitProgress";
import type { BobcaatAccountPublicInfo } from "../../models/account.model";
import type { Inexact } from "../../types";
import { ColumnStack } from "../lib/layout/stacks";
import { TextField } from "@mui/material";
import { UserToken } from "../../lib/session.lib";
import { Body2 } from "../lib/display/Text";
import useTranslate from "../../hooks/useTranslate";
import isEmpty from "lodash/isEmpty";
import PostApprovalStatusChip from "../display/PostApprovalStatusChip";
import Select from "../lib/inputs/Select";
import { ALL_POST_APPROVAL_STATUSES } from "../../models/postApproval.model";
import {
  getPostApprovalStatusColor,
  PostApprovalStatusAdornment,
} from "../display/Adornments";

type Props = {
  post: ?Post,
  account: Inexact<BobcaatAccountPublicInfo>,
  postApproval: PostApproval,
  onUpdatePostApproval: (PostApproval) => any,
};

const readOnlyInputProps = { readOnly: true, disableUnderline: true };

const PostApprovalWidget: React.ComponentType<Props> = ({
  post,
  account,
  postApproval,
  onUpdatePostApproval,
}) => {
  const t = useTranslate();
  if (!post) return <PleaseWaitProgress />;
  const isUser = UserToken.isValid();

  return (
    <PostCard post={post} account={account}>
      <ColumnStack padding={1} alignItems="flex-end">
        {isUser && !isEmpty(postApproval.comment) && (
          <Body2 fontWeight="bold" style={{ alignSelf: "flex-start" }}>
            {t("ApprovalPage.Main.PostApprovalsTable.commentFromYourClient")}
          </Body2>
        )}
        <TextField
          placeholder={t(
            isUser
              ? "ApprovalPage.Main.PostApprovalsTable.noComment"
              : "ApprovalPage.Main.PostApprovalsTable.commentPlaceholder"
          )}
          multiline
          fullWidth
          defaultValue={postApproval.comment}
          InputProps={isUser ? readOnlyInputProps : undefined}
          onBlur={(e) =>
            !isUser &&
            e.currentTarget.value !== postApproval.comment &&
            onUpdatePostApproval({
              ...postApproval,
              comment: e.currentTarget.value,
            })
          }
          maxRows={5}
        />
        {isUser ? (
          <PostApprovalStatusChip status={postApproval.status} />
        ) : (
          <Select
            id="select-approval-status"
            color={getPostApprovalStatusColor(postApproval.status)}
            size="small"
            adornOptions
            AdornmentComponent={PostApprovalStatusAdornment}
            translateLabel
            disabled={isUser}
            value={postApproval.status}
            getOptionLabel={(status) => "refer.postApprovalStatus." + status}
            onChange={(status: ?PostApprovalStatuses) =>
              status &&
              status !== postApproval.status &&
              onUpdatePostApproval({ ...postApproval, status })
            }
            options={ALL_POST_APPROVAL_STATUSES.filter(
              (opt) => opt !== "pending" || postApproval.status === "pending"
            )}
          />
        )}
      </ColumnStack>
    </PostCard>
  );
};

export default PostApprovalWidget;
