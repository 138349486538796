// @flow
import * as React from "react";
import EngagementHubPosts from "./EngagementHubPosts";
import { useScopedAccount } from "../../../scopes/scopes";
import type { ModelID } from "../../../types";
import type { Post } from "../../../models/post.model";
import EngagementHubContext from "../../../contexts/engagementHub";
import {
  useAccountEngageablePosts,
  useCommentSummaries,
  usePostsInitialised,
} from "../../../store/selectors";
import { useNavigate, useParams } from "react-router-dom";
import { useStoreActions } from "../../../store/store";
import * as engagementService from "../../../services/engagement.service";
import routes, { getRoutePath } from "../../../config/routes.config";
import { getId, parseModelId } from "../../../models/base.model";
import orderBy from "lodash/orderBy";
import type { SocialActionSummary } from "../../../models/socialActionSummary.model";

const filterPosts = (
  posts: Post[],
  summaries: { [ModelID]: SocialActionSummary },
  commentedPostsOnly: boolean,
  unreadItemsOnly: boolean,
  selectedPostId: ?ModelID
) => {
  if (unreadItemsOnly) {
    posts = posts.filter(
      (post) =>
        post.id === selectedPostId || summaries[post.id]?.unread_comments > 0
    );
  }
  // else if here, because if unread only, posts must have comments.
  else if (commentedPostsOnly) {
    posts = posts.filter((post) => summaries[post.id]?.comments_count > 0);
  }
  // order by last active, or post id.
  return orderBy(
    posts,
    (p) => summaries[p.id]?.latest_comment_at || getId(p),
    "desc"
  );
};

const EngagementHubPostsContainer: React.ComponentType<{}> = () => {
  const account = useScopedAccount();
  const actions = useStoreActions();
  const navigate = useNavigate();
  const { commentedPostsOnly, unreadItemsOnly } =
    React.useContext(EngagementHubContext);
  const engageablePosts = useAccountEngageablePosts(account?.id);
  const summaries = useCommentSummaries();
  const { postId: rawSelectedPostId } = useParams();
  const selectedPostId = parseModelId(rawSelectedPostId);
  const initialised = usePostsInitialised();

  const posts = React.useMemo(
    () =>
      filterPosts(
        engageablePosts,
        summaries,
        commentedPostsOnly,
        unreadItemsOnly,
        selectedPostId
      ),
    [
      engageablePosts,
      summaries,
      commentedPostsOnly,
      unreadItemsOnly,
      selectedPostId,
    ]
  );

  const handleSelectPostId = (postId: ModelID) => {
    if (postId && account) {
      engagementService.markPostCommentsAsRead(actions)(postId);
      navigate(
        getRoutePath(
          routes.app.nested.portfolios.nested.engagement.nested.account.nested
            .posts.nested.post.nested.comments,
          { portfolioId: account.portfolio_id, accountId: account.id, postId }
        )
      );
    }
  };

  return (
    <EngagementHubPosts
      posts={posts}
      summaries={summaries}
      onSelectPost={handleSelectPostId}
      selectedPostId={selectedPostId}
      postsInitialised={initialised}
    />
  );
};

export default EngagementHubPostsContainer;
