// @flow
import * as React from "react";
import type { Callback } from "../../types";
import PlanUpgradeInviteDialog from "./PlanUpgradeInviteDialog";

type Props = {
  open?: boolean,
  onClose: Callback,
};

const UpgradeRequiredDialogContainer: React.ComponentType<Props> = ({
  open,
  onClose,
}) => {
  return (
    <PlanUpgradeInviteDialog
      open={open}
      onClose={onClose}
      title={"UpgradeRequiredDialog.title"}
      message={"UpgradeRequiredDialog.upgradeRequired"}
      cancelLabel={"UpgradeRequiredDialog.cancel"}
      acceptLabel={"UpgradeRequiredDialog.submit"}
    />
  );
};

export default UpgradeRequiredDialogContainer;
