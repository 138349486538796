// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Caption } from "../lib/display/Text";
import useTranslate from "../../hooks/useTranslate";

type Props = {
  page: number,
  pages: number,
};

const Root = styled(Caption)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const Pagination: React.ComponentType<Props> = (props) => {
  const t = useTranslate();
  return (
    <Root color="textSecondary" display="block" align="right">
      {t("global.pagination", props)}
    </Root>
  );
};

export default Pagination;
