// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { propMap } from "../utils/props";
import type { CSSProps } from "../../../reactTypes";

type StickerSize = "xsmall" | "small" | "medium" | "large" | "xlarge";
type StickerVariant = "circle" | "rounded";

export type StickerProps = {
  size?: StickerSize,
  variant?: StickerVariant,
  color?: string,
  outline?: boolean,
  children?: React.ChildrenArray<any>,
  ...CSSProps,
};

const getBorderRadius = propMap({ rounded: "4px", circle: "50%" }, "circle");

const getBorder = (theme: any, outline?: boolean) =>
  outline ? `solid 1px ${theme.palette.divider}` : "transparent";

const Sticker: React.ComponentType<StickerProps> = styled("div", {
  shouldForwardProp: (prop) =>
    !["size", "variant", "outline", "color"].includes(prop),
})(
  ({ theme, size = "medium", variant, outline, color }) => `
  border-radius: ${getBorderRadius(variant)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${color ?? theme.palette.background.paper};
  width: ${theme.const.iconSize[size]}px;
  height: ${theme.const.iconSize[size]}px;
  border: ${getBorder(theme, outline)};
`
);

export default Sticker;
