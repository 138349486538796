// @flow
import * as React from "react";
import type { Slug } from "../types";
import lightThemeIcon from "@mui/icons-material/WbSunnyRounded";
import darkThemeIcon from "@mui/icons-material/Brightness2Rounded";
import { bySlug } from "../models/base.model";

export type ThemeOption = {
  slug: Slug,
  label: string,
  Icon: React.ComponentType<{ htmlColor?: string }>,
  color: string,
};

export const themeOptions: ThemeOption[] = [
  {
    slug: "light",
    label: `refer.themes.light`,
    Icon: lightThemeIcon,
    color: "gold",
  },
  {
    slug: "dark",
    label: `refer.themes.dark`,
    Icon: darkThemeIcon,
    color: "#479FD5",
  },
];

export const getOptionLabel = (option: ThemeOption): string => option.label;

export const getOptionValue = (option: ThemeOption): string => option.slug;

export const getOptionFromSlug = (slug: Slug): ?ThemeOption =>
  themeOptions.find(bySlug(slug));

export const userPrefersDarkColorScheme = (): boolean =>
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;
