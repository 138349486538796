// @flow
import { MBYTE } from "../../lib/files.lib";
import type { Channel } from "./channel.model";
import {
  login,
  wasLoginCancelled,
} from "../../services/channels/twitter.service";

const twitter: Channel = {
  name: "X",
  slug: "twitter",
  color: "#000",

  features: {
    personnalAccount: true,
    content: {
      selfReplies: true,
      polls: {
        separateQuestion: false,
        pollsOrAttachments: true,
        maxOptionLength: 25,
      },
    },
  },

  requires: {
    extractLink: true,
  },

  contentSpecs: {
    maxChars: 280,
    media: {
      image: {
        mimetypes: ["image/png", "image/jpeg", "image/gif"],
        maxCount: 4,
        maxSize: 5 * MBYTE,
      },
      gif: {
        maxSize: 15 * MBYTE,
        maxCount: 1,
      },
      video: {
        mimetypes: ["video/mp4", "video/mov", "video/quicktime"],
        maxSize: 512 * MBYTE,
        maxCount: 1,
      },
      overall: {
        maxCount: 4,
        oneMediaTypeOnly: true,
      },
    },
  },

  subchannelTypes: [],
  surfaces: [],
  wasLoginCancelled,
  login,
};

export default twitter;
