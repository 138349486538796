// @flow
import { styled } from "@mui/material/styles";

const BlurLayer: "div" = styled("div", {
  shouldForwardProp: (prop) => prop !== "blur",
})(({ blur = 8 }) => ({
  width: "100%",
  height: "100%",
  backdropFilter: `blur(${blur}px)`,
}));

export default BlurLayer;
