// @flow
import * as React from "react";
import useTranslate from "../../../hooks/useTranslate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import type { Moment } from "../../../types";

export type Props = {
  fromDate: ?Moment,
  toDate: ?Moment,
  onSetToDate: (Moment) => any,
  onSetFromDate: (Moment) => any,
  minFromDate?: ?Moment,
  maxFromDate?: ?Moment,
  minToDate?: ?Moment,
  maxToDate?: ?Moment,
  slotProps?: {
    datePicker?: Object,
  },
};

const DateRangeInputs: React.ComponentType<Props> = ({
  fromDate,
  toDate,
  onSetToDate,
  onSetFromDate,
  minFromDate,
  maxFromDate,
  minToDate,
  maxToDate,
  slotProps,
}) => {
  const t = useTranslate();
  return (
    <>
      <DatePicker
        {...slotProps?.datePicker}
        margin="none"
        label={t("global.fromdate")}
        value={fromDate}
        minDate={minFromDate}
        maxDate={maxFromDate}
        onChange={onSetFromDate}
      />
      <DatePicker
        {...slotProps?.datePicker}
        margin="none"
        label={t("global.todate")}
        value={toDate}
        minDate={minToDate}
        maxDate={maxToDate}
        onChange={onSetToDate}
      />
    </>
  );
};

export default DateRangeInputs;
