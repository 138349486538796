// @flow
import * as React from "react";
import useTimer from "../hooks/useTimer";
import { API as backend } from "../services/backend.service";
import noop from "lodash/noop";
import type { Provider } from "../reactTypes";
import type { EventType } from "../config/eventTypes.config";
import type { Moment } from "../types";

export type OnNavigate = (?EventType) => void;
export type OnBeat = (lastBeat: Moment, onDone: (Object) => any) => void;

type _TrackingContext = {
  onFire: (EventType) => void,
  onBeat: OnBeat,
  onNavigate: OnNavigate,
};

export const TrackingContext: React.Context<_TrackingContext> =
  React.createContext({
    onFire: noop,
    onBeat: noop,
    onNavigate: noop,
  });

/**
 * Provides a context to fire user tracking events.
 */
const TrackingProvider: Provider<_TrackingContext> = ({ children }) => {
  const timer = useTimer();
  const [, setEvents] = React.useState<EventType[]>([]);

  const onFire = React.useCallback((event: EventType) => {
    setEvents((oldEvents) => [...oldEvents, event]);
  }, []);

  const onBeat = React.useCallback(
    (lastBeat: Moment, onDone: (Object) => any) => {
      setEvents((events) => {
        backend.race.beat(lastBeat, events).then(onDone);
        return [];
      });
    },
    []
  );

  const onNavigate = React.useCallback(
    (eventType: ?EventType) => {
      if (eventType) {
        // Stop any ticking timer and start a new one. The event is only
        // fired if the user stays on the page for more than 5 seconds.
        timer.stop();
        timer.start(() => onFire(eventType), 3000);
      }
    },
    [timer, onFire]
  );

  return (
    <TrackingContext.Provider value={{ onFire, onBeat, onNavigate }}>
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingProvider;
