// @flow
import * as React from "react";
import AccountCard from "./AccountCard";
import useBind from "./useBind";
import type { Channel } from "../../../models/channels/channel.model";
import type { BobcaatAccount } from "../../../models/account.model";
import type { ModelID } from "../../../types";

type Props = {
  portfolioId: ModelID,
  channel: Channel,
  account: BobcaatAccount,
};

const AccountCardContainer: React.ComponentType<Props> = React.forwardRef(
  ({ channel, account, portfolioId }, ref) => {
    const [onBind, fragment] = useBind({
      channel,
      account,
      portfolioId,
    });
    return (
      <>
        {fragment}
        <AccountCard
          ref={ref}
          channel={channel}
          account={account}
          onBind={onBind}
        />
      </>
    );
  }
);

export default AccountCardContainer;
