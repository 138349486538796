// @flow
import * as React from "react";
import type { Provider } from "../reactTypes";
import noop from "lodash/noop";

type _DropContext = {
  isActive: boolean,
  onDragOver: (e: SyntheticDragEvent<>) => any,
  onDragLeave: (e: SyntheticDragEvent<>) => any,
  onDragEnter: (e: SyntheticDragEvent<>) => any,
  onDrop: (e: SyntheticDragEvent<>) => any,
};

export const DropContext: React.Context<_DropContext> = React.createContext({
  isActive: false,
  onDragOver: noop,
  onDragLeave: noop,
  onDragEnter: noop,
  onDrop: noop,
});

const preventDefault = (e: SyntheticDragEvent<>) => {
  e.stopPropagation();
  e.preventDefault();
};

export const DropContextProvider: Provider<
  _DropContext,
  { onSelectFiles: (File[]) => any }
> = ({ children, onSelectFiles }) => {
  const [active, setActive] = React.useState(0);

  return (
    <DropContext.Provider
      value={{
        isActive: Boolean(active),
        onDragLeave: React.useCallback(() => setActive((cur) => cur - 1), []),
        onDragEnter: React.useCallback(
          () => setActive((cur) => Math.max(0, cur + 1)),
          []
        ),
        onDragOver: preventDefault,
        onDrop: React.useCallback(
          (e) => {
            // Prevent default behavior (Prevent file from being opened)
            preventDefault(e);

            onSelectFiles(
              e.dataTransfer.items
                ? [...e.dataTransfer.items]
                    .filter((item) => item.kind === "file")
                    .map((item) => item.getAsFile())
                    .filter(Boolean)
                : [...e.dataTransfer.files]
            );
            setActive(0);
          },
          [onSelectFiles]
        ),
      }}
    >
      {children}
    </DropContext.Provider>
  );
};

export default DropContext;
