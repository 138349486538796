// @flow
import * as React from "react";
import {
  SettingsSectionHead,
  SettingsSectionRoot,
} from "../../../layout/SettingsSection";
import useTranslate from "../../../../hooks/useTranslate";
import type { Callback, ModelID } from "../../../../types";
import { Body2, Caption } from "../../../lib/display/Text";
import type { LimitSet } from "../../../../models/limitSet.model";
import type { ChildUser } from "../../../../models/user.model";
import DataTable from "../../../lib/display/DataTable/DataTable";
import type {
  CellComponent,
  Column,
  TableSelection,
} from "../../../lib/display/DataTable/base";
import {
  PrimaryTextButton,
  SmallPrimaryButton,
} from "../../../lib/inputs/buttons";
import { PersonAddRounded } from "@mui/icons-material";
import useIsMobile from "../../../../hooks/useIsMobile";
import { UserAvatar } from "../../../display/avatars";
import useConfirmDialog from "../../../lib/feedback/useConfirmDialog";
import makeSkeletonTable from "../../../lib/display/DataTable/makeSkeletonTable";
import StandardEmptyNotice from "../../../display/EmptyNotice";
import { makeDeleteAction } from "../../../lib/display/DataTable/TableAction";

type CellProps = {
  onUpdate: (ChildUser) => any,
};

type Props = {
  ...CellProps,
  locked?: boolean,
  limits: LimitSet,
  onAdd: Callback,
  onDelete: (TableSelection<ModelID>) => any,
  childAccounts: ChildUser[],
};

const AvatarCell: CellComponent<ChildUser, CellProps> = ({ row }) => (
  <UserAvatar user={row} size={32} />
);

const FirstNameCell: CellComponent<ChildUser, CellProps> = ({
  row,
  cellProps,
}) => {
  return (
    <PrimaryTextButton onClick={() => cellProps?.onUpdate(row)}>
      {row.first_name}
    </PrimaryTextButton>
  );
};

const columns: Column<ChildUser, CellProps>[] = [
  { label: "", Component: AvatarCell, headCellProps: { sx: { width: 70 } } },
  {
    label: "global.firstName",
    Component: FirstNameCell,
    headCellProps: { sx: { width: 150 } },
  },
  {
    label: "global.lastName",
    dataKey: "last_name",
    headCellProps: { sx: { width: 150 } },
  },
  { label: "global.email", dataKey: "email" },
];

const SKELETON_SLOT_PROPS = {
  tableContainer: {
    sx: { minHeight: 150 },
  },
};

const SLOT_PROPS = {
  toolbar: {},
  ...SKELETON_SLOT_PROPS,
};

export const SkeletonTeamsTable: React.ComponentType<{}> = makeSkeletonTable({
  columns,
  slotProps: SKELETON_SLOT_PROPS,
  rowsCount: 2,
});

const TeamsSection: React.ComponentType<Props> = ({
  locked,
  limits,
  onAdd,
  onUpdate,
  onDelete,
  childAccounts,
}) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const [handleDelete, deleteDlg] = useConfirmDialog(
    {
      DialogProps: {
        name: "delete-child",
        title: "Page.SettingsSection.ChildrenAccountsTab.confirmDelete.title",
        message:
          "Page.SettingsSection.ChildrenAccountsTab.confirmDelete.message",
      },
    },
    onDelete
  );
  return (
    <>
      {deleteDlg}
      <SettingsSectionRoot>
        <SettingsSectionHead title={t("global.childrenAccounts")}>
          <SmallPrimaryButton
            onClick={onAdd}
            disabled={locked || childAccounts.length >= limits.max_children}
          >
            <PersonAddRounded />
            &nbsp;
            {!isMobile && t("global.create")}
          </SmallPrimaryButton>
        </SettingsSectionHead>
        <Body2 color="textSecondary" paragraph>
          {t("Page.SettingsSection.ChildrenAccountsTab.description")}
        </Body2>
        {childAccounts.length ? (
          <DataTable
            columns={columns}
            rows={childAccounts}
            selectable
            actions={[makeDeleteAction(handleDelete)]}
            cellProps={{ onUpdate }}
            slotProps={SLOT_PROPS}
          />
        ) : (
          <StandardEmptyNotice bgColor="paper">
            <SkeletonTeamsTable />
          </StandardEmptyNotice>
        )}
        <Caption>
          {t("Page.SettingsSection.ChildrenAccountsTab.remainingChildren", {
            count: limits.max_children - childAccounts.length,
          })}
        </Caption>
      </SettingsSectionRoot>
    </>
  );
};

export default TeamsSection;
