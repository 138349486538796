// @flow
import * as React from "react";
import type {
  FormField,
  MediaFormFieldDef,
  OnChangeField,
} from "../../../../hooks/useForm";
import { useFormFieldError } from "../../../../hooks/useForm";
import MediaInput from "../MediaInput/MediaInput";
import type { Props as MediaInputProps } from "../MediaInput/useHeadlessMediaInput";

type Props = {
  ...Omit<
    MediaInputProps,
    "error" | "helperText" | "maxFiles" | "items" | "maxSize"
  >,
  formField: FormField<MediaFormFieldDef>,
  onChange: OnChangeField<MediaFormFieldDef>,
};

const FormMediaInput: React.ComponentType<Props> = ({
  formField,
  ...props
}) => {
  const helperText = useFormFieldError(formField) ?? formField.helperText;
  return (
    <MediaInput
      items={formField.value}
      maxFiles={formField.definition.maxItems}
      maxSize={formField.definition.maxItemSize}
      error={!!formField.error}
      type={formField.definition.mediaType}
      helperText={helperText}
      disabled={formField.value.length > formField.definition.maxItems}
      {...props}
    />
  );
};

export default FormMediaInput;
