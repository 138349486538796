// @flow
import * as React from "react";
import MimeTypeBasedPreview from "./MimeTypeBasedPreview";
import { getFileOrURLMimeType, getMimetypeType } from "../../../lib/files.lib";
import type { Props as ImageProps } from "./Image";
import type { Props as VideoProps } from "./Video";
import type { Props as FileProps } from "./File";
import type { URLStr } from "../../../types";

export type Props = {
  ...Omit<{ ...ImageProps, ...VideoProps, ...FileProps }, "src" | "alt">,
  src: URLStr,
};

const URLPreview: React.ComponentType<Props> = React.forwardRef(
  ({ src, ...props }, ref) => {
    const mimetype = React.useMemo(() => getFileOrURLMimeType(src), [src]);
    const mimetypeType = getMimetypeType(mimetype);

    return (
      <MimeTypeBasedPreview
        src={src}
        alt=""
        mimetypeType={mimetypeType}
        ref={ref}
        {...props}
      />
    );
  }
);

export default URLPreview;
