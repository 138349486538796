// @flow
import * as React from "react";
import IntegrationsSection from "./IntegrationsSection";
import { useIntegrations } from "../../../../store/selectors";
import { useStore } from "../../../../store/store";
import * as integrationService from "../../../../services/integration.service";

const IntegrationsSectionContainer: React.ComponentType<{}> = () => {
  const integrations = useIntegrations();
  const [state, actions] = useStore();

  return (
    <IntegrationsSection
      integrations={integrations}
      onRefresh={integrationService.getIntegrations(actions)}
      locked={state.integration.locked}
    />
  );
};

export default IntegrationsSectionContainer;
