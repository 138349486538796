// @flow
import * as React from "react";
import FilePicker from "./FilePicker";
import type { Props as FilePickerProps } from "./FilePicker";
import {
  DEFAULT_ACCEPT_IMAGES,
  DEFAULT_IMAGE_MAX_SIZE,
} from "../../../../lib/files.lib";
import type { DefaultSomeProps } from "../../../../reactTypes";
import { withDefaultProps } from "../../utils/hoc";

type OverridenKeys = "accept" | "maxSize";

type Props = DefaultSomeProps<FilePickerProps, OverridenKeys>;

const ImagePicker: React.ComponentType<Props> = withDefaultProps<
  FilePickerProps,
  OverridenKeys
>(FilePicker, {
  accept: DEFAULT_ACCEPT_IMAGES,
  maxSize: DEFAULT_IMAGE_MAX_SIZE,
});

export default ImagePicker;
