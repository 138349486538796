// @flow
import * as React from "react";
import type { Form, StringFormFieldDef } from "../../../hooks/useForm";
import { Body1, Header, Subtitle } from "../../lib/display/Text";
import type { Callback } from "../../../types";
import HTMLForm from "../../lib/inputs/form/HTMLForm";
import Section from "../../layout/Section";
import useTranslate from "../../../hooks/useTranslate";
import FormTextInput from "../../lib/inputs/form/FormTextInput";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeGradient } from "../../../util/colors.util";
import { RowStack } from "../../lib/layout/stacks";
import Logo from "../../display/Logo";
import Translate from "../../lib/display/Translate";
import CopyIconButton from "../../inputs/CopyIconButton";
import { FormActions } from "../../../hooks/useForm";

export type InviteFriendFormDef = {
  name: StringFormFieldDef,
  email: StringFormFieldDef,
  message: StringFormFieldDef,
};

const CouponRoot = styled(Paper)(({ theme }) => ({
  background:
    "url('https://s3.eu-west-2.amazonaws.com/yellowstones-eu-west-2/public/images/emails/backend-email-back.jpg')",
  position: "relative",
  color: theme.palette.getContrastText(theme.palette.primary.main),
  borderRadius: 16,
  overflow: "clip",
}));

const CouponBack = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  opacity: 0.4,
  zIndex: 0,
  background: makeGradient({
    startColor: theme.palette.primary.main,
    endColor: theme.palette.primary.light,
  }),
}));

const CouponContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: 200,
  alignItems: "flex-end",
  justifyContent: "space-between",
  padding: 16,
  position: "relative",
});

const Coupon = React.memo(({ referralCode }: { referralCode: string }) => (
  <CouponRoot>
    <CouponBack />
    <CouponContent>
      <RowStack justifyContent="space-between" alignItems="center" width="100%">
        <Logo size={48} />
        <Header>bobcaat</Header>
        <CopyIconButton contentElId="referral-code" />
      </RowStack>
      <div>
        <Subtitle id="referral-code">{referralCode}</Subtitle>
        <Body1>
          <Translate id="global.referralCode" />
        </Body1>
      </div>
    </CouponContent>
  </CouponRoot>
));

type Props = {
  form: Form<InviteFriendFormDef>,
  onConfirm: Callback,
  referralCode?: string,
};

const InviteAFriendWidget: React.ComponentType<Props> = ({
  form,
  onConfirm,
  referralCode,
}) => {
  const t = useTranslate();
  return (
    <HTMLForm onSubmit={onConfirm} submitTextId="InviteFriendForm.submit">
      <Section title="InviteFriendForm.subtitle">
        <Body1>{t("InviteFriendForm.body1")}</Body1>
        <Coupon referralCode={referralCode ?? ""} />
        <Body1>{t("InviteFriendForm.body2")}</Body1>
      </Section>
      <Section title="global.yourFriend">
        <FormTextInput
          required
          formField={form.state.name}
          onChange={FormActions.onChangeField(form.set, "name")}
          label={t("InviteFriendForm.yourFriendsName")}
        />
        <FormTextInput
          required
          formField={form.state.email}
          onChange={FormActions.onChangeField(form.set, "email")}
          label={t("InviteFriendForm.yourFriendsEmail")}
          type="email"
        />
        <FormTextInput
          required
          formField={form.state.message}
          onChange={FormActions.onChangeField(form.set, "message")}
          label={t("global.message")}
          placeholder={t("InviteFriendForm.messagePlaceholder")}
          multiline
          rows={5}
        />
      </Section>
    </HTMLForm>
  );
};

export default InviteAFriendWidget;
