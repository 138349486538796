// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import PostFiltersContext, {
  PostFiltersContextProvider,
} from "../../../contexts/postFilters";
import PostSearchBar from "../../../components/widgets/PostSearchBar";
import useLocationPostFilters from "../../../hooks/useLocationPostFilters";
import { useScopedPortfolio } from "../../../scopes/scopes";
import PostsTable from "../../../components/widgets/PostsTable";
import useFilteredPosts from "../../../hooks/useFilteredPosts";
import { SkeletonPostsTable } from "../../../components/widgets/PostsTable/PostsTable";
import { Center } from "../../../components/lib/layout/placement";
import StandardEmptyNotice from "../../../components/display/EmptyNotice";
import { usePostsInitialised } from "../../../store/selectors";
import PleaseWaitProgress from "../../../components/feedback/PleaseWaitProgress";
import { Outlet } from "react-router-dom";
import NoResults from "../../../components/display/NoResults";

const Inner: React.ComponentType<{}> = () => {
  const pf = useScopedPortfolio();
  const posts = useFilteredPosts(pf?.id);
  const postsInitialised = usePostsInitialised();
  const { activeFilters } = React.useContext(PostFiltersContext);
  const route = routes.app.nested.portfolios.nested.creator;

  return (
    <>
      <PrimaryPagePanel size="fill" route={route}>
        <PostSearchBar portfolioId={pf?.id} />
        {postsInitialised === "none" && <PleaseWaitProgress />}
        {
          // If there is a filter but no posts, no results.
          activeFilters > 0 &&
            postsInitialised === "all" &&
            posts.length === 0 && <NoResults textId="global.noPostsResult" />
        }
        {
          // If there is no filter but no posts, no post yet
          activeFilters === 0 &&
            postsInitialised === "all" &&
            posts.length === 0 && (
              <Center>
                <StandardEmptyNotice
                  label="global.noPostsYet"
                  addResourceButtonProps={{
                    size: "large",
                    label: "routes.addPost.label",
                    listRoute: route,
                  }}
                >
                  <SkeletonPostsTable />
                </StandardEmptyNotice>
              </Center>
            )
        }
        {postsInitialised !== "none" && posts.length > 0 && (
          <PostsTable rows={posts} loading={postsInitialised !== "all"} />
        )}
      </PrimaryPagePanel>
      <Outlet />
    </>
  );
};

const CreatorPage: React.ComponentType<{}> = () => (
  <PostFiltersContextProvider initial={useLocationPostFilters()}>
    <Inner />
  </PostFiltersContextProvider>
);

export default CreatorPage;
