// @flow
import * as React from "react";
import noop from "lodash/noop";
import { truncateTime } from "../lib/time.lib";
import type { ModelID } from "../types";
import type { PostStatus } from "../models/post.model";
import { Moment } from "../types";
import type { Provider } from "../reactTypes";

type PostFiltersState = {
  accountIds: ModelID[],
  postIds: ModelID[],
  statuses: PostStatus[],
  search: string,
  fromDate: ?Moment,
  toDate: ?Moment,
};

export type PostFiltersInitial = Partial<PostFiltersState>;

export type PostFiltersContextType = {
  onSetAccountIds: (ModelID[]) => any,
  onSetPostIds: (ModelID[]) => any,
  onSetStatuses: (PostStatus[]) => any,
  onSetSearch: (string) => any,
  onSetFromDate: (?Moment) => any,
  onSetToDate: (?Moment) => any,
  ...PostFiltersState,
  activeFilters: number,
};

const EMPTY_STATE: PostFiltersState = {
  accountIds: [],
  postIds: [],
  statuses: [],
  search: "",
  fromDate: null,
  toDate: null,
};

const PostFiltersContext: React.Context<PostFiltersContextType> =
  React.createContext({
    onSetAccountIds: noop,
    onSetPostIds: noop,
    onSetStatuses: noop,
    onSetSearch: noop,
    onSetFromDate: noop,
    onSetToDate: noop,
    ...EMPTY_STATE,
    activeFilters: 0,
  });

/**
 * Provides a context to filter posts.
 * @constructor
 */
export const PostFiltersContextProvider: Provider<
  PostFiltersContextType,
  { initial?: PostFiltersInitial, override?: PostFiltersInitial }
> = ({ children, initial, override }) => {
  const [state, onSetState] = React.useState<PostFiltersState>({
    ...EMPTY_STATE,
    ...initial,
  });
  const set = React.useCallback(
    <K: string>(field: K, value: PostFiltersState[K]): void =>
      onSetState((prevState) => ({ ...prevState, [field]: value })),
    []
  );

  // contrary to initial, override updates state after mount.
  React.useEffect(() => {
    onSetState((prev) => ({ ...prev, ...override }));
  }, [override]);

  return (
    <PostFiltersContext.Provider
      value={{
        onSetAccountIds: React.useCallback(
          (val) => set("accountIds", val),
          [set]
        ),
        onSetPostIds: React.useCallback((val) => set("postIds", val), [set]),
        onSetStatuses: React.useCallback((val) => set("statuses", val), [set]),
        onSetSearch: React.useCallback((val) => set("search", val), [set]),
        onSetFromDate: React.useCallback(
          (val) => set("fromDate", val ? truncateTime(val) : val),
          [set]
        ),
        onSetToDate: React.useCallback(
          (val) => set("toDate", val ? truncateTime(val) : val),
          [set]
        ),
        ...state,
        activeFilters:
          Number(!!state.fromDate) +
          Number(!!state.toDate) +
          Number(state.accountIds.length > 0) +
          Number(state.statuses.length > 0) +
          Number(state.postIds.length > 0),
      }}
    >
      {children}
    </PostFiltersContext.Provider>
  );
};

export default PostFiltersContext;
