// @flow
import * as React from "react";
import type { MediaItemSrc } from "./MediaItem";
import type {
  AttachmentTypes,
  AttachmentUploadProgress,
} from "../../../../models/attachment.model";
import type { Props as FilesValidatorProps } from "../../../../hooks/useFilesValidator";
import identity from "lodash/identity";
import { getDefaultAccept } from "../../../../lib/files.lib";
import { splice } from "../../../../lib/lodashex.lib";
import CropImageDialog from "../../feedback/CropImageDialog";
import type { Props as FileDropContainerProps } from "../FileDropContainer";
import type { Milliseconds } from "../../../../types";

export type MediaItem = {
  src: MediaItemSrc,
  /**
   * For videos, optional offset to use for the cover of the media.
   */
  videoOffset?: ?Milliseconds,
  uploadProgress?: AttachmentUploadProgress,
  /**
   * The tag is not used by the media input and is meant for users
   * to carry data around or identify the item.
   */
  tag?: any,
};

export type Props = {
  ...FilesValidatorProps,
  items: MediaItem[],
  nocrop?: boolean,
  disabled?: boolean,
  onChange: (MediaItem[]) => any,
  onMediaAdded?: (MediaItem) => MediaItem,
  enableGooglePicker?: boolean,
  type?: AttachmentTypes,
  error?: boolean,
  helperText?: React.Node,
};

type ReturnValue = {
  cropImageDialog: React.Node,
  onSelect: (newItems: $ReadOnlyArray<MediaItemSrc>) => any,
  onSetEditedItem: (MediaItem) => any,
  fileDropContainerProps: Omit<FileDropContainerProps, "children">,
  validatorProps: FilesValidatorProps,
};

const addItems =
  (items: MediaItem[], onMediaAdded: (MediaItem) => MediaItem) =>
  (newItems: $ReadOnlyArray<MediaItemSrc>): MediaItem[] =>
    [...items, ...newItems.map((src) => ({ src })).map(onMediaAdded)];

const useHeadlessMediaInput = ({
  items,
  nocrop,
  disabled,
  onChange,
  enableGooglePicker,
  type,
  accept,
  error,
  helperText,
  onMediaAdded = identity,
  ...validatorProps
}: Props): ReturnValue => {
  accept = accept ?? getDefaultAccept(type);
  validatorProps = { accept, ...validatorProps };
  const [editedItem, setEditedItem] = React.useState<?MediaItem>();
  const dropEditedItem = React.useCallback(() => setEditedItem(null), []);

  const handleImageCropped = (src: MediaItemSrc) => {
    dropEditedItem();
    onChange(
      splice(items, items.indexOf(editedItem), 1, [onMediaAdded({ src })])
    );
  };
  const handleSelect = (newItems: $ReadOnlyArray<MediaItemSrc>) =>
    onChange(addItems(items, onMediaAdded)(newItems));

  const cropImageDialog = !nocrop && editedItem && (
    <CropImageDialog
      open
      src={editedItem.src}
      onClose={dropEditedItem}
      onSubmit={handleImageCropped}
    />
  );

  return {
    cropImageDialog,
    onSelect: handleSelect,
    onSetEditedItem: setEditedItem,
    fileDropContainerProps: {
      disabled,
      onSelect: handleSelect,
      ...validatorProps,
    },
    validatorProps,
  };
};

export default useHeadlessMediaInput;
