// @flow
import * as React from "react";
import type { TFunction } from "../../../hooks/useTranslate";
import useTranslate from "../../../hooks/useTranslate";
import EngagementHubContext from "../../../contexts/engagementHub";
import { ColumnStack } from "../../lib/layout/stacks";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  Paper,
} from "@mui/material";
import ChannelIcon from "../../lib/display/icons/ChannelIcon";
import { useAccountEngagementUnreadCount } from "../../../store/selectors";
import {
  CircularSkeletonListItem,
  NavigationListItem,
} from "../../lib/display/listItems";
import type { Callback } from "../../../types";
import type { BrowserRoute } from "../../../config/routes.config";
import { getRoutePath } from "../../../config/routes.config";
import type { BobcaatAccount } from "../../../models/account.model";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useIsMobile from "../../../hooks/useIsMobile";
import Autocomplete from "../../lib/inputs/Autocomplete";
import { EngagementRouteAdornment } from "../../display/Adornments";

export type EngagementRoute = {
  feature: "comments" | "messages",
  account: BobcaatAccount,
  route: BrowserRoute,
  key: string,
};

type Props = {
  engagementRoutes: EngagementRoute[],
  loading?: boolean,
  selectedRoute?: ?EngagementRoute,
};

type AccountItemProps = {
  engagementRoute: EngagementRoute,
  selected?: boolean,
  onSelect: Callback,
  t: TFunction,
};

const AccountItem: React.ComponentType<AccountItemProps> = ({
  engagementRoute,
  onSelect,
  selected,
  t,
}) => {
  const { account, route, feature } = engagementRoute;
  const unread = useAccountEngagementUnreadCount(account.id, feature);
  const prefix = unread ? `(${unread}) ` : "";
  return (
    <NavigationListItem
      onClick={onSelect}
      selected={selected}
      icon={<ChannelIcon channel={account.channel} color="channel" />}
      primary={prefix + account.entity_name}
      secondary={t(route.label)}
      important={!!unread}
    />
  );
};

const Root = styled(Paper)(({ theme }) => ({
  minWidth: 280,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    borderRadius: 0,
  },
}));

const Options = styled(FormGroup)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const SmallUpAccounts: React.ComponentType<Props> = ({
  engagementRoutes,
  loading,
  selectedRoute,
}) => {
  const navigate = useNavigate();
  const t = useTranslate();
  return (
    <Root>
      <List>
        {loading && (
          <>
            <CircularSkeletonListItem />
            <CircularSkeletonListItem />
            <CircularSkeletonListItem />
          </>
        )}
        {!loading &&
          engagementRoutes.map((engagementRoute) => (
            <AccountItem
              key={engagementRoute.key}
              selected={selectedRoute === engagementRoute}
              engagementRoute={engagementRoute}
              onSelect={() =>
                navigate(
                  getRoutePath(engagementRoute.route, {
                    portfolioId: engagementRoute.account.portfolio_id,
                    accountId: engagementRoute.account.id,
                  })
                )
              }
              t={t}
            />
          ))}
      </List>
    </Root>
  );
};

const AccountsAutocomplete = styled(Autocomplete)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  alignSelf: "stretch",
}));

const XsAccounts: React.ComponentType<Props> = ({
  engagementRoutes,
  loading,
  selectedRoute,
}) => {
  const navigate = useNavigate();
  const t = useTranslate();
  return (
    <AccountsAutocomplete
      value={selectedRoute}
      onChange={(e, route) =>
        navigate(
          getRoutePath(route.route, {
            portfolioId: route.account.portfolio_id,
            accountId: route.account.id,
          })
        )
      }
      optionPrimaryLabel="account.entity_name"
      optionSecondaryLabel="route.label"
      TextFieldProps={{ label: t("global.account") }}
      options={engagementRoutes}
      disableClearable
      loading={loading}
      adornOptions
      AdornmentComponent={EngagementRouteAdornment}
    />
  );
};

const EngagementHubAccounts: React.ComponentType<Props> = (props) => {
  const t = useTranslate();
  const {
    unreadItemsOnly,
    onSetUnreadItemsOnly,
    commentedPostsOnly,
    onSetCommentedPostsOnly,
  } = React.useContext(EngagementHubContext);
  const isMobile = useIsMobile();
  return (
    <ColumnStack alignItems="flex-start">
      {isMobile ? <XsAccounts {...props} /> : <SmallUpAccounts {...props} />}
      <Options>
        <FormControlLabel
          control={<Checkbox checked={unreadItemsOnly} />}
          label={t("EngagementHubSection.unreadItemsOnlyButton")}
          onChange={(e, checked) => onSetUnreadItemsOnly(checked)}
        />
        <FormControlLabel
          control={<Checkbox checked={commentedPostsOnly} />}
          label={t("EngagementHubSection.commentedPostsOnlyButton")}
          onChange={(e, checked) => onSetCommentedPostsOnly(checked)}
        />
      </Options>
    </ColumnStack>
  );
};

export default EngagementHubAccounts;
