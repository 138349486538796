// @flow
import * as React from "react";
import StatusChip from "../lib/display/StatusChip";
import type { PostApprovalStatuses } from "../../models/postApproval.model";
import { propMap } from "../lib/utils/props";
import type { Severity } from "../../models/alerts.model";
import useTranslate from "../../hooks/useTranslate";
import { ApprovalRounded } from "@mui/icons-material";
import type { Callback } from "../../types";

type Props = {
  status: PostApprovalStatuses,
  iconOnly?: boolean,
  onClick?: Callback,
};

const _STATUS_TO_SEVERITY: Record<PostApprovalStatuses, Severity> = {
  pending: "info",
  edits_required: "warning",
  approved: "success",
  rejected: "error",
};

export const getSeverity: (PostApprovalStatuses) => Severity = propMap(
  _STATUS_TO_SEVERITY,
  "pending"
);

const PostApprovalStatusChip: React.ComponentType<Props> = React.memo(
  ({ status, onClick, iconOnly }) => {
    const t = useTranslate();
    return (
      <StatusChip
        severity={getSeverity(status)}
        icon={ApprovalRounded}
        onClick={onClick}
      >
        {!iconOnly && t(`refer.postApprovalStatus.${status}`)}
      </StatusChip>
    );
  }
);

export default PostApprovalStatusChip;
