import type { TFunction } from "../../hooks/useTranslate";
import useApprovalRequestCounts from "../../hooks/useApprovalRequestCounts";
import keys from "lodash/keys";
import omit from "lodash/omit";
import type { ApprovalRequest } from "../../models/approvalRequest.model";
import useTranslate from "../../hooks/useTranslate";
import { useTheme } from "@mui/material";
import React from "react";
import { Pie } from "@nivo/pie";
import { getSeverity } from "./PostApprovalStatusChip";

const makePieData = (
  t: TFunction,
  src: ReturnType<typeof useApprovalRequestCounts>
) =>
  keys(omit(src, "count")).map((status) => ({
    id: status,
    label: t(`refer.postApprovalStatus.${status}`),
    value: src[status],
    color: "green",
  }));

const ApprovalRequestStatusPie = ({
  request,
  diameter = 32,
}: {
  request: ApprovalRequest,
  diameter?: number,
}) => {
  const t = useTranslate();
  const theme = useTheme();
  const countsByStatus = useApprovalRequestCounts(request);
  const data = React.useMemo(
    () => makePieData(t, countsByStatus),
    [t, countsByStatus]
  );
  return (
    <Pie
      colors={(datum) => theme.palette[getSeverity(datum.id)].light}
      height={diameter}
      width={diameter}
      data={data}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      innerRadius={0.3}
      padAngle={0}
      cornerRadius={0}
      activeOuterRadiusOffset={0}
      borderWidth={0}
    />
  );
};

export default ApprovalRequestStatusPie;
