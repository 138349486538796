// @flow
import * as React from "react";
import { Caption } from "../lib/display/Text";
import type { Timezone } from "../../models/timezone.model";
import { getCity } from "../../models/timezone.model";

type Props = {
  timezone: ?Timezone,
  short?: boolean,
};

const TimezoneCaption: React.ComponentType<Props> = React.memo(
  ({ timezone, short }) =>
    timezone && (
      <Caption display="block" textAlign="center">
        {short ? getCity(timezone) : timezone.name}
      </Caption>
    )
);

export default TimezoneCaption;
