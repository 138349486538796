import { useLocation } from "react-router-dom";
import React from "react";
import { getRouteForLocation } from "../config/routes.config";

const useCurrentRoute = () => {
  const location = useLocation();
  return React.useMemo(
    () => getRouteForLocation(location.pathname),
    [location.pathname]
  );
};

export default useCurrentRoute;
