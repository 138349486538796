// @flow
import * as React from "react";
import type { Attachment, LocalAttachment } from "../models/attachment.model";
import { isAttachmentVideo } from "../models/attachment.model";
import type {
  IdentifiedMutablePost,
  MutablePost,
  Post,
} from "../models/post.model";
import type { AsyncAction, AsyncVoidAction } from "./types";
import * as alerts from "../models/alerts.model";
import flow from "lodash/flow";
import noop from "lodash/noop";
import pick from "lodash/pick";
import { API as backend } from "./backend.service";
import type { StoreActions } from "../store/store";
import { uploadFileAttachment } from "./attachment.service";
import type {
  AddPostParams,
  EditPostParams,
  MediaParams,
} from "./backend.service/post";
import instagram from "../models/channels/instagram.model";
import type { ModelID, Slug } from "../types";
import { AxiosError } from "axios";
import { paginateRequest } from "./utils.service";
import { downloadFile } from "../lib/ajax.lib";
import { makeLocalAttachment } from "../models/post.model";
import { uploadFile } from "./upload.service";
import { getFileType } from "../lib/files.lib";
import type { BulkReschedulePostsParams } from "./backend.service/post";
import { periodicityToString } from "../models/periodicity.model";

type ServicePostParamsExtras = {
  /**
   * The channel is needed to show the right error / success message.
   */
  channel: Slug,
};

type ServiceAddPostParams = { ...AddPostParams, ...ServicePostParamsExtras };
type ServiceEditPostParams = { ...EditPostParams, ...ServicePostParamsExtras };
export type AddPostInput = { ...MutablePost, ...ServicePostParamsExtras, ... };
export type EditPostInput = {
  ...IdentifiedMutablePost,
  ...ServicePostParamsExtras,
  ...
};

const alertPostSuccess =
  (actions: StoreActions): ((Post) => Post) =>
  (post) => {
    actions.global.openModalAlert(
      alerts.modalLocalSuccess({
        title: `store.post.${(post.status: string)}.title`,
        message: `store.post.${(post.status: string)}.message`,
      })
    );
    return post;
  };

export const onAttachmentUploadProgress: AsyncVoidAction<
  ProgressEvent,
  LocalAttachment
> = (actions) => (prgrss, attachmentOrFile) => {
  actions.post.uploads.insert({
    key: attachmentOrFile.id,
    current: prgrss.loaded,
    total: prgrss.total,
  });
};

type UploadPostOrSelfReplyAttachmentsFunc = (
  Attachment[]
) => Promise<MediaParams[]>;

/**
 * Create a local attachment out of the provided file.
 * The file is uploaded straightaway to the server.
 */
export const createUploadLocalAttachment = (file: File): LocalAttachment =>
  makeLocalAttachment({
    type: getFileType(file),
    file,
    uploadPromise: uploadFile(file, true),
  });

/**
 * Create a local attachment out of the provided file.
 * The file is uploaded straightaway to the server.
 */
export const createTrackUploadLocalAttachment = (
  file: File,
  actions: StoreActions
): LocalAttachment => {
  // create the attachment first to generate the idea and get a reference.
  const attachment = makeLocalAttachment({ type: getFileType(file), file });
  const onProgress = onAttachmentUploadProgress(actions);
  return {
    ...attachment,
    uploadPromise: uploadFile(file, true, (progress) =>
      onProgress(progress, attachment)
    ),
  };
};

const uploadPostOrSelfReplyAttachments =
  (actions: StoreActions): UploadPostOrSelfReplyAttachmentsFunc =>
  async (attachments) => {
    const onUploadProgress = onAttachmentUploadProgress(actions);

    return await Promise.all(
      attachments.map((attachment: Attachment): Promise<MediaParams> => {
        if (attachment.file) {
          return uploadFileAttachment(attachment, false, onUploadProgress).then(
            (refAttachment) =>
              pick(refAttachment, ["type", "data", "is_ref", "user_tags"])
          );
        } else {
          return Promise.resolve({
            type: attachment.type,
            data: attachment.url,
            user_tags: attachment?.user_tags ?? [],
            is_ref: false,
          });
        }
      })
    );
  };

export const makeAddPostParamsFromVizDrop = async (
  actions: StoreActions,
  accountId: ModelID,
  file: File
): Promise<ServiceAddPostParams> => ({
  // _CorePostParams
  content: "",
  status: "draft",
  account_id: accountId,
  channel: "instagram",
  time: null,

  // PostAttachmentParams
  media: await uploadPostOrSelfReplyAttachments(actions)([
    createUploadLocalAttachment(file),
  ]),

  // Threads
  self_replies: [],

  // PostParams
  title: null,
  content_type: "post",

  // Multi channel target
  secondary_account_ids: [],
  via: "VizPlanner",
});

export const makeAddPostParamsFromPost = async (
  post: AddPostInput,
  actions: StoreActions
): Promise<ServiceAddPostParams> => ({
  // _CorePostParams
  content: post.content,
  status: post.status,
  account_id: post.account_id,
  channel: post.channel,
  time: post.schedule_time?.unix(),

  // PostAttachmentParams
  media: await uploadPostOrSelfReplyAttachments(actions)(post.attachments),

  // Post thumbnail params
  thumbnail_image: post.thumbnail_url,
  thumbnail_title: post.thumbnail_title,
  thumbnail_video_offset: post.thumbnail_video_offset,

  // Threads
  self_replies: await Promise.all(
    post.self_replies.map(async (selfReply) => ({
      content: selfReply.content,
      media: await uploadPostOrSelfReplyAttachments(actions)(
        selfReply.attachments
      ),
    }))
  ),

  // PostParams
  title: post.title,
  timezone_id: post.local_schedule_tz_id,

  first_comment: post.first_comment,
  extras: post.extras,
  link: post.link,
  poll: post.poll,

  // Content type and subchannel, to manage target and surface
  subchannel_id: post.subchannel_id,
  content_type: post.content_type,
  disable_autopublish: post.disable_autopublish,

  // Multi channel target
  secondary_account_ids: post.secondary_account_ids,
  via: "Creator",
  periodicity: periodicityToString(post.periodicity),
});

export const makeEditPostParamsFromPost = async (
  post: EditPostInput,
  actions: StoreActions
): Promise<ServiceEditPostParams> => ({
  ...(await makeAddPostParamsFromPost(post, actions)),
  id: post.id,
});

const onAddEditPostFail =
  (actions: StoreActions, msgId: string, isInstagramVideoPost?: boolean) =>
  (e: AxiosError<{ message: string }>) => {
    let message = "";
    try {
      message = e.response?.data.message ?? "";
    } catch (e) {}
    actions.global.openModalAlert(
      alerts.modalLocalError({
        title: `store.post.${msgId}.title`,
        message: `store.post.${msgId}.message${
          isInstagramVideoPost ? "InstagramVideo" : ""
        }`,
        messageData: { error: message },
        messageComponents: {
          alignedDiv: <div style={{ textAlign: "left" }} />,
        },
      })
    );
  };

export const addPost: AsyncVoidAction<ServiceAddPostParams, ?() => any> =
  (actions) => (params, onSuccess) => {
    actions.post.lock();
    const isInstagramVideoPost = Boolean(
      params.media.filter(isAttachmentVideo).length &&
        params.channel === instagram.slug
    );
    backend.post
      .addPost(params)
      .then(alertPostSuccess(actions))
      // Prepend. It should be on top of the table.
      .then(actions.post.posts.prepend)
      .then(onSuccess ?? noop)
      .then(actions.post.uploads.clear)
      .catch(onAddEditPostFail(actions, "failedToCreate", isInstagramVideoPost))
      .finally(actions.post.unlock);
  };

export const editPost: AsyncVoidAction<ServiceEditPostParams, ?() => any> =
  (actions) => (params, onSuccess) => {
    actions.post.lock();
    const isInstagramVideoPost = Boolean(
      params.media.filter(isAttachmentVideo).length &&
        params.channel === instagram.slug
    );
    backend.post
      .editPost(params)
      .then(alertPostSuccess(actions))
      .then(actions.post.posts.replace)
      .then(onSuccess ?? noop)
      .then(actions.post.uploads.clear)
      .catch(onAddEditPostFail(actions, "failedToEdit", isInstagramVideoPost))
      .finally(actions.post.unlock);
  };

export const reloadPortfolioPosts: AsyncAction<
  Promise<void>,
  { portfolioId: ModelID }
> =
  (actions) =>
  ({ portfolioId }) => {
    actions.post.removeInitialised();
    return paginateRequest(
      backend.post.getPortfolioPosts,
      flow(actions.post.posts.replaceAll, actions.post.setSomeInitialised),
      actions.post.posts.extendAndSort,
      { portfolioId },
      20
    ).then(actions.post.setAllInitialised);
  };

export const deletePost: AsyncAction<Promise<void>, ModelID> =
  (actions) => (postId) =>
    backend.post.deletePost(postId).then((post) => {
      actions.post.posts.replace(post);
      actions.global.openModalAlert(
        alerts.modalLocalSuccess({
          title: "store.post.binned.title",
          message: "store.post.binned.message",
        })
      );
    });

export const deletePostPermanently: AsyncVoidAction<ModelID> =
  (actions) => (postId) =>
    backend.post.deletePost(postId, true).then(() => {
      actions.post.posts.remove(postId);
      actions.global.openModalAlert(
        alerts.modalLocalSuccess({
          title: "store.post.deleted.title",
          message: "store.post.deleted.message",
        })
      );
    });

export const cancelDeletePost: AsyncVoidAction<ModelID> =
  (actions) => (postId) =>
    backend.post.cancelDeletePost(postId).then((post) => {
      actions.post.posts.replace(post);
      actions.global.openModalAlert(
        alerts.modalLocalSuccess({
          title: "store.post.recovered.title",
          message: "store.post.recovered.message",
        })
      );
    });

export const duplicatePost: AsyncVoidAction<ModelID> = (actions) => (postId) =>
  backend.post.duplicatePost(postId).then((post) => {
    actions.post.posts.append(post);
    actions.snacks.append(
      alerts.localSuccess({ message: "store.post.duplicated" })
    );
  });

export const markPostAsPublished: AsyncVoidAction<ModelID> =
  (actions) => (postId) =>
    backend.post.markPostAsPublished(postId).then(actions.post.posts.replace);

export const exportScreenshot: AsyncAction<Promise<void>, Post[]> =
  (actions) => (posts) => {
    actions.post.lock();
    return downloadFile(
      `post/viz_grid/export?post_ids=${posts.map((p) => p.id).join(",")}`,
      "viz-planner-preview.jpg"
    ).finally(actions.post.unlock);
  };

export const reschedulePosts: AsyncVoidAction<BulkReschedulePostsParams> =
  (actions) => (params) =>
    backend.post.reschedulePosts(params).then((posts) => {
      posts.map(actions.post.posts.replace);
      actions.snacks.append(
        alerts.localSuccess({ message: "store.post.rescheduled" })
      );
    });
