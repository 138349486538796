// @flow
import * as React from "react";
import type {
  BooleanFormFieldDef,
  FormField,
  OnChangeField,
} from "../../../../hooks/useForm";
import SwitchField from "../SwitchField";
import type { Props as SwitchFieldProps } from "../SwitchField";

type Props = {
  ...SwitchFieldProps,
  formField: FormField<BooleanFormFieldDef>,
  onChange: OnChangeField<BooleanFormFieldDef>,
};

const FormSwitchField: React.ComponentType<Props> = ({
  formField,
  onChange,
  ...props
}) => {
  return (
    <SwitchField
      checked={formField.value}
      onChange={(e, checked) => onChange(checked)}
      name={formField.name}
      {...props}
    />
  );
};

export default FormSwitchField;
