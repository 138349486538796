// @flow
import * as React from "react";
import "./assets/styles/raleway.css";
import "./assets/styles/animations.css";
import type { BrowserRoute } from "./config/routes.config";
import routes, {
  getRouteForLocation,
  isRouteEnabled,
} from "./config/routes.config";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CurrencyProvider } from "./contexts/currency";
import SnacksManager from "./components/lib/feedback/SnacksManager";
import { LocalizationProvider as MuiPickersLocalizationProvider } from "@mui/x-date-pickers";
import ThemeProvider from "./contexts/theme";
import TrackingProvider, { TrackingContext } from "./contexts/tracking";
import { CssBaseline } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import compact from "lodash/compact";
import values from "lodash/values";
import type { User } from "./models/user.model";
import { useCurrentUser } from "./store/selectors";
import { byTruthyProp } from "./models/base.model";

const makeRoute =
  (user: ?User): ((BrowserRoute) => ?typeof Route) =>
  (route: BrowserRoute) => {
    return !isRouteEnabled(user)(route) ? null : (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        element={<route.ElementType />}
      >
        {(values(route.nested) ?? []).map(makeRoute(user))}
      </Route>
    );
  };

const makeRoutes = (routes: BrowserRoute[], user: ?User): typeof Route[] => {
  const defaultRoute = routes.find(byTruthyProp<BrowserRoute>("default"));
  return compact([
    ...routes.map(makeRoute(user)),
    // If there is a default route, Make sure to navigate to it
    // if there is no match.
    defaultRoute && (
      <Route
        path="*"
        key="*"
        element={<Navigate to={defaultRoute.path} replace />}
      />
    ),
  ]);
};

const RouteTable = () => {
  const currentUser = useCurrentUser();
  const { onNavigate } = React.useContext(TrackingContext);
  const location = useLocation();

  React.useEffect(() => {
    onNavigate(getRouteForLocation(location.pathname)?.navigationEvent);
  }, [location.pathname, onNavigate]);

  return <Routes>{makeRoutes(values(routes), currentUser)}</Routes>;
};

const App: React.ComponentType<{}> = () => {
  return (
    <>
      <ThemeProvider>
        <CssBaseline />
        <MuiPickersLocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={localStorage.getItem("locale")}
        >
          <SnacksManager />
          <CurrencyProvider>
            <BrowserRouter>
              <TrackingProvider>
                <RouteTable />
              </TrackingProvider>
            </BrowserRouter>
          </CurrencyProvider>
        </MuiPickersLocalizationProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
