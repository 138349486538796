// @flow
import * as React from "react";
import DurationPicker from "./DurationPicker";
import type { Integer } from "../../../../types";
import { divmod } from "../../../../lib/lodashex.lib";
import interval from "../../../../lib/interval.lib";

type Props = {
  onChange: (seconds: Integer) => any,
  duration: Integer,
  showDays?: boolean,
  showHours?: boolean,
  showMinutes?: boolean,
  showSeconds?: boolean,
};

const secondsToComponents = (duration: Integer) => {
  const [days, lessThanADay] = divmod(duration, interval.day.as.seconds);
  const [hours, lessThanAnHour] = divmod(
    lessThanADay,
    interval.hour.as.seconds
  );
  const [minutes, seconds] = divmod(lessThanAnHour, interval.minute.as.seconds);
  return { days, hours, minutes, seconds };
};

const componentsToSeconds = (
  days: Integer,
  hours: Integer,
  minutes: Integer,
  seconds: Integer
) =>
  days * interval.day.as.seconds +
  hours * interval.hour.as.seconds +
  minutes * interval.minute.as.seconds +
  seconds;

const DurationPickerContainer: React.ComponentType<Props> = ({
  onChange,
  duration = interval.minute.as.seconds,
  showDays = true,
  showHours = true,
  showMinutes = true,
  showSeconds = false,
}) => {
  const { days, hours, minutes, seconds } = React.useMemo(
    () => secondsToComponents(duration),
    [duration]
  );

  return (
    <DurationPicker
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      onChangeDays={
        showDays
          ? (days) =>
              onChange(componentsToSeconds(days, hours, minutes, seconds))
          : undefined
      }
      onChangeHours={
        showHours
          ? (hours) =>
              onChange(componentsToSeconds(days, hours, minutes, seconds))
          : undefined
      }
      onChangeMinutes={
        showMinutes
          ? (minutes) =>
              onChange(componentsToSeconds(days, hours, minutes, seconds))
          : undefined
      }
      onChangeSeconds={
        showSeconds
          ? (seconds) =>
              onChange(componentsToSeconds(days, hours, minutes, seconds))
          : undefined
      }
    />
  );
};

export default DurationPickerContainer;
