// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Collapse, Paper } from "@mui/material";
import { Body1 } from "../lib/display/Text";
import type { I18nKey } from "../../types";
import useTranslate from "../../hooks/useTranslate";
import { InlineRowStack } from "../lib/layout/stacks";
import type { Children, SyntheticEventListener } from "../../reactTypes";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useSwitch from "../../hooks/useSwitch";

const PostCreatorSectionRoot: typeof Paper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: "hidden",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    borderRadius: 0,
  },
}));

const SectionHeadRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== "cursor",
})(({ cursor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor,
}));

type SectionHeadProps = {
  title: React.Node,
  children?: Children,
  onClick?: SyntheticEventListener,
};

const PostCreatorSectionHead: React.ComponentType<SectionHeadProps> = ({
  title,
  children,
  onClick,
}) => (
  <SectionHeadRoot onClick={onClick} cursor={onClick ? "pointer" : undefined}>
    <Body1 fontWeight="bold">{title}</Body1>
    <InlineRowStack>{children}</InlineRowStack>
  </SectionHeadRoot>
);

export type Props = {
  title?: I18nKey,
  titleData?: Object,
  children?: Children,
  slotProps?: {
    head?: Partial<Omit<SectionHeadProps, "title">>,
  },
};

export type CollapsibleProps = {
  ...Props,
  defaultCollapsed?: boolean,
};

const PostCreatorSection: React.ComponentType<Props> = React.forwardRef(
  ({ title, titleData, children, slotProps = {} }, ref) => {
    const t = useTranslate();
    return (
      <PostCreatorSectionRoot ref={ref}>
        <PostCreatorSectionHead
          title={t(title, titleData)}
          {...slotProps.head}
        />
        {children}
      </PostCreatorSectionRoot>
    );
  }
);

export const PostCreatorCollapsibleSection: React.ComponentType<CollapsibleProps> =
  React.forwardRef(
    ({ defaultCollapsed = false, children, slotProps, ...props }, ref) => {
      const [opened, open, close] = useSwitch(!defaultCollapsed);
      return (
        <PostCreatorSection
          ref={ref}
          slotProps={{
            ...slotProps,
            head: {
              ...slotProps?.head,
              children: (
                <>
                  {slotProps?.head?.children}
                  {opened ? <ExpandLess /> : <ExpandMore />}
                </>
              ),
              onClick: opened ? close : open,
            },
          }}
          {...props}
        >
          <Collapse in={opened}>{children}</Collapse>
        </PostCreatorSection>
      );
    }
  );

export default PostCreatorSection;
