import * as React from "react";
import PostBuilderContext from "../../contexts/postBuilder";
import { isAttachmentImage } from "../../models/attachment.model";

const useImageAttachments = () => {
  const {
    post: { attachments },
  } = React.useContext(PostBuilderContext);
  return React.useMemo(
    () => attachments.filter(isAttachmentImage),
    [attachments]
  );
};

export default useImageAttachments;
