// @flow
import * as React from "react";
import DateRangeSelect from "./DateRangeSelect";
import type { Props as DateRangeSelectProps } from "./DateRangeSelect";
import moment from "moment";
import type { DateRangePeriod } from "./periods";
import * as periods from "./periods";
import { yesterday } from "./periods";
import DateRangeInputs from "./DateRangeInputs";
import type { Props as DateRangeInputsProps } from "./DateRangeInputs";
import type { I18nKey, Moment } from "../../../types";
import type { StoreActions } from "../../../store/store";
import { useStoreActions } from "../../../store/store";
import * as snacks from "../../../models/alerts.model";
import { Collapse, Portal, Stack } from "@mui/material";

type Props = {
  fromDate: Moment,
  toDate: Moment,
  onChangeFromDate: (Moment) => any,
  onChangeToDate: (Moment) => any,
  slotProps?: {
    dateRangeSelect?: Partial<DateRangeSelectProps>,
    dateRangeInputsStack?: Object,
    dateRangeInputs?: DateRangeInputsProps["slotProps"],
  },
  dateRangeInputsContainer?: HTMLElement | (() => ?HTMLElement),
};

const checkDateRange = (
  date: Moment,
  minDate: ?Moment,
  maxDate: ?Moment,
  minError: I18nKey,
  maxError: I18nKey,
  actions: StoreActions
) => {
  if (minDate && date.isBefore(minDate)) {
    actions.snacks.append(snacks.localWarning({ message: minError }));
    return moment(minDate);
  }
  if (maxDate && date.isAfter(maxDate)) {
    actions.snacks.append(snacks.localWarning({ message: maxError }));
    return moment(maxDate);
  }
  return date;
};

const DateRangeSelectContainer: React.ComponentType<Props> = ({
  fromDate,
  toDate,
  onChangeFromDate,
  onChangeToDate,
  slotProps,
  dateRangeInputsContainer,
}) => {
  const actions = useStoreActions();
  const [period, setPeriod] = React.useState<DateRangePeriod>("w");
  const minFromDate = React.useMemo(() => moment().add(-30, "d"), []);
  const maxFromDate = React.useMemo(() => moment().add(-1, "d"), []);
  const minToDate = React.useMemo(() => moment().add(-29, "d"), []);
  const maxToDate = React.useMemo(() => moment().add(-1, "d"), []);

  const handleChangePeriod = (unit: DateRangePeriod) => {
    if (unit !== periods.CUSTOM.value) {
      onChangeFromDate(moment().startOf(periods.DAY.value).add(-1, unit));
      onChangeToDate(yesterday());
    }
    setPeriod(unit);
  };

  const handleSetFromDate = (newFromDate: Moment) => {
    newFromDate = checkDateRange(
      newFromDate,
      minFromDate,
      maxFromDate,
      "DateRangeSelect.minFromDateExceeded",
      "DateRangeSelect.maxFromDateExceeded",
      actions
    );
    onChangeFromDate(newFromDate);
    if (toDate.isBefore(newFromDate)) {
      onChangeToDate(newFromDate.startOf(periods.DAY.value));
    }
    setPeriod(periods.CUSTOM.value);
  };

  const handleSetToDate = (newToDate: Moment) => {
    newToDate = checkDateRange(
      newToDate,
      minToDate,
      maxToDate,
      "DateRangeSelect.minToDateExceeded",
      "DateRangeSelect.maxToDateExceeded",
      actions
    );
    onChangeToDate(newToDate);
    if (newToDate.isBefore(fromDate)) {
      onChangeFromDate(newToDate.startOf(periods.DAY.value));
    }
    setPeriod(periods.CUSTOM.value);
  };

  return (
    <>
      <DateRangeSelect
        period={period}
        fromDate={fromDate}
        toDate={toDate}
        onSetPeriod={handleChangePeriod}
        {...slotProps?.dateRangeSelect}
      />
      <Portal
        container={dateRangeInputsContainer}
        disablePortal={!dateRangeInputsContainer}
      >
        <Collapse in={period === periods.CUSTOM.value}>
          <Stack {...slotProps?.dateRangeInputsStack}>
            <DateRangeInputs
              slotProps={slotProps?.dateRangeInputs}
              fromDate={fromDate}
              toDate={toDate}
              onSetFromDate={handleSetFromDate}
              onSetToDate={handleSetToDate}
              minFromDate={minFromDate}
              maxFromDate={maxFromDate}
              minToDate={minToDate}
              maxToDate={maxToDate}
            />
          </Stack>
        </Collapse>
      </Portal>
    </>
  );
};

export default DateRangeSelectContainer;
