// @flow
import * as React from "react";
import numeral from "numeral";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import type { TextProps } from "./Text";

type Display = "compact" | "absolute" | "percent";
type Sign = "positive" | "negative" | "neutral";

type Props = {
  value: ?number,
  display: Display,
  colored?: boolean,
  ...Pick<TextProps, "variant" | "fontWeight">,
};

const SIGN_COLORS: Record<Sign, string> = {
  positive: "success",
  negative: "error",
  neutral: "inherit",
};

export const FORMATS = {
  compact: "0.[00]a", // 120; 113.14k
  absolute: "+0,0.[00]", // 12.14; 135; 1,420
  percent: "0%",
};

const getSign = (num: ?number): Sign => {
  if (typeof num === "undefined" || num === null || isNaN(num))
    return "neutral";
  return num < 0 ? "negative" : num > 0 ? "positive" : "neutral";
};

const formatValue = (val: ?number, display: Display) => {
  if (val === undefined || isNaN(val)) return "-";
  if (val === null) return "0";
  if (!isFinite(val)) return val > 0 ? "∞" : "-∞";
  if (val === 0 && display === "absolute") return "0";
  return numeral(val).format(FORMATS[display]);
};

const Root = styled(Typography, {
  shouldForwardProp: (prop) => !["colored", "value"].includes(prop),
})(({ colored, value, theme }) => {
  const color = SIGN_COLORS[getSign(value)];
  return colored ? { color: theme.palette[color]?.main ?? color } : {};
});

const Numeral: React.ComponentType<Props> = ({
  value,
  display = "absolute",
  variant = "body1",
  colored,
  ...props
}) => (
  <Root colored={colored} value={value} variant={variant} {...props}>
    {formatValue(value, display)}
  </Root>
);

export default Numeral;
