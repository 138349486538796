// @flow
import * as React from "react";
import type { Milliseconds } from "../types";

export type Timer = {
  start: (callback: () => void, delay: Milliseconds) => void,
  stop: () => void,
};

const useTimer = (): Timer => {
  const handleRef = React.useRef<?TimeoutID>(null);

  // Install cleanup.
  React.useEffect(
    () => () => {
      if (handleRef.current) {
        clearTimeout(handleRef.current);
      }
    },
    []
  );

  const start = React.useCallback(
    (callback: () => void, delay: number) => {
      handleRef.current = setTimeout(callback, delay);
    },
    [handleRef]
  );

  const stop = React.useCallback(() => {
    clearTimeout(handleRef.current);
    handleRef.current = null;
  }, [handleRef]);

  return React.useMemo(() => ({ start, stop }), [start, stop]);
};

export default useTimer;
