// @flow
// Opera 8.0+
const isSafari: boolean = /safari/.test(navigator.userAgent.toLowerCase()); // Safari on any device (but not Webview)

// Internet Explorer 6-11
const isIE: boolean = /*@cc_on!@*/ !!document.documentMode;

// Edge 20+
const isEdge: boolean = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome: boolean = navigator.userAgent.includes("Chrome");

const isIOSBase: boolean = !!(
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform))
);

const isIOS: boolean = isIOSBase && !isSafari; // IOS Webview

const isMobileSafari: boolean = isIOSBase && isSafari; // Safari on Phone / Tablet

const isAppleBrowser: boolean = isIOSBase || isSafari; // Any Safari + Webview

const isAndroid: boolean = typeof window.Android !== "undefined";

const isDevice: boolean = isIOS || isAndroid;

const isMobileBrowser: boolean = isIOSBase || isAndroid;

const isMobileApple: boolean = isIOSBase; // Safari on Phone / Tablet  + Webview

const isWindowsBrowser: boolean = navigator.userAgent.indexOf("Windows") >= 0;

export {
  isSafari,
  isIE,
  isEdge,
  isChrome,
  isIOS,
  isAndroid,
  isDevice,
  isMobileSafari,
  isMobileApple,
  isMobileBrowser,
  isWindowsBrowser,
  isAppleBrowser,
};
