// @flow
import ajax from "../../lib/ajax.lib";
import type { DataURI, Email, Integer, LocaleCode, ModelID } from "../../types";
import type { Palette } from "../../models/userPreferences.model";
import type {
  APIUser,
  APIChildUser,
  ChildUser,
  User,
} from "../../models/user.model";
import { fromAPIResult } from "../../models/user.model";
import type { APINoContentResult, APIResult } from "./types";
import { fileToImageDataURL } from "../../lib/images.lib";

export type UpdateUserParams = {
  first_name: string,
  last_name: string,
  avatar: ?DataURI,
  locale: ?LocaleCode,
  theme: ?string,
  palette: ?Palette,
  current_password: ?string,
  new_password: ?string,
  email: ?Email,
};

export type UpdateDashboardLayoutPreferencesParams = {
  hide_inactive_channels?: boolean,
  portfolios_order?: Integer[],
};

export type DeleteUserParams = {
  reason: string,
};

type AddUpdateChildUserParams = {
  first_name: string,
  last_name: string,
  password: string,
  email: Email,
  portfolios: ?(ModelID[]),
};

const prepareProfile = async (
  user: UpdateUserParams
): Promise<UpdateUserParams> => {
  if (!user.avatar || typeof user.avatar === "string") {
    // Nothing to do
    return user;
  }
  return {
    ...user,
    avatar: await fileToImageDataURL(user.avatar),
  };
};

export const getCurrentUser = (): Promise<User> =>
  ajax
    .get<APIResult<APIUser>>(`/profile/me`)
    .then((resp) => fromAPIResult<User>(resp.data.data));

export const updateCurrentUser = (params: UpdateUserParams): Promise<User> =>
  prepareProfile(params)
    .then((processedParams) =>
      ajax.put<UpdateUserParams, APIResult<APIUser>>(
        "/profile/me",
        processedParams
      )
    )
    .then((response) => fromAPIResult<User>(response.data.data));

export const requestDeleteCurrentUser = (
  params: DeleteUserParams
): Promise<APINoContentResult> =>
  ajax.post<DeleteUserParams, APINoContentResult>("/profile/me/delete", params);

export const updateDashboardLayoutPreferences = (
  params: UpdateDashboardLayoutPreferencesParams
): Promise<User> =>
  ajax
    .put<UpdateDashboardLayoutPreferencesParams, APIResult<APIUser>>(
      "/profile/me/preferences",
      params
    )
    .then((response) => fromAPIResult<User>(response.data.data));

export const getChildAccounts = (): Promise<ChildUser[]> =>
  ajax
    .get<APIResult<APIChildUser[]>>("/profile/me/children")
    .then((response) => response.data.data.map(fromAPIResult));

export const deleteChildUser = (
  childId: ModelID
): Promise<APINoContentResult> =>
  ajax.delete<APINoContentResult>(`/profile/me/children/${childId}`);

export const addChildUser = (
  params: AddUpdateChildUserParams
): Promise<ChildUser> =>
  ajax
    .post<AddUpdateChildUserParams, APIResult<APIChildUser>>(
      `/profile/me/children`,
      params
    )
    .then((response) => fromAPIResult<ChildUser>(response.data.data));

export const editChildUser = (
  childId: ModelID,
  params: AddUpdateChildUserParams
): Promise<ChildUser> =>
  ajax
    .put<AddUpdateChildUserParams, APIResult<APIChildUser>>(
      `/profile/me/children/${childId}`,
      params
    )
    .then((response) => fromAPIResult<ChildUser>(response.data.data));
