// @flow
import * as React from "react";
import {
  SettingsSectionHead,
  SettingsSectionRoot,
} from "../../../layout/SettingsSection";
import useTranslate from "../../../../hooks/useTranslate";
import type { Integration } from "../../../../models/integration.model";
import { APPS } from "../../../../models/integration.model";
import type { Callback } from "../../../../types";
import { Link, List } from "@mui/material";
import { SmallNeutralButton } from "../../../lib/inputs/buttons";
import { Body2 } from "../../../lib/display/Text";
import Translate from "../../../lib/display/Translate";
import * as links from "../../../../util/links.util";
import { byProp } from "../../../../models/base.model";
import IntegrationAppItem from "./IntegrationAppitem";

type Props = {
  integrations: Integration[],
  onRefresh: Callback,
  locked?: boolean,
};

const IntegrationsSection: React.ComponentType<Props> = ({
  integrations,
  onRefresh,
  locked,
}) => {
  const t = useTranslate();
  return (
    <SettingsSectionRoot>
      <SettingsSectionHead title={t("global.integrations")}>
        <SmallNeutralButton onClick={onRefresh} loading={locked}>
          {t("global.refresh")}
        </SmallNeutralButton>
      </SettingsSectionHead>
      <Body2 color="textSecondary" paragraph>
        <Translate id="IntegrationsTab.description" />{" "}
        <Link
          color="secondary"
          href={links.contact("suggestion")}
          target="_blank"
        >
          <Translate id="global.getInTouch" />
        </Link>
      </Body2>
      <List>
        {APPS.map((app) => (
          <IntegrationAppItem
            key={app.slug}
            app={app}
            integrationId={integrations.find(byProp("app", app.slug))?.id}
          />
        ))}
      </List>
    </SettingsSectionRoot>
  );
};

export default IntegrationsSection;
