// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Alert, Divider, List, Menu } from "@mui/material";
import Logo from "../../../display/Logo";
import { Subheader } from "../../../lib/display/Text";
import { MiniVariantDrawer, SlidingDrawer } from "./MiniVariantDrawer";
import {
  useCurrentSubscription,
  useCurrentUser,
  usePortfolioSummary,
} from "../../../../store/selectors";
import { PortfolioAvatar, UserAvatar } from "../../../display/avatars";
import StdListItem, {
  CircularSkeletonListItem,
  CollapsibleStdListItem,
  RoundedSkeletonListItem,
} from "../../../lib/display/listItems";
import type { Portfolio } from "../../../../models/portfolio.model";
import {
  getPortfolioDisplayOrder,
  getPortfolioName,
} from "../../../../models/portfolio.model";
import type { NavigationProps } from "../types";
import type { BrowserRoute } from "../../../../config/routes.config";
import routes, {
  getNestedRoutes,
  getRoutePath,
  isAscendingRouteOf,
  isRouteEnabled,
  isRouteVisible,
  makeGetRouteAdornedLabel,
} from "../../../../config/routes.config";
import type { ModelID } from "../../../../types";
import { cast } from "../../../../types";
import useTranslate from "../../../../hooks/useTranslate";
import {
  Assignment,
  ErrorRounded,
  FeedbackRounded,
  FlipCameraAndroidRounded,
  InfoRounded,
  LockRounded,
  LogoutRounded,
  MoreVertRounded,
  PersonAdd,
  RouteRounded,
  WalletOutlined,
  WarningRounded,
} from "@mui/icons-material";
import useElementState from "../../../../hooks/useElementState";
import MenuListItem from "../../../lib/navigation/MenuListItem";
import type { User } from "../../../../models/user.model";
import withConfirmDialog from "../../../lib/feedback/withConfirmDialog";
import * as env from "../../../../config/env.config";
import { useParams } from "react-router-dom";
import { parseModelId } from "../../../../models/base.model";
import sortBy from "lodash/sortBy";
import DragAndDropArea from "../../../lib/inputs/DragAndDropArea";
import useCurrentRoute from "../../../../hooks/useCurrentRoute";
import type { PortfolioSummary } from "../../../../models/portfolioSummary.model";
import { getPortfolioSummarySeverity } from "../../../../models/portfolioSummary.model";
import InsetAdornment from "../../../lib/layout/InsetAdornment";
import type { Severity } from "../../../../models/alerts.model";
import ToggleNavMenuIconButton from "../ToggleNavMenuIconButton";
import { isIOS } from "../../../../lib/platform.lib";
import type { Theme } from "../../../../stubs/mui/theming";
import useLogOutHandler from "../../../../hooks/useLogOutHandler";

const DrawerHeaderBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: theme.spacing(1),
  padding: theme.spacing(0, 1),
  gap: theme.spacing(3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerHeader = () => (
  <DrawerHeaderBox>
    <Logo size={48} />
    <Subheader style={{ flexGrow: 1, flexShrink: 1 }}>bobcaat</Subheader>
    <ToggleNavMenuIconButton />
  </DrawerHeaderBox>
);

const LogOutMenuItem = withConfirmDialog(MenuListItem, {
  DialogProps: {
    name: "log-out",
    title: "Page.Navigation.confirmLogOut.title",
    message: "Page.Navigation.confirmLogOut.message",
  },
});

const AvatarListItem = styled(StdListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const AccountItem = ({
  onNavigate,
  open,
}: {
  onNavigate: NavigationProps["onNavigate"],
  open?: boolean,
}) => {
  const user = useCurrentUser();
  const subscription = useCurrentSubscription();
  const t = useTranslate();
  const [anchorEl, captureEl, clearEl] = useElementState();
  const handleLogOut = useLogOutHandler();
  const handleMenuNavigate = React.useCallback(
    (url: string) => {
      onNavigate(url);
      clearEl();
    },
    [onNavigate, clearEl]
  );

  return (
    <>
      <AvatarListItem
        onClick={() =>
          onNavigate(getRoutePath(routes.app.nested.settings.nested.account))
        }
        avatar={<UserAvatar user={user} />}
        primary={user?.first_name}
        primaryProps={{ fontWeight: "bold" }}
        secondary={subscription?.plan.name}
        SecondaryActionIcon={open ? MoreVertRounded : undefined}
        secondaryAction={captureEl}
      />
      <Menu
        MenuListProps={{ dense: true }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={clearEl}
      >
        <MenuListItem
          Icon={WalletOutlined}
          primary={t(routes.app.nested.addPortfolio.label)}
          onClick={() =>
            handleMenuNavigate(getRoutePath(routes.app.nested.addPortfolio))
          }
        />
        <Divider />
        <MenuListItem
          Icon={FeedbackRounded}
          primary={t(routes.app.nested.feedback.label)}
          onClick={() =>
            handleMenuNavigate(getRoutePath(routes.app.nested.feedback))
          }
        />
        <MenuListItem
          Icon={PersonAdd}
          primary={t(routes.app.nested.referral.label)}
          onClick={() =>
            handleMenuNavigate(getRoutePath(routes.app.nested.referral))
          }
        />
        {user?.metamorph && <Divider />}
        {user?.metamorph && (
          <MenuListItem
            Icon={Assignment}
            primary={t("global.metamorph")}
            onClick={() =>
              handleMenuNavigate(getRoutePath(routes.app.nested.metamorph))
            }
          />
        )}
        {user?.metamorph && isIOS && (
          <MenuListItem
            Icon={FlipCameraAndroidRounded}
            primary={"Switch environment"}
            onClick={() => handleMenuNavigate(env.SWITCH_APP_URL)}
          />
        )}
        <Divider />
        <LogOutMenuItem
          Icon={LogoutRounded}
          primary={t("global.logOut")}
          onClick={handleLogOut}
          onClose={clearEl}
        />
      </Menu>
    </>
  );
};

type PortfolioNavigationItemsProps = {
  onNavigate: NavigationProps["onNavigate"],
  portfolioId: ModelID,
  user: ?User,
  summary: ?PortfolioSummary,
};

const PortfolioItemsList = styled(List)(
  ({ theme }) => `
  background-color: ${theme.palette.background.paper};
`
);

const PORTFOLIO_ITEM_SELECTED_MIXIN = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "& svg": {
    fill: theme.palette.primary.contrastText,
  },
});

const PortfolioItemStdListItem = styled(StdListItem)(({ theme }) => ({
  "& > div": {
    transition: "background-color 0.3s ease",
    padding: theme.spacing(0.25, 0.75, 0.25, 0),
    margin: theme.spacing(0.25, 1.25, 0.25, 2),
    borderRadius: theme.shape.borderRadius,
  },
  "& > div.Mui-selected:hover": PORTFOLIO_ITEM_SELECTED_MIXIN(theme),
  "& > div.Mui-selected": PORTFOLIO_ITEM_SELECTED_MIXIN(theme),
}));

const PortfolioNavigationItems = React.memo(
  ({
    onNavigate,
    portfolioId,
    user,
    summary,
  }: PortfolioNavigationItemsProps) => {
    const t = useTranslate();
    const currentRoute = useCurrentRoute();
    const getRouteAdornedLabel = makeGetRouteAdornedLabel(t, summary);
    const { portfolioId: portfolioIdParams } = useParams();
    const accountsCount = summary?.accounts.length ?? 99;

    return (
      <>
        <PortfolioItemsList>
          {getNestedRoutes(routes.app.nested.portfolios)
            .filter(isRouteVisible(user, accountsCount))
            .map((anyRoute) => {
              const route = cast<BrowserRoute>(anyRoute);
              const { Icon = RouteRounded, path } = route;
              const disabled = !isRouteEnabled(user)(route);
              const selected =
                parseModelId(portfolioIdParams) === portfolioId &&
                isAscendingRouteOf(route, currentRoute);
              return (
                <PortfolioItemStdListItem
                  key={path}
                  selected={selected}
                  disabled={disabled}
                  disableRipple
                  icon={<Icon size="small" style={{ marginLeft: 8 }} />}
                  primary={getRouteAdornedLabel(route)}
                  onClick={() =>
                    onNavigate(getRoutePath(route, { portfolioId }))
                  }
                  dense
                />
              );
            })}
        </PortfolioItemsList>
        {accountsCount === 0 && (
          <Alert
            severity="warning"
            sx={{ whiteSpace: "pre-wrap" }}
            icon={<LockRounded size="small" style={{ marginLeft: 8 }} />}
          >
            {t("global.noAccountsYetNavigationWarning")}
          </Alert>
        )}
      </>
    );
  }
);

const PortfolioItemInsetAdornment = ({ severity }: { severity: ?Severity }) => {
  switch (severity) {
    case "error":
      return (
        <InsetAdornment offset={{ vertical: -1 }}>
          <ErrorRounded color="error" fontSize="small" />
        </InsetAdornment>
      );
    case "warning":
      return (
        <InsetAdornment offset={{ vertical: -1 }}>
          <WarningRounded color="warning" fontSize="small" />
        </InsetAdornment>
      );
    case "info":
      return (
        <InsetAdornment offset={{ vertical: -1 }}>
          <InfoRounded color="info" fontSize="small" />
        </InsetAdornment>
      );
    default:
      return null;
  }
};

const PortfolioAvatarContainer = styled("div")({
  position: "relative",
});

const StyledPortfolioAvatar = styled(PortfolioAvatar, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  ...(selected && {
    border: `solid 3px ${theme.palette.primary.main}`,
    "& img": {
      borderRadius: 4,
    },
  }),
}));

const PortfolioItem = React.memo(
  ({
    open,
    selected,
    portfolio,
    onNavigate,
    user,
  }: {
    open?: boolean,
    selected?: boolean,
    portfolio: Portfolio,
    onNavigate: NavigationProps["onNavigate"],
    user: ?User,
  }) => {
    const summary = usePortfolioSummary(portfolio.id);
    const severity = getPortfolioSummarySeverity(summary);

    return (
      <CollapsibleStdListItem
        selected={selected}
        avatar={
          <PortfolioAvatarContainer>
            <PortfolioItemInsetAdornment severity={severity} />
            <StyledPortfolioAvatar portfolio={portfolio} selected={selected} />
          </PortfolioAvatarContainer>
        }
        primary={portfolio.name}
        primaryProps={{ fontWeight: "bold", variant: "body2" }}
        disabled={!open}
      >
        <PortfolioNavigationItems
          user={user}
          onNavigate={onNavigate}
          portfolioId={portfolio.id}
          summary={summary}
        />
      </CollapsibleStdListItem>
    );
  }
);

const renderPortfolioItem =
  (
    open: boolean,
    onNavigate: (url: string) => any,
    selectedPortfolioId: ?ModelID,
    user: ?User
  ) =>
  (pf: Portfolio) =>
    (
      <PortfolioItem
        selected={selectedPortfolioId === pf.id}
        portfolio={pf}
        open={open}
        key={pf.id}
        onNavigate={onNavigate}
        user={user}
      />
    );

const DrawerContent: React.ComponentType<NavigationProps> = ({
  open,
  portfolios,
  onNavigate,
  user,
  onPortfolioReordered,
}) => {
  // Can't use scoped portfolio here. We are out of the tree hierarchy.
  const { portfolioId: portfolioIdParam } = useParams();
  const portfolioId = parseModelId(portfolioIdParam);
  const sortedPortfolios = React.useMemo(
    () => sortBy(portfolios, getPortfolioDisplayOrder, getPortfolioName),
    [portfolios]
  );
  const renderPortfolio = React.useMemo(
    () => renderPortfolioItem(!!open, onNavigate, portfolioId, user),
    [open, onNavigate, portfolioId, user]
  );

  return (
    <>
      <DrawerHeader />
      <List>
        {user ? (
          <>
            <AccountItem onNavigate={onNavigate} open={open} />
            <DragAndDropArea
              items={sortedPortfolios}
              spacing={0}
              layout="vertical"
              renderer={renderPortfolio}
              onItemsReordered={onPortfolioReordered}
            />
          </>
        ) : (
          <>
            <CircularSkeletonListItem />
            <RoundedSkeletonListItem />
            <RoundedSkeletonListItem />
            <RoundedSkeletonListItem />
          </>
        )}
      </List>
    </>
  );
};

export const PermanentDrawer: React.ComponentType<NavigationProps> = ({
  open,
  ...props
}) => {
  return (
    <MiniVariantDrawer variant="permanent" open={open}>
      <DrawerContent open={open} {...props} />
    </MiniVariantDrawer>
  );
};

export const TemporaryDrawer: React.ComponentType<NavigationProps> = ({
  open,
  onToggle,
  ...props
}) => {
  return (
    <SlidingDrawer variant="temporary" open={open} onClose={onToggle}>
      <DrawerContent open={open} {...props} />
    </SlidingDrawer>
  );
};
