// @flow
import * as React from "react";
import type {
  BooleanFormFieldDef,
  Form,
  ObjectFormFieldDef,
  StringFormFieldDef,
} from "../../../hooks/useForm";
import { FormActions } from "../../../hooks/useForm";
import { ColumnStack, ResponsiveInlineRowStack } from "../../lib/layout/stacks";
import FormTextInput from "../../lib/inputs/form/FormTextInput";
import useTranslate from "../../../hooks/useTranslate";
import FormAutocomplete from "../../lib/inputs/form/FormAutocomplete";
import COUNTRIES from "../../../config/countries.config";

export type BillingInfoFormDef = {
  cardHolderName: StringFormFieldDef,
  addressLine: StringFormFieldDef,
  city: StringFormFieldDef,
  postalCode: StringFormFieldDef,
  stateOrRegion: StringFormFieldDef,
  country: ObjectFormFieldDef,
  email: StringFormFieldDef,
  enableSendInvoices: BooleanFormFieldDef,
};

type Props = {
  editing?: boolean,
  form: Form<BillingInfoFormDef>,
};

const readOnlyInputProps = { readOnly: true, disableUnderline: true };
const INPUT_WIDTH = 250;

const BillingInfoWidget: React.ComponentType<Props> = ({ form, editing }) => {
  const t = useTranslate();
  const InputProps = !editing ? readOnlyInputProps : undefined;
  return (
    <ColumnStack>
      <ResponsiveInlineRowStack>
        <FormTextInput
          formField={form.state.cardHolderName}
          onChange={FormActions.onChangeField(form.set, "cardHolderName")}
          label={t("Page.SettingsSection.PaymentDetailsTab.cardHolderName")}
          name="fullname"
          placeholder="Bob Kaat"
          required
          sx={{ width: { sm: INPUT_WIDTH } }}
          InputProps={InputProps}
        />
        <FormTextInput
          formField={form.state.email}
          onChange={FormActions.onChangeField(form.set, "email")}
          label={t("Page.SettingsSection.PaymentDetailsTab.email")}
          name="email"
          type="email"
          placeholder="billing@bobcaat.com"
          required
          sx={{ width: { sm: INPUT_WIDTH } }}
          InputProps={InputProps}
        />
      </ResponsiveInlineRowStack>
      <FormTextInput
        formField={form.state.addressLine}
        onChange={FormActions.onChangeField(form.set, "addressLine")}
        label={t("Page.SettingsSection.PaymentDetailsTab.street")}
        name="address"
        placeholder="12 Biscuit Road"
        required
        sx={{ width: { sm: INPUT_WIDTH } }}
        InputProps={InputProps}
      />
      <ResponsiveInlineRowStack>
        <FormTextInput
          formField={form.state.city}
          onChange={FormActions.onChangeField(form.set, "city")}
          label={t("Page.SettingsSection.PaymentDetailsTab.city")}
          name="town"
          placeholder="Catstown"
          required
          sx={{ width: { sm: INPUT_WIDTH } }}
          InputProps={InputProps}
        />
        <FormTextInput
          formField={form.state.postalCode}
          onChange={FormActions.onChangeField(form.set, "postalCode")}
          label={t("Page.SettingsSection.PaymentDetailsTab.postalCode")}
          name="postal-code"
          placeholder="AX1 2ZE"
          sx={{ width: { sm: INPUT_WIDTH } }}
          InputProps={InputProps}
        />
      </ResponsiveInlineRowStack>
      <ResponsiveInlineRowStack>
        <FormTextInput
          formField={form.state.stateOrRegion}
          onChange={FormActions.onChangeField(form.set, "stateOrRegion")}
          label={t("Page.SettingsSection.PaymentDetailsTab.stateRegion")}
          name="county-or-state"
          placeholder="Tigershire"
          sx={{ width: { sm: INPUT_WIDTH } }}
          InputProps={InputProps}
        />
        <FormAutocomplete
          readOnly={!editing}
          formField={form.state.country}
          options={COUNTRIES}
          onChange={FormActions.onChangeField(form.set, "country")}
          optionPrimaryLabel="label"
          disableClearable
          TextFieldProps={{
            InputProps,
            sx: { width: { sm: INPUT_WIDTH } },
            label: t("global.country"),
          }}
        />
      </ResponsiveInlineRowStack>
    </ColumnStack>
  );
};

export default BillingInfoWidget;
