import useTranslate from "../../../hooks/useTranslate";
import { CircularProgress } from "@mui/material";
import PaymentCard from "../../lib/display/PaymentCard";
import StatusChip from "../../lib/display/StatusChip";
import React from "react";
import { styled } from "@mui/material/styles";
import { RowStack } from "../../lib/layout/stacks";
import useCurrentCardDetails from "./useCurrentCardDetails";

const PaymentCardContainer = styled(RowStack)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const CurrentCardPreview = () => {
  const cardDetails = useCurrentCardDetails();
  const t = useTranslate();
  return typeof cardDetails === "undefined" ? (
    <CircularProgress size={24} />
  ) : (
    cardDetails && (
      <PaymentCardContainer alignItems="center" spacing={2}>
        <PaymentCard card={cardDetails} />
        <StatusChip severity="success">{t("global.verified")}</StatusChip>
      </PaymentCardContainer>
    )
  );
};
