// @flow
import * as React from "react";
import AlertDialog from "../../components/lib/feedback/AlertDialog";
import type { I18nKey } from "../../types";

type Props = {
  version: string,
  titleId: I18nKey,
  messageId: I18nKey,
  yesId: I18nKey,
  onClose?: () => void,
};

const NewVersionAlert: React.ComponentType<Props> = ({
  version,
  titleId,
  yesId,
  messageId,
  onClose,
}) => {
  const [initialVersion, setInitialVersion] = React.useState(version);

  if (initialVersion === version) {
    return null;
  }

  const handleClose = () => {
    setInitialVersion(version);
    onClose && onClose();
  };

  return (
    <AlertDialog
      title={titleId}
      onClose={handleClose}
      yesLabel={yesId}
      message={messageId}
      name="new-version"
    />
  );
};

export default NewVersionAlert;
