// @flow
import * as React from "react";
import moment from "moment";
import type { IdentifiedMutablePost, MutablePost } from "../models/post.model";
import { findURL } from "../lib/patterns.lib";
import type { ModelID, Moment } from "../types";
import noop from "lodash/noop";
import type { Provider } from "../reactTypes";
import { debounce } from "@mui/material/utils";
import pick from "lodash/pick";
import keys from "lodash/keys";
import merge from "lodash/merge";
import type { SubChannel } from "../models/subChannel.model";
import type { Channel } from "../models/channels/channel.model";
import without from "lodash/without";
import type { BobcaatAccount } from "../models/account.model";
import type { ContentTypes } from "../models/contentTypes.model";
import { useAccount } from "../store/selectors";
import { getChannel } from "../models/channels";
import type { MixedCreatorInfo } from "../models/channels/tiktok.model";

type TiktokCreatorContext = {
  info: ?MixedCreatorInfo,
  updatedAt: ?Moment,
  accountId: ?ModelID,
};

const EMPTY_TIKTOK_CREAtOR_CONTEXT = {
  info: null,
  updatedAt: null,
  accountId: null,
};

type PostBuilderState = {
  post: IdentifiedMutablePost,
  alreadyPublished: boolean,
  tiktokCreatorContext: TiktokCreatorContext,
  // cache
  channel: ?Channel,
  subchannel: ?SubChannel,
};

export type PostBuilderInitial = {
  post?: { ...Partial<IdentifiedMutablePost>, ... },
  subchannel?: ?SubChannel,
  alreadyPublished?: boolean,
};

export type PostBuilderContextType = {
  onUpdatePost: (updates: Partial<MutablePost>) => any,
  onSetDestination: (account: BobcaatAccount, contentType: ContentTypes) => any,
  onSetSubchannel: (subchannel: SubChannel) => any,
  onSetContent: (content: string) => any,
  onSetTiktokCreatorInfo: (info: MixedCreatorInfo, accountId: ModelID) => any,
  ...PostBuilderState,
};

const getDefaultScheduleTime = (): Moment => moment().add(1, "hour");
const getInitialScheduleTime = (time: ?Moment): Moment =>
  !time || moment().isSameOrAfter(time) ? getDefaultScheduleTime() : time;

const getEmptyPost = (): IdentifiedMutablePost => ({
  id: 0,
  title: "",
  account_id: 0,
  content: "",
  status: "scheduled",
  schedule_time: getDefaultScheduleTime(),
  local_schedule_tz_id: null,
  link: null,
  poll: null,
  attachments: [],
  content_type: "post",
  subchannel_id: null,
  approval: null,
  thumbnail_url: null,
  thumbnail_video_offset: null,
  thumbnail_title: null,
  first_comment: null,
  extras: null,
  secondary_account_ids: [],
  disable_autopublish: false,
  self_replies: [],
  periodicity: null,
});

const CORE_POST_KEYS = keys(getEmptyPost());

const postStateInitialiser =
  (initial: ?{ ...Partial<MutablePost>, ... }) => (): IdentifiedMutablePost => {
    const emptyPost = getEmptyPost();
    if (!initial) return emptyPost;

    return {
      // Use pick to ensure we only get a core post and nothing more.
      // Then use merge not to override when source is undefined.
      ...merge(emptyPost, pick(initial, CORE_POST_KEYS)),
      schedule_time: getInitialScheduleTime(initial.schedule_time),
    };
  };

const PostBuilderContext: React.Context<PostBuilderContextType> =
  React.createContext({
    post: getEmptyPost(),
    subchannel: null,
    channel: null,
    tiktokCreatorContext: EMPTY_TIKTOK_CREAtOR_CONTEXT,
    alreadyPublished: false,
    onUpdatePost: noop,
    onSetDestination: noop,
    onSetContent: noop,
    onSetSubchannel: noop,
    onSetTiktokCreatorInfo: noop,
  });

export const PostBuilderContextProvider: Provider<
  PostBuilderContextType,
  { initial?: PostBuilderInitial }
> = ({ children, initial }) => {
  const [subchannel, onSetSubchannel] = React.useState<?SubChannel>(
    initial?.subchannel
  );
  const [tiktokCreatorContext, setTiktokCreatorContext] =
    React.useState<TiktokCreatorContext>(EMPTY_TIKTOK_CREAtOR_CONTEXT);
  const [post, onSetPost] = React.useState<IdentifiedMutablePost>(
    postStateInitialiser(initial?.post)
  );
  const account = useAccount(post.account_id);
  const channel = React.useMemo(
    () => getChannel(account?.channel),
    [account?.channel]
  );

  const handleExtractLink = React.useMemo(
    () =>
      debounce(
        (content: string) =>
          onSetPost((prev) => ({ ...prev, link: findURL(content) })),
        300
      ),
    []
  );

  const handleSetTiktokCreatorInfo = React.useCallback(
    (info: MixedCreatorInfo, accountId: ModelID) => {
      setTiktokCreatorContext({
        info,
        updatedAt: moment(),
        accountId: accountId,
      });
    },
    []
  );

  const handleSetDestination = React.useCallback(
    (account: BobcaatAccount, contentType: ContentTypes) => {
      onSetPost((prev) => ({
        ...prev,
        account_id: account.id,
        subchannel_id: null,
        extras: null,
        secondary_account_ids: without(prev.secondary_account_ids, account.id),
        content_type: contentType,
      }));
    },
    []
  );

  return (
    <PostBuilderContext.Provider
      value={{
        post,
        channel,
        subchannel,
        tiktokCreatorContext,
        alreadyPublished: !!initial?.alreadyPublished,
        onSetDestination: handleSetDestination,
        onSetTiktokCreatorInfo: handleSetTiktokCreatorInfo,
        onSetContent: React.useCallback(
          (content) => {
            channel?.requires?.extractLink && handleExtractLink(content);
            onSetPost((prev) => ({ ...prev, content }));
          },
          [channel?.requires?.extractLink, handleExtractLink]
        ),
        onUpdatePost: React.useCallback(
          (updates) => onSetPost((prev) => ({ ...prev, ...updates })),
          []
        ),
        onSetSubchannel: React.useCallback((subchannel) => {
          onSetPost((prev) => ({ ...prev, subchannel_id: subchannel.id }));
          onSetSubchannel(subchannel);
        }, []),
      }}
    >
      {children}
    </PostBuilderContext.Provider>
  );
};

export default PostBuilderContext;
