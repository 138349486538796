// @flow
import * as React from "react";
import type { AttachmentUploadProgress } from "../../../../models/attachment.model";
import {
  CircularProgress,
  Fade,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowLeft, ArrowRight, DeleteRounded } from "@mui/icons-material";
import { FlexGrow } from "../../layout/boxes";
import FileOrURLPreview from "../../display/FileOrURLPreview";
import type { DataURI, Milliseconds } from "../../../../types";
import type { SyntheticEventListener } from "../../../../reactTypes";
import useTranslate from "../../../../hooks/useTranslate";

type UploadProgressProps = {
  uploadProgress?: AttachmentUploadProgress,
};

const UploadProgressRoot = styled("div")({
  position: "absolute",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  zIndex: 10,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const UploadProgress: React.ComponentType<UploadProgressProps> = ({
  uploadProgress,
}) => {
  return (
    <Fade
      unmountOnExit
      mountOnEnter
      in={uploadProgress && uploadProgress.current < uploadProgress.total}
    >
      <UploadProgressRoot>
        <CircularProgress
          size={48}
          value={
            uploadProgress
              ? (100 * uploadProgress.current) / uploadProgress.total
              : 0
          }
          variant="determinate"
        />
      </UploadProgressRoot>
    </Fade>
  );
};

export type MediaItemSrc = DataURI | File;

type Props = {
  ...UploadProgressProps,
  src: MediaItemSrc,
  onDelete: SyntheticEventListener,
  onMediaClick?: SyntheticEventListener,
  onMoveLeft?: SyntheticEventListener,
  onMoveRight?: SyntheticEventListener,
  videoOffset?: ?Milliseconds,
};

const Root = styled(Paper)({
  display: "inline-block",
  overflow: "clip",
});

const MediaItemHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.5),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const MediaItemContent = styled("div")(({ theme }) => ({
  height: 140,
  width: 140,
  display: "flex",
  justifyContent: "center",
  position: "relative",
  [theme.breakpoints.down("xs")]: {
    height: 80,
    width: 80,
  },
}));

const COVER_MIXIN = {
  width: "100%",
  minHeight: "100%",
  objectFit: "cover",
  "& svg": {
    height: "calc(100% - 42px)",
  },
};

const CoverFileOrURLPreview = styled(FileOrURLPreview)(COVER_MIXIN);

const MediaItem: React.ComponentType<Props> = ({
  src,
  onMediaClick,
  onDelete,
  onMoveLeft,
  onMoveRight,
  uploadProgress,
  videoOffset,
}) => {
  const t = useTranslate();
  return (
    <Root variant="outlined">
      <MediaItemHeader>
        {onMoveLeft && (
          <IconButton size="small" onClick={onMoveLeft}>
            <ArrowLeft />
          </IconButton>
        )}
        {onMoveRight && (
          <IconButton size="small" onClick={onMoveRight}>
            <ArrowRight />
          </IconButton>
        )}
        <FlexGrow />
        <IconButton size="small" onClick={onDelete}>
          <DeleteRounded />
        </IconButton>
      </MediaItemHeader>
      <Tooltip
        title={onMediaClick ? t("MediaList.itemTooltip") : undefined}
        disabled={!onMediaClick}
        placement="bottom"
      >
        <MediaItemContent
          onClick={onMediaClick}
          style={{ ...(onMediaClick && { cursor: "pointer" }) }}
        >
          <UploadProgress uploadProgress={uploadProgress} />
          <CoverFileOrURLPreview src={src} videoOffset={videoOffset} />
        </MediaItemContent>
      </Tooltip>
    </Root>
  );
};

export default MediaItem;
