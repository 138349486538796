// @flow
import type { CreateScopeReturn } from "./scope";
import createResourceScope from "./scope";
import type { Portfolio } from "../models/portfolio.model";
import { byId } from "../models/base.model";
import type { HashtagGroup } from "../models/hashtagGroup.model";
import type { Post } from "../models/post.model";
import * as accountService from "../services/account.service";
import * as postService from "../services/post.service";
import * as messagingService from "../services/messaging.service";
import type { ApprovalRequest } from "../models/approvalRequest.model";
import type { BobcaatAccount } from "../models/account.model";
import type { StoreState } from "../store/store";
import type { ModelID } from "../types";
import type { Conversation } from "../models/conversation.model";
import moment from "moment";
import * as engagementService from "../services/engagement.service";

export const [
  useScopedPortfolio,
  PortfolioScope,
]: CreateScopeReturn<Portfolio> = createResourceScope<"portfolioId", Portfolio>(
  "portfolioId",
  (state, pfId) =>
    // If it can be found, return it.
    state.portfolio.portfolios.find(byId(pfId)) ??
    // If it can't, locked means it is being loaded.
    (state.portfolio.locked ? undefined : null),
  (pf, actions, user) => {
    accountService.getAccounts(actions)(pf.id);
    postService.reloadPortfolioPosts(actions)({ portfolioId: pf.id });

    // One day or another, might have to actually paginate all of this.
    if (user?.limits.can_use_comment_manager) {
      messagingService.getConversations(actions)(pf.id);
      messagingService.getConversationSummaries(actions)(pf.id);
      engagementService.getSocialActionSummaries(actions)(pf.id);
    }
  }
);

export const [
  useScopedAccount,
  AccountScope,
]: CreateScopeReturn<BobcaatAccount> = createResourceScope<
  "accountId",
  BobcaatAccount
>(
  "accountId",
  (state: StoreState, accountId: ModelID): ?BobcaatAccount =>
    state.account.accounts.find(byId(accountId)) ??
    // If it can't, locked means it is being loaded.
    (state.account.locked ? undefined : null)
);

export const [
  useScopedConversation,
  ConversationScope,
]: CreateScopeReturn<Conversation> = createResourceScope<
  "conversationId",
  Conversation
>(
  "conversationId",
  (state: StoreState, conversationId: ModelID): ?Conversation =>
    state.messaging.conversations.find(byId(conversationId)) ??
    // If it can't, locked means it is being loaded.
    (state.messaging.initialised === "all" ? undefined : null),
  (conversation, actions) => {
    actions.messaging.messages.clear();
    // Load an initial batch of messages for the conversation.
    messagingService.getOlderMessages(actions)(
      conversation.id,
      moment().unix()
    );
  }
);

export const [
  useScopedHashtagGroup,
  HashtagGroupScope,
]: CreateScopeReturn<HashtagGroup> = createResourceScope<
  "hashtagGroupId",
  HashtagGroup
>(
  "hashtagGroupId",
  (state, hashtagGroupId) =>
    state.hashtagGroup.hashtagGroups.find(byId(hashtagGroupId)) ??
    // If it can't, locked means it is being loaded.
    (state.hashtagGroup.locked ? undefined : null)
);

export const [useScopedPost, PostScope]: CreateScopeReturn<Post> =
  createResourceScope<"postId", Post>(
    "postId",
    (state, postId) =>
      state.post.posts.find(byId(postId)) ??
      // If it can't, locked means it is being loaded.
      (state.post.initialised !== "all" ? undefined : null)
  );

export const [
  useScopedApprovalRequest,
  ApprovalRequestScope,
]: CreateScopeReturn<ApprovalRequest> = createResourceScope<
  "approvalRequestId",
  ApprovalRequest
>(
  "approvalRequestId",
  (state, approvalRequestId) =>
    state.approvalRequest.approvalRequests.find(byId(approvalRequestId)) ??
    // If it can't, locked means it is being loaded.
    (state.approvalRequest.locked ? undefined : null)
);
