// @flow
import * as React from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { FormControl, FormHelperText } from "@mui/material";

type StripeError = {
  message: string,
};

const StripePaymentInput: React.ComponentType<{}> = () => {
  const [error, setError] = React.useState<?StripeError>();
  return (
    <FormControl fullWidth error={!!error}>
      <PaymentElement
        options={{
          fields: {
            billingDetails: {
              name: "never",
              email: "never",
              address: {
                line1: "never",
                city: "never",
                country: "never",
                state: "never",
                postalCode: "never",
              },
            },
          },
        }}
        onChange={(e) => setError(e.error)}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default StripePaymentInput;
