// @flow
import * as React from "react";
import Tag from "../Tag";
import type { Props as TagProps } from "../Tag";
import type { AttachmentUserTag } from "../../../../models/attachment.model";
import { InputBase } from "@mui/material";
import type { Callback } from "../../../../types";
import { styled } from "@mui/material/styles";

type ActivatedContentProps = {
  onDeactivate: Callback,
  onChange: (text: string, value: string) => any,
  text: string,
};

const Input = styled(InputBase)({
  fontWeight: "bold",
  color: "inherit",
  // To align with prefix.
  paddingTop: 3,
});

const ActivatedContent: React.ComponentType<ActivatedContentProps> = ({
  onDeactivate,
  onChange,
  text,
}) => {
  const handleKeyDown = React.useCallback(
    (e: SyntheticKeyboardEvent<>) => {
      if (["Enter", "Escape"].includes(e.key)) {
        onDeactivate();
      }
    },
    [onDeactivate]
  );

  return (
    <Input
      onKeyDown={handleKeyDown}
      value={text}
      size="small"
      margin="none"
      autoFocus
      onChange={(e) => onChange(e.currentTarget.value, e.currentTarget.value)}
    />
  );
};

type Props = {
  tag: AttachmentUserTag,
  ...Omit<
    TagProps,
    "x" | "y" | "text" | "value" | "prefix" | "ActivatedContentComponent"
  >,
};

const UserTag: React.ComponentType<Props> = ({ tag, ...props }) => (
  <Tag
    x={tag.x}
    y={tag.y}
    text={tag.handle}
    value={tag.handle}
    prefix="@"
    ActivatedContentComponent={ActivatedContent}
    {...props}
  />
);

export default UserTag;
