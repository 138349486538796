// @flow
import * as React from "react";
import { Link } from "@mui/material";
import { Link as LinkImpl } from "react-router-dom";
import type { Children, CSSProps } from "../../../reactTypes";

/**
 * The pathname, search, and hash values of a URL.
 */
export interface Path {
  /**
   * A URL pathname, beginning with a /.
   */
  pathname: string;

  /**
   * A URL search string, beginning with a ?.
   */
  search: string;

  /**
   * A URL fragment identifier, beginning with a #.
   */
  hash: string;
}

export type RelativeRoutingType = "route" | "path";

/**
 * Describes a location that is the destination of some navigation, either via
 * `history.push` or `history.replace`. May be either a URL or the pieces of a
 * URL path.
 */
export type To = string | Partial<Path>;

type Props = {
  reloadDocument?: boolean,
  replace?: boolean,
  state?: any,
  preventScrollReset?: boolean,
  relative?: RelativeRoutingType,
  to: To,
  rel?: string,
  target?: string,
  children?: Children,
  variant?: string,
  ...CSSProps,
  sx?: Object,
};

const RouterLink: React.ComponentType<Props> = (props) => (
  <Link component={LinkImpl} underline="hover" {...props} />
);

export default RouterLink;
