// @flow
import * as React from "react";
import type { GeneralTabFormDef } from "./GeneralSection";
import GeneralSection from "./GeneralSection";
import {
  useCurrentSubscription,
  useCurrentUser,
} from "../../../../store/selectors";
import { FormActions, useForm } from "../../../../hooks/useForm";
import { difference } from "../../../../lib/lodashex.lib";
import * as profileService from "../../../../services/profile.service";
import useSwitch from "../../../../hooks/useSwitch";
import { useStoreActions } from "../../../../store/store";

const FORM_FIELDS: GeneralTabFormDef = {
  avatar: {
    type: "string",
  },
  first_name: {
    type: "string",
  },
  last_name: {
    type: "string",
  },
  email: {
    type: "string",
  },
};

const GeneralSectionContainer: React.ComponentType<{}> = () => {
  const user = useCurrentUser();
  const subscription = useCurrentSubscription();
  const actions = useStoreActions();
  const [editing, onBeginEdit, onEndEdit] = useSwitch(false);
  const initial = React.useMemo(
    () => ({
      avatar: user?.avatar ?? "",
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      email: user?.email ?? "",
    }),
    [user]
  );
  const form = useForm(FORM_FIELDS, initial);

  React.useEffect(() => {
    FormActions.reset(form.set, initial);
  }, [form.set, initial]);

  const handleCancel = () => {
    onEndEdit();
    FormActions.reset(form.set, initial);
  };

  const handleConfirm = () => {
    if (user && FormActions.validate(form)) {
      const updates = { ...FormActions.collect(form.state) };
      return profileService
        .updateCurrentUser(actions)(user, difference(initial, updates))
        .then(onEndEdit);
    }
  };
  return (
    <GeneralSection
      editing={editing}
      onBeginEdit={onBeginEdit}
      onCancelEdit={handleCancel}
      onCompleteEdit={handleConfirm}
      form={form}
      user={user}
      subscription={subscription}
    />
  );
};

export default GeneralSectionContainer;
