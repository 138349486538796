// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import {
  useAccountsLocked,
  usePortfolioAccounts,
} from "../../../store/selectors";
import { useScopedPortfolio } from "../../../scopes/scopes";
import AccountCard from "../../../components/inputs/AccountCard";
import { Grow } from "@mui/material";
import { getAccountChannel } from "../../../models/account.model";
import { ResponsiveInlineRowStack } from "../../../components/lib/layout/stacks";
import { PrimaryButton } from "../../../components/lib/inputs/buttons";
import withPopupMenu from "../../../components/lib/navigation/withPopupMenu";
import Translate from "../../../components/lib/display/Translate";
import channels from "../../../models/channels";
import MenuListItem from "../../../components/lib/navigation/MenuListItem";
import { getChannelIcon } from "../../../components/lib/display/icons/channels";
import useTranslate from "../../../hooks/useTranslate";
import useBind from "../../../components/inputs/AccountCard/useBind";
import type { ModelID } from "../../../types";
import type { Channel } from "../../../models/channels/channel.model";
import { EmptyNotice } from "../../../components/display/EmptyNotice";
import { AccountCardSkeleton } from "../../../components/inputs/AccountCard/AccountCard";
import range from "lodash/range";
import { Center } from "../../../components/lib/layout/placement";

const AddAccountButton = withPopupMenu(PrimaryButton, {
  children: <Translate id="global.addAccount" />,
  anchor: { vertical: "bottom", horizontal: "left" },
  transform: { horizontal: "left", vertical: "top" },
});

type DisconnectedChannelMenuListItemProps = {
  channel: Channel,
  portfolioId: ModelID,
};

const DisconnectedChannelMenuListItem: React.ComponentType<DisconnectedChannelMenuListItemProps> =
  ({ channel, portfolioId }) => {
    const t = useTranslate();
    const [onBind, fragment] = useBind({ channel, portfolioId });
    return (
      <>
        {fragment}
        <MenuListItem
          key={channel.slug}
          Icon={getChannelIcon(channel.slug)}
          primary={channel.name}
          iconColor={channel.slug}
          secondary={t(`refer.channels.${channel.slug}.features`)}
          onClick={onBind}
        />
      </>
    );
  };

const AccountsPage: React.ComponentType<empty> = () => {
  const pf = useScopedPortfolio();
  const accounts = usePortfolioAccounts(pf?.id);
  const locked = useAccountsLocked();
  if (!pf) return;

  const route = routes.app.nested.portfolios.nested.socialAccounts;
  const addButton = (
    <AddAccountButton size={accounts.length === 0 ? "large" : undefined}>
      {channels.map((channel) => (
        <DisconnectedChannelMenuListItem
          portfolioId={pf.id}
          channel={channel}
          key={channel.slug}
        />
      ))}
    </AddAccountButton>
  );

  return (
    <PrimaryPagePanel size="fill" route={route}>
      {!locked && accounts.length > 0 && (
        <>
          <div>{addButton}</div>
          <ResponsiveInlineRowStack>
            {accounts.map((account) => (
              <Grow in unmountOnExit key={account.id}>
                <div>
                  <AccountCard
                    account={account}
                    channel={getAccountChannel(account)}
                    portfolioId={pf.id}
                  />
                </div>
              </Grow>
            ))}
          </ResponsiveInlineRowStack>
        </>
      )}
      {!locked && accounts.length === 0 && (
        <Center>
          <EmptyNotice
            label="global.noAccountsYet"
            cta={addButton}
            maxWidth="100%"
          >
            <ResponsiveInlineRowStack>
              {range(3).map((idx) => (
                <AccountCardSkeleton key={idx} />
              ))}
            </ResponsiveInlineRowStack>
          </EmptyNotice>
        </Center>
      )}
    </PrimaryPagePanel>
  );
};

export default AccountsPage;
