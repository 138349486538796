// @flow
import * as React from "react";
import type { I18nKey, Callback } from "../../../types";
import Dialog from "./Dialog";
import Translate from "../display/Translate";
import { NeutralTextButton, PrimaryButton } from "../inputs/buttons";
import type { Props as DialogProps } from "./Dialog";
import useTranslate from "../../../hooks/useTranslate";
import flow from "lodash/flow";

export type Props<ConfirmArg = void> = {
  ...Omit<DialogProps, "children" | "text" | "actions">,
  title?: I18nKey,
  message: I18nKey,

  onClose: Callback,
  cancelLabel?: I18nKey,

  onConfirm: (ConfirmArg) => any,
  confirmLabel?: I18nKey,

  onConfirmAlt?: Callback,
  confirmAltLabel?: I18nKey,
};

const ConfirmDialog = <ConfirmArg = void>({
  message,
  title,
  onClose,
  cancelLabel = "global.cancel",
  onConfirm,
  confirmLabel = "global.ok",
  onConfirmAlt,
  confirmAltLabel,
  ...props
}: Props<ConfirmArg>): React.Node => {
  const t = useTranslate();
  return (
    <Dialog
      onClose={onClose}
      title={title ? t(title) : undefined}
      text={<Translate id={message} />}
      actions={
        <>
          <NeutralTextButton onClick={onClose}>
            {t(cancelLabel)}
          </NeutralTextButton>
          {onConfirmAlt && (
            <NeutralTextButton onClick={flow(onConfirmAlt, onClose)}>
              {t(confirmAltLabel)}
            </NeutralTextButton>
          )}
          <PrimaryButton onClick={flow(onConfirm, onClose)} autoFocus>
            {t(confirmLabel)}
          </PrimaryButton>
        </>
      }
      {...props}
    />
  );
};

export default ConfirmDialog;
