// @flow
import * as React from "react";
import { Backdrop } from "@mui/material";
import type { Children } from "../../../reactTypes";
import BlurLayer from "./BlurLayer";

type Props = {
  open?: boolean,
  children: Children,
  ...React.ElementProps<typeof Backdrop>,
};

const BlurBackdrop: React.ComponentType<Props> = ({
  open,
  children,
  ...props
}) => (
  <Backdrop open={open} invisible {...props}>
    <BlurLayer>{children}</BlurLayer>
  </Backdrop>
);
export default BlurBackdrop;
