// @flow
import * as React from "react";
import News from "./News";
import type { NewsAlert } from "./News";
import moment from "moment";
import usePersistentState from "../../../hooks/usePersistentState";
import RouterLink from "../../lib/navigation/RouterLink";

const NEWS: NewsAlert[] = [
  {
    slug: "lorelai-version",
    title: "News.lorelaiVersion.title",
    body: "News.lorelaiVersion.body",
    bodyComponents: {
      contact: <RouterLink to="./contact" />,
    },
    expires: moment("2024-04-01T00:00:00.000Z"),
  },
];

console.log(NEWS[0].expires.toISOString());

const NewsContainer: React.ComponentType<{}> = () => {
  const now = moment();
  const [hiddenNews, setHiddenNews] = usePersistentState<{
    [NewsAlert["slug"]]: NewsAlert,
  }>("news", {});

  const news = NEWS.filter((n) => n.expires.isAfter(now)).filter(
    (n) => !hiddenNews[n.slug]
  );

  return (
    <News
      news={news}
      onHideNews={(n) => setHiddenNews({ ...hiddenNews, [n.slug]: true })}
    />
  );
};

export default NewsContainer;
