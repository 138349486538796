// @flow
import { useTranslation } from "react-i18next";

export type TFunction = (key: ?string, data?: Object) => string;

const useTranslate = (): TFunction => {
  const { t } = useTranslation();
  return t;
};

export default useTranslate;
