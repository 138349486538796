// @flow
import ajax from "../../lib/ajax.lib";
import type { ModelID } from "../../types";
import type { APIResult } from "./types";
import type { SubChannel } from "../../models/subChannel.model";
import type { MixedCreatorInfo } from "../../models/channels/tiktok.model";

export const requestSubchannelDetails = (
  subchannelId: ModelID,
  accountId: ModelID
): Promise<SubChannel> =>
  ajax
    .get<APIResult<SubChannel>>(
      `channels/subchannel/${subchannelId}?account_id=${accountId}`,
      // Disable errors on this one. Most probably it comes from an account
      // that needs to be refreshed and error messages are annoying.
      // Also, this feature is not critical.
      { disableErrorInterceptor: true }
    )
    .then((response) => response.data.data);

export const getTiktokCreatorInfo = (
  accountId: ModelID
): Promise<MixedCreatorInfo> =>
  ajax
    .get<APIResult<MixedCreatorInfo>>(
      `channels/tiktok/${accountId}/creator/info`,
      // Errors will be handled by the caller.
      { disableErrorInterceptor: true }
    )
    .then((response) => response.data.data);
