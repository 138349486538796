// @flow
import { MBYTE } from "../../lib/files.lib";
import type { Channel } from "./channel.model";
import moment from "moment/moment";
import type { Moment } from "../../types";
import {
  login,
  wasLoginCancelled,
} from "../../services/channels/googleMyBusiness.service";

export type ActionTypes =
  /** This post wants a user to book an appointment/table/etc. */
  | "BOOK"
  /** This post wants a user to order something. */
  | "ORDER"
  /** This post wants a user to browse a product catalog. */
  | "SHOP"
  /** This post wants a user to learn more (at their website) */
  | "LEARN_MORE"
  /** This post wants a user to register/sign up/join something. */
  | "SIGN_UP"
  /** This post wants a user to call the business. */
  | "CALL";

export type TopicTypes =
  /** Post contains basic information, like summary and images. */
  | "STANDARD"
  /** Post contains basic information and an event. */
  | "EVENT"
  /** Post contains basic information, an event and offer related content (e.g. coupon code) */
  | "OFFER";

export type CallToAction = {
  type: ActionTypes,
  url?: string,
};

export type Event = {
  title: string,
  schedule: {
    start: Moment,
    end: Moment,
  },
};

export type Offer = {
  coupon: string,
  url: string,
  terms: string,
};

type PostBase = {
  type: TopicTypes,
};

export type StandardPost = {
  ...PostBase,
  type: "STANDARD",
  cta: CallToAction,
};

export type EventPost = {
  ...PostBase,
  type: "EVENT",
  cta: CallToAction,
  event: Event,
};

export type OfferPost = {
  ...PostBase,
  type: "OFFER",
  event: Event,
  offer: Offer,
};

export type Post = StandardPost | EventPost | OfferPost;

export type GMBExtras = {
  topic: TopicTypes,
  cta: CallToAction,
  event: Event,
  offer: Offer,
};

const googleMyBusiness: Channel = {
  name: "Google My Business",
  slug: "gmb",
  color: "#77A6EF",

  features: {
    personnalAccount: true,
    businessAccount: true,
    liveEdit: {},
    analytics: {
      personal: true,
      post: false,
    },
  },

  requires: {
    subchannel: true,
  },

  contentSpecs: {
    maxChars: 1500,
    media: {
      image: {
        mimetypes: ["image/jpeg"],
        pngAsJpeg: true,
        maxCount: 1,
        maxSize: 5 * MBYTE,
      },
      video: {
        mimetypes: ["video/mp4", "video/x-m4v", "video/*"],
        maxCount: 1,
        maxSize: 75 * MBYTE,
      },
      overall: {
        maxCount: 1,
        oneMediaTypeOnly: true,
      },
    },
  },

  surfaces: [],
  subchannelTypes: [
    {
      slug: "gmb_location",
      fetchItemsURL: "channels/gmb/<account_id>/locations",
    },
  ],
  wasLoginCancelled,
  login,
};

export const getDefaultExtras = (): GMBExtras => ({
  topic: "STANDARD",
  cta: { url: "", type: "LEARN_MORE" },
  event: {
    title: "",
    schedule: {
      start: moment().add(1, "d"),
      end: moment().add(8, "d"),
    },
  },
  offer: { coupon: "", url: "", terms: "" },
});

export default googleMyBusiness;
