// @flow
import type { FindIteratee, ModelID, Percent, Timestamp } from "../types";
import { Moment } from "../types";
import { unixToMomentInterval } from "./base.model";
import moment from "moment";
import type { MomentInterval, UnixInterval } from "./base.model";
import meanBy from "lodash/meanBy";

export type AnalyticsMetric = {
  /**
   * The current value of the metric.
   */
  value: number | null,
  /**
   * The delta, in percentage compared to last time.
   */
  delta: Percent,
};

export type PostMetric =
  | "reach"
  | "comments"
  | "reactions"
  | "shares"
  | "video_views"
  | "impressions"
  | "engagements"
  | "link_clicks"
  | "count"
  | "taps_forward"
  | "taps_back"
  | "exits"
  | "saved";

export type EntityMetric =
  | "reach"
  | "followers"
  | "impressions"
  | "engagements"
  | "new_followers"
  | "lost_followers"
  | "all_time_post_count"
  | "followed_count"
  | "listed_count"
  | "text_message_clicks"
  | "website_clicks"
  | "get_directions_clicks"
  | "phone_call_clicks"
  | "email_contacts"
  | "pin_clicks"
  | "pin_saves"
  | "pin_outbound_clicks"
  | "queries_direct"
  | "queries_indirect"
  | "queries_chain"
  | "view_maps"
  | "view_search";

/** Daily analytics for the entity itself. */
export type EntityMetricsDailyCollection = {
  day: Timestamp,
  ...Record<EntityMetric, number>,
};

/** Aggregated analytics for the entity itself. */
export type EntityMetricsCollection = Record<EntityMetric, AnalyticsMetric>;

/** Aggregated analytics for all posts. */
export type PostMetricsCollection = Record<PostMetric, AnalyticsMetric>;

/** For a given post ID, the post analytics. */
export type PostBreakdown = Record<PostMetric, number>;

/** Post analytics by post ID. */
export type PostsBreakdown = { [ModelID]: PostBreakdown };

/**
 * An anallytics report for an account.
 */
export type AnalyticsReport = {
  previous_interval: MomentInterval,
  current_interval: MomentInterval,
  timestamp: Moment,
  started_at: Moment,
  entity_overview: EntityMetricsCollection,
  posts_overview: PostMetricsCollection,
  posts_breakdown: PostsBreakdown,
  account_id: ModelID,
  previous_entity_dailies: EntityMetricsDailyCollection[],
  current_entity_dailies: EntityMetricsDailyCollection[],
};

/**
 * Analytic reports keyed by their account_id
 */
export type KeyedAnalyticsReports = { [ModelID]: AnalyticsReport };

export type APIAnalyticsReport = {
  ...Omit<
    AnalyticsReport,
    "previous_interval" | "current_interval" | "timestamp" | "started_at"
  >,
  previous_interval: UnixInterval,
  current_interval: UnixInterval,
  timestamp: Timestamp,
  started_at: Timestamp,
};

export const isEntityMetricDefined = (
  collection: EntityMetricsCollection,
  metric: EntityMetric
): boolean => Number.isFinite(collection[metric].value);

export const isEntityMetricRelevant =
  (report: AnalyticsReport): FindIteratee<EntityMetric> =>
  (metric) =>
    isEntityMetricDefined(report.entity_overview, metric);

export const isPostMetricRelevant =
  (breakdown: PostsBreakdown): FindIteratee<PostMetric> =>
  (metric) =>
    !!Object.values(breakdown).find((postBreakdown) =>
      Number.isFinite(postBreakdown[metric])
    );

export const getDailyAverage = (
  dailies: EntityMetricsDailyCollection[],
  metric: EntityMetric
): number => meanBy(dailies, (d) => d[metric]);

export const fromAPIResult = (report: APIAnalyticsReport): AnalyticsReport => ({
  ...report,
  previous_interval: unixToMomentInterval(report.previous_interval),
  current_interval: unixToMomentInterval(report.current_interval),
  timestamp: moment.unix(report.timestamp),
  started_at: moment.unix(report.started_at),
});
