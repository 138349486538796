// @flow
import * as React from "react";
import type { DiagnosticError } from "../../util/diagnostics.util";
import compact from "lodash/compact";
import type { Channel } from "../../models/channels/channel.model";
import googleMyBusiness from "../../models/channels/googleMyBusiness.model";
import isEmpty from "lodash/isEmpty";
import { getGMBTopic } from "../../config/gmbTopics.config";
import PostBuilderContext from "../../contexts/postBuilder";
import tiktok from "../../models/channels/tiktok.model";

const EMPTY: Array<DiagnosticError> = [];

export const useContextlessContentDiagnostic = (
  channel: ?Channel,
  content: string,
  extras: ?Object
): DiagnosticError[] => {
  return React.useMemo(() => {
    if (!channel) {
      return EMPTY;
    }

    return compact([
      content.length > channel.contentSpecs.maxChars && "contentTooLong",
      !!extras &&
        channel === googleMyBusiness &&
        getGMBTopic(extras.topic)?.supportsCTA &&
        isEmpty(extras.cta.url) !== (extras.cta.type === "call") &&
        "invalidGMBCTA",
      !!extras &&
        channel === tiktok &&
        extras.commercialContent &&
        !extras.brand_content_toggle &&
        !extras.brand_organic_toggle &&
        "tiktokMissingCommercialContentDisclosure",
    ]);
  }, [content, channel, extras]);
};

const useContentDiagnostic = (channel: ?Channel): DiagnosticError[] => {
  const {
    post: { content, extras },
  } = React.useContext(PostBuilderContext);
  return useContextlessContentDiagnostic(channel, content, extras);
};

export default useContentDiagnostic;
