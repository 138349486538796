// @flow
import * as React from "react";

type SelectionState<V> = Set<V>;
export type Selection<V> = {
  add: (V) => void,
  extend: (items: V[]) => void,
  remove: (V) => void,
  clear: () => void,
  isSelected: (V) => boolean,
  setSelected: (V, boolean) => void,
  selection: $ReadOnlySet<V>,
  selectedCount: number,
};

export const useSelection = <V>(
  initial?: Iterable<V>,
  onChangeSelection?: ($ReadOnlySet<V>) => any
): Selection<V> => {
  const [selection, setSelection] = React.useState<SelectionState<V>>(
    new Set(initial || [])
  );

  const handleFireSelectionChange = React.useCallback(
    (set: Set<V>) => {
      onChangeSelection && onChangeSelection(set);
      return set;
    },
    [onChangeSelection]
  );

  return {
    isSelected: (item: V) => selection.has(item),
    add: React.useCallback(
      (item: V) =>
        setSelection((prev) =>
          handleFireSelectionChange(new Set([...prev, item]))
        ),
      [handleFireSelectionChange]
    ),
    extend: React.useCallback(
      (items: V[]) =>
        setSelection((prev) =>
          handleFireSelectionChange(new Set([...prev, ...items]))
        ),
      [handleFireSelectionChange]
    ),
    remove: React.useCallback(
      (item: V) =>
        setSelection((prev) => {
          const s = new Set(prev);
          s.delete(item);
          return handleFireSelectionChange(s);
        }),
      [handleFireSelectionChange]
    ),
    clear: React.useCallback(
      () => setSelection(handleFireSelectionChange(new Set())),
      [handleFireSelectionChange]
    ),
    setSelected: React.useCallback(
      (item: V, selected: boolean) =>
        setSelection((prev) => {
          const s = new Set(prev);
          if (selected) {
            s.add(item);
          } else {
            s.delete(item);
          }
          return handleFireSelectionChange(s);
        }),
      [handleFireSelectionChange]
    ),
    selection,
    selectedCount: selection.size,
  };
};
