// @flow
import * as React from "react";
import PostFiltersContext from "../contexts/postFilters";
import type { ModelID } from "../types";
import type { Post } from "../models/post.model";
import isEmpty from "lodash/isEmpty";
import intersection from "lodash/intersection";
import { usePosts } from "../store/selectors";
import { matchQuery } from "../lib/text.lib";
import { byPortfolioId } from "../models/base.model";
import { getTime } from "../models/post.model";

const useFilteredPosts = (portfolioId: ?ModelID): Post[] => {
  const { statuses, accountIds, search, fromDate, toDate, postIds } =
    React.useContext(PostFiltersContext);
  const posts_ = usePosts();

  return React.useMemo(() => {
    let posts = posts_.filter(byPortfolioId(portfolioId));
    if (!isEmpty(postIds)) {
      posts = posts.filter((post) => postIds.includes(post.id));
    }
    if (!isEmpty(statuses))
      posts = posts.filter((post) => statuses.includes(post.status));
    else {
      // Deleted posts are only visible if asked. They are not in the default view.
      posts = posts.filter((post) => post.status !== "deleted");
    }

    if (!isEmpty(accountIds)) {
      posts = posts.filter(
        (post) =>
          accountIds.includes(post.account_id) ||
          intersection(post.secondary_account_ids, accountIds).length > 0
      );
    }

    if (fromDate) {
      posts = posts.filter((post) => getTime(post)?.isSameOrAfter(fromDate));
    }

    if (toDate) {
      posts = posts.filter((post) => getTime(post)?.isSameOrBefore(toDate));
    }

    if (search?.length >= 2)
      posts = posts.filter((post) => matchQuery(post.content, search));

    return posts;
  }, [
    portfolioId,
    postIds,
    posts_,
    search,
    statuses,
    accountIds,
    fromDate,
    toDate,
  ]);
};

export default useFilteredPosts;
