// @flow
import * as React from "react";
import useTranslate from "../../hooks/useTranslate";
import ColoredChip from "../lib/display/ColoredChip";
import { Body2 } from "../lib/display/Text";
import { ReplayRounded } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import type { Periodicity } from "../../models/periodicity.model";
import { periodicityToString } from "../../models/periodicity.model";
import type { CSSProps } from "../../reactTypes";

type Props = {
  periodicity: Periodicity,
  ...CSSProps,
};

const PeriodicityChip: React.ComponentType<Props> = React.memo(
  ({ periodicity, ...props }) => {
    const t = useTranslate();
    return (
      <Tooltip
        title={t(`global.periodicity`, {
          quantity: periodicity.quantity,
          unit: t(`global.units.${periodicity.unit}`),
        })}
      >
        <ColoredChip paletteColor="periodicity" {...props}>
          <ReplayRounded fontSize="inherit" color="inherit" />
          <Body2>{periodicityToString(periodicity)}</Body2>
        </ColoredChip>
      </Tooltip>
    );
  }
);

export default PeriodicityChip;
