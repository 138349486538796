// @flow
import * as React from "react";
import type { BobcaatAccount } from "../../../models/account.model";
import type { Callback } from "../../../types";
import { useNavigate } from "react-router-dom";
import routes, { getRoutePath } from "../../../config/routes.config";
import { useStoreActions } from "../../../store/store";
import * as accountService from "../../../services/account.service";

export type AccountHandlers = {
  onViewPosts: Callback,
  onCreatePost: Callback,
  onViewPostsCalendar: Callback,
  onDisconnect: Callback,
};

const useAccountActions = (account: BobcaatAccount): AccountHandlers => {
  const portfolioId = account.portfolio_id;
  const accountId = account.id;
  const navigate = useNavigate();
  const actions = useStoreActions();

  return React.useMemo(
    () => ({
      onViewPosts: () =>
        navigate(
          getRoutePath(
            routes.app.nested.portfolios.nested.creator,
            { portfolioId },
            { accountId: accountId.toString() }
          )
        ),
      onCreatePost: () =>
        navigate(
          getRoutePath(
            routes.app.nested.portfolios.nested.addPost,
            { portfolioId },
            { accountId: accountId.toString() }
          )
        ),
      onViewPostsCalendar: () =>
        navigate(
          getRoutePath(
            routes.app.nested.portfolios.nested.calendar,
            { portfolioId },
            { accountId: accountId.toString() }
          )
        ),
      onDisconnect: () => {
        actions.post.posts.deleteAllPostsInAccount(accountId);
        accountService.deleteAccount(actions)(accountId);
      },
    }),
    [accountId, portfolioId, actions, navigate]
  );
};

export default useAccountActions;
