// @flow
import * as React from "react";
import { useLockState } from "./lib/lock.state";
import type { MappingActions, MappingState } from "./lib/mapping.reducer";
import {
  makeMappingActions,
  mappingReducer,
  useMappingReducer,
} from "./lib/mapping.reducer";
import type { PostComment } from "../../models/postComment.model";
import type { ModelID } from "../../types";
import type { BucketActions, BucketState } from "./lib/bucket.reducer";
import {
  bucketReducer,
  makeBucketActions,
  useBucketReducer,
} from "./lib/bucket.reducer";
import type { LockActions, LockState } from "./lib/lock.state";
import type { SocialActionSummary } from "../../models/socialActionSummary.model";

export type EngagementActions = {
  ...LockActions,
  comments: BucketActions<ModelID, PostComment>,
  summaries: MappingActions<SocialActionSummary>,
};

export type EngagementState = {
  ...LockState,
  comments: BucketState<ModelID, PostComment>,
  summaries: MappingState<SocialActionSummary>,
};

const commentsReducer = bucketReducer<ModelID, PostComment>({
  bucketKey: "post_id",
  key: "id",
});
const summariesReducer = mappingReducer<SocialActionSummary>({
  key: "post_id",
});

export const useEngagementReducer = (): [
  EngagementState,
  EngagementActions
] => {
  // Reducers first
  const [comments, commentsDispatch] = useBucketReducer(commentsReducer);
  const [summaries, summariesDispatch] = useMappingReducer(summariesReducer);
  const [locked, lockActions] = useLockState();

  // Then make actions
  const commentActions = React.useMemo(
    () => makeBucketActions(commentsDispatch),
    [commentsDispatch]
  );

  const summariesActions = React.useMemo(
    () => makeMappingActions(summariesDispatch),
    [summariesDispatch]
  );

  const actions = React.useMemo(
    () => ({
      comments: commentActions,
      summaries: summariesActions,
      ...lockActions,
    }),
    [commentActions, summariesActions, lockActions]
  );

  return [{ comments, summaries, locked }, actions];
};
