// @flow
import * as React from "react";
import PostsTable from "./PostsTable";
import type { ModelID } from "../../../types";
import * as postService from "../../../services/post.service";
import { useStoreActions } from "../../../store/store";
import type { Post } from "../../../models/post.model";
import useConfirmDialog from "../../lib/feedback/useConfirmDialog";
import type { Options as ConfirmDialogOptions } from "../../lib/feedback/withConfirmDialog";
import { byId } from "../../../models/base.model";
import { useNavigate } from "react-router-dom";
import routes, { getRoutePath } from "../../../config/routes.config";
import { useScopedPortfolio } from "../../../scopes/scopes";
import type { TableSelection } from "../../lib/display/DataTable/base";

type Props = {
  rows: Post[],
  loading?: boolean,
};

const doesSelectionContainDeletedRows =
  (rows: Post[]) =>
  (selection: ?TableSelection<ModelID>): boolean => {
    // Can't be called without selected IDs.
    const rowId = selection?.selection.values().next().value ?? 0;
    return rows.find(byId(rowId))?.status === "deleted";
  };

const getDeleteDialogProps =
  (rows: Post[]) =>
  (selection: ?TableSelection<ModelID>): ConfirmDialogOptions => {
    // Can't be called without selected IDs.
    return doesSelectionContainDeletedRows(rows)(selection)
      ? {
          DialogProps: {
            name: "delete-bin-posts",
            title: "SchedulingSection.PostCard.confirmDeleteFromBin",
            message: "SchedulingSection.PostCard.deletePostFromBin",
          },
        }
      : {
          DialogProps: {
            name: "delete-posts",
            title: "SchedulingSection.PostCard.confirmDelete",
            message: "SchedulingSection.PostCard.deletePost",
          },
        };
  };

const PostsTableContainer: React.ComponentType<Props> = ({ rows, loading }) => {
  const actions = useStoreActions();
  const navigate = useNavigate();
  const portfolio = useScopedPortfolio();

  const handleDelete = React.useCallback(
    (selection: TableSelection<ModelID>) => {
      const deleteFromBin = doesSelectionContainDeletedRows(rows)(selection);
      return Promise.all(
        [...selection.selection].map(
          deleteFromBin
            ? (id) => postService.deletePostPermanently(actions)(id)
            : (id) => postService.deletePost(actions)(id)
        )
      ).then(selection.clear);
    },
    [rows, actions]
  );

  const handleCancelDelete = React.useCallback(
    (selection: TableSelection<ModelID>) =>
      Promise.all(
        [...selection.selection].map((id) =>
          postService.cancelDeletePost(actions)(id)
        )
      ).then(selection.clear),
    [actions]
  );

  const handleMarkAsPublished = React.useMemo(
    () => postService.markPostAsPublished(actions),
    [actions]
  );

  const handleDuplicate = React.useMemo(
    () => postService.duplicatePost(actions),
    [actions]
  );

  const handleCreateApprovalRequest = React.useCallback(
    (postsIds: Iterable<ModelID>) =>
      navigate(
        getRoutePath(
          routes.app.nested.portfolios.nested.addApprovalRequest,
          { portfolioId: portfolio?.id },
          { postIds: [...postsIds].toString() }
        )
      ),
    [navigate, portfolio?.id]
  );

  const handleEdit = React.useCallback(
    (postId: ModelID) =>
      navigate(
        getRoutePath(routes.app.nested.portfolios.nested.post.nested.edit, {
          portfolioId: portfolio?.id,
          postId,
        })
      ),
    [navigate, portfolio?.id]
  );

  const [onDelete, deleteDlg] = useConfirmDialog(
    getDeleteDialogProps(rows),
    handleDelete
  );

  return (
    <>
      {deleteDlg}
      <PostsTable
        loading={loading}
        rows={rows}
        onDelete={onDelete}
        onCancelDelete={handleCancelDelete}
        onMarkAsPublished={handleMarkAsPublished}
        onCreateApprovalRequest={handleCreateApprovalRequest}
        onEdit={handleEdit}
        onDuplicate={handleDuplicate}
      />
    </>
  );
};

export default PostsTableContainer;
