// @flow
import * as React from "react";

const useIsVisible = (element: ?HTMLElement): boolean => {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    []
  );

  React.useEffect(() => {
    if (element) {
      observer.observe(element);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, [element, observer]);

  return isIntersecting;
};

export default useIsVisible;
