// @flow
import * as React from "react";
import { useScopedPortfolio } from "../../../scopes/scopes";
import PortfolioWidget from "../../../components/widgets/PortfolioWidget";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
const AddEditPortfolioPage: React.ComponentType<empty> = () => {
  const pf = useScopedPortfolio();
  return (
    <PrimaryPagePanel
      route={
        pf
          ? routes.app.nested.portfolios.nested.settings
          : routes.app.nested.addPortfolio
      }
    >
      <PortfolioWidget portfolio={pf} />
    </PrimaryPagePanel>
  );
};

export default AddEditPortfolioPage;
