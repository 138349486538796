// @flow
import * as React from "react";
import type {
  SelfReply,
  SelfReplyAttachment,
} from "../../../../../models/post.model";
import type { Channel } from "../../../../../models/channels/channel.model";
import { useContextlessContentDiagnostic } from "../../../../../hooks/postCreator/useContentDiagnostic";
import { useContextlessAttachmentsDiagnostic } from "../../../../../hooks/postCreator/useAttachmentsDiagnostic";
import {
  isAttachmentImage,
  isAttachmentVideo,
} from "../../../../../models/attachment.model";
import RichContentInput from "../../../../inputs/RichContentInput";
import { getAccepts, getMaxSize } from "../../../../../models/channels";
import MediaCollection from "../../../../lib/inputs/MediaInput/MediaCollection";
import useAttachmentsToMediaItemsAdapter from "../../useAttachmentsToMediaItemsAdapter";
import useInputCharLimitHelper from "../../../../../hooks/useInputCharLimitHelper";
import DiagnosticErrors from "../../../../display/DiagnosticErrors";
import useHeadlessMediaInput from "../../../../lib/inputs/MediaInput/useHeadlessMediaInput";
import useTranslate from "../../../../../hooks/useTranslate";

type Props = {
  idx: number,
  selfReply: SelfReply,
  channel: Channel,
  onChangeReplyContent: (idx: number, content: string) => any,
  onChangeReplyAttachments: (
    idx: number,
    attachments: SelfReplyAttachment[]
  ) => any,
};

const SelfReplyInput: React.ComponentType<Props> = ({
  idx,
  selfReply,
  onChangeReplyContent,
  onChangeReplyAttachments,
  channel,
}) => {
  const selfReplyContent = selfReply.content ?? "";
  const t = useTranslate();
  const contentDiagnostics = useContextlessContentDiagnostic(
    channel,
    selfReplyContent
  );
  const attachmentDiagnostics = useContextlessAttachmentsDiagnostic(
    channel,
    selfReply.attachments.filter(isAttachmentVideo),
    selfReply.attachments.filter(isAttachmentImage)
  );
  const handleChangeReplyContent = React.useCallback(
    (newContent: string) => onChangeReplyContent(idx, newContent),
    [idx, onChangeReplyContent]
  );
  const handleChangeReplyAttachments = React.useCallback(
    (newAttachments: SelfReplyAttachment[]) =>
      onChangeReplyAttachments(idx, newAttachments),
    [idx, onChangeReplyAttachments]
  );

  const [onChangeContent, helperFactory, isError] = useInputCharLimitHelper({
    maxLength: channel.contentSpecs.maxChars,
    onChange: handleChangeReplyContent,
  });

  const { mediaItems, onChangeMedia, onMediaAdded } =
    useAttachmentsToMediaItemsAdapter(
      selfReply.attachments,
      handleChangeReplyAttachments
    );

  // Shortened up version of mediaInputProps for the crop dialog only.
  const { cropImageDialog, onSetEditedItem } = useHeadlessMediaInput({
    items: mediaItems,
    onChange: onChangeMedia,
    onMediaAdded,
  });

  return (
    <>
      {cropImageDialog}
      <RichContentInput
        mediaInputProps={{
          items: mediaItems,
          onChange: onChangeMedia,
          onMediaAdded,
          maxItems: channel?.contentSpecs.media.overall.maxCount ?? 5,
          accept: getAccepts(channel),
          maxSize: getMaxSize(channel),
          forceImagesAsJpeg: !!channel?.contentSpecs.media.image?.pngAsJpeg,
          disabled: !channel,
        }}
        fieldProps={{
          placeholder: t("PostCreator.contentPlaceholder"),
          value: selfReplyContent,
          onChange: (e) => onChangeContent(e.target.value),
          error: isError(selfReplyContent),
          helperText: helperFactory(selfReplyContent),
          multiline: true,
          minRows: 4,
          maxRows: 8,
        }}
        onInsertEmoji={onChangeContent}
        onInsertHashtagGroup={onChangeContent}
      />
      <MediaCollection
        items={mediaItems}
        onChange={onChangeMedia}
        onSetEditedItem={onSetEditedItem}
      />
      <DiagnosticErrors
        errors={[...contentDiagnostics, ...attachmentDiagnostics]}
        channel={channel}
      />
    </>
  );
};

export default SelfReplyInput;
