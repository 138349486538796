// @flow
import * as React from "react";
import DashboardPage from "./DashboardPage";
import { useScopedPortfolio } from "../../../scopes/scopes";
import {
  useCurrentUser,
  usePortfolioApprovalRequests,
  usePortfolioPosts,
  usePostsInitialised,
} from "../../../store/selectors";

const DashboardPageContainer: React.ComponentType<{}> = () => {
  const pf = useScopedPortfolio();
  const user = useCurrentUser();
  const posts = usePortfolioPosts(pf?.id, true);
  const approvalRequests = usePortfolioApprovalRequests(pf?.id);
  const postsInitialised = usePostsInitialised();

  if (!pf || !user) return null;

  return (
    <DashboardPage
      posts={posts}
      user={user}
      postsInitialised={postsInitialised}
      portfolio={pf}
      approvalRequests={approvalRequests}
    />
  );
};

export default DashboardPageContainer;
