// @flow
import * as React from "react";
import type { Slug } from "../../../../types";
import type { StickerProps } from "../Sticker";
import { RowStack } from "../../layout/stacks";
import take from "lodash/take";
import ChannelIcon from "./ChannelIcon";
import TextSticker from "../TextSticker";

type SizeVariant = StickerProps["size"];

type Props = {
  channels: Slug[],
  size?: SizeVariant,
  limit?: number,
};

const MultiChannelIcon: React.ComponentType<Props> = React.memo(
  ({ channels, limit, size }) => {
    const displayedChannels = take(channels, limit);
    const remainingCount = channels.length - displayedChannels.length;
    return (
      <RowStack spacing={-1} display="inline-flex">
        {displayedChannels.map((channel, index) => (
          <ChannelIcon
            channel={channel}
            key={channel}
            size={size}
            color="channel-alt"
            style={{ zIndex: displayedChannels.length - index }}
          />
        ))}
        {remainingCount > 0 && (
          <TextSticker size={size} style={{ zIndex: 0 }}>
            +{remainingCount}
          </TextSticker>
        )}
      </RowStack>
    );
  }
);

export default MultiChannelIcon;
