// @flow
import * as React from "react";
import { NavigationListItem } from "../../lib/display/listItems";
import type { ModelID } from "../../../types";
import type { Post } from "../../../models/post.model";
import { getPostSnippet } from "../../../models/post.model";
import { AutoSizeList } from "../../lib/layout/FixedSizeList";
import { ListAvatarPostMediaThumbnail } from "../../display/PostMediaThumbnail";
import PageRootOutlet from "../../layout/PageRootOutlet";
import type { InitStatus } from "../../../store/reducers/lib/init.state";
import { InlineRowStack } from "../../lib/layout/stacks";
import CommentsChip from "../../display/CommentsChip";
import type { SocialActionSummary } from "../../../models/socialActionSummary.model";
import moment from "moment";
import useTranslate from "../../../hooks/useTranslate";

type Props = {
  posts: Post[],
  selectedPostId: ?ModelID,
  summaries: { [ModelID]: SocialActionSummary },
  onSelectPost: (ModelID) => any,
  postsInitialised: InitStatus,
};

type PostItemProps = {
  item: Post,
  style: Object,
  selectedPostId: ?ModelID,
  summaries: { [ModelID]: SocialActionSummary },
  onSelectPost: (ModelID) => any,
};

const PostItem: React.ComponentType<PostItemProps> = ({
  item,
  selectedPostId,
  summaries,
  onSelectPost,
  style,
}) => {
  const summary = summaries[item.id];
  const unread = summary?.unread_comments ?? 0;
  const count = summary?.comments_count ?? 0;
  const prefix = unread ? `(${unread}) ` : "";
  const latest_comment_at = summary?.latest_comment_at;
  const t = useTranslate();
  return (
    <NavigationListItem
      style={style}
      selected={selectedPostId === item.id}
      onClick={() => onSelectPost(item.id)}
      avatar={<ListAvatarPostMediaThumbnail post={item} adorned={false} />}
      primary={prefix + getPostSnippet(item) || <>&nbsp;</>}
      secondary={
        <InlineRowStack>
          <CommentsChip count={count} />
          {latest_comment_at
            ? moment.unix(latest_comment_at).fromNow()
            : t("EngagementHubSection.CommentsPreview.noCommentsYet")}
        </InlineRowStack>
      }
      important={!!unread}
    />
  );
};

const EngagementHubPosts: React.ComponentType<Props> = ({
  posts,
  postsInitialised,
  ...itemProps
}) => {
  return (
    <>
      <AutoSizeList
        itemSize={61}
        items={posts}
        ItemComponent={PostItem}
        itemProps={itemProps}
        itemsInitStatus={postsInitialised}
      />
      <PageRootOutlet />
    </>
  );
};

export default EngagementHubPosts;
