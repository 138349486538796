// @flow
import * as React from "react";
import type { I18nKey } from "../../../types";
import { styled } from "@mui/material/styles";
import { Fade, Link } from "@mui/material";
import Logo from "../../display/Logo";
import { Body1, Body2, Header, Title } from "../../lib/display/Text";
import { ColumnStack, RowStack } from "../../lib/layout/stacks";
import FormTextInput from "../../lib/inputs/form/FormTextInput";
import HTMLForm from "../../lib/inputs/form/HTMLForm";
import type { Form, StringFormFieldDef } from "../../../hooks/useForm";
import { PrimaryTextButton } from "../../lib/inputs/buttons";
import * as links from "../../../util/links.util";
import useTranslate from "../../../hooks/useTranslate";
import { FormActions } from "../../../hooks/useForm";

export type LoginFormDef = {
  username: StringFormFieldDef,
  password: StringFormFieldDef,
};

export type Props = {
  title?: React.Node,
  prompt?: React.Node,
  nousername?: boolean,
  noSignupInvite?: boolean,
  onConfirm: () => any,
  loading: boolean,
  onResetPassword?: (username: string) => any,
  confirmMessageId?: I18nKey,
  usernameType?: "email" | "password",
  form: Form<LoginFormDef>,
};

const Root = styled("div")(
  ({ theme }) => `
  max-width: 450px;
  padding: ${theme.spacing(2)};
`
);

const InviteTitle = styled(Title)(
  ({ theme }) => `
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(2)};
`
);

const SignupInviteBox = styled("div")(
  ({ theme }) => `
  padding-top: ${theme.spacing(4)};
`
);

const LoginWidget: React.ComponentType<Props> = ({
  onConfirm,
  prompt,
  nousername,
  noSignupInvite,
  onResetPassword,
  title,
  loading,
  confirmMessageId = "global.login",
  usernameType = "email",
  form,
}) => {
  const t = useTranslate();

  return (
    <Fade in timeout={500}>
      <Root>
        <RowStack alignItems="center">
          <Logo size={48} />
          <Header>bobcaat</Header>
        </RowStack>
        <InviteTitle>{title ?? t("LoginPage.title")}</InviteTitle>
        <Body2 color="textSecondary" paragraph>
          {prompt}
        </Body2>
        <HTMLForm
          onSubmit={onConfirm}
          alignButtons="center"
          submitTextId={confirmMessageId}
          loading={loading}
        >
          <ColumnStack>
            {!nousername && (
              <FormTextInput
                autoFocus
                required
                name={
                  usernameType === "password" ? "current-password" : "email"
                }
                type={usernameType}
                autoComplete={
                  usernameType === "password" ? "current-password" : "email"
                }
                formField={form.state.username}
                onChange={FormActions.onChangeField(form.set, "username")}
                label={t("global." + usernameType)}
              />
            )}
            <FormTextInput
              required
              type="password"
              formField={form.state.password}
              onChange={FormActions.onChangeField(form.set, "password")}
              autoComplete={
                usernameType === "password"
                  ? "new-password"
                  : "current-password"
              }
              name={
                usernameType === "password"
                  ? "new-password"
                  : "current-password"
              }
              label={t(
                usernameType === "password"
                  ? "global.confirmPassword"
                  : "global.password"
              )}
            />
            {onResetPassword && (
              <PrimaryTextButton
                color="secondary"
                onClick={onResetPassword}
                disabled={form.state.username.value === ""}
              >
                {t("global.resetPassword")}
              </PrimaryTextButton>
            )}
          </ColumnStack>
        </HTMLForm>
        {!noSignupInvite && (
          <SignupInviteBox>
            <Body1>
              {t("LoginPage.noAccountYet")}
              &nbsp;
              <Link href={links.signup} color="secondary">
                {t("LoginPage.signUpHere")}
              </Link>
            </Body1>
          </SignupInviteBox>
        )}
      </Root>
    </Fade>
  );
};
export default LoginWidget;
