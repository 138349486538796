// @flow
import * as React from "react";
import VizGridItem from "./VizGridItem";
import type { Post } from "../../../../../models/post.model";
import useConfirmDialog from "../../../../lib/feedback/useConfirmDialog";
import * as postService from "../../../../../services/post.service";
import { useStoreActions } from "../../../../../store/store";
import useRedirectToEditPost from "../../../../../hooks/useRedirectToEditPost";
import type { CSSProps } from "../../../../../reactTypes";
import { isPublished } from "../../../../../models/post.model";
import noop from "lodash/noop";

type Props = {
  post: Post,
  ...CSSProps,
};

const VizGridItemContainer: React.ComponentType<Props> = ({
  post,
  ...props
}) => {
  const actions = useStoreActions();
  const handleEditPost = useRedirectToEditPost(post.portfolio_id);

  const handleDeletePost = React.useCallback(
    () => postService.deletePost(actions)(post.id),
    [actions, post.id]
  );

  const [onDelete, deleteDlg] = useConfirmDialog(
    {
      DialogProps: {
        name: "delete-posts",
        title: "SchedulingSection.PostCard.confirmDelete",
        message: "SchedulingSection.PostCard.deletePost",
      },
    },
    handleDeletePost
  );

  return (
    <>
      {deleteDlg}
      <VizGridItem
        post={post}
        onDelete={onDelete}
        onDoubleClick={isPublished(post) ? noop : () => handleEditPost(post.id)}
        {...props}
      />
    </>
  );
};

export default VizGridItemContainer;
