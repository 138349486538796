// @flow
import ajax from "../../lib/ajax.lib";
import type {
  APISubscription,
  CardDetails,
  SetupIntent,
  Subscription,
} from "../../models/subscription.model";
import { fromAPIResult } from "../../models/subscription.model";
import type { APIOKResult, APIResult } from "./types";
import type { PlanFlavour } from "../../models/plan.model";
import type { ModelID } from "../../types";
import type { AddOn } from "../../models/addOn.model";
import type { LimitSet } from "../../models/limitSet.model";
import type { PromotionCode } from "../../models/promotionCode.model";
import type { TransientBillingInfo } from "../../models/billingInfo.model";
import isEmpty from "lodash/isEmpty";

export type FreezeUnfreezeParams = {
  frozen: boolean,
  reason?: string,
};

export const getSubscription = (): Promise<?Subscription> =>
  ajax
    .get<APIResult<APISubscription>>("/subscription")
    .then((response) => fromAPIResult(response.data.data));

export const getPlanFlavours = (): Promise<PlanFlavour[]> =>
  ajax
    .get<APIResult<PlanFlavour[]>>("/subscription/plan_flavours")
    .then((response) => response.data.data);

export const updatePlan = (planFlavourId: ModelID): Promise<Subscription> =>
  ajax
    .put<{ plan_flavour_id: ModelID }, APIResult<APISubscription>>(
      "/subscription/plan",
      { plan_flavour_id: planFlavourId }
    )
    .then((response) => fromAPIResult(response.data.data));

export const freezeSubscription = ({
  reason,
}: {
  reason: string,
}): Promise<Subscription> =>
  ajax
    .put<FreezeUnfreezeParams, APIResult<APISubscription>>(
      "/subscription/freeze",
      { frozen: true, reason }
    )
    .then((response) => fromAPIResult(response.data.data));

export const unfreezeSubscription = (): Promise<Subscription> =>
  ajax
    .put<FreezeUnfreezeParams, APIResult<APISubscription>>(
      "/subscription/freeze",
      { frozen: false }
    )
    .then((response) => fromAPIResult(response.data.data));

export const applyPromoCode = (code: string): Promise<any> =>
  ajax.post<{ code: string }, APIOKResult>("/subscription/promocode", { code });

export const updateInvoicingPreferences = (
  sendInvoices: boolean
): Promise<Subscription> =>
  ajax
    .put<{ send_invoices: boolean }, APIResult<APISubscription>>(
      "/subscription/invoicing",
      { send_invoices: sendInvoices }
    )
    .then((response) => fromAPIResult(response.data.data));

export const updateBillingInfo = (
  billingInfo: TransientBillingInfo
): Promise<Subscription> =>
  ajax
    .put<TransientBillingInfo, APIResult<APISubscription>>(
      "/subscription/billing",
      billingInfo
    )
    .then((response) => fromAPIResult(response.data.data));

export const loadAllAddOns = (): Promise<AddOn[]> =>
  ajax
    .get<APIResult<AddOn[]>>("/subscription/addons")
    .then((response) => response.data.data);

export const purchaseAddOn = (pricingId: ModelID): Promise<LimitSet> =>
  ajax
    .post<void, APIResult<LimitSet>>(
      `/subscription/addons/${pricingId}/purchase`
    )
    .then((response) => response.data.data);

export const cancelAddOn = (pricingId: ModelID): Promise<LimitSet> =>
  ajax
    .post<void, APIResult<LimitSet>>(`/subscription/addons/${pricingId}/cancel`)
    .then((response) => response.data.data);

export const listAppliedPromocodes = (): Promise<PromotionCode[]> =>
  ajax
    .get<APIResult<PromotionCode[]>>("/subscription/promocodes")
    .then((response) => response.data.data);

export const setupCheckout = (): Promise<SetupIntent> =>
  ajax
    .get<APIResult<SetupIntent>>("/subscription/checkout/setup")
    .then((response) => response.data.data);

export const getCurrentCardDetails = (): Promise<?CardDetails> =>
  ajax
    .get<APIResult<CardDetails>>("/subscription/payment_method")
    .then((response) =>
      isEmpty(response.data.data) ? null : response.data.data
    );
