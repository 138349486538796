// @flow
import * as React from "react";
import ChannelIcon from "../../components/lib/display/icons/ChannelIcon";
import { styled } from "@mui/material/styles";
import {
  InlineRowStack,
  ColumnStack,
  RowStack,
} from "../../components/lib/layout/stacks";
import TextSticker from "../../components/lib/display/TextSticker";
import MultiChannelIcon from "../../components/lib/display/icons/MultiChannelIcon";
import StatusChip from "../../components/lib/display/StatusChip";
import {
  Body1,
  Body2,
  Caption,
  Header,
  Jumbo,
  Subheader,
  Subtitle,
  Title,
} from "../../components/lib/display/Text";
import PostApprovalStatusChip from "../../components/display/PostApprovalStatusChip";
import * as fixtures from "./fixtures";
import { PortfolioAvatar, UserAvatar } from "../../components/display/avatars";
import omit from "lodash/omit";
import IconAvatar from "../../components/lib/display/icons/IconAvatar";
import { Dashboard } from "@mui/icons-material";
import {
  DeleteButton,
  LargeDeleteButton,
  LargeNeutralButton,
  LargePrimaryButton,
  NeutralButton,
  PrimaryButton,
  SecondaryButton,
  SmallPrimaryButton,
  SmallSecondaryButton,
  PrimaryTextButton,
  NeutralTextButton,
  LargeTextButton,
} from "../../components/lib/inputs/buttons";
import RateTheAppDialog from "../../components/feedback/RateTheAppDialog";
import NewVersionAlert from "../AppPage/NewVersionAlert";
import { useStoreActions } from "../../store/store";
import * as alerts from "../../models/alerts.model";
import ModalAlert from "../AppPage/ModalAlert";
import PostStatusChip from "../../components/display/PostStatusChip";
import moment from "moment";
import ContentTypeChip from "../../components/display/ContentTypeChip";
import ThreadsChip from "../../components/display/ThreadsChip";
import FirstCommentChip from "../../components/display/FirstCommentChip";
import PollChip from "../../components/display/PollChip";
import PeriodicityChip from "../../components/display/PeriodicityChip";

const Root = styled("div")`
  padding: 4px;
`;

type DialogButtonProps = {
  label: string,
  children: (open: boolean, onClose: () => void) => React.Node,
};

const DialogButton = ({ label, children }: DialogButtonProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <>
      <NeutralButton onClick={() => setOpen(true)}>{label}</NeutralButton>
      {children(open, () => setOpen(false))}
    </>
  );
};

const StyleGuidePage: React.ComponentType<{}> = () => {
  const storeActions = useStoreActions();
  return (
    <Root>
      <ColumnStack>
        <RowStack>
          <ChannelIcon channel="facebook" color="channel" />
          <ChannelIcon channel="facebook" color="channel-alt" />
          <ChannelIcon channel="instagram" size="small" color="channel" />
          <ChannelIcon channel="instagram" size="small" color="channel-alt" />
          <ChannelIcon channel="twitter" color="channel" />
          <ChannelIcon channel="twitter" color="channel-alt" />
          <ChannelIcon channel="linkedin" color="channel" />
          <ChannelIcon channel="linkedin" color="channel-alt" />
        </RowStack>
        <RowStack>
          <TextSticker>+1</TextSticker>
          <TextSticker size="small">+10</TextSticker>
          <TextSticker size="medium" variant="rounded">
            +3
          </TextSticker>
        </RowStack>
        <div>
          <MultiChannelIcon
            channels={["facebook", "linkedin", "twitter", "pinterest"]}
            limit={3}
          />
        </div>
        <RowStack>
          <StatusChip severity="info">Info</StatusChip>
          <StatusChip severity="warning">Warning</StatusChip>
          <StatusChip severity="error">Error</StatusChip>
          <StatusChip severity="success">Success</StatusChip>
        </RowStack>
        <RowStack>
          <PostApprovalStatusChip status="approved" />
          <PostApprovalStatusChip status="pending" />
          <PostApprovalStatusChip status="edits_required" />
          <PostApprovalStatusChip status="rejected" />
        </RowStack>
        <RowStack>
          <PostStatusChip postId={0} channel="facebook" status="draft" />
          <PostStatusChip
            postId={0}
            channel="facebook"
            status="scheduled"
            time={moment()}
          />
          <PostStatusChip
            postId={0}
            channel="facebook"
            status="deleted"
            time={moment()}
          />
          <PostStatusChip
            postId={0}
            channel="facebook"
            status="published"
            time={moment()}
          />
        </RowStack>
        <RowStack>
          <ContentTypeChip type="post" />
          <ContentTypeChip type="reel" />
          <ContentTypeChip type="story" />
          <ThreadsChip count={3} />
          <PeriodicityChip periodicity={{ unit: "d", quantity: 30 }} />
          <FirstCommentChip />
          <PollChip />
        </RowStack>
        <RowStack>
          <UserAvatar user={fixtures.user} />
          <UserAvatar user={omit(fixtures.user, "avatar")} />
          <PortfolioAvatar portfolio={fixtures.portfolio} />
          <PortfolioAvatar portfolio={omit(fixtures.portfolio, "avatar")} />
          <IconAvatar Icon={Dashboard} color="secondary" />
        </RowStack>
        <InlineRowStack>
          <SmallPrimaryButton>SmallPrimaryButton</SmallPrimaryButton>
          <SmallSecondaryButton>SmallSecondaryButton</SmallSecondaryButton>
        </InlineRowStack>
        <InlineRowStack>
          <PrimaryButton>PrimaryButton</PrimaryButton>
          <SecondaryButton>SecondaryButton</SecondaryButton>
          <NeutralButton>NeutralButton</NeutralButton>
          <DeleteButton>DeleteButton</DeleteButton>
          <PrimaryTextButton>PrimaryTextButton</PrimaryTextButton>
          <NeutralTextButton>NeutralTextButton</NeutralTextButton>
        </InlineRowStack>
        <InlineRowStack>
          <LargePrimaryButton>LargePrimaryButton</LargePrimaryButton>
          <LargeDeleteButton>LargeDeleteButton</LargeDeleteButton>
          <LargeNeutralButton>LargeNeutralButton</LargeNeutralButton>
          <LargeTextButton>LargeTextButton</LargeTextButton>
        </InlineRowStack>
        <ColumnStack>
          <Caption>Caption</Caption>
          <Body2>Body2</Body2>
          <Body1>Body1</Body1>
          <Subheader>Subheader</Subheader>
          <Header>Header</Header>
          <Subtitle>Subtitle</Subtitle>
          <Title>Title</Title>
          <Jumbo>Jumbo</Jumbo>
        </ColumnStack>
      </ColumnStack>
      <InlineRowStack>
        <DialogButton label="Rate the App">
          {(open, onClose) => (
            <RateTheAppDialog forceOpen={open} onCancelOrSubmit={onClose} />
          )}
        </DialogButton>
        <DialogButton label="New version">
          {(open, onClose) => (
            <NewVersionAlert
              version={open ? "9.9.9." + (Date.now() % 10) : "1.1.1.0"}
              titleId="Page.newVersionDialog.title"
              yesId="Page.newVersionDialog.yes"
              messageId="Page.newVersionDialog.message"
              onClose={onClose}
            />
          )}
        </DialogButton>
        <NeutralButton
          onClick={() =>
            storeActions.global.openModalAlert(
              alerts.modalLocalSuccess({
                title: `store.post.published.title`,
                message: `store.post.published.message`,
              })
            )
          }
        >
          Post Published Alert
        </NeutralButton>
        <NeutralButton
          onClick={() =>
            storeActions.global.openModalAlert(
              alerts.modalLocalWarning({
                title: "DashboardSection.AccountCard.missingPermissions.title",
                message:
                  "DashboardSection.AccountCard.missingPermissions.message",
                messageData: { channel: "linkedin" },
              })
            )
          }
        >
          Warning Oauth denied alert
        </NeutralButton>
        <ModalAlert />
      </InlineRowStack>
    </Root>
  );
};

export default StyleGuidePage;
