// @flow
import * as React from "react";
import HashtagGroupSelector from "./HashtagGroupSelector";
import type { HashtagGroup } from "../../../models/hashtagGroup.model";
import { filterHashtagGroups } from "../../../models/hashtagGroup.model";
import { useHashtagGroups } from "../../../store/selectors";
import type { ModelID, Slug } from "../../../types";

const HashtagGroupSelectorContainer: React.ComponentType<{
  onSelect: (HashtagGroup) => any,
  channel?: ?Slug,
  portfolioId?: ?ModelID,
}> = React.forwardRef(({ onSelect, portfolioId, channel }, ref) => {
  const [search, setSearch] = React.useState<string>("");
  const hashtagGroups = filterHashtagGroups(useHashtagGroups(), {
    portfolioId,
    channel,
    search,
  });
  return (
    <HashtagGroupSelector
      ref={ref}
      hashtagGroups={hashtagGroups}
      onSelect={onSelect}
      onChangeSearch={setSearch}
    />
  );
});

export default HashtagGroupSelectorContainer;
