// @flow
/**
 * The different types of content (surfaces) of a post.
 */
export type ContentTypes = "post" | "story" | "reel";

/**
 * Whether the content type supports disabling autopublish.
 * @param contentType
 */
export const canDisableAutoPublish = (contentType: ContentTypes): boolean =>
  ["story", "reel"].includes(contentType);
