// @flow
import ajax from "../../lib/ajax.lib";
import type { APINoContentResult, APIResult } from "./types";
import type {
  APIBobcaatAccount,
  BobcaatAccount,
  BobcaatAccountPublicInfo,
} from "../../models/account.model";
import { fromAPIResult } from "../../models/account.model";
import type { ModelID, Timestamp } from "../../types";
import omit from "lodash/omit";

type AddEditAccountParamsBase = {
  ...Omit<
    BobcaatAccount,
    "parent_entity_id" | "is_organisation" | "status_code"
  >,
  access_token: string,
  user_access_token: string,
  refresh_token: string,
  access_token_expiry: Timestamp,
  refresh_token_expiry: Timestamp,
};

export type AddAccountParams = {
  ...Omit<AddEditAccountParamsBase, "id">,
  access_token_secret: string,
};

export type EditAccountParams = Omit<
  AddEditAccountParamsBase,
  "portfolio_id" | "channel" | "user_id"
>;

export type MoveAccountParams = Pick<BobcaatAccount, "id" | "portfolio_id">;

export const addAccount = (
  account: AddAccountParams
): Promise<BobcaatAccount> =>
  ajax
    .post<AddAccountParams, APIResult<APIBobcaatAccount>>(`/account`, account)
    .then((resp) => fromAPIResult(resp.data.data));

export const editAccount = (
  account: EditAccountParams
): Promise<BobcaatAccount> =>
  ajax
    .put<EditAccountParams, APIResult<APIBobcaatAccount>>(
      `/account/${account.id}`,
      omit(account, "id")
    )
    .then((resp) => fromAPIResult(resp.data.data));

export const moveAccount = (
  params: MoveAccountParams
): Promise<BobcaatAccount> =>
  ajax
    .post<EditAccountParams, APIResult<APIBobcaatAccount>>(
      `/account/${params.id}/move`,
      omit(params, "id")
    )
    .then((resp) => fromAPIResult(resp.data.data));

export const searchAccountsPublicInfo = (
  accountIds: ModelID[]
): Promise<BobcaatAccountPublicInfo[]> =>
  ajax
    .get<APIResult<BobcaatAccountPublicInfo[]>>(
      `/account/public/search?ids=${accountIds.toString()}`
    )
    .then((resp) => resp.data.data);

export const getPortfolioAccounts = (
  portfolioId: ModelID
): Promise<BobcaatAccount[]> =>
  ajax
    .get<APIResult<APIBobcaatAccount[]>>(`/account/${portfolioId}`)
    .then((resp) => resp.data.data.map(fromAPIResult));

export const deleteAccount = (
  accountId: ModelID
): Promise<APINoContentResult> =>
  ajax.delete<APINoContentResult>(`/account/${accountId}`);
