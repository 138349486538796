// @flow
import * as React from "react";
import type { Dispatcher, KeyBase, Reducer } from "./types";
import type { FindIteratee } from "../../../types";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";

export type MappingState<TItem> = { [KeyBase]: TItem };
type InsertAction<TItem> = { type: "INSERT", data: TItem };
type ExtendAction<TItem> = { type: "EXTEND", data: TItem[] };
type ReplaceAllAction<TItem> = { type: "REPLACE_ALL", data: TItem[] };
type RemoveAction = { type: "REMOVE", data: mixed };
type PatchAction<TItem> = {
  type: "PATCH",
  data: { key: KeyBase, updates: Partial<TItem> },
};
type RemoveByAction<TItem> = { type: "REMOVE_BY", data: FindIteratee<TItem> };
type ClearAction = { type: "CLEAR" };

/**
 * Actions supported by a Mapping reducer.
 */
export type MappingAction<TItem> =
  | InsertAction<TItem>
  | ExtendAction<TItem>
  | ReplaceAllAction<TItem>
  | RemoveAction
  | RemoveByAction<TItem>
  | PatchAction<TItem>
  | ClearAction;

export type MappingActions<TItem> = {
  /**
   * Insert or replace an item in the mapping.
   * @param item
   */
  insert: (item: TItem) => void,
  /**
   * Update an item partially, using its key.
   * @param item
   */
  patch: (key: KeyBase, updates: Partial<TItem>) => void,
  /**
   * Remove all items from the mapping.
   */
  clear: () => void,
  /**
   * Add items to the mapping.
   * @param items
   */
  extend: (items: TItem[]) => void,
  /**
   * Remove the item whose key is provided, using the reducer key config.
   * @param key
   */
  remove: (key: KeyBase) => void,
  /**
   * Remove all items that satisfy the provided condition.
   * @param cond
   */
  removeBy: (cond: FindIteratee<TItem>) => void,
  /**
   * Replace the entire content of the mapping with the provided items.
   * @param items
   */
  replaceAll: (items: TItem[]) => void,
};

/**
 * Factories to create Mapping actions.
 *
 * @template TItem The type of the items in the mapping.
 */
export const makeMappingActions = <TItem: Object>(
  dispatch: (MappingAction<TItem>) => void
): MappingActions<TItem> => ({
  insert: (item: TItem) => dispatch({ type: "INSERT", data: item }),
  patch: (key: KeyBase, updates: Partial<TItem>) =>
    dispatch({ type: "PATCH", data: { key, updates } }),
  extend: (items: TItem[]) => dispatch({ type: "EXTEND", data: items }),
  remove: (key: KeyBase) => dispatch({ type: "REMOVE", data: key }),
  removeBy: (cond: FindIteratee<TItem>) =>
    dispatch({ type: "REMOVE_BY", data: cond }),
  replaceAll: (items: TItem[]) =>
    dispatch({ type: "REPLACE_ALL", data: items }),
  clear: () => dispatch({ type: "CLEAR" }),
});

interface Props<TItem> {
  key: $Keys<TItem>;
  transform?: (item: TItem) => TItem;
}

export type MappingReducer<TItem> = Reducer<
  MappingState<TItem>,
  MappingAction<TItem>
>;

/**
 * Create a mapping reducer.
 *
 * @template TItem The type of the items in the mapping.
 * @param params Mapping reducer parameters.
 * @param params.key The resource main key name.
 * @param [params.transform] A transformer to apply on all loaded items.
 */
export const mappingReducer = <TItem: Object>({
  key,
  transform,
}: Props<TItem>): MappingReducer<TItem> => {
  const getKey = (item: TItem) => item[key];

  return (state: MappingState<TItem>, action: MappingAction<TItem>) => {
    switch (action.type) {
      case "INSERT":
        action = (action: InsertAction<TItem>);
        const item = transform ? transform(action.data) : action.data;
        return { ...state, [getKey(item)]: item };

      case "PATCH":
        action = (action: PatchAction<TItem>);
        if (!(action.data.key in state)) {
          return state;
        }
        return {
          ...state,
          [(action.data.key: any)]: {
            ...state[action.data.key],
            ...action.data.updates,
          },
        };

      case "EXTEND":
        action = (action: ExtendAction<TItem>);
        const items = transform ? action.data.map(transform) : action.data;
        return { ...state, ...keyBy(items, key) };

      case "REMOVE":
        return omit(state, (action: RemoveAction).data);

      case "REMOVE_BY":
        return omitBy(state, (action: RemoveByAction<TItem>).data);

      case "REPLACE_ALL":
        action = (action: ReplaceAllAction<TItem>);
        return keyBy(transform ? action.data.map(transform) : action.data, key);

      case "CLEAR":
        return {};

      default:
        return state;
    }
  };
};

/**
 * Typed wrapper around React native useReducer.
 */
export const useMappingReducer = <TItem: Object>(
  reducer: MappingReducer<TItem>
): [MappingState<TItem>, Dispatcher<MappingAction<TItem>>] =>
  React.useReducer<MappingState<TItem>, MappingAction<TItem>>(reducer, {});
