// @flow
import * as React from "react";
import type {
  CellComponent,
  Column,
  TableSelection,
} from "../../lib/display/DataTable/base";
import { DEFAULT_PAPER_SLOT_PROPS } from "../../lib/display/DataTable/base";
import type { HashtagGroup } from "../../../models/hashtagGroup.model";
import ChannelIcon from "../../lib/display/icons/ChannelIcon";
import { Body2 } from "../../lib/display/Text";
import VirtualDataTable from "../../lib/display/DataTable/VirtualDataTable";
import RouterLink from "../../lib/navigation/RouterLink";
import type { ModelID } from "../../../types";
import { makeSkeletonTable } from "../../lib/display/DataTable/makeSkeletonTable";
import { makeDeleteAction } from "../../lib/display/DataTable/TableAction";
import { ColumnStack } from "../../lib/layout/stacks";
import useIsMobile from "../../../hooks/useIsMobile";

const Filter: CellComponent<HashtagGroup> = ({ row }) =>
  row.channel && (
    <ChannelIcon channel={row.channel} color="channel" size="small" />
  );

const Tags: CellComponent<HashtagGroup> = ({ row }) => (
  <Body2 color="textSecondary">
    {row.hashtags.map((tag) => `#${tag}`).join(", ")}
  </Body2>
);

const Name: CellComponent<HashtagGroup> = ({ row }) => {
  return <RouterLink to={`./${row.id}/edit`}>{row.name}</RouterLink>;
};

const XsColumn: CellComponent<HashtagGroup> = ({ row }) => {
  return (
    <ColumnStack>
      <Name row={row} />
      <Tags row={row} />
    </ColumnStack>
  );
};

const columns: Column<HashtagGroup, void>[] = [
  {
    label: "global.filter",
    Component: Filter,
    headCellProps: { sx: { width: 60 } },
  },
  {
    label: "global.groupTitle",
    Component: Name,
    headCellProps: { sx: { width: 150 } },
  },
  { label: "global.hashtags", Component: Tags },
];

const xsColumns: Column<HashtagGroup, void>[] = [
  {
    label: "global.filter",
    Component: Filter,
    headCellProps: { sx: { width: 60 } },
  },
  {
    label: "global.content",
    Component: XsColumn,
  },
];

type Props = {
  rows: HashtagGroup[],
  onDelete: (TableSelection<ModelID>) => any,
};

const SLOT_PROPS = {
  paper: DEFAULT_PAPER_SLOT_PROPS,
  toolbar: {
    title: "global.hashtagGroups",
  },
  table: {
    size: "small",
  },
  tableContainer: {
    sx: {
      maxHeight: {
        xs: "calc(100% - 56px)",
        sm: "calc(100% - 64px)",
      },
    },
  },
  tableRow: {
    sx: {
      borderTop: { xs: "solid 1px rgba(0, 0, 0, 0.1)", sm: "none" },
      borderBottom: { xs: "solid 1px rgba(0, 0, 0, 0.1)", sm: "none" },
    },
  },
};

const HashtagGroupsTable: React.ComponentType<Props> = ({ rows, onDelete }) => {
  const isMobile = useIsMobile();

  return (
    <VirtualDataTable
      rows={rows}
      selectable
      columns={isMobile ? xsColumns : columns}
      actions={[makeDeleteAction(onDelete)]}
      slotProps={SLOT_PROPS}
    />
  );
};

export const SkeletonHashtagGroupsTable: React.ComponentType<{}> =
  makeSkeletonTable({ columns, slotProps: SLOT_PROPS });

export default HashtagGroupsTable;
