// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import FreeformFeedbackWidget from "../../../components/widgets/FreeformFeedbackWidget";

const FreeFormFeedbackPage: React.ComponentType<empty> = () => {
  return (
    <PrimaryPagePanel route={routes.app.nested.feedback}>
      <FreeformFeedbackWidget />
    </PrimaryPagePanel>
  );
};

export default FreeFormFeedbackPage;
