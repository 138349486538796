// @flow
import * as React from "react";
import ModalAlert from "./ModalAlert";
import NewAppVersionAlert from "./NewAppVersionAlert";
import NewIOSVersionAlert from "./NewIOSVersionAlert";
import RatheAppDialog from "../../components/feedback/RateTheAppDialog";
import { Outlet } from "react-router-dom";
import AppNavigation from "../../components/navigation/AppNavigation";
import DisconnectedModal from "./DisconnectedModal";
import FrozenSubscriptionDialog from "../../components/feedback/FrozenSubscriptionDialog";
import ActionRequiredDialog from "../../components/feedback/ActionRequiredDialog";
import StripeContext from "../../components/lib/display/stripe/StripeContext";
import PageRoot from "../../components/layout/PageRoot";
import { PAGE_ROOT_ID } from "../../config/identifiers.config";

const AppPage: React.ComponentType<{}> = () => (
  <StripeContext>
    <NewAppVersionAlert />
    <NewIOSVersionAlert />
    <ModalAlert />
    <RatheAppDialog />
    <DisconnectedModal />
    <PageRoot id={PAGE_ROOT_ID}>
      <AppNavigation />
      <FrozenSubscriptionDialog />
      <ActionRequiredDialog />
      <Outlet />
    </PageRoot>
  </StripeContext>
);

export default AppPage;
