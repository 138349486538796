// @flow
import moment from "moment";
import type { Timestamp } from "../types";
import { Moment } from "../types";
import type { Timezone } from "../models/timezone.model";

// Use delimiters, otherwise how the hell separating numbers would work?
const PARSE_FMT = "Y-M-D-H-m-s";

/**
 * Convert an optional UNIX timestamp to a moment.
 */
export const toMoment = (ts: ?Timestamp, tz?: ?Timezone): Moment | null => {
  if (!Number.isFinite(ts)) return null;
  const mom = moment.unix(ts);
  return tz ? mom.tz(tz.name) : mom;
};
/**
 * Returns a moment that wraps a datetime of same time as the input one.
 * This helper is required because moment.toDate() always returns the original
 * datetime it was constructed with, regardless of applied timezone.
 * Without this helper:
 *  - Input: 2020-07-10T06:00:00-04:00
 *  - Output: 2020 12:00:00 GMT+0200 (local time of Paris, France, summer)
 *
 *  With this helper:
 *  - Input: 2020-07-10T06:00:00-04:00
 * @param {Moment} from
 */
export const toLocalMoment = (from: Moment): Moment =>
  moment(from.format(PARSE_FMT), PARSE_FMT);

/**
 *
 * @param {Timestamp} timestamp
 * @return {string}
 */
export const toUTCDateStringFromUnix = (timestamp: Timestamp): string =>
  moment.unix(timestamp).utc().toDate().toLocaleDateString();

/**
 * Remove the time part of a date.
 * @param {Date|Moment} date
 * @return {Date|Moment}
 */
export const truncateTime = (date: Date | Moment): Moment => {
  if (!date) return date;
  if (date instanceof Date) date = moment(date);
  return date.startOf("day");
};

export const toDateString = (m: ?Moment): string => m?.format("l") ?? "";

export const toParamDateString = (m: ?Moment): string =>
  m?.format("Y-M-D") ?? "";
export const fromParamDateString = (s: ?string): ?Moment =>
  s ? moment(s, "Y-M-D") ?? null : null;
