// @flow
import * as React from "react";
import MiniCalendarDay from "./MiniCalendarDay";
import { styled } from "@mui/material/styles";

type Props = {
  children?: React.Element<typeof MiniCalendarDay>[],
};

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "stretch",
  gap: theme.spacing(1),
  [theme.breakpoints.down("lg")]: {
    gap: theme.spacing(0.5),
  },
}));

const MiniCalendar: React.ComponentType<Props> = ({ children }) => (
  <Root>{children}</Root>
);

export default MiniCalendar;
