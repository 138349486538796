// @flow
import TIMEZONES, { getCity, getContinent } from "../models/timezone.model";
import sortBy from "lodash/sortBy";
import { byId, byProp } from "../models/base.model";
import type { I18nKey, ModelID } from "../types";
import type { Timezone } from "../models/timezone.model";
import { cast } from "../types";

export type TimezoneOption = {
  value: number,
  label: string,
  shortLabel: string,
  group: string,
};

export const timezoneOptions: TimezoneOption[] = sortBy(
  TIMEZONES,
  (t) => t.name
).map((tz) => {
  const city = getCity(tz);
  return {
    value: tz.id,
    label: `refer.timezones.${city}.description`,
    shortLabel: `refer.timezones.${city}.name`,
    group: getContinent(tz),
  };
});

export const getTimezoneDescription = (timezone: Timezone): I18nKey =>
  `refer.timezones.${getCity(timezone)}.description`;

export const getOptionFromValue = (timezoneId: ?ModelID): ?TimezoneOption =>
  timezoneOptions.find(byProp("value", timezoneId ?? 0)) ?? null;

export const getTimezoneFromOption = (timezone: TimezoneOption): Timezone =>
  cast<Timezone>(TIMEZONES.find(byId(timezone.value)));
