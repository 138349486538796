// @flow
import * as React from "react";
import { useStore } from "../../../store/store";
import useTranslate from "../../../hooks/useTranslate";
import { Alert, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { Snack } from "../../../models/alerts.model";

const FullWidthAlert = styled(Alert)`
  width: 100%;
`;

export const SnacksManager = (): React.Node => {
  const [state, actions] = useStore();
  const [currentSnack, setCurrentSnack] = React.useState<?Snack>(null);
  const t = useTranslate();

  // If there is no current snack but the queue is not empty, pop one
  // from the queue.
  React.useEffect(() => {
    if (!currentSnack && state.snacks.length) {
      setCurrentSnack(state.snacks[0]);
      actions.snacks.remove(state.snacks[0].id);
    }
  }, [currentSnack, state.snacks, actions.snacks]);

  const handleClose = () => setCurrentSnack(null);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={!!currentSnack}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      {currentSnack && (
        <FullWidthAlert onClose={handleClose} severity={currentSnack.severity}>
          {currentSnack.translate
            ? t(currentSnack.message, currentSnack.messageData)
            : currentSnack.message}
        </FullWidthAlert>
      )}
    </Snackbar>
  );
};

export default SnacksManager;
