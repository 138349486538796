// @flow
import ajax from "../../lib/ajax.lib";
import type { APIResult, Pagination } from "./types";
import type {
  APIPostComment,
  PostComment,
} from "../../models/postComment.model";
import { fromAPIResult as commentFromAPIResult } from "../../models/postComment.model";
import { fromAPIResult as summaryFromAPIResult } from "../../models/socialActionSummary.model";
import type { ModelID } from "../../types";
import omit from "lodash/omit";
import type {
  SocialActionSummary,
  APISocialActionSummary,
} from "../../models/socialActionSummary.model";

export type CommentParams = {
  message: string,
};

export type CreateCommentParams = {
  ...CommentParams,
  parent_id?: ?ModelID,
};

export type EditCommentParams = {
  ...CommentParams,
  id: ModelID,
};

type APICommentResponse = {
  comment: APIPostComment,
  summary: APISocialActionSummary,
};

export type CommentResponse = {
  comment: PostComment,
  summary: SocialActionSummary,
};

const commentResponseFromAPIResult = (
  response: APICommentResponse
): CommentResponse => ({
  comment: commentFromAPIResult(response.comment),
  summary: summaryFromAPIResult(response.summary),
});

export const addComment = (
  postId: ModelID,
  params: CreateCommentParams
): Promise<CommentResponse> =>
  ajax
    .post<CreateCommentParams, APIResult<APICommentResponse>>(
      `/social_actions/${postId}/comments`,
      params
    )
    .then((resp) => commentResponseFromAPIResult(resp.data.data));

export const editComment = (
  params: EditCommentParams
): Promise<CommentResponse> =>
  ajax
    .put<PostComment, APIResult<APICommentResponse>>(
      `/social_actions/comments/${params.id}`,
      omit(params, "id")
    )
    .then((resp) => commentResponseFromAPIResult(resp.data.data));

export const getComments = (postId: ModelID): Promise<PostComment[]> =>
  ajax
    .get<APIResult<APIPostComment[]>>(`/social_actions/${postId}/comments`)
    .then((resp) => resp.data.data.map(commentFromAPIResult));

export const deleteComment = (commentId: ModelID): Promise<CommentResponse> =>
  ajax
    .delete<APIResult<APICommentResponse>>(
      `/social_actions/comments/${commentId}`
    )
    .then((resp) => commentResponseFromAPIResult(resp.data.data));

export const likeComment = (commentId: ModelID): Promise<CommentResponse> =>
  ajax
    .post<void, APIResult<APICommentResponse>>(
      `/social_actions/comments/${commentId}/likes`
    )
    .then((resp) => commentResponseFromAPIResult(resp.data.data));

export const unlikeComment = (commentId: ModelID): Promise<CommentResponse> =>
  ajax
    .delete<
      APIResult<{ comment: APIPostComment, summary: APISocialActionSummary }>
    >(`/social_actions/comments/${commentId}/likes`)
    .then((resp) => commentResponseFromAPIResult(resp.data.data));

export const markPostCommentsAsRead = (
  postId: ModelID
): Promise<{ comments: PostComment[], summary: SocialActionSummary }> =>
  ajax
    .post<
      void,
      APIResult<{ comments: APIPostComment[], summary: SocialActionSummary }>
    >(`/social_actions/${postId}/comments/read`)
    .then((resp) => ({
      ...resp.data.data,
      comments: resp.data.data.comments.map(commentFromAPIResult),
    }));

export const getPostSocialActionSummary = (
  postId: ModelID
): Promise<SocialActionSummary> =>
  ajax
    .get<APIResult<APISocialActionSummary>>(`/social_actions/${postId}`)
    .then((response) => summaryFromAPIResult(response.data.data));

export const getSocialActionSummaries = ({
  page = 1,
  pagesize = 200,
  portfolioId,
}: {
  ...Pagination,
  portfolioId: ModelID,
}): Promise<SocialActionSummary[]> =>
  ajax
    .get<APIResult<APISocialActionSummary[]>>(
      `portfolio/${portfolioId}/social_actions?page=${page}&pagesize=${pagesize}`
    )
    .then((response) => response.data.data.map(summaryFromAPIResult));
