// @flow
import type { Bytes, FindIteratee, Percent, URLStr } from "../types";
import type { MediaRef } from "./base.model";

/**
 * Specifies the types of a media attachment.
 */
export type AttachmentTypes = "video" | "audio" | "file" | "image" | "pdf";

/**
 * Describe a tag on a media. Base class.
 */
export type AttachmentTag = {
  /** Horizontal position of the tag, in width percentage. */
  x: Percent,
  /** Vertical position of the tag, in height percentage. */
  y: Percent,
};

/**
 * A product reference on an attachment.
 */
export type AttachmentProductTag = {
  ...AttachmentTag,
  /** Could be an id or a slug, depending on the channel. */
  product_id: string,
  /** The pretty name of the product, for display purposes. */
  product_name: string,
};

/**
 * A user reference on an attachment.
 */
export type AttachmentUserTag = {
  ...AttachmentTag,
  /** Could be an id or a username, depending on the channel. */
  handle: string,
};

type BaseAttachment = {
  /** The enum value of this approval request status. */
  type: AttachmentTypes,
  /** The user tags. */
  user_tags?: AttachmentUserTag[],
  /** The product tags. */
  product_tags?: AttachmentProductTag[],
};

/**
 * An attachment that has just been created. No server counterpart yet.
 */
export type LocalAttachment = {
  ...BaseAttachment,
  /** A local ID, since URL cannot be used as a single identifier. */
  id: string,
  /** Before saving, the file carried by the attachment. */
  file: File,
  /** Once the attachment is being saved, the promise resolved with the media ref */
  uploadPromise?: Promise<MediaRef>,
};

/**
 * An attachment that is saved on a server.
 */
export type RemoteAttachment = {
  ...BaseAttachment,
  /** The URL to the attachment, if the attachment has been saved. */
  url: URLStr,
};

export type AttachmentSrc = URLStr | File;

/**
 * The current progress of an attachment upload.
 */
export type AttachmentUploadProgress = {
  /** The key of the linked attachment */
  key: string,
  /** The uploaded data, in bytes. */
  current: Bytes,
  /** The total data to upload, in bytes. */
  total: Bytes,
};

interface AttachmentLike {
  type: AttachmentTypes;
}

/**
 * Stands for a media attachment. Base class.
 */
export type Attachment = LocalAttachment | RemoteAttachment;
export const getAttachmentSrc = (attachment: Attachment): AttachmentSrc =>
  attachment.file ?? attachment.url ?? "";

export const getAttachmentKey = (attachment: Attachment): string =>
  attachment.id ?? attachment.url ?? "";

export const isAttachmentImage: FindIteratee<AttachmentLike> = (attachment) =>
  attachment.type === "image";
export const isAttachmentVideo: FindIteratee<AttachmentLike> = (attachment) =>
  attachment.type === "video";
export const isAttachmentPDF: FindIteratee<AttachmentLike> = (attachment) =>
  attachment.type === "pdf";
export const isAttachmentGif: FindIteratee<Attachment> = (attachment) =>
  attachment.url?.includes(".gif") ?? false;
export const isAttachmentNotGif: FindIteratee<Attachment> = (attachment) =>
  !isAttachmentGif(attachment);
export const getAttachmentPoster = (
  attachment: Attachment,
  offset?: ?number
): ?string =>
  isAttachmentVideo(attachment)
    ? attachment.url
      ? `${attachment.url}.thumb${offset ?? ""}.jpg`
      : undefined
    : undefined;
