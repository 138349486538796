// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { darken, lighten } from "@mui/material";
import type { CSSProps, SyntheticEventListener } from "../../../reactTypes";

type CoreProps = {
  paletteColor: string,
  onClick?: SyntheticEventListener,
  children?: React.ChildrenArray<any>,
  ...CSSProps,
};

type Props = {
  ...CoreProps,
  dense?: boolean,
};

const BaseColoredChip: React.ComponentType<CoreProps> = styled("div", {
  shouldForwardProp: (prop) => prop !== "paletteColor",
})(
  ({ theme, paletteColor, onClick }) =>
    `
    display: inline-flex;
    align-items: center;
    cursor: ${onClick ? "pointer" : "auto"};
    background-color: ${
      theme.palette.mode === "light"
        ? lighten(theme.palette[paletteColor].light, 0.7)
        : darken(theme.palette[paletteColor].dark, 0.6)
    };
    color: ${theme.palette[paletteColor].main};
    
  `
);

const RegularColoredChip: React.ComponentType<CoreProps> = styled(
  BaseColoredChip
)`
  border-radius: 4px;
  padding: 4px 12px;
  gap: 4px;
  & svg {
    font-size: 1.2rem;
  }
`;

const DenseColoredChip: React.ComponentType<CoreProps> = styled(
  BaseColoredChip
)`
  border-radius: 3px;
  padding: 2px 6px;
  gap: 3px;
  & svg {
    font-size: 1rem;
  }
`;

const ColoredChip: React.ComponentType<Props> = React.forwardRef(
  ({ dense, ...props }, ref) =>
    dense ? (
      <DenseColoredChip ref={ref} {...props} />
    ) : (
      <RegularColoredChip ref={ref} {...props} />
    )
);

export default ColoredChip;
