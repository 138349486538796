// @flow
import * as React from "react";
import { Stack } from "@mui/material";
import { withDefaultProps } from "../utils/hoc";
import { styled } from "@mui/material/styles";

export const RowStack: typeof Stack = withDefaultProps<
  React.ElementProps<typeof Stack>,
  "direction"
>(Stack, { direction: "row", alignItems: "center" });

export const ColumnStack: typeof Stack = withDefaultProps<
  React.ElementProps<typeof Stack>,
  "direction"
>(Stack, { direction: "column" });

export const InlineRowStack: "div" = styled("div", {
  shouldForwardProp: (prop) =>
    !["spacing", "alignItems", "justifyContent"].includes(prop),
})(({ theme, spacing = 1, alignItems = "center", justifyContent }) => ({
  display: "inline-flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems,
  justifyContent,
  gap: theme.spacing(spacing),
}));

export const RowGroup: "div" = styled("div")(({ theme }) => ({
  display: "flex",
  direction: "row",
  alignItems: "stretch",
  gap: 0,
  "& > div": { borderRadius: 0 },
  "& > button": { borderRadius: 0 },
  "& > *:first-child": {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  "& > *:last-child": {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

export const SpaceBetweenRow: typeof Stack = withDefaultProps<
  React.ElementProps<typeof Stack>,
  "direction" | "justifyContent"
>(Stack, { direction: "row", justifyContent: "space-between" });

export const ResponsiveInlineRowStack: "div" = styled("div", {
  shouldForwardProp: (prop) =>
    !["flexGrow", "alignItems", "spacing", "flexWrap"].includes(prop),
})(
  ({
    theme,
    alignItems,
    flexGrow,
    spacing = 2,
    flexWrap = "wrap",
    justifyContent,
  }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent,
    alignItems,
    flexGrow,
    flexWrap,
    gap: theme.spacing(spacing),
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "stretch",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  })
);
