// @flow
import type { LocalAttachment } from "../models/attachment.model";
import type { MediaRef } from "../models/base.model";
import { uploadFile } from "./upload.service";

export type RefAttachmentPayload = {
  ...LocalAttachment,
  data: MediaRef,
  is_ref: true,
};

export type OnUploadAttachmentProgress = (
  progress: ProgressEvent,
  attachment: LocalAttachment
) => void;

export const uploadFileAttachments = async (
  attachments: LocalAttachment[],
  publicRead: boolean,
  onProgress?: OnUploadAttachmentProgress
): Promise<RefAttachmentPayload[]> =>
  await Promise.all(
    attachments.map((att) => uploadFileAttachment(att, publicRead, onProgress))
  );

export const uploadFileAttachment = async (
  attachment: LocalAttachment,
  publicRead: boolean,
  onProgress?: OnUploadAttachmentProgress
): Promise<RefAttachmentPayload> =>
  // Return either the eager promise or a new one if it does not exist.
  (
    attachment.uploadPromise ??
    uploadFile(
      attachment.file,
      publicRead,
      onProgress ? (prgrss) => onProgress(prgrss, attachment) : undefined
    )
  ).then((media_ref) => ({ ...attachment, data: media_ref, is_ref: true }));
