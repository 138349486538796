// @flow
import * as React from "react";
import AttachmentTagsEditorLayout from "./AttachmentTagsEditorLayout";
import type { PostAttachment } from "../../../../models/post.model";
import { splice, withoutIndex } from "../../../../lib/lodashex.lib";
import { getAttachmentSrc } from "../../../../models/attachment.model";
import type { AttachmentUserTag } from "../../../../models/attachment.model";

type AttachmentTag = AttachmentUserTag;

type Props = {
  attachment: PostAttachment,
  onChangeAttachment: (PostAttachment) => any,
  TagComponent: React.ComponentType<any>,
  tagAttribute: string,
  onNewTag: (AttachmentTag) => any,
  onEditTag: (AttachmentTag, text: string, value: any) => any,
  isTagEmpty: (AttachmentTag) => boolean,
};

const toCoordinate = (val: number) => parseFloat(val.toFixed(4));

const updateAttachmentTags = (
  attachment: PostAttachment,
  tagAttribute: string,
  tags: AttachmentTag[]
): PostAttachment => ({
  ...attachment,
  [tagAttribute]: tags,
});

const AttachmentTagsEditorLayoutContainer: React.ComponentType<Props> = ({
  attachment,
  onChangeAttachment,
  TagComponent,
  tagAttribute,
  onNewTag,
  onEditTag,
  isTagEmpty,
}) => {
  const [hideTags, setHideTags] = React.useState(false);
  const [currentTagIndex, setCurrentTagIndex] = React.useState<?number>();
  const tags = attachment[tagAttribute];

  const handleAddTag = (e: SyntheticMouseEvent<>) => {
    const newTag = onNewTag({
      // x and y are percentages, between 0 and 1.
      // $FlowFixMe[prop-missing]
      x: toCoordinate(e.nativeEvent.offsetX / e.target.clientWidth),
      // $FlowFixMe[prop-missing]
      y: toCoordinate(e.nativeEvent.offsetY / e.target.clientHeight),
      handle: "",
    });
    onChangeAttachment(
      updateAttachmentTags(attachment, tagAttribute, [...tags, newTag])
    );
    setCurrentTagIndex(tags.length);
  };

  const handleEditTag = (text: string, value: string) => {
    onChangeAttachment(
      updateAttachmentTags(
        attachment,
        tagAttribute,
        splice(tags, currentTagIndex ?? -1, 1, [
          onEditTag(tags[currentTagIndex], text, value),
        ])
      )
    );
  };

  const handleDeleteTag = (tagIndex: number) => {
    onChangeAttachment(
      updateAttachmentTags(
        attachment,
        tagAttribute,
        withoutIndex(tags, tagIndex)
      )
    );
  };

  const handleSetCurrentTagIndex = (tagIndex: ?number) => {
    setCurrentTagIndex(tagIndex);
    if (Number.isFinite(currentTagIndex)) {
      const currentTag = tags[currentTagIndex];
      if (currentTag && isTagEmpty(currentTag)) {
        handleDeleteTag(currentTagIndex ?? -1);
      }
    }
  };

  return (
    <AttachmentTagsEditorLayout
      tags={tags}
      TagComponent={TagComponent}
      imageSrc={getAttachmentSrc(attachment)}
      onAddTag={handleAddTag}
      currentTagIndex={currentTagIndex}
      hideTags={hideTags}
      onSetHideTags={setHideTags}
      onEditTag={handleEditTag}
      onDeleteTag={handleDeleteTag}
      onSetCurrentTagIndex={handleSetCurrentTagIndex}
    />
  );
};

export default AttachmentTagsEditorLayoutContainer;
