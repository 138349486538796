// @flow
import * as React from "react";
import UpdatePaymentDetailsSection from "./UpdatePaymentDetailsSection";
import useSwitch from "../../../../hooks/useSwitch";
import type { TransientBillingInfo } from "../../../../models/billingInfo.model";
import useConfirmSetupIntent from "../../../lib/display/stripe/useConfirmSetupIntent";
import type { Inexact } from "../../../../types";

type Props = {
  billingInfo?: Inexact<TransientBillingInfo>,
};

const UpdatePaymentDetailsSectionContainer: React.ComponentType<Props> = ({
  billingInfo,
}) => {
  const confirmSetupIntent = useConfirmSetupIntent();
  const [editing, onBeginEdit, onEndEdit] = useSwitch(false);

  return (
    <UpdatePaymentDetailsSection
      editing={editing}
      onBeginEdit={onBeginEdit}
      onCancelEdit={onEndEdit}
      onCompleteEdit={() =>
        billingInfo &&
        confirmSetupIntent &&
        confirmSetupIntent({
          action: "update",
          billingInfo,
        })
      }
    />
  );
};

export default UpdatePaymentDetailsSectionContainer;
