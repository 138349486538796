// @flow
import * as React from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import {
  horizontalListSortingStrategy,
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import useSensors from "./useSensors";
import type { Params as SensorsParams } from "./useSensors";
import { styled } from "@mui/material/styles";
import type { Children, CSSProps } from "../../../../reactTypes";
import { reorderItem } from "../../../../lib/lodashex.lib";

type Layout = "horizontal" | "vertical" | "grid";
type ItemId = string | number;
type Item = { id: string, ... } | { id: number, ... };

const LAYOUTS_STYLES_MIXINS: Record<Layout, Object> = {
  horizontal: {
    display: "flex",
    overflowX: "auto",
  },
  vertical: {},
  grid: {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "auto",
  },
};

const SORTING_STRATEGIES: Record<Layout, Function> = {
  vertical: verticalListSortingStrategy,
  horizontal: horizontalListSortingStrategy,
  grid: rectSortingStrategy,
};

const SortableDroppableRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== "layout",
})(({ layout }) => ({
  position: "relative",
  ...LAYOUTS_STYLES_MIXINS[layout],
}));

type SortableDroppableProps = {
  layout: Layout,
  items: $ReadOnlyArray<Item>,
  children?: Children,
  ...CSSProps,
};

/**
 * Stands for an area where draggables can be dropped and reordered.
 */
const SortableDroppable: React.ComponentType<SortableDroppableProps> = ({
  layout,
  items,
  children,
  ...props
}) => (
  <SortableContext items={items} strategy={SORTING_STRATEGIES[layout]}>
    <SortableDroppableRoot layout={layout} {...props}>
      {children}
    </SortableDroppableRoot>
  </SortableContext>
);

const SortableDraggableRoot = styled("div", {
  shouldForwardProp: (prop) => !["layout", "spacing", "dragged"].includes(prop),
})(({ theme, layout, spacing, dragged }) => ({
  margin: theme.spacing(spacing),
  display: layout === "vertical" ? undefined : "flex",
  opacity: dragged ? 0.3 : 1,
  // Images should not give a draggable preview.
  // The item will do it ONLY if drag is enable.
  "& img": {
    pointerEvents: "none",
  },
}));

type SortableDraggableProps = {
  layout: Layout,
  disabled?: boolean,
  dragged?: boolean,
  spacing: number,
  id: ItemId,
  children?: Children,
  ...CSSProps,
};

/**
 * Stands for a draggable that can be sorted in a SortableDroppable.
 */
const SortableDraggable: React.ComponentType<SortableDraggableProps> = ({
  children,
  id,
  disabled,
  ...props
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  const transformStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    // https://docs.dndkit.com/api-documentation/sensors/pointer
    // If your draggable item is part of a scrollable list, we recommend you use a
    // drag handle and set touch-action to none only for the drag handle, so that the
    // contents of the list can still be scrolled, but that initiating a drag from the
    // drag handle does not scroll the page.
    touchAction: props.dragged ? "none" : undefined,
  };

  return (
    <SortableDraggableRoot
      ref={setNodeRef}
      style={transformStyle}
      {...listeners}
      {...attributes}
      {...props}
    >
      {children}
    </SortableDraggableRoot>
  );
};

const _CONTEXT_MODIFIERS: Record<Layout, Function[]> = {
  vertical: [restrictToVerticalAxis],
  horizontal: [restrictToHorizontalAxis],
  grid: [restrictToParentElement],
};

const _DRAG_OVERLAY_MODIFIERS = [restrictToParentElement];
const _DRAG_OVERLAY_STYLE = { boxShadow: "2px 2px 4px rgba(0,0,0,0.3)" };

type Props<T: Item> = {
  layout: Layout,
  items: $ReadOnlyArray<T>,
  renderer: (T) => React.Node,
  onItemsReordered: (
    reorderedItems: T[],
    movedItem: T,
    fromIndex: number,
    toIndex: number
  ) => any,
  isItemDisabled?: (T) => boolean,
  spacing?: number,
  sensors?: SensorsParams,
  ...CSSProps,
};

type DragEventItem = {
  id: ItemId,
  data: {
    current: {
      sortable: {
        index: number,
      },
    },
  },
};

type DragEvent = {
  active: DragEventItem,
  over: DragEventItem,
};

const DragAndDropArea = <T: Item>({
  onItemsReordered,
  renderer,
  items,
  layout,
  spacing = 0,
  isItemDisabled,
  className,
  sensors,
}: Props<T>): React.Node => {
  const [activeItem, setActiveItem] = React.useState<?T>(null);

  const handleDragEnd = React.useCallback(
    (e: DragEvent) => {
      const { active, over } = e;
      if (active && over && active.id !== over.id) {
        onItemsReordered(
          reorderItem(
            items,
            active.data.current.sortable.index,
            over.data.current.sortable.index
          ),
          items[active.data.current.sortable.index],
          active.data.current.sortable.index,
          over.data.current.sortable.index
        );
      }
      setActiveItem(null);
    },
    [items, onItemsReordered]
  );

  const handleDragStart = React.useCallback(
    (e: DragEvent) => {
      setActiveItem(items[e.active.data.current.sortable.index]);
    },
    [items]
  );

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={_CONTEXT_MODIFIERS[layout]}
      sensors={useSensors(sensors)}
    >
      <SortableDroppable items={items} layout={layout} className={className}>
        {items.map((item) => (
          <SortableDraggable
            key={item.id}
            id={item.id}
            spacing={spacing}
            layout={layout}
            dragged={item.id === activeItem?.id}
            disabled={isItemDisabled && isItemDisabled(item)}
          >
            {renderer(item)}
          </SortableDraggable>
        ))}
      </SortableDroppable>
      <DragOverlay
        modifiers={_DRAG_OVERLAY_MODIFIERS}
        style={_DRAG_OVERLAY_STYLE}
      >
        {activeItem && renderer(activeItem)}
      </DragOverlay>
    </DndContext>
  );
};

export default DragAndDropArea;
