// @flow
import * as React from "react";
import { insertAtCursorPos } from "../../../../../lib/text.lib";
import EmojiKeyboard from "./EmojiKeyboard";
import usePopup from "../../../utils/usePopup";
import type { PopperProps } from "../../../utils/usePopup";

type Props = {
  inputEl: ?HTMLInputElement,
  onChangeInputValue: (string) => any,
  children: React.Element<any>,
  popperProps?: PopperProps,
};

const EmojiPickerContainer: React.ComponentType<Props> = ({
  inputEl,
  onChangeInputValue,
  children,
  popperProps,
}) => {
  const handleSelectEmoji = React.useCallback(
    (emoji: string) =>
      inputEl && onChangeInputValue(insertAtCursorPos(inputEl, emoji)),
    [inputEl, onChangeInputValue]
  );
  const [onClick, popper] = usePopup(
    <EmojiKeyboard onSelectEmoji={handleSelectEmoji} />,
    popperProps
  );
  return (
    <>
      {React.cloneElement(children, { onClick })}
      {popper}
    </>
  );
};

export default EmojiPickerContainer;
