// @flow
import * as React from "react";
import useLocationParams from "./useLocationParams";
import compact from "lodash/compact";
import { parseModelId } from "../models/base.model";
import type { PostFiltersInitial } from "../contexts/postFilters";
import { fromParamDateString } from "../lib/time.lib";

const useLocationPostFilters = (): PostFiltersInitial => {
  const { accountId, from, to, postId } = useLocationParams([
    "accountId",
    "postId",
    "from",
    "to",
  ]);
  return React.useMemo(
    () => ({
      accountIds: compact([parseModelId(accountId)]),
      postIds: compact((postId ?? "").split(",").map(parseModelId)),
      fromDate: fromParamDateString(from),
      toDate: fromParamDateString(to),
    }),
    [accountId, from, to, postId]
  );
};

export default useLocationPostFilters;
