// @flow
import * as React from "react";
import Image from "../../../lib/display/Image";
import { styled } from "@mui/material/styles";
import { RowStack } from "../../../lib/layout/stacks";
import type { ImageSrc } from "../../../../lib/images.lib";
import type { AttachmentUserTag } from "../../../../models/attachment.model";
import { Tooltip } from "@mui/material";
import { NeutralButton } from "../../../lib/inputs/buttons";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import useTranslate from "../../../../hooks/useTranslate";
import UserTag from "../AttachmentUserTagsEditor/UserTag";

type Props = {
  imageSrc: ImageSrc,
  tags: AttachmentUserTag[],
  currentTagIndex: ?number,
  onAddTag: (SyntheticMouseEvent<>) => any,
  hideTags: boolean,
  onSetHideTags: (boolean) => any,
  onSetCurrentTagIndex: (?number) => any,
  onEditTag: (text: string, value: string) => any,
  onDeleteTag: (tagIndex: number) => any,
  TagComponent: typeof UserTag,
};

const StyledImage = styled(Image)({
  width: "100%",
  maxHeight: "60vh",
});

const Controls = styled(RowStack)(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(0.5),
  },
}));

const AttachmentTagsEditorLayout: React.ComponentType<Props> = ({
  imageSrc,
  tags,
  onAddTag,
  hideTags,
  onSetHideTags,
  currentTagIndex,
  onEditTag,
  onDeleteTag,
  onSetCurrentTagIndex,
  TagComponent,
}) => {
  const t = useTranslate();
  return (
    <div>
      <StyledImage
        src={imageSrc}
        onDoubleClick={onAddTag}
        // Equivalent to a clickaway listener on all tags.
        onClick={() => onSetCurrentTagIndex(null)}
      />
      {tags
        .filter((tag, idx) => !hideTags || idx === currentTagIndex)
        .map((tag, idx) => (
          <TagComponent
            key={idx}
            tag={tag}
            onActivate={() => onSetCurrentTagIndex(idx)}
            onDeactivate={() => onSetCurrentTagIndex(null)}
            activated={idx === currentTagIndex}
            onEditTag={onEditTag}
            onDelete={() => onDeleteTag(idx)}
          />
        ))}
      <Controls>
        <Tooltip title={t("AttachmentTagDialog.hideShowTags")}>
          <NeutralButton onClick={() => onSetHideTags(!hideTags)}>
            {hideTags ? <VisibilityOffRounded /> : <VisibilityRounded />}
          </NeutralButton>
        </Tooltip>
      </Controls>
    </div>
  );
};

export default AttachmentTagsEditorLayout;
