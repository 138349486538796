// @flow
import * as React from "react";
import BillingInfoSection from "./BillingInfoSection";
import useSwitch from "../../../../hooks/useSwitch";
import * as subscriptionService from "../../../../services/subscription.service";
import { useStoreActions } from "../../../../store/store";
import useBillingInfoForm from "../../BillingInfoWidget/useBillingInfoForm";
import { useSubscriptionInitialised } from "../../../../store/selectors";

const BillingInfoSectionContainer: React.ComponentType<{}> = () => {
  const { form, onValidateAndCollect, onReset } = useBillingInfoForm();
  const subscriptionInitialised = useSubscriptionInitialised();
  const actions = useStoreActions();
  const [editing, onBeginEdit, onEndEdit] = useSwitch(false);
  const handleCancel = () => {
    onEndEdit();
    onReset();
  };

  const handleConfirm = () => {
    const payload = onValidateAndCollect();
    if (payload) {
      return subscriptionService
        .updateBillingInfo(actions)(payload.billingInfo)
        .then(() =>
          subscriptionService.updateInvoicingPreferences(actions)(
            payload.enableSendInvoices
          )
        )
        .then(onEndEdit);
    }
  };
  return (
    <BillingInfoSection
      loading={!subscriptionInitialised}
      editing={editing}
      onBeginEdit={onBeginEdit}
      onCancelEdit={handleCancel}
      onCompleteEdit={handleConfirm}
      form={form}
    />
  );
};

export default BillingInfoSectionContainer;
