// @flow
import GMBIcon from "./GMBIcon";
import TiktokIcon from "./TiktokIcon";
import type { Slug } from "../../../../../types";
import { Icon } from "@mui/material";
import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  Pinterest,
  QuestionMarkRounded,
  X,
} from "@mui/icons-material";

const all = {
  facebook: FacebookRounded,
  linkedin: LinkedIn,
  instagram: Instagram,
  pinterest: Pinterest,
  twitter: X,
  gmb: GMBIcon,
  tiktok: TiktokIcon,
};

export const getChannelIcon = (slug: ?Slug): typeof Icon =>
  (slug && all[slug]) || QuestionMarkRounded;

export default all;
