// @flow

// Navigation
/** A navigation event code. */
export opaque type EventType = number;
export const NAVIGATED_TO_DASHBOARD: EventType = 401;
export const NAVIGATED_TO_CREATOR: EventType = 402;
export const NAVIGATED_TO_CALENDAR: EventType = 403;
export const NAVIGATED_TO_ANALYTICS: EventType = 404;
export const NAVIGATED_TO_HASHTAG_MANAGER: EventType = 405;
export const NAVIGATED_TO_ENGAGEMENT_HUB: EventType = 406;
export const NAVIGATED_TO_POST_APPROVAL: EventType = 407;
export const NAVIGATED_TO_VIZ_PLANNER: EventType = 408;
export const NAVIGATED_TO_REFER_A_FRIEND: EventType = 409;
export const NAVIGATED_TO_EDIT_PLAN: EventType = 410;
export const NAVIGATED_TO_HELP: EventType = 412;

// Other
export const LOGGED_OUT: EventType = 903;
