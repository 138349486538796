import { loginOAuth2 } from "./oauth.service";
import * as env from "../../config/env.config";

const SIGN_IN_URL =
  "https://www.linkedin.com/oauth/v2/authorization?" +
  "response_type=code&" +
  `client_id=${env.LINKEDIN_CLIENT_ID}&` +
  "scope=r_liteprofile%20w_organization_social%20rw_organization_admin%20r_organization_social%20w_member_social&" +
  `redirect_uri=${env.API_URL}${env.LINKEDIN_REDIRECT_ENDPOINT}&` +
  `state=${env.LINKEDIN_STATE}`;

const POPUP_FEATURES =
  "height=800,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no," +
  "location=no,directories=no, status=yes";

export const login = () => loginOAuth2(SIGN_IN_URL, POPUP_FEATURES);

export const wasLoginCancelled = (e) =>
  ["user_cancelled_authorize", "user_cancelled_login"].includes(e.error);
