// @flow
import * as React from "react";
import ManageAddOnsWidget from "./ManageAddOnsWidget";
import {
  useAddOns,
  useCurrentSubscription,
  useSubscriptionLocked,
} from "../../../store/selectors";
import * as subscriptionService from "../../../services/subscription.service";
import { useStoreActions } from "../../../store/store";

const ManageAddOnsWidgetContainer: React.ComponentType<{}> = () => {
  const subscription = useCurrentSubscription();
  const addOns = useAddOns();
  const actions = useStoreActions();
  const locked = useSubscriptionLocked();

  if (!subscription) {
    return null;
  }

  return (
    <ManageAddOnsWidget
      currency={subscription.plan.currency}
      onPurchase={subscriptionService.purchaseAddOn(actions)}
      onCancel={subscriptionService.cancelAddOn(actions)}
      locked={locked}
      addOns={addOns}
    />
  );
};

export default ManageAddOnsWidgetContainer;
