// @flow

import type { ModelID, Slug, Timestamp } from "../types";
import { cast, Moment } from "../types";
import moment from "moment";
import type { Channel } from "./channels/channel.model";
import { getChannel } from "./channels";

/**
 * Possible flags for an account.
 */
export type AccountStatusFlags = number;
export const AccountStatusFlag: Record<string, AccountStatusFlags> =
  Object.freeze({
    OK: 0,
    MISSING_PERMISSIONS: 0x01,
    INVALID_TOKEN: 0x02,
    ACCOUNT_LOCKED: 0x04,
    ANALYTICS_FAILURE: 0x10,
    NOT_A_BUSINESS_ACCOUNT: 0x10000,
  });

/**
 * The lifecycle states of an account.
 */
export type AccountLifecycleState =
  | "disconnected"
  | "connected"
  | "expires_soon"
  | "expired";

export type BobcaatAccountPublicInfo = {
  id: ModelID,
  /**
   * Name of the entity for the connected account.
   */
  entity_name: string,
  /**
   * Slug of the target channel.
   */
  channel: Slug,
};

/**
 * A portfolio account connected to some social network.
 */
export type BobcaatAccount = {
  ...BobcaatAccountPublicInfo,
  /**
   * The portfolio ID this account belongs to.
   */
  portfolio_id: ModelID,

  /**
   * The username of the connected social account.
   */
  user_name: string,

  /**
   * The native user ID for the connected account.
   */
  user_id: string,

  /**
   * The native entity ID for the connected account.
   */
  entity_id: string,

  /**
   * The native ID of the parent of the account entity. For Instagram,
   * this is the ID of the page that is linked to the Instagram account.
   */
  parent_entity_id?: string,

  /**
   * Name of the entity for the connected account.
   */
  entity_name: string,

  /**
   * UNIX timestamp of the token expiry.
   */
  access_token_expiry: Moment,

  /**
   * Whether this account represents an entity.
   */
  is_organisation: boolean,

  /**
   * Flags representing the account status.
   */
  status_code: AccountStatusFlags,
};

export type APIBobcaatAccount = {
  ...Omit<BobcaatAccount, "access_token_expiry">,
  access_token_expiry: Timestamp,
};

/**
 * Enrich a raw account received from the server.
 */
export const fromAPIResult = (account: APIBobcaatAccount): BobcaatAccount => ({
  ...account,
  access_token_expiry: moment.unix(account.access_token_expiry),
});

/**
 * Get the channel account. Guaranteed to return a channel.
 */
export const getAccountChannel = (account: BobcaatAccount): Channel =>
  cast<Channel>(getChannel(account.channel));

export const getAccountEntityName = (account: ?BobcaatAccount): string =>
  account?.entity_name ?? "";

export const getAccountLongName = (account: ?BobcaatAccount): string => {
  if (!account) return "";
  return `${account.entity_name} (${getAccountChannel(account).name ?? ""})`;
};

interface AccountLifecycle {
  access_token_expiry: Moment;
}

export const isExpired = (account: AccountLifecycle): boolean =>
  account.access_token_expiry.isBefore(moment());

export const isAboutToExpire = (account: AccountLifecycle): boolean =>
  account.access_token_expiry.isBetween(moment(), moment().add(14, "d"));

/**
 * Ge the lifecycle state of the specifed account.
 */
export const getAccountLifecycleState = (
  account: AccountLifecycle
): AccountLifecycleState => {
  if (!account) return "disconnected";
  if (isExpired(account)) return "expired";
  if (isAboutToExpire(account)) return "expires_soon";
  return "connected";
};

export const getExpiresInDays = (account: BobcaatAccount): number =>
  moment(account.access_token_expiry).diff(moment(), "days");

export const isIndividualAccount = (account: ?BobcaatAccount): boolean =>
  !!account && account.user_id === account.entity_id;
