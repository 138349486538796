// @flow
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import type { Theme } from "../../../../stubs/mui/theming";

export const DRAWER_WIDTH = 250;

const paperMixin = (theme: Theme) => ({
  backgroundColor: theme.palette.background.alt,
  border: "none",
});

const openedMixin = (theme: Theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

/**
 * A permanent drawer that can be open or collapsed.
 */
export const MiniVariantDrawer: typeof MuiDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      ...paperMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      ...paperMixin(theme),
    },
  }),
}));

/**
 * A permanent drawer that can be open or collapsed.
 */
export const SlidingDrawer: typeof MuiDrawer = styled(MuiDrawer)(
  ({ theme }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiDrawer-paper": {
      ...paperMixin(theme),
    },
  })
);
