// @flow
import * as React from "react";
import SelectEntityDialog from "./SelectEntityDialog";
import type { BobcaatAccount } from "../../../models/account.model";
import type { Channel } from "../../../models/channels/channel.model";
import { useStoreActions } from "../../../store/store";
import useTranslate from "../../../hooks/useTranslate";
import * as snacks from "../../../models/alerts.model";
import { capitalize } from "@mui/material";
import { useRemainingAccountCapacity } from "../../../store/selectors";
import {
  OAUTH_ERROR_CANCELLED,
  OAUTH_ERROR_DENIED,
  OAUTH_ERROR_UNKNOWN,
} from "../../../services/channels/oauth.service";
import type {
  LoginFlowResult,
  ResolvedChannelEntitySelection,
  SelectChannelEntityRejectReasons,
} from "../../../services/channels/binding.service";
import {
  completedEntitySelection,
  selectChannelEntity,
} from "../../../services/channels/binding.service";
import type { StoreActions } from "../../../store/store";
import * as accountService from "../../../services/account.service";
import type { Callback, ModelID } from "../../../types";

type BindParams = {
  account?: ?BobcaatAccount,
  portfolioId: ModelID,
  channel: Channel,
};

type HandleSelectEntityParams = {
  ...BindParams,
  actions: StoreActions,
  entitySelection: ResolvedChannelEntitySelection,
};

export type UseBindResult = [Callback, React.Node];

const handleSelectEntity = ({
  account,
  portfolioId,
  channel,
  actions,
  entitySelection,
}: HandleSelectEntityParams) => {
  const sharedParams = {
    entity_id: entitySelection.entity.id,
    access_token: entitySelection.entity.access_token,
    access_token_expiry: entitySelection.user.access_token_expiry,
    user_access_token: entitySelection.user.access_token,

    refresh_token: entitySelection.user.refresh_token,
    refresh_token_expiry: entitySelection.user.refresh_token_expiry,

    // Resend names as they may have changed since last refresh.
    entity_name: entitySelection.entity.name,
    user_name: entitySelection.user.user_name,
  };

  if (!account) {
    accountService.addAccount(actions)({
      ...sharedParams,

      // Account parameters
      portfolio_id: portfolioId,
      channel: channel.slug,

      // User details
      user_id: entitySelection.user.user_id,
      access_token_secret: entitySelection.user.access_token_secret,
    });
  } else {
    accountService.editAccount(actions)({ ...sharedParams, id: account.id });
  }
};

const useBind = ({
  account,
  channel,
  portfolioId,
}: BindParams): UseBindResult => {
  const actions = useStoreActions();
  const t = useTranslate();
  const [numAccountsLeft, maxAccounts] = useRemainingAccountCapacity();
  const [loginResult, setLoginResult] = React.useState<?LoginFlowResult>(null);

  const selectEntityFragment = React.useMemo(
    () =>
      !!loginResult && (
        <SelectEntityDialog
          refresh={!!account}
          channel={channel}
          user={loginResult.user}
          entities={loginResult.entities}
          onClose={() => setLoginResult(undefined)}
          onSelect={(entity) =>
            handleSelectEntity({
              account,
              portfolioId,
              channel,
              actions,
              entitySelection: completedEntitySelection(
                loginResult.user,
                entity
              ),
            })
          }
        />
      ),
    [actions, loginResult, account, portfolioId, channel]
  );

  const handleBind = React.useCallback(() => {
    if (!account && numAccountsLeft <= 0) {
      actions.snacks.append(
        snacks.localWarning({
          message: "DashboardSection.AccountCard.maxAccountsReached",
          messageData: { limit: maxAccounts },
        })
      );
    } else {
      selectChannelEntity(channel, account)
        .then((entitySelection) => {
          if (!entitySelection.resolved) setLoginResult(entitySelection.result);
          else
            handleSelectEntity({
              account,
              portfolioId,
              channel,
              actions,
              entitySelection,
            });
        })
        .catch((reason: SelectChannelEntityRejectReasons) => {
          if (reason === "accountNotFound") {
            actions.global.openModalAlert(
              snacks.modalLocalError({
                title: "DashboardSection.AccountCard.accountNotFound.title",
                message: "DashboardSection.AccountCard.accountNotFound.message",
                messageData: {
                  entity: capitalize(
                    t(`refer.channels.${channel.slug}.entity`)
                  ),
                },
              })
            );
          }
          if (reason === OAUTH_ERROR_DENIED) {
            actions.global.openModalAlert(
              snacks.modalLocalWarning({
                title: "DashboardSection.AccountCard.missingPermissions.title",
                message:
                  "DashboardSection.AccountCard.missingPermissions.message",
                messageData: { channel: channel.name },
              })
            );
          }
          // Ignore the common ones.
          if ([OAUTH_ERROR_UNKNOWN, OAUTH_ERROR_CANCELLED].includes(reason)) {
          }
          // Closing the window or cancelling will cause reject without reason.
          else if (reason) {
            if (!channel.wasLoginCancelled(reason)) {
              console.log("bind:selectChannelEntity closed: ", reason);
              actions.snacks.append(
                snacks.error({ message: reason.toString() })
              );
            }
          }
        });
    }
  }, [actions, account, portfolioId, channel, t, numAccountsLeft, maxAccounts]);

  return [handleBind, selectEntityFragment];
};

export default useBind;
