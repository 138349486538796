// @flow
import * as React from "react";
import InviteAFriendWidget from "./InviteAFriendWidget";
import {
  checkInviteFriendEmail,
  checkInviteFriendMessage,
  checkInviteFriendName,
} from "../../../util/validation.util";
import { FormActions, useForm } from "../../../hooks/useForm";
import { useCurrentUser } from "../../../store/selectors";
import { API as backend } from "../../../services/backend.service";
import * as snacks from "../../../models/alerts.model";
import { useStoreActions } from "../../../store/store";
import type { InviteFriendFormDef } from "./InviteAFriendWidget";

const FORM_DEF: InviteFriendFormDef = {
  name: { type: "string", validate: checkInviteFriendName, maxLength: 100 },
  email: { type: "string", validate: checkInviteFriendEmail, maxLength: 100 },
  message: {
    type: "string",
    validate: checkInviteFriendMessage,
    maxLength: 2048,
  },
};
const FORM_INITIAL = {
  name: "",
  email: "",
  message: "",
};

const InviteAFriendWidgetContainer: React.ComponentType<{}> = () => {
  const form = useForm<InviteFriendFormDef>(FORM_DEF, FORM_INITIAL);
  const user = useCurrentUser();
  const actions = useStoreActions();

  const handleConfirm = () => {
    if (FormActions.validate(form)) {
      backend.misc
        .inviteFriend(FormActions.collect(form.state))
        .then(() =>
          actions.snacks.append(
            snacks.localSuccess({ message: "InviteFriendForm.onSuccess" })
          )
        );
    }
  };

  return (
    <InviteAFriendWidget
      form={form}
      referralCode={user?.referral_code}
      onConfirm={handleConfirm}
    />
  );
};

export default InviteAFriendWidgetContainer;
