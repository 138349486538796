// @flow
import ajax from "../../lib/ajax.lib";
import { fileToImageDataURL } from "../../lib/images.lib";
import type { DataURI, Integer } from "../../types";
import type { APIResult, APINoContentResult } from "./types";

type FreeformFeedbackParams = {
  title: string,
  description: string,
  images: File[],
};

type ProcessedFreeformFeedbackParams = {
  ...Omit<FreeformFeedbackParams, "images">,
  images: DataURI[],
};

type PostRatingParams = {
  submitted: boolean,
  // Between 0 and 5.
  rating: number,
  feedback: ?string,
  duration: Integer,
};

export const postFreeformFeedback = async (
  params: FreeformFeedbackParams
): Promise<APINoContentResult> => {
  return ajax.post<ProcessedFreeformFeedbackParams, APINoContentResult>(
    "/feedback/freeform",
    {
      ...params,
      images: await Promise.all(params.images.map(fileToImageDataURL)),
    }
  );
};

export const shouldDisplayAppRating = (): Promise<boolean> =>
  ajax
    .get<APIResult<{ display: boolean }>>("/feedback/rating")
    .then((response) => response.data.data.display);

export const submitAppRating = (
  params: PostRatingParams
): Promise<APINoContentResult> =>
  ajax.post<PostRatingParams, APINoContentResult>("/feedback/rating", params);
