// @flow
import * as React from "react";
import noop from "lodash/noop";
import { bound } from "../../../../lib/lodashex.lib";
import { blobToFile, byteStringToBlob } from "../../../../lib/files.lib";
import { useFilesValidator } from "../../../../hooks/useFilesValidator";
import type { Props as FilesValidatorProps } from "../../../../hooks/useFilesValidator";
import type { Mimetype } from "../../../../types";
import * as env from "../../../../config/env.config";

// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
const SCOPE = "https://www.googleapis.com/auth/drive.readonly";

type Props = {
  ...FilesValidatorProps,
  disabled?: boolean,
  onSelect?: (File[]) => any,
  children: React.Element<any>,
};

type TokenClient = {
  callback: (response: Object) => void,
  requestAccessToken: ({ prompt: string }) => void,
};

class PickerBuilder {
  addView: (string) => PickerBuilder;
  setSelectableMimeTypes: (string) => PickerBuilder;
  setMaxItems: (number) => PickerBuilder;
  setSize: (width: number, height: number) => PickerBuilder;
  setOAuthToken: (string) => PickerBuilder;
  setDeveloperKey: (string) => PickerBuilder;
  enableFeature: (string) => PickerBuilder;
  disableFeature: (string) => PickerBuilder;
  setCallback: ((any) => void) => PickerBuilder;
  build: () => { setVisible: (boolean) => void };
}

type Picker = {
  PickerBuilder: typeof PickerBuilder,
  ViewId: {
    DOCS_IMAGES_AND_VIDEOS: string,
  },
  Response: {
    ACTION: string,
    DOCUMENTS: string,
  },
  Action: {
    PICKED: string,
  },
  Feature: {
    MULTISELECT_ENABLED: string,
  },
  Document: {
    ID: string,
    NAME: string,
    MIME_TYPE: string,
  },
};

/**
 * The client used to request access.
 */
let tokenClient: TokenClient = { requestAccessToken: noop, callback: noop };

/**
 * The picker namespace. Initialised with a mock for autocomplete.
 * @type {*}
 */
let picker: Picker;

/**
 * External entrypoint to load the proper API.
 * Should be called at startup.
 */
export const initClient = () => {
  picker = window.google.picker;
  window.gapi.client.init({
    apiKey: env.GOOGLE_API_KEY,
    discoveryDocs: [DISCOVERY_DOC],
  });
};

export const initTokenClient = () => {
  tokenClient = window.google.accounts.oauth2.initTokenClient({
    client_id: env.GOOGLE_CLIENT_ID,
    scope: SCOPE,
    callback: "", // Defined later.
  });
};

/**
 * Create nd display a Google Drive picker.
 * @param accept {string[]} list of accepted mime types.
 * @param maxFiles {number} Max number of files to pick.
 * @return {function(*=): Promise<unknown>}
 */
const showPicker =
  (accept: Mimetype[], maxFiles: number = 15) =>
  (oauthToken: string) =>
    new Promise((resolve) => {
      const width = bound(566, 1051, 0.8 * window.screen.availWidth);
      const height = bound(350, 650, 0.8 * window.screen.availHeight);
      const builder = new picker.PickerBuilder()
        .addView(picker.ViewId.DOCS_IMAGES_AND_VIDEOS)
        .setSelectableMimeTypes(accept.join(","))
        .setMaxItems(maxFiles)
        .setSize(width, height)
        .setOAuthToken(oauthToken)
        .setDeveloperKey(env.GOOGLE_API_KEY)
        .setCallback((data) => {
          if (data[picker.Response.ACTION] === picker.Action.PICKED) {
            resolve(data);
          }
        });
      if (maxFiles > 1) {
        builder.enableFeature(picker.Feature.MULTISELECT_ENABLED);
      } else {
        builder.disableFeature(picker.Feature.MULTISELECT_ENABLED);
      }
      builder.build().setVisible(true);
    });

const authenticate = (): Promise<string> =>
  new Promise((resolve, reject) => {
    tokenClient.callback = (tokenResponse: Object) => {
      if (
        tokenResponse &&
        !tokenResponse.error &&
        window.google.accounts.oauth2.hasGrantedAnyScope(tokenResponse, SCOPE)
      ) {
        resolve(tokenResponse.access_token);
      } else {
        reject();
      }
    };
    tokenClient.requestAccessToken({ prompt: "" });
  });

/**
 * Takes the raw Google Drive response and downloads the selected files.
 * @param data
 * @return {Promise<unknown[]>}
 */
const documentsToFiles = (data: any): Promise<File[]> =>
  Promise.all(
    data[picker.Response.DOCUMENTS].map((doc) =>
      window.gapi.client.drive.files
        .get({
          fileId: doc[picker.Document.ID],
          alt: "media",
        })
        .then((file) =>
          blobToFile(doc[picker.Document.NAME])(
            byteStringToBlob(file.body, file.headers["Content-Type"])
          )
        )
    )
  );

/**
 *
 */
const GooglePicker: React.ComponentType<Props> = ({
  disabled,
  children,
  onSelect = noop,
  ...validatorProps
}: Props) => {
  const handleValidate = useFilesValidator(validatorProps);

  const handleClick = () => {
    authenticate()
      .then(showPicker(validatorProps.accept ?? ["*"], validatorProps.maxFiles))
      .then(documentsToFiles)
      .then(handleValidate)
      .then(onSelect);
  };

  return disabled
    ? children
    : React.cloneElement(children, { onClick: handleClick });
};

export default GooglePicker;
