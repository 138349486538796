// @flow
import * as React from "react";
import useTranslate from "../../hooks/useTranslate";
import ColoredChip from "../lib/display/ColoredChip";
import { Body2 } from "../lib/display/Text";
import type { Integer } from "../../types";
import { Tooltip } from "@mui/material";
import { CommentRounded } from "@mui/icons-material";

type Props = {
  count: Integer,
};

const ThreadsChip: React.ComponentType<Props> = React.memo(({ count }) => {
  const t = useTranslate();
  if (!count) return null;
  return (
    <Tooltip title={t(`global.commentCount`, { count })}>
      <ColoredChip paletteColor="firstComment" dense>
        <CommentRounded fontSize="inherit" color="inherit" />
        <Body2>{count}</Body2>
      </ColoredChip>
    </Tooltip>
  );
});

export default ThreadsChip;
