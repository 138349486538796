// @flow
import * as React from "react";
import type { Props as AvatarProps } from "../Avatar";
import { SvgIcon } from "@mui/material";
import Avatar from "../Avatar";
import type { ThemeColor } from "../../../../stubs/mui/theming";

export type Props = {
  ...AvatarProps,
  color?: ThemeColor,
  Icon: typeof SvgIcon,
};

const IconAvatar: React.ComponentType<Props> = React.memo(
  ({ Icon, color, size, ...props }) => (
    <Avatar
      bgColor={color ? color + ".light" : color}
      color={color ? color + ".dark" : color}
      // auto size to counter the default avatar size.
      size="auto"
      // the specified size is the font size one. Make some room
      // for the borders.
      style={{ fontSize: size, padding: 8 }}
      {...props}
    >
      <Icon color="inherit" fontSize="inherit" />
    </Avatar>
  )
);
export default IconAvatar;
