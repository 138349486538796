// @flow
import * as React from "react";
import { useScopedPost } from "../../../scopes/scopes";
import type { BrowserRoute } from "../../../config/routes.config";
import routes from "../../../config/routes.config";
import {
  NO_XS_HORIZ_MARGIN,
  PrimaryPagePanel,
} from "../../../components/layout/PagePanel";
import { ColumnStack, RowStack } from "../../../components/lib/layout/stacks";
import { StickToTheTop } from "../../../components/lib/layout/placement";
import { PostBuilderContextProvider } from "../../../contexts/postBuilder";
import type { IdentifiedMutablePost } from "../../../models/post.model";
import SelectPostTargetWidget from "../../../components/widgets/postCreator/SelectPostTargetWidget";
import useLocationParams from "../../../hooks/useLocationParams";
import { parseModelId } from "../../../models/base.model";
import CurrentPostDiagnosticErrors from "../../../components/widgets/postCreator/CurrentPostDiagnosticErrors";
import PublishButton from "./PublishButton";
import PostContentInput from "../../../components/widgets/postCreator/PostContentInput";
import PostScheduleAndStatusWidget from "../../../components/widgets/postCreator/PostScheduleAndStatusWidget";
import PostAttachmentsWidget from "../../../components/widgets/postCreator/PostAttachmentsWidget";
import FirstCommentWidget from "../../../components/widgets/postCreator/FirstCommentWidget";
import type { SubChannel } from "../../../models/subChannel.model";
import * as channelService from "../../../services/backend.service/channels";
import PollWidget from "../../../components/widgets/postCreator/PollWidget";
import ThreadWidget from "../../../components/widgets/postCreator/ThreadWidget";
import PostTitleInput from "../../../components/widgets/postCreator/PostTitleInput";
import PostLinkWidget from "../../../components/widgets/postCreator/PostLinkWidget";
import DynamicContentWidget from "../../../components/widgets/postCreator/DynamicContentWidget";
import { useStore } from "../../../store/store";
import useIsSmallOrSmaller from "../../../hooks/useIsSmallOrSmaller";
import { Box } from "@mui/material";

type Props = {
  post?: { ...Partial<IdentifiedMutablePost>, ... },
  subchannel?: ?SubChannel,
  route: BrowserRoute,
};

const AddEditPostPageSmallDown: React.ComponentType<Props> = ({
  post,
  subchannel,
  route,
}) => {
  return (
    <PostBuilderContextProvider initial={{ post, subchannel }}>
      <PrimaryPagePanel size="fill" route={route}>
        <ColumnStack className={NO_XS_HORIZ_MARGIN}>
          <SelectPostTargetWidget />
          <PostTitleInput />
          <PostContentInput />
          <DynamicContentWidget />
          <PostLinkWidget />
          <PostAttachmentsWidget />
          <FirstCommentWidget />
          <ThreadWidget />
          <PollWidget />
          <PostScheduleAndStatusWidget />
          <CurrentPostDiagnosticErrors />
          <Box padding={2}>
            <PublishButton />
          </Box>
        </ColumnStack>
      </PrimaryPagePanel>
    </PostBuilderContextProvider>
  );
};

const AddEditPostPageMediumUp: React.ComponentType<Props> = ({
  post,
  subchannel,
  route,
}) => {
  return (
    <PostBuilderContextProvider initial={{ post, subchannel }}>
      <PrimaryPagePanel size="fill" route={route}>
        <RowStack alignItems="flex-start" spacing={3} overflow="auto">
          <StickToTheTop>
            <ColumnStack width={380}>
              <SelectPostTargetWidget />
              <PostScheduleAndStatusWidget />
              <CurrentPostDiagnosticErrors />
              <PublishButton />
            </ColumnStack>
          </StickToTheTop>
          <ColumnStack minWidth={400} maxWidth={700} flexGrow={1}>
            <PostTitleInput />
            <PostContentInput />
            <DynamicContentWidget />
            <PostLinkWidget />
            <PostAttachmentsWidget />
            <FirstCommentWidget />
            <ThreadWidget />
            <PollWidget />
          </ColumnStack>
        </RowStack>
      </PrimaryPagePanel>
    </PostBuilderContextProvider>
  );
};

const AddEditPostPage: React.ComponentType<Props> = (props) =>
  useIsSmallOrSmaller() ? (
    <AddEditPostPageSmallDown {...props} />
  ) : (
    <AddEditPostPageMediumUp {...props} />
  );

export const AddPostPage: React.ComponentType<{}> = () => {
  const { accountId } = useLocationParams(["accountId"]);
  const [state, actions] = useStore();

  // If there was a builder to carry over, clean it now it has been used.
  React.useEffect(() => {
    if (!!state.post.builderPayload) {
      actions.post.onSetBuilderPayload(null);
    }
  }, [state.post.builderPayload, actions]);

  return (
    <AddEditPostPage
      route={routes.app.nested.portfolios.nested.addPost}
      post={
        state.post.builderPayload?.post ?? {
          account_id: parseModelId(accountId) ?? 0,
        }
      }
      subchannel={state.post.builderPayload?.subchannel}
    />
  );
};

export const EditPostPage: React.ComponentType<{}> = () => {
  const post = useScopedPost();
  const [subchannel, setSubchannel] = React.useState<?SubChannel>(undefined);

  // If there is a subchannel id, try loading the object now.
  // It shouldn't fail, but if it does just give up instead of raising.
  React.useEffect(() => {
    if (
      post?.account_id &&
      post.subchannel_id &&
      typeof subchannel === "undefined"
    ) {
      channelService
        .requestSubchannelDetails(post.account_id, post.subchannel_id)
        .then(setSubchannel)
        .catch(() => setSubchannel(null));
    }
  }, [post?.account_id, post?.subchannel_id, subchannel]);

  if (!post || (post.subchannel_id && typeof subchannel === "undefined"))
    return null;

  return (
    <AddEditPostPage
      post={{ ...post }}
      subchannel={subchannel}
      route={routes.app.nested.portfolios.nested.post.nested.edit}
    />
  );
};
