// @flow
import * as React from "react";
import BlurBackdrop from "../../components/lib/surfaces/BlurBackdrop";
import { AlignCenter, Body1, Header } from "../../components/lib/display/Text";
import { Link, useTheme } from "@mui/material";
import useTranslate from "../../hooks/useTranslate";
import { useForceLogOut } from "../../store/selectors";
import * as links from "../../util/links.util";
import { PowerOff } from "@mui/icons-material";
import IconAvatar from "../../components/lib/display/icons/IconAvatar";
import { Center } from "../../components/lib/layout/placement";

const DisconnectedModal: React.ComponentType<{}> = () => {
  const t = useTranslate();
  const show = useForceLogOut();
  const theme = useTheme();
  React.useEffect(() => {
    if (show) {
      setTimeout(() => (window.location.href = "/login"), 5000);
    }
  }, [show]);

  return (
    show && (
      <BlurBackdrop open style={{ zIndex: theme.zIndex.drawer + 10 }}>
        <Center>
          <IconAvatar Icon={PowerOff} size={96} color="secondary" />
          <AlignCenter style={{ marginTop: 32 }}>
            <Header>{t("Page.DisconnectedDialog.titleAuth")}</Header>
            <Body1>{t("Page.DisconnectedDialog.message")}</Body1>
            <Link style={{ display: "block" }} href={links.login}>
              {t("Page.DisconnectedDialog.clickToGoNow")}
            </Link>
          </AlignCenter>
        </Center>
      </BlurBackdrop>
    )
  );
};

export default DisconnectedModal;
