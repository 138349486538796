// @flow
import * as React from "react";
import type { Subscription } from "../../models/subscription.model";
import { isSubscriptionStateOK } from "../../models/subscription.model";
import { isIOS } from "../../lib/platform.lib";
import { useCurrentSubscription } from "../../store/selectors";
import { Alert, AlertTitle, Link } from "@mui/material";
import useTranslate from "../../hooks/useTranslate";
import Translate from "../lib/display/Translate";
import * as links from "../../util/links.util";

const ALERTS = {
  noSubscription: {
    severity: "info",
    title: "Page.SubscriptionPanel.welcomeAlert.title",
    message: "Page.SubscriptionPanel.welcomeAlert.message",
  },
  noSubscription_iOS: {
    severity: "info",
    title: "Page.SubscriptionPanel.welcomeAlert_iOS.title",
    message: "Page.SubscriptionPanel.welcomeAlert_iOS.message",
  },
  badSubscription: {
    severity: "error",
    title: "Page.SubscriptionPanel.errorAlert.title",
    message: "Page.SubscriptionPanel.errorAlert.message",
    includeLink: true,
  },
  badSubscription_iOS: {
    severity: "error",
    title: "Page.SubscriptionPanel.errorAlert_iOS.title",
    message: "Page.SubscriptionPanel.errorAlert_iOS.message",
  },
};

const getBaseAlertName = (subscription: ?Subscription) => {
  if (!subscription) return "noSubscription";
  if (!isSubscriptionStateOK(subscription.status)) return "badSubscription";
  return "";
};
const getAlert = (subscription: ?Subscription) =>
  ALERTS[getBaseAlertName(subscription) + (isIOS ? "_iOS" : "")];

const SubscriptionStateAlert: React.ComponentType<{}> = React.memo(() => {
  const subscription = useCurrentSubscription();
  const t = useTranslate();
  const alert = getAlert(subscription);
  if (!alert) return <React.Fragment />;

  return (
    <Alert severity={alert.severity}>
      <AlertTitle>{t(alert.title)}</AlertTitle>
      <Translate id={alert.message} />
      {alert.includeLink && (
        <Link color="secondary" target="_blank" href={links.contact("tech")}>
          {t("global.supportTeam")}
        </Link>
      )}
    </Alert>
  );
});

export default SubscriptionStateAlert;
