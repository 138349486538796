import { loginOAuth2 } from "./oauth.service";
import { clientId } from "./facebook.service";
import * as env from "../../config/env.config";

const SIGN_IN_URL =
  "https://www.facebook.com/v18.0/dialog/oauth?" +
  "response_type=code&" +
  `client_id=${clientId()}&` +
  `redirect_uri=${env.API_URL}${env.INSTAGRAM_REDIRECT_ENDPOINT}&` +
  `state=${env.FACEBOOK_STATE}&` +
  `scope=${env.FACEBOOK_PERMISSIONS}`;

const POPUP_FEATURES =
  "height=700,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no," +
  "location=no,directories=no, status=yes";

export const login = () => loginOAuth2(SIGN_IN_URL, POPUP_FEATURES);

export const wasLoginCancelled = (e) => e.error === "access_denied";
