// @flow
import * as React from "react";
import {
  Autocomplete as AutocompleteImpl,
  InputAdornment,
  TextField,
} from "@mui/material";
import type { Props as AutocompleteProps } from "../../../stubs/mui/Autocomplete";
import type { Props as TextFieldProps } from "../../../stubs/mui/TextField";
import useTranslate from "../../../hooks/useTranslate";
import { Body1, Body2 } from "../display/Text";
import { RowStack } from "../layout/stacks";
import get from "lodash/get";

export type BaseAutocompleteItem = { [string]: any };
interface AdornmentComponentProps<T> {
  value: ?T;
}

export type Props<T> = {
  ...Omit<AutocompleteProps, "getOptionLabel" | "renderInput">,
  TextFieldProps?: Omit<TextFieldProps, "value" | "onChange">,
  options: T[],
  optionPrimaryLabel: $Keys<T> | string,
  optionSecondaryLabel?: $Keys<T> | string,
  translateLabel?: boolean,
  adornOptions?: boolean,
  AdornmentComponent?: React.ComponentType<AdornmentComponentProps<T>>,
};

const Autocomplete = <T: BaseAutocompleteItem>({
  TextFieldProps,
  optionPrimaryLabel,
  optionSecondaryLabel,
  translateLabel = true,
  adornOptions,
  AdornmentComponent,
  value,
  slotProps,
  ...props
}: Props<T>): React.Node => {
  const t = useTranslate();
  const getOptionLabel = React.useMemo(
    () =>
      translateLabel
        ? (opt: T) => t(get(opt, optionPrimaryLabel))
        : (opt: T) => get(opt, optionPrimaryLabel),
    [translateLabel, t, optionPrimaryLabel]
  );
  const getSecondaryLabel = React.useMemo(
    () =>
      !optionSecondaryLabel
        ? null
        : translateLabel
        ? (opt: T) => t(get(opt, optionSecondaryLabel))
        : (opt: T) => get(opt, optionSecondaryLabel),
    [translateLabel, t, optionSecondaryLabel]
  );
  const Adornment = React.useCallback(
    ({ value }: { value: ?T }) =>
      AdornmentComponent ? (
        <InputAdornment position="end">
          <AdornmentComponent value={value} />
        </InputAdornment>
      ) : undefined,
    [AdornmentComponent]
  );

  const renderOption = React.useCallback(
    (props: Object, opt: T) => (
      <RowStack {...props}>
        {adornOptions && AdornmentComponent && (
          <AdornmentComponent value={opt} />
        )}
        <div style={{ display: "block" }}>
          <Body1>{getOptionLabel(opt)}</Body1>
          {getSecondaryLabel && (
            <Body2 color="textSecondary" display="block">
              {getSecondaryLabel(opt)}
            </Body2>
          )}
        </div>
      </RowStack>
    ),
    [adornOptions, AdornmentComponent, getOptionLabel, getSecondaryLabel]
  );

  const { InputProps, ...restTextFieldProps } = TextFieldProps ?? {};
  return (
    <AutocompleteImpl
      value={value}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      slotProps={{
        ...slotProps,
        paper: {
          sx: { maxHeight: 500, minWidth: 280 },
          elevation: 10,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...InputProps,
            ...params.InputProps,
            disableUnderline: true,
            startAdornment: value ? <Adornment value={value} /> : undefined,
          }}
          {...restTextFieldProps}
        />
      )}
      {...props}
    />
  );
};

export default Autocomplete;
