// @flow
import * as React from "react";
import ActionText from "./ActionText";
import type { Props as ActionTextProps } from "./ActionText";
import copyToClipboard from "../../../lib/clipboard.lib";
import { useStoreActions } from "../../../store/store";
import * as snacks from "../../../models/alerts.model";
import { Tooltip } from "@mui/material";
import { FileCopyRounded } from "@mui/icons-material";
import useTranslate from "../../../hooks/useTranslate";

type Props = {
  ...Omit<ActionTextProps, "onActionClick" | "actionIcon">,
  children: string,
};

const CopyActionText: React.ComponentType<Props> = ({ children, ...props }) => {
  const actions = useStoreActions();
  const t = useTranslate();
  const handleClick = React.useCallback(() => {
    copyToClipboard(children).then(() =>
      actions.snacks.append(
        snacks.localInfo({
          message: "global.copiedToClipboard",
        })
      )
    );
  }, [actions, children]);

  return (
    <ActionText
      onActionClick={handleClick}
      actionIcon={
        <Tooltip title={t("global.copy")}>
          <FileCopyRounded fontSize="small" color="action" />
        </Tooltip>
      }
      {...props}
    >
      {children}
    </ActionText>
  );
};

export default CopyActionText;
