// @flow
import * as React from "react";
import identity from "lodash/identity";
import ajax from "../lib/ajax.lib";
import { useStoreActions } from "../store/store";
import * as snacks from "../models/alerts.model";
import { AxiosError } from "axios";
import type { AxiosConfig } from "../lib/ajax.lib";

const useAjaxErrorInterceptor = () => {
  const actions = useStoreActions();
  const onAjaxError = React.useCallback(
    (error: any) => {
      const axiosError = (error: AxiosError<any>);
      if (
        // If not interceptor turned off.
        !(error.config: ?AxiosConfig)?.disableErrorInterceptor &&
        // and not connectivity issue.
        axiosError.response
      ) {
        const errorMessage = axiosError.response?.data.message;
        actions.snacks.append(
          errorMessage
            ? snacks.error({ message: errorMessage })
            : snacks.localError({ message: "global.connectivityIssues" })
        );
        // If user is not authentified, force log out.
        if (axiosError.response?.status === 401) {
          actions.global.forceDisconnect();
        }
      }
      return Promise.reject(error);
    },
    [actions]
  );

  // Use useLayoutEffect to install this immediately.
  // Otherwise, some effects may run before the interceptior is installed.
  React.useLayoutEffect(() => {
    const listenersId = ajax.interceptors.response.use(identity, onAjaxError);
    return () => ajax.interceptors.response.eject(listenersId);
  }, [onAjaxError]);
};

export default useAjaxErrorInterceptor;
