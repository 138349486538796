// @flow
import * as React from "react";
import useIsVisible from "../../../hooks/useIsVisible";
import type { Callback } from "../../../types";

const withOnVisible =
  <Props>(
    Component: React.AbstractComponent<Props, HTMLElement>
  ): React.ComponentType<{
    ...Props,
    onVisible: Callback,
  }> =>
  ({ onVisible, ...props }) => {
    const ref = React.useRef<?HTMLElement>();
    const isVisible = useIsVisible(ref.current);
    React.useEffect(() => {
      onVisible && isVisible && onVisible();
    }, [isVisible, onVisible]);

    return <Component {...props} ref={ref} />;
  };

export default withOnVisible;
