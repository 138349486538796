// @flow
import * as React from "react";
import VizGridItem from "./VizGridItem";
import { styled } from "@mui/material/styles";
import type { Post } from "../../../../models/post.model";
import DragAndDropArea from "../../../lib/inputs/DragAndDropArea";
import { isPublished } from "../../../../models/post.model";

const IMAGE_SIZE = 18; // vh
const XS_IMAGE_SIZE = 33.3; // vw

type Props = {
  posts: Post[],
  onItemsReordered: (
    reorderedPosts: Post[],
    movedPost: Post,
    fromIndex: number,
    toIndex: number
  ) => any,
};

const Root = styled(DragAndDropArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: IMAGE_SIZE * 3 + "vh",
  height: IMAGE_SIZE * 4 + "vh",
  backgroundColor: theme.palette.divider,
  [theme.breakpoints.down("sm")]: {
    borderRadius: 0,
    width: XS_IMAGE_SIZE * 3 + "vw",
    height: XS_IMAGE_SIZE * 4 + "vw",
  },
}));

const SizedVizGridItem = styled(VizGridItem)(({ theme }) => ({
  padding: 0.5,
  height: IMAGE_SIZE + "vh",
  width: IMAGE_SIZE + "vh",
  [theme.breakpoints.down("sm")]: {
    width: XS_IMAGE_SIZE + "vw",
    height: XS_IMAGE_SIZE + "vw",
  },
}));

const renderItem = (post: Post) => <SizedVizGridItem post={post} />;

const VizGrid: React.ComponentType<Props> = ({ posts, onItemsReordered }) => (
  <Root
    onItemsReordered={onItemsReordered}
    layout="grid"
    items={posts}
    renderer={renderItem}
    isItemDisabled={isPublished}
  />
);

export default VizGrid;
