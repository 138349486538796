// @flow
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import type { Provider } from "../reactTypes";
import type { ApprovalRequest } from "../models/approvalRequest.model";
import type { PostApproval } from "../models/postApproval.model";
import type { Post } from "../models/post.model";
import { API as backend } from "../services/backend.service";
import * as approvalService from "../services/approval.service";
import { replaceItem } from "../lib/lodashex.lib";
import { byProp } from "../models/base.model";
import * as Sentry from "@sentry/react";
import * as postService from "../services/backend.service/post";
import useConfirmDialog from "../components/lib/feedback/useConfirmDialog";
import { UserToken } from "../lib/session.lib";
import { useStoreActions } from "../store/store";
import type { BobcaatAccountPublicInfo } from "../models/account.model";
import { getTime } from "../models/post.model";

type _ReviewApprovalRequestContext = {
  approvalRequest: ?ApprovalRequest,
  postApprovals: PostApproval[],
  posts: Post[],
  accounts: BobcaatAccountPublicInfo[],
  loading: boolean,
  onUpdatePostApproval: (PostApproval) => any,
  onLoadApprovalRequest: (hashId: string) => any,
  onSendFeedback: () => any,
};

export const ReviewApprovalRequestContext: React.Context<_ReviewApprovalRequestContext> =
  React.createContext({
    approvalRequest: null,
    postApprovals: [],
    posts: [],
    accounts: [],
    loading: false,
    onUpdatePostApproval: noop,
    onLoadApprovalRequest: noop,
    onSendFeedback: noop,
  });

const ReviewApprovalRequestProvider: Provider<_ReviewApprovalRequestContext> =
  ({ children }) => {
    const [approvalRequest, setApprovalRequest] =
      React.useState<?ApprovalRequest>(null);
    const [postApprovals, setPostApprovals] = React.useState<PostApproval[]>(
      []
    );
    const [posts, setPosts] = React.useState<Post[]>([]);
    const [accounts, setAccounts] = React.useState<BobcaatAccountPublicInfo[]>(
      []
    );
    const actions = useStoreActions();

    const dialogType = UserToken.isValid()
      ? "confirmReadyToReview"
      : "confirmSendFeedback";
    const approvalRequestId = approvalRequest?.id;

    const handleUpdatePostApproval = React.useCallback(
      (postApproval: PostApproval) => {
        approvalService
          .updatePostApproval(actions)(postApproval)
          .then((result) => {
            setPostApprovals((prevApprovals) =>
              replaceItem(
                prevApprovals,
                byProp("post_id", result.post_id),
                result
              )
            );
          })
          .catch(Sentry.captureException);
      },
      [actions]
    );

    const handleLoadApprovalRequest = React.useCallback((hashId: string) => {
      return backend.approval
        .getApprovalRequest(hashId)
        .then((newApprovalRequest: ApprovalRequest) => {
          setApprovalRequest(newApprovalRequest);
          backend.approval
            .getApprovalRequestPostApprovals(newApprovalRequest.id)
            .then(setPostApprovals)
            .catch(Sentry.captureException);
          if (!isEmpty(newApprovalRequest.posts)) {
            postService
              .getPostsByIds(newApprovalRequest.posts)
              .then((loadedPosts) => {
                setPosts(sortBy(loadedPosts, getTime));
                backend.account
                  .searchAccountsPublicInfo(
                    uniq(loadedPosts.map((p) => p.account_id))
                  )
                  .then(setAccounts);
              })
              .catch(Sentry.captureException);
          }
        });
    }, []);

    const handleSendFeedback = React.useCallback(() => {
      if (approvalRequestId) {
        return approvalService
          .commitApprovalRequest(actions)(approvalRequestId)
          .then(() =>
            handleLoadApprovalRequest(approvalRequest?.hash_id ?? "")
          );
      }
    }, [
      actions,
      handleLoadApprovalRequest,
      approvalRequestId,
      approvalRequest?.hash_id,
    ]);

    const [onRequestSendFeedback, confirSendFeebackDlg] = useConfirmDialog(
      {
        DialogProps: {
          name: "send-feedback",
          title: `ApprovalPage.Main.${dialogType}.title`,
          message: `ApprovalPage.Main.${dialogType}.message`,
        },
      },
      handleSendFeedback
    );

    const context = {
      approvalRequest,
      postApprovals,
      posts,
      accounts,
      loading: !approvalRequest || isEmpty(postApprovals),
      onUpdatePostApproval: handleUpdatePostApproval,
      onLoadApprovalRequest: handleLoadApprovalRequest,
      onSendFeedback: onRequestSendFeedback,
    };

    return (
      <ReviewApprovalRequestContext.Provider value={context}>
        {confirSendFeebackDlg}
        {children}
      </ReviewApprovalRequestContext.Provider>
    );
  };

export default ReviewApprovalRequestProvider;
