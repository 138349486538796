// @flow
import * as React from "react";
import File from "./File";
import type { Props as FileProps } from "./File";
import { styled } from "@mui/material/styles";
import type { CSSProps } from "../../../reactTypes";

type Props = {
  src: FileProps["src"],
  ...CSSProps,
};

const Root = styled("div")(({ theme }) => ({
  display: "flex-inline",
  justifyContent: "center",
  color: "#DDD",
  height: "100%",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
}));

const PDFPreview: React.ComponentType<Props> = React.forwardRef(
  ({ src, ...props }, ref) => {
    return (
      <Root ref={ref} {...props}>
        <File
          ref={ref}
          src={src}
          width={64}
          openOnClick
          style={{ display: "inline-block" }}
        />
      </Root>
    );
  }
);

export default PDFPreview;
