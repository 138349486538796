// @flow
import ajax from "../../lib/ajax.lib";
import type { Email, URLStr } from "../../types";
import type { APINoContentResult, APIResult } from "./types";

type InviteFriendsParams = {
  name: string,
  email: Email,
  message: string,
};

export type LinkPreview = {
  title: string,
  image: URLStr,
  description: URLStr,
};

export const inviteFriend = (
  params: InviteFriendsParams
): Promise<APINoContentResult> =>
  ajax.post<InviteFriendsParams, APINoContentResult>("/misc/invite", params);

export const previewLink = (url: URLStr): Promise<LinkPreview> =>
  ajax
    .get<APIResult<LinkPreview>>(
      "/misc/preview/" + encodeURIComponent(encodeURIComponent(url))
    )
    .then((response) => response.data.data);
