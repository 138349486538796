// @flow
import ajax from "../../lib/ajax.lib";
import type { APIToken, APIResult, APIOKResult } from "./types";

const REDIRECT_TO_APP_BASE = `/?t=`;

type SignInParams = {
  email: string,
  password: string,
};

type ResetPasswordParams = {
  reset_token: string,
  password: string,
};

export const signIn = (payload: SignInParams): Promise<void> =>
  ajax
    .post<SignInParams, APIResult<APIToken>>("/auth/signin", payload)
    .then((r) => {
      window.location.href = REDIRECT_TO_APP_BASE + r.data.data.token;
    });

export const requestPasswordReset = (username: string): mixed =>
  ajax.get<APIOKResult>(`/auth/reset?email=${username}`);

export const resetPassword = (payload: ResetPasswordParams): Promise<void> =>
  ajax
    .post<ResetPasswordParams, APIResult<APIToken>>("/auth/reset", payload)
    .then((r) => {
      window.location.href = REDIRECT_TO_APP_BASE + r.data.data.token;
    });

export const metamorph = (email: string): mixed =>
  ajax.get<APIResult<APIToken>>(`auth/${email}`).then((r) => {
    window.location.href = REDIRECT_TO_APP_BASE + r.data.data.token;
  });

export const renewToken = (): Promise<APIToken> =>
  ajax.get<APIResult<APIToken>>("auth/token").then((r) => r.data.data);

export const checkToken = (): Promise<void> =>
  ajax.get<void>("auth/token/status").then(() => undefined);
