// @flow
import * as React from "react";
import FormDialog from "../../../../lib/feedback/FormDialog";
import type { Callback } from "../../../../../types";
import type { Post } from "../../../../../models/post.model";
import { Alert, List } from "@mui/material";
import StdListItem from "../../../../lib/display/listItems";
import { RowStack } from "../../../../lib/layout/stacks";
import PostTitle from "../../../../display/PostTitle";
import { TextPreview } from "../../../PostsTable/PostsTable";
import MultiChannelIcon from "../../../../lib/display/icons/MultiChannelIcon";
import { styled } from "@mui/material/styles";
import ScheduleTimePicker from "../../../../inputs/ScheduleTimePicker";
import { Moment } from "../../../../../types";
import { localTimezone } from "../../../../../models/timezone.model";
import useTranslate from "../../../../../hooks/useTranslate";
import { ListAvatarPostMediaThumbnail } from "../../../../display/PostMediaThumbnail";

export type Props = {
  open?: boolean,
  onSubmit: Callback,
  onCancel: Callback,
  posts: Post[],
  onTimeChange: (Post, ?Moment) => any,
  onDateChange: (Post, ?Moment) => any,
};

const StyledStdListItem = styled(StdListItem)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ReorderPostsDialog: React.ComponentType<Props> = ({
  open,
  onSubmit,
  onCancel,
  posts,
  onTimeChange,
  onDateChange,
}) => {
  const t = useTranslate();
  const schedulesMissing = !!posts.find((p) => !p.schedule_time);
  return (
    <FormDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onCancel}
      title="ReorderPostsDialog.title"
      text="ReorderPostsDialog.body"
      name="reporder-posts"
      maxWidth="sm"
      formProps={{
        submitTextId: "ReorderPostsDialog.submit",
        disabled: schedulesMissing,
      }}
      drawerOnMobile
    >
      <List>
        {posts.map((post) => (
          <StyledStdListItem
            key={post.id}
            avatar={<ListAvatarPostMediaThumbnail post={post} />}
            primary={
              <RowStack>
                <PostTitle title={post.title} />
                <TextPreview color="textSecondary" whiteSpace="no-wrap">
                  {post.content}
                </TextPreview>
              </RowStack>
            }
            secondary={
              <RowStack>
                <MultiChannelIcon
                  channels={post.channels}
                  limit={3}
                  size="small"
                />
                <ScheduleTimePicker
                  fieldProps={{ size: "small" }}
                  timezone={post.timezone ?? localTimezone()}
                  selectedDate={post.schedule_time}
                  onTimeChange={(d) => onTimeChange(post, d)}
                  onDateChange={(d) => onDateChange(post, d)}
                />
              </RowStack>
            }
          />
        ))}
      </List>
      {schedulesMissing && (
        <Alert severity="warning">
          {t("ReorderPostsDialog.schedulesMissing")}
        </Alert>
      )}
    </FormDialog>
  );
};

export default ReorderPostsDialog;
