// @flow
import * as React from "react";
import type { Plan, PlanFlavour } from "../../../models/plan.model";
import type { Callback } from "../../../types";
import { styled } from "@mui/material/styles";
import { Box, List, ListItem, ListItemText, Paper } from "@mui/material";
import { InlineRowStack } from "../../lib/layout/stacks";
import Image from "../../lib/display/Image";
import { Body1, Body2, Header } from "../../lib/display/Text";
import PaidPrice from "../../display/PaidPrice";
import { PrimaryButton } from "../../lib/inputs/buttons";
import { CheckRounded } from "@mui/icons-material";
import useTranslate from "../../../hooks/useTranslate";
import withConfirmDialog from "../../lib/feedback/withConfirmDialog";
import StatusChip from "../../lib/display/StatusChip";

type Props = {
  plan: Plan,
  locked?: boolean,
  selected?: boolean,
  onSelect: Callback,
  flavour: PlanFlavour,
  isEdit: boolean,
};

const Root = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4, 1.5),
  display: "inline-flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  width: 280,
  borderRadius: 24,
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
}));

const Description = styled(Body2)(({ theme }) => ({
  minHeight: 45,
}));

const SelectedPlanLabel = styled(Body1)(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
  fontWeight: "bold",
  textAlign: "center",
  display: "block",
}));

const ConfirmButton = withConfirmDialog(PrimaryButton, {
  DialogProps: {
    name: "confirm-change-plan",
    title: "Page.SubscriptionPanel.confirmNewPlan.title",
    message: "Page.SubscriptionPanel.confirmNewPlan.message",
    confirmLabel: "global.letsGo",
    cancelLabel: "global.letMeThink",
  },
});

const CheckIcon = styled(CheckRounded)`
  min-width: 36px;
`;

const PlanButton: React.ComponentType<Props> = ({
  plan,
  flavour,
  selected,
  onSelect,
  locked,
  isEdit,
}) => {
  const t = useTranslate();
  const ConfirmButtonComponent = isEdit ? ConfirmButton : PrimaryButton;
  return (
    <Root>
      <InlineRowStack alignItems="center" spacing={2}>
        <Image height={48} src={plan.thumbnail} alt={plan.name} />
        <Header color={selected ? "primary" : undefined}>{t(plan.name)}</Header>
        {plan.popular && (
          <StatusChip severity="success" icon={false}>
            {t("global.popular")}
          </StatusChip>
        )}
      </InlineRowStack>
      <Description color="textSecondary">{t(plan.description)}</Description>
      <Box textAlign="center" padding={1}>
        <PaidPrice price={flavour.monthly_price} currency={flavour.currency} />
      </Box>
      {selected ? (
        <SelectedPlanLabel color="primary">
          {t("Page.SubscriptionPanel.PlanButton.planSelected")}
        </SelectedPlanLabel>
      ) : (
        <ConfirmButtonComponent onClick={onSelect} loading={locked}>
          {t("Page.SubscriptionPanel.PlanButton.selectThisPlan")}
        </ConfirmButtonComponent>
      )}
      <Body2 fontWeight="bold">
        {t("Page.SubscriptionPanel.PlanButton.includes")}
      </Body2>
      <List dense disablePadding>
        {plan.features.map((feature) => (
          <ListItem key={feature} disablePadding>
            <CheckIcon />
            <ListItemText primary={t(feature)} />
          </ListItem>
        ))}
      </List>
    </Root>
  );
};

export default PlanButton;
