// @flow
import * as React from "react";
import SubscriptionActionRequiredDialog from "./SubscriptionActionRequiredDialog";
import {
  useCurrentSubscription,
  useSubscriptionInitialised,
} from "../../../../store/selectors";
import { isSubscriptionStateAcceptable } from "../../../../models/subscription.model";
import useBillingInfoForm from "../../../widgets/BillingInfoWidget/useBillingInfoForm";
import useConfirmSetupIntent from "../../../lib/display/stripe/useConfirmSetupIntent";

const SubscriptionActionRequiredDialogContainer: React.ComponentType<{}> =
  () => {
    const subscription = useCurrentSubscription();
    const loadedInitial = useSubscriptionInitialised();
    const confirmSetupIntent = useConfirmSetupIntent();
    const open =
      loadedInitial &&
      (!subscription || !isSubscriptionStateAcceptable(subscription?.status));
    const [planFlavourId, onSetSelectedFlavourId] = React.useState(
      subscription?.plan.id
    );

    React.useEffect(() => {
      if (subscription?.plan.id) {
        onSetSelectedFlavourId(subscription?.plan.id);
      }
    }, [subscription?.plan.id]);

    const { form, onValidateAndCollect } = useBillingInfoForm();

    const handleSubmitDialogForm = (): Promise<void> => {
      const billingFormPayload = onValidateAndCollect();
      if (!billingFormPayload || !confirmSetupIntent) {
        // validation error.
        return Promise.resolve();
      }

      const intent = planFlavourId
        ? {
            action: "subscribe",
            billingInfo: billingFormPayload.billingInfo,
            planFlavourId,
          }
        : { action: "update", billingInfo: billingFormPayload.billingInfo };

      return confirmSetupIntent(intent);
    };

    return (
      <SubscriptionActionRequiredDialog
        open={open}
        selectedFlavourId={planFlavourId}
        onChangeSelectedFlavourId={onSetSelectedFlavourId}
        isAdd={!subscription}
        billingForm={form}
        onSubmit={handleSubmitDialogForm}
      />
    );
  };

export default SubscriptionActionRequiredDialogContainer;
