// @flow
import * as React from "react";
import { CircularProgress } from "@mui/material";
import { Center } from "../lib/layout/placement";
import type { CSSProps } from "../../reactTypes";

const PleaseWaitProgress: React.ComponentType<CSSProps> = (props) => {
  return (
    <Center {...props}>
      <CircularProgress />
    </Center>
  );
};

export default PleaseWaitProgress;
