// @flow
import * as React from "react";
import UpcomingPostsWidget from "./UpcomingPostsWidget";
import type { Post } from "../../../models/post.model";
import { isScheduledToday } from "../../../models/post.model";
import usePaginationState from "../../../hooks/usePaginationState";
import { pageOf } from "../../../lib/lodashex.lib";

type Props = {
  posts: Post[],
  pageSize?: number,
  onSelect: (Post) => any,
};

const UpcomingPostsWidgetContainer: React.ComponentType<Props> = ({
  posts,
  pageSize = 3,
  onSelect,
}) => {
  const todaysPosts = posts.filter(isScheduledToday);
  const pages = Math.ceil(todaysPosts.length / pageSize) || 1;
  const [page, onPreviousPage, onNextPage] = usePaginationState(pages);
  const pagePosts = pageOf(todaysPosts, page, pageSize);

  return (
    <UpcomingPostsWidget
      onSelect={onSelect}
      page={page}
      pages={pages}
      posts={pagePosts}
      onPreviousPage={onPreviousPage}
      onNextPage={onNextPage}
    />
  );
};

export default UpcomingPostsWidgetContainer;
