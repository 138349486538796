// @flow
import * as React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export type ReactCropState = {
  cropIsActive: boolean,
  newCropIsBeingDrawn: boolean,
};

export type Crop = {
  x: number,
  y: number,
  width: number,
  height: number,
  unit: "px" | "%",
};

export type PixelCrop = {
  ...Crop,
  unit: "px",
};

export type PercentCrop = {
  ...Crop,
  unit: "%",
};

export type Props = {
  /** The aspect ratio of the crop, e.g. `1` for a square or `16 / 9` for landscape. */
  aspect?: ?number,
  /** Classes to pass to the `ReactCrop` element. */
  className?: string,
  /** The elements that you want to perform a crop on. For example
   * an image or video. */
  children?: React.Node,
  /** Show the crop area as a circle. If your aspect is not 1 (a square) then the circle will be warped into an oval shape. Defaults to false. */
  circularCrop?: boolean,
  /** Since v10 all crop params are required except for aspect. Omit the entire crop object if you don't want a crop. See README on how to create an aspect crop with a % crop. */
  crop?: PixelCrop | PercentCrop,
  /** If true then the user cannot resize or draw a new crop. A class of `ReactCrop--disabled` is also added to the container for user styling. */
  disabled?: boolean,
  /** If true then the user cannot create or resize a crop, but can still drag the existing crop around. A class of `ReactCrop--locked` is also added to the container for user styling. */
  locked?: boolean,
  /** If true is passed then selection can't be disabled if the user clicks outside the selection area. */
  keepSelection?: boolean,
  /** A minimum crop width, in pixels. */
  minWidth?: number,
  /** A minimum crop height, in pixels. */
  minHeight?: number,
  /** A maximum crop width, in pixels. */
  maxWidth?: number,
  /** A maximum crop height, in pixels. */
  maxHeight?: number,
  /** A callback which happens for every change of the crop. You should set the crop to state and pass it back into the library via the `crop` prop. */
  onChange: (crop: PixelCrop, percentageCrop: PercentCrop) => void,
  /** A callback which happens after a resize, drag, or nudge. Passes the current crop state object in pixels and percent. */
  onComplete?: (crop: PixelCrop, percentageCrop: PercentCrop) => void,
  /** A callback which happens when a user starts dragging or resizing. It is convenient to manipulate elements outside this component. */
  onDragStart?: (e: PointerEvent) => void,
  /** A callback which happens when a user releases the cursor or touch after dragging or resizing. */
  onDragEnd?: (e: PointerEvent) => void,
  onImageLoaded: (el: HTMLImageElement) => void,
  /** Render a custom element in crop selection. */
  renderSelectionAddon?: (state: ReactCropState) => React.Node,
  /** Show rule of thirds lines in the cropped area. Defaults to false. */
  ruleOfThirds?: boolean,
  /** Inline styles object to be passed to the `ReactCrop` element. */
  style?: Object,
  imageStyle?: Object,
  src: ?string,
};

const ImageCropper: React.ComponentType<Props> = ({
  imageStyle,
  src,
  onImageLoaded,
  ...props
}) => (
  <ReactCrop {...props}>
    <img
      alt="cropped"
      src={src}
      style={imageStyle}
      onLoad={(e) => onImageLoaded(e.target)}
    />
  </ReactCrop>
);

export default ImageCropper;
