// @flow
import * as React from "react";
import SelectPlanWidget from "../../SelectPlanWidget";
import * as subscriptionService from "../../../../services/subscription.service";
import type { PlanFlavour } from "../../../../models/plan.model";
import { useNavigate } from "react-router-dom";
import routes, { getRoutePath } from "../../../../config/routes.config";
import { useStoreActions } from "../../../../store/store";

const ChangePlanTab: React.ComponentType<{}> = () => {
  const navigate = useNavigate();
  const actions = useStoreActions();

  const handleConfirm = (planFlavour: PlanFlavour) => {
    subscriptionService
      .editPlan(actions)(planFlavour.id)
      .then(() =>
        navigate(getRoutePath(routes.app.nested.settings.nested.subscription))
      );
  };

  return <SelectPlanWidget onSelect={handleConfirm} />;
};

export default ChangePlanTab;
