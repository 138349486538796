// @flow
import * as React from "react";
import VizGrid from "./VizGrid";
import type { BobcaatAccount } from "../../../models/account.model";
import type { Portfolio } from "../../../models/portfolio.model";
import type { Post } from "../../../models/post.model";
import { hasNoSchedule } from "../../../models/post.model";
import type { Callback } from "../../../types";
import { ColumnStack, InlineRowStack, RowStack } from "../../lib/layout/stacks";
import { PortfolioAvatar } from "../../display/avatars";
import { Body1, Subtitle } from "../../lib/display/Text";
import ChannelIcon from "../../lib/display/icons/ChannelIcon";
import Translate from "../../lib/display/Translate";
import { CircularProgress } from "@mui/material";
import useTranslate from "../../../hooks/useTranslate";
import { NeutralButton } from "../../lib/inputs/buttons";
import { styled } from "@mui/material/styles";

type WidgetHeadProps = {
  posts: Post[],
  portfolio: Portfolio,
  account: ?BobcaatAccount,
};

const WidgetHeadRoot = styled(RowStack)(({ theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
}));

const WidgetHead: React.ComponentType<WidgetHeadProps> = ({
  portfolio,
  account,
  posts,
}) => {
  const unscheduled = posts.filter(hasNoSchedule).length;
  if (!account) return <CircularProgress />;
  return (
    <WidgetHeadRoot>
      <PortfolioAvatar portfolio={portfolio} size={58} />
      <div>
        <Subtitle>{account.entity_name}</Subtitle>
        <InlineRowStack>
          <ChannelIcon channel={account.channel} color="channel" />
          <Body1 color="textSecondary">
            <Translate
              id="VizPlannerSection.unscheduledCount"
              data={{ count: unscheduled }}
            />
          </Body1>
        </InlineRowStack>
      </div>
    </WidgetHeadRoot>
  );
};

type Props = {
  ...WidgetHeadProps,
  locked?: boolean,
  onScreenshot: Callback,
};

const VizPlannerWidget: React.ComponentType<Props> = ({
  posts,
  portfolio,
  account,
  onScreenshot,
  locked,
}) => {
  const t = useTranslate();
  return (
    <ColumnStack alignItems="center" marginBottom={1}>
      <WidgetHead portfolio={portfolio} account={account} posts={posts} />
      <VizGrid posts={posts} />
      <RowStack justifyContent="center">
        <NeutralButton loading={locked} onClick={onScreenshot}>
          {t("global.download")}
        </NeutralButton>
      </RowStack>
    </ColumnStack>
  );
};

export default VizPlannerWidget;
