// @flow
import keyBy from "lodash/keyBy";
import type { I18nKey } from "../types";
import type { SubscriptionStates } from "../models/subscription.model";

export type SubscriptionStatus = {
  slug: SubscriptionStates,
  name: I18nKey,
};

export const TRIALING = {
  slug: "trialing",
  name: "refer.subscriptionStatus.trial",
};
export const ACTIVE = {
  slug: "active",
  name: "refer.subscriptionStatus.active",
};
export const INCOMPLETE = {
  slug: "incomplete",
  name: "refer.subscriptionStatus.incomplete",
};
export const INCOMPLETE_EXPIRED = {
  slug: "incomplete_expired",
  name: "refer.subscriptionStatus.incompleteExpired",
};
export const PAST_DUE = {
  slug: "past_due",
  name: "refer.subscriptionStatus.pastDue",
};
export const CANCELED = {
  slug: "canceled",
  name: "refer.subscriptionStatus.canceled",
};
export const UNPAID = {
  slug: "unpaid",
  name: "refer.subscriptionStatus.unpaid",
};

const subscriptionStatuses: SubscriptionStatus[] = [
  TRIALING,
  ACTIVE,
  INCOMPLETE,
  INCOMPLETE_EXPIRED,
  PAST_DUE,
  CANCELED,
  UNPAID,
];

const allByStatus = keyBy(subscriptionStatuses, "slug");

export const getSubscriptionStatus = (
  status: ?SubscriptionStates
): SubscriptionStatus =>
  allByStatus[status] ?? {
    slug: status,
    name: status,
  };

export default subscriptionStatuses;
