// @flow
import * as React from "react";
import type { Notification } from "../../models/notification.model";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import type { ModelID } from "../../types";
import { byFalsyProp } from "../../models/base.model";

export type NotificationActions = {
  ...SequenceActions<Notification>,
  loadAllAndUpdateLatestUnread: (notifications: Notification[]) => void,
  AddAndUpdateLatestUnread: (notification: Notification) => void,
  deleteAllExceptProtected: () => void,
};

export type NotificationState = {
  notifications: SequenceState<Notification>,
  latestUnread: ModelID,
};

const notificationReducer = sequenceReducer<Notification>({ key: "id" });

export const useNotificationReducer = (): [
  NotificationState,
  NotificationActions
] => {
  const [latestUnread, setLatestUnread] = React.useState(0);
  const [notifications, notificationsDispatch] =
    useSequenceReducer(notificationReducer);
  const notificationsActions = React.useMemo(
    () => makeSequenceActions(notificationsDispatch),
    [notificationsDispatch]
  );

  const actions = React.useMemo(
    () => ({
      ...notificationsActions,
      loadAllAndUpdateLatestUnread: (notifications: Notification[]) => {
        const latestUnread = notifications.find(byFalsyProp("read"));
        notificationsActions.replaceAll(notifications);
        setLatestUnread(latestUnread ? latestUnread.id : 0);
      },
      AddAndUpdateLatestUnread: (notification: Notification) => {
        const latestUnread = notification.read ? null : notification;
        notificationsActions.append(notification);
        setLatestUnread(latestUnread ? latestUnread.id : 0);
      },
      deleteAllExceptProtected: () => {
        notificationsActions.removeBy(byFalsyProp("protected"));
      },
    }),
    [notificationsActions, setLatestUnread]
  );
  return [{ notifications, latestUnread }, actions];
};
