// @flow
import * as React from "react";
import PostBuilderContext from "../../../contexts/postBuilder";
import SwitchField from "../../lib/inputs/SwitchField";
import { Body2 } from "../../lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import instagram from "../../../models/channels/instagram.model";
import { canDisableAutoPublish } from "../../../models/contentTypes.model";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const DisableAutopublishSetting: React.ComponentType<{}> = () => {
  const {
    post: { disable_autopublish: disableAutopublishPost, content_type },
    channel,
    onUpdatePost,
  } = React.useContext(PostBuilderContext);
  const showDisableAutopublish =
    channel?.slug === instagram.slug && canDisableAutoPublish(content_type);
  const t = useTranslate();

  return (
    showDisableAutopublish && (
      <Root>
        <SwitchField
          checked={!!disableAutopublishPost}
          onChange={(e, disable_autopublish) =>
            onUpdatePost({ disable_autopublish })
          }
          label={t("PostCreator.disableAutopublish")}
        >
          <Body2 color="textSecondary">
            {t("store.post.instagramScheduled.message")}
          </Body2>
        </SwitchField>
      </Root>
    )
  );
};

export default DisableAutopublishSetting;
