// @flow
import * as React from "react";
import type { HashtagGroup } from "../../models/hashtagGroup.model";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import { useLockState } from "./lib/lock.state";
import type { LockActions, LockState } from "./lib/lock.state";

const hashtagGroupReducer = sequenceReducer<HashtagGroup>({
  key: "id",
  sortKey: "name",
});
export type HashtagGroupActions = {
  ...LockActions,
  ...SequenceActions<HashtagGroup>,
};
export type HashtagGroupState = {
  ...LockState,
  hashtagGroups: SequenceState<HashtagGroup>,
};

export const useHashtagGroupReducer = (): [
  HashtagGroupState,
  HashtagGroupActions
] => {
  const [hashtagGroups, dispatch] = useSequenceReducer(hashtagGroupReducer);
  // initially locked, because we need an initial load before it is ready.
  const [locked, lockActions] = useLockState(true);
  const baseActions = React.useMemo(
    () => makeSequenceActions(dispatch),
    [dispatch]
  );
  const actions = React.useMemo(
    () => ({
      ...baseActions,
      ...lockActions,
    }),
    [baseActions, lockActions]
  );
  return [{ hashtagGroups, locked }, actions];
};
