// @flow
import * as React from "react";
import fbMissingPermissionImg from "../../../assets/images/troubleshoot/fb-missing-permission.jpg";
import facebook from "../../../models/channels/facebook.model";
import instagram from "../../../models/channels/instagram.model";
import { AccountStatusFlag } from "../../../models/account.model";
import { Link } from "@mui/material";
import type { Slug } from "../../../types";
import type { AccountStatusFlags } from "../../../models/account.model";

const missingPermissionsBase = (
  <>
    <p>
      It looks like bobcaat doesn't have all the permissions it needs to work
      properly.
    </p>
    <p>
      bobcaat only asks for helpful permissions. Restricting its access to your
      account might result in errors and/or inaccurate data displayed. If you
      ignore this message we cannot guarantee the application will work
      properly.
    </p>
  </>
);

const invalidTokenBase = (
  <p>
    Social networks regularly want you to refresh our access to your account so
    that you stay in control. In order to do so, just click the refresh button
    and go through the login window again.
  </p>
);

const accountLockedBase = (
  <p>
    When they suspect an account is compromised, Social networks can lock the
    account until there is confirmation that the owner is accessing the account.
    <br />
    Sadly, we are not able to do anything on our end. You will have to log into
    the locked account and follow the procedure.
  </p>
);

const unknwownAnalyticsErrorBase = (
  <p>
    Not to worry, simply make sure bobcaat is set up properly by going to your{" "}
    <Link
      href="https://www.facebook.com/settings/?tab=business_tools"
      rel="noopener noreferrer"
      target="_blank"
    >
      Business integration settings
    </Link>{" "}
    on Facebook and selecting all permissions. This will ensure things run
    smoothly on our end! If you have any questions, don't hesitate to contact
    us.
    <br />
    If you do not need analytics, you can ignore this message.
  </p>
);

const defaultStatusErrorSet = {
  [AccountStatusFlag.MISSING_PERMISSIONS]: [
    "Missing application permissions",
    missingPermissionsBase,
  ],
  [AccountStatusFlag.INVALID_TOKEN]: [
    "Invalid or expired user token",
    invalidTokenBase,
  ],
  [AccountStatusFlag.ACCOUNT_LOCKED]: [
    "Your account has been locked",
    accountLockedBase,
  ],
  [AccountStatusFlag.ANALYTICS_FAILURE]: [
    "We could not fetch your analytics",
    unknwownAnalyticsErrorBase,
  ],
};

const accountStatusWarnings = {
  [facebook.slug]: {
    ...defaultStatusErrorSet,
    [AccountStatusFlag.MISSING_PERMISSIONS]: [
      "Missing permissions",
      <>
        {missingPermissionsBase}
        <p>
          Attached is a screenshot showing what your business integration
          settings should look like. You can access{" "}
          <Link
            href="https://www.facebook.com/settings?tab=business_tools"
            rel="noopener noreferrer"
            target="_blank"
          >
            your settings here.
          </Link>
        </p>
        <p>
          If you need help with this, please contact our support team, we'd be
          happy to help.
        </p>
        <p style={{ textAlign: "center" }}>
          <img
            style={{
              width: "90%",
              border: "solid 1px #EFEFEF",
              borderRadius: 4,
              boxShadow: "2px 2px 4px rgba(0,0,0,0.3)",
            }}
            src={fbMissingPermissionImg}
            alt="Facebook business integration example"
          />
        </p>
      </>,
    ],
  },
  [instagram.slug]: {
    ...defaultStatusErrorSet,
  },
};

export const getAccountStatusWarning = (
  channel: Slug,
  statusCode: AccountStatusFlags
): [?string, ?React.Node] =>
  (accountStatusWarnings[channel] ?? defaultStatusErrorSet)[statusCode] ?? [
    undefined,
    undefined,
  ];
