// @flow
import { API as backend } from "./backend.service";
import type { AsyncVoidAction } from "./types";
import type { ModelID } from "../types";

export const getIntegrations: AsyncVoidAction<void> = (actions) => () => {
  actions.integration.lock();
  return backend.integration
    .getIntegrations()
    .then(actions.integration.replaceAll)
    .finally(actions.integration.unlock);
};

export const deleteIntegration: AsyncVoidAction<ModelID> =
  (actions) => (integrationId) =>
    backend.integration
      .deleteIntegration(integrationId)
      .then(actions.integration.remove);
