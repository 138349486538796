// @flow
import * as React from "react";

type ToggleState = [boolean, () => void];

const useToggle = (initial: boolean = false): ToggleState => {
  const [value, setValue] = React.useState<boolean>(initial);
  const toggle = React.useCallback(() => setValue((prev) => !prev), []);

  return [value, toggle];
};

export default useToggle;
