// @flow
import * as React from "react";
import type {
  FormField,
  OnChangeField,
  NumberListFormFieldDef,
} from "../../../../hooks/useForm";
import type { Props as SwitchGroupProps } from "../SwitchGroup";
import SwitchGroup from "../SwitchGroup";

type NumberProps<Option> = {
  ...Omit<SwitchGroupProps<Option, number>, "value" | "onChange">,
  formField: FormField<NumberListFormFieldDef>,
  onChange: OnChangeField<NumberListFormFieldDef>,
};

type Props<Option> = NumberProps<Option>;

const FormSwitchGroup = <Option>({
  formField,
  onChange,
  ...props
}: Props<Option>): React.Node => {
  return <SwitchGroup value={formField.value} onChange={onChange} {...props} />;
};

export default FormSwitchGroup;
