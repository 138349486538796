// @flow
import * as React from "react";
import GeneralSection from "../../../components/widgets/settings/GeneralSection";
import PasswordSection from "../../../components/widgets/settings/PasswordSection";
import PreferencesSection from "../../../components/widgets/settings/PreferencesSection";
import IntegrationsSection from "../../../components/widgets/settings/IntegrationsSection";
import TeamsSection from "../../../components/widgets/settings/TeamsSection";
import { useCurrentUser } from "../../../store/selectors";

const AccountTab: React.ComponentType<empty> = () => {
  const user = useCurrentUser();
  return (
    <>
      <GeneralSection />
      <PasswordSection />
      <PreferencesSection />
      {!user?.is_child && <IntegrationsSection />}
      {!user?.is_child && <TeamsSection />}
    </>
  );
};

export default AccountTab;
