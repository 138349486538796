// @flow
import isEmpty from "lodash/isEmpty";

export const DIGITS_ONLY: RegExp = /^\d+$/;

export const REGEX_URL: RegExp = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.\S{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.\S{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.\S{2,}|www\.[a-zA-Z0-9]+\.\S{2,})/gi
);
export const EMAIL_REGEX: RegExp =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

/**
 * Find the first occurence of a URL in the provided text.
 * @param {string} text The text to search.
 * @return {str|null} The first occurence of a URL, or null.
 */
export const findURL = (text: string): ?string => {
  const match = text.match(REGEX_URL);
  return match && !isEmpty(match[0]) ? match[0] : null;
};
