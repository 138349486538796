// @flow
import * as React from "react";
import { debounce } from "@mui/material/utils";

const useDebouncedOnChange = (
  callback: (string) => any,
  timeout: number
): ((SyntheticInputEvent<>) => any) =>
  React.useMemo(
    () =>
      debounce((e: SyntheticInputEvent<>) => callback(e.target.value), timeout),
    [callback, timeout]
  );

export default useDebouncedOnChange;
