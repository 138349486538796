// @flow
import * as React from "react";
import type { PixelCrop, Props as ImageCropperProps } from "./ImageCropper";
import ImageCropper from "./ImageCropper";
import { cropImage } from "../../../../lib/images.lib";
import type { DataURI } from "../../../../types";

const defaultCrop = (aspect: ?number) => ({
  x: 0,
  y: 0,
  width: 100,
  height: 100 / (aspect ?? 1),
  unit: "px",
});

const loadingImageStyles = {
  width: 400,
  height: 400,
  backgroundColor: "rgba(0, 0, 0, 0.1)",
};

export type Props = {
  ...Omit<ImageCropperProps, "onChange" | "onImageLoaded" | "onComplete">,
  onCropComplete: (DataURI) => any,
};

const ImageCropperContainer: React.ComponentType<Props> = ({
  aspect,
  onCropComplete,
  ...props
}) => {
  const [crop, setCrop] = React.useState<PixelCrop>(defaultCrop(aspect));
  const [imageEl, setImageEl] = React.useState<?HTMLImageElement>();

  React.useEffect(() => {
    setCrop((prevCrop) => ({
      ...(prevCrop ?? defaultCrop(aspect)),
      height: !!aspect ? prevCrop.width / aspect : prevCrop.height,
    }));
  }, [aspect]);

  return (
    <ImageCropper
      {...props}
      aspect={aspect}
      crop={crop}
      onChange={setCrop}
      onComplete={
        imageEl ? (crop) => onCropComplete(cropImage(imageEl, crop)) : undefined
      }
      imageStyle={imageEl ? { maxHeight: "60vh" } : loadingImageStyles}
      onImageLoaded={(el) => {
        // fire onComplete once to set the initial cropped image.
        onCropComplete(cropImage(el, crop));
        setImageEl(el);
      }}
    />
  );
};

export default ImageCropperContainer;
