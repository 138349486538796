// @flow
import * as React from "react";
import DebouncedSearchInput from "../../lib/inputs/DebouncedSearchInput";
import type { HashtagGroup } from "../../../models/hashtagGroup.model";
import { hashtagGroupToString } from "../../../models/hashtagGroup.model";
import NoResults from "../../display/NoResults";
import { List, Paper } from "@mui/material";
import StdListItem from "../../lib/display/listItems";
import Hashtag from "../../lib/display/icons/Hashtag";
import { styled } from "@mui/material/styles";

type Props = {
  onChangeSearch: (string) => any,
  onSelect: (HashtagGroup) => any,
  hashtagGroups: HashtagGroup[],
};

const Root = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  width: 300,
}));

const ItemsList = styled(List)({
  maxHeight: 350,
  overflow: "auto",
});

const HashtagGroupSelector: React.ComponentType<Props> = React.forwardRef(
  ({ onChangeSearch, hashtagGroups, onSelect }, ref) => (
    <Root ref={ref}>
      <DebouncedSearchInput onChange={onChangeSearch} fullWidth />
      {hashtagGroups.length === 0 && <NoResults />}
      {hashtagGroups.length > 0 && (
        <ItemsList dense>
          {hashtagGroups.map((group) => (
            <StdListItem
              key={group.id}
              primary={group.name}
              secondary={hashtagGroupToString(group)}
              secondaryProps={{
                sx: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
              icon={<Hashtag />}
              onClick={() => onSelect(group)}
            />
          ))}
        </ItemsList>
      )}
    </Root>
  )
);

export default HashtagGroupSelector;
