// @flow
import * as React from "react";
import MenuListItem from "../../lib/navigation/MenuListItem";
import { Caption } from "../../lib/display/Text";
import { IconButton } from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";
import type {
  Notification,
  NotificationType,
} from "../../../models/notification.model";
import type { ModelID } from "../../../types";
import NewsIcon from "@mui/icons-material/NewReleases";
import AlarmIcon from "@mui/icons-material/AlarmOn";
import ErrorIcon from "@mui/icons-material/Error";
import GeneralIcon from "@mui/icons-material/NotificationImportant";

export type Props = {
  item: Notification,
  onDelete: (ModelID) => any,
  onClick: (Notification) => any,
  style: Object,
};

const TYPE_TO_ICON_MAP: Record<
  NotificationType,
  React.ComponentType<{ style: Object }>
> = {
  news: NewsIcon,
  alarm: AlarmIcon,
  error: ErrorIcon,
  general: GeneralIcon,
};

const UNREAD_STYLE = { fontWeight: "bold" };

const NotificationItem: React.ComponentType<Props> = ({
  item,
  onClick,
  onDelete,
  style,
}) => {
  const Icon = TYPE_TO_ICON_MAP[item.type] ?? GeneralIcon;
  const typoProps = item.read ? undefined : UNREAD_STYLE;

  return (
    <MenuListItem
      style={style}
      onClick={() => onClick(item)}
      Icon={Icon}
      primary={item.title}
      primaryProps={typoProps}
      secondaryProps={typoProps}
      secondary={
        <>
          {item.content}
          <Caption display="block" color="textSecondary">
            {item.schedule_time?.fromNow()}
          </Caption>
        </>
      }
    >
      <IconButton
        color="error"
        size="small"
        onClick={(e: SyntheticEvent<>) => {
          e.stopPropagation();
          onDelete(item.id);
        }}
      >
        <DeleteRounded sx={{ fontSize: 16 }} />
      </IconButton>
    </MenuListItem>
  );
};

export default NotificationItem;
