// @flow
import * as React from "react";
import type { BobcaatAccount } from "../../../models/account.model";
import {
  getAccountLifecycleState,
  getExpiresInDays,
} from "../../../models/account.model";
import StatusChip from "../../lib/display/StatusChip";
import { getAccountStatusWarning } from "./accountStatusWarnings";
import useTranslate from "../../../hooks/useTranslate";
import useAlertDialog from "../../lib/feedback/useAlertDialog";
import Translate from "../../lib/display/Translate";
import type { CSSProps } from "../../../reactTypes";

type Props = {
  account: BobcaatAccount,
  ...CSSProps,
};

const useStatusProps = (account: BobcaatAccount) => {
  const t = useTranslate();
  switch (getAccountLifecycleState(account)) {
    case "connected":
      return {
        severity: "success",
        children: t("DashboardSection.AccountCard.connected.label", {
          expiresIn: account.access_token_expiry.fromNow(),
        }),
      };
    case "expires_soon":
      return {
        severity: "warning",
        children: t("DashboardSection.AccountCard.expiresSoon.label", {
          days: getExpiresInDays(account),
        }),
      };
    case "expired":
      return {
        severity: "error",
        children: t("DashboardSection.AccountCard.expired.label"),
      };
    default:
      return { severity: "info", children: null };
  }
};

const AccountStatusChip: React.ComponentType<Props> = ({
  account,
  ...props
}) => {
  const { severity, children } = useStatusProps(account);
  const [warningHead, warningBody] = getAccountStatusWarning(
    account.channel,
    account.status_code
  );
  const [openWarningAlert, warningAlert] = useAlertDialog({
    DialogProps: {
      name: "warning-status",
      title: warningHead ?? "",
      message: warningBody,
    },
  });
  return (
    <>
      {warningAlert}
      <StatusChip
        severity={warningHead ? "warning" : severity}
        onClick={warningHead ? openWarningAlert : undefined}
        {...props}
      >
        {warningHead ? (
          <Translate id="DashboardSection.AccountCard.accountNeedsAttention" />
        ) : (
          children
        )}
      </StatusChip>
    </>
  );
};

export default AccountStatusChip;
