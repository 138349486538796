// @flow
import * as React from "react";
import type { Message as MessageType } from "../../../../../../models/message.model";
import Message from "./Message";
import type { Conversation } from "../../../../../../models/conversation.model";
import { useScopedPortfolio } from "../../../../../../scopes/scopes";
import { CircularProgress } from "@mui/material";

type Props = {
  message: MessageType,
  conversation: Conversation,
  /**
   * A replied to message is a message another message is in reply of,
   * and is shown out of the regular flow.
   */
  repliedTo?: boolean,
  fromUser?: boolean,
};
const MessageContainer: React.ComponentType<Props> = (props) => {
  const portfolio = useScopedPortfolio();
  if (!portfolio) return <CircularProgress />;

  return <Message portfolio={portfolio} {...props} />;
};

export default MessageContainer;
