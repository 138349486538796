// @flow
import instagram from "../../models/channels/instagram.model";
import type { BobcaatAccount } from "../../models/account.model";
import type { Channel } from "../../models/channels/channel.model";
import type { URLStr } from "../../types";

/** Handles account binding logic.**/

export interface OAuthTarget {
  /** Native identifier for the managed user / entity. */
  id: string;
  /** Name of the target. */
  name: string;
  /** Access token for authentication. */
  access_token: string;
  cover?: URLStr;
  managed_entity_id?: string;
}

/**
 * Stands for the OAuth result for the main user (not one of its managed
 * entities)
 */
export type OAuthUserDetails = {
  /** Access token for authentication. */
  access_token: string,
  /** Token used to obtain a new access token. */
  refresh_token: string,
  /** Secret used to sign requests. */
  access_token_secret: string,
  /** Time until the access token expires (in seconds). */
  access_token_expiry: number,
  /** Time until the refresh token expires (in seconds). */
  refresh_token_expiry: number,
  /** User's name. */
  user_name: string,
  /** User's identifier. */
  user_id: string,
};

/**
 * Stands for the OAuth result for a managed entity.
 */
export type OAuthEntityDetails = {
  /** Native identifier for the managed user / entity. */
  id: string,
  /** Name of the target. */
  name: string,
  /** Access token for authentication. */
  access_token: string,
  cover?: URLStr,
  managed_entity_id?: string,
};

/**
 * Result of a flow login function.
 */
export type LoginFlowResult = {
  oauth_result: boolean,
  user: OAuthUserDetails,
  entities: OAuthEntityDetails[],
};

export type UnresolvedChannelEntitySelection = {
  /** Indicates whether more steps are required. */
  resolved: false,
  /** In case more steps are needed. */
  result: LoginFlowResult,
};

export type ResolvedChannelEntitySelection = {
  /** Indicates whether more steps are required. */
  resolved: true,
  /** Details on the master user. */
  user: OAuthUserDetails,
  /** Selected entity (either user or managed entity). */
  entity: OAuthTarget,
};

/**
 * Stands for the result of a channel selection.
 */
export type ChannelEntitySelection =
  | UnresolvedChannelEntitySelection
  | ResolvedChannelEntitySelection;

/**
 * Creates a ChannelEntitySelection from a user only.
 */
const completedUserSelection = (
  user: OAuthUserDetails
): ResolvedChannelEntitySelection => ({
  resolved: true,
  user,
  entity: {
    id: user.user_id,
    name: user.user_name,
    access_token: user.access_token,
  },
});

/**
 * Creates a ChannelEntitySelection from a user only.
 */
export const completedEntitySelection = (
  user: OAuthUserDetails,
  target: OAuthTarget
): ResolvedChannelEntitySelection => ({
  resolved: true,
  user,
  entity: {
    id: target.id,
    name: target.name,
    // Remember that some channels provide an entity token to
    // override the user one, but not all of them.
    access_token: target.access_token ?? user.access_token,
  },
});

const findExistingEntity = (
  account: BobcaatAccount,
  newEntities: OAuthEntityDetails[]
): ?OAuthEntityDetails => {
  // For Instagram, Try to match with parent_entity_id (new way).
  // Default to the name (obsolete) if there is no parent_entity_id
  return newEntities.find(
    account.channel === instagram.slug
      ? (e) =>
          e.id === account.parent_entity_id || e.name === account.entity_name
      : (e) => e.id === account.entity_id
  );
};

/**
 * Possible rejection reasons when selecting an entity.
 */
export type SelectChannelEntityRejectReasons =
  | "accountNotFound"
  | string
  | { error?: any };

export const selectChannelEntity = (
  channel: Channel,
  account: ?BobcaatAccount
): Promise<ChannelEntitySelection> =>
  new Promise((resolve, reject) => {
    channel
      .login()
      .then((res: LoginFlowResult) => {
        if (
          !channel.features?.businessAccount ||
          (account && !account.is_organisation)
        ) {
          // Channel does not support entities or account is personal, so select user and skip entity dialog
          resolve(completedUserSelection(res.user));
          return;
        }

        if (account) {
          // There is already an account and this is just a refresh. Manually find the same entity
          const entity = findExistingEntity(account, res.entities);

          if (!entity) {
            // If the entity cannot be found, ask the user to find the page manually.
            resolve({ resolved: false, result: res });
          } else {
            resolve(completedEntitySelection(res.user, entity));
          }
          return;
        }

        if (
          (res.entities?.length ?? 0) === 0 &&
          !channel.features.personnalAccount
        ) {
          // Could not find a single entity to select and channel does not allow
          // personnal accounts.
          reject("accountNotFound");
          return;
        }
        // All good. Set auth result to open pick entity dialog.
        resolve({ resolved: false, result: res });
      })
      .catch(reject);
  });
