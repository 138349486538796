// @flow
import * as React from "react";
import PaidPrice from "./PaidPrice";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { Body1, Caption, Subheader, Subtitle } from "../lib/display/Text";
import Translate from "../lib/display/Translate";
import type { PromotionCode } from "../../models/promotionCode.model";
import { LocalOfferRounded } from "@mui/icons-material";

type Props = {
  promocode: PromotionCode,
};

const Root = styled(Paper)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1),
  alignItems: "stretch",
  flexGrow: 1,
}));

const ImageContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  margin: -theme.spacing(1),
  marginRight: 0,
  width: 96,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Content = styled("div")(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  display: "flex",
  flexDirection: "column",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
}));

const Pricing = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
}));

const AppliedPromocode: React.ComponentType<Props> = ({ promocode }) => {
  return (
    <Root>
      <ImageContainer>
        <LocalOfferRounded fontSize="large" />
      </ImageContainer>
      <Content>
        <Subheader>{promocode.code}</Subheader>
        <Body1>{promocode.coupon.name}</Body1>
        <Caption color="textSecondary">
          {promocode.coupon.duration === "repeating" ? (
            <Translate
              id="global.monthDuration"
              data={{ months: promocode.coupon.duration_in_months }}
            />
          ) : (
            <Translate id={promocode.coupon.duration} />
          )}
        </Caption>
      </Content>
      <Pricing>
        {promocode.coupon.amount_off ? (
          <PaidPrice
            price={-promocode.coupon.amount_off}
            currency={promocode.coupon.currency}
          />
        ) : (
          <Subtitle>
            <Translate
              id="global.percent_off"
              data={{ amount: promocode.coupon.percent_off }}
            />
          </Subtitle>
        )}
      </Pricing>
    </Root>
  );
};

export default AppliedPromocode;
