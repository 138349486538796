// @flow
import * as React from "react";
import PostScheduleWidget from "./PostScheduleWidget";
import moment from "moment";
import PostBuilderContext from "../../../../contexts/postBuilder";
import { useScopedPortfolio } from "../../../../scopes/scopes";
import { getTimezone, localTimezone } from "../../../../models/timezone.model";
import type { Moment } from "../../../../types";
import { Collapse } from "@mui/material";

const PostSchedulerContainer: React.ComponentType<{}> = () => {
  const { post, onUpdatePost } = React.useContext(PostBuilderContext);
  const portfolio = useScopedPortfolio();
  const timezone = getTimezone(portfolio?.timezone) ?? localTimezone();

  React.useEffect(() => {
    onUpdatePost({ local_schedule_tz_id: timezone.id });
  }, [onUpdatePost, timezone.id]);

  const handleChangeDate = React.useCallback(
    (d: ?Moment) => {
      onUpdatePost({
        schedule_time: d ? d.seconds(0).milliseconds(0) : null,
      });
    },
    [onUpdatePost]
  );

  const handleChangeTime = React.useCallback(
    (d: ?Moment) => {
      const baseDate = post.schedule_time ?? moment();
      onUpdatePost({
        schedule_time: d
          ? d.year(baseDate.year()).dayOfYear(baseDate.dayOfYear())
          : baseDate,
      });
    },
    [post.schedule_time, onUpdatePost]
  );

  return (
    <Collapse in={post.status !== "published"} mountOnEnter unmountOnExit>
      <PostScheduleWidget
        timezone={timezone}
        selectedDate={post.schedule_time}
        onDateChange={handleChangeDate}
        onTimeChange={handleChangeTime}
        periodicity={post.periodicity}
        onChangePeriodicity={(periodicity) => onUpdatePost({ periodicity })}
      />
    </Collapse>
  );
};

export default PostSchedulerContainer;
