// @flow
import * as React from "react";
import routes, { getRoutePath } from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import { Grow } from "@mui/material";
import {
  NeutralButton,
  PrimaryButton,
} from "../../../components/lib/inputs/buttons";
import useTranslate from "../../../hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import {
  useCurrentUser,
  usePortfoliosLocked,
  usePortfolioSummaries,
  useSortedPortfoliosForHomePage,
} from "../../../store/selectors";
import PortfolioCard, {
  PortfolioCardSkeleton,
} from "../../../components/widgets/PortfolioCard";
import { EmptyNotice } from "../../../components/display/EmptyNotice";
import range from "lodash/range";
import { Center } from "../../../components/lib/layout/placement";
import { ResponsiveInlineRowStack } from "../../../components/lib/layout/stacks";
import useLogOutHandler from "../../../hooks/useLogOutHandler";
import News from "../../../components/display/News";

const HomePage: React.ComponentType<empty> = () => {
  const t = useTranslate();
  const route = routes.app.nested.home;
  const user = useCurrentUser();
  const portfolios = useSortedPortfoliosForHomePage();
  const locked = usePortfoliosLocked();
  const summaries = usePortfolioSummaries();
  const navigate = useNavigate();
  const handleLogOut = useLogOutHandler();
  const addPortfolioButton = (
    <PrimaryButton
      size={portfolios.length === 0 ? "large" : undefined}
      onClick={() => navigate(getRoutePath(routes.app.nested.addPortfolio))}
    >
      {t("routes.addPortfolio.label")}
    </PrimaryButton>
  );

  return (
    <PrimaryPagePanel
      isHome
      size="fill"
      route={route}
      titleData={{ user: user?.first_name ?? "you" }}
    >
      <News />
      {!locked && portfolios.length > 0 && (
        <>
          <ResponsiveInlineRowStack spacing={1} justifyContent="space-between">
            {addPortfolioButton}
            <ResponsiveInlineRowStack spacing={1}>
              <NeutralButton
                onClick={() =>
                  navigate(getRoutePath(routes.app.nested.feedback))
                }
              >
                {t(routes.app.nested.feedback.label)}
              </NeutralButton>
              <NeutralButton
                onClick={() =>
                  navigate(getRoutePath(routes.app.nested.referral))
                }
              >
                {t(routes.app.nested.referral.label)}
              </NeutralButton>
              <NeutralButton onClick={handleLogOut}>
                {t("global.logOut")}
              </NeutralButton>
            </ResponsiveInlineRowStack>
          </ResponsiveInlineRowStack>
          <ResponsiveInlineRowStack>
            {portfolios.map((pf) => (
              <Grow in unmountOnExit key={pf.id}>
                <div>
                  <PortfolioCard portfolio={pf} summary={summaries[pf.id]} />
                </div>
              </Grow>
            ))}
          </ResponsiveInlineRowStack>
        </>
      )}
      {!locked && portfolios.length === 0 && (
        <Center>
          <EmptyNotice
            cta={addPortfolioButton}
            maxWidth="100%"
            label={t("global.noPortfoliosYet")}
            subcopy={"global.noPortfoliosYetSubcopy"}
          >
            <ResponsiveInlineRowStack>
              {range(3).map((idx) => (
                <PortfolioCardSkeleton key={idx} />
              ))}
            </ResponsiveInlineRowStack>
          </EmptyNotice>
        </Center>
      )}
    </PrimaryPagePanel>
  );
};

export default HomePage;
