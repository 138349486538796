// @flow
import type { TopicTypes } from "../models/channels/googleMyBusiness.model";
import type { I18nKey } from "../types";
import { cast } from "../types";

export type GMBTopicDef = {
  type: TopicTypes,
  name: I18nKey,
  supportsCTA: boolean,
  supportsEvent: boolean,
  supportsOffer: boolean,
};
export const STANDARD: GMBTopicDef = {
  type: "STANDARD",
  name: "refer.channels.gmb.topicTypes.standard.name",
  supportsCTA: true,
  supportsEvent: false,
  supportsOffer: false,
};

export const EVENT: GMBTopicDef = {
  type: "EVENT",
  name: "refer.channels.gmb.topicTypes.event.name",
  supportsCTA: true,
  supportsEvent: true,
  supportsOffer: false,
};

export const OFFER: GMBTopicDef = {
  type: "OFFER",
  name: "refer.channels.gmb.topicTypes.offer.name",
  supportsCTA: false,
  supportsEvent: true,
  supportsOffer: true,
};

const all: GMBTopicDef[] = [STANDARD, EVENT, OFFER];

export const getGMBTopic = (type: TopicTypes): GMBTopicDef =>
  cast<GMBTopicDef>(all.find((topic) => topic.type === type));

export default all;
