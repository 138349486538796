// @flow
import * as React from "react";
import keyBy from "lodash/keyBy";
import range from "lodash/range";
import DurationPickerColumn from "./DurationPickerColumn";
import type { Integer, Pixels } from "../../../../../types";

const UNITS = [
  { slug: "days", max: 7, label: "global.days" },
  { slug: "hours", max: 24, label: "global.hours" },
  { slug: "minutes", max: 60, label: "global.minutes" },
  { slug: "seconds", max: 60, label: "global.seconds" },
];
const UNITS_BY_SLUG = keyBy(UNITS, "slug");

const offsetToIndexImpl = (cellHeight: number) => (offset: number) =>
  -Math.round(offset / cellHeight) + 1;

const indexToOffsetImpl = (cellHeight: number) => (idx: number) =>
  -1 * (idx - 1) * cellHeight;

const anchorOffsetImpl = (cellHeight: number) => (offset: number) =>
  Math.round(offset / cellHeight) * cellHeight;

const getClientY = (e: any) => (e.touches ? e.touches[0].clientY : e.clientY);

const calculateOffsetToColumnRatio = (
  slideY: HTMLElement,
  container: HTMLElement
) => {
  const slideyRect = slideY.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const middleOfContainer = (containerRect.bottom + containerRect.top) / 2;
  return (
    (middleOfContainer - slideyRect.top) / (slideyRect.bottom - slideyRect.top)
  );
};

const swapArrayHalves = <T>(array: T[]): T[] => [
  ...array.slice(array.length / 2, array.length),
  ...array.slice(0, array.length / 2),
];

type Props = {
  onChange: (value: Integer) => any,
  unit: "hours" | "minutes" | "seconds" | "days",
  value: Integer,
  cellHeight?: Pixels,
};

const DurationPickerColumnContainer: React.ComponentType<Props> = ({
  onChange,
  unit,
  value,
  cellHeight = 35,
}) => {
  // Prepare the offset computers based on cellHeight.
  const offsetToIndex = React.useMemo(
    () => offsetToIndexImpl(cellHeight),
    [cellHeight]
  );
  const indexToOffset = React.useMemo(
    () => indexToOffsetImpl(cellHeight),
    [cellHeight]
  );
  const anchorOffset = React.useMemo(
    () => anchorOffsetImpl(cellHeight),
    [cellHeight]
  );

  const unitObj = React.useMemo(() => UNITS_BY_SLUG[unit], [unit]);
  const slideRef = React.useRef<?HTMLElement>(null);
  const boxRef = React.useRef<?HTMLElement>(null);
  const [offset, setOffset] = React.useState<number>(indexToOffset(value));

  // Following state is used for display updates, but should not trigger a re-render
  // in itself. Only change in offset does.
  const noReRenderState = React.useRef({
    lastY: 0,
    cellContents: range(0, unitObj.max),
    value: value,
    isInteracting: false,
  });

  // Reshuffle makes sure the wheel is ever spinning, by changing numbers
  // and offset when needed.
  const reshuffle = React.useCallback(
    (newOffset: number): number => {
      if (!slideRef.current || !boxRef.current) return newOffset;
      const slide = slideRef.current;
      const box = boxRef.current;

      const ratio = calculateOffsetToColumnRatio(slide, box);
      if (ratio >= 0.75 || ratio <= 0.25) {
        const { bottom, top } = slide.getBoundingClientRect();
        noReRenderState.current.cellContents = swapArrayHalves(
          noReRenderState.current.cellContents
        );
        return (
          newOffset +
          ((ratio >= 0.75 ? 1 : -1) * (bottom - top)) / 2 +
          (unitObj.max % 2 === 1 ? (-1 * cellHeight) / 2 : 0) // extra bit when num cells is odd
        );
      }
      return newOffset;
    },
    [slideRef, boxRef, unitObj, cellHeight]
  );

  // Always reshuffle when you change the offset.
  const handleSetOffset = React.useCallback(
    (setter: (number) => number) => setOffset((old) => reshuffle(setter(old))),
    [reshuffle]
  );

  // KEY HANDLING
  // On key down when the column has focus, move up or down one step.
  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      const increment =
        e.code === "ArrowUp" ? 1 : e.code === "ArrowDown" ? -1 : 0;
      if (increment !== 0) {
        e.preventDefault();
        handleSetOffset((offset) => offset + increment * cellHeight);
      }
    },
    [cellHeight, handleSetOffset]
  );
  const handleBlur = React.useCallback(() => {
    window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const handleFocus = React.useCallback(() => {
    window.addEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  // WHEEL SPINNING HANDLING
  // Whether touch or mouse, register the cursor position
  // and start interaction.
  const handleStartInteracting = React.useCallback(
    (e: SyntheticTouchEvent<> | SyntheticMouseEvent<>) => {
      e.preventDefault();
      noReRenderState.current.isInteracting = true;
      noReRenderState.current.lastY = getClientY(e);
    },
    []
  );

  // move the offset according to cursor movement.
  const handleMove = React.useCallback(
    (e: SyntheticTouchEvent<>) => {
      if (noReRenderState.current.isInteracting) {
        e.preventDefault();
        const pos = getClientY(e);
        handleSetOffset((old) => old + pos - noReRenderState.current.lastY);
        noReRenderState.current.lastY = pos;
      }
    },
    [handleSetOffset]
  );

  // Whether touch or mouse, stick to the nearest step
  // and stop interaction.
  const handleStopInteracting = React.useCallback(() => {
    handleSetOffset(anchorOffset);
    noReRenderState.current.isInteracting = false;
  }, [handleSetOffset, anchorOffset]);

  // When the offset is changed by any trigger, check if the value
  // has changed and fire the change event if required.
  React.useEffect(() => {
    if (!noReRenderState.current.isInteracting) {
      const newVal =
        noReRenderState.current.cellContents[offsetToIndex(offset)];
      if (newVal !== noReRenderState.current.value) {
        noReRenderState.current.value = newVal;
        onChange(newVal);
      }
    }
  }, [onChange, offsetToIndex, offset]);

  // When the value is changed by the parent component
  // set the offset accordingly. Do not use handleSetOffset
  // because we need the slider to move.
  React.useEffect(() => {
    if (
      !noReRenderState.current.isInteracting &&
      noReRenderState.current.value !== value
    ) {
      setOffset(
        indexToOffset(noReRenderState.current.cellContents.indexOf(value))
      );
    }
  }, [value, indexToOffset]);

  React.useEffect(() => {
    // set up and teardown listeners for mouse input
    window.addEventListener("mousemove", handleMove);
    window.addEventListener("mouseup", handleStopInteracting);
    return () => {
      window.removeEventListener("mousemove", handleMove);
      window.removeEventListener("mouseup", handleStopInteracting);
    };
  }, [handleMove, handleStopInteracting]);

  return (
    <DurationPickerColumn
      offset={offset}
      cellContents={noReRenderState.current.cellContents}
      unit={unitObj}
      slideyRef={slideRef}
      containerRef={boxRef}
      cellHeight={cellHeight}
      onTouchMove={handleMove}
      onTouchStart={handleStartInteracting}
      onTouchEnd={handleStopInteracting}
      onMouseDown={handleStartInteracting}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={value}
    />
  );
};

export default DurationPickerColumnContainer;
