// @flow
import * as React from "react";
import { Trans } from "react-i18next";
import type { TFunction } from "../../../hooks/useTranslate";

type Props = {
  id: string,
  data?: Object,
  components?: Object,
  t?: TFunction,
};

const Translate: React.ComponentType<Props> = ({ id, data, t, components }) => (
  <Trans i18nKey={id} values={data} t={t} components={components} />
);

export default Translate;
