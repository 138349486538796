// @flow
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";

export type Country = {
  code: string,
  name: string,
  label: string,
};

const COUNTRIES: Country[] = sortBy(
  [
    {
      code: "AE",
      value: "United Arab Emirates",
      label: "refer.countries.unitedArabEmirates",
    },
    { code: "AT", value: "Austria", label: "refer.countries.austria" },
    { code: "AU", value: "Australia", label: "refer.countries.australia" },
    { code: "BE", value: "Belgium", label: "refer.countries.belgium" },
    { code: "BG", value: "Bulgaria", label: "refer.countries.bulgaria" },
    { code: "BR", value: "Brazil", label: "refer.countries.brazil" },
    { code: "CA", value: "Canada", label: "refer.countries.canada" },
    { code: "CH", value: "Switzerland", label: "refer.countries.switzerland" },
    { code: "CY", value: "Cyprus", label: "refer.countries.cyprus" },
    {
      code: "CZ",
      value: "Czech Republic",
      label: "refer.countries.czechRepublic",
    },
    { code: "DE", value: "Germany", label: "refer.countries.germany" },
    { code: "DK", value: "Denmark", label: "refer.countries.denmark" },
    { code: "EE", value: "Estonia", label: "refer.countries.estonia" },
    { code: "ES", value: "Spain", label: "refer.countries.spain" },
    { code: "FI", value: "Finland", label: "refer.countries.finland" },
    { code: "FR", value: "France", label: "refer.countries.france" },
    {
      code: "GB",
      value: "United Kingdom",
      label: "refer.countries.unitedKingdom",
    },
    { code: "GR", value: "Greece", label: "refer.countries.greece" },
    { code: "HK", value: "Hong Kong", label: "refer.countries.hongKong" },
    { code: "HU", value: "Hungary", label: "refer.countries.hungary" },
    { code: "IE", value: "Ireland", label: "refer.countries.ireland" },
    { code: "IN", value: "India", label: "refer.countries.india" },
    { code: "IT", value: "Italy", label: "refer.countries.italy" },
    { code: "JP", value: "Japan", label: "refer.countries.japan" },
    { code: "LT", value: "Lithuania", label: "refer.countries.lithuania" },
    { code: "LU", value: "Luxembourg", label: "refer.countries.luxembourg" },
    { code: "LV", value: "Latvia", label: "refer.countries.latvia" },
    { code: "MT", value: "Malta", label: "refer.countries.malta" },
    { code: "MX", value: "Mexico", label: "refer.countries.mexico" },
    { code: "MY", value: "Malaysia", label: "refer.countries.malaysia" },
    { code: "NL", value: "Netherlands", label: "refer.countries.netherlands" },
    { code: "NO", value: "Norway", label: "refer.countries.norway" },
    { code: "NZ", value: "New Zealand", label: "refer.countries.newZealand" },
    { code: "PH", value: "Philippines", label: "refer.countries.philippines" },
    { code: "PL", value: "Poland", label: "refer.countries.poland" },
    { code: "PT", value: "Portugal", label: "refer.countries.portugal" },
    { code: "KE", value: "Kenya", label: "refer.countries.kenya" },
    { code: "RO", value: "Romania", label: "refer.countries.romania" },
    { code: "SE", value: "Sweden", label: "refer.countries.sweden" },
    { code: "SG", value: "Singapore", label: "refer.countries.singapore" },
    { code: "SI", value: "Slovenia", label: "refer.countries.slovenia" },
    {
      code: "US",
      value: "United States",
      label: "refer.countries.unitedStates",
    },
    { code: "ZA", value: "South Africa", label: "refer.countries.southAfrica" },
  ],
  "value"
);

const COUNTRIES_BY_VALUE = keyBy(COUNTRIES, "value");

export const stripeCodeForCountry = (value: string): string =>
  COUNTRIES_BY_VALUE[value]?.code ?? value;

export const getCountryFromValue = (value: ?string): ?Country =>
  COUNTRIES_BY_VALUE[value];

export default COUNTRIES;
