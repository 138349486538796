// @flow
import * as React from "react";
import type { Props as SettingsSectionProps } from "../../../layout/SettingsSection";
import SettingsSection from "../../../layout/SettingsSection";
import type { Form } from "../../../../hooks/useForm";
import { FormActions } from "../../../../hooks/useForm";
import useTranslate from "../../../../hooks/useTranslate";
import FormSwitchField from "../../../lib/inputs/form/FormSwitchField";
import { Alert, Link } from "@mui/material";
import type { BillingInfoFormDef } from "../../BillingInfoWidget/BillingInfoWidget";
import BillingInfoWidget from "../../BillingInfoWidget/BillingInfoWidget";

type Props = {
  ...Omit<SettingsSectionProps, "title" | "children">,
  loading?: boolean,
  form: Form<BillingInfoFormDef>,
};

const BillingInfoSection: React.ComponentType<Props> = ({
  form,
  loading,
  ...props
}) => {
  const t = useTranslate();
  return (
    <SettingsSection title="global.billing" loading={loading} {...props}>
      <BillingInfoWidget editing={props.editing} form={form} />
      <FormSwitchField
        formField={form.state.enableSendInvoices}
        onChange={FormActions.onChangeField(form.set, "enableSendInvoices")}
        label={t("Page.SettingsSection.SubscriptionTab.enableSendInvoices")}
        disabled={!props.editing}
      >
        <Alert severity="info">
          {t(
            "Page.SettingsSection.SubscriptionTab.enableSendInvoicesDescription"
          )}
          <br />
          {t("Page.SettingsSection.SubscriptionTab.findYourInvoicesHere")}{" "}
          <Link
            style={{ fontWeight: "bold" }}
            href="https://billing.stripe.com/p/login/14k4gXcwzeGWcgwfYY"
            target="_blank"
          >
            {t("global.here")}
          </Link>
          .
        </Alert>
      </FormSwitchField>
    </SettingsSection>
  );
};

export default BillingInfoSection;
