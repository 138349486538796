// @flow
import { API as backend } from "./backend.service";
import * as snacks from "../models/alerts.model";
import type { AsyncAction, AsyncVoidAction } from "./types";
import type { ModelID } from "../types";
import type { FreezeUnfreezeParams } from "./backend.service/subscription";
import type { TransientBillingInfo } from "../models/billingInfo.model";

export const getSubscription: AsyncVoidAction<void> = (actions) => () => {
  backend.subscription
    .getSubscription()
    .then(actions.subscription.loadSubscription);
  backend.subscription
    .loadAllAddOns()
    .then(actions.subscription.addOns.replaceAll);
};

export const getPlanFlavours: AsyncVoidAction<void> = (actions) => () => {
  backend.subscription
    .getPlanFlavours()
    .then(actions.subscription.planFlavours.replaceAll);
};

export const editPlan: AsyncAction<Promise<void>, ModelID> =
  (actions) => (planFlavourId) => {
    actions.subscription.lock();
    return backend.subscription
      .updatePlan(planFlavourId)
      .then(actions.subscription.loadSubscription)
      .then(() => {
        actions.snacks.append(
          snacks.localSuccess({ message: "store.subscription.planEdited" })
        );
      })
      .finally(actions.subscription.unlock);
  };

export const freezeSubscription: AsyncVoidAction<FreezeUnfreezeParams> =
  (actions) => (params) => {
    actions.subscription.lock();
    return backend.subscription
      .freezeSubscription(params)
      .then(actions.subscription.loadSubscription)
      .then(() => {
        actions.snacks.append(
          snacks.localSuccess({ message: "store.subscription.planFrozen" })
        );
      })
      .finally(actions.subscription.unlock);
  };

export const purchaseAddOn: AsyncAction<Promise<void>, ModelID> =
  (actions) => (pricingId) => {
    actions.subscription.lock();
    return backend.subscription
      .purchaseAddOn(pricingId)
      .then(actions.profile.loadUserLimits)
      .then(() => actions.subscription.addAddOnToSubscription(pricingId))
      .then(() => {
        actions.snacks.append(
          snacks.localSuccess({
            message: "Page.SubscriptionPanel.addOnPurchased",
          })
        );
      })
      .finally(actions.subscription.unlock);
  };

export const cancelAddOn: AsyncAction<Promise<void>, ModelID> =
  (actions) => (pricingId) => {
    actions.subscription.lock();
    return backend.subscription
      .cancelAddOn(pricingId)
      .then(actions.profile.loadUserLimits)
      .then(() => actions.subscription.removeAddOnFromSubscription(pricingId))
      .then(() => {
        actions.snacks.append(
          snacks.localSuccess({
            message: "Page.SubscriptionPanel.addOnCancelled",
          })
        );
      })
      .finally(actions.subscription.unlock);
  };

export const unfreezeSubscription: AsyncVoidAction<void> = (actions) => () => {
  actions.subscription.lock();
  return backend.subscription
    .unfreezeSubscription()
    .then(actions.subscription.loadSubscription)
    .then(() => {
      actions.snacks.append(
        snacks.localSuccess({ message: "store.subscription.planUnfrozen" })
      );
    })
    .finally(actions.subscription.unlock);
};

export const applyPromoCode: AsyncAction<Promise<void>, string> =
  (actions) => (code) => {
    actions.subscription.lock();
    return backend.subscription
      .applyPromoCode(code)
      .then(() => {
        actions.snacks.append(
          snacks.localSuccess({
            message: "store.subscription.promocodeApplied",
          })
        );
      })
      .finally(actions.subscription.unlock);
  };

export const updateInvoicingPreferences: AsyncVoidAction<boolean> =
  (actions) => (sendInvoices) => {
    return backend.subscription.updateInvoicingPreferences(sendInvoices);
  };

export const updateBillingInfo: AsyncAction<
  Promise<void>,
  TransientBillingInfo
> = (actions) => (billingInfo) => {
  actions.subscription.lock();
  return backend.subscription
    .updateBillingInfo(billingInfo)
    .then(actions.subscription.loadSubscription)
    .then(() => {
      actions.snacks.append(
        snacks.localSuccess({
          message: "store.subscription.invoicingPreferencesSaved",
        })
      );
    })
    .finally(actions.subscription.unlock);
};
