// @flow
import type { HexColor, Integer, ModelID, URLStr } from "../types";

export type PortfolioDisplay = {
  /** URL to the portfolio avatar. */
  avatar?: URLStr,
  /** Color of the portfolio. */
  color?: HexColor,
  /** Portfolio initials */
  initials: ?string,
};

/**
 * A portfolio that contains a set of social media accounts related in some way.
 */
export type Portfolio = {
  ...PortfolioDisplay,
  id: ModelID,
  /** The name of the portfolio. */
  name: string,
  /** The description of the portfolio. */
  description: string,
  /** The ID of the portfolio timezone. */
  timezone?: number | null,
  /** The public hash of the portfolio. */
  public_hash: ?string,
  /** Whether the calendar is public. */
  is_calendar_public: boolean,
  /** Display order in the UI. */
  display_order: Integer,
};

export const getPortfolioName = (pf: Portfolio): string => pf.name;

export const getPortfolioDisplayOrder = (pf: Portfolio): number =>
  pf.display_order;
