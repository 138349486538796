// @flow
import * as React from "react";
import PostBuilderContext, {
  PostBuilderContextProvider,
} from "../../../contexts/postBuilder";
import { ColumnStack, RowStack } from "../../lib/layout/stacks";
import PostContentInput from "../postCreator/PostContentInput";
import DynamicContentWidget from "../postCreator/DynamicContentWidget";
import PostLinkWidget from "../postCreator/PostLinkWidget";
import PostAttachmentsWidget from "../postCreator/PostAttachmentsWidget";
import FirstCommentWidget from "../postCreator/FirstCommentWidget";
import ThreadWidget from "../postCreator/ThreadWidget";
import PollWidget from "../postCreator/PollWidget";
import WidgetHead from "../../inputs/WidgetHead";
import useTranslate from "../../../hooks/useTranslate";
import { EditRounded } from "@mui/icons-material";
import { useStoreActions } from "../../../store/store";
import { Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes, { getRoutePath } from "../../../config/routes.config";
import type { ModelID } from "../../../types";
import SelectPostTargetWidget from "../postCreator/SelectPostTargetWidget";
import PublishButton from "../../../pages/AppPage/AddEditPostPage/PublishButton";
import { styled } from "@mui/material/styles";
import PostStatusInput from "../postCreator/PostStatusInput";
import PostScheduleWidget from "../postCreator/PostScheduleWidget";
import CurrentPostDiagnosticErrors from "../postCreator/CurrentPostDiagnosticErrors";
import { SmallNeutralButton } from "../../lib/inputs/buttons";
import { Body2 } from "../../lib/display/Text";

type Props = {
  portfolioId: ModelID,
};

const QuickSchedulingBlock = styled(Paper, {
  shouldForwardProp: (prop) => !["flexGrow"].includes(prop),
})(({ theme, flexGrow }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(1),
  minHeight: 76,
  gap: theme.spacing(1),
  flexDirection: "column",
  flexShrink: 1,
  flexGrow,
}));

const QuickPostWidgetInner: React.ComponentType<Props> = ({ portfolioId }) => {
  const t = useTranslate();
  const actions = useStoreActions();
  const navigate = useNavigate();
  const { post, subchannel } = React.useContext(PostBuilderContext);
  const handleOpenInEditor = () => {
    actions.post.onSetBuilderPayload({ post: { ...post }, subchannel });
    navigate(
      getRoutePath(routes.app.nested.portfolios.nested.addPost, { portfolioId })
    );
  };

  return (
    <div>
      <WidgetHead
        primary={t("global.quickPost")}
        actions={
          <SmallNeutralButton onClick={handleOpenInEditor}>
            <EditRounded size="small" color="action" />
            &nbsp;
            {t("global.openInEditor")}
          </SmallNeutralButton>
        }
      />
      <ColumnStack flexGrow={1}>
        <Body2 color="textSecondary">{t("global.quickPostSubtitle")}</Body2>
        <PostContentInput minRows={5} maxRows={8} />
        <Grid container rowGap={1} columnGap={1}>
          <Grid item sm={12} md lg="auto">
            <ColumnStack>
              <QuickSchedulingBlock>
                <SelectPostTargetWidget variant="quick" />
              </QuickSchedulingBlock>
              <CurrentPostDiagnosticErrors />
            </ColumnStack>
          </Grid>
          <Grid item sm={12} md>
            <QuickSchedulingBlock flexGrow={1}>
              <RowStack>
                <PostStatusInput variant="quick" />
                <PublishButton />
              </RowStack>
              <PostScheduleWidget />
            </QuickSchedulingBlock>
          </Grid>
        </Grid>
        <DynamicContentWidget />
        <PostLinkWidget />
        <PostAttachmentsWidget />
        <FirstCommentWidget />
        <ThreadWidget />
        <PollWidget />
      </ColumnStack>
    </div>
  );
};

const QuickPostWidget: React.ComponentType<Props> = ({ portfolioId }) => (
  <PostBuilderContextProvider>
    <QuickPostWidgetInner portfolioId={portfolioId} />
  </PostBuilderContextProvider>
);

export default QuickPostWidget;
