// @flow
import * as React from "react";
import flow from "lodash/flow";
import type { Callback, I18nKey } from "../../types";
import Dialog from "../lib/feedback/Dialog";
import { VpnKeyRounded } from "@mui/icons-material";
import { InlineRowStack } from "../lib/layout/stacks";
import { Subheader } from "../lib/display/Text";
import { isIOS } from "../../lib/platform.lib";
import { NeutralTextButton, PrimaryButton } from "../lib/inputs/buttons";
import useTranslate from "../../hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import routes, { getRoutePath } from "../../config/routes.config";

type Props = {
  open?: boolean,
  onClose: Callback,
  title: I18nKey,
  message: I18nKey,
  messageData?: Object,
  acceptLabel: I18nKey,
  cancelLabel: I18nKey,
};

const PlanUpgradeInviteDialog: React.ComponentType<Props> = ({
  open,
  onClose,
  title,
  message,
  messageData,
  acceptLabel,
  cancelLabel,
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleAccept = () =>
    navigate(getRoutePath(routes.app.nested.planAndAddons.nested.plan));
  return (
    <Dialog
      name="upgrade-invite-dialog"
      open={open}
      onClose={onClose}
      title={
        <InlineRowStack alignItems="center">
          <VpnKeyRounded htmlColor="gold" fontSize="medium" />
          <Subheader>{t(title)}</Subheader>
        </InlineRowStack>
      }
      text={
        <>
          {t(message, messageData)}
          <br />
          {isIOS && t("UpgradeRequiredDialog.iOSMention")}
        </>
      }
      actions={
        <>
          <NeutralTextButton onClick={onClose}>
            {t(cancelLabel)}
          </NeutralTextButton>
          <PrimaryButton
            onClick={isIOS ? null : flow(onClose, handleAccept)}
            autoFocus
          >
            {t(acceptLabel)}
          </PrimaryButton>
        </>
      }
    />
  );
};
export default PlanUpgradeInviteDialog;
