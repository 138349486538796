// @flow
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import fromPairs from "lodash/fromPairs";

const pick = (query: URLSearchParams, keys: $ReadOnlyArray<string>) =>
  fromPairs(keys.map((k) => [k, query.get(k)]));

/**
 *
 * @return {Pick<URLSearchParams, keyof T>}
 */
const useLocationParams = <Key: string>(
  params: $ReadOnlyArray<Key>
): Record<Key, string> => {
  const location = useLocation();
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  return useMemo(() => pick(query, params), [params, query]);
};

export default useLocationParams;
