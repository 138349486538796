// @flow
import { drawVideoFrame } from "./images.lib";
import type { Milliseconds, Pixels } from "../types";

type CreateThumbnailFromVideo = {
  video: HTMLVideoElement,
  offset?: Milliseconds,
  width?: Pixels,
  height?: Pixels,
};

/**
 * Extracts a thumbnail image from the provided video URL.
 * @param url {string} The URL of the video to load.
 * @param offset {number} The offset to take the thumbnail from, in milliseconds.
 * @param width {number} Optional. Width of the thumbnail. Defaults to video width.
 * @param height {number} Optional. Height of the thumbnail. Defaults to video height.
 * @return {Promise<HTMLImageElement>}
 */
export const createThumbnailFromVideo = ({
  video,
  offset = 1000,
  width,
  height,
}: CreateThumbnailFromVideo): Promise<HTMLImageElement> =>
  new Promise((resolve) => {
    video.addEventListener("timeupdate", () => {
      drawVideoFrame(video, width, height).then(resolve);
    });
    video.currentTime = offset / 1000;
  });
