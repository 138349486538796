// @flow
import * as React from "react";
import useTranslate from "../../hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import type { BrowserRoute } from "../../config/routes.config";
import { isParentRouteOf } from "../../config/routes.config";
import { PrimaryButton } from "../lib/inputs/buttons";
import type { I18nKey } from "../../types";
import useUpgradeRequiredDialog from "../feedback/useUpgradeRequiredDialog";
import type { LimitSet } from "../../models/limitSet.model";
import useCurrentRoute from "../../hooks/useCurrentRoute";

export type Props = {
  label: I18nKey,
  listRoute: BrowserRoute,
  size?: "small" | "medium" | "large",
  addVerb?: string,
  permission?: $Keys<LimitSet>,
};

const AddResourceButton: React.ComponentType<Props> = ({
  label,
  listRoute,
  addVerb = "add",
  size = "medium",
  permission,
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const redirect = React.useCallback(() => {
    navigate(addVerb, { relative: "path" });
  }, [navigate, addVerb]);
  const [onClick, dlg] = useUpgradeRequiredDialog(redirect, permission);

  return (
    <>
      {dlg}
      <PrimaryButton
        size={size}
        disabled={isParentRouteOf(listRoute, currentRoute)}
        onClick={onClick}
      >
        {t(label)}
      </PrimaryButton>
    </>
  );
};

export default AddResourceButton;
