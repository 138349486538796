// @flow
import * as React from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import "./emoji-keyboard.css";
import noop from "lodash/noop";
import type { Callback } from "../../../../../../types";
import { useTheme } from "@mui/material";
import useLocale from "../../../../../../hooks/useLocale";

type Props = {
  includeRecent?: boolean,
  onSelectEmoji: (string) => any,
  onClose?: Callback,
};

const EmojiKeyboard: React.ComponentType<Props> = React.forwardRef(
  ({ includeRecent, onSelectEmoji, onClose = noop }, ref) => {
    const theme = useTheme();
    const locale = useLocale();
    return (
      <div ref={ref}>
        <Picker
          exclude={includeRecent ? undefined : ["recent"]}
          data={data}
          onClickOutside={onClose}
          onEmojiSelect={(emoji) => onSelectEmoji(emoji.native)}
          sheetSize={16}
          emojiSize={24}
          autoFocus
          theme={theme.palette.type}
          locale={locale.language}
        />
      </div>
    );
  }
);
export default EmojiKeyboard;
