// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import { Outlet } from "react-router-dom";
import { useHashtagGroups } from "../../../store/selectors";
import AddResourceButton from "../../../components/inputs/AddResourceButton";
import HashtagGroupsTable from "../../../components/widgets/HashtagGroupsTable";
import { useScopedPortfolio } from "../../../scopes/scopes";
import { byMaybeProp } from "../../../models/base.model";
import * as hashtagGroupService from "../../../services/hashtagGroup.service";
import { useStoreActions } from "../../../store/store";
import { SkeletonHashtagGroupsTable } from "../../../components/widgets/HashtagGroupsTable/HashtagGroupsTable";
import StandardEmptyNotice from "../../../components/display/EmptyNotice";
import type { ModelID } from "../../../types";
import { InlineRowStack } from "../../../components/lib/layout/stacks";
import { Center } from "../../../components/lib/layout/placement";
import type { TableSelection } from "../../../components/lib/display/DataTable/base";

const HashtagGroupsPage: React.ComponentType<empty> = () => {
  const pf = useScopedPortfolio();
  const actions = useStoreActions();
  const hashtagGroups = useHashtagGroups().filter(
    byMaybeProp("portfolio_id", pf?.id)
  );

  const handleDelete = React.useCallback(
    (selection: TableSelection<ModelID>) =>
      [...selection.selection].map((id) =>
        hashtagGroupService.deleteHashtagGroup(actions)(id)
      ),
    [actions]
  );

  const route = routes.app.nested.portfolios.nested.hashtags;
  return (
    <>
      <PrimaryPagePanel size="fill" route={route}>
        {hashtagGroups.length ? (
          <>
            <InlineRowStack>
              <AddResourceButton
                listRoute={route}
                label="routes.addHashtagGroup.label"
                permission="can_use_comment_manager"
              />
            </InlineRowStack>
            <HashtagGroupsTable rows={hashtagGroups} onDelete={handleDelete} />
          </>
        ) : (
          <Center>
            <StandardEmptyNotice
              label="global.noHashtagGroups"
              addResourceButtonProps={{
                size: "large",
                label: "routes.addHashtagGroup.label",
                permission: "can_use_hashtag_manager",
                listRoute: route,
              }}
            >
              <SkeletonHashtagGroupsTable />
            </StandardEmptyNotice>
          </Center>
        )}
      </PrimaryPagePanel>
      <Outlet />
    </>
  );
};

export default HashtagGroupsPage;
