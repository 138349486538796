// @flow
import * as React from "react";
import type { SyntheticEventListener } from "../reactTypes";
import type { Callback } from "../types";

type EventTargetState = [?EventTarget, SyntheticEventListener, Callback];

const useEventTargetState = (initial?: EventTarget): EventTargetState => {
  const [el, setEl] = React.useState<?EventTarget>(initial);
  const captureTarget = React.useCallback((e: SyntheticEvent<>) => {
    e.stopPropagation();
    setEl(e.currentTarget);
  }, []);
  const clearTarget = React.useCallback(() => setEl(null), []);

  return [el, captureTarget, clearTarget];
};

export default useEventTargetState;
