// @flow
import ajax from "../../lib/ajax.lib";

import omit from "lodash/omit";
import type { HashtagGroup } from "../../models/hashtagGroup.model";
import type { APINoContentResult, APIResult } from "./types";
import type { ModelID, Transient } from "../../types";

export const addHashtagGroup = (
  hashtagGroup: Transient<HashtagGroup>
): Promise<HashtagGroup> =>
  ajax
    .post<Transient<HashtagGroup>, APIResult<HashtagGroup>>(
      "/hashtag_group",
      hashtagGroup
    )
    .then((response) => response.data.data);

export const editHashtagGroup = (
  hashtagGroup: HashtagGroup
): Promise<HashtagGroup> =>
  ajax
    .put<HashtagGroup, APIResult<HashtagGroup>>(
      `/hashtag_group/${hashtagGroup.id}`,
      omit(hashtagGroup, ["id"])
    )
    .then((response) => response.data.data);

export const deleteHashtagGroup = (
  hashtagGroupId: ModelID
): Promise<APINoContentResult> =>
  ajax.delete<APINoContentResult>(`/hashtag_group/${hashtagGroupId}`);

export const getHashtagGroups = (): Promise<HashtagGroup[]> =>
  ajax
    .get<APIResult<HashtagGroup[]>>("/hashtag_group")
    .then((response) => response.data.data);
