// @flow
import * as React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import { useIsNavigationDrawerOpen } from "../../../store/selectors";
import { useStoreActions } from "../../../store/store";
import { MenuRounded } from "@mui/icons-material";

type Props = {
  variant?: "permanent" | "temporary",
};

const getIcon = (variant: Props["variant"], open: boolean) => {
  if (variant === "temporary") return MenuRounded;
  return open ? ChevronLeftIcon : ChevronRightIcon;
};

const ToggleNavMenuIconButton: React.ComponentType<Props> = ({
  variant = "permanent",
}) => {
  const open = useIsNavigationDrawerOpen();
  const actions = useStoreActions();
  const Icon = getIcon(variant, open);
  return (
    <IconButton onClick={() => actions.global.onSetDrawerOpen(!open)}>
      <Icon />
    </IconButton>
  );
};

export default ToggleNavMenuIconButton;
