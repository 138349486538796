// @flow
import * as React from "react";
import moment from "moment";
import compact from "lodash/compact";
import type { Integer } from "../../../types";
import useTranslate from "../../../hooks/useTranslate";

type Props = {
  minutes: Integer,
};

const Duration: React.ComponentType<Props> = ({ minutes }) => {
  const t = useTranslate();
  const d = moment.duration(minutes, "minutes");
  return React.useMemo(
    () =>
      compact([
        d.days() > 0 ? `${d.days()} ${t("global.days")}` : "",
        d.hours() > 0 ? `${d.hours()} ${t("global.hours")}` : "",
        d.minutes() > 0 ? `${d.minutes()} ${t("global.minutes")}` : "",
      ]).join(", "),
    [d, t]
  );
};

export default Duration;
