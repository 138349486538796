// @flow
import * as React from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import type { DataTableProps, RowId } from "./base";
import TableToolbar from "./TableToolbar";
import { TableRootPaper, TableRow, useDataTableParams } from "./base";

const DataTable = <DataItem, K: RowId = RowId, CellProps = {}>({
  actions,
  slotProps,
  loading,
  ...props
}: DataTableProps<DataItem, K, CellProps>): React.Node => {
  const params = useDataTableParams(props);
  return (
    <TableRootPaper {...slotProps?.paper}>
      {loading && <LinearProgress />}
      {slotProps?.toolbar && (
        <TableToolbar
          {...slotProps.toolbar}
          actions={actions}
          selection={params.selection}
        />
      )}
      <TableContainer {...slotProps?.tableContainer}>
        <Table {...slotProps?.table} stickyHeader>
          <TableHead>{params.getFixedHeaderContent()}</TableHead>
          <TableBody>
            {props.rows.map((row, i) => (
              <TableRow key={params.getRowId(row)}>
                {params.getRowContent(i, row)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableRootPaper>
  );
};

export default DataTable;
