// @flow
import * as React from "react";
import noop from "lodash/noop";
import type { Provider } from "../reactTypes";

type EngagementHubContextState = {
  commentedPostsOnly: boolean,
  unreadItemsOnly: boolean,
};

export type EngagementHubContextType = {
  onSetCommentedPostsOnly: (boolean) => any,
  onSetUnreadItemsOnly: (boolean) => any,
  ...EngagementHubContextState,
};

const EMPTY_STATE: EngagementHubContextState = {
  commentedPostsOnly: false,
  unreadItemsOnly: false,
};

const EngagementHubContext: React.Context<EngagementHubContextType> =
  React.createContext({
    onSetCommentedPostsOnly: noop,
    onSetUnreadItemsOnly: noop,
    ...EMPTY_STATE,
  });

/**
 * Provides a context to configure the engagement hub.
 * @constructor
 */
export const EngagementHubContextProvider: Provider<
  EngagementHubContextType,
  { initial?: { unreadItemsOnly?: boolean } }
> = ({ children, initial }) => {
  const [state, onSetState] = React.useState<EngagementHubContextState>({
    ...EMPTY_STATE,
    ...initial,
  });
  const set = React.useCallback(
    <k: $Keys<EngagementHubContextState>>(
      field: k,
      value: EngagementHubContextState[k]
    ): void =>
      onSetState((prevState) => ({ ...prevState, [(field: string)]: value })),
    []
  );

  return (
    <EngagementHubContext.Provider
      value={{
        onSetCommentedPostsOnly: React.useCallback(
          (val) => set("commentedPostsOnly", val),
          [set]
        ),
        onSetUnreadItemsOnly: React.useCallback(
          (val) => set("unreadItemsOnly", val),
          [set]
        ),
        ...state,
      }}
    >
      {children}
    </EngagementHubContext.Provider>
  );
};

export default EngagementHubContext;
