// @flow
import * as React from "react";

type SwitchState = [boolean, () => void, () => void];

const useSwitch = (initial: boolean = false): SwitchState => {
  const [value, setValue] = React.useState<boolean>(initial);
  const setOn = React.useCallback(() => setValue(true), []);
  const setOff = React.useCallback(() => setValue(false), []);

  return [value, setOn, setOff];
};

export default useSwitch;
