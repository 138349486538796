// @flow
import * as React from "react";
import type { BobcaatAccount } from "../../models/account.model";
import { getAccountLongName } from "../../models/account.model";
import Select from "../lib/inputs/Select";
import useTranslate from "../../hooks/useTranslate";
import PostFiltersContext from "../../contexts/postFilters";
import compact from "lodash/compact";
import { byId, getIdAsString } from "../../models/base.model";
import type { Portfolio } from "../../models/portfolio.model";
import { useStoreActions } from "../../store/store";
import * as analyticsService from "../../services/analytics.service";
import { LargeNeutralButton, LargePrimaryButton } from "../lib/inputs/buttons";
import { useAnalyticsLocked } from "../../store/selectors";
import DateRangeSelect from "../inputs/DateRangeSelect";
import { AccountAdornment } from "../display/Adornments";
import useSwitch from "../../hooks/useSwitch";
import ExportAnalyticsDialog from "./ExportAnalyticsDialog";
import { Stack } from "@mui/material";
import { ColumnStack } from "../lib/layout/stacks";
import { DownloadRounded } from "@mui/icons-material";

export type Props = {
  accounts: BobcaatAccount[],
  portfolio: Portfolio,
  direction: "column" | "row",
};

const CONTROL_WIDTH = {
  sx: undefined, // full width as per container,
  sm: 198,
  md: 300,
};
const SMALL_WIDTH_DATE_RANGE_INPUTS_CONTAINER_ID =
  "small_width_date_range_inputs_container";

const getDateRangeInputsContainer = () =>
  document.getElementById(SMALL_WIDTH_DATE_RANGE_INPUTS_CONTAINER_ID);

const AnalyticsControls: React.ComponentType<Props> = ({
  accounts,
  portfolio,
  direction,
}) => {
  const t = useTranslate();
  const actions = useStoreActions();
  const analyticsLocked = useAnalyticsLocked();
  const [exportDialogOpen, openExportDialog, closeExportDialog] = useSwitch();
  const {
    accountIds,
    onSetAccountIds,
    fromDate,
    toDate,
    onSetFromDate,
    onSetToDate,
  } = React.useContext(PostFiltersContext);

  const selectedAccounts = compact(
    accountIds.map((accId) => accounts.find(byId(accId)))
  );

  const handleRequest = () => {
    accountIds.length > 0 &&
      fromDate &&
      toDate &&
      analyticsService.getAnalytics(actions)({
        portfolioId: portfolio.id,
        accountId: accountIds[0],
        start: fromDate,
        end: toDate,
      });
  };

  return (
    <>
      <ExportAnalyticsDialog
        onClose={closeExportDialog}
        open={exportDialogOpen}
      />
      <ColumnStack>
        <Stack direction={direction} alignItems="stretch">
          <Select
            width={CONTROL_WIDTH}
            id="select-accounts"
            multiple={false}
            value={selectedAccounts.length > 0 ? selectedAccounts[0] : ""}
            onChange={(acc) => onSetAccountIds(acc ? [acc.id] : [])}
            getOptionLabel={getAccountLongName}
            getOptionValue={getIdAsString}
            label={t("global.filterByAccount")}
            options={accounts}
            renderValue={getAccountLongName}
            adornOptions
            AdornmentComponent={AccountAdornment}
          />
          {fromDate && toDate && (
            <DateRangeSelect
              fromDate={fromDate}
              toDate={toDate}
              onChangeFromDate={onSetFromDate}
              onChangeToDate={onSetToDate}
              slotProps={{
                dateRangeSelect: { width: CONTROL_WIDTH },
                dateRangeInputsStack: { direction },
                dateRangeInputs: {
                  datePicker: { sx: { width: CONTROL_WIDTH } },
                },
              }}
              dateRangeInputsContainer={
                direction === "row" ? getDateRangeInputsContainer : undefined
              }
            />
          )}
          <LargePrimaryButton
            onClick={handleRequest}
            loading={analyticsLocked}
            disabled={accountIds.length === 0}
          >
            {t("global.load")}
          </LargePrimaryButton>
          <LargeNeutralButton
            onClick={openExportDialog}
            loading={analyticsLocked}
            title={t("global.export")}
          >
            {direction === "row" ? <DownloadRounded /> : t("global.export")}
          </LargeNeutralButton>
        </Stack>
        {direction === "row" && (
          // Date range custom inputs will be mounted here.
          <div id={SMALL_WIDTH_DATE_RANGE_INPUTS_CONTAINER_ID} />
        )}
      </ColumnStack>
    </>
  );
};

export default AnalyticsControls;
