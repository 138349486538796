// @flow
import * as React from "react";

export type LockActions = { lock: () => void, unlock: () => void };
export type LockState = { locked: boolean };

export const useLockState = (
  initial?: boolean = false
): [boolean, LockActions] => {
  const [locked, setLocked] = React.useState(initial);
  return React.useMemo(
    () => [
      locked,
      { lock: () => setLocked(true), unlock: () => setLocked(false) },
    ],
    [locked]
  );
};
