// @flow
import * as React from "react";
import type { I18nKey, Callback } from "../../../types";
import Dialog from "./Dialog";
import type { Props as DialogProps } from "./Dialog";
import useTranslate from "../../../hooks/useTranslate";
import HTMLForm from "../inputs/form/HTMLForm";
import type { Props as FormProps } from "../inputs/form/HTMLForm";
import { styled } from "@mui/material/styles";
import flow from "lodash/flow";

export type Props = {
  ...Omit<DialogProps, "actions">,
  title?: I18nKey,
  text?: I18nKey,
  onClose?: Callback,
  onCancel?: Callback,
  onSubmit: () => any,
  formProps?: Omit<FormProps, "onSubmit" | "onCancel" | "loading" | "children">,
};

const SpacedForm = styled(HTMLForm)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const FormDialog: React.ComponentType<Props> = ({
  title,
  text,
  onClose,
  onSubmit,
  formProps,
  children,
  onCancel,
  ...props
}) => {
  const t = useTranslate();
  const handleSubmit = React.useCallback(
    () => Promise.resolve(onSubmit()).then(onClose),
    [onSubmit, onClose]
  );

  return (
    <Dialog
      onClose={onClose}
      title={title ? t(title) : undefined}
      text={text ? t(text) : undefined}
      {...props}
    >
      <SpacedForm
        onSubmit={handleSubmit}
        onCancel={onCancel && onClose ? flow(onCancel, onClose) : onClose}
        {...formProps}
      >
        {children}
      </SpacedForm>
    </Dialog>
  );
};

export default FormDialog;
