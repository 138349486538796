// @flow
import * as React from "react";
import CommentEngagementEditor from "./CommentEngagementEditor";
import { useScopedAccount, useScopedPost } from "../../../../scopes/scopes";
import { useStoreActions } from "../../../../store/store";
import { useCommentSummary } from "../../../../store/selectors";
import * as engagementService from "../../../../services/engagement.service";
import { useNavigate } from "react-router-dom";

const CommentEngagementEditorContainer: React.ComponentType<{}> = () => {
  const post = useScopedPost();
  const account = useScopedAccount();
  const actions = useStoreActions();
  const summary = useCommentSummary(post?.id);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (post?.id && (summary?.comments_count ?? 0) > 0) {
      engagementService.getPostComments(actions)(post.id);
    }
  }, [actions, summary?.comments_count, post?.id]);

  const handleSendNewComment = (message: string) => {
    return post && engagementService.addComment(actions)(post.id, { message });
  };

  const handleClose = React.useCallback(() => {
    navigate("..", { relative: "route" });
  }, [navigate]);

  if (!post || !account) {
    return null;
  }

  return (
    <CommentEngagementEditor
      post={post}
      account={account}
      onClose={handleClose}
      onSendNewComment={handleSendNewComment}
    />
  );
};

export default CommentEngagementEditorContainer;
