// @flow
import CheckCircleRounded from "@mui/icons-material/CheckCircleRounded";
import ScheduledIcon from "@mui/icons-material/ScheduleRounded";
import DeletedIcon from "@mui/icons-material/DeleteRounded";
import DraftIcon from "@mui/icons-material/InboxRounded";
import type { PostStatus } from "../../models/post.model";
import { SvgIcon } from "@mui/material";

const postStatusIcons: Record<PostStatus, typeof SvgIcon> = {
  scheduled: ScheduledIcon,
  draft: DraftIcon,
  published: CheckCircleRounded,
  deleted: DeletedIcon,
};

const postStatusIconsAndColor: Record<PostStatus, [typeof SvgIcon, string]> = {
  scheduled: [postStatusIcons.scheduled, "info"],
  draft: [postStatusIcons.draft, "warning"],
  published: [postStatusIcons.published, "success"],
  deleted: [postStatusIcons.deleted, "error"],
};

export const getPostStatusIcon = (status: PostStatus): typeof SvgIcon =>
  postStatusIcons[status];

export const getPostStatusIconAndColor = (
  status: PostStatus
): [typeof SvgIcon, string] => postStatusIconsAndColor[status];

export default postStatusIcons;
