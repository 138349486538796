// @flow
import * as React from "react";
import PostBuilderContext from "../../../contexts/postBuilder";
import usePostDiagnostic from "../../../hooks/postCreator/usePostDiagnostic";
import DiagnosticErrors from "../../display/DiagnosticErrors";
import { Alert } from "@mui/material";
import Translate from "../../lib/display/Translate";

const CurrentPostDiagnosticErrors: React.ComponentType<{}> = () => {
  const { channel, post } = React.useContext(PostBuilderContext);
  const postDiagnostics = usePostDiagnostic(channel);
  if (!channel) return null;
  return (
    <>
      <DiagnosticErrors channel={channel} errors={postDiagnostics} />
      {channel.features?.content?.polls?.pollsOrAttachments &&
        (post.poll || post.attachments.length > 0) && (
          <Alert severity="info">
            <Translate
              id="PostCreator.unsupportedAttachmentsWithPoll"
              data={{ channel: channel.name }}
            />
          </Alert>
        )}
    </>
  );
};

export default CurrentPostDiagnosticErrors;
