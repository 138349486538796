// @flow
import type { ModelID, Timestamp, URLStr } from "../types";
import canvaLogo from "../assets/images/integrations/canva.png";
import { Moment } from "../types";
import moment from "moment";

/**
 * The apps bobcaat supports integration for.
 */
export type Apps = "canva";

export type App = {
  slug: Apps,
  name: string,
  thumbnail: URLStr,
  connectURL: URLStr,
};

/**
 * An integration of an app for the current user.
 */
export type Integration = {
  id: ModelID,
  created_at: Moment,
  app: Apps,
};

export const CANVA: App = {
  slug: "canva",
  name: "Canva",
  connectURL: "https://www.canva.com/apps/AAExmNltfiQ-bobcaat",
  thumbnail: canvaLogo,
};

export const APPS: App[] = [CANVA];

type APIIntegration = {
  ...Omit<Integration, "created_at">,
  created_at: Timestamp,
};

export const fromAPIResult = (integration: APIIntegration): Integration => ({
  ...integration,
  created_at: moment.unix(integration.created_at),
});
