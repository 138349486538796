// @flow
import * as React from "react";
import type { Callback } from "../../../../../types";
import type {
  BooleanFormFieldDef,
  Form,
  NumberListFormFieldDef,
  StringFormFieldDef,
} from "../../../../../hooks/useForm";
import { FormActions } from "../../../../../hooks/useForm";
import type { Portfolio } from "../../../../../models/portfolio.model";
import { getPortfolioName } from "../../../../../models/portfolio.model";
import useIsMobile from "../../../../../hooks/useIsMobile";
import Dialog from "../../../../lib/feedback/Dialog";
import Translate from "../../../../lib/display/Translate";
import { Alert } from "@mui/material";
import HTMLForm from "../../../../lib/inputs/form/HTMLForm";
import Section from "../../../../layout/Section";
import { ResponsiveInlineRowStack } from "../../../../lib/layout/stacks";
import FormTextInput from "../../../../lib/inputs/form/FormTextInput";
import useTranslate from "../../../../../hooks/useTranslate";
import { Body2 } from "../../../../lib/display/Text";
import { PortfolioAdornment } from "../../../../display/Adornments";
import FormSwitchField from "../../../../lib/inputs/form/FormSwitchField";
import FormSwitchGroup from "../../../../lib/inputs/form/FormSwitchGroup";
import { getId } from "../../../../../models/base.model";
import { styled } from "@mui/material/styles";

export type AddEditChildFormDef = {
  first_name: StringFormFieldDef,
  last_name: StringFormFieldDef,
  email: StringFormFieldDef,
  enablePortfolioFilter: BooleanFormFieldDef,
  portfolios: NumberListFormFieldDef,
  password: StringFormFieldDef,
};

type Props = {
  isEdit?: boolean,
  onSubmit: Callback,
  form: Form<AddEditChildFormDef>,
  onClose: Callback,
  open?: boolean,
  portfolios: Portfolio[],
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const AddEditChildDialog: React.ComponentType<Props> = ({
  isEdit,
  onSubmit,
  form,
  onClose,
  open,
  portfolios,
}) => {
  const isMobile = useIsMobile();
  const t = useTranslate();
  return (
    <Dialog
      drawerOnMobile
      name="add-edit-child-account"
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      title={
        <Translate
          id={
            "Page.SettingsSection.ChildrenAccountsTab.AddEditChildDialog.title" +
            (isEdit ? "Edit" : "Add")
          }
        />
      }
    >
      {!isEdit && (
        <StyledAlert severity="info">
          <Translate id="Page.SettingsSection.ChildrenAccountsTab.AddEditChildDialog.description" />
        </StyledAlert>
      )}
      <HTMLForm
        onCancel={isMobile ? undefined : onClose}
        onSubmit={onSubmit}
        submitTextId={isEdit ? "global.save" : "global.create"}
      >
        <Section title="global.general">
          <ResponsiveInlineRowStack>
            <FormTextInput
              label={t("global.firstName")}
              formField={form.state.first_name}
              onChange={FormActions.onChangeField(form.set, "first_name")}
              sx={{ width: { sm: 220 } }}
            />
            <FormTextInput
              label={t("global.lastName")}
              formField={form.state.last_name}
              onChange={FormActions.onChangeField(form.set, "last_name")}
              sx={{ width: { sm: 220 } }}
            />
          </ResponsiveInlineRowStack>
          <FormTextInput
            label={t("global.email")}
            formField={form.state.email}
            onChange={FormActions.onChangeField(form.set, "email")}
            type="email"
            sx={{ width: { sm: 220 } }}
          />
        </Section>
        <Section title="global.permissions">
          <FormSwitchField
            label={t(
              "Page.SettingsSection.ChildrenAccountsTab.AddEditChildDialog.restrictUsage"
            )}
            formField={form.state.enablePortfolioFilter}
            onChange={FormActions.onChangeField(
              form.set,
              "enablePortfolioFilter"
            )}
          >
            <Body2 color="textSecondary">
              <Translate id="Page.SettingsSection.ChildrenAccountsTab.AddEditChildDialog.restrictUsageHelper" />
            </Body2>
            <FormSwitchGroup
              AdornmentComponent={PortfolioAdornment}
              getOptionValue={getId}
              getOptionLabel={getPortfolioName}
              formField={form.state.portfolios}
              onChange={FormActions.onChangeField(form.set, "portfolios")}
              options={portfolios}
            />
          </FormSwitchField>
        </Section>
      </HTMLForm>
    </Dialog>
  );
};

export default AddEditChildDialog;
