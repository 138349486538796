// @flow
import * as React from "react";
import type { TargetSpecs } from "./types";
import { PostCreatorCollapsibleSection } from "../../../layout/PostCreatorSection";
import SwitchGroup from "../../../lib/inputs/SwitchGroup";
import type { ModelID } from "../../../../types";
import { getAccountChannel } from "../../../../models/account.model";
import { TargetSpecAdornment } from "../../../display/Adornments";
import usePostDiagnosticAnyChannel from "../../../../hooks/postCreator/usePostDiagnosticAnyChannel";
import { getDiagnosticErrorDescriptions } from "../../../../util/diagnostics.util";
import useTranslate from "../../../../hooks/useTranslate";
import { Body2 } from "../../../lib/display/Text";

export type Props = {
  targets: TargetSpecs[],
  selected: ModelID[],
  onSetSecondaryAccountIds: (ModelID[]) => any,
};

const getOptionValue = (target: TargetSpecs): ModelID => target.account.id;
const getOptionLabel = (target: TargetSpecs): string =>
  target.account.entity_name;

const SecondaryTargetsWidget: React.ComponentType<Props> = React.forwardRef(
  ({ targets, selected, onSetSecondaryAccountIds }, ref) => {
    const t = useTranslate();
    const allChannelsErrors = usePostDiagnosticAnyChannel();
    const disableSecondaryAccountIf = React.useCallback(
      (target: TargetSpecs): boolean | string => {
        const account = target.account;
        if (!(account.channel in allChannelsErrors)) {
          return false;
        }
        const errors = getDiagnosticErrorDescriptions(
          allChannelsErrors[account.channel]
        );
        return (
          errors.length > 0 &&
          t(errors[0].msg, errors[0].data(getAccountChannel(account)))
        );
      },
      [t, allChannelsErrors]
    );

    return (
      <PostCreatorCollapsibleSection
        title="SelectPostTargetWidget.secondaryAccounts"
        defaultCollapsed={selected.length === 0}
        slotProps={{
          head: {
            children: selected.length > 0 && (
              <Body2>
                {t("global.countSelected", { count: selected.length })}
              </Body2>
            ),
          },
        }}
        ref={ref}
      >
        <SwitchGroup
          value={selected}
          onChange={onSetSecondaryAccountIds}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          AdornmentComponent={TargetSpecAdornment}
          options={targets}
          disableIf={disableSecondaryAccountIf}
        />
      </PostCreatorCollapsibleSection>
    );
  }
);

export default SecondaryTargetsWidget;
