// @flow
import type { Post } from "../models/post.model";
import type { Portfolio } from "../models/portfolio.model";
import type { BobcaatAccount } from "../models/account.model";
import { getTime } from "../models/post.model";
import { byId } from "../models/base.model";
import { findOne } from "../lib/lodashex.lib";
import moment from "moment";
import { localTimezone } from "../models/timezone.model";
import type { Moment } from "../types";
import { toMomentUnit } from "../models/periodicity.model";

export type CalendarView = "month" | "week";
export type RestrictedCalendarPost = {
  ...Post,
  start: ?Date,
  end: ?Date,
};
export type CalendarPost = {
  ...RestrictedCalendarPost,
  account: BobcaatAccount,
  portfolio: Portfolio,
  repeat: boolean,
};

function* getScheduleOrRepeatTimesWithin(
  post: Post,
  start: Moment,
  end: Moment
) {
  let time = getTime(post);
  if (!time) return;
  if (time.isBetween(start, end)) yield time;
  // Only scheduled / draft posts should have a periodicity anyway.
  // But just in case ignore published one.
  if (post.status === "published") return;

  const periodicity = post.periodicity;
  if (!periodicity) return;

  const unit = toMomentUnit(periodicity.unit);

  while (time && time.isBefore(end)) {
    time = time.clone().add(periodicity.quantity, unit);
    if (time.isBetween(start, end)) yield time;
  }
}

const makeEnrichOnePost =
  (
    portfolio: Portfolio,
    accounts: BobcaatAccount[],
    timezoneOffset: number,
    start: Moment,
    end: Moment,
    draggedPost: ?CalendarPost = null,
    view: ?CalendarView = "month"
  ) =>
  (post: Post): CalendarPost[] => {
    const account = findOne(accounts, byId<BobcaatAccount>(post.account_id));

    return [...getScheduleOrRepeatTimesWithin(post, start, end)].map(
      (time, idx) => ({
        ...post,
        start: time.add(timezoneOffset, "h").toDate(),
        end: time
          .clone()
          ?.add(timezoneOffset, "h")
          .add(view === "month" ? 1 : 120, "m")
          .toDate(),
        schedule_time:
          draggedPost && post.id === draggedPost.id
            ? moment(draggedPost.start)
            : time,
        account: account,
        portfolio: portfolio,
        // Repeat posts should be shown differently,
        // and you can't drag them.
        repeat: idx > 0,
      })
    );
  };

export const enrichPostsForMainCalendar = (
  posts: Post[],
  portfolio: Portfolio,
  accounts: BobcaatAccount[],
  timezoneOffset: number,
  rangeStart: Moment,
  rangeEnd: Moment,
  draggedPost: ?CalendarPost = null,
  view: ?CalendarView = "month"
): CalendarPost[] => {
  const initialTimezone = localTimezone();
  const enrichOnePost = makeEnrichOnePost(
    portfolio,
    accounts,
    timezoneOffset - initialTimezone.offset,
    rangeStart,
    rangeEnd,
    draggedPost,
    view
  );
  return posts.flatMap(enrichOnePost);
};

/**
 * get the targeted beginning of month for the anchor, in month view.
 */
export const getAnchorStartOfTargetMonth = (anchor: Moment): Moment =>
  anchor.date() === 1
    ? moment(anchor)
    : moment(anchor).add(1, "week").startOf("month");
