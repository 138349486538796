// @flow
import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select as SelectImpl,
} from "@mui/material";
import type { Props as SelectProps } from "../../../stubs/mui/Select";
import useTranslate from "../../../hooks/useTranslate";
import { Body1 } from "../display/Text";
import { RowStack } from "../layout/stacks";
import useIsMobile from "../../../hooks/useIsMobile";
import head from "lodash/head";
import { cast } from "../../../types";

interface AdornmentComponentProps<T> {
  value: ?T;
}

type BaseProps<T> = {
  ...Omit<SelectProps<T>, "children" | "onChange" | "multiple">,
  id: string,
  options: $ReadOnlyArray<T>,
  getOptionLabel?: (T) => string,
  getOptionValue?: (T) => string,
  translateLabel?: boolean,
  adornOptions?: boolean,
  AdornmentComponent?: React.ComponentType<AdornmentComponentProps<T>>,
  width?: number | string | Object,
  size?: "small" | "medium",
  disableUnderline?: boolean,
};

type SingleSelectProps<T> = {
  ...BaseProps<T>,
  onChange: (T) => any,
  value?: T | string,
  renderValue?: (?T) => string,
  multiple?: false,
};

type MultipleSelectProps<T> = {
  ...BaseProps<T>,
  onChange: (T[]) => any,
  renderValue?: (T[]) => string,
  value?: T[] | string,
  multiple: true,
};

export type Props<T> = SingleSelectProps<T> | MultipleSelectProps<T>;

const EMPTY_NATIVE_OPTION = <option value="" style={{ display: "none" }} />;

const Select = <T: number | string | Object>({
  getOptionLabel: userGetOptionLabel,
  getOptionValue: userGetOptionValue,
  value,
  options,
  multiple,
  onChange,
  native,
  label,
  id,
  width,
  translateLabel,
  size,
  error,
  helperText,
  AdornmentComponent,
  adornOptions,
  variant = "filled",
  disableUnderline = true,
  ...props
}: Props<T>): React.Node => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  native = native || (isMobile && !multiple);
  const Item = native ? "option" : MenuItem;

  const getOptionValue = React.useCallback(
    (value: ?(T | T[] | string)) => {
      if (!native || !value || typeof value === "string") return value;
      if (!userGetOptionValue) return value.toString();
      return Array.isArray(value)
        ? value.map(userGetOptionValue)
        : userGetOptionValue(value);
    },
    [userGetOptionValue, native]
  );

  const getOptionLabel = React.useCallback(
    (value: ?T) => {
      if (value == null) return value;
      return userGetOptionLabel ? userGetOptionLabel(value) : value.toString();
    },
    [userGetOptionLabel]
  );

  const getOptionLabelT = React.useMemo(
    () =>
      translateLabel
        ? (opt: T) => t(getOptionLabel(opt))
        : (opt: T) => getOptionLabel(opt),
    [translateLabel, t, getOptionLabel]
  );

  const Adornment = React.useCallback(
    ({ value }: { value: ?T }) =>
      AdornmentComponent ? (
        <InputAdornment position="end">
          <AdornmentComponent value={value} />
        </InputAdornment>
      ) : undefined,
    [AdornmentComponent]
  );

  const renderOption = React.useCallback(
    ({ selected }: Object, opt: T) => (
      <RowStack
        spacing={adornOptions && !multiple ? 0.5 : 0}
        alignItems="center"
      >
        {!multiple && adornOptions && AdornmentComponent && (
          <Adornment value={opt} />
        )}
        {multiple && <Checkbox checked={selected} size="small" />}
        <Body1>{getOptionLabelT(opt)}</Body1>
      </RowStack>
    ),
    [multiple, getOptionLabelT, adornOptions, AdornmentComponent]
  );

  const handleChange = React.useCallback(
    (e: SyntheticEvent<HTMLSelectElement>) => {
      if (!native) onChange((e.target: any).value);
      else {
        const { options: nativeOptions } = e.currentTarget;
        const selectedValues = Array.from(nativeOptions)
          .filter((opt) => opt.selected)
          .map(({ value }) => value);
        const selectedOptions = options.filter((opt) =>
          selectedValues.includes(getOptionValue(opt))
        );
        if (multiple) {
          cast<(T[]) => any>(onChange)(selectedOptions);
        } else {
          onChange(head(selectedOptions));
        }
      }
    },
    [options, getOptionValue, onChange, native, multiple]
  );

  return (
    <FormControl sx={{ width }} size={size} error={error} variant={variant}>
      {label && <InputLabel id={`label-${id}`}>{label}</InputLabel>}
      <SelectImpl
        labelId={`label-${id}`}
        label={label}
        multiple={multiple}
        native={native}
        value={getOptionValue(value)}
        onChange={handleChange}
        error={error}
        id={id}
        disableUnderline={disableUnderline}
        sx={{
          ...props.sx,
          // Disable pointer events on mobile when readonly
          // because native select do not support readonly attribute.
          pointerEvents: {
            xs: props.readOnly ? "none" : undefined,
            sm: "initial",
          },
        }}
        {...props}
      >
        {native && EMPTY_NATIVE_OPTION}
        {options.map((opt) => {
          const optValue = getOptionValue(opt);
          return (
            <Item
              key={getOptionLabel(opt)}
              value={optValue}
              dense={native ? undefined : true}
            >
              {native
                ? getOptionLabelT(opt)
                : renderOption(
                    {
                      selected:
                        Array.isArray(value) && value.includes(optValue),
                    },
                    opt
                  )}
            </Item>
          );
        })}
      </SelectImpl>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
