// @flow
import * as React from "react";
import { Grid as GiphyGrid } from "@giphy/react-components";
import giphyLogo from "../../../../assets/images/third-parties/giphy.png";
import { styled } from "@mui/material/styles";
import type { Callback, URLStr } from "../../../../types";
import useIsMobile from "../../../../hooks/useIsMobile";
import Dialog from "../../feedback/Dialog";
import useTranslate from "../../../../hooks/useTranslate";
import { ButtonGroup, FormControl, FormHelperText } from "@mui/material";
import { NeutralButton } from "../buttons";
import DebouncedSearchInput from "../DebouncedSearchInput";
import { ColumnStack } from "../../layout/stacks";

export type SearchType = "gifs" | "stickers" | "text";
export type GIF = {
  images: {
    fixed_height: {
      mp4: URLStr,
      url: URLStr,
    },
  },
  ...
};

export type Props = {
  open?: boolean,
  onClose: Callback,
  onGifClick: (GIF, e: SyntheticEvent<>) => any,
  fetchGifsHandler: (offset: number) => any[],
  textValue: string,
  onInputChange: (string) => any,
  searchTypes: SearchType[],
  selectedSearchType: SearchType,
  onChangeSearchType: (SearchType) => any,
};

const GiphyLogo = styled("img")(({ theme }) => ({
  width: 100,
  display: "block",
  margin: "auto",
  marginBottom: theme.spacing(1),
}));

const TypesButtonGroup = styled("div")({
  justifyContent: "center",
  display: "flex",
});

const PickGifDialog: React.ComponentType<Props> = ({
  open,
  onClose,
  onGifClick,
  fetchGifsHandler,
  textValue,
  onInputChange,
  searchTypes,
  selectedSearchType,
  onChangeSearchType,
}) => {
  const isMobile = useIsMobile();
  const width = isMobile ? 263 : 552;
  const t = useTranslate();
  return (
    <Dialog
      title={t("PickGifDialog.title")}
      name="select-gif"
      open={open}
      onClose={onClose}
      maxWidth="sm"
      drawerOnMobile
    >
      <ColumnStack>
        <div>
          <GiphyLogo
            src={giphyLogo}
            width={100}
            alt="Powered by GIPHY"
            title="Powered by GIPHY"
          />
        </div>
        <TypesButtonGroup>
          <ButtonGroup>
            {searchTypes.map((searchType) => (
              <NeutralButton
                color={
                  searchType === selectedSearchType ? "primary" : "inherit"
                }
                onClick={() => onChangeSearchType(searchType)}
                key={searchType}
              >
                {searchType}
              </NeutralButton>
            ))}
          </ButtonGroup>
        </TypesButtonGroup>
        <FormControl>
          <DebouncedSearchInput onChange={onInputChange} autoFocus />
          <FormHelperText>{t("PickGifDialog.searchHelper")}</FormHelperText>
        </FormControl>
        <div style={{ minHeight: 800 }}>
          <GiphyGrid
            onGifClick={onGifClick}
            fetchGifs={fetchGifsHandler}
            key={textValue + "__" + selectedSearchType}
            width={width}
            columns={3}
            gutter={6}
          />
        </div>
      </ColumnStack>
    </Dialog>
  );
};

export default PickGifDialog;
