// @flow
import * as React from "react";
import type { I18nKey, Slug } from "../../../types";
import type { Severity } from "../../../models/alerts.model";
import useTranslate from "../../../hooks/useTranslate";
import { ColumnStack } from "../../lib/layout/stacks";
import { Alert, AlertTitle } from "@mui/material";
import { Moment } from "../../../types";
import Translate from "../../lib/display/Translate";

export type NewsAlert = {
  slug: Slug,
  severity?: Severity,
  title?: I18nKey,
  body: I18nKey,
  bodyComponents?: Object,
  expires: Moment,
};

type Props = {
  news: NewsAlert[],
  onHideNews: (NewsAlert) => any,
};

const News: React.ComponentType<Props> = ({ news, onHideNews }) => {
  const t = useTranslate();
  return (
    <ColumnStack>
      {news.map((n) => (
        <Alert
          key={n.slug}
          severity={n.severity ?? "info"}
          onClose={() => onHideNews(n)}
        >
          {n.title && <AlertTitle>{t(n.title)}</AlertTitle>}
          <Translate t={t} id={n.body} components={n.bodyComponents} />
        </Alert>
      ))}
    </ColumnStack>
  );
};

export default News;
