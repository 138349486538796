// @flow
/* Pretty interval constants */

const interval = {
  second: {
    as: {
      milliseconds: 1000,
    },
  },
  minute: {
    as: {
      seconds: 60,
      milliseconds: 60 * 1000,
    },
  },
  hour: {
    as: {
      minutes: 60,
      seconds: 60 * 60,
      milliseconds: 60 * 60 * 1000,
    },
  },
  day: {
    as: {
      hours: 24,
      minutes: 24 * 60,
      seconds: 24 * 60 * 60,
      milliseconds: 24 * 60 * 60 * 1000,
    },
  },
};

export default interval;
