// @flow
import * as React from "react";
import * as PaymentIcons from "react-payment-logos/dist/flat";
import capitalize from "lodash/capitalize";
import { InlineRowStack } from "../layout/stacks";
import { Body2 } from "./Text";
import { styled } from "@mui/material/styles";
import type { CardDetails } from "../../../models/subscription.model";
import Translate from "./Translate";
import { mapKeys } from "lodash/object";

const PaymentIconsMap = mapKeys(PaymentIcons, (value, key: string) =>
  key.toLowerCase()
);

const PaymentIcon = ({ id, ...props }: { id: string }) => {
  const Icon = PaymentIconsMap[id];
  return Icon ? <Icon {...props} /> : null;
};

const Card = styled(PaymentIcon)`
  width: 60px;
  border-radius: 6px;
`;

type Props = {
  card: CardDetails,
};

const PaymentCard: React.ComponentType<Props> = ({ card }) => (
  <InlineRowStack alignItems="center" spacing={2}>
    <Card id={card.brand} />
    <div>
      <Body2 fontWeight="bold">{capitalize(card.brand)}</Body2>
      <Body2 color="textSecondary">•••• •••• •••• {card.last4}</Body2>
    </div>
    <div>
      <Body2 fontWeight="bold">
        <Translate id="global.expires" />
      </Body2>
      <Body2 color="textSecondary">
        {card.exp_month}/{card.exp_year}
      </Body2>
    </div>
  </InlineRowStack>
);

export default PaymentCard;
