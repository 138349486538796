// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { IconButton, SwipeableDrawer } from "@mui/material";
import type { Callback, Pixels } from "../../../types";
import type { Children } from "../../../reactTypes";
import useSwitch from "../../../hooks/useSwitch";
import { CloseRounded } from "@mui/icons-material";

const DRAWER_BLEEDING = 58;
const PULLER_HEIGHT = 8 + 6;

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const PullableEdge = styled("div", {
  shouldForwardProp: (prop) => !["edgeWidth", "color"].includes(prop),
})(({ theme, color, edgeWidth }) => ({
  boxShadow: "2px -4px 6px rgba(0,0,0,0.3)",
  backgroundColor: theme.palette.background[color],
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  position: "absolute",
  top: -edgeWidth,
  height: edgeWidth + 400, // +400 to protect against bouncing effects.
  visibility: "visible",
  right: 0,
  left: 0,
}));

const Content = styled("div", {
  shouldForwardProp: (prop) => !["edgeWidth", "contentInEdge"].includes(prop),
})(({ edgeWidth, contentInEdge }) => ({
  position: "relative",
  // don't let an expanded drawer touch the top.
  maxHeight: `calc(100vh - ${edgeWidth}px - 20px)`,
  marginTop: contentInEdge ? PULLER_HEIGHT - edgeWidth + 8 : 0,
  overflowY: "auto",
}));

type Props = {
  color?: "paper" | "default" | "alt",
  onClose: Callback,
  onOpen: Callback,
  open: boolean,
  children?: Children,
  edgeWidth?: Pixels,
  contentInEdge?: boolean,
  hideBackdrop?: boolean,
};

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& div": {
    visibility: "visible",
  },
}));

const PAPER_PROPS = { sx: { overflow: "visible" } };

const SwipeableEdgeDrawer: React.ComponentType<Props> = ({
  color,
  onClose,
  onOpen,
  open,
  children,
  edgeWidth = DRAWER_BLEEDING,
  contentInEdge,
  hideBackdrop,
}) => (
  <StyledSwipeableDrawer
    PaperProps={PAPER_PROPS}
    anchor="bottom"
    open={open}
    onClose={onClose}
    onOpen={onOpen}
    swipeAreaWidth={edgeWidth}
    hideBackdrop={hideBackdrop}
    disableSwipeToOpen={false}
    ModalProps={{ keepMounted: true }}
  >
    <PullableEdge color={color} edgeWidth={edgeWidth}>
      <Puller />
    </PullableEdge>
    <Content edgeWidth={edgeWidth} contentInEdge={contentInEdge}>
      {children}
    </Content>
  </StyledSwipeableDrawer>
);

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1.8),
  top: theme.spacing(1.2),
  zIndex: 2,
}));

export const UncontrolledSwipeableEdgeDrawer: React.ComponentType<{
  ...Omit<Props, "onClose" | "onOpen" | "open">,
  onClose?: ?Callback,
}> = ({ children, onClose, ...props }) => {
  const [expanded, expand, collapse] = useSwitch(false);

  // Open the drawer when mounted.
  React.useEffect(() => {
    setTimeout(expand, 50);
  }, [expand]);

  // Animate the exit.
  const handleClose = React.useCallback(() => {
    collapse();
    if (onClose) {
      setTimeout(onClose, 200);
    }
  }, [collapse, onClose]);

  return (
    <SwipeableEdgeDrawer
      onClose={handleClose}
      onOpen={expand}
      open={expanded}
      {...props}
    >
      {onClose && (
        <CloseIconButton onClick={handleClose}>
          <CloseRounded />
        </CloseIconButton>
      )}
      {children}
    </SwipeableEdgeDrawer>
  );
};

export default SwipeableEdgeDrawer;
