// @flow
import * as React from "react";
import type { EngagementRoute } from "./EngagementHubAccounts";
import EngagementHubAccounts from "./EngagementHubAccounts";
import {
  useAccountsLocked,
  usePortfolioAccounts,
} from "../../../store/selectors";
import { useScopedPortfolio } from "../../../scopes/scopes";
import type { BobcaatAccount } from "../../../models/account.model";
import { getAccountChannel } from "../../../models/account.model";
import routes, { isAscendingRouteOf } from "../../../config/routes.config";
import { useParams } from "react-router-dom";
import { parseModelId } from "../../../models/base.model";
import useCurrentRoute from "../../../hooks/useCurrentRoute";
import Translate from "../../lib/display/Translate";
import { Alert } from "@mui/material";

const useEngagementRoutes = (): EngagementRoute[] => {
  const portfolio = useScopedPortfolio();
  const accounts = usePortfolioAccounts(portfolio?.id);

  return React.useMemo(
    () =>
      accounts.reduce((result: EngagementRoute[], account: BobcaatAccount) => {
        const feats = getAccountChannel(account).features;
        if (feats?.commentManagement) {
          result.push({
            feature: "comments",
            account,
            route:
              routes.app.nested.portfolios.nested.engagement.nested.account
                .nested.posts,
            key: `${account.id}:p`,
          });
        }
        if (feats?.messaging) {
          result.push({
            feature: "messages",
            account,
            route:
              routes.app.nested.portfolios.nested.engagement.nested.account
                .nested.conversations,
            key: `${account.id}:c`,
          });
        }
        return result;
      }, []),
    [accounts]
  );
};

const useCurrentEngagementRoute = (
  engagementRoutes: EngagementRoute[]
): ?EngagementRoute => {
  const { accountId: accountIdStr } = useParams();
  const accountId = parseModelId(accountIdStr);
  const currentRoute = useCurrentRoute();

  return React.useMemo(
    () =>
      engagementRoutes.find(
        (route) =>
          route.account.id === accountId &&
          isAscendingRouteOf(route.route, currentRoute)
      ),
    [engagementRoutes, accountId, currentRoute]
  );
};

const EngagementHubAccountsContainer: React.ComponentType<{}> = () => {
  const engagementRoutes = useEngagementRoutes();
  const accountsLocked = useAccountsLocked();
  const currentRoute = useCurrentEngagementRoute(engagementRoutes);

  if (!accountsLocked && engagementRoutes.length === 0) {
    return (
      <div>
        <Alert severity="warning">
          <Translate id="global.noEngagementRoutes" />
        </Alert>
      </div>
    );
  }

  return (
    <>
      <EngagementHubAccounts
        engagementRoutes={engagementRoutes}
        loading={accountsLocked}
        selectedRoute={currentRoute}
      />
      {!currentRoute && (
        <div>
          <Alert severity="info">
            <Translate id="EngagementHubSection.inviteSelectEngagementItem" />
          </Alert>
        </div>
      )}
    </>
  );
};

export default EngagementHubAccountsContainer;
