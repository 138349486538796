// @flow
import * as React from "react";
import Notifications from "./Notifications";
import { useStoreActions } from "../../../store/store";
import * as notificationService from "../../../services/notification.service";
import { useNotifications } from "../../../store/selectors";
import useNotificationRedirect from "./useNotificationRedirect";
import type { Notification } from "../../../models/notification.model";

const NotificationsWidgetContainer: React.ComponentType<{}> = () => {
  const notifications = useNotifications();
  const onNotificationRedirect = useNotificationRedirect();
  const actions = useStoreActions();

  const handleRedirect = (notif: Notification) => {
    if (!notif.read) {
      notificationService.markNotificationAsRead(actions)(notif.id);
    }
    onNotificationRedirect(notif);
  };

  return (
    <Notifications
      notifications={notifications}
      onMarkAllAsRead={notificationService.markAllNotificationsAsRead(actions)}
      onDeleteAll={notificationService.deleteAllNotifications(actions)}
      onClick={handleRedirect}
      onDelete={notificationService.deleteNotification(actions)}
    />
  );
};

export default NotificationsWidgetContainer;
