// @flow
import ajax from "../../lib/ajax.lib";

import { downloadData } from "../../lib/files.lib";
import { fileToImageDataURL } from "../../lib/images.lib";
import type { Portfolio } from "../../models/portfolio.model";
import type { ModelID, StrColor, URLStr } from "../../types";
import type { APINoContentResult, APIResult } from "./types";
import type {
  APIPortfolioSummary,
  PortfolioSummary,
} from "../../models/portfolioSummary.model";
import { fromAPIResult } from "../../models/portfolioSummary.model";

type DownloadPortfolioIcalParams = Pick<Portfolio, "id" | "name">;
type AvatarBase = { avatar: ?URLStr | ?File };

type CreatePortfolioParams = {
  ...AvatarBase,
  name: string,
  description: ?string,
  timezone: ?ModelID,
  color: ?StrColor,
};

type EditPortfolioParams = {
  ...AvatarBase,
  ...Omit<Portfolio, "public_hash" | "avatar">,
};

type ProcessedPortfolioParams<T> = {
  ...Omit<T, "avatar">,
  avatar: ?URLStr,
};

export const downloadPortfolioIcal = ({
  id,
  name,
}: DownloadPortfolioIcalParams): Promise<void> =>
  ajax
    .get<ArrayBuffer>(`portfolio/${id}/ical`)
    .then((response) =>
      downloadData(
        new Blob([response.data], { type: "text/calendar" }),
        `${name}-portfolio.ics`
      )
    );

const preparePortfolio = async <T: Object>(
  portfolio: T
): Promise<ProcessedPortfolioParams<T>> => {
  return !portfolio.avatar || typeof portfolio.avatar === "string"
    ? { ...portfolio, avatar: portfolio.avatar }
    : { ...portfolio, avatar: await fileToImageDataURL(portfolio.avatar) };
};

export const addPortfolio = (
  params: CreatePortfolioParams
): Promise<Portfolio> =>
  preparePortfolio(params)
    .then((pf) =>
      ajax.post<
        ProcessedPortfolioParams<CreatePortfolioParams>,
        APIResult<Portfolio>
      >("/portfolio", pf)
    )
    .then((response) => response.data.data);

export const editPortfolio = ({
  id,
  ...portfolio
}: EditPortfolioParams): Promise<Portfolio> =>
  preparePortfolio(portfolio)
    .then((pf) =>
      ajax.put<
        ProcessedPortfolioParams<Omit<EditPortfolioParams, "id">>,
        APIResult<Portfolio>
      >(`/portfolio/${id}`, pf)
    )
    .then((response) => response.data.data);

export const deletePortfolio = (
  portfolioId: ModelID
): Promise<APINoContentResult> =>
  ajax.delete<APINoContentResult>(`/portfolio/${portfolioId}`);

export const getPortfolios = (): Promise<Portfolio[]> =>
  ajax
    .get<APIResult<Portfolio[]>>("/portfolio")
    .then((response) => response.data.data);

export const getPortfolioSummaries = (): Promise<PortfolioSummary[]> =>
  ajax
    .get<APIResult<APIPortfolioSummary[]>>("/portfolio/summaries")
    .then((response) => response.data.data.map(fromAPIResult));
