// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Body2 } from "../../../display/Text";
import Translate from "../../../display/Translate";

type Props = {
  offset: number,
  cellContents: number[],
  unit: Object,
  slideyRef: Object,
  containerRef: Object,
  cellHeight: number,
  onTouchMove: (SyntheticTouchEvent<>) => any,
  onTouchStart: (SyntheticTouchEvent<>) => any,
  onTouchEnd: (SyntheticTouchEvent<>) => any,
  onMouseDown: (SyntheticMouseEvent<>) => any,
  onFocus: (SyntheticEvent<>) => any,
  onBlur: (SyntheticEvent<>) => any,
  value: number,
};

const Root = styled("div")({
  flexGrow: 1,
  display: "inline-block",
  touchAction: "none",
  // background:
  //   "linear-gradient(rgba(0, 0, 0, 0.1), 1%, transparent, 99%, rgba(0, 0, 0, 0.1))",
});

const MaskedDiv = styled("div")({
  overflow: "hidden",
  height: "100%",
  position: "relative",
  maskImage:
    "linear-gradient(transparent, 15%, rgba(0, 0, 0, 1), 85%, transparent)",
});

const Column = styled("div")({
  position: "absolute",
  pointerEvents: "none",
  touchAction: "none",
  zIndex: 0,
  width: "100%",
});

const TextOverlay = styled("div", {
  shouldForwardProp: (prop) => prop !== "cellHeight",
})(({ theme, cellHeight }) => ({
  position: "absolute",
  display: "flex",
  backgroundColor: theme.palette.divider,
  alignItems: "center",
  justifyContent: "center",
  height: cellHeight,
  top: cellHeight,
  paddingLeft: theme.spacing(8),
  width: "100%",
  pointerEvents: "none",
}));

const Cell = styled("div", {
  shouldForwardProp: (prop) => prop !== "cellHeight",
})(({ cellHeight }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: cellHeight,
}));

const format = (value: number) => value.toString().padStart(2, "0");

const DurationPickerColumn: React.ComponentType<Props> = ({
  offset,
  cellContents,
  unit,
  slideyRef,
  containerRef,
  cellHeight,
  onTouchMove,
  onTouchStart,
  onTouchEnd,
  onMouseDown,
  onFocus,
  onBlur,
  value,
}) => {
  return (
    <Root
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onFocus={onFocus}
      onBlur={onBlur}
      role="slider"
      aria-valuemax={unit.max}
      aria-valuemin={0}
      aria-valuenow={value}
      ref={containerRef}
      tabIndex={0}
    >
      <MaskedDiv>
        <TextOverlay cellHeight={cellHeight}>
          <Body2 color="textSecondary">
            <Translate id={unit.label} />
          </Body2>
        </TextOverlay>
        <Column style={{ top: offset || 0 }} ref={slideyRef}>
          {cellContents.map((val) => (
            <Cell cellHeight={cellHeight} key={val}>
              {format(val)}
            </Cell>
          ))}
        </Column>
      </MaskedDiv>
    </Root>
  );
};

export default DurationPickerColumn;
