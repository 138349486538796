// @flow

import type { I18nKey } from "../types";
import type { EntityMetric, PostMetric } from "../models/analytics.model";
import keys from "lodash/keys";

export type MetricInfo = {
  name: I18nKey,
  unit: I18nKey,
};

export const POST_METRICS: Record<PostMetric, MetricInfo> = {
  reach: {
    name: "refer.postMetrics.reach.name",
    unit: ",efer.postMetrics.reach.unit",
  },
  comments: {
    name: "refer.postMetrics.comments.name",
    unit: "refer.postMetrics.comments.unit",
  },
  reactions: {
    name: "refer.postMetrics.reactions.name",
    unit: "refer.postMetrics.reactions.unit",
  },
  shares: {
    name: "refer.postMetrics.shares.name",
    unit: "refer.postMetrics.shares.unit",
  },
  video_views: {
    name: "refer.postMetrics.video_views.name",
    unit: "refer.postMetrics.video_views.unit",
  },
  impressions: {
    name: "refer.postMetrics.impressions.name",
    unit: "refer.postMetrics.impressions.unit",
  },
  engagements: {
    name: "refer.postMetrics.engagements.name",
    unit: "refer.postMetrics.engagements.unit",
  },
  link_clicks: {
    name: "refer.postMetrics.link_clicks.name",
    unit: "refer.postMetrics.link_clicks.unit",
  },
  count: {
    name: "refer.postMetrics.count.name",
    unit: "refer.postMetrics.count.unit",
  },
  taps_forward: {
    name: "refer.postMetrics.taps_forward.name",
    unit: "refer.postMetrics.taps_forward.unit",
  },
  taps_back: {
    name: "refer.postMetrics.taps_back.name",
    unit: "refer.postMetrics.taps_back.unit",
  },
  exits: {
    name: "refer.postMetrics.exits.name",
    unit: "refer.postMetrics.exits.unit",
  },
  saved: {
    name: "refer.postMetrics.saved.name",
    unit: "refer.postMetrics.saved.unit",
  },
};

export const PAGE_METRICS: Record<EntityMetric, MetricInfo> = {
  reach: {
    name: "refer.pageMetrics.reach.name",
    unit: ",efer.pageMetrics.reach.unit",
  },
  followers: {
    name: "refer.pageMetrics.followers.name",
    unit: "refer.pageMetrics.followers.unit",
  },
  impressions: {
    name: "refer.pageMetrics.impressions.name",
    unit: "refer.pageMetrics.impressions.unit",
  },
  engagements: {
    name: "refer.pageMetrics.engagements.name",
    unit: "refer.pageMetrics.engagements.unit",
  },
  new_followers: {
    name: "refer.pageMetrics.new_followers.name",
    unit: "refer.pageMetrics.new_followers.unit",
  },
  lost_followers: {
    name: "refer.pageMetrics.lost_followers.name",
    unit: "refer.pageMetrics.lost_followers.unit",
  },
  all_time_post_count: {
    name: "refer.pageMetrics.all_time_post_count.name",
    unit: "refer.pageMetrics.all_time_post_count.unit",
  },
  followed_count: {
    name: "refer.pageMetrics.followed_count.name",
    unit: "refer.pageMetrics.followed_count.unit",
  },
  listed_count: {
    name: "refer.pageMetrics.listed_count.name",
    unit: "refer.pageMetrics.listed_count.unit",
  },
  text_message_clicks: {
    name: "refer.pageMetrics.text_message_clicks.name",
    unit: "refer.pageMetrics.text_message_clicks.unit",
  },
  website_clicks: {
    name: "refer.pageMetrics.website_clicks.name",
    unit: "refer.pageMetrics.website_clicks.unit",
  },
  get_directions_clicks: {
    name: "refer.pageMetrics.get_directions_clicks.name",
    unit: "refer.pageMetrics.get_directions_clicks.unit",
  },
  phone_call_clicks: {
    name: "refer.pageMetrics.phone_call_clicks.name",
    unit: "refer.pageMetrics.phone_call_clicks.unit",
  },
  email_contacts: {
    name: "refer.pageMetrics.email_contacts.name",
    unit: "refer.pageMetrics.email_contacts.unit",
  },
  pin_clicks: {
    name: "refer.pageMetrics.pin_clicks.name",
    unit: "refer.pageMetrics.pin_clicks.unit",
  },
  pin_saves: {
    name: "refer.pageMetrics.pin_saves.name",
    unit: "refer.pageMetrics.pin_saves.unit",
  },
  pin_outbound_clicks: {
    name: "refer.pageMetrics.pin_outbound_clicks.name",
    unit: "refer.pageMetrics.pin_outbound_clicks.unit",
  },

  queries_direct: {
    name: "refer.pageMetrics.queries_direct.name",
    unit: "refer.pageMetrics.queries_direct.unit",
  },

  queries_indirect: {
    name: "refer.pageMetrics.queries_indirect.name",
    unit: "refer.pageMetrics.queries_indirect.unit",
  },

  queries_chain: {
    name: "refer.pageMetrics.queries_chain.name",
    unit: "refer.pageMetrics.queries_chain.unit",
  },

  view_maps: {
    name: "refer.pageMetrics.view_maps.name",
    unit: "refer.pageMetrics.view_maps.unit",
  },

  view_search: {
    name: "refer.pageMetrics.view_search.name",
    unit: "refer.pageMetrics.view_search.unit",
  },
};

export const PAGE_METRICS_KEYS: EntityMetric[] = keys(PAGE_METRICS);

type PageMetricOption = {
  value: EntityMetric,
  label: I18nKey,
};

export const PAGE_METRICS_OPTIONS: PageMetricOption[] = PAGE_METRICS_KEYS.map(
  (key: EntityMetric) => ({ value: key, label: PAGE_METRICS[key].name })
);

export const getPageMetricOptionLabel = (opt: PageMetricOption): string =>
  opt.label;

export const getPageMetricOptionValue = (opt: PageMetricOption): EntityMetric =>
  opt.value;
