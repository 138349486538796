// @flow
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import Comment from "./Comment";
import type { PostComment } from "../../../../../models/postComment.model";
import Translate from "../../../../lib/display/Translate";
import { Alert } from "@mui/material";
import { ColumnStack } from "../../../../lib/layout/stacks";
import { styled } from "@mui/material/styles";

type Props = {
  comments: PostComment[],
};

const Root = styled(ColumnStack)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: 400,
  [theme.breakpoints.down("sm")]: {
    minWidth: "auto",
  },
}));

const CommentsPreview: React.ComponentType<Props> = ({ comments }) =>
  isEmpty(comments) ? (
    <Alert severity="info">
      <Translate id="EngagementHubSection.CommentsPreview.noCommentsYet" />
    </Alert>
  ) : (
    <Root>
      {comments.map((cmt) => (
        <Comment comment={cmt} key={cmt.id} />
      ))}
    </Root>
  );

export default CommentsPreview;
