// @flow
import * as React from "react";
import type { Callback } from "../../../types";
import Dialog from "../../lib/feedback/Dialog";
import { LargePrimaryButton } from "../../lib/inputs/buttons";
import Translate from "../../lib/display/Translate";
import { DarkModeRounded } from "@mui/icons-material";
import useTranslate from "../../../hooks/useTranslate";
import { ColumnStack } from "../../lib/layout/stacks";
import { styled } from "@mui/material/styles";

type Props = {
  open?: boolean,
  onUnfreeze: Callback,
  locked?: boolean,
};

const Root = styled(ColumnStack)(({ theme }) => ({
  alignItems: "center",
  "& svg": {
    fontSize: 96,
  },
}));

const FrozenSubscriptionDialog: React.ComponentType<Props> = ({
  open,
  onUnfreeze,
  locked,
}) => {
  const t = useTranslate();
  return (
    <Dialog
      open={open}
      name="unfreeze-subscription-dialog"
      title={t("Page.FrozenSubscriptionDialog.accountFrozen.title")}
      text={t("Page.FrozenSubscriptionDialog.accountFrozen.subtitle")}
      maxWidth="xs"
    >
      <Root spacing={2}>
        <DarkModeRounded htmlColor="navy" />
        <LargePrimaryButton disabled={locked} onClick={onUnfreeze}>
          <Translate id="Page.FrozenSubscriptionDialog.unfreeze" />
        </LargePrimaryButton>
      </Root>
    </Dialog>
  );
};

export default FrozenSubscriptionDialog;
