// @flow
import * as React from "react";
import type { I18nKey } from "../../types";
import { Body1 } from "../lib/display/Text";
import Translate from "../lib/display/Translate";
import { styled } from "@mui/material/styles";

type Props = {
  textId?: I18nKey,
};

const Root = styled(Body1)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  margin: "auto",
  display: "block",
}));

const NoResults: React.ComponentType<Props> = ({ textId }) => (
  <Root color="textSecondary">
    <Translate id={textId ?? "global.noResultFound"} />
  </Root>
);

export default NoResults;
