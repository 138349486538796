// @flow
import * as React from "react";
import type { Children } from "../../../reactTypes";
import type { Callback } from "../../../types";
import { Backdrop, ClickAwayListener, Portal } from "@mui/material";
import { styled } from "@mui/material/styles";
import useIsMobile from "../../../hooks/useIsMobile";
import { UncontrolledSwipeableEdgeDrawer } from "../navigation/SwipeableEdgeDrawer";

type Props = {
  children?: Children,
  open?: boolean,
  onClose: Callback,
};

const ZoomedInRoot = styled("div")({
  maxHeight: "90vh",
  maxWidth: "85vw",
  overflow: "auto",
});

const HighBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Spotlight: React.ComponentType<Props> = ({ open, onClose, children }) => {
  const isMobile = useIsMobile();
  if (isMobile && open) {
    return (
      <UncontrolledSwipeableEdgeDrawer color="paper" onClose={onClose}>
        {children}
      </UncontrolledSwipeableEdgeDrawer>
    );
  }

  return (
    <Portal>
      <HighBackdrop open={open}>
        <ClickAwayListener onClickAway={onClose}>
          <ZoomedInRoot>{children}</ZoomedInRoot>
        </ClickAwayListener>
      </HighBackdrop>
    </Portal>
  );
};

export default Spotlight;
