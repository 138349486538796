// @flow
import * as React from "react";
import SelectEntityDialog from "./SelectEntityDialog";
import type {
  OAuthEntityDetails,
  OAuthTarget,
  OAuthUserDetails,
} from "../../../../services/channels/binding.service";
import type { Channel } from "../../../../models/channels/channel.model";
import type { Callback } from "../../../../types";

type Props = {
  channel: Channel,
  user?: OAuthUserDetails,
  entities?: OAuthEntityDetails[],
  refresh?: boolean,
  onClose: Callback,
  onSelect: (OAuthTarget) => any,
};

const SelectEntityDialogContainer: React.ComponentType<Props> = ({
  entities,
  channel,
  onSelect,
  onClose,
  user,
  refresh,
}) => {
  const allowsPersonalAccounts = channel.features?.personnalAccount ?? false;
  const adaptedUser: ?OAuthTarget = React.useMemo(
    () =>
      allowsPersonalAccounts && user
        ? {
            id: user.user_id,
            name: user.user_name,
            access_token: user.access_token,
          }
        : undefined,
    [user, allowsPersonalAccounts]
  );

  return (
    <SelectEntityDialog
      refresh={refresh}
      channel={channel}
      user={adaptedUser}
      onSelect={(value) => {
        onSelect(value);
        onClose();
      }}
      entities={entities}
      onClose={onClose}
    />
  );
};

export default SelectEntityDialogContainer;
