// @flow
import * as React from "react";
import WidgetHead from "../../inputs/WidgetHead";
import type { Callback, Moment } from "../../../types";
import MiniWeekCalendar from "../../display/MiniWeekCalendar";
import MiniMonthCalendar from "../../display/MiniMonthCalendar";
import { getAnchorStartOfTargetMonth } from "../../../util/calendar.util";

type Props = {
  anchor: Moment,
  onPreviousPage: ?Callback,
  onNextPage: ?Callback,
  getBadge: (Moment) => React.Node,
  onDayClick?: (Moment) => any,
  view: "month" | "week",
};

const MiniPostsCalendarWidget: React.ComponentType<Props> = ({
  anchor,
  onPreviousPage,
  onNextPage,
  getBadge,
  onDayClick,
  view,
}) => {
  return (
    <div>
      <WidgetHead
        primary={(view === "month"
          ? getAnchorStartOfTargetMonth(anchor)
          : anchor
        ).format("MMMM YY")}
        paginated={{ onPreviousPage, onNextPage }}
      />
      {view === "week" && (
        <MiniWeekCalendar
          anchor={anchor}
          getBadge={getBadge}
          onDayClick={onDayClick}
        />
      )}
      {view === "month" && (
        <MiniMonthCalendar
          anchor={anchor}
          getBadge={getBadge}
          onDayClick={onDayClick}
        />
      )}
    </div>
  );
};

export default MiniPostsCalendarWidget;
