// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";

export const FlexGrow: React.ComponentType<React.ElementProps<"div">> = styled(
  "div"
)`
  flex-grow: 1;
`;

export const CircleBox: "div" = styled("div", {
  shouldForwardProp: (prop) => prop !== "size",
})(({ size }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  height: size,
  width: size,
}));
