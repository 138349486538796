// @flow
import * as React from "react";
import NewVersionAlert from "./NewVersionAlert";
import { VersionContext } from "../../contexts/version";

const NewAppVersionAlert: React.ComponentType<{}> = () => {
  const { webVersion } = React.useContext(VersionContext);
  return (
    <NewVersionAlert
      version={webVersion}
      titleId="Page.newVersionDialog.title"
      yesId="Page.newVersionDialog.yes"
      messageId="Page.newVersionDialog.message"
    />
  );
};

export default NewAppVersionAlert;
