// @flow
import * as React from "react";
import type {
  Form,
  MediaFormFieldDef,
  StringFormFieldDef,
} from "../../../hooks/useForm";
import type { Callback } from "../../../types";
import HTMLForm from "../../lib/inputs/form/HTMLForm";
import { Body1 } from "../../lib/display/Text";
import Translate from "../../lib/display/Translate";
import Section from "../../layout/Section";
import FormTextInput from "../../lib/inputs/form/FormTextInput";
import useTranslate from "../../../hooks/useTranslate";
import FormMediaInput from "../../lib/inputs/form/FormMediaInput";
import { FormActions } from "../../../hooks/useForm";
import { Alert, AlertTitle } from "@mui/material";
import { BugReport } from "@mui/icons-material";

export type FreeformBackFormDef = {
  title: StringFormFieldDef,
  description: StringFormFieldDef,
  images: MediaFormFieldDef,
};

type Props = {
  form: Form<FreeformBackFormDef>,
  onConfirm: Callback,
};

const FreeformFeedbackWidget: React.ComponentType<Props> = ({
  form,
  onConfirm,
}) => {
  const t = useTranslate();
  return (
    <HTMLForm
      onSubmit={onConfirm}
      submitTextId="routes.freeformFeedback.submit"
    >
      <Section>
        <Body1>
          <Translate id="FreeformFeedbackForm.messageLine" />
        </Body1>
        <Alert severity="warning" icon={<BugReport />}>
          <AlertTitle>{t("FreeformFeedbackForm.bugAlertTitle")}</AlertTitle>
          {t("FreeformFeedbackForm.bugAlertMessage")}
        </Alert>
      </Section>
      <Section title="FreeformFeedbackForm.messageSection">
        <FormTextInput
          required
          placeholder={t("FreeformFeedbackForm.titlePlaceholder")}
          formField={form.state.title}
          onChange={FormActions.onChangeField(form.set, "title")}
          label={t("global.title")}
        />
        <FormTextInput
          formField={form.state.description}
          onChange={FormActions.onChangeField(form.set, "description")}
          placeholder={t("FreeformFeedbackForm.descriptionPlaceholder")}
          label={t("global.description")}
          multiline
          rows={8}
        />
      </Section>
      <Section title="global.attachments">
        <Body1>
          <Translate id="FreeformFeedbackForm.screenshotsInstructions" />
        </Body1>
        <FormMediaInput
          formField={form.state.images}
          onChange={FormActions.onChangeField(form.set, "images")}
          onError={FormActions.onSetFieldError(form.set, "images")}
        />
      </Section>
    </HTMLForm>
  );
};

export default FreeformFeedbackWidget;
