// @flow
import * as React from "react";
import MessagesPreview from "./MessagesPreview";
import { useScopedAccount } from "../../../../../scopes/scopes";
import type { Conversation } from "../../../../../models/conversation.model";
import {
  useConversationSummary,
  useMessageHistoryRemains,
  useMessages,
} from "../../../../../store/selectors";
import head from "lodash/head";
import last from "lodash/last";
import { useStoreActions } from "../../../../../store/store";
import * as messagingService from "../../../../../services/messaging.service";
import type { Moment } from "../../../../../types";

type Props = {
  conversation: Conversation,
};

const MessagesPreviewContainer: React.ComponentType<Props> = ({
  conversation,
}) => {
  const account = useScopedAccount();
  const [locked, setLocked] = React.useState(false);
  const messages = useMessages();
  const actions = useStoreActions();
  const summary = useConversationSummary(conversation.id);
  const firstMessage = head(messages);
  const lastMessage = last(messages);
  const historyRemains = useMessageHistoryRemains();

  const handleFetchOlderMessages = React.useCallback(
    (reference: Moment) => {
      setLocked(true);
      messagingService
        .getOlderMessages(actions)(conversation.id, reference.unix())
        .finally(() => setLocked(false));
    },
    [actions, conversation.id]
  );

  const handleFetchOlderMessagesThanFirst = React.useCallback(() => {
    if (!locked && historyRemains && firstMessage?.created_at) {
      handleFetchOlderMessages(firstMessage.created_at);
    }
  }, [
    handleFetchOlderMessages,
    firstMessage?.created_at,
    locked,
    historyRemains,
  ]);

  // Install cleaner on exit.
  React.useEffect(() => actions.messaging.messages.clear, [actions]);

  React.useEffect(() => {
    if (!summary) {
      return;
    }
    if (summary.unread_messages > 0) {
      messagingService.markConversationAsRead(actions)(conversation.id);
    }
    if (lastMessage && summary.updated_at.isAfter(lastMessage.created_at)) {
      messagingService.getNewerMessages(actions)(
        conversation.id,
        lastMessage.created_at.unix() + 1 // so that we don't get the same.
      );
    }
  }, [summary, conversation.id, lastMessage, actions]);

  if (!account) return null;

  return (
    <MessagesPreview
      userNativeId={account.entity_id}
      messages={messages}
      conversation={conversation}
      allMessagesLoaded={!historyRemains}
      // Do not pass an onFetchOlderMessages handler if we are already beyond
      // the root of the conversation.
      onFetchOlderMessages={
        !lastMessage || !historyRemains || locked
          ? undefined
          : handleFetchOlderMessagesThanFirst
      }
    />
  );
};

export default MessagesPreviewContainer;
