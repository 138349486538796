// @flow
import ajax from "../../lib/ajax.lib";
import type { Email, ModelID } from "../../types";
import type { Apps, Integration } from "../../models/integration.model";
import type { APINoContentResult, APIResult } from "./types";

type AppSignInParams = {
  email: Email,
  password: string,
  app: Apps,
  native_id: string,
};

export const appSignIn = (
  params: AppSignInParams
): Promise<APINoContentResult> =>
  ajax.post<AppSignInParams, APINoContentResult>(
    "/integrations/signin",
    params
  );

export const getIntegrations = (): Promise<Integration[]> =>
  ajax
    .get<APIResult<Integration[]>>("/integrations")
    .then((response) => response.data.data);

export const deleteIntegration = (
  integrationId: ModelID
): Promise<APINoContentResult> =>
  ajax.delete<APINoContentResult>(`/integrations/${integrationId}`);
