// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import { PostFiltersContextProvider } from "../../../contexts/postFilters";
import {
  DAY,
  WEEK,
  yesterday,
} from "../../../components/inputs/DateRangeSelect/periods";
import AnalyticsPage from "./AnalyticsPage";
import { usePortfolioAccountsForAnalytics } from "../../../store/selectors";
import { useScopedPortfolio } from "../../../scopes/scopes";
import moment from "moment/moment";

const CONTEXT_OVERRIDE = { statuses: ["published"] };

const AnalyticsPageContainer: React.ComponentType<empty> = () => {
  const portfolio = useScopedPortfolio();
  const accounts = usePortfolioAccountsForAnalytics(portfolio?.id);
  const initial = React.useMemo(
    () => ({
      fromDate: moment().startOf(DAY.value).add(-1, WEEK.value),
      toDate: yesterday(),
    }),
    []
  );
  if (!portfolio) {
    // For the type checker. Should not happen.
    return false;
  }

  return (
    <PrimaryPagePanel
      size="fill"
      route={routes.app.nested.portfolios.nested.analytics}
    >
      <PostFiltersContextProvider initial={initial} override={CONTEXT_OVERRIDE}>
        <AnalyticsPage portfolio={portfolio} accounts={accounts} />
      </PostFiltersContextProvider>
    </PrimaryPagePanel>
  );
};

export default AnalyticsPageContainer;
