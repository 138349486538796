// @flow

import { cast } from "../types";

type Stage = "local" | "development" | "production";

export const STAGE: Stage = cast<Stage>(process.env.REACT_APP_STAGE ?? "local");

export const API_URL: string = process.env.REACT_APP_API_URL ?? "";
export const APP_URL: string = process.env.REACT_APP_APP_URL ?? "";
export const LANDING_URL: string = process.env.REACT_APP_LANDING_URL ?? "";
export const SWITCH_APP_URL: string =
  process.env.REACT_APP_SWITCH_APP_URL ?? "";
export const ATTACHMENTS_URL: string =
  process.env.REACT_APP_ATTACHMENTS_URL ?? "";
export const FACEBOOK_PERMISSIONS: string =
  process.env.REACT_APP_FACEBOOK_PERMISSIONS ?? "";
export const FACEBOOK_CLIENT_ID: string =
  process.env.REACT_APP_FACEBOOK_CLIENT_ID ?? "";
export const FACEBOOK_REDIRECT_ENDPOINT: string =
  process.env.REACT_APP_FACEBOOK_REDIRECT_ENDPOINT ?? "";
export const FACEBOOK_STATE: string =
  process.env.REACT_APP_FACEBOOK_STATE ?? "";

export const INSTAGRAM_REDIRECT_ENDPOINT: string =
  process.env.REACT_APP_INSTAGRAM_REDIRECT_ENDPOINT ?? "";

export const LINKEDIN_CLIENT_ID: string =
  process.env.REACT_APP_LINKEDIN_CLIENT_ID ?? "";
export const LINKEDIN_REDIRECT_ENDPOINT: string =
  process.env.REACT_APP_LINKEDIN_REDIRECT_ENDPOINT ?? "";
export const LINKEDIN_STATE: string =
  process.env.REACT_APP_LINKEDIN_STATE ?? "";

export const PINTEREST_CLIENT_ID: string =
  process.env.REACT_APP_PINTEREST_CLIENT_ID ?? "";
export const PINTEREST_REDIRECT_ENDPOINT: string =
  process.env.REACT_APP_PINTEREST_REDIRECT_ENDPOINT ?? "";
export const PINTEREST_PERMISSIONS: string =
  process.env.REACT_APP_PINTEREST_PERMISSIONS ?? "";
export const PINTEREST_STATE: string =
  process.env.REACT_APP_PINTEREST_STATE ?? "";

export const GOOGLE_API_KEY: string =
  process.env.REACT_APP_GOOGLE_API_KEY ?? "";
export const GOOGLE_CLIENT_ID: string =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "";
export const GMB_REDIRECT_ENDPOINT: string =
  process.env.REACT_APP_GMB_REDIRECT_ENDPOINT ?? "";
export const GMB_PERMISSIONS: string =
  process.env.REACT_APP_GMB_PERMISSIONS ?? "";
export const GMB_STATE: string = process.env.REACT_APP_GMB_STATE ?? "";

export const TIKTOK_PERMISSIONS: string =
  process.env.REACT_APP_TIKTOK_PERMISSIONS ?? "";
export const TIKTOK_CLIENT_ID: string =
  process.env.REACT_APP_TIKTOK_CLIENT_ID ?? "";
export const TIKTOK_REDIRECT_ENDPOINT: string =
  process.env.REACT_APP_TIKTOK_REDIRECT_ENDPOINT ?? "";
export const TIKTOK_STATE: string = process.env.REACT_APP_TIKTOK_STATE ?? "";

export const STRIPE_API_PUBLIC_KEY: string =
  process.env.REACT_APP_STRIPE_API_PUBLIC_KEY ?? "";

export const GOOGLE_ANALYTICS_ID: string =
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? "";

export const GIPHY_KEY: string = process.env.REACT_APP_GIPHY_KEY ?? "";

export const ENABLE_SENTRY: boolean =
  process.env.REACT_APP_ENABLE_SENTRY === "true";

export const isProd = (): boolean => STAGE === "production";
