// @flow
import * as React from "react";
import identity from "lodash/identity";
import type {
  Props as AutocompleteProps,
  BaseAutocompleteItem,
} from "../../lib/inputs/Autocomplete";
import Autocomplete from "../../lib/inputs/Autocomplete";
import { debounce } from "@mui/material/utils";
import apiInstance from "../../../lib/ajax.lib";
import type { APIResult } from "../../../services/backend.service/types";

type Props<T> = {
  ...Omit<AutocompleteProps<T>, "options">,
  endpoint: string,
  optionsAttribute: string,
  once?: boolean,
};

const APISearchContainer = <Item: BaseAutocompleteItem>({
  endpoint,
  optionsAttribute,
  once,
  ...props
}: Props<Item>): React.Node => {
  const [options, setOptions] = React.useState<Item[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce((search) => {
        const url = search === "" ? endpoint : `${endpoint}/${search}`;
        apiInstance
          .get<APIResult<Object>>(url)
          .then((response) => response.data.data[optionsAttribute])
          .then(setOptions);
      }, 400),
    [endpoint, optionsAttribute]
  );

  // In requestOnlyOnce mode, load items once and for all.
  // Reload them if the fetch function changes though (target has changed).
  React.useEffect(() => {
    if (once) {
      fetch("");
    }
  }, [fetch, once]);

  const handleInputChange = (e: SyntheticInputEvent<>) => {
    if ((e?.target?.value?.length ?? 0) >= 3 && !once) {
      fetch(e.target.value);
    }
  };

  return (
    <Autocomplete
      onInputChange={handleInputChange}
      filterOptions={identity}
      options={options}
      {...props}
    />
  );
};

export default APISearchContainer;
