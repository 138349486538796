// @flow
import * as React from "react";
import useSwitch from "../../../hooks/useSwitch";
import Spotlight from "./Spotlight";
import type { SyntheticEventListener } from "../../../reactTypes";
import { styled } from "@mui/material/styles";
import useIsMobile from "../../../hooks/useIsMobile";

/**
 * Gives a popup behaviour to a Component.
 * It can now accept children as menu items.
 */
export const withSpotlight = <Props: { onClick?: SyntheticEventListener, ... }>(
  Component: React.ComponentType<Props>,
  disabledOnMobile?: boolean = true
): React.ComponentType<Props> => {
  const StyledComponent = styled(Component)`
    cursor: zoom-in;
  `;
  return (props) => {
    const isMobile = useIsMobile();
    const [open, onOpen, onClose] = useSwitch();
    if (isMobile && disabledOnMobile) return <Component {...props} />;

    const target = <StyledComponent {...props} onClick={onOpen} />;
    return (
      <>
        {target}
        {open && (
          // Using open={open} does not seem to work
          // so use a conditional render.
          <Spotlight onClose={onClose} open>
            <Component {...props} />
          </Spotlight>
        )}
      </>
    );
  };
};

export default withSpotlight;
