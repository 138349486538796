// @flow
import * as React from "react";
import PostStatusChip from "./PostStatusChip";
import { getTime } from "../../models/post.model";
import TimezoneCaption from "./TimezoneCaption";
import type { Post } from "../../models/post.model";

type Props = {
  post: Post,
  dense?: boolean,
};

const PostStatusChipAndTimezone: React.ComponentType<Props> = ({
  post,
  dense,
}) => {
  return (
    <>
      <PostStatusChip
        status={post.status}
        time={getTime(post)}
        dense={dense}
        postId={post.id}
        channel={post.channel}
        autopublishDisabled={post.disable_autopublish}
      />
      <TimezoneCaption timezone={post.timezone} short={dense} />
    </>
  );
};

export default PostStatusChipAndTimezone;
