// @flow
import * as React from "react";
import type { Post } from "../../models/post.model";
import { InlineRowStack } from "../lib/layout/stacks";
import ContentTypeChip from "./ContentTypeChip";
import PollChip from "./PollChip";
import FirstCommentChip from "./FirstCommentChip";
import ThreadsChip from "./ThreadsChip";
import PostApprovalStatusChip from "./PostApprovalStatusChip";
import PostHandle from "./PostHandle";
import { API as backend } from "../../services/backend.service";
import type { ModelID } from "../../types";
import isEmpty from "lodash/isEmpty";
import * as links from "../../util/links.util";
import { isIOS } from "../../lib/platform.lib";
import PostTitle from "./PostTitle";
import useIsMobile from "../../hooks/useIsMobile";
import PeriodicityChip from "./PeriodicityChip";

type Props = {
  post: Post,
};

const onApprovalStatusChipClickExceptIOS = (postId: ModelID) => {
  backend.approval.searchApprovalRequests(postId).then((approvalRequests) => {
    if (!isEmpty(approvalRequests)) {
      window.open(links.approvalRequest(approvalRequests[0]));
    }
  });
};

const onApprovalStatusChipClick = isIOS
  ? undefined
  : onApprovalStatusChipClickExceptIOS;

const PostDescriptors: React.ComponentType<Props> = React.memo(({ post }) => {
  const isMobile = useIsMobile();
  return (
    <InlineRowStack spacing={0.5}>
      <ContentTypeChip type={post.content_type} />
      {post.poll && <PollChip />}
      {post.first_comment && <FirstCommentChip />}
      {!!post.self_replies?.length && (
        <ThreadsChip count={post.self_replies.length} />
      )}
      {post.approval && (
        <PostApprovalStatusChip
          iconOnly={isMobile}
          status={post.approval}
          onClick={
            onApprovalStatusChipClick
              ? () => onApprovalStatusChipClick(post.id)
              : undefined
          }
        />
      )}
      {post.periodicity && <PeriodicityChip periodicity={post.periodicity} />}
      <PostHandle
        postId={post.id}
        title={post.title}
        portfolioId={post.portfolio_id}
      />
      <PostTitle title={post.title} />
    </InlineRowStack>
  );
});

export default PostDescriptors;
