// @flow
import * as React from "react";
import { useScopedPost } from "../../../scopes/scopes";
import Spotlight from "../../../components/lib/feedback/Spotlight";
import useNavigateBackToResourceList from "../../../hooks/useNavigateBackToResourceList";
import PostCard from "../../../components/display/PostCard";
import { useAccount } from "../../../store/selectors";
import { styled } from "@mui/material/styles";

const SizedPostCard = styled(PostCard)({
  maxWidth: 600,
});

const ViewPostPage: React.ComponentType<{}> = () => {
  const post = useScopedPost();
  const account = useAccount(post?.account_id);
  const navigateBack = useNavigateBackToResourceList(true);
  if (!post || !account) {
    // For the type checker. Should not happen.
    return false;
  }
  return (
    <Spotlight open onClose={navigateBack}>
      <SizedPostCard account={account} post={post} />
    </Spotlight>
  );
};

export default ViewPostPage;
