// @flow
import * as React from "react";
import fromPairs from "lodash/fromPairs";
import useAjaxErrorInterceptor from "../../hooks/useAjaxInterceptor";
import { useParams } from "react-router-dom";
import { getURLParam, makeURL } from "../../lib/url.lib";
import { API as backend } from "../../services/backend.service";
import LoginPanel from "../../components/widgets/LoginWidget";
import useTranslate from "../../hooks/useTranslate";
import { checkEmail, checkPassword } from "../../util/validation.util";
import { capitalise } from "../../lib/lodashex.lib";
import { Center } from "../../components/lib/layout/placement";

const getSignInQueryArgs = () =>
  fromPairs(
    getURLParam("forward_args")
      .split(",")
      .filter(Boolean)
      .map((arg) => [arg, getURLParam(arg)])
  );

const IntegrationLoginPageContainer: React.ComponentType<{}> = () => {
  useAjaxErrorInterceptor();
  const t = useTranslate();
  const { app } = useParams<{ app: string }>();

  const handleConfirm = (email: string, password: string) =>
    backend.integration
      .appSignIn({ email, password, app, native_id: getURLParam("native_id") })
      .then(() =>
        window.location.replace(
          makeURL(
            decodeURIComponent(getURLParam("redirect_url")),
            getSignInQueryArgs()
          )
        )
      );

  return (
    <Center>
      <LoginPanel
        noSignupInvite
        onValidCredentials={handleConfirm}
        title={t("IntegrationLoginPage.title", { app: capitalise(app) })}
        prompt={t("IntegrationLoginPage.subtitle")}
        checkPassword={checkPassword}
        checkUsername={checkEmail}
      />
    </Center>
  );
};

export default IntegrationLoginPageContainer;
