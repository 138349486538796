// @flow
import * as React from "react";
import {
  Badge,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import useTranslate from "../../../hooks/useTranslate";
import { FilterListRounded, SearchRounded } from "@mui/icons-material";
import type { Props as TextFieldProps } from "../../../stubs/mui/TextField";
import type { Callback } from "../../../types";
import { styled } from "@mui/material/styles";

export type Props = {
  ...Omit<
    TextFieldProps,
    | "type"
    | "placeholder"
    | "startAdornment"
    | "endAdornment"
    | "variant"
    | "InputProps"
  >,
  onToggleFilter?: Callback,
  filterSelected?: boolean,
  activeFilters?: number,
};

const PaperInput: typeof OutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const SearchInput: React.ComponentType<Props> = ({
  onToggleFilter,
  filterSelected,
  activeFilters,
  ...props
}) => {
  const t = useTranslate();
  return (
    <PaperInput
      type="search"
      placeholder={t("global.searchHere")}
      startAdornment={
        <InputAdornment position="start">
          <SearchRounded />
        </InputAdornment>
      }
      endAdornment={
        onToggleFilter ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="filter"
              value="showFilters"
              onClick={onToggleFilter}
              color={filterSelected ? "secondary" : undefined}
            >
              <Badge
                badgeContent={
                  activeFilters ? activeFilters.toString() : undefined
                }
                color="primary"
              >
                <FilterListRounded />
              </Badge>
            </IconButton>
          </InputAdornment>
        ) : undefined
      }
      {...props}
    />
  );
};

export default SearchInput;
