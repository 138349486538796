// @flow
import * as React from "react";
import VizPlannerWidget from "./VizPlannerWidget";
import PostFiltersContext from "../../../contexts/postFilters";
import {
  useAccount,
  usePostsLocked,
  useVizPosts,
} from "../../../store/selectors";
import { head } from "lodash";
import * as postService from "../../../services/post.service";
import { useScopedPortfolio } from "../../../scopes/scopes";
import { useStoreActions } from "../../../store/store";

const VizPlannerWidgetContainer: React.ComponentType<{}> = () => {
  const portfolio = useScopedPortfolio();
  const filters = React.useContext(PostFiltersContext);
  const account = useAccount(head(filters.accountIds));
  const actions = useStoreActions();
  const locked = usePostsLocked();
  const posts = useVizPosts(account?.id, filters.fromDate, filters.toDate);

  if (!portfolio) return false;

  return (
    <VizPlannerWidget
      posts={posts}
      account={account}
      portfolio={portfolio}
      locked={locked}
      onScreenshot={() => postService.exportScreenshot(actions)(posts)}
    />
  );
};

export default VizPlannerWidgetContainer;
