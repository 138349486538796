import React from "react";
import { SvgIcon } from "@mui/material";

const Hashtag = React.forwardRef(({ htmlColor, ...props }, ref) => (
  <SvgIcon
    titleAccess="Hashtag"
    ref={ref}
    {...props}
    htmlColor={htmlColor || "inherit"}
    focusable="false"
    aria-hidden="true"
    data-icon="hashtag"
    viewBox="-8 -8 48 48"
  >
    <path d="M30,12V8h-5.004l1-8h-4l-1,8h-7.998l1-8h-4l-1,8H2v4h6.498L7.5,20H2v4h5l-1,8h4l1-8h8l-1.002,8H22l1-8h7v-4h-6.5l0.996-8H30z M19.5,20h-8l0.998-8h7.998L19.5,20z" />
  </SvgIcon>
));

export default Hashtag;
