// @flow
import type { AxiosPromise } from "axios";
import axios from "axios";
import noop from "lodash/noop";
import { blobToString, downloadData } from "./files.lib";
import type { TokenManager } from "./session.lib";
import {
  getAvailableToken,
  hasAnyValidToken,
  UserToken,
  VisitorToken,
} from "./session.lib";
import type { URLStr } from "../types";
import * as env from "../config/env.config";

export type AxiosConfig = {
  disableErrorInterceptor: boolean,
};

const initAPIInstance = (instance: axios.Axios): axios.Axios => {
  instance.defaults.baseURL = env.API_URL;
  // $FlowIgnore
  instance.defaults.headers.post["Content-Type"] = "application/json";

  // Always include the token in storage if any.
  instance.interceptors.request.use(
    (config) => {
      if (hasAnyValidToken()) {
        // $FlowIgnore
        config.headers["Authorization"] = getAvailableToken();
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

const initFrontInstance = (instance: axios.Axios): axios.Axios => {
  instance.defaults.baseURL = "/";

  // Always "unwrap" the data from the response object.
  // We don't care about the response headers and status.
  instance.interceptors.response.use((r) => r.data);

  return instance;
};

// Create our custom apiInstance to use throughout the app.
/**
 * apiInstance is the main one. It is preprogrammed to communicate with our
 * API and includes authentication.
 */
const apiInstance: axios.Axios = initAPIInstance(axios.create());
/**
 * frontInstance is used to fetch frontend files, not to communicate with the
 * API.
 */
export const staticInstance: axios.Axios = initFrontInstance(axios.create());
/**
 * ExtInstance is used to communicate with the outside world, like Amazon S3.
 */
export const webInstance: axios.Axios = axios.create();

const checkDeleteToken = (manager: TokenManager, url: URLStr) => () => {
  if (manager.isValid()) {
    apiInstance
      .get<any>(url, {
        headers: { Authorization: manager.get() },
        // Disable error interception. We don't want the user to be redirected
        // if the token is invalid. It is just a check.
        disableErrorInterceptor: true,
      })
      .catch((error) => {
        console.log(error);
        // If NotAuthenticated or AuthenticationFailed, delete the token.
        if (error.response?.status === 401) {
          manager.unset();
        }
      });
  }
};

const checkDeleteUserToken = checkDeleteToken(UserToken, "/auth/token/status");
const checkDeleteVisitorToken = checkDeleteToken(
  VisitorToken,
  "/approval/token/status"
);

export const clearExpiredTokens = () => {
  checkDeleteUserToken();
  checkDeleteVisitorToken();
};

type UploadFileProps = {
  url: URLStr,
  file: File,
  headers?: { [string]: any },
  onProgress?: (progressEvent: ProgressEvent) => void,
  instance: axios.Axios,
};

export const uploadFile = ({
  url,
  file,
  headers,
  onProgress,
  instance,
}: UploadFileProps): AxiosPromise<void> => {
  return instance.put<any, any, File>(url, file, {
    timeout: 3 * 60 * 1000, // 3 minutes.
    onUploadProgress: onProgress ?? noop,
    headers,
  });
};

export const downloadFile = (url: URLStr, filename: string): Promise<void> =>
  apiInstance
    .get<Blob>(url, { responseType: "blob" })
    .then((response) => {
      downloadData(response.data, filename);
    })
    .catch((reason) => {
      console.error("Error in downloadFile:", reason);
      blobToString(reason.response.data).then((result) => {
        console.error("Converted blob to string:", result);
        throw JSON.parse(result);
      });
    });

export default apiInstance;
