// @flow
import * as React from "react";
import {
  useCurrentSubscription,
  useCurrentUser,
} from "../../../store/selectors";
import { getCountryFromValue } from "../../../config/countries.config";
import { FormActions, useForm } from "../../../hooks/useForm";
import type { TransientBillingInfo } from "../../../models/billingInfo.model";
import type { BillingInfoFormDef } from "./BillingInfoWidget";
import type { Form } from "../../../hooks/useForm";
import type { Callback } from "../../../types";

const FORM_FIELDS: BillingInfoFormDef = {
  cardHolderName: { type: "string" },
  addressLine: { type: "string" },
  city: { type: "string" },
  postalCode: { type: "string" },
  stateOrRegion: { type: "string" },
  country: { type: "object" },
  email: { type: "string" },
  enableSendInvoices: { type: "boolean" },
};

type ReturnValue = {
  form: Form<BillingInfoFormDef>,
  onValidateAndCollect: () => ?{
    billingInfo: TransientBillingInfo,
    enableSendInvoices: boolean,
  },
  onReset: Callback,
};

const useBillingInfoForm = (): ReturnValue => {
  const user = useCurrentUser();
  const subscription = useCurrentSubscription();
  const billingInfo = subscription?.billing_info;

  const initial = React.useMemo(
    () => ({
      cardHolderName: billingInfo?.entity_name ?? "",
      addressLine: billingInfo?.street ?? "",
      city: billingInfo?.city ?? "",
      postalCode: billingInfo?.postcode ?? "",
      stateOrRegion: billingInfo?.state_or_region ?? "",
      country: getCountryFromValue(billingInfo?.country) ?? null,
      email: billingInfo?.email ?? user?.email ?? "",
      enableSendInvoices: subscription?.send_invoices ?? false,
    }),
    [billingInfo, user?.email, subscription?.send_invoices]
  );

  const form = useForm(FORM_FIELDS, initial);
  const onReset = () => {
    FormActions.reset(form.set, initial);
  };

  React.useEffect(() => {
    FormActions.reset(form.set, initial);
  }, [form.set, initial]);

  const onValidateAndCollect = () => {
    if (FormActions.validate(form)) {
      const { cardHolderName, email, enableSendInvoices, ...address } =
        FormActions.collect(form.state);
      return {
        billingInfo: {
          entity_name: cardHolderName.trim(),
          email: email.trim(),
          city: address.city.trim(),
          country: address.country.value,
          street: address.addressLine.trim(),
          state_or_region: address.stateOrRegion.trim(),
          postcode: address.postalCode.trim(),
        },
        enableSendInvoices,
      };
    }
  };
  return { form, onValidateAndCollect, onReset };
};

export default useBillingInfoForm;
