// @flow
import * as React from "react";
import type { CellComponent, Column, DataTableProps, RowId } from "./base";
import omit from "lodash/omit";
import range from "lodash/range";
import { Skeleton } from "@mui/material";
import DataTable from "./DataTable";

type SkeletonColumn = Column<{ id: RowId }>;

const SkeletonCell: CellComponent<void> = () => <Skeleton animation={false} />;

const skeletiseColumns = <CellProps>(
  columns: Column<any, CellProps>[]
): SkeletonColumn[] =>
  columns.map((col) => ({
    ...omit(col, ["dataKey"]),
    Component: SkeletonCell,
  }));

type MakeSkeletonTableParam<CellProps> = {
  columns: Column<any, CellProps>[],
  rowsCount?: number,
  slotProps?: DataTableProps<any>["slotProps"],
};

export const makeSkeletonTable = <CellProps>({
  columns,
  rowsCount = 5,
  slotProps = {},
}: MakeSkeletonTableParam<CellProps>): React.ComponentType<{}> => {
  const cols = skeletiseColumns(columns);
  const rows = range(rowsCount).map((id) => ({ id }));
  return () => <DataTable columns={cols} rows={rows} slotProps={slotProps} />;
};

export default makeSkeletonTable;
