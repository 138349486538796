// @flow
import * as React from "react";
import IntegrationAppItem from "./IntegrationAppItem";
import type { App } from "../../../../../models/integration.model";
import { useStoreActions } from "../../../../../store/store";
import * as integrationService from "../../../../../services/integration.service";
import type { ModelID } from "../../../../../types";

type Props = {
  app: App,
  integrationId?: ModelID,
};

const IntegrationAppItemContainer: React.ComponentType<Props> = ({
  app,
  integrationId,
}) => {
  const actions = useStoreActions();

  return (
    <IntegrationAppItem
      onDelete={() =>
        integrationId &&
        integrationService.deleteIntegration(actions)(integrationId)
      }
      app={app}
      connected={!!integrationId}
    />
  );
};

export default IntegrationAppItemContainer;
