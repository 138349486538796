// @flow
import strings from "../../public/locales/en-gb/translation.json";
import type { I18nKey } from "../types";

type FreezeOrdDeleteReasonOption = {
  value: string,
  label: I18nKey,
};

const freezeOrDeleteReasons: FreezeOrdDeleteReasonOption[] = Object.keys(
  strings.refer.freezeReasons
).map((value) => ({
  value,
  label: `refer.freezeReasons.${value}`,
}));

const REQUIRES_MORE_DETAILS = [
  "other",
  "notEnoughFeatures",
  "difficultToNavigate",
];

export const requiresMoreDetails = (reason: string): boolean =>
  REQUIRES_MORE_DETAILS.includes(reason);

export default freezeOrDeleteReasons;
