// @flow
import * as React from "react";
import type {
  Form,
  ObjectFormFieldDef,
  StringFormFieldDef,
  StringListFormFieldDef,
} from "../../../hooks/useForm";
import { FormActions } from "../../../hooks/useForm";
import type { Callback } from "../../../types";
import HTMLForm from "../../lib/inputs/form/HTMLForm";
import Section from "../../layout/Section";
import FormTextInput from "../../lib/inputs/form/FormTextInput";
import { Body2 } from "../../lib/display/Text";
import Translate from "../../lib/display/Translate";
import FormAutocomplete from "../../lib/inputs/form/FormAutocomplete";
import channels from "../../../models/channels";
import useTranslate from "../../../hooks/useTranslate";
import FormChipsInput from "../../lib/inputs/form/FormChipsInput";
import { ChannelAdornment } from "../../display/Adornments";
import useIsMobile from "../../../hooks/useIsMobile";

export type HashtagGroupFormDef = {
  name: StringFormFieldDef,
  hashtags: StringListFormFieldDef,
  channel: ObjectFormFieldDef,
};

type Props = {
  form: Form<HashtagGroupFormDef>,
  onConfirm: Callback,
  onDelete?: Callback,
  onCancel: Callback,
};

const HashtagGroupWidget: React.ComponentType<Props> = ({
  form,
  onConfirm,
  onCancel,
  onDelete,
}) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  return (
    <HTMLForm
      onSubmit={onConfirm}
      onCancel={isMobile ? undefined : onCancel}
      submitTextId="global.save"
      onDelete={onDelete}
      cancelTextId="global.dropChanges"
    >
      <Section title="HashtagGroupForm.details">
        <FormTextInput
          required
          formField={form.state.name}
          onChange={FormActions.onChangeField(form.set, "name")}
          label={t("global.name")}
        />
        <FormChipsInput
          formField={form.state.hashtags}
          onChange={FormActions.onChangeField(form.set, "hashtags")}
          TextFieldProps={{
            placeholder: t("HashtagGroupForm.hashtagsInputHelper"),
            label: t("global.hashtags"),
          }}
        />
      </Section>
      <Section title="global.filters">
        <Body2 color="textSecondary">
          <Translate id="HashtagGroupForm.filtersInstructions" />
        </Body2>
        <FormAutocomplete
          formField={form.state.channel}
          onChange={FormActions.onChangeField(form.set, "channel")}
          options={channels}
          optionPrimaryLabel="name"
          AdornmentComponent={ChannelAdornment}
          adornOptions
          TextFieldProps={{
            label: t("global.channel"),
          }}
        />
      </Section>
    </HTMLForm>
  );
};

export default HashtagGroupWidget;
