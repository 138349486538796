// @flow
import * as React from "react";
import type { Props } from "./PostSearchBar";
import { useNavigate, useParams } from "react-router-dom";
import routes, { getRoutePath } from "../../../config/routes.config";
import PostSearchBar from "./PostSearchBar";
import { parseModelId } from "../../../models/base.model";

const PostSearchBarContainer: React.ComponentType<
  Omit<Props, "onComposeClick">
> = (props) => {
  const navigate = useNavigate();
  const { portfolioId } = useParams();

  const handleComposeClick = React.useCallback(() => {
    navigate(
      getRoutePath(routes.app.nested.portfolios.nested.addPost, {
        portfolioId: parseModelId(portfolioId),
      })
    );
  }, [portfolioId, navigate]);

  return <PostSearchBar {...props} onComposeClick={handleComposeClick} />;
};

export default PostSearchBarContainer;
