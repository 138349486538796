// @flow
import * as React from "react";
import InboxWidget from "./InboxWidget";
import { useInboxSummary } from "../../../store/selectors";
import type { ModelID } from "../../../types";

type Props = {
  onSelect: ("messages" | "comments") => any,
  portfolioId: ModelID,
};

const InboxWidgetContainer: React.ComponentType<Props> = ({
  onSelect,
  portfolioId,
}) => {
  const inboxSummary = useInboxSummary(portfolioId);

  return <InboxWidget onSelect={onSelect} inboxSummary={inboxSummary} />;
};

export default InboxWidgetContainer;
