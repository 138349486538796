// @flow
import * as React from "react";
import type {
  FormField,
  OnChangeField,
  StringFormFieldDef,
} from "../../../../hooks/useForm";
import AvatarInput from "../AvatarInput";
import type { Props as AvatarProps } from "../AvatarInput";
import { SmallNeutralButton } from "../buttons";
import Translate from "../../display/Translate";
import { fileToImageDataURL } from "../../../../lib/images.lib";
import { styled } from "@mui/material/styles";

type Props = {
  ...AvatarProps,
  formField: FormField<StringFormFieldDef>,
  onChange: OnChangeField<StringFormFieldDef>,
};

const Root = styled("div")`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const FormAvatarInput: React.ComponentType<Props> = ({
  formField,
  onChange,
  ...props
}) => (
  <Root>
    <AvatarInput
      src={formField.value}
      onChange={(file) => fileToImageDataURL(file).then(onChange)}
      {...props}
    />
    {formField.value && (
      <SmallNeutralButton
        onClick={() => onChange("")}
        style={{ visibility: props.disabled ? "hidden" : undefined }}
      >
        <Translate id="PortfolioForm.removeAvatar" />
      </SmallNeutralButton>
    )}
  </Root>
);
export default FormAvatarInput;
