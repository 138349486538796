// @flow
import * as React from "react";
import { PermanentDrawer, TemporaryDrawer } from "./Drawers/Drawers";
import type { NavigationProps } from "./types";

type Props = {
  ...NavigationProps,
  temporary: boolean,
};

const AppNavigation: React.ComponentType<Props> = ({ temporary, ...props }) =>
  temporary ? <TemporaryDrawer {...props} /> : <PermanentDrawer {...props} />;

export default AppNavigation;
