// @flow
import * as React from "react";
import type { URLStr } from "../../../types";
import { LinearProgress, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Caption } from "./Text";
import { LinkRounded } from "@mui/icons-material";
import { InlineRowStack } from "../layout/stacks";
import type { Preview } from "../../../pages/AppPage/hooks/useLinkPreview";
import useLinkPreview from "../../../pages/AppPage/hooks/useLinkPreview";
import type { CSSProps } from "../../../reactTypes";

type Props = {
  link: URLStr,
  preview?: ?Preview,
  variant?: "image" | "default",
  ...CSSProps,
};

const LinkImage = styled("img")({
  width: "100%",
});

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  border: `solid 1px ${theme.palette.divider}`,
}));

const LinkMeta = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.alt,
  padding: theme.spacing(1, 1, 0.5, 1),
}));

const LinkTitle = styled(Caption)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: "bold",
  display: "block",
});

const LinkURL = styled(Link)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "block",
  lineHeight: "1.66rem",
});

const LinkPreview: React.ComponentType<Props> = React.forwardRef(
  (
    { link, preview: controlledPreview, variant = "default", ...props },
    ref
  ) => {
    const [preview, refreshPreview, locked] = useLinkPreview(controlledPreview);

    React.useEffect(() => {
      // Only load if preview is not manually set.
      if (!controlledPreview) {
        refreshPreview(link);
      }
    }, [refreshPreview, link, controlledPreview]);

    if (locked) {
      return <LinearProgress />;
    }
    if (!preview.image) {
      return null;
    }

    const linkImage = (
      <LinkImage src={preview.image} alt={preview.title} ref={ref} />
    );

    if (variant === "image") {
      return linkImage;
    }
    return (
      <Root {...props}>
        {linkImage}
        <LinkMeta>
          <LinkTitle>{preview.title}</LinkTitle>
          <InlineRowStack>
            <LinkRounded fontSize="small" color="textSecondary" />
            <LinkURL
              variant="overline"
              color="textSecondary"
              href={link}
              target="_blank"
            >
              {link}
            </LinkURL>
          </InlineRowStack>
        </LinkMeta>
      </Root>
    );
  }
);

export default LinkPreview;
