// @flow
import * as React from "react";
import type { PromoCodeFormDef } from "./PromoCodeSection";
import PromoCodeSection from "./PromoCodeSection";
import { FormActions, useForm } from "../../../../hooks/useForm";
import useSwitch from "../../../../hooks/useSwitch";
import { checkPromoCode } from "../../../../util/validation.util";
import * as subscriptionService from "../../../../services/subscription.service";
import { API as backend } from "../../../../services/backend.service";
import type { PromotionCode } from "../../../../models/promotionCode.model";
import { useStoreActions } from "../../../../store/store";

const FORM_FIELDS: PromoCodeFormDef = {
  promoCode: {
    type: "string",
    validate: checkPromoCode,
  },
};

const FORM_INITIAL = {
  promoCode: "",
};

const useAppliedPromocodes = () => {
  const [promocodes, setPromocodes] = React.useState<PromotionCode[]>([]);
  React.useEffect(() => {
    backend.subscription.listAppliedPromocodes().then(setPromocodes);
  }, []);
  return promocodes;
};

const PromoCodeSectionContainer: React.ComponentType<{}> = () => {
  const promocodes = useAppliedPromocodes();
  const actions = useStoreActions();
  const [editing, onBeginEdit, onEndEdit] = useSwitch(false);
  const form = useForm(FORM_FIELDS, FORM_INITIAL);

  const handleCancel = () => {
    onEndEdit();
    FormActions.reset(form.set, FORM_INITIAL);
  };

  const handleConfirm = () => {
    if (FormActions.validate(form)) {
      return subscriptionService
        .applyPromoCode(actions)(form.state.promoCode.value)
        .then(onEndEdit);
    }
  };
  return (
    <PromoCodeSection
      appliedPromocodes={promocodes}
      editing={editing}
      onBeginEdit={onBeginEdit}
      onCancelEdit={handleCancel}
      onCompleteEdit={handleConfirm}
      form={form}
    />
  );
};

export default PromoCodeSectionContainer;
