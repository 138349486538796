// @flow
import * as React from "react";
import MessageInput from "./MessageInput";
import type { Props as MessageInputProps } from "./MessageInput";
import useSwitch from "../../../../hooks/useSwitch";

export type Props = {
  ...Pick<
    MessageInputProps,
    "actions" | "children" | "empty" | "style" | "className"
  >,
  onSubmit: (string) => any,
  initial?: ?string,
};

const MessageInputContainer: React.ComponentType<Props> = React.forwardRef(
  ({ empty = false, onSubmit, initial, ...props }, ref) => {
    const [locked, setLocked, setUnlocked] = useSwitch();
    const [text, setText] = React.useState(initial ?? "");

    const handleSubmit = () => {
      setLocked();
      // Use Promise.resolve to ensure a Promise result.
      Promise.resolve(onSubmit(text))
        .then(() => setText(""))
        .finally(setUnlocked);
    };

    return (
      <MessageInput
        ref={ref}
        onChangeText={setText}
        onSubmit={handleSubmit}
        empty={!text && empty}
        loading={locked}
        text={text}
        {...props}
      />
    );
  }
);

export default MessageInputContainer;
