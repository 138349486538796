// @flow
import * as React from "react";
import Image from "./Image";
import Video from "./Video";
import File from "./File";
import type { Props as ImageProps } from "./Image";
import type { Props as VideoProps } from "./Video";
import type { Props as FileProps } from "./File";
import omit from "lodash/omit";
import { styled } from "@mui/material/styles";

export type Props =
  | { mimetypeType: "image", ...ImageProps }
  | { mimetypeType: "video", ...VideoProps }
  | { mimetypeType: "audio", ...Object }
  | { mimetypeType: string, ...FileProps };

const FileWithMargin = styled(File)`
  margin: 4px;
  width: 64px;
`;

const MimeTypeBasedPreview: React.ComponentType<Props> = React.forwardRef(
  ({ mimetypeType, ...props }, ref) => {
    switch (mimetypeType) {
      case "image":
        return <Image {...omit(props, "videoOffset")} ref={ref} />;
      case "video":
        return <Video {...props} ref={ref} />;
      case "audio":
        return <audio {...props} ref={ref} />;
      default:
        return <FileWithMargin {...props} ref={ref} />;
    }
  }
);

export default MimeTypeBasedPreview;
