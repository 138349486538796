// @flow
import * as React from "react";
import type { DateRangePeriod } from "./periods";
import periods, { getOptionLabel, getOptionValue } from "./periods";
import type { CSSSize, Moment } from "../../../types";
import useTranslate from "../../../hooks/useTranslate";
import Select from "../../lib/inputs/Select";

export type Props = {
  period: DateRangePeriod,
  onSetPeriod: (DateRangePeriod) => any,
  fromDate: Moment,
  toDate: Moment,
  width?: CSSSize | Object,
};

const DateRangeSelect: React.ComponentType<Props> = React.forwardRef(
  ({ period, onSetPeriod, fromDate, toDate, width }, ref) => {
    const t = useTranslate();
    return (
      <Select
        width={width}
        fullWidth
        id="date-range-select"
        onChange={(opt) => opt && onSetPeriod(opt.value)}
        ref={ref}
        multiple={false}
        label={t("global.daterange")}
        options={periods}
        value={periods.find((opt) => opt.value === period)}
        translateLabel
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
    );
  }
);

export default DateRangeSelect;
