// @flow
import type { FindIteratee, Integer, ModelID, Timestamp } from "../types";

export type SocialActionSummary = {
  post_id: ModelID,
  comments_count: Integer,
  unread_comments: Integer,
  latest_comment_at: Timestamp | null,
};

export const hasUnreadComments: FindIteratee<SocialActionSummary> = (s) =>
  s.unread_comments > 0;

export const byLatestCommentAt = (s: SocialActionSummary): Timestamp =>
  s.latest_comment_at ?? 0;

export type APISocialActionSummary = SocialActionSummary;

export const fromAPIResult = (
  summary: APISocialActionSummary
): SocialActionSummary => summary;
