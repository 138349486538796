// @flow
import * as React from "react";
import type { Integration } from "../../models/integration.model";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import type { LockActions, LockState } from "./lib/lock.state";
import { useLockState } from "./lib/lock.state";

const integrationReducer = sequenceReducer<Integration>({
  key: "id",
});

export type IntegrationState = {
  ...LockState,
  integrations: SequenceState<Integration>,
};

export type IntegrationActions = {
  ...LockActions,
  ...SequenceActions<Integration>,
};

export const useIntegrationReducer = (): [
  IntegrationState,
  IntegrationActions
] => {
  const [integrations, dispatch] = useSequenceReducer(integrationReducer);
  // initially locked, because we need an initial load before it is ready.
  const [locked, lockActions] = useLockState(true);
  const baseActions = React.useMemo(
    () => makeSequenceActions(dispatch),
    [dispatch]
  );
  const actions = React.useMemo(
    () => ({
      ...baseActions,
      ...lockActions,
    }),
    [baseActions, lockActions]
  );
  return [{ integrations, locked }, actions];
};
