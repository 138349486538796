// @flow
import * as React from "react";
import type { Callback } from "../../../types";
import HTMLForm from "../../lib/inputs/form/HTMLForm";
import type {
  BooleanFormFieldDef,
  Form,
  ObjectFormFieldDef,
  StringFormFieldDef,
} from "../../../hooks/useForm";
import Section from "../../layout/Section";
import { Grid } from "@mui/material";
import FormAvatarInput from "../../lib/inputs/form/FormAvatarInput";
import FormTextInput from "../../lib/inputs/form/FormTextInput";
import FormAutocomplete from "../../lib/inputs/form/FormAutocomplete";
import FormColorInput from "../../lib/inputs/form/FormColorInput";
import { timezoneOptions } from "../../../util/timezoneOptions.util";
import useTranslate from "../../../hooks/useTranslate";
import type { TimezoneOption } from "../../../util/timezoneOptions.util";
import type { Portfolio } from "../../../models/portfolio.model";
import { SpaceBetweenRow } from "../../lib/layout/stacks";
import { Body1, Body2 } from "../../lib/display/Text";
import { SmallNeutralButton } from "../../lib/inputs/buttons";
import DownloadIcon from "@mui/icons-material/GetApp";
import FormSwitchField from "../../lib/inputs/form/FormSwitchField";
import CopyActionText from "../../lib/display/CopyActionText";
import * as env from "../../../config/env.config";
import { FormActions } from "../../../hooks/useForm";
import useIsMobile from "../../../hooks/useIsMobile";

export type PortfolioFormDef = {
  name: StringFormFieldDef,
  avatar: StringFormFieldDef,
  timezone: ObjectFormFieldDef,
  description: StringFormFieldDef,
  color: StringFormFieldDef,
  is_calendar_public: BooleanFormFieldDef,
};

type Props = {
  portfolio: ?Portfolio,
  form: Form<PortfolioFormDef>,
  onConfirm: Callback,
  onDelete?: Callback,
  onCancel: Callback,
  onClickICalButton: ?Callback,
};

const PortfolioWidget: React.ComponentType<Props> = ({
  portfolio,
  form,
  onConfirm,
  onCancel,
  onClickICalButton,
  onDelete,
}) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  return (
    <HTMLForm
      onSubmit={onConfirm}
      onCancel={onCancel}
      submitTextId="global.save"
      onDelete={onDelete}
      cancelTextId="global.dropChanges"
      confirmDeleteOptions={{
        DialogProps: {
          title: "PortfolioForm.confirmDelete.title",
          message: "PortfolioForm.confirmDelete.message",
          name: "delete-portfolio",
        },
      }}
    >
      <Section title="global.general">
        <Grid container spacing={2}>
          <Grid item xs={12} sm="auto">
            <FormAvatarInput
              formField={form.state.avatar}
              onChange={FormActions.onChangeField(form.set, "avatar")}
              size={144}
              variant="rounded"
            />
          </Grid>
          <Grid item xs>
            <FormTextInput
              required
              autoFocus={!isMobile}
              formField={form.state.name}
              onChange={FormActions.onChangeField(form.set, "name")}
              label={t("global.name")}
              fullWidth
            />
            <FormTextInput
              formField={form.state.description}
              onChange={FormActions.onChangeField(form.set, "description")}
              label={t("global.description")}
              multiline
              fullWidth
              rows={3}
              margin="dense"
            />
          </Grid>
        </Grid>
      </Section>
      <Section title="global.preferences">
        <FormAutocomplete
          formField={form.state.timezone}
          onChange={FormActions.onChangeField(form.set, "timezone")}
          options={timezoneOptions}
          optionPrimaryLabel="shortLabel"
          optionSecondaryLabel="label"
          TextFieldProps={{
            label: t("global.timezone"),
            helperText: t("PortfolioForm.timezoneHelperText"),
          }}
          groupBy={(opt: TimezoneOption) => opt.group}
        />
        <FormColorInput
          formField={form.state.color}
          onChange={FormActions.onChangeField(form.set, "color")}
          label={t("global.color")}
        />
      </Section>
      {portfolio && (
        <Section title="PortfolioForm.titleCalendar">
          <SpaceBetweenRow>
            <Body1>{t("PortfolioForm.downloadICSFile")}</Body1>
            <SmallNeutralButton onClick={onClickICalButton}>
              <DownloadIcon fontSize="small" /> {t("global.download")}
            </SmallNeutralButton>
          </SpaceBetweenRow>
          <FormSwitchField
            label={t("PortfolioForm.makePortfolioPublic")}
            formField={form.state.is_calendar_public}
            onChange={FormActions.onChangeField(form.set, "is_calendar_public")}
          >
            <Body2 color="textSecondary" paragraph>
              {t("PortfolioForm.makePortfolioPublicWarning")}
            </Body2>
            <CopyActionText disabled={!form.state.is_calendar_public.value}>
              {`${env.API_URL}/portfolio/${portfolio.public_hash ?? ""}/ical`}
            </CopyActionText>
          </FormSwitchField>
        </Section>
      )}
    </HTMLForm>
  );
};

export default PortfolioWidget;
