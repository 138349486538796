// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import MetamorphWidget from "../../../components/widgets/MetamorphWidget";

const MetamorphPage: React.ComponentType<empty> = () => {
  return (
    <PrimaryPagePanel route={routes.app.nested.metamorph}>
      <MetamorphWidget />
    </PrimaryPagePanel>
  );
};

export default MetamorphPage;
