// @flow
import * as React from "react";
import CommentsPreview from "./CommentsPreview";
import sortBy from "lodash/sortBy";
import type { PostComment } from "../../../../../models/postComment.model";
import { useParams } from "react-router-dom";
import { useComments } from "../../../../../store/selectors";

const getCommentSortKey = (comment: PostComment) => {
  const id = comment.parent_id ? comment.parent_id : comment.id;
  return id.toString() + "_" + comment.created_at.unix();
};

const CommentsPreviewContainer: React.ComponentType<{}> = () => {
  const { postId } = useParams();
  const allComments = useComments();
  const comments = React.useMemo(
    () => sortBy(allComments[postId] || [], getCommentSortKey),
    [postId, allComments]
  );
  return <CommentsPreview comments={comments} />;
};

export default CommentsPreviewContainer;
