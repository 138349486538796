// @flow
import * as React from "react";
import SettingsSection from "../../../layout/SettingsSection";
import {
  ColumnStack,
  InlineRowStack,
  SpaceBetweenRow,
} from "../../../lib/layout/stacks";
import useTranslate from "../../../../hooks/useTranslate";
import type { Subscription } from "../../../../models/subscription.model";
import { isSubscriptionStateOK } from "../../../../models/subscription.model";
import type { Plan } from "../../../../models/plan.model";
import type { SubscriptionStatus } from "../../../../config/subscriptionStatus.config";
import {
  getSubscriptionStatus,
  INCOMPLETE,
} from "../../../../config/subscriptionStatus.config";
import type { AddOn } from "../../../../models/addOn.model";
import SubscriptionStateAlert from "../../../feedback/SubscriptionStateAlert";
import { Body1, Body2, Subheader } from "../../../lib/display/Text";
import Translate from "../../../lib/display/Translate";
import moment from "moment";
import StatusChip from "../../../lib/display/StatusChip";
import { Alert } from "@mui/material";
import PaidPrice from "../../../display/PaidPrice";
import uniq from "lodash/uniq";
import AddOnItem from "../../../display/AddOnItem";
import Image from "../../../lib/display/Image";
import LocaleDate from "../../../lib/display/LocaleDate";
import type { Callback } from "../../../../types";
import { styled } from "@mui/material/styles";

type Props = {
  subscription: ?Subscription,
  plan: ?Plan,
  status: SubscriptionStatus,
  purchasedAddOns: AddOn[],
  onEditPlan: Callback,
};

/**
 * Return a representation of the next time the customer will be charged.
 * @param subscription
 * @return {JSX.Element|string}
 */
const getNextCharge = (subscription: ?Subscription) => {
  if (!subscription) return "";
  // If late or incomplete, return the name of the status.
  if (!isSubscriptionStateOK(subscription?.status)) {
    return <Translate id={getSubscriptionStatus(subscription.status).name} />;
  }
  // We need to be careful of end of months. moment knows how to handle those
  // when using add("month", n), so let's rely on that.

  const nextChargeDay = moment(subscription.created_at).add(
    // We get the floating difference in months and round it up to the next int.
    Math.ceil(moment().diff(subscription.created_at, "months", true)),
    "months"
  );
  // If the moment is more than a week from now, put it as a plain date.
  return nextChargeDay.diff(moment(), "days") > 7 ? (
    <LocaleDate value={nextChargeDay} />
  ) : (
    nextChargeDay.fromNow()
  );
};

const PurchasedAddOns = styled(ColumnStack)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const YourPlanSection: React.ComponentType<Props> = ({
  subscription,
  plan,
  status,
  purchasedAddOns,
  onEditPlan,
}) => {
  const t = useTranslate();
  const isFrozen = subscription?.is_frozen;
  const nextCharge = React.useMemo(
    () => getNextCharge(subscription),
    [subscription]
  );
  return (
    <>
      <SettingsSection
        title="Page.SettingsSection.SubscriptionTab.currentSubscription"
        loading={!subscription}
        onBeginEdit={onEditPlan}
      >
        <SubscriptionStateAlert />
        {status === INCOMPLETE && (
          <Alert severity="warning">
            <Translate id="Page.SettingsSection.SubscriptionSummary.incompletePaymentMessage" />
          </Alert>
        )}
        <SpaceBetweenRow>
          <InlineRowStack spacing={4}>
            {plan && (
              <div>
                <Subheader color="primary" gutterBottom>
                  {t(plan.name)}
                </Subheader>
                <Image src={plan.thumbnail} alt={plan.name} width={56} />
              </div>
            )}
            <div>
              <Subheader gutterBottom>{t("global.status")}</Subheader>
              {isFrozen ? (
                <StatusChip severity="warning">{t("global.frozen")}</StatusChip>
              ) : (
                <StatusChip
                  severity={status === INCOMPLETE ? "error" : "success"}
                >
                  {t(status.name)}
                </StatusChip>
              )}
            </div>
            <div>
              <Subheader color="textSecondary" gutterBottom>
                <Translate id="Page.SettingsSection.SubscriptionSummary.nextBill" />
              </Subheader>
              <Body1>{nextCharge}</Body1>
            </div>
          </InlineRowStack>
          {subscription && (
            <PaidPrice
              price={subscription.plan.monthly_price}
              currency={subscription.plan.currency}
              suspended={isFrozen}
            />
          )}
        </SpaceBetweenRow>
        <Body2 color="textSecondary">
          <Translate
            id="Page.SettingsSection.SubscriptionSummary.description"
            data={{
              createdAt: subscription?.created_at.format("dddd, MMMM Do YYYY"),
            }}
          />
        </Body2>
        {subscription && purchasedAddOns.length > 0 && (
          <PurchasedAddOns spacing={1}>
            {uniq(purchasedAddOns).map((addOn) => (
              <AddOnItem
                key={addOn.name}
                dense
                addOn={addOn}
                currency={subscription.plan.currency}
              />
            ))}
          </PurchasedAddOns>
        )}
      </SettingsSection>
    </>
  );
};

export default YourPlanSection;
