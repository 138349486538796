// @flow
import type { AsyncAction, AsyncVoidAction } from "./types";
import type {
  DeleteUserParams,
  UpdateUserParams,
} from "./backend.service/profile";
import { API as backend } from "./backend.service";
import * as snacks from "../models/alerts.model";
import { UserToken } from "../lib/session.lib";
import isEmpty from "lodash/isEmpty";
import type { User } from "../models/user.model";
import * as env from "../config/env.config";
import type { Portfolio } from "../models/portfolio.model";
import { getId } from "../models/base.model";
import { isIOS } from "../lib/platform.lib";

export const getCurrentUser: AsyncAction<Promise<User>> = (actions) => () =>
  backend.profile.getCurrentUser().then((user) => {
    actions.profile.load(user);
    return user;
  });

export const requestDeleteCurrentUser = (params: DeleteUserParams): void =>
  backend.profile.requestDeleteCurrentUser(params).then(() => {
    UserToken.unset();
    window.location.href = env.LANDING_URL;
  });

export const updateCurrentUser: AsyncAction<
  Promise<void>,
  User,
  Partial<UpdateUserParams>
> = (actions) => (user, diffs) => {
  if (isEmpty(diffs)) {
    actions.snacks.append(
      snacks.localInfo({ message: "store.settings.noChangeFound" })
    );
    return Promise.resolve();
  }
  actions.profile.lock();
  // The endpoint expects a null value to remove:
  // theme, palette, avatar. (so always provide them).
  // In addition, it expects first name and last name, always.
  const params = {
    theme: user.preferences.theme,
    palette: user.preferences.palette,
    avatar: user.avatar,
    first_name: user.first_name,
    last_name: user.last_name,
    ...diffs,
  };

  if (isIOS) {
    setTimeout(() => {
      // If this is IOS, try telling the app containing this WebView
      // through an intercepted call.
      window.location.href = `bobcaat:setTheme?theme=${params.theme ?? ""}`;
    }, 500);
  }

  return backend.profile
    .updateCurrentUser(params)
    .then(actions.profile.load)
    .then(() =>
      actions.snacks.append(
        snacks.localSuccess({ message: "store.settings.updated" })
      )
    )
    .finally(actions.profile.unlock);
};

export const setPortfoliosDisplayOrder: AsyncVoidAction<{
  portfolios: Portfolio[],
}> =
  (actions) =>
  ({ portfolios }) => {
    // assume this will not fail and pre reshuffle portfolios.
    actions.portfolio.replaceAll(
      portfolios.map((pf, display_order) => ({ ...pf, display_order }))
    );
    return backend.profile
      .updateDashboardLayoutPreferences({
        portfolios_order: portfolios.map(getId),
      })
      .then(actions.profile.load);
  };
