// @flow
import * as React from "react";
import { Chip, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AttachFileRounded } from "@mui/icons-material";
import { SmallTextButton } from "../../../lib/inputs/buttons";
import type { Props as MessageInputProps } from "../MessageInput";
import MessageInput from "../MessageInput";
import type { ValidationError } from "../../../../util/validation.util";
import FilePicker from "../../../lib/inputs/pickers/FilePicker";
import { MBYTE } from "../../../../lib/files.lib";
import useTranslate from "../../../../hooks/useTranslate";
import isEmpty from "lodash/isEmpty";

export type Props = {
  ...MessageInputProps,
  files: File[],
  onSelectFiles: (File[]) => any,
  onDeleteFile: (File) => any,
  fileError?: ?ValidationError,
  onFileError: (?ValidationError) => any,
};

const Attachments = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const AttachmentChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  borderRadius: 3,
  maxWidth: 180,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "& svg": {
    color: "inherit",
  },
}));

const RichMessageInput: React.ComponentType<Props> = ({
  files,
  onSelectFiles,
  onDeleteFile,
  fileError,
  onFileError,
  ...messageInputProps
}) => {
  const t = useTranslate();
  return (
    <MessageInput
      {...messageInputProps}
      actions={
        <FilePicker
          maxFiles={3}
          maxSize={25 * MBYTE}
          onSelect={onSelectFiles}
          onError={onFileError}
        >
          <SmallTextButton>
            <AttachFileRounded />
          </SmallTextButton>
        </FilePicker>
      }
    >
      {!isEmpty(files) && (
        <Attachments>
          {files.map((file) => (
            <AttachmentChip
              key={file.name}
              label={file.name}
              onDelete={() => onDeleteFile(file)}
            />
          ))}
        </Attachments>
      )}
      <FormHelperText error={!!fileError}>
        {fileError ? t(fileError.error, fileError.data) : ""}
      </FormHelperText>
    </MessageInput>
  );
};

export default RichMessageInput;
