// @flow
import * as React from "react";
import YourPlanSection from "../../../../components/widgets/settings/YourPlanSection";
import PromoCodeSection from "../../../../components/widgets/settings/PromoCodeSection";
import BillingInfoSection from "../../../../components/widgets/settings/BillingInfoSection";
import UpdatePaymentDetailsSection from "../../../../components/widgets/settings/UpdatePaymentDetailsSection";
import { useCurrentSubscription } from "../../../../store/selectors";

const SubscriptionTab: React.ComponentType<{}> = () => {
  const subscription = useCurrentSubscription();
  return (
    <>
      <YourPlanSection />
      <BillingInfoSection />
      <UpdatePaymentDetailsSection billingInfo={subscription?.billing_info} />
      <PromoCodeSection />
    </>
  );
};

export default SubscriptionTab;
