// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { isMobileBrowser } from "../../../lib/platform.lib";
import { CircleBox } from "../../lib/layout/boxes";
import { Fade, IconButton, Paper, Popper } from "@mui/material";
import { InlineRowStack } from "../../lib/layout/stacks";
import { Body1, Body2 } from "../../lib/display/Text";
import { CloseRounded } from "@mui/icons-material";
import type { SyntheticEventListener } from "../../../reactTypes";
import type { Callback } from "../../../types";

export type Props = {
  onEditTag: (text: string, value: string) => any,
  activated?: boolean,
  onActivate: SyntheticEventListener,
  onDelete: Callback,
  onDeactivate: SyntheticEventListener,
  x: number,
  y: number,
  text: string,
  value: string,
  prefix: string,
  ActivatedContentComponent: React.ComponentType<any>,
};

const TagDot = styled(CircleBox)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  boxShadow: theme.shadows[4],
  backgroundColor: grey[700],
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: grey[700],
  display: "flex",
  alignItems: "center",
  gap: 4,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(0.5),
  color: theme.palette.common.white,
  [theme.breakpoints.up("sm")]: {
    "& .deleteButton": {
      opacity: 0,
    },
    "&:hover .deleteButton": {
      opacity: 1,
    },
  },
}));

const Arrow = styled("div")({
  visibility: "hidden",
  position: "absolute",
  width: 8,
  height: 8,
  background: grey[700],
  top: -4,
  "&::before": {
    position: "absolute",
    width: 8,
    height: 8,
    background: grey[700],
    visibility: "visible",
    content: "''",
    transform: "rotate(45deg)",
  },
});

const Text = styled(Body2)({
  cursor: "pointer",
  fontWeight: "bold",
});

const Prefix = styled(Body1)({
  opacity: 0.7,
  fontWeight: "bold",
});

const DELETE_BT_CLASS = isMobileBrowser ? undefined : "deleteButton";
const MODIFIERS = [
  {
    name: "arrow",
    options: {
      element: "[data-popper-arrow]",
    },
  },
  {
    name: "offset",
    options: {
      offset: [0, 10],
    },
  },
];
const Tag: React.ComponentType<Props> = ({
  x,
  y,
  prefix,
  text,
  value,
  ActivatedContentComponent,
  activated,
  onEditTag,
  onDelete,
  onActivate,
  onDeactivate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popperOpen, setPopperOpen] = React.useState(false);

  // Poor man's fix. need to wait for the image to be loaded for the popper
  // to position properly.
  React.useEffect(() => {
    setTimeout(() => {
      setPopperOpen(true);
    }, 300);
  }, []);

  return (
    <>
      <TagDot
        ref={setAnchorEl}
        size={10}
        style={{
          left: `calc(${x * 100}% - 7px)`,
          top: `calc(${y * 100}% - 7px)`,
        }}
      />
      <Popper
        open={!!anchorEl && popperOpen}
        anchorEl={anchorEl}
        disablePortal
        modifiers={MODIFIERS}
        placement="bottom"
      >
        <Fade in>
          <div>
            <Arrow data-popper-arrow />
            <StyledPaper>
              {prefix && <Prefix>{prefix}</Prefix>}
              {activated ? (
                <ActivatedContentComponent
                  text={text}
                  value={value}
                  onChange={onEditTag}
                  onDeactivate={onDeactivate}
                />
              ) : (
                <InlineRowStack>
                  <Text onClick={onActivate}>{text}</Text>
                  <IconButton
                    color="inherit"
                    onClick={onDelete}
                    size="small"
                    className={DELETE_BT_CLASS}
                  >
                    <CloseRounded />
                  </IconButton>
                </InlineRowStack>
              )}
            </StyledPaper>
          </div>
        </Fade>
      </Popper>
    </>
  );
};

export default Tag;
