// @flow
import * as React from "react";
import { Stack, ToggleButton } from "@mui/material";
import type { PostStatus } from "../../../models/post.model";
import { ALL_ACTIVE_STATUSES } from "../../../models/post.model";
import PostBuilderContext from "../../../contexts/postBuilder";
import type { Callback } from "../../../types";
import { Body2 } from "../../lib/display/Text";
import { getPostStatusIconAndColor } from "../../display/postStatusIcons";
import { styled } from "@mui/material/styles";
import useTranslate from "../../../hooks/useTranslate";

type StatusButtonProps = {
  status: PostStatus,
  onSelect: Callback,
  selected?: boolean,
  variant?: ?"quick",
};

const StatusButtonRoot = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== "quick",
})(({ theme, quick }) => ({
  flexDirection: "column",
  gap: theme.spacing(1),
  border: "none",
  minWidth: quick ? 50 : 100,
}));

const StatusButton: React.ComponentType<StatusButtonProps> = ({
  status,
  onSelect,
  selected,
  variant,
}) => {
  const [Icon, color] = getPostStatusIconAndColor(status);
  const quick = variant === "quick";
  const t = useTranslate();
  return (
    <StatusButtonRoot
      value={status}
      selected={selected}
      quick={quick}
      onClick={onSelect}
    >
      <Icon
        color={selected ? color : "action"}
        fontSize={quick ? "medium" : "large"}
      />
      {!quick && (
        <Body2 fontWeight="bold">
          {t(`PostCreator.statusButtons.${status}`)}
        </Body2>
      )}
    </StatusButtonRoot>
  );
};
const PostStatusInput: React.ComponentType<Pick<StatusButtonProps, "variant">> =
  ({ variant }) => {
    const {
      post: { status: postStatus },
      alreadyPublished,
      onUpdatePost,
    } = React.useContext(PostBuilderContext);

    return (
      !alreadyPublished && (
        <Stack direction="row" justifyContent="space-between">
          {ALL_ACTIVE_STATUSES.map((status) => (
            <StatusButton
              variant={variant}
              key={status}
              status={status}
              onSelect={() => onUpdatePost({ status })}
              selected={status === postStatus}
            />
          ))}
        </Stack>
      )
    );
  };

export default PostStatusInput;
