// @flow
import some from "lodash/some";
import words from "lodash/words";
import defer from "lodash/defer";

/**
 * Return an iteratee that checks whether some string is contained in
 * another string.
 * @param {string} text
 * @return {function(string): boolean}
 */
const matchWord =
  (text: string) =>
  (word: string): boolean =>
    !!text.match(RegExp(word, "gi"));

/**
 *
 * @param {string} text
 * @param {string} query
 * @return {boolean}
 */
export const matchQuery = (text: string, query: string): boolean =>
  some(words(query), matchWord(text));

/**
 * Insert the specified text at the current position of an
 * input element. The caret is then moved after the inserted content.
 * @param {HTMLInputElement} target The input element.
 * @param {string} content The content to insert.
 * @return {string}
 */
export const insertAtCursorPos = (
  target: HTMLInputElement,
  content: string
): string => {
  const caret = target.selectionStart;
  const message = target.value;
  defer(() => (target.selectionStart = caret + content.length));
  return message.slice(0, caret) + content + message.slice(caret);
};
