// @flow
import * as React from "react";
import type { User } from "../../models/user.model";
import APISearch from "./APISearch";
import type { Props as AutocompleteProps } from "../lib/inputs/Autocomplete";
import useTranslate from "../../hooks/useTranslate";
import { UserAdornment } from "../display/Adornments";

type Props = {
  ...Omit<
    AutocompleteProps<User>,
    "options" | "optionPrimaryLabel" | "optionSecondaryLabel"
  >,
};

const SearchUser: React.ComponentType<Props> = ({
  TextFieldProps,
  ...props
}) => {
  const t = useTranslate();

  return (
    <APISearch
      endpoint="misc/users"
      optionsAttribute="users"
      TextFieldProps={{
        ...TextFieldProps,
        label: t("SearchUser.label"),
        helperText: t("SearchUser.helperText"),
      }}
      AdornmentComponent={UserAdornment}
      optionPrimaryLabel="last_name"
      optionSecondaryLabel="first_name"
      adornOptions
      {...props}
    />
  );
};

export default SearchUser;
