// @flow
import type { StringDict } from "../types";

/**
 * Gets a query parameter from the a URL.
 * If the url is not specified, the current document location is used.
 * @param param {string} The name of the param to fetch.
 * @param defaults {string} The value to return if the param is not found.
 */
export const getURLParam = (param: string, defaults?: string = ""): string =>
  new URL(document.location.href).searchParams.get(param) ?? defaults;

/**
 * Extract all parameters from the provided search part of the location
 * and returns them as an object.
 * @param search {string}
 */
export const getAllURLParamsFromSearch = (search: string): StringDict<string> =>
  Object.fromEntries(new URLSearchParams(search).entries());

/**
 * Creates a new URL from provided base and params.
 */
export const makeURL = (base: string, query: StringDict<string>): URL => {
  const url = new URL(base);
  url.search = new URLSearchParams(query).toString();
  return url;
};

/**
 * Creates a new URL path from provided base and params.
 */
export const makeURLPath = (base: string, query: StringDict<string>): string =>
  `${base}?${new URLSearchParams(query).toString()}`;
