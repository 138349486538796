// @flow
import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { Callback, I18nKey } from "../../../types";
import type { Children } from "../../../reactTypes";

type Props = {
  pulse?: boolean,
  title?: I18nKey,
  onClick?: Callback,
  children?: Children,
};

export const StyledButton: typeof IconButton = styled(IconButton, {
  shouldForwardProp: (prop) => !["pulse"].includes(prop),
})(({ theme, pulse }) => ({
  animation: pulse ? theme.animations.pulseError + " 2s" : undefined,
  color: "inherit",
  backgroundColor: theme.palette.divider,
}));

const AccountButton: React.ComponentType<Props> = React.memo(
  ({ title, ...props }) => (
    <Tooltip title={title}>
      <StyledButton variant="contained" {...props} />
    </Tooltip>
  )
);

export default AccountButton;
