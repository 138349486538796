// @flow
import type { ApprovalRequest } from "../models/approvalRequest.model";
import routes from "../config/routes.config";
import { UserToken } from "../lib/session.lib";
import * as env from "../config/env.config";

const appPath = (path?: string) => `${env.APP_URL}/${path ?? ""}`;
const landingPath = (path?: string) => `${env.LANDING_URL}/${path ?? ""}`;

export const approvalRequest = (approvalRequest: ApprovalRequest): string =>
  appPath(routes.approval.path?.replace(":hashId", approvalRequest.hash_id));

export const signup: string = landingPath("?action=signup");
export const contact = (topic: string): string =>
  landingPath(`contact?topic=${topic}&t=${UserToken.get() ?? ""}`);

export const login: string = appPath("login");
