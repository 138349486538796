// @flow
import deburr from "lodash/deburr";
import { EMAIL_REGEX, REGEX_URL } from "../lib/patterns.lib";

export type ValidationError = {
  error: string,
  field?: string,
  data?: Object,
};

type AtomicValidator = (any) => ?ValidationError;
export type Validator = (any) => ValidationError[];

const error = (
  field: string,
  error: string,
  data?: Object
): ValidationError => ({
  field,
  error,
  data,
});

const notEmpty = (field: string) => (value: string) =>
  value.length <= 0 ? error(field, "cannotBeEmpty") : undefined;

const match = (field: string, pattern: string | RegExp) => (value: string) =>
  !value.match(pattern) ? error(field, "isInvalid") : undefined;

const atMaxLength = (field: string, max: number) => (value: string) =>
  value.length > max ? error(field, "isTooLong", { max }) : undefined;

const atLeastLength = (field: string, min: number) => (value: string) =>
  value.length < min ? error(field, "isTooShort", { min }) : undefined;

const hasAlpha = (field: string) => (value: string) =>
  !deburr(value).match(/[a-z]+/i) ? error(field, "hasNoAlpha") : undefined;

const hasDigit = (field: string) => (value: string) =>
  !deburr(value).match(/[0-9]+/i) ? error(field, "hasNoDigit") : undefined;

const hasVariedCase = (field: string) => (value: string) =>
  value.toLowerCase() === value ? error(field, "hasMonotonousCase") : undefined;

const compose =
  (...tests: AtomicValidator[]): ((any) => ValidationError[]) =>
  (value: any) =>
    tests.map((test) => test(value)).filter(Boolean); // filter no errors.

export const checkEmail: Validator = compose(
  notEmpty("email"),
  match("email", EMAIL_REGEX)
);

export const checkPassword: Validator = compose(notEmpty("password"));

export const checkWebsiteURL: Validator = compose(
  match("websiteURL", REGEX_URL)
);

export const checkPortfolioName: Validator = compose(
  notEmpty("portfolioName"),
  atMaxLength("portfolioName", 50)
);

export const checkPortfolioDescription: Validator = compose(
  atMaxLength("portfolioDescription", 300)
);

export const checkBugTitle: Validator = compose(
  notEmpty("bugTitle"),
  atMaxLength("bugTitle", 100)
);

export const checkBugDescription: Validator = compose(
  notEmpty("bugDescription"),
  atMaxLength("bugDescription", 2048)
);

export const checkInviteFriendName: Validator = compose(
  notEmpty("friendName"),
  atMaxLength("friendName", 100)
);

export const checkInviteFriendEmail: Validator = compose(
  notEmpty("friendEmail"),
  atMaxLength("friendEmail", 100),
  match("friendEmail", EMAIL_REGEX)
);

export const checkInviteFriendMessage: Validator = compose(
  atMaxLength("friendMessage", 2048)
);

export const checkHashtagGroupName: Validator = compose(
  notEmpty("hashtagGroupName"),
  atMaxLength("hashtagGroupName", 50)
);

export const checkApprovalRequestTitle: Validator = compose(
  notEmpty("approvalRequestTitle"),
  atMaxLength("approvalRequestTitle", 100)
);

export const checkFreezeOrDeleteReason: Validator = compose(
  notEmpty("freezeOrDeleteReason")
);

export const checkCreatePassword: Validator = compose(
  notEmpty("password"),
  hasDigit("password"),
  hasAlpha("password"),
  hasVariedCase("password"),
  atLeastLength("password", 8),
  atMaxLength("password", 32)
);

export const checkChildAccountFirstName: Validator = compose(
  notEmpty("firstName"),
  atMaxLength("firstName", 64)
);

export const checkChildAccountLastName: Validator = compose(
  notEmpty("lastName"),
  atMaxLength("lastName", 64)
);

export const checkPromoCode: Validator = compose(notEmpty("promoCode"));
