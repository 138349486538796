// @flow
import * as React from "react";
import {
  Dialog as DialogImpl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide,
} from "@mui/material";
import type { Breakpoint } from "../../../stubs/mui/theming";
import type { Children } from "../../../reactTypes";
import type { Callback } from "../../../types";
import { UncontrolledSwipeableEdgeDrawer } from "../navigation/SwipeableEdgeDrawer";
import { styled } from "@mui/material/styles";
import useIsMobile from "../../../hooks/useIsMobile";

export type Props = {
  scroll?: "body" | "paper",
  open?: boolean,
  maxWidth?: Breakpoint,
  title?: React.Node,
  text?: React.Node,
  children?: Children,
  name: string,

  fullScreen?: boolean,
  onClose?: Callback,
  actions?: React.Node,
  dividers?: boolean,
  PaperComponent?: React.ComponentType<any>,
  PaperProps?: React.ElementProps<typeof Paper>,

  disableEscapeKeyDown?: boolean,
  drawerOnMobile?: boolean,
};

const StyledDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== "drawerOnMobile",
})(({ theme, drawerOnMobile }) => ({
  [theme.breakpoints.down("sm")]: {
    backgroundColor: drawerOnMobile
      ? theme.palette.background.default
      : undefined,
  },
}));

const Dialog: React.ComponentType<Props> = ({
  scroll = "body",
  open = true,
  maxWidth = "sm",
  title,
  text,
  children,
  name,
  actions,
  dividers,
  drawerOnMobile,
  ...props
}) => {
  const ariaTitle = `${name}-title`;
  const ariaDescription = `${name}-title`;
  const isMobile = useIsMobile();

  const content = (
    <>
      {title && <DialogTitle id={ariaTitle}>{title}</DialogTitle>}
      <StyledDialogContent drawerOnMobile={drawerOnMobile} dividers={dividers}>
        {text && (
          <DialogContentText id={ariaDescription}>{text}</DialogContentText>
        )}
        {children}
      </StyledDialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </>
  );

  return drawerOnMobile && isMobile && open ? (
    <UncontrolledSwipeableEdgeDrawer color="default" onClose={props.onClose}>
      {content}
    </UncontrolledSwipeableEdgeDrawer>
  ) : (
    <DialogImpl
      open={open}
      fullWidth
      maxWidth={maxWidth}
      scroll={scroll}
      aria-labelledby={ariaTitle}
      aria-describedby={ariaDescription}
      TransitionComponent={props.fullScreen ? Slide : undefined}
      TransitionProps={props.fullScreen ? { direction: "up" } : undefined}
      {...props}
    >
      {content}
    </DialogImpl>
  );
};

export default Dialog;
