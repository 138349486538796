// @flow
import { API as backend } from "./backend.service";
import * as alerts from "../models/alerts.model";
import type { AsyncAction, AsyncVoidAction } from "./types";
import type { ModelID } from "../types";
import * as links from "../util/links.util";
import type {
  AddApprovalRequestParams,
  UpdateApprovalRequestParams,
} from "./backend.service/approval";
import { UserToken } from "../lib/session.lib";
import type { PostApproval } from "../models/postApproval.model";

export const addApprovalRequest: AsyncAction<
  Promise<void>,
  AddApprovalRequestParams
> = (actions) => (approvalRequest) => {
  actions.approvalRequest.lock();
  return backend.approval
    .addApprovalRequest(approvalRequest)
    .then((resultApprovalRequest) => {
      actions.approvalRequest.approvalRequests.append(resultApprovalRequest);
      actions.global.openModalAlert(
        alerts.modalLocalSuccess({
          title: "store.approvalRequest.created.title",
          message: "store.approvalRequest.created.message",
          messageData: {
            url: links.approvalRequest(resultApprovalRequest),
            password: resultApprovalRequest.password,
          },
        })
      );
    })
    .finally(actions.approvalRequest.unlock);
};

export const editApprovalRequest: AsyncAction<
  Promise<void>,
  UpdateApprovalRequestParams
> = (actions) => (approvalRequest) => {
  actions.approvalRequest.lock();
  return backend.approval
    .editApprovalRequest(approvalRequest)
    .then(actions.approvalRequest.approvalRequests.replace)
    .then(() => {
      actions.snacks.append(
        alerts.localSuccess({ message: "store.approvalRequest.updated" })
      );
    })
    .finally(actions.approvalRequest.unlock);
};

export const getApprovalRequests: AsyncVoidAction<void> = (actions) => () => {
  actions.approvalRequest.lock();
  return backend.approval
    .getApprovalRequests()
    .then(actions.approvalRequest.approvalRequests.replaceAll)
    .finally(actions.approvalRequest.unlock);
};

export const deleteApprovalRequest: AsyncAction<Promise<void>, ModelID> =
  (actions) => (approvalRequestId) =>
    backend.approval.deleteApprovalRequest(approvalRequestId).then(() => {
      actions.approvalRequest.approvalRequests.remove(approvalRequestId);
      actions.snacks.append(
        alerts.localSuccess({ message: "store.approvalRequest.deleted" })
      );
    });

export const updatePostApproval: AsyncAction<
  Promise<PostApproval>,
  PostApproval
> = (actions) => (postApproval) =>
  backend.approval.updatePostApproval(postApproval).then((result) => {
    actions.snacks.append(
      alerts.localSuccess({ message: "global.postApprovalUpdated" })
    );
    return result;
  });

export const commitApprovalRequest: AsyncAction<Promise<void>, ModelID> =
  (actions) => (approvalRequestId) =>
    backend.approval.commitApprovalRequest(approvalRequestId).then(() => {
      actions.snacks.append(
        alerts.localSuccess({
          message: UserToken.isValid()
            ? "store.approvalRequest.reset"
            : "store.approvalRequest.feedbackSent",
        })
      );
    });
