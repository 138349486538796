// @flow
import * as React from "react";
import { useUnreadNotificationsCount } from "../../../store/selectors";

const useTitleUpdater = () => {
  const unreadNotificationCount = useUnreadNotificationsCount();

  React.useEffect(() => {
    document.title = unreadNotificationCount
      ? `(${unreadNotificationCount}) bobcaat`
      : "bobcaat";
  }, [unreadNotificationCount]);
};

export default useTitleUpdater;
