// @flow
import * as React from "react";
import { Paper } from "@mui/material";
import StdListItem from "../lib/display/listItems";
import type { BobcaatAccountPublicInfo } from "../../models/account.model";
import type { Post } from "../../models/post.model";
import ChannelIcon from "../lib/display/icons/ChannelIcon";
import LocaleDateTime from "../lib/display/LocaleDateTime";
import { getTime } from "../../models/post.model";
import { Body1 } from "../lib/display/Text";
import { styled } from "@mui/material/styles";
import PostMediaPreview from "./PostMediaPreview";
import type { Inexact } from "../../types";
import type { Children, CSSProps } from "../../reactTypes";
import { NO_XS_HORIZ_MARGIN } from "../layout/PagePanel";

type Props = {
  account: Inexact<BobcaatAccountPublicInfo>,
  post: Post,
  children?: Children,
  ...CSSProps,
};

const PostContent = styled(Body1)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const Root = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    borderRadius: 0,
  },
}));

const PostCardHeader = styled(StdListItem)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const PostCard: React.ComponentType<Props> = ({
  account,
  post,
  children,
  className,
  ...props
}) => (
  <Root
    id={"post-" + post.id.toString()}
    className={NO_XS_HORIZ_MARGIN}
    {...props}
  >
    <PostCardHeader
      icon={<ChannelIcon channel={account.channel} color="channel" />}
      primary={account.entity_name}
      secondary={<LocaleDateTime value={getTime(post)} />}
    />
    <PostContent>{post.content}</PostContent>
    <PostMediaPreview post={post} />
    {children}
  </Root>
);

export default PostCard;
