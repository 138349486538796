// @flow
import * as React from "react";
import SubscriptionActionRequiredDialog from "./SubscriptionActionRequiredDialog";
import LimitExceededActionRequiredDialog from "./LimitExceededActionRequiredDialog";

const ActionRequiredDialog: React.ComponentType<{}> = () => (
  <>
    <LimitExceededActionRequiredDialog />
    <SubscriptionActionRequiredDialog />
  </>
);

export default ActionRequiredDialog;
