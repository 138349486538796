// @flow
import * as React from "react";
import type { Moment } from "../../../types";
import { toDateString } from "../../../lib/time.lib";

type Props = {
  value: ?Moment,
  linebreak?: boolean,
};

const LocaleDateTime: React.ComponentType<Props> = ({ value, linebreak }) => (
  <>
    {toDateString(value)}
    {linebreak ? <br /> : " "}
    {value?.format("LT") ?? ""}
  </>
);

export default LocaleDateTime;
