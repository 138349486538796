// @flow
import * as React from "react";
import * as Sentry from "@sentry/react";
import type { User } from "../../../models/user.model";
import { getFullName } from "../../../models/user.model";
import { updateLanguage } from "../../../localization";

const setSentryScope = (user: User) => () =>
  Sentry.configureScope(function (scope) {
    scope.setTag("locale", user.preferences.locale);
    scope.setUser({
      username: getFullName(user),
      email: user.email,
    });
  });

const useAppSetupForUser = (user: ?User) => {
  React.useEffect(() => {
    if (user) {
      updateLanguage(user.preferences.locale?.toLowerCase()).then(
        setSentryScope(user)
      );
    }
  }, [user]);
};

export default useAppSetupForUser;
