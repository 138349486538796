// @flow
import * as React from "react";
import { Portal } from "@mui/material";
import { PAGE_ROOT_ID } from "../../config/identifiers.config";
import { Outlet } from "react-router-dom";

const getContainer = () => document.getElementById(PAGE_ROOT_ID);

/**
 * Represents an Outlet that is appended at the end of the page root,
 * breaking the usual flow of render.
 *
 * @component
 * @returns {React.ReactNode} Returns the rendered portal element.
 */
const PageRootOutlet: React.ComponentType<{}> = () => (
  <Portal container={getContainer}>
    <Outlet />
  </Portal>
);

export default PageRootOutlet;
