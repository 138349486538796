// @flow
import * as React from "react";
import type { ThemeColor } from "../../../stubs/mui/theming";
import { ListItemIcon, ListItemText, MenuItem, useTheme } from "@mui/material";
import type { Children } from "../../../reactTypes";
import type { TextProps } from "../display/Text";

export type Props = {
  primary: React.Node,
  secondary?: React.Node,
  Icon?: React.ComponentType<{ style?: Object }>,
  iconColor?: ThemeColor | string,
  onClick?: (SyntheticEvent<>) => any,
  children?: Children,
  primaryProps?: Partial<TextProps>,
  secondaryProps?: Partial<TextProps>,
  style?: Object,
};

const MenuListItem: React.ComponentType<Props> = ({
  primary,
  secondary,
  Icon,
  iconColor,
  children,
  primaryProps,
  secondaryProps,
  ...props
}) => {
  const theme = useTheme();

  return (
    <MenuItem {...props}>
      {Icon && (
        <ListItemIcon>
          <Icon style={{ color: theme.palette[iconColor]?.main }} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={primaryProps}
        secondaryTypographyProps={secondaryProps}
      />
      {children}
    </MenuItem>
  );
};

export default MenuListItem;
