// @flow
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import { styled } from "@mui/material/styles";
import { ListItemAvatar, Paper } from "@mui/material";
import { getContrastColor } from "../../../util/colors.util";
import { Body2, Caption } from "../../lib/display/Text";
import Avatar from "../../lib/display/Avatar";
import { PersonRounded } from "@mui/icons-material";
import type { Moment } from "../../../types";
import type { Children, CSSProps } from "../../../reactTypes";
import type { Theme } from "../../../stubs/mui/theming";
import Translate from "../../lib/display/Translate";
import { FlexGrow } from "../../lib/layout/boxes";

const Root = styled("div", {
  shouldForwardProp: (prop) => !["indent", "reverse"].includes(prop),
})(({ theme, reverse, indent }) => ({
  display: "flex",
  flexDirection: reverse ? "row-reverse" : "row",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  paddingLeft: theme.spacing(indent * 8),
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(indent * 4),
  },
}));

const MessageAndChildrenContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "conversational",
})(({ conversational }) => ({
  width: conversational ? "80%" : "100%",
}));

const messageMixin = (theme: Theme) => ({
  padding: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  borderRadius: 12,
  lineHeight: "1.5rem",
  display: "flex",
  flexGrow: 1,
});

const DeletedMessage = styled(Paper)(({ theme }) => ({
  ...messageMixin(theme),
  border: `solid 1px ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.background.paper,
}));

const Message = styled("div", {
  shouldForwardProp: (prop) => !["fromUser", "color"].includes(prop),
})(({ theme, fromUser, color }) => ({
  ...messageMixin(theme),
  backgroundColor: fromUser ? color : theme.palette.grey[200],
  color: fromUser ? getContrastColor(color) : theme.palette.grey[700],
}));

const ItemAvatar = styled(ListItemAvatar)({
  minWidth: 0,
});

const Content = styled(Body2)(({ theme }) => ({
  marginTop: theme.spacing(1),
  whiteSpace: "pre-wrap",
  overflowX: "hidden",
}));

const PERSON_AVATAR = (
  <Avatar bgColor="default">
    <PersonRounded />
  </Avatar>
);

const DELETED_BLOCK = (
  <DeletedMessage variant="outlined">
    <Body2 color="textSecondary">
      <Translate id="global.contentDeleted" />
    </Body2>
  </DeletedMessage>
);

export type Props = {
  indent?: number,
  authorName?: string,
  content?: string,
  avatar?: React.Node,
  fromUser?: boolean,
  color?: string,
  reverse?: boolean,
  deleted?: boolean,
  createdAt: Moment,
  conversational?: boolean,
  children?: Children,
  secondaryAction?: React.Node,
  ...CSSProps,
};

const MessageLayout: React.ComponentType<Props> = ({
  indent,
  authorName,
  content,
  avatar,
  fromUser,
  color,
  createdAt,
  reverse,
  conversational,
  deleted,
  children,
  secondaryAction,
  className,
}) => (
  <Root reverse={reverse} indent={indent}>
    {avatar !== null && <ItemAvatar>{avatar || PERSON_AVATAR}</ItemAvatar>}
    {deleted && DELETED_BLOCK}
    <MessageAndChildrenContainer conversational={conversational}>
      {!isEmpty(content) && (
        <Message className={className} fromUser={fromUser} color={color}>
          <FlexGrow>
            {authorName && (
              <Body2 fontWeight="bold" display="inline">
                {authorName}{" "}
              </Body2>
            )}
            <Caption>{createdAt.fromNow()}</Caption>
            <Content>{content}</Content>
          </FlexGrow>
          <div>{secondaryAction}</div>
        </Message>
      )}
      {!deleted && children}
    </MessageAndChildrenContainer>
  </Root>
);

export default MessageLayout;
