// @flow
import * as React from "react";
import { EngagementHubContextProvider } from "../../../contexts/engagementHub";
import EngagementHubAccounts from "../../../components/widgets/EngagementHubAccounts";
import {
  NO_XS_HORIZ_MARGIN,
  PrimaryPagePanel,
} from "../../../components/layout/PagePanel";
import routes from "../../../config/routes.config";
import { Outlet } from "react-router-dom";
import { ResponsiveInlineRowStack } from "../../../components/lib/layout/stacks";
import useLocationParams from "../../../hooks/useLocationParams";
import { useScopedPortfolio } from "../../../scopes/scopes";

const EngagementHubPage: React.ComponentType<{}> = () => {
  const route = routes.app.nested.portfolios.nested.engagement;
  const pf = useScopedPortfolio();
  const { unreadItemsOnly } = useLocationParams(["unreadItemsOnly"]);
  if (!pf) return null;

  return (
    <>
      <PrimaryPagePanel
        size="large"
        align="flex-start"
        route={route}
        subtitleData={{ portfolio: pf.name }}
      >
        <EngagementHubContextProvider
          initial={{
            unreadItemsOnly: Boolean(unreadItemsOnly),
          }}
        >
          <ResponsiveInlineRowStack
            alignItems="stretch"
            flexGrow={1}
            className={NO_XS_HORIZ_MARGIN}
          >
            <EngagementHubAccounts />
            <Outlet />
          </ResponsiveInlineRowStack>
        </EngagementHubContextProvider>
      </PrimaryPagePanel>
    </>
  );
};

export default EngagementHubPage;
