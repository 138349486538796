// @flow
import * as React from "react";
import * as Sentry from "@sentry/react";

import { useFilesValidator } from "../../../../hooks/useFilesValidator";
import type { Props as FilesValidatorProps } from "../../../../hooks/useFilesValidator";
import { ensureArray } from "../../../../lib/lodashex.lib";
import type { OneOrMore } from "../../../../types";
import noop from "lodash/noop";

export type Props = {
  ...FilesValidatorProps,
  disabled?: boolean,
  onSelect?: (File[]) => void,
  children: React.Element<any>,
};

const FilePicker: React.ComponentType<Props> = ({
  disabled,
  children,
  onSelect = noop,
  ...validatorProps
}) => {
  const inputRef = React.useRef<?HTMLInputElement>(null);
  const handleValidate = useFilesValidator(validatorProps);
  const handleSelect = React.useCallback(
    (fileOrFiles: OneOrMore<File>) =>
      handleValidate(ensureArray(fileOrFiles)).then(onSelect),
    [handleValidate, onSelect]
  );

  const handleClick = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  }, [inputRef]);

  const handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const files = [...e.currentTarget.files];
    if (files.length > 0) {
      handleSelect(files).catch(Sentry.captureException);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={inputRef}
        multiple={validatorProps.maxFiles && validatorProps.maxFiles > 1}
        accept={validatorProps.accept?.join(",") ?? "*"}
        style={{ display: "none" }}
        onChange={handleInputChange}
        disabled={disabled}
      />
      {disabled
        ? children
        : React.cloneElement(children, { onClick: handleClick })}
    </>
  );
};

export default FilePicker;
