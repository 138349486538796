// @flow
import * as React from "react";
import type { Props as MessageLayoutProps } from "../../../MessageLayout";
import MessageLayout from "../../../MessageLayout";
import MessageAttachments from "./MessageAttachments";
import type { Portfolio } from "../../../../../../models/portfolio.model";
import type { Message as MessageType } from "../../../../../../models/message.model";
import useMessageColor from "../../../useMessageColor";
import type { Conversation } from "../../../../../../models/conversation.model";
import { getInterlocutor } from "../../../../../../models/conversation.model";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import {
  ConversationAvatar,
  PortfolioAvatar,
} from "../../../../../display/avatars";
import { styled } from "@mui/material/styles";
import { Caption } from "../../../../../lib/display/Text";

type Props = {
  portfolio: Portfolio,
  conversation: Conversation,
  message: MessageType,
  repliedTo?: boolean,
  ...Pick<MessageLayoutProps, "fromUser" | "children" | "secondaryAction">,
};

const StyledMessageAttachments = styled(MessageAttachments, {
  shouldForwardProp: (prop) => prop !== "fromUser",
})(({ fromUser }) => ({
  display: "flex",
  flexDirection: fromUser ? "row-reverse" : "row",
}));

const TimeTag = styled(Caption, {
  shouldForwardProp: (prop) => prop !== "fromUser",
})(({ theme, fromUser }) => ({
  display: "flex",
  flexDirection: fromUser ? "row-reverse" : "row",
  margin: theme.spacing(0.5),
}));

const Message: React.ComponentType<Props> = ({
  portfolio,
  fromUser,
  message,
  conversation,
  repliedTo,
}) => {
  const isMobile = useIsMobile();
  const color = useMessageColor(portfolio);
  const interlocutor = getInterlocutor(conversation, message.native_sender_id);
  return (
    <MessageLayout
      createdAt={message.created_at}
      indent={repliedTo ? 2 : 0}
      avatar={
        repliedTo ? null : fromUser ? (
          <PortfolioAvatar size={isMobile ? 32 : 48} portfolio={portfolio} />
        ) : (
          <ConversationAvatar
            size={isMobile ? 32 : 48}
            conversation={conversation}
          />
        )
      }
      fromUser={fromUser}
      deleted={message.deleted}
      reverse={fromUser}
      color={color}
      content={message.text}
      conversational
    >
      {!repliedTo && message.attachments.length > 0 && (
        <>
          <StyledMessageAttachments
            fromUser={fromUser}
            attachments={message.attachments}
            interlocutorName={interlocutor?.name}
          />
          <TimeTag fromUser={fromUser} color="textSecondary">
            {message.created_at.fromNow()}
          </TimeTag>
        </>
      )}
    </MessageLayout>
  );
};

export default Message;
