// @flow
import * as React from "react";
import type { ExportAnalyticsFormDef } from "./ExportAnalyticsDialog";
import ExportAnalyticsDialog from "./ExportAnalyticsDialog";
import { useStoreActions } from "../../../store/store";
import {
  useAnalyticsLocked,
  usePortfolioAccounts,
} from "../../../store/selectors";
import { FormActions, useForm } from "../../../hooks/useForm";
import { useScopedPortfolio } from "../../../scopes/scopes";
import { WEEK, yesterday } from "../../inputs/DateRangeSelect/periods";
import moment from "moment/moment";
import type { Callback } from "../../../types";
import * as analyticsService from "../../../services/analytics.service";
import type { ExportFormat } from "../../../services/backend.service/analytics";
import useUpgradeRequiredDialog from "../../feedback/useUpgradeRequiredDialog";

type Props = {
  open?: boolean,
  onClose: Callback,
};

const FORM_FIELDS: ExportAnalyticsFormDef = {
  fromDate: { type: "datetime" },
  toDate: { type: "datetime" },
  accounts: { type: "number[]" },
};

const ExportAnalyticsDialogContainer: React.ComponentType<Props> = ({
  open,
  onClose,
}) => {
  const pf = useScopedPortfolio();
  const actions = useStoreActions();
  const form = useForm(FORM_FIELDS, {
    accounts: [],
    fromDate: moment().startOf(WEEK.value).add(-1, WEEK.value),
    toDate: yesterday(),
  });
  const accounts = usePortfolioAccounts(pf?.id);
  const loading = useAnalyticsLocked();

  const handleExport = (format: ExportFormat) => () => {
    if (FormActions.validate(form)) {
      const payload = { ...FormActions.collect(form.state) };
      analyticsService.exportAnalytics(actions)({
        portfolioId: pf?.id ?? 0,
        accountIds: payload.accounts,
        start: payload.fromDate,
        end: payload.toDate,
        format,
        onSuccess: onClose,
      });
    }
  };

  const [onRequestExport, upgradeRequiredDialog] = useUpgradeRequiredDialog(
    handleExport,
    "can_use_analytics_export_basic"
  );

  return (
    <>
      {upgradeRequiredDialog}
      <ExportAnalyticsDialog
        loading={loading}
        open={open}
        onClose={onClose}
        accounts={accounts}
        form={form}
        onExportAsCSV={onRequestExport("csv")}
        onExportAsExcel={onRequestExport("xlsx")}
      />
    </>
  );
};

export default ExportAnalyticsDialogContainer;
