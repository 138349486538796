// @flow
import { GBYTE, MBYTE } from "../../lib/files.lib";
import type { Channel } from "./channel.model";
import {
  login,
  wasLoginCancelled,
} from "../../services/channels/facebook.service";

const facebook: Channel = {
  name: "Facebook",
  slug: "facebook",
  color: "#3b5895",

  features: {
    businessAccount: true,
    liveEdit: {},
    commentManagement: {
      likes: true,
      edit: true,
    },
    content: {
      thumbnail: {
        link: true,
      },
    },
    analytics: {
      post: true,
    },
    messaging: true,
  },

  requires: {
    extractLink: true,
  },

  contentSpecs: {
    maxChars: 5000,
    media: {
      image: {
        mimetypes: ["image/png", "image/jpeg", "image/gif"],
        maxSize: 10 * MBYTE,
        maxCount: 30,
      },
      video: {
        mimetypes: ["video/mp4", "video/x-m4v", "video/*"],
        maxSize: 1.75 * GBYTE,
        maxCount: 1,
      },
      gif: {
        maxSize: 8 * MBYTE,
        maxCount: 30,
        asMP4: true,
      },
      overall: {
        oneMediaTypeOnly: true,
        maxCount: 30,
      },
    },
  },

  surfaces: [],
  subchannelTypes: [
    {
      slug: "fb_group",
      fetchItemsURL: "channels/facebook/<account_id>/groups",
    },
  ],
  wasLoginCancelled,
  login,
};

export default facebook;
