// @flow
import * as React from "react";
import type { AddEditChildFormDef } from "./AddEditChildDialog";
import AddEditChildDialog from "./AddEditChildDialog";
import {
  checkChildAccountFirstName,
  checkChildAccountLastName,
  checkEmail,
} from "../../../../../util/validation.util";
import type { Callback } from "../../../../../types";
import type { ChildUser } from "../../../../../models/user.model";
import { FormActions, useForm } from "../../../../../hooks/useForm";
import type { FormValues } from "../../../../../hooks/useForm";
import { usePortfolios } from "../../../../../store/selectors";
import * as snacks from "../../../../../models/alerts.model";
import { useStoreActions } from "../../../../../store/store";
import omit from "lodash/omit";
import { API as backend } from "../../../../../services/backend.service";

const FORM_FIELDS: AddEditChildFormDef = {
  first_name: {
    type: "string",
    maxLength: 64,
    validate: checkChildAccountFirstName,
    preventOverflow: true,
  },
  last_name: {
    type: "string",
    maxLength: 64,
    validate: checkChildAccountLastName,
    preventOverflow: true,
  },
  email: {
    type: "string",
    maxLength: 128,
    validate: checkEmail,
    preventOverflow: true,
  },
  enablePortfolioFilter: { type: "boolean" },
  portfolios: { type: "number[]" },
  password: { type: "string" },
};

type Props = {
  onAdded: (ChildUser) => any,
  onUpdated: (ChildUser) => any,
  onClose: Callback,
  open?: boolean,
  child: ?ChildUser,
};

const transformPayload = (state: FormValues<AddEditChildFormDef>) => ({
  ...omit(state, "enablePortfolioFilter"),
  portfolios: state.enablePortfolioFilter ? state.portfolios : null,
});

const AddEditChildDialogContainer: React.ComponentType<Props> = ({
  onAdded,
  onUpdated,
  onClose,
  open,
  child,
}) => {
  const portfolios = usePortfolios();
  const actions = useStoreActions();
  const initial = React.useMemo(
    () => ({
      first_name: child?.first_name ?? "",
      last_name: child?.last_name ?? "",
      email: child?.email ?? "",
      portfolios: child?.restricted_portfolios ?? [],
      password: "changeMe!123",
      enablePortfolioFilter: !!child?.restricted_portfolios,
    }),
    [child]
  );
  const form = useForm(FORM_FIELDS);

  const handleClose = () => {
    FormActions.reset(form.set, initial);
    onClose();
  };

  React.useEffect(() => {
    if (initial) {
      FormActions.reset(form.set, initial);
    }
  }, [form.set, initial]);

  const handleAdd = () => {
    if (FormActions.validate(form)) {
      const payload = transformPayload({ ...FormActions.collect(form.state) });
      return backend.profile
        .addChildUser(payload)
        .then(onAdded)
        .then(handleClose)
        .then(() => {
          actions.snacks.append(
            snacks.localInfo({
              message:
                "Page.SettingsSection.ChildrenAccountsTab.AddEditChildDialog.addSuccess",
            })
          );
        });
    }
  };

  const handleUpdate = () => {
    if (child && FormActions.validate(form)) {
      const payload = transformPayload({ ...FormActions.collect(form.state) });
      return backend.profile
        .editChildUser(child.id, payload)
        .then(onUpdated)
        .then(handleClose)
        .then(() => {
          actions.snacks.append(
            snacks.localInfo({
              message:
                "Page.SettingsSection.ChildrenAccountsTab.AddEditChildDialog.updateSuccess",
            })
          );
        });
    }
  };

  return (
    <AddEditChildDialog
      portfolios={portfolios}
      open={open}
      form={form}
      onClose={handleClose}
      onSubmit={child ? handleUpdate : handleAdd}
      isEdit={Boolean(child)}
    />
  );
};

export default AddEditChildDialogContainer;
