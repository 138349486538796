// @flow
import ajax, { downloadFile } from "../../lib/ajax.lib";
import type { APIResult } from "./types";
import type { ModelID } from "../../types";
import { Moment } from "../../types";
import type {
  AnalyticsReport,
  APIAnalyticsReport,
} from "../../models/analytics.model";
import { fromAPIResult } from "../../models/analytics.model";

export type ExportFormat = "xlsx" | "csv";

export const getAnalytics = (
  portfolioId: ModelID,
  accountId: ModelID,
  start: Moment,
  end: Moment
): Promise<AnalyticsReport[]> =>
  ajax
    .get<APIResult<APIAnalyticsReport[]>>(
      `/analytics?portfolio_id=${portfolioId}&account_ids=${accountId}&start=${start.unix()}&end=${end.unix()}`
    )
    .then((resp) => resp.data.data.map(fromAPIResult));

export const downloadAnalytics = (
  portfolioId: ModelID,
  accountIds: ModelID[],
  start: Moment,
  end: Moment,
  format: ExportFormat
): Promise<void> =>
  downloadFile(
    `/analytics/export?portfolio_id=${portfolioId}&channels=&account_ids=${accountIds.join(
      ","
    )}&fmt=${format}&start=${start.unix()}&end=${end.unix()}`,
    format === "xlsx" ? "analytics.xlsx" : "analytics.zip"
  );
