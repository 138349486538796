// @flow
import * as React from "react";
import {
  FileException,
  isImage,
  toJpegImageFile,
  validateDimensions,
  validateSize,
  validateType,
} from "../lib/files.lib";
import noop from "lodash/noop";
import identity from "lodash/identity";
import type { Mimetype } from "../types";
import type { MaxSizeArg } from "../lib/files.lib";
import type { ValidationError } from "../util/validation.util";

export type Props = {
  maxSize?: MaxSizeArg,
  maxFiles?: number,
  accept?: Mimetype[],
  forceImagesAsJpeg?: boolean,
  onError?: (?ValidationError) => any,
};

type ReturnValue = (File[]) => Promise<File[]>;

const toJpegImageFileIgnoreVideo = (f: File): Promise<File> =>
  isImage(f) ? toJpegImageFile(f) : Promise.resolve(f);

export const useFilesValidator = ({
  maxSize = Infinity,
  maxFiles = 20,
  accept = ["*"],
  forceImagesAsJpeg = false,
  onError = noop,
}: Props): ReturnValue => {
  const transformImage = forceImagesAsJpeg
    ? toJpegImageFileIgnoreVideo
    : identity;

  return React.useCallback(
    async (files) => {
      const results = await Promise.all(
        files.slice(0, maxFiles).map((file) =>
          validateSize(maxSize)(file)
            .then(validateType(accept))
            .then(transformImage)
            .then(validateDimensions)
            .catch((e: FileException) =>
              onError({
                error: e.message,
                data: e.data,
              })
            )
        )
      );
      const validatedFiles = results.filter(Boolean);
      if (validatedFiles.length === files.length) {
        // No error! clear the error field.
        onError();
      }
      return validatedFiles;
    },
    [maxSize, maxFiles, accept, transformImage, onError]
  );
};
