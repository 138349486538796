// @flow
import * as React from "react";
import AppNavigation from "./AppNavigation";
import {
  useCurrentUser,
  useIsNavigationDrawerOpen,
  usePortfolios,
} from "../../../store/selectors";
import { useNavigate } from "react-router-dom";
import type { Portfolio } from "../../../models/portfolio.model";
import { useStoreActions } from "../../../store/store";
import * as profileService from "../../../services/profile.service";
import useTemporaryNavigation from "../../../hooks/useTemporaryNavigation";

const AppNavigationContainer: React.ComponentType<{}> = () => {
  const open = useIsNavigationDrawerOpen();
  const portfolios = usePortfolios();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const actions = useStoreActions();
  const showTempNav = useTemporaryNavigation();

  const handlePortfoliosReordered = React.useCallback(
    (pfs: Portfolio[]) =>
      profileService.setPortfoliosDisplayOrder(actions)({ portfolios: pfs }),
    [actions]
  );

  const handleNavigate = React.useCallback(
    (url: string) => {
      navigate(url);
      // On tablet and mobile, close when navigating.
      if (showTempNav) {
        actions.global.onSetDrawerOpen(false);
      }
    },
    [navigate, actions, showTempNav]
  );

  return (
    <AppNavigation
      open={open}
      temporary={showTempNav}
      onToggle={() => actions.global.onSetDrawerOpen(!open)}
      portfolios={portfolios}
      onNavigate={handleNavigate}
      user={user}
      onPortfolioReordered={handlePortfoliosReordered}
    />
  );
};

export default AppNavigationContainer;
