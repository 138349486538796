// @flow
import * as React from "react";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import type { Subscription } from "../../models/subscription.model";
import type { LockActions, LockState } from "./lib/lock.state";
import { useLockState } from "./lib/lock.state";
import type { InitState } from "./lib/init.state";
import { useInitState } from "./lib/init.state";
import type { PlanFlavour } from "../../models/plan.model";
import type { AddOn } from "../../models/addOn.model";
import type { ModelID } from "../../types";
import without from "lodash/without";

export type SubscriptionActions = {
  ...LockActions,
  loadSubscription: (subscription: ?Subscription) => void,
  addAddOnToSubscription: (addOnPricingId: ModelID) => void,
  removeAddOnFromSubscription: (addOnPricingId: ModelID) => void,
  planFlavours: SequenceActions<PlanFlavour>,
  addOns: SequenceActions<AddOn>,
};
export type SubscriptionState = {
  ...LockState,
  ...InitState,
  planFlavours: SequenceState<PlanFlavour>,
  addOns: SequenceState<AddOn>,
  subscription: ?Subscription,
};

const addOnsReducer = sequenceReducer<AddOn>({
  key: "id",
});
const planFlavoursReducer = sequenceReducer<PlanFlavour>({
  key: "id",
});

export const useSubscriptionReducer = (): [
  SubscriptionState,
  SubscriptionActions
] => {
  const [subscription, setSubscription] = React.useState<?Subscription>(null);
  const [addOns, addOnsDispatch] = useSequenceReducer(addOnsReducer);
  const [planFlavours, planFlavoursDispatch] =
    useSequenceReducer(planFlavoursReducer);
  const [locked, lockActions] = useLockState();
  const [initialised, initActions] = useInitState();

  const addOnsActions = React.useMemo(
    () => makeSequenceActions(addOnsDispatch),
    [addOnsDispatch]
  );
  const planFlavoursActions = React.useMemo(
    () => makeSequenceActions(planFlavoursDispatch),
    [planFlavoursDispatch]
  );
  const actions = React.useMemo(
    () => ({
      ...lockActions,
      planFlavours: planFlavoursActions,
      addOns: addOnsActions,
      loadSubscription: (subscription: ?Subscription) => {
        setSubscription(subscription);
        initActions.setAllInitialised();
      },
      addAddOnToSubscription: (addOnPricingId: ModelID) => {
        setSubscription((prev) =>
          prev
            ? {
                ...prev,
                add_ons: [...prev.add_ons, addOnPricingId],
              }
            : null
        );
      },
      removeAddOnFromSubscription: (addOnPricingId: ModelID) => {
        setSubscription((prev) =>
          prev
            ? {
                ...prev,
                add_ons: without(prev.add_ons, addOnPricingId),
              }
            : null
        );
      },
    }),
    [
      addOnsActions,
      planFlavoursActions,
      setSubscription,
      lockActions,
      initActions,
    ]
  );
  return [{ subscription, addOns, planFlavours, locked, initialised }, actions];
};
