// @flow
import * as React from "react";
import TiktokContentEditor from "./TiktokContentEditor";
import { API as backend } from "../../../../../services/backend.service";
import PostBuilderContext from "../../../../../contexts/postBuilder";
import type { TiktokExtras } from "../../../../../models/channels/tiktok.model";
import { getDefaultExtras } from "../../../../../models/channels/tiktok.model";
import { isAttachmentImage } from "../../../../../models/attachment.model";
import every from "lodash/every";
import moment from "moment";

const TiktokContentEditorContainer: React.ComponentType<{}> = () => {
  const {
    post: { account_id, extras: rawExtras, attachments },
    tiktokCreatorContext,
    onSetTiktokCreatorInfo,
    onUpdatePost,
  } = React.useContext(PostBuilderContext);
  // As per Tiktok's guidelines, all should be disabled by default.
  const extras: TiktokExtras = rawExtras ?? getDefaultExtras();
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      account_id !== tiktokCreatorContext.accountId ||
      moment().diff(tiktokCreatorContext.updatedAt, "minute") > 10
    ) {
      backend.channels
        .getTiktokCreatorInfo(account_id)
        .then((info) => onSetTiktokCreatorInfo(info, account_id))
        .catch((e) => {
          console.log(e);
          setError(true);
        });
    }
  }, [
    account_id,
    tiktokCreatorContext.accountId,
    tiktokCreatorContext.updatedAt,
    onSetTiktokCreatorInfo,
  ]);

  return (
    <TiktokContentEditor
      creatorInfo={tiktokCreatorContext.info}
      error={error}
      extras={extras}
      onChangeExtras={(extras) => onUpdatePost({ extras })}
      variant={
        attachments.length > 0 && every(attachments, isAttachmentImage)
          ? "images"
          : "video"
      }
    />
  );
};

export default TiktokContentEditorContainer;
