// @flow
import * as React from "react";
import Comment from "./Comment";
import CommentActions from "./CommentActions";
import type { PostComment } from "../../../../../../models/postComment.model";
import {
  useScopedAccount,
  useScopedPortfolio,
} from "../../../../../../scopes/scopes";
import { useStoreActions } from "../../../../../../store/store";
import * as engagementService from "../../../../../../services/engagement.service";
import { Grow, IconButton } from "@mui/material";
import PleaseWaitProgress from "../../../../../feedback/PleaseWaitProgress";
import MessageInput from "../../../MessageInput";
import useSwitch from "../../../../../../hooks/useSwitch";
import { getChannel } from "../../../../../../models/channels";
import {
  DeleteRounded,
  EditRounded,
  MoreHorizRounded,
} from "@mui/icons-material";
import withPopupMenu from "../../../../../lib/navigation/withPopupMenu";
import MenuListItem from "../../../../../lib/navigation/MenuListItem";
import useTranslate from "../../../../../../hooks/useTranslate";

type CommentAction = "edit" | "reply";

type Props = {
  comment: PostComment,
};

const MoreIconButton = withPopupMenu(IconButton, {
  children: <MoreHorizRounded />,
  MenuListProps: { dense: true },
  closeOnClick: true,
});

const CommentContainer: React.ComponentType<Props> = ({ comment }) => {
  const [currentAction, setCurrentAction] = React.useState<?CommentAction>();
  const [locked, lock, unlock] = useSwitch();
  const actions = useStoreActions();
  const account = useScopedAccount();
  const portfolio = useScopedPortfolio();
  const t = useTranslate();
  const channel = getChannel(account?.channel);

  const handlePressReply = React.useCallback(() => {
    setCurrentAction((prevAction) => (prevAction !== "reply" ? "reply" : null));
  }, []);

  const handlePressEdit = React.useCallback(() => {
    setCurrentAction((prevAction) => (prevAction !== "edit" ? "edit" : null));
  }, []);

  const handlePressLike = React.useCallback(() => {
    lock();
    engagementService
      .setCommentLiked(actions)(comment.id, !comment.liked)
      .finally(unlock);
  }, [lock, unlock, comment.liked, comment.id, actions]);

  const handleDelete = React.useCallback(() => {
    lock();
    return engagementService.deleteComment(actions)(comment.id).finally(unlock);
  }, [lock, unlock, actions, comment.id]);

  const handleSubmitMessage = React.useCallback(
    (message: string) => {
      switch (currentAction) {
        case "reply":
          lock();
          return engagementService
            .addComment(actions)(comment.post_id ?? 0, {
              message,
              parent_id: comment.id,
            })
            .then(handlePressReply)
            .finally(unlock);
        case "edit":
          lock();
          return engagementService
            .editComment(actions)({ id: comment.id, message })
            .then(handlePressEdit)
            .finally(unlock);
        default:
          return;
      }
    },
    [
      lock,
      unlock,
      actions,
      handlePressEdit,
      currentAction,
      handlePressReply,
      comment.id,
      comment.post_id,
    ]
  );

  if (!portfolio || !account || !channel) return <PleaseWaitProgress />;

  return (
    <>
      <Comment
        comment={comment}
        portfolio={portfolio}
        fromUser={account.entity_id === comment.author_native_id}
        secondaryAction={
          <MoreIconButton size="small" color="inherit" disabled={locked}>
            {channel.features?.commentManagement?.edit && (
              <MenuListItem
                primary={t(
                  currentAction === "edit" ? "global.cancelEdit" : "global.edit"
                )}
                onClick={handlePressEdit}
                Icon={EditRounded}
              />
            )}
            <MenuListItem
              primary={t("global.delete")}
              onClick={handleDelete}
              iconColor="error"
              Icon={DeleteRounded}
            />
          </MoreIconButton>
        }
      >
        <CommentActions
          onPressReply={handlePressReply}
          liked={comment.liked}
          locked={locked}
          channel={channel}
          isReplying={currentAction === "reply"}
          onPressLike={handlePressLike}
        />
      </Comment>
      <Grow in={!!currentAction} unmountOnExit>
        <MessageInput
          onSubmit={handleSubmitMessage}
          initial={currentAction === "edit" ? comment.message : undefined}
        />
      </Grow>
    </>
  );
};

export default CommentContainer;
