// @flow
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initMomentLocales, setMomentLocale } from "./moment-i18n";
import { en_gb, getLocale } from "../config/locales.config";
import type { Slug } from "../types";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to the react-i18next components.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: [en_gb.slug],
    // locale will be fully lowercased; e.g. en-US ⇒ en-us
    lowerCaseLng: true,
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      transKeepBasicHtmlNodesFor: [
        "br",
        "strong",
        "i",
        "p",
        "sub",
        "small",
        "pre",
        "code",
        "b",
        "a",
        "div",
        "ul",
        "li",
      ],
    },
  })
  .then(initMomentLocales);

export const updateLanguage = (languageCode: ?Slug): Promise<boolean> => {
  // If no language provided, reload from localStorage.
  languageCode =
    languageCode ?? localStorage.getItem("locale") ?? navigator.language;
  // Make sure we support the request, and that it is not the current language.
  if (
    languageCode &&
    getLocale(languageCode) &&
    languageCode !== i18n.language
  ) {
    return i18n
      .changeLanguage(languageCode)
      .then(() => setMomentLocale(languageCode))
      .then(() => localStorage.setItem("locale", languageCode ?? ""))
      .then(() => true);
  }
  return Promise.resolve(false);
};
