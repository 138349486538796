// @flow
import * as React from "react";
import routes, {
  getRouteById,
  getRoutePath,
} from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import { Box, Tab, Tabs } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import values from "lodash/values";
import { Body1 } from "../../../components/lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import RouterLink from "../../../components/lib/navigation/RouterLink";
import useCurrentRoute from "../../../hooks/useCurrentRoute";
import useIsMobile from "../../../hooks/useIsMobile";

const TabsContainer = styled("div")({});

const PlanAndAddOnsPage: React.ComponentType<{}> = () => {
  const t = useTranslate();
  const tabsRoute = routes.app.nested.planAndAddons;
  const subscriptionRoute = routes.app.nested.settings.nested.subscription;

  const currentRoute = useCurrentRoute();
  const onNavigate = useNavigate();
  const isMobile = useIsMobile();
  const value = currentRoute === tabsRoute ? false : currentRoute?.id ?? false;

  return (
    <PrimaryPagePanel size="fill" route={tabsRoute}>
      {!isMobile && (
        <RouterLink to={getRoutePath(subscriptionRoute)}>
          {"< "}
          {t("Page.SettingsSection.SubscriptionTab.backTo")}
        </RouterLink>
      )}
      <TabsContainer>
        <Tabs
          orientation="horizontal"
          value={value}
          onChange={(e, routeId: string) =>
            onNavigate(getRoutePath(getRouteById(routeId)))
          }
          indicatorColor="transparent"
        >
          {values(tabsRoute.nested).map((route) => (
            <Tab
              key={route.id}
              value={route.id}
              label={<Body1 fontWeight="bold">{t(route.label)}</Body1>}
            />
          ))}
        </Tabs>
      </TabsContainer>
      <Box flexGrow={1}>
        <Outlet />
      </Box>
    </PrimaryPagePanel>
  );
};

export default PlanAndAddOnsPage;
