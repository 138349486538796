// @flow
import * as React from "react";
import { useLockState } from "./lib/lock.state";
import type { User } from "../../models/user.model";
import type { LimitSet } from "../../models/limitSet.model";
import type { LockActions, LockState } from "./lib/lock.state";

export type ProfileActions = {
  ...LockActions,
  load: (User) => void,
  loadUserLimits: (LimitSet) => void,
};

export type ProfileState = {
  ...LockState,
  currentUser: ?User,
};

export const useProfileReducer = (): [ProfileState, ProfileActions] => {
  const [currentUser, setCurrentUser] = React.useState<?User>(null);
  const [locked, lockActions] = useLockState();

  const actions = React.useMemo(
    () => ({
      load: (user: User) => setCurrentUser(user),
      loadUserLimits: (limits: LimitSet) =>
        setCurrentUser((user) => (user ? { ...user, limits } : null)),
      ...lockActions,
    }),
    [setCurrentUser, lockActions]
  );

  return [{ currentUser, locked }, actions];
};
