// @flow
import { API as backend } from "./backend.service";
import * as snacks from "../models/alerts.model";
import type { AsyncVoidAction } from "./types";
import type { ModelID } from "../types";
import type {
  AddAccountParams,
  EditAccountParams,
  MoveAccountParams,
} from "./backend.service/account";
import { getPortfolioSummaries } from "./backend.service/portfolio";

export const addAccount: AsyncVoidAction<AddAccountParams> =
  (actions) => (account) =>
    backend.account
      .addAccount(account)
      .then((resultAccount) => {
        actions.account.append(resultAccount);
        actions.snacks.append(
          snacks.localSuccess({ message: "store.account.linked" })
        );
      })
      // Reload summaries since a new account was added.
      .then(getPortfolioSummaries);

export const editAccount: AsyncVoidAction<EditAccountParams> =
  (actions) => (account) =>
    backend.account
      .editAccount(account)
      .then((resultAccount) => {
        actions.account.replace(resultAccount);
        actions.snacks.append(
          snacks.localSuccess({ message: "store.account.refreshed" })
        );
      })
      // Reload summaries in case an account error was lifted.
      .then(getPortfolioSummaries);

export const moveAccount: AsyncVoidAction<MoveAccountParams> =
  (actions) => (account) =>
    backend.account
      .editAccount(account)
      .then((resultAccount) => {
        actions.account.replace(resultAccount);
        actions.snacks.append(
          snacks.localSuccess({ message: "store.account.move" })
        );
      })
      // Reload summaries in case an account error was lifted.
      .then(getPortfolioSummaries);

export const getAccounts: AsyncVoidAction<ModelID> =
  (actions) => (portfolioId: ModelID) => {
    actions.account.lock();
    return backend.account
      .getPortfolioAccounts(portfolioId)
      .then(actions.account.replaceAll)
      .finally(actions.account.unlock);
  };

export const deleteAccount: AsyncVoidAction<ModelID> =
  (actions) => (accountId) =>
    backend.account
      .deleteAccount(accountId)
      .then(() => {
        actions.account.remove(accountId);
        actions.snacks.append(
          snacks.localSuccess({ message: "store.account.unlinked" })
        );
      })
      // Reload summaries since an account was removed.
      .then(getPortfolioSummaries);
