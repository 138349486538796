// @flow
import * as React from "react";
import HTMLForm from "../../lib/inputs/form/HTMLForm";
import type { Callback } from "../../../types";
import type { User } from "../../../models/user.model";
import SearchUser from "../../inputs/SearchUser";
import { Body1 } from "../../lib/display/Text";

type Props = {
  user: ?User,
  onConfirm: Callback,
  onChangeSelectedUser: (?User) => any,
};

const MetamorphWidget: React.ComponentType<Props> = ({
  user,
  onConfirm,
  onChangeSelectedUser,
}) => (
  <HTMLForm onSubmit={onConfirm} style={{ width: "100%" }}>
    <Body1 paragraph>
      Use this form to impersonate another user. Be careful not to break
      anything!
    </Body1>
    <SearchUser
      value={user}
      onChange={(e, value) => onChangeSelectedUser(value)}
      TextFieldProps={{
        fullWidth: true,
      }}
    />
  </HTMLForm>
);

export default MetamorphWidget;
