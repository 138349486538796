// @flow
import * as React from "react";
import { isIOS } from "../lib/platform.lib";
import type { URLStr } from "../types";
import { staticInstance } from "../lib/ajax.lib";
import { useCallback, useEffect } from "react";
import useInterval from "../hooks/useInterval";
import interval from "../lib/interval.lib";
import type { Provider } from "../reactTypes";
import noop from "lodash/noop";

type SetVersion = (string) => void;
type AppVersion = { webVersion: string, iOSVersion: string };
const NULL_VERSION = "0.0.0";

export const VersionContext: React.Context<AppVersion> =
  React.createContext<AppVersion>({
    webVersion: NULL_VERSION,
    iOSVersion: NULL_VERSION,
  });

const checkOneVersion = (url: URLStr, setVersion: SetVersion) => {
  staticInstance
    .get<{ version: string }>(url, { disableErrorInterceptor: true })
    .then((data) => {
      if (data.data.version) {
        setVersion(data.data.version);
      }
    })
    .catch(noop);
};

export const AppVersionProvider: Provider<AppVersion> = ({ children }) => {
  const [webVersion, setWebVersion] = React.useState(NULL_VERSION);
  const [iOSVersion, setIOSVersion] = React.useState(NULL_VERSION);

  const checkNewVersion = useCallback(() => {
    console.log("Checking Web version...");
    checkOneVersion("version.json", setWebVersion);

    if (isIOS) {
      console.log("Checking IOS version...");
      checkOneVersion("version-ios.json", setIOSVersion);
    }
  }, []);

  useInterval(checkNewVersion, 2 * interval.minute.as.milliseconds);
  useEffect(() => checkNewVersion(), [checkNewVersion]);

  return (
    <VersionContext.Provider value={{ webVersion, iOSVersion }}>
      {children}
    </VersionContext.Provider>
  );
};
