// @flow

import * as React from "react";
import * as eventTypes from "../config/eventTypes.config";
import moment from "moment/moment";
import { UserToken } from "../lib/session.lib";
import * as env from "../config/env.config";
import { TrackingContext } from "../contexts/tracking";
import type { Callback } from "../types";

const useLogOutHandler = (): Callback => {
  const { onFire, onBeat } = React.useContext(TrackingContext);

  return React.useCallback(() => {
    onFire(eventTypes.LOGGED_OUT);
    onBeat(moment(), () => {
      UserToken.unset();
      window.location.href = env.LANDING_URL;
    });
  }, [onFire, onBeat]);
};

export default useLogOutHandler;
