// @flow
import type { ModelID, Timestamp } from "../types";
import { Moment } from "../types";
import { toMoment } from "../lib/time.lib";

export type NotificationType = "general" | "news" | "alarm" | "error";

export type Notification = {
  id: ModelID,
  title: string,
  content: string,
  type: NotificationType,
  schedule_time: ?Moment,
  is_loud: boolean,
  tag: ?Object,
  protected: boolean,
  read: boolean,
};

export type APINotification = {
  ...Omit<Notification, "schedule_time">,
  schedule_time: Timestamp,
};

export const fromAPIResult = (notification: APINotification): Notification => ({
  ...notification,
  schedule_time: toMoment(notification.schedule_time),
});
