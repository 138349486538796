// @flow
import * as React from "react";
import type { Callback } from "../types";

type ReturnValue = [number, ?Callback, ?Callback];

const usePaginationState = (
  pages?: number = Infinity,
  initial?: ?number
): ReturnValue => {
  const [page, setPage] = React.useState<number>(initial ?? 1);
  const handlePreviousPage = React.useCallback(() => setPage((p) => p - 1), []);
  const handleNextPage = React.useCallback(() => setPage((p) => p + 1), []);
  return [
    page,
    page === 1 ? undefined : handlePreviousPage,
    page >= pages ? undefined : handleNextPage,
  ];
};

export default usePaginationState;
