// @flow
import * as React from "react";
import VizPlannerControls from "./VizPlannerControls";
import PostFiltersContext from "../../../contexts/postFilters";
import { useStoreActions } from "../../../store/store";
import { useAccount } from "../../../store/selectors";
import head from "lodash/head";
import * as postService from "../../../services/post.service";
import { makeAddPostParamsFromVizDrop } from "../../../services/post.service";
import { cast } from "../../../types";
import type { ValidationError } from "../../../util/validation.util";
import type { MediaItem } from "../../lib/inputs/MediaInput/useHeadlessMediaInput";

const VizPlannerControlsContainer: React.ComponentType<{}> = () => {
  const [fileError, setFileError] = React.useState<?ValidationError>(null);
  const filters = React.useContext(PostFiltersContext);
  const actions = useStoreActions();
  const account = useAccount(head(filters.accountIds));

  const handleAddMedia = React.useCallback(
    (newItems: MediaItem[]) => {
      account?.id &&
        newItems.forEach((mediaItem) =>
          makeAddPostParamsFromVizDrop(
            actions,
            account.id,
            cast<File>(mediaItem.src)
          ).then(postService.addPost(actions))
        );
      setFileError(null);
    },
    [account?.id, actions]
  );

  return (
    <VizPlannerControls
      onMediaError={setFileError}
      onAddMedia={handleAddMedia}
      mediaError={fileError}
      filters={filters}
    />
  );
};

export default VizPlannerControlsContainer;
