// @flow
import * as React from "react";
import FrozenSubscriptionDialog from "./FrozenSubscriptionDialog";
import {
  useCurrentSubscription,
  useSubscriptionLocked,
} from "../../../store/selectors";
import * as subscriptionService from "../../../services/subscription.service";
import { useStoreActions } from "../../../store/store";
const FrozenSubscriptionDialogContainer: React.ComponentType<{}> = () => {
  const actions = useStoreActions();
  const subscription = useCurrentSubscription();
  const locked = useSubscriptionLocked();

  return (
    <FrozenSubscriptionDialog
      open={!!subscription?.is_frozen}
      locked={locked}
      onUnfreeze={subscriptionService.unfreezeSubscription(actions)}
    />
  );
};

export default FrozenSubscriptionDialogContainer;
