// @flow
import * as React from "react";
import { PostCreatorCollapsibleSection } from "../../layout/PostCreatorSection";
import { Collapse, IconButton, TextField } from "@mui/material";
import PostBuilderContext from "../../../contexts/postBuilder";
import { Body1, Body2 } from "../../lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import { isEmpty } from "lodash";
import { AddRounded, CloseRounded } from "@mui/icons-material";
import Duration from "../../lib/display/Duration";
import DurationPicker from "../../lib/inputs/DurationPicker";
import useInputCharLimitHelper from "../../../hooks/useInputCharLimitHelper";
import { splice } from "../../../lib/lodashex.lib";
import { ColumnStack, RowStack } from "../../lib/layout/stacks";

const Options = styled(ColumnStack)({
  flexGrow: 1,
  flexShrink: 1,
});

const DurationSummary = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
});

const PollWidget: React.ComponentType<{}> = () => {
  const {
    post: { poll, id },
    onUpdatePost,
    channel,
  } = React.useContext(PostBuilderContext);
  const t = useTranslate();
  const handleChangeOption = React.useCallback(
    (text: string, index: number) =>
      poll &&
      onUpdatePost({
        poll: { ...poll, options: splice(poll.options, index, 1, [text]) },
      }),
    [poll, onUpdatePost]
  );

  const [onChangeOption, optionHelperFactory, isErrorOption] =
    useInputCharLimitHelper({
      maxLength: channel?.features?.content?.polls?.maxOptionLength ?? 999,
      onChange: handleChangeOption,
      preventOverflow: true,
    });

  return (
    channel?.features?.content?.polls && (
      <Collapse in={!!poll}>
        <PostCreatorCollapsibleSection
          title="global.poll"
          defaultCollapsed={!!id}
        >
          <Body2 color="textSecondary" paragraph>
            {t("PollWidget.description")}
          </Body2>
          {poll && (
            <ColumnStack spacing={2}>
              <RowStack alignItems="flex-start" spacing={1.5}>
                <Options>
                  {poll.options.map((opt, index) => (
                    <TextField
                      value={opt}
                      margin="none"
                      required={index < 2}
                      key={index}
                      helperText={optionHelperFactory(opt)}
                      error={isErrorOption(opt)}
                      onChange={(e) =>
                        onChangeOption(e.currentTarget.value, index)
                      }
                      placeholder={
                        index > 1 ? t("global.optional") : t("global.required")
                      }
                    />
                  ))}
                </Options>
                <ColumnStack>
                  <IconButton
                    onClick={() => onUpdatePost({ poll: null })}
                    color="error"
                  >
                    <CloseRounded />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      onUpdatePost({
                        poll: { ...poll, options: [...poll.options, ""] },
                      })
                    }
                    disabled={isEmpty(poll.options[poll.options.length - 1])}
                  >
                    <AddRounded />
                  </IconButton>
                </ColumnStack>
              </RowStack>
              <div>
                <DurationSummary>
                  <Body1 fontWeight="bold" gutterBottom>
                    {t("global.duration")}
                  </Body1>
                  <Body2 color="textSecondary">
                    <Duration minutes={poll.duration_minutes} />
                  </Body2>
                </DurationSummary>
                <DurationPicker
                  onChange={(seconds) =>
                    onUpdatePost({
                      poll: { ...poll, duration_minutes: seconds / 60 },
                    })
                  }
                  duration={poll.duration_minutes * 60}
                  showSeconds={false}
                />
              </div>
            </ColumnStack>
          )}
        </PostCreatorCollapsibleSection>
      </Collapse>
    )
  );
};

export default PollWidget;
