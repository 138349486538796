// @flow
import * as React from "react";
import PostBuilderContext from "../../../../contexts/postBuilder";
import useTranslate from "../../../../hooks/useTranslate";
import type { InstagramExtras } from "../../../../models/channels/instagram.model";
import { getDefaultExtras } from "../../../../models/channels/instagram.model";
import ChipsInput from "../../../lib/inputs/ChipsInput";

const InstagramContentEditor: React.ComponentType<{}> = () => {
  const {
    post: { content_type, extras: rawExtras },
    onUpdatePost,
  } = React.useContext(PostBuilderContext);
  const t = useTranslate();
  const extras: InstagramExtras = rawExtras ?? getDefaultExtras();

  return (
    <ChipsInput
      value={extras.collaborators}
      maxItems={3}
      onChange={(e, value) =>
        onUpdatePost({
          extras: { collaborators: value.map((v) => v.replace("@", "")) },
        })
      }
      disabled={content_type === "story"}
      TextFieldProps={{
        label: t("InstagramContentEditor.collaborators"),
        helperText: t("InstagramContentEditor.collaboratorsHelperText"),
      }}
    />
  );
};

export default InstagramContentEditor;
