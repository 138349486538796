// @flow
import * as React from "react";
import { isPastScheduleTime } from "../../models/post.model";
import type { Post } from "../../models/post.model";
import { Alert } from "@mui/material";

type Props = {
  ...Pick<Post, "last_scheduling_error" | "status" | "schedule_time">,
  compact?: boolean,
  ...
};

const PostErrorAlert: React.ComponentType<Props> = ({
  last_scheduling_error,
  compact,
  ...post
}) => {
  if (!last_scheduling_error || !isPastScheduleTime(post)) {
    return null;
  }

  return <Alert severity="error">{last_scheduling_error}</Alert>;
};

export default PostErrorAlert;
