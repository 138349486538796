// @flow
import { API as backend } from "./backend.service";
import type { AsyncAction, AsyncVoidAction } from "./types";
import type { ModelID, Timestamp } from "../types";
import type { CreateMessageParams } from "./backend.service/messaging";
import { paginateRequest } from "./utils.service";
import flow from "lodash/flow";
import type { Message } from "../models/message.model";

export const addMessage: AsyncAction<
  Promise<any>,
  ModelID,
  CreateMessageParams
> = (actions) => (conversationId, message) => {
  actions.messaging.lock();
  return (
    backend.messaging
      .addMessage(conversationId, message)
      // "extendAndSyncSummary", because a single meta message can create
      // more than 1 atomic message, for example if the platform
      // separates attachments and text.
      .then(actions.messaging.messages.extendAndSyncSummary)
      .finally(actions.messaging.unlock)
  );
};

export const markConversationAsRead: AsyncVoidAction<ModelID> =
  (actions) => (conversationId) =>
    backend.messaging.markConversationAsRead(conversationId).then(() =>
      actions.messaging.summaries.patch(conversationId, {
        unread_messages: 0,
      })
    );

export const getConversations: AsyncAction<Promise<void>, ModelID> =
  (actions) => (portfolioId) => {
    actions.messaging.removeInitialised();
    return paginateRequest(
      backend.messaging.getConversations,
      flow(
        actions.messaging.conversations.replaceAll,
        actions.messaging.setSomeInitialised
      ),
      actions.messaging.conversations.extend,
      { portfolioId },
      5,
      100
    );
  };

export const getConversationSummary: AsyncVoidAction<ModelID> =
  (actions) => (conversationId) =>
    backend.messaging
      .getConversationSummary(conversationId)
      .then(actions.messaging.summaries.insert);

export const getConversationSummaries: AsyncAction<Promise<void>, ModelID> =
  (actions) => (portfolioId) => {
    // Set some, not none, because conversations themselves may be loaded too.
    actions.messaging.setSomeInitialised();
    return paginateRequest(
      backend.messaging.getConversationSummaries,
      flow(
        actions.messaging.summaries.replaceAll,
        actions.messaging.setSomeInitialised
      ),
      actions.messaging.summaries.extend,
      { portfolioId },
      5,
      100
    ).then(actions.messaging.setAllInitialised);
  };

const MESSAGE_PAGE_SIZE = 20;

export const getNewerMessages: AsyncAction<Promise<void>, ModelID, Timestamp> =
  (actions) => (conversationId, after) =>
    backend.messaging
      .getMessages(conversationId, "asc", after)
      .then(actions.messaging.messages.loadNewerMessages);

export const getOlderMessages: AsyncAction<Promise<void>, ModelID, Timestamp> =
  (actions) => (conversationId, before) =>
    backend.messaging
      .getMessages(conversationId, "desc", before, MESSAGE_PAGE_SIZE)
      .then((messages: Message[]) => {
        actions.messaging.messages.loadOlderMessages(
          messages,
          messages.length < MESSAGE_PAGE_SIZE
        );
      });
