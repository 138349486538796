// @flow
import * as React from "react";
import YourPlanSection from "./YourPlanSection";
import {
  useCurrentSubscription,
  usePurchasedAddOns,
} from "../../../../store/selectors";
import { getPlan } from "../../../../config/plans.config";
import { getSubscriptionStatus } from "../../../../config/subscriptionStatus.config";
import { useNavigate } from "react-router-dom";
import routes, { getRoutePath } from "../../../../config/routes.config";

const YourPlanSectionContainer: React.ComponentType<{}> = () => {
  const subscription = useCurrentSubscription();
  const purchasedAddOns = usePurchasedAddOns();
  const navigate = useNavigate();
  const plan = getPlan(subscription?.plan.plan_slug);
  const status = getSubscriptionStatus(subscription?.status);
  return (
    <YourPlanSection
      subscription={subscription}
      plan={plan}
      status={status}
      purchasedAddOns={purchasedAddOns}
      onEditPlan={() =>
        navigate(getRoutePath(routes.app.nested.planAndAddons.nested.plan))
      }
    />
  );
};

export default YourPlanSectionContainer;
