// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import MediaItemElement from "./MediaItem";
import { reorderItem } from "../../../../lib/lodashex.lib";
import { isImage } from "../../../../lib/files.lib";
import without from "lodash/without";
import type { MediaItem } from "./useHeadlessMediaInput";

type Props = {
  items: MediaItem[],
  onChange: (MediaItem[]) => any,
  onSetEditedItem?: (MediaItem) => any,
};

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginTop: theme.spacing(1),
  "& > *": {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

const MediaCollection: React.ComponentType<Props> = ({
  items,
  onChange,
  onSetEditedItem,
}) => {
  return (
    <Root>
      {items.map((item, i) => (
        <MediaItemElement
          key={typeof item.src === "string" ? item.src : item.src.name}
          onMoveLeft={
            i > 0 ? () => onChange(reorderItem(items, i, i - 1)) : undefined
          }
          onMoveRight={
            i < items.length - 1
              ? () => onChange(reorderItem(items, i, i + 1))
              : undefined
          }
          onMediaClick={
            onSetEditedItem && isImage(item.src)
              ? () => onSetEditedItem(item)
              : undefined
          }
          onDelete={() => onChange(without(items, item))}
          src={item.src}
          uploadProgress={item.uploadProgress}
          videoOffset={item.videoOffset}
        />
      ))}
    </Root>
  );
};

export default MediaCollection;
