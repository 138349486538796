// @flow
import * as React from "react";
import PlanButton from "./PlanButton";
import type { Form, StringFormFieldDef } from "../../../hooks/useForm";
import { FormActions } from "../../../hooks/useForm";
import type { Plan, PlanFlavour } from "../../../models/plan.model";
import { Body1 } from "../../lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import currencies from "../../../config/currencies.config";
import { ResponsiveInlineRowStack } from "../../lib/layout/stacks";
import plans from "../../../config/plans.config";
import type { ModelID } from "../../../types";

export type SelectPlanWidgetFormDef = {
  currency: StringFormFieldDef,
};

type Props = {
  locked?: boolean,
  selectedFlavourId?: ?ModelID,
  subscriptionFlavourId?: ?ModelID,
  planFlavours: PlanFlavour[],
  form: Form<SelectPlanWidgetFormDef>,
  onConfirm: (Plan) => any,
  noCurrencyChange?: boolean,
};

const CurrencyBlock = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 4, 4, 4),
  textAlign: "center",
}));

const Root = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const SelectPlanWidget: React.ComponentType<Props> = ({
  planFlavours,
  form,
  noCurrencyChange,
  locked,
  selectedFlavourId,
  subscriptionFlavourId,
  onConfirm,
}) => {
  const t = useTranslate();
  return (
    <Root>
      <Body1 color="textSecondary" paragraph>
        {t("Page.SubscriptionPanel.pleaseSelectPlan")}
      </Body1>
      {!noCurrencyChange && (
        <CurrencyBlock>
          <ToggleButtonGroup
            value={form.state.currency.value}
            size="large"
            onChange={(e, val) =>
              val && FormActions.onChangeField(form.set, "currency")(val)
            }
            exclusive
          >
            {currencies.map((currency) => (
              <ToggleButton key={currency.name} value={currency.name}>
                {currency.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </CurrencyBlock>
      )}
      <ResponsiveInlineRowStack alignItems="stretch">
        {plans.map((plan) => {
          const planFlavour = planFlavours
            .filter((f) => f.plan_slug === plan.slug)
            .find((f) => f.currency === form.state.currency.value);

          return (
            planFlavour && (
              <PlanButton
                key={plan.slug}
                plan={plan}
                locked={locked}
                selected={
                  // If the widget is controlled and there is a value
                  // selected, it takes precedence, otherwise, the subscription
                  // flavour, if it exists, is the selected one.
                  selectedFlavourId
                    ? planFlavour.id === selectedFlavourId
                    : planFlavour.id === subscriptionFlavourId
                }
                onSelect={() => onConfirm(plan)}
                flavour={planFlavour}
                isEdit={!!subscriptionFlavourId}
              />
            )
          );
        })}
      </ResponsiveInlineRowStack>
    </Root>
  );
};

export default SelectPlanWidget;
