// @flow
import * as React from "react";
import type {
  FormField,
  OnChangeField,
  StringListFormFieldDef,
} from "../../../../hooks/useForm";
import { useFormFieldError } from "../../../../hooks/useForm";
import ChipsInput from "../ChipsInput";
import type { Props as ChipsInputProps } from "../ChipsInput";

type Props = {
  ...Omit<ChipsInputProps, "value" | "onChange">,
  formField: FormField<StringListFormFieldDef>,
  onChange: OnChangeField<StringListFormFieldDef>,
};

const FormChipsInput = ({
  formField,
  onChange,
  TextFieldProps,
  ...props
}: Props): React.Node => {
  const helperText = useFormFieldError(formField) ?? formField.helperText;
  return (
    <ChipsInput
      value={formField.value}
      onChange={(e, value) => onChange(value)}
      TextFieldProps={{
        helperText,
        error: !!formField.error,
        name: formField.name,
        ...TextFieldProps,
      }}
      {...props}
    />
  );
};

export default FormChipsInput;
