// @flow
import * as React from "react";
import type { ApprovalRequest } from "../../models/approvalRequest.model";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import type { LockActions, LockState } from "./lib/lock.state";
import { useLockState } from "./lib/lock.state";

export type ApprovalRequestActions = {
  ...LockActions,
  approvalRequests: SequenceActions<ApprovalRequest>,
};
export type ApprovalRequestState = {
  ...LockState,
  approvalRequests: SequenceState<ApprovalRequest>,
};

const approvalRequestReducer = sequenceReducer<ApprovalRequest>({ key: "id" });

export const useApprovalRequestReducer = (): [
  ApprovalRequestState,
  ApprovalRequestActions
] => {
  const [approvalRequests, dispatch] = useSequenceReducer(
    approvalRequestReducer
  );
  const [locked, lockActions] = useLockState(true);
  const actions = React.useMemo(
    () => ({
      approvalRequests: makeSequenceActions(dispatch),
      ...lockActions,
    }),
    [dispatch, lockActions]
  );
  return [{ approvalRequests, locked }, actions];
};
