// @flow
import * as React from "react";
import ReviewApprovalRequestPage from "./ReviewApprovalRequestPage";
import useAjaxErrorInterceptor from "../../hooks/useAjaxInterceptor";
import * as approvalService from "../../services/backend.service/approval";
import {
  hasAnyValidToken,
  UserToken,
  VisitorToken,
} from "../../lib/session.lib";
import { useParams } from "react-router-dom";
import ReviewApprovalRequestProvider, {
  ReviewApprovalRequestContext,
} from "../../contexts/reviewApprovalRequest";

const Inner: React.ComponentType<{}> = () => {
  useAjaxErrorInterceptor();
  const { hashId } = useParams<{ hashId: string }>();
  const { onLoadApprovalRequest, loading } = React.useContext(
    ReviewApprovalRequestContext
  );
  const [showLogin, setShowLogin] = React.useState(!hasAnyValidToken());

  const loadApprovalRequest = React.useCallback(() => {
    onLoadApprovalRequest(hashId).catch((e) => {
      console.log(e);
      setShowLogin(true);
    });
  }, [hashId, onLoadApprovalRequest]);

  // If there is already a valid token at startup, load
  // data immediately.
  React.useEffect(() => {
    if (hasAnyValidToken()) {
      loadApprovalRequest();
    }
  }, [loadApprovalRequest]);

  const handleLogin = (username: string, password: string) => {
    return (
      approvalService
        .signIn({ hashId, password })
        // Sign in as visitor.
        .then(VisitorToken.set)
        // Sign out as user if there was one.
        .then(UserToken.unset)
        .then(() => setShowLogin(false))
        .then(loadApprovalRequest)
    );
  };

  return (
    <ReviewApprovalRequestPage
      onLogin={handleLogin}
      loginRequired={showLogin}
      loading={loading}
    />
  );
};

const ReviewApprovalRequestPageContainer: React.ComponentType<{}> = () => (
  <ReviewApprovalRequestProvider>
    <Inner />
  </ReviewApprovalRequestProvider>
);

export default ReviewApprovalRequestPageContainer;
