import * as account from "./account";
import * as analytics from "./analytics";
import * as approval from "./approval";
import * as auth from "./auth";
import * as channels from "./channels";
import * as engagement from "./engagement";
import * as feedback from "./feedback";
import * as hashtagGroup from "./hashtagGroup";
import * as integration from "./integration";
import * as messaging from "./messaging";
import * as misc from "./misc";
import * as notification from "./notification";
import * as portfolio from "./portfolio";
import * as post from "./post";
import * as profile from "./profile";
import * as race from "./race";
import * as subscription from "./subscription";

export const API = {
  account,
  analytics,
  approval,
  auth,
  channels,
  engagement,
  feedback,
  hashtagGroup,
  integration,
  messaging,
  misc,
  notification,
  portfolio,
  post,
  profile,
  race,
  subscription,
};
