import * as React from "react";
import type { ModelID } from "../types";
import routes, { getRoutePath } from "../config/routes.config";
import { useNavigate } from "react-router-dom";

const useRedirectToEditPost = (portfolioId: ModelID) => {
  const navigate = useNavigate();
  return React.useCallback(
    (postId: ModelID) =>
      navigate(
        getRoutePath(routes.app.nested.portfolios.nested.post.nested.edit, {
          portfolioId,
          postId,
        })
      ),
    [navigate, portfolioId]
  );
};

export default useRedirectToEditPost;
