// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Body1 } from "../lib/display/Text";
import type { SelfReply } from "../../models/post.model";
import PostMediaThumbnail from "./PostMediaThumbnail";

type Props = {
  selfReply: SelfReply,
};

const Root = styled("div")({
  display: "flex",
});

const PostPreviewContainer = styled("div")({
  width: 64,
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
});

const TextPreview = styled(Body1)(({ theme }) => ({
  // High enough for two lines.
  height: 58,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "pre-wrap",
  padding: theme.spacing(1),
}));

const SelfReplyPreview: React.ComponentType<Props> = ({ selfReply }) => {
  return (
    <Root>
      <PostPreviewContainer>
        <PostMediaThumbnail post={selfReply} />
      </PostPreviewContainer>
      <TextPreview>{selfReply.content}</TextPreview>
    </Root>
  );
};

export default SelfReplyPreview;
