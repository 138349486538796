// @flow
import * as React from "react";
import routes, { getRoutePath } from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import { Outlet, useNavigate } from "react-router-dom";
import { usePortfolioApprovalRequests } from "../../../store/selectors";
import AddResourceButton from "../../../components/inputs/AddResourceButton";
import ApprovalRequestsTable from "../../../components/widgets/ApprovalRequestsTable";
import { useScopedPortfolio } from "../../../scopes/scopes";
import * as approvalRequestService from "../../../services/approval.service";
import { useStoreActions } from "../../../store/store";
import { SkeletonApprovalRequestsTable } from "../../../components/widgets/ApprovalRequestsTable/ApprovalRequestsTable";
import StandardEmptyNotice from "../../../components/display/EmptyNotice";
import type { ModelID } from "../../../types";
import { InlineRowStack } from "../../../components/lib/layout/stacks";
import { Center } from "../../../components/lib/layout/placement";
import type { TableSelection } from "../../../components/lib/display/DataTable/base";

const ApprovalRequestsPage: React.ComponentType<empty> = () => {
  const pf = useScopedPortfolio();
  const actions = useStoreActions();
  const navigate = useNavigate();
  const approvalRequests = usePortfolioApprovalRequests(pf?.id);

  const handleDelete = React.useCallback(
    (selection: TableSelection<ModelID>) =>
      [...selection.selection].map((id) =>
        approvalRequestService.deleteApprovalRequest(actions)(id)
      ),
    [actions]
  );

  const handleEdit = React.useCallback(
    (approvalRequestId: ModelID) => {
      navigate(
        getRoutePath(
          routes.app.nested.portfolios.nested.approvalRequest.nested.edit,
          { portfolioId: pf?.id, approvalRequestId }
        )
      );
    },
    [pf?.id, navigate]
  );

  const route = routes.app.nested.portfolios.nested.approvals;
  return (
    <>
      <PrimaryPagePanel size="fill" route={route}>
        {approvalRequests.length ? (
          <>
            <InlineRowStack>
              <AddResourceButton
                listRoute={route}
                label="routes.addApprovalRequest.label"
                permission="can_use_post_approval"
              />
            </InlineRowStack>
            <ApprovalRequestsTable
              rows={approvalRequests}
              selectable
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          </>
        ) : (
          <Center>
            <StandardEmptyNotice
              label="global.noApprovalRequestYet"
              addResourceButtonProps={{
                size: "large",
                label: "routes.addApprovalRequest.label",
                permission: "can_use_post_approval",
                listRoute: route,
              }}
            >
              <SkeletonApprovalRequestsTable />
            </StandardEmptyNotice>
          </Center>
        )}
      </PrimaryPagePanel>
      <Outlet />
    </>
  );
};

export default ApprovalRequestsPage;
