// @flow
import * as React from "react";
import head from "lodash/head";
import identity from "lodash/identity";
import some from "lodash/some";
import mapValues from "lodash/mapValues";
import moment from "moment";
import type { Validator, ValidationError } from "../util/validation.util";
import { Moment } from "../types";
import type { Provider } from "../reactTypes";
import useTranslate from "./useTranslate";
import type { MaxSizeArg } from "../lib/files.lib";
import type { AttachmentTypes } from "../models/attachment.model";
import type { MediaItem } from "../components/lib/inputs/MediaInput/useHeadlessMediaInput";

/**
 * The type of value of a form field.
 */
type FormFieldType =
  | "string"
  | "number"
  | "datetime"
  | "boolean"
  | "object"
  | "string[]"
  | "number[]"
  | "media";

const FromInputValue: Record<FormFieldType, (string) => any> = {
  string: identity,
  number: Number,
  datetime: moment,
  boolean: Boolean,
  object: identity,
  media: identity,
  "string[]": identity,
  "number[]": identity,
};

// Types of form field definitions.
type FormFieldDefBase = { validate?: Validator };
export type NumberFormFieldDef = { ...FormFieldDefBase, type: "number" };
export type DatetimeFormFieldDef = { ...FormFieldDefBase, type: "datetime" };
export type BooleanFormFieldDef = { ...FormFieldDefBase, type: "boolean" };
export type MediaFormFieldDef = {
  ...FormFieldDefBase,
  type: "media",
  maxItems: number,
  maxItemSize: MaxSizeArg,
  mediaType: AttachmentTypes,
};
export type ObjectFormFieldDef = {
  ...FormFieldDefBase,
  type: "object",
};
export type StringFormFieldDef = {
  ...FormFieldDefBase,
  type: "string",
  maxLength?: number,
  preventOverflow?: boolean,
};
export type StringListFormFieldDef = {
  ...FormFieldDefBase,
  type: "string[]",
  maxLength?: number,
  preventOverflow?: boolean,
};
export type NumberListFormFieldDef = {
  ...FormFieldDefBase,
  type: "number[]",
  maxLength?: number,
  preventOverflow?: boolean,
};

export type ArrayFormFieldDef = StringListFormFieldDef | NumberListFormFieldDef;

type FormFieldDef =
  | StringFormFieldDef
  | NumberFormFieldDef
  | BooleanFormFieldDef
  | ObjectFormFieldDef
  | MediaFormFieldDef
  | ArrayFormFieldDef
  | DatetimeFormFieldDef;

type FormDefBase = Record<string, FormFieldDef>;
type FormFieldDefValueType = {
  any: any,
  string: string,
  "string[]": string[],
  "number[]": number[],
  number: number,
  datetime: Moment,
  boolean: boolean,
  object: Object,
  media: MediaItem[],
};
/** The values of the form. */
type FormFieldValue<FieldDef> = FormFieldDefValueType[FieldDef["type"]];
export type FormValues<Def> = $ObjMap<Def, <V>(V) => FormFieldValue<V>>;
/** The whole form state */
type FormState<Def> = $ObjMap<Def, <V>(V) => FormField<V>>;
/** The whole form state */

/**
 * A single form field.
 */
export type FormField<FieldDef = any> = {
  name: string,
  definition: FieldDef,
  value: FormFieldValue<FieldDef>,
  error: ?ValidationError,
  initial: ?FormFieldValue<FieldDef>,
  helperText?: string,
  fromString: (string) => FormFieldValue<FieldDef>,
};

type SetForm<Def> = ((form: FormState<Def>) => FormState<Def>) => void;
export type OnChangeField<FieldDef: FormFieldDef = any> = (
  value: FormFieldValue<FieldDef>
) => void;
export type OnSetFieldError = (error: ?ValidationError) => void;

export type Form<Def> = {
  state: FormState<Def>,
  set: SetForm<Def>,
};

const validateField =
  <Def: FormDefBase, FieldDef: FormFieldDef>(
    setForm: SetForm<Def>
  ): ((FormField<FieldDef>) => boolean) =>
  (field) => {
    if (!field.definition.validate) return false;
    const error = head(field.definition.validate(field.value));
    if (error) {
      setForm((form) => ({
        ...form,
        [field.name]: { ...form[field.name], error },
      }));
    }
    return !!error;
  };
const changeField =
  <Def: FormDefBase, FieldDef: FormFieldDef>(
    setForm: SetForm<Def>,
    name: $Keys<Def>
  ): OnChangeField<FieldDef> =>
  (value) => {
    setForm((formState) => {
      const def = formState[name].definition;
      let error: ?ValidationError = undefined;
      if (
        (typeof value === "string" || Array.isArray(value)) &&
        def.maxLength
      ) {
        const overflow = value.length > def.maxLength;
        if (def.preventOverflow && overflow) return formState;
        error = overflow
          ? { error: "global.isTooLong", data: { max: def.maxLength } }
          : null;
      }

      return {
        ...formState,
        [name]: {
          ...formState[name],
          value,
          error: error !== undefined ? error : formState[name].error,
          helperText: getHelperText(
            def,
            typeof value === "string" || Array.isArray(value) ? value : ""
          ),
        },
      };
    });
  };

const getHelperText = (def: FormFieldDef, value: string | any[]): string =>
  def.type === "string" && def.maxLength
    ? `${value.length} / ${def.maxLength}`
    : "";

const initFormState =
  <Def: FormDefBase>(
    definition: Def,
    defaults?: FormValues<Def>
  ): (() => FormState<Def>) =>
  () =>
    mapValues(definition, (def, name) => {
      const initial = defaults?.[name];
      const value = typeof initial === "undefined" ? null : initial;
      return {
        definition: def,
        name,
        value,
        error: undefined,
        initial: initial,
        helperText: getHelperText(def, typeof value === "string" ? value : ""),
        fromString: FromInputValue[def.type],
      };
    });

/**
 * Create and use a form, as a structured object.
 * @param definition The list of static fields definitions composing the form.
 * @param initial The initial value for each field. If missing, empty strings are used.
 */
export const useForm = <Def: Object>(
  definition: Def,
  initial?: FormValues<Def>
): Form<Def> => {
  const [state, set] = React.useState<FormState<Def>>(
    initFormState<Def>(definition, initial)
  );
  return { state, set };
};

export const FormActions = {
  reset: <Def: Object>(setForm: SetForm<Def>, initial: FormValues<Def>): void =>
    setForm((form: FormState<Def>) =>
      mapValues(form, (field, key) => ({
        ...field,
        error: undefined,
        value: initial[key] ?? null,
        initial: initial[key],
      }))
    ),
  collect: <Def: Object>(state: FormState<Def>): FormValues<Def> =>
    mapValues(state, (field: FormField<>) => field.value),
  validate: <Def: Object>(form: Form<Def>): boolean =>
    !some(mapValues(form.state, validateField<Def, any>(form.set))),
  validateField: <Def: Object, FieldDef: FormFieldDef>(
    setForm: SetForm<Def>,
    field: FormField<FieldDef>
  ): boolean => validateField<Def, FieldDef>(setForm)(field),
  onChangeField: <Def: Object, K: $Keys<Def>>(
    setForm: SetForm<Def>,
    name: K
  ): OnChangeField<Def[K]> => changeField<Def, Def[K]>(setForm, name),
  onSetFieldError:
    <Def: Object>(setForm: SetForm<Def>, name: $Keys<Def>): OnSetFieldError =>
    (error: ?ValidationError) =>
      setForm((form) => ({ ...form, [name]: { ...form[name], error } })),
};

/**
 * A Form context. Can be used to propagate a form down a component tree.
 * @type {React$Context<?Form>}
 */
export const FormContext: React.Context<?Form<any>> =
  React.createContext<?Form<any>>(null);

/**
 * A Form context provider to propagate a form down a component tree.
 */
export const FormContextProvider: Provider<Form<any>, { value: Form<any> }> = ({
  value,
  children,
}) => <FormContext.Provider value={value}>{children}</FormContext.Provider>;

export type FormFieldState<Def: FormDefBase, K = $Keys<Def>> = [
  FormField<Def[K]>,
  OnChangeField<Def[K]>
];

/**
 * Used by form aware components to get the form field associated to a
 * name.
 * @param name The name of the form field.
 */
export const useContextFormField = <Def: FormDefBase, K: $Keys<Def>>(
  name: K
): FormFieldState<Def, K> => {
  const { state, set } = React.useContext<?Form<Def>>(FormContext) ?? {};
  if (!state || !set) throw Error("Unable to find form field.");
  return [state[name], changeField<Def, Def[K]>(set, name)];
};

export const useFormFieldError = (formField: FormField<any>): ?string => {
  const t = useTranslate();
  const error = formField.error;
  return !error
    ? null
    : error.field
    ? t(`util.validation.${error.field}.${error.error}`, error.data)
    : t(error.error, error.data);
};
