// @flow
import * as React from "react";
import withOnVisible from "./withOnVisible";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import type { Callback } from "../../../types";
import type { CSSProps } from "../../../reactTypes";

type Props = {
  onVisible: Callback,
  ...CSSProps,
};

const _Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(12, 0),
  textAlign: "center",
}));

const Root = withOnVisible(_Root);

const LoadMoreMarker: React.ComponentType<Props> = (props) => (
  <Root {...props}>
    <CircularProgress size={24} />
  </Root>
);

export default LoadMoreMarker;
