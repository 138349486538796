// @flow
import * as React from "react";
import RichMessageInput from "./RichMessageInput";
import type { LocalAttachment } from "../../../../models/attachment.model";
import type { ValidationError } from "../../../../util/validation.util";
import without from "lodash/without";
import { createUploadLocalAttachment } from "../../../../services/post.service";
import type { CSSProps } from "../../../../reactTypes";

type Props = {
  onSubmit: (text: string, attachments: LocalAttachment[]) => any,
  ...CSSProps,
};

const RichMessageInputContainer: React.ComponentType<Props> = ({
  onSubmit,
  ...props
}) => {
  const [attachments, setAttachments] = React.useState<LocalAttachment[]>([]);
  const [fileError, setFileError] = React.useState<?ValidationError>();

  const handleSelectFiles = React.useCallback((files: File[]) => {
    setAttachments((prevAttachments) => [
      ...prevAttachments,
      ...files.map((f) => createUploadLocalAttachment(f)),
    ]);
  }, []);

  const handleDeleteFile = React.useCallback((deleted: File) => {
    setAttachments((prevAttachments) =>
      without(
        prevAttachments,
        prevAttachments.find((att) => att.file === deleted)
      )
    );
  }, []);

  React.useEffect(() => {
    // Reset the error whenever there is a successfull change in items.
    setFileError(null);
  }, [attachments]);

  const handleSubmit = (text: string) => {
    // Use Promise.resolve to ensure a Promise result.
    return Promise.resolve(onSubmit(text, attachments)).then(() =>
      setAttachments([])
    );
  };

  return (
    <RichMessageInput
      onSubmit={handleSubmit}
      empty={attachments.length === 0}
      fileError={fileError}
      onFileError={setFileError}
      files={attachments.map((att) => att.file)}
      onDeleteFile={handleDeleteFile}
      onSelectFiles={handleSelectFiles}
      {...props}
    />
  );
};

export default RichMessageInputContainer;
