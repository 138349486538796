// @flow
import * as React from "react";
import Image from "../lib/display/Image";
import { styled } from "@mui/material/styles";
import type { PostAttachment } from "../../models/post.model";
import Video from "../lib/display/Video";
import {
  getAttachmentKey,
  getAttachmentPoster,
  getAttachmentSrc,
} from "../../models/attachment.model";
import PDFPreview from "../lib/display/PDFPreview";
import type { CSSProps, SyntheticEventListener } from "../../reactTypes";
import omit from "lodash/omit";

export type Props = {
  attachment: PostAttachment,
  videoOffset?: ?number,
  controls?: boolean,
  onClick?: SyntheticEventListener,
  ...CSSProps,
};

const COVER_MIXIN = {
  width: "100%",
  minHeight: "100%",
  objectFit: "cover",
};

const CoverImage = styled(Image)(COVER_MIXIN);
const CoverVideo = styled(Video)(COVER_MIXIN);

const AttachmentPreview: React.ComponentType<Props> = ({
  attachment,
  videoOffset,
  ...props
}) => {
  switch (attachment.type) {
    case "image":
      return (
        <CoverImage
          alt={getAttachmentKey(attachment)}
          src={getAttachmentSrc(attachment)}
          loading="lazy"
          {...props}
        />
      );
    case "video":
      return (
        <CoverVideo
          key={attachment.id}
          src={getAttachmentSrc(attachment)}
          // If the media comes from a saved attachment, try to get the associated poster.
          poster={getAttachmentPoster(attachment, videoOffset)}
          {...props}
        />
      );
    default:
      return (
        <PDFPreview
          src={getAttachmentSrc(attachment)}
          {...omit(props, "controls")}
        />
      );
  }
};

export default AttachmentPreview;
