// @flow
import type { ModelID, Moment, Timestamp } from "../types";
import type { PlanFlavour } from "./plan.model";
import type { BillingInfo } from "./billingInfo.model";
import moment from "moment";

export type SetupIntent = {
  client_secret: string,
  ...
};

export type CardDetails = {
  brand: string,
  exp_month: number,
  exp_year: number,
  last4: string,
};

export type SubscriptionStates =
  | "trialing"
  | "active"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "canceled"
  | "unpaid";

// Normal states.
const OK_STATES = ["trialing", "active"];
// There may be something not quite right, but we don't require immediate action.
const ACCEPTABLE_STATES = ["incomplete", "past_due", "trialing", "active"];

export const isSubscriptionStateOK = (state: ?SubscriptionStates): boolean =>
  OK_STATES.includes(state);
export const isSubscriptionStateAcceptable = (
  state: ?SubscriptionStates
): boolean => ACCEPTABLE_STATES.includes(state);

export const isSubscriptionActive = (state: ?SubscriptionStates): boolean =>
  state === "active";

export type Subscription = {
  created_at: Moment,
  plan: PlanFlavour,
  billing_info: BillingInfo,
  status: SubscriptionStates,
  is_frozen: boolean,
  send_invoices: boolean,
  add_ons: ModelID[],
};

export type APISubscription = {
  ...Omit<Subscription, "created_at">,
  created_at: Timestamp,
};

export const fromAPIResult = (sub: APISubscription): Subscription =>
  sub
    ? {
        ...sub,
        created_at: moment.unix(sub.created_at),
      }
    : sub;
