// @flow
import * as React from "react";
import useTranslate from "../../hooks/useTranslate";
import type { ContentTypes } from "../../models/contentTypes.model";
import ColoredChip from "../lib/display/ColoredChip";
import { Body2 } from "../lib/display/Text";

type Props = {
  type: ContentTypes,
  dense?: boolean,
};

const ContentTypeChip: React.ComponentType<Props> = React.memo(
  ({ type, dense }) => {
    const t = useTranslate();
    return (
      <ColoredChip paletteColor="contentType" dense={dense}>
        <Body2>{t(`global.${type}`)}</Body2>
      </ColoredChip>
    );
  }
);

export default ContentTypeChip;
