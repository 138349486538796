// @flow
import { GBYTE, MBYTE } from "../../lib/files.lib";
import type { Channel } from "./channel.model";
import {
  login,
  wasLoginCancelled,
} from "../../services/channels/tiktok.service";

export type PrivacyLevelOptions =
  | "PUBLIC_TO_EVERYONE"
  | "MUTUAL_FOLLOW_FRIENDS"
  | "SELF_ONLY"
  | "FOLLOWER_OF_CREATOR";

export type TiktokErrorCode =
  | "spam_risk_too_many_posts"
  | "spam_risk_user_banned_from_posting"
  | "access_token_invalid"
  | "scope_not_authorized"
  | "rate_limit_exceeded";

export type CreatorInfo = {
  creator_avatar_url: string,
  creator_username: string,
  creator_nickname: string,
  /**
   * If the TikTok creator account is public, the available options are:
   * PUBLIC_TO_EVERYONE
   * MUTUAL_FOLLOW_FRIENDS
   * SELF_ONLY
   *
   * If the TikTok creator account is private, the available options are:
   * FOLLOWER_OF_CREATOR
   * MUTUAL_FOLLOW_FRIENDS
   * SELF_ONLY
   */
  privacy_level_options: PrivacyLevelOptions[],
  /**
   * Returns true if the creator sets the comment interaction to "No one"
   * in their privacy setting.
   */
  comment_disabled?: boolean,
  /**
   * Returns true if the creator account is private or they set the Duet
   * interaction to "No one" in their privacy setting. IGNORE if your
   * client only sends photo media through this API.
   */
  duet_disabled?: boolean,
  /**
   * Returns true if the creator account is private or they set the Stitch
   * interaction to "No one" in their privacy setting. IGNORE if your
   * client only sends photo media through this API.
   */
  stitch_disabled?: boolean,
  /**
   * The longest video duration in seconds that the TikTok creator can
   * post. Different users have different maximum video-duration
   * privileges. Developers should use this field to stop video posts
   * that are too long. IGNORE if your client only sends photo media
   * through this API.
   */
  max_video_post_duration_sec: number,
  error?: false,
};

/**
 * Returned when fetching creator info in case there is an error.
 */
export type CreatorInfoError = {
  error: true,
  code: TiktokErrorCode,
  message: string,
};

export type MixedCreatorInfo = CreatorInfo | CreatorInfoError;

const tiktok: Channel = {
  name: "Tiktok",
  slug: "tiktok",
  color: "#000000",

  features: {
    content: {
      title: {
        maxCharsIfVideo: 150,
        maxCharsIfImages: 90,
      },
      thumbnail: {
        video: true,
      },
    },
  },

  contentSpecs: {
    maxChars: 150,
    media: {
      video: {
        noCaption: true,
        mimetypes: ["video/mov", "video/mp4", "video/webm"],
        maxSize: GBYTE,
        maxCount: 1,
      },
      image: {
        mimetypes: [], // ["image/jpeg", "image/webp"],
        maxCount: 0, // 10,
        maxSize: 20 * MBYTE,
      },
      overall: {
        maxCount: 1, // 10,
        minCount: 1,
      },
    },
  },

  subchannelTypes: [],
  surfaces: [],
  wasLoginCancelled,
  login,
};

export type UploadPostInfo = {
  /**
   * If the TikTok creator account is public, the available options are:
   * PUBLIC_TO_EVERYONE
   * MUTUAL_FOLLOW_FRIENDS
   * SELF_ONLY
   *
   * If the TikTok creator account is private, the available options are:
   * FOLLOWER_OF_CREATOR
   * MUTUAL_FOLLOW_FRIENDS
   * SELF_ONLY
   */
  privacy_level?: PrivacyLevelOptions,
  /**
   * Returns true if the creator sets the comment interaction to "No one"
   * in their privacy setting.
   */
  disable_comment?: boolean,
  /**
   * Only works for post_mode = DIRECT POST.
   * Set to true if this content is promoting the creator's own business.
   */
  brand_content_toggle?: boolean,
  /**
   *  Only works for post_mode = DIRECT POST.
   *  Set to true if the content is a paid partnership to promote a
   *  third-party business.
   */
  brand_organic_toggle?: boolean,
};

type UploadVideoPostInfo = {
  ...UploadPostInfo,
  disable_stitch?: boolean,
  disable_duet?: boolean,
};

type UploadImagesPostInfo = {
  ...UploadPostInfo,
  auto_add_music?: boolean,
};

export type TiktokExtras = {
  ...UploadVideoPostInfo,
  ...UploadImagesPostInfo,
  commercialContent: boolean,
};

export const getDefaultExtras = (): TiktokExtras => ({
  // As per Tiktok's guidelines, all should be disabled by default.
  disable_comment: true,
  disable_duet: true,
  disable_stitch: true,
  auto_add_music: false,
  commercialContent: false,
});

export default tiktok;
