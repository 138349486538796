// @flow
import * as React from "react";
import Select from "../lib/inputs/Select";
import type { BobcaatAccount } from "../../models/account.model";
import { getAccountLongName } from "../../models/account.model";
import type { PostStatus } from "../../models/post.model";
import { ALL_ACTIVE_STATUSES } from "../../models/post.model";
import PostFiltersContext from "../../contexts/postFilters";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { byId } from "../../models/base.model";
import useTranslate from "../../hooks/useTranslate";
import type { Props as TextFieldProps } from "../../stubs/mui/TextField";
import { Chip } from "@mui/material";
import { toDateString } from "../../lib/time.lib";
import without from "lodash/without";

type Props = {
  accounts: BobcaatAccount[],
  size?: TextFieldProps["size"],
  statusOptions?: $ReadOnlyArray<PostStatus>,
};

const PostFilterInputs: React.ComponentType<Props> = ({
  accounts,
  size,
  statusOptions = ALL_ACTIVE_STATUSES,
}) => {
  const t = useTranslate();
  const {
    accountIds,
    onSetAccountIds,
    statuses,
    onSetStatuses,
    fromDate,
    onSetFromDate,
    toDate,
    onSetToDate,
    postIds,
    onSetPostIds,
  } = React.useContext(PostFiltersContext);
  const selectedAccounts = compact(
    accountIds.map((accId) => accounts.find(byId(accId)))
  );
  const getStatusLabel = React.useCallback(
    (status: PostStatus) => t("refer.postStatus." + status),
    [t]
  );

  return (
    <>
      {!isEmpty(accounts) && (
        <Select
          id="select-accounts"
          width={{ sm: 220, md: 250 }}
          value={selectedAccounts}
          multiple
          size={size}
          onChange={(accs) => onSetAccountIds(accs.map((acc) => acc.id))}
          getOptionLabel={getAccountLongName}
          label={t("global.filterByAccount")}
          options={accounts}
          renderValue={(accounts) =>
            accounts.length > 1
              ? t("global.countSelected", { count: accounts.length })
              : getAccountLongName(accounts[0])
          }
        />
      )}
      <Select
        id="select-statuses"
        width={{ sm: 180, md: 200 }}
        value={statuses}
        multiple
        size={size}
        onChange={onSetStatuses}
        getOptionLabel={getStatusLabel}
        label={t("global.filterByStatus")}
        options={statusOptions}
        renderValue={(statuses) => statuses.map(getStatusLabel).join(", ")}
      />
      {fromDate && (
        <Chip
          label={t("global.fromDate", { date: toDateString(fromDate) })}
          onDelete={() => onSetFromDate(null)}
        />
      )}
      {toDate && (
        <Chip
          label={t("global.toDate", { date: toDateString(toDate) })}
          onDelete={() => onSetToDate(null)}
        />
      )}
      {postIds.map((postId) => (
        <Chip
          label={t("global.post") + " " + postId.toString(16)}
          onDelete={() => onSetPostIds(without(postIds, postId))}
        />
      ))}
    </>
  );
};

export default PostFilterInputs;
