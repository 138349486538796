// @flow
import * as React from "react";
import moment from "moment";
import type { GMBTopicDef } from "../../../../config/gmbTopics.config";
import GMB_TOPICS, { getGMBTopic } from "../../../../config/gmbTopics.config";
import {
  EmojiEventsRounded,
  LocalOfferRounded,
  PostAddRounded,
} from "@mui/icons-material";
import type {
  CallToAction,
  Event,
  GMBExtras,
  Offer,
  TopicTypes,
} from "../../../../models/channels/googleMyBusiness.model";
import { getDefaultExtras } from "../../../../models/channels/googleMyBusiness.model";
import { styled } from "@mui/material/styles";
import { Collapse, Fade, TextField, ToggleButton } from "@mui/material";
import type { Callback } from "../../../../types";
import useTranslate from "../../../../hooks/useTranslate";
import PostBuilderContext from "../../../../contexts/postBuilder";
import Section from "../../../layout/Section";
import { ColumnStack, RowStack } from "../../../lib/layout/stacks";
import { Body2 } from "../../../lib/display/Text";
import Select from "../../../lib/inputs/Select";
import gmbActionTypes, {
  getName,
  getValue,
} from "../../../../config/gmbActionTypes";
import { FlexGrow } from "../../../lib/layout/boxes";
import DateRangeInputs from "../../../inputs/DateRangeSelect/DateRangeInputs";

const TOPIC_TO_ICON: Record<
  TopicTypes,
  React.ComponentType<{ color?: string, fontSize: "large" }>
> = {
  STANDARD: PostAddRounded,
  EVENT: EmojiEventsRounded,
  OFFER: LocalOfferRounded,
};

const StatusButtonRoot = styled(ToggleButton)(({ theme }) => ({
  flexDirection: "column",
  gap: theme.spacing(1),
  border: "none",
  minWidth: 100,
}));

const getTopicIcon = (type: TopicTypes) => TOPIC_TO_ICON[type];

type TopicButtonProps = {
  topic: GMBTopicDef,
  selected?: boolean,
  onClick: Callback,
};

const TopicButton: React.ComponentType<TopicButtonProps> = React.memo(
  ({ topic, ...props }) => {
    const Icon = getTopicIcon(topic.type);
    const t = useTranslate();

    return (
      <StatusButtonRoot {...props} value={topic.type}>
        <Icon color={props.selected ? "primary" : "inherit"} fontSize="large" />
        {t(topic.name)}
      </StatusButtonRoot>
    );
  }
);

type CTASectionProps = {
  cta: CallToAction,
  onChange: (CallToAction) => any,
};

const CTASection: React.ComponentType<CTASectionProps> = ({
  cta,
  onChange,
}) => {
  const t = useTranslate();
  const value = gmbActionTypes.find((t) => t.value === cta.type);
  return (
    <Section title="global.callToAction">
      <Select
        id="select-call-to-action"
        multiple={false}
        translateLabel
        value={value ?? ""}
        helperText={t(
          value?.description ?? "refer.channels.gmb.cta.actionTypeHelper"
        )}
        label={t("refer.channels.gmb.cta.actionType")}
        onChange={(type) => onChange({ ...cta, type: type.value })}
        getOptionLabel={getName}
        getOptionValue={getValue}
        options={gmbActionTypes}
      />
      <TextField
        margin="none"
        value={cta.url}
        required={cta.type !== "CALL"}
        type="url"
        onChange={(e) => onChange({ ...cta, url: e.currentTarget.value })}
        label={t("global.url")}
        helperText={t("refer.channels.gmb.cta.URLHelper")}
      />
    </Section>
  );
};

type EventSectionProps = {
  event: Event,
  onChange: (Event) => any,
};

const EventSection: React.ComponentType<EventSectionProps> = ({
  event,
  onChange,
}) => {
  const t = useTranslate();
  return (
    <Section title="global.event">
      <TextField
        value={event.title}
        onChange={(e) => onChange({ ...event, title: e.currentTarget.value })}
        label={t("global.title")}
        helperText={t("refer.channels.gmb.event.titleHelper")}
        margin="none"
      />
      <DateRangeInputs
        minFromDate={moment()}
        maxFromDate={event.schedule.end}
        minToDate={event.schedule.start}
        fromDate={event.schedule.start}
        toDate={event.schedule.end}
        onSetFromDate={(start) =>
          onChange({ ...event, schedule: { ...event.schedule, start } })
        }
        onSetToDate={(end) =>
          onChange({ ...event, schedule: { ...event.schedule, end } })
        }
      />

      <Body2 color="textSecondary">
        {t("refer.channels.gmb.event.scheduleHelper")}
      </Body2>
    </Section>
  );
};

type OfferSectionProps = {
  offer: Offer,
  onChange: (Offer) => any,
};

const OfferSection: React.ComponentType<OfferSectionProps> = ({
  offer,
  onChange,
}) => {
  const t = useTranslate();
  return (
    <Section title="global.offer">
      <TextField
        margin="none"
        value={offer.coupon}
        onChange={(e) => onChange({ ...offer, coupon: e.currentTarget.value })}
        label={t("refer.channels.gmb.offer.couponCode")}
        helperText={t("refer.channels.gmb.offer.couponCodeHelper")}
      />
      <TextField
        margin="none"
        value={offer.url}
        type="url"
        onChange={(e) => onChange({ ...offer, url: e.currentTarget.value })}
        label={t("refer.channels.gmb.offer.redeemOnlineUrl")}
        helperText={t("refer.channels.gmb.offer.redeemOnlineUrlHelper")}
      />
      <TextField
        margin="none"
        multiline
        rows={3}
        maxRows={5}
        value={offer.terms}
        onChange={(e) => onChange({ ...offer, terms: e.currentTarget.value })}
        label={t("global.termsOfAgreement")}
        helperText={t("refer.channels.gmb.offer.termsConditionsHelper")}
      />
    </Section>
  );
};

const GmbContentEditor: React.ComponentType<{}> = () => {
  const {
    post: { extras: rawExtras },
    onUpdatePost,
    alreadyPublished,
  } = React.useContext(PostBuilderContext);
  const extras: GMBExtras = rawExtras ?? getDefaultExtras();
  const currentTopic = getGMBTopic(extras.topic);

  return (
    <RowStack spacing={2} alignItems="flex-start">
      <Fade in={!alreadyPublished} unmountOnExit>
        <ColumnStack>
          {GMB_TOPICS.map((topic) => (
            <TopicButton
              key={topic.name}
              topic={topic}
              onClick={() =>
                onUpdatePost({ extras: { ...extras, topic: topic.type } })
              }
              selected={currentTopic === topic}
            />
          ))}
        </ColumnStack>
      </Fade>
      <FlexGrow>
        <Collapse in={currentTopic.supportsCTA} unmountOnExit>
          <CTASection
            cta={extras.cta}
            onChange={(cta) => onUpdatePost({ extras: { ...extras, cta } })}
          />
        </Collapse>
        <Collapse in={currentTopic.supportsEvent} unmountOnExit>
          <EventSection
            event={extras.event}
            onChange={(event) => onUpdatePost({ extras: { ...extras, event } })}
          />
        </Collapse>
        <Collapse in={currentTopic.supportsOffer} unmountOnExit>
          <OfferSection
            offer={extras.offer}
            onChange={(offer) => onUpdatePost({ extras: { ...extras, offer } })}
          />
        </Collapse>
      </FlexGrow>
    </RowStack>
  );
};

export default GmbContentEditor;
