// @flow
import * as React from "react";
import { defaultStyles, FileIcon } from "react-file-icon";
import useObjectURL from "../../../hooks/useObjectURL";
import { getExtension } from "../../../lib/files.lib";
import { Tooltip } from "@mui/material";
import type { CSSProps } from "../../../reactTypes";
import useTranslate from "../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";

export type Props = {
  src: string | File,
  width?: number,
  openOnClick?: boolean,
  ...CSSProps,
};

const Root = styled("div", { shouldForwardProp: (prop) => prop !== "width" })(
  ({ width }) => ({
    width,
  })
);

const _File: React.ComponentType<Props> = React.forwardRef(
  ({ src, openOnClick, ...props }, ref) => {
    const t = useTranslate();
    const srcString = src instanceof File ? src.name : src;
    const fileURL = useObjectURL(src);
    const ext = React.useMemo(() => getExtension(srcString), [srcString]);

    return (
      <Tooltip
        title={openOnClick ? t("global.download") : ""}
        disabled={!openOnClick}
      >
        <Root
          ref={ref}
          style={{ cursor: openOnClick ? "pointer" : undefined }}
          onClick={openOnClick ? () => window.open(fileURL) : undefined}
          {...props}
        >
          <FileIcon extension={ext} {...defaultStyles[ext]} />
        </Root>
      </Tooltip>
    );
  }
);

export default _File;
