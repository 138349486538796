// @flow
import * as React from "react";
import useObjectURL from "../../../hooks/useObjectURL";
import type { ObjectURLSrc } from "../../../hooks/useObjectURL";
import type { Pixels } from "../../../types";

export type Props = {
  alt: string,
  height?: Pixels | string,
  width?: Pixels | string,
  src?: ObjectURLSrc,
  loading?: "lazy" | "eager",
};

const Image: React.ComponentType<Props> = React.forwardRef(
  ({ src, alt, ...props }, ref) => (
    <img ref={ref} alt={alt} src={useObjectURL(src)} {...props} />
  )
);
export default Image;
