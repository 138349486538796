// @flow
import * as React from "react";
import noop from "lodash/noop";
import usePersistentState from "../hooks/usePersistentState";
import type { Provider, StateSetter } from "../reactTypes";
import { DEFAULT_CURRENCY } from "../config/currencies.config";
import type { CurrencyCode } from "../types";

type _CurrencyContext = {
  currency: CurrencyCode,
  onSetCurrency: StateSetter<CurrencyCode>,
};

export const CurrencyContext: React.Context<_CurrencyContext> =
  React.createContext({
    onSetCurrency: noop,
    currency: DEFAULT_CURRENCY.name,
  });

/**
 * Used only once to wrap the whole app with a currency context.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const CurrencyProvider: Provider<_CurrencyContext> = ({ children }) => {
  const [currency, setCurrency] = usePersistentState(
    "currency",
    DEFAULT_CURRENCY.name
  );

  return (
    <CurrencyContext.Provider value={{ onSetCurrency: setCurrency, currency }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyContext;
