// @flow
import { stripeCodeForCountry } from "../../../../config/countries.config";
import type { Inexact, ModelID } from "../../../../types";
import type { TransientBillingInfo } from "../../../../models/billingInfo.model";
import { useStripe } from "@stripe/react-stripe-js";
import * as env from "../../../../config/env.config";
import * as snacks from "../../../../models/alerts.model";
import * as Sentry from "@sentry/react";
import { useStoreActions } from "../../../../store/store";
import { useGuardedElements } from "./useGuardedElements";

type SubscribeProps = {
  planFlavourId: ModelID,
  billingInfo: Inexact<TransientBillingInfo>,
  action: "subscribe",
};

type UpdateProps = {
  planFlavourId?: void,
  billingInfo: Inexact<TransientBillingInfo>,
  action: "update",
};

type Props = SubscribeProps | UpdateProps;
type ConfirmSetupIntent = (Props) => Promise<void>;

const useGuardedStripe = () => {
  try {
    return useStripe();
  } catch (e) {
    return null;
  }
};

const useConfirmSetupIntent = (): ?ConfirmSetupIntent => {
  const actions = useStoreActions();
  const stripe = useGuardedStripe();
  const elements = useGuardedElements();

  if (!stripe || !elements) return null;

  return (props: Props) => {
    // Confirm the Setup Intent.
    // User will be redirected to a page where they can continue with
    // any bank imposed security check.
    // On success, user will be redirected to the specified return_url
    // and the promise will never return.
    // On error, the promise will resolve with an error object.
    const { billingInfo } = props;
    const endpoint =
      props.action === "subscribe"
        ? `/subscription/checkout/subscribe/${props.planFlavourId}`
        : "/subscription/checkout/update";

    const billing_details = {
      name: billingInfo.entity_name,
      email: billingInfo.email,
      address: {
        city: billingInfo.city,
        country: stripeCodeForCountry(billingInfo.country),
        line1: billingInfo.street,
        state: billingInfo.state_or_region,
        postal_code: billingInfo.postcode,
      },
    };

    return (
      stripe
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: env.API_URL + endpoint,
            payment_method_data: { billing_details },
          },
        })
        // This promise should never resolve, unless there is an error.
        .then((result) => {
          if (result.error) {
            console.log("Could not proceed with stripe setup.", {
              ...result.error,
            });
            actions.snacks.append(
              snacks.error({
                message: result.error.message,
              })
            );
            throw result.error;
          }
        })
        .catch(Sentry.captureException)
    );
  };
};

export default useConfirmSetupIntent;
