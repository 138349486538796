// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import type { Theme } from "../../../stubs/mui/theming";
import type { DefaultSomeProps } from "../../../reactTypes";

/**
 * Create a component type that uses some props just for styling
 * and renders the specified underlying component types without the
 * extra props.
 * @param Component The rendered component.
 * @param styleProps The keys of the styling props.
 * @param callback The styling callback.
 */
export const withStyleProps = <
  Props: Record<string, any>,
  StyleProps: string = $Keys<Props>
>(
  Component: React.ComponentType<Partial<Props>>,
  styleProps: StyleProps[],
  callback: ({ theme: Theme, ...Props }) => string | Object
): React.ComponentType<Props> =>
  styled(
    React.forwardRef((props, ref) => <Component {...props} ref={ref} />),
    { shouldForwardProp: (prop: string) => !styleProps.includes(prop) }
  )(callback);

export const withDefaultProps = <Props: Object, Overriden: $Keys<Props>>(
  Component: React.ComponentType<Props>,
  defaultProps: Pick<Props, Overriden>
): React.ComponentType<DefaultSomeProps<Props, Overriden>> =>
  React.forwardRef((props, ref) => (
    <Component {...defaultProps} {...props} ref={ref} />
  ));
