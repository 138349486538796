// @flow
import type {
  Attachment,
  AttachmentTypes,
  LocalAttachment,
} from "./attachment.model";
import { isAttachmentImage, isAttachmentVideo } from "./attachment.model";
import type {
  FindIteratee,
  I18nKey,
  Inexact,
  Integer,
  ModelID,
  Moment,
  Slug,
  Timestamp,
  URLStr,
} from "../types";
import type { Poll } from "./poll.model";
import type { PostApprovalStatuses } from "./postApproval.model";
import moment from "moment/moment";
import type { Timezone } from "./timezone.model";
import { getTimezone } from "./timezone.model";
import { toMoment } from "../lib/time.lib";
import overEvery from "lodash/overEvery";
import uniqueId from "lodash/uniqueId";
import { getUrlType } from "../lib/files.lib";
import type { MediaRef } from "./base.model";
import type { ApprovalRequest } from "./approvalRequest.model";
import type { ContentTypes } from "./contentTypes.model";
import instagram from "./channels/instagram.model";
import type { Channel } from "./channels/channel.model";
import { every } from "lodash";
import type { Periodicity } from "./periodicity.model";
import { periodicityFromString } from "./periodicity.model";

/**
 * Stands for an attachment to a self reply.
 */
export type SelfReplyAttachment = Attachment;

/** Specifies the current publication state of a post. */
export type PostStatus = "draft" | "scheduled" | "published" | "deleted";

/** Stands for an intention to reply to an authored post by another one. */
export type SelfReply = {
  content: ?string,
  attachments: SelfReplyAttachment[],
};

export const EMPTY_SELF_REPLY: SelfReply = {
  content: "",
  attachments: [],
};

export const isSelfReplyNotEmpty = (selfReply: SelfReply): boolean =>
  (selfReply.content?.length ?? 0) > 0 || selfReply.attachments.length > 0;

/**
 * Stands for an attachment that comes along a Post.
 */
export type PostAttachment = Attachment;

/**
 * The attributes of a post that can be mutated by a user.
 */
export type MutablePost = {
  title: ?string,
  account_id: ModelID,
  content: string,
  status: PostStatus,
  schedule_time: ?Moment,
  local_schedule_tz_id: ?ModelID,
  link: ?string,
  poll: ?Poll,
  attachments: PostAttachment[],
  content_type: ContentTypes,
  subchannel_id: ?ModelID,
  approval: ?PostApprovalStatuses,
  thumbnail_url: ?URLStr,
  thumbnail_video_offset: ?Integer,
  thumbnail_title: ?string,
  first_comment: ?string,
  /** For those channels with special post fields that are not shareable. */
  extras: ?Object,
  secondary_account_ids: ModelID[],
  disable_autopublish: ?boolean,
  self_replies: SelfReply[],
  periodicity: ?Periodicity,
};

/**
 * Just enough to identify updates to a post.
 */
export type IdentifiedMutablePost = {
  ...MutablePost,
  id: ModelID,
};

/**
 * The core attributes of a post, without the extras added for
 * ease of use.
 */
export type CorePost = {
  ...IdentifiedMutablePost,
  effective_time: ?Moment,
  last_scheduling_error: ?string,
  created_at: Moment,
  /** The ID of the post this post is in reply to. */
  reply_to_id: ?ModelID,
};

/**
 * A bobcaat post.
 */
export type Post = {
  ...CorePost,
  channel: Slug,
  channels: Slug[],
  secondary_channels: Slug[],
  timezone: ?Timezone,
  portfolio_id: ModelID,
  account_name?: string,
};

export type APIPost = {
  ...Omit<
    Post,
    | "schedule_time"
    | "effective_time"
    | "timezone"
    | "created_at"
    | "periodicity"
  >,
  schedule_time?: Timestamp,
  effective_time?: Timestamp,
  created_at?: Timestamp,
  periodicity?: string,
};

/**
 * This function must be called on all posts received from the server.
 * It has 2 responsibilities:
 *  - deserialise complex types, like datetimes and timezones.
 *  - make sure all the attributes are included in the object, even if they
 *    were not sent by the server because they were null.
 * @param post {object}
 */
export const fromAPIResult = (post: APIPost): Post => {
  const timezone = getTimezone(post.local_schedule_tz_id);
  const schedule_time = toMoment(post.schedule_time, timezone);
  const effective_time = toMoment(post.effective_time, timezone);
  return {
    id: post.id,
    title: post.title,
    account_id: post.account_id,
    content: post.content ?? "",
    status: post.status ?? "draft",
    schedule_time,
    effective_time,
    local_schedule_tz_id: post.local_schedule_tz_id,
    timezone,
    link: post.link,
    attachments: post.attachments ?? [],
    content_type: post.content_type,
    channel: post.channel,
    portfolio_id: post.portfolio_id,
    last_scheduling_error: post.last_scheduling_error,
    subchannel_id: post.subchannel_id,
    created_at: moment.unix(post.created_at),
    approval: post.approval,
    thumbnail_url: post.thumbnail_url,
    thumbnail_video_offset: post.thumbnail_video_offset,
    thumbnail_title: post.thumbnail_title,
    first_comment: post.first_comment,
    extras: post.extras,
    secondary_account_ids: post.secondary_account_ids ?? [],
    secondary_channels: post.secondary_channels ?? [],
    channels: [post.channel, ...(post.secondary_channels ?? [])],
    poll: post.poll ?? null,
    disable_autopublish: !!post.disable_autopublish,
    self_replies: post.self_replies ?? [],
    reply_to_id: post.reply_to_id ?? null,
    periodicity: periodicityFromString(post.periodicity),
  };
};

const constantTrue = () => true;

/**
 * Get the scheduled or published time of a post.
 * The time is cloned on the fly to support mutation without side effects.
 */
export const getTime = (post: ?Inexact<Post>): ?Moment => {
  if (!post) return undefined;
  const time = post.effective_time ?? post.schedule_time;
  return time ? time.clone() : undefined;
};

export const postTimeSameOrAfter = (time: ?Moment): FindIteratee<Post> =>
  time ? (post) => getTime(post)?.isSameOrAfter(time) ?? true : constantTrue;
export const postTimeBeforeEndOfDay = (time: ?Moment): FindIteratee<Post> => {
  if (!time) return constantTrue;
  const eod = moment(time).add(1, "day");
  return (post) => getTime(post)?.isBefore(eod) ?? true;
};

export const getTimeDayUnix = (post: ?Inexact<Post>): ?Timestamp => {
  const time = getTime(post);
  return time ? time.startOf("day").unix() : time;
};

export const getPostSnippet = (post: Inexact<Post>): string =>
  post.title || post.content.substring(0, 100);

export const ALL_ACTIVE_STATUSES: $ReadOnlyArray<PostStatus> = [
  "draft",
  "scheduled",
  "published",
];
export const POST_APPROVAL_CANDIDATE_STATUSES: $ReadOnlyArray<PostStatus> = [
  "draft",
  "scheduled",
];
export const ALL_STATUSES: $ReadOnlyArray<PostStatus> = [
  ...ALL_ACTIVE_STATUSES,
  "deleted",
];

const hasAttachments = (post: Post) => !!post.attachments.length;
const byStatus = (status: PostStatus) => (post: Post) => post.status === status;
const excludeStatus = (status: PostStatus) => (post: Post) =>
  post.status !== status;
export const isScheduled: FindIteratee<Post> = byStatus("scheduled");
export const isPublished: FindIteratee<Post> = byStatus("published");
export const isDraft: FindIteratee<Post> = byStatus("draft");
export const isNotDeleted: FindIteratee<Post> = excludeStatus("deleted");

export const hasNoSchedule: FindIteratee<Post> = (post: Post) =>
  !post.schedule_time;
const scheduledInFuture: FindIteratee<Post> = (post: Post) =>
  post.schedule_time?.isAfter(moment()) ?? false;
export const isScheduledForward: FindIteratee<Post> = overEvery(
  isScheduled,
  scheduledInFuture
);
export const isScheduledToday: FindIteratee<Post> = (post: Post) =>
  post.schedule_time?.isSame(moment(), "day") ?? false;

const hasCType = (type: ContentTypes) => (post: Post) =>
  post.content_type === type;
const ofCTypes = (types: ContentTypes[]) => (post: Post) =>
  types.includes(post.content_type);
export const isPostOrReel: FindIteratee<Post> = ofCTypes(["post", "reel"]);
export const isPost: FindIteratee<Post> = hasCType("post");
export const hasScheduleOrPublishedTime: FindIteratee<Post> = (post: Post) =>
  !!(post.effective_time ?? post.schedule_time);

export const postCanBeVizPlanned: FindIteratee<Post> = overEvery([
  isNotDeleted,
  hasAttachments,
  isPostOrReel,
  hasScheduleOrPublishedTime,
]);
export const isPostEngageable: FindIteratee<Post> = overEvery([
  isPublished,
  isPost,
]);

export const getScheduleTime = (post: Post): number =>
  post.schedule_time?.unix() ?? 0;
export const getPublishedTime = (post: Post): number =>
  post.effective_time?.unix() ?? 0;

export const isPastScheduleTime: FindIteratee<
  Inexact<Pick<Post, "schedule_time" | "status">>
> = (post) =>
  !!post.schedule_time &&
  post.status === "scheduled" &&
  post.schedule_time.isBefore(moment());

type MakeAttachmentProps = {
  type: AttachmentTypes,
  url?: URLStr,
  file?: File,
  uploadPromise?: Promise<MediaRef>,
};

type MakeLocalAttachmentProps = {
  type: AttachmentTypes,
  file: File,
  uploadPromise?: Promise<MediaRef>,
};

export const makeLocalAttachment = ({
  file,
  type,
  uploadPromise,
}: MakeLocalAttachmentProps): LocalAttachment => ({
  id: uniqueId(),
  type,
  user_tags: [],
  product_tags: [],
  file,
  uploadPromise,
});

const makeAttachment = ({
  file,
  type,
  url,
  uploadPromise,
}: MakeAttachmentProps): PostAttachment => {
  if (file) {
    return makeLocalAttachment({ file, type, uploadPromise });
  } else if (url) {
    // A remote URL attachment
    return { type, user_tags: [], product_tags: [], url };
  } else {
    throw Error("Tried to create an attachment that had no URL and no File.");
  }
};

export const urlToPostAttachment = (url: URLStr): PostAttachment =>
  makeAttachment({ type: getUrlType(url), url });

export const isFitForApprovalRequest =
  (approvalRequest: ?ApprovalRequest): FindIteratee<Post> =>
  (post) =>
    // either the post has no approval and has a valid status.
    (!post.approval &&
      POST_APPROVAL_CANDIDATE_STATUSES.includes(post.status)) ||
    // Or it is part of the current approval.
    (!!approvalRequest && approvalRequest.posts.includes(post.id));

export const disableCaptionForPost = (
  post: { ...MutablePost, ... },
  channel: ?Channel
): ?I18nKey => {
  if (
    !post.disable_autopublish &&
    channel?.slug === instagram.slug &&
    post.content_type === "story"
  )
    return "PostCreator.noCaptionOnAutomatedScheduling";
  if (!channel || post.attachments.length === 0) return null;
  if (
    channel.contentSpecs?.media?.image?.noCaption &&
    every(post.attachments, isAttachmentImage)
  ) {
    return "PostCreator.noCaptionWithImages";
  }
  if (
    channel.contentSpecs?.media?.video?.noCaption &&
    every(post.attachments, isAttachmentVideo)
  ) {
    return "PostCreator.noCaptionWithVideos";
  }
  return null;
};
