import { loginOAuth2 } from "./oauth.service";
import * as env from "../../config/env.config";

const SIGN_IN_URL =
  "https://www.pinterest.com/oauth/?" +
  "response_type=code&" +
  `client_id=${env.PINTEREST_CLIENT_ID}&` +
  `scope=${env.PINTEREST_PERMISSIONS}&` +
  `redirect_uri=${env.API_URL}${env.PINTEREST_REDIRECT_ENDPOINT}&` +
  `state=${env.PINTEREST_STATE}`;

const POPUP_FEATURES =
  "height=650,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no," +
  "location=no,directories=no,status=yes";
export const login = () => loginOAuth2(SIGN_IN_URL, POPUP_FEATURES);

export const wasLoginCancelled = (e) => false;
