// @flow
import * as React from "react";
import type { Children } from "../../reactTypes";
import type { CSSSize, I18nKey } from "../../types";
import type { Props as AddResourceButtonProps } from "../inputs/AddResourceButton";
import AddResourceButton from "../inputs/AddResourceButton";
import { ColumnStack } from "../lib/layout/stacks";
import { Body2, Subheader } from "../lib/display/Text";
import Translate from "../lib/display/Translate";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material";

type CoreProps = {
  children?: Children,
  label?: I18nKey,
  subcopy?: I18nKey,
  cta: React.Node,
  bgColor?: "alt" | "paper" | "default",
  maxWidth?: CSSSize,
};

type Props = {
  ...Omit<CoreProps, "cta">,
  addResourceButtonProps?: AddResourceButtonProps,
};

const CSSFilter = styled("div")(({ theme, bgColor }) => ({
  opacity: 0.8,
  position: "relative",
  pointerEvents: "none",
  "& > span": {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    background: `linear-gradient(${alpha(
      theme.palette.background[bgColor],
      0
    )},${theme.palette.background[bgColor]})`,
  },
}));

export const EmptyNotice: React.ComponentType<CoreProps> = ({
  label,
  subcopy,
  children,
  cta,
  bgColor = "alt",
  maxWidth = 800,
}) => (
  <ColumnStack
    justifyContent="stretch"
    alignItems="stretch"
    maxWidth={maxWidth}
    spacing={2}
    padding={1}
  >
    <CSSFilter bgColor={bgColor}>
      {children}
      <span />
    </CSSFilter>
    <ColumnStack
      maxWidth={maxWidth}
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {label && (
        <Subheader
          sx={{ maxWidth: 600, margin: "auto" }}
          color="textSecondary"
          paragraph
          align="center"
        >
          <Translate id={label} />
        </Subheader>
      )}
      {subcopy && (
        <Body2
          sx={{ maxWidth: 600, margin: "auto" }}
          color="textSecondary"
          paragraph
          align="center"
        >
          <Translate id={subcopy} />
        </Body2>
      )}
      {cta}
    </ColumnStack>
  </ColumnStack>
);

const StandardEmptyNotice: React.ComponentType<Props> = ({
  addResourceButtonProps,
  ...props
}) => (
  <EmptyNotice
    {...props}
    cta={
      addResourceButtonProps && (
        <AddResourceButton {...addResourceButtonProps} />
      )
    }
  />
);

export default StandardEmptyNotice;
