// @flow
import { GBYTE, MBYTE } from "../../lib/files.lib";
import type { Channel } from "./channel.model";
import {
  login,
  wasLoginCancelled,
} from "../../services/channels/pinterest.service";

const pinterest: Channel = {
  name: "Pinterest",
  slug: "pinterest",
  color: "#E40023",

  features: {
    personnalAccount: true,
    analytics: {
      personal: true,
      post: false,
    },
    content: {
      title: {},
      thumbnail: {
        video: true,
      },
    },
  },

  requires: {
    subchannel: true,
  },

  surfaces: [],
  subchannelTypes: [
    {
      slug: "pi_board",
      fetchItemsURL: "channels/pinterest/<account_id>/boards",
    },
  ],

  contentSpecs: {
    maxChars: 800,
    media: {
      image: {
        mimetypes: ["image/png", "image/jpeg", "image/gif"],
        minCount: 0,
        maxCount: 5,
        maxSize: 32 * MBYTE,
      },
      gif: {
        maxCount: 1,
        maxSize: 3 * MBYTE,
      },
      video: {
        mimetypes: ["video/mp4", "video/mov", "video/quicktime", "video/m4v"],
        maxSize: 2 * GBYTE,
        maxCount: 1,
      },
      overall: {
        minCount: 1,
        maxCount: 8,
        oneMediaTypeOnly: true,
      },
    },
  },
  wasLoginCancelled,
  login,
};

export default pinterest;
