// @flow
import usePostDiagnostic from "./usePostDiagnostic";
import { useMemo } from "react";
import facebook from "../../models/channels/facebook.model";
import instagram from "../../models/channels/instagram.model";
import linkedin from "../../models/channels/linkedin.model";
import twitter from "../../models/channels/twitter.model";
import pinterest from "../../models/channels/pinterest.model";
import googleMyBusiness from "../../models/channels/googleMyBusiness.model";
import type { DiagnosticError } from "../../util/diagnostics.util";
import type { Slug } from "../../types";

const usePostDiagnosticAnyChannel = (): Record<Slug, DiagnosticError[]> => {
  const facebookDiagnostics = usePostDiagnostic(facebook);
  const instagramDiagnostics = usePostDiagnostic(instagram);
  const linkedinDiagnostics = usePostDiagnostic(linkedin);
  const twitterDiagnostics = usePostDiagnostic(twitter);
  const pinterestDiagnostics = usePostDiagnostic(pinterest);
  const googleMyBusinessDiagnostics = usePostDiagnostic(googleMyBusiness);
  return useMemo(
    () => ({
      [facebook.slug]: facebookDiagnostics,
      [instagram.slug]: instagramDiagnostics,
      [linkedin.slug]: linkedinDiagnostics,
      [twitter.slug]: twitterDiagnostics,
      [pinterest.slug]: pinterestDiagnostics,
      [googleMyBusiness.slug]: googleMyBusinessDiagnostics,
    }),
    [
      facebookDiagnostics,
      instagramDiagnostics,
      linkedinDiagnostics,
      twitterDiagnostics,
      pinterestDiagnostics,
      googleMyBusinessDiagnostics,
    ]
  );
};

export default usePostDiagnosticAnyChannel;
