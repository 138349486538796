// @flow
import * as React from "react";
import { TwitterPicker } from "react-color";
import { Box, ClickAwayListener, Collapse, TextField } from "@mui/material";
import useSwitch from "../../../hooks/useSwitch";
import { ColorBox } from "../display/ColorBox";

export type ColorInputProps = {
  ...React.ElementProps<typeof TextField>,
  onChange: (string) => any,
};

const ColorInput: React.ComponentType<ColorInputProps> = ({
  value,
  onChange,
  ...textFieldProps
}) => {
  const [visible, show, hide] = useSwitch();

  return (
    <ClickAwayListener onClickAway={hide}>
      <div>
        <div>
          <ColorBox
            width={60}
            height={56}
            marginTop={2} // To align with TextInput margin
            color={value}
          />
          <TextField
            readOnly
            onFocus={show}
            value={value}
            onChange={(e) => onChange(e.currentTarget.value)}
            {...textFieldProps}
          />
        </div>
        <Collapse in={visible}>
          <Box marginTop={2} marginLeft={8}>
            <TwitterPicker
              color={value || undefined}
              onChangeComplete={(color) => onChange(color.hex.toUpperCase())}
            />
          </Box>
        </Collapse>
      </div>
    </ClickAwayListener>
  );
};

export default ColorInput;
