// @flow
import * as React from "react";
import type { Attachment } from "../../models/attachment.model";
import AttachmentPreview from "./AttachmentPreview";
import { ImageList, ImageListItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { bound } from "../../lib/lodashex.lib";
import withSpotlight from "../lib/feedback/withSpotlight";

type Props = {
  attachments: Attachment[],
  videoOffset?: ?number,
  rowHeight?: number,
};

const Gallery = styled(ImageList)({
  // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  transform: "translateZ(0)",
});

const SingleRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== "rowHeight",
})(({ rowHeight }) => ({
  height: rowHeight,
}));

const SpotlightAttachmentPreview = withSpotlight(AttachmentPreview);

const AttachmentsGallery: React.ComponentType<Props> = ({
  attachments,
  videoOffset,
  rowHeight,
}) => {
  if (attachments.length === 1) {
    return (
      <SingleRoot rowHeight={rowHeight}>
        <SpotlightAttachmentPreview
          attachment={attachments[0]}
          controls
          videoOffset={videoOffset}
        />
      </SingleRoot>
    );
  }

  return (
    <Gallery
      gap={2}
      cols={bound(1, 3, attachments.length)}
      rowHeight={rowHeight}
    >
      {attachments.map((attachment, idx) => (
        <ImageListItem key={attachment.id}>
          <SpotlightAttachmentPreview
            attachment={attachment}
            videoOffset={idx === 0 ? videoOffset : undefined}
          />
        </ImageListItem>
      ))}
    </Gallery>
  );
};

export default AttachmentsGallery;
