// @flow
import * as React from "react";
import type { RowId, TableSelection } from "./base";
import { IconButton, Tooltip } from "@mui/material";
import {
  CopyAllRounded,
  DeleteRounded,
  EditRounded,
} from "@mui/icons-material";
import type { I18nKey } from "../../../../types";
import Translate from "../Translate";
import { next } from "../../../../util/iterable";

export type Props<K = RowId> = {
  selection: TableSelection<K>,
  onAction: (selection: TableSelection<K>) => any,
  hideIf?: (selection: TableSelection<K>) => boolean,
};

export type TableToolbarActionComponent<K = RowId> = React.ComponentType<
  Props<K>
>;

export type TableToolbarActionConfig<K = RowId> = {
  Component: React.ComponentType<Props<K>>,
  onAction: (selection: TableSelection<K>) => any,
  hideIf?: (selection: TableSelection<K>) => boolean,
  key: I18nKey,
};

type TableActionProps<K> = {
  ...Props<K>,
  title: I18nKey,
  Icon: React.ComponentType<{}>,
  single?: boolean,
};

export const TableAction = <K = RowId>({
  selection,
  onAction,
  hideIf,
  title,
  Icon,
  single,
}: TableActionProps<K>): React.Node =>
  (single ? selection.selectedCount === 1 : selection.selectedCount > 0) &&
  (!hideIf || !hideIf(selection)) && (
    <Tooltip title={<Translate id={title} />}>
      <IconButton onClick={() => onAction(selection)}>
        <Icon />
      </IconButton>
    </Tooltip>
  );

const DeleteAction = <K = RowId>(props: Props<K>): React.Node => (
  <TableAction {...props} title="global.delete" Icon={DeleteRounded} />
);

const EditAction = <K = RowId>(props: Props<K>): React.Node => (
  <TableAction {...props} title="global.edit" Icon={EditRounded} single />
);

const DuplicateAction = <K = RowId>(props: Props<K>): React.Node => (
  <TableAction
    {...props}
    title="global.duplicate"
    Icon={CopyAllRounded}
    single
  />
);

export const makeDeleteAction = <K = RowId>(
  onDelete: (TableSelection<K>) => any,
  hideIf?: Props<K>["hideIf"]
): TableToolbarActionConfig<K> => ({
  key: "delete",
  onAction: (selection: TableSelection<K>) => onDelete(selection),
  Component: DeleteAction,
  hideIf,
});

export const makeEditAction = <K = RowId>(
  onEdit: (K) => any,
  hideIf?: Props<K>["hideIf"]
): TableToolbarActionConfig<K> => ({
  key: "edit",
  onAction: (selection: TableSelection<K>) =>
    onEdit(next(selection.selection.values())),
  Component: EditAction,
  hideIf,
});

export const makeDuplicateAction = <K = RowId>(
  onEdit: (K) => any,
  hideIf?: Props<K>["hideIf"]
): TableToolbarActionConfig<K> => ({
  key: "duplicate",
  onAction: (selection: TableSelection<K>) =>
    onEdit(next(selection.selection.values())),
  Component: DuplicateAction,
  hideIf,
});
