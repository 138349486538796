// @flow
import * as React from "react";
import type { DiagnosticError } from "../../util/diagnostics.util";
import PostBuilderContext from "../../contexts/postBuilder";
import { useRemainingAccountCapacity } from "../../store/selectors";
import compact from "lodash/compact";

const usePublishDiagnostics = (): DiagnosticError[] => {
  const {
    post: { schedule_time, status },
  } = React.useContext(PostBuilderContext);
  const hasScheduledTime = !!schedule_time;
  const [remainingAccountCapacity] = useRemainingAccountCapacity();
  return React.useMemo(
    () =>
      compact([
        status === "scheduled" && !hasScheduledTime && "missingScheduleTime",
        remainingAccountCapacity < 0 && "tooManyAccounts",
      ]),
    [hasScheduledTime, status, remainingAccountCapacity]
  );
};

export default usePublishDiagnostics;
