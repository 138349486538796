// @flow
import * as React from "react";
import copyToClipboard from "../../lib/clipboard.lib";
import * as snacks from "../../models/alerts.model";
import { useStoreActions } from "../../store/store";
import { IconButton, Tooltip } from "@mui/material";
import useTranslate from "../../hooks/useTranslate";
import { FileCopyRounded } from "@mui/icons-material";

type Props = {
  contentElId: string,
};

const CopyIconButton: React.ComponentType<Props> = ({ contentElId }) => {
  const actions = useStoreActions();
  const t = useTranslate();

  const handleClick = React.useCallback(() => {
    copyToClipboard(document.getElementById(contentElId)?.innerText ?? "").then(
      () =>
        actions.snacks.append(
          snacks.localInfo({
            message: "global.copiedToClipboard",
          })
        )
    );
  }, [contentElId, actions]);

  return (
    <Tooltip title={t("global.copy")}>
      <IconButton onClick={handleClick}>
        <FileCopyRounded />
      </IconButton>
    </Tooltip>
  );
};

export default CopyIconButton;
