// @flow
import * as React from "react";
import { Box, ButtonGroup, Popover, TextField } from "@mui/material";
import type { CSSProps, SyntheticEventListener } from "../../../reactTypes";
import ImageCropper from "../../lib/inputs/ImageCropper";
import type { Props as ImageCropperProps } from "../../lib/inputs/ImageCropper";
import { isGif } from "../../../lib/files.lib";
import { NeutralButton, PrimaryButton } from "../../lib/inputs/buttons";
import {
  Crop32Rounded,
  Crop54Rounded,
  CropFree,
  CropSquare,
  RotateLeftRounded,
  RotateRightRounded,
} from "@mui/icons-material";
import { Body2 } from "../../lib/display/Text";
import { ColumnStack } from "../../lib/layout/stacks";
import Translate from "../../lib/display/Translate";
import type { Callback, Pixels } from "../../../types";
import { styled } from "@mui/material/styles";

type DimensionFieldProps = {
  label: React.Node,
  value: ?number,
  onChange: (e: SyntheticEvent<HTMLInputElement>) => any,
};

const DimensionField: React.ComponentType<DimensionFieldProps> = ({
  label,
  value,
  onChange,
}) => (
  <TextField
    label={label}
    type="number"
    size="small"
    margin="none"
    value={value?.toString() || ""}
    onChange={onChange}
  />
);

export type Props = {
  ...ImageCropperProps,
  onRotateLeft: Callback,
  onRotateRight: Callback,
  onSetAspect: (?number) => any,
  realWidth: Pixels,
  realHeight: Pixels,
  onStartResize: SyntheticEventListener,
  resizeMenuAnchorEl: ?EventTarget,
  onCancelResize: Callback,
  onConfirmResize: Callback,
  resizeWidth: Pixels,
  resizeHeight: Pixels,
  onChangeResizeWidth: (e: SyntheticEvent<HTMLInputElement>) => any,
  onChangeResizeHeight: (e: SyntheticEvent<HTMLInputElement>) => any,
  slotProps?: {
    root?: CSSProps,
    controls?: CSSProps,
  },
};

const Root = styled("div")`
  text-align: center;
`;

const Controls = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  alignItems: "flex-end",
}));

const CropImageWidget: React.ComponentType<Props> = ({
  src,
  aspect,
  onRotateLeft,
  onRotateRight,
  onSetAspect,
  realWidth,
  realHeight,
  onStartResize,
  resizeMenuAnchorEl,
  onCancelResize,
  onConfirmResize,
  resizeWidth,
  resizeHeight,
  onChangeResizeWidth,
  onChangeResizeHeight,
  slotProps,
  ...props
}) => (
  <Root {...slotProps?.root}>
    {src && <ImageCropper keepSelection src={src} aspect={aspect} {...props} />}
    {src && !isGif(src) && (
      <>
        <Controls {...slotProps?.controls}>
          <ButtonGroup color="inherit" display="block">
            <NeutralButton
              onClick={() => onSetAspect(undefined)}
              color={aspect === undefined ? "info" : undefined}
            >
              <CropFree />
            </NeutralButton>
            <NeutralButton
              onClick={() => onSetAspect(2 / 3)}
              color={aspect === 2 / 3 ? "info" : undefined}
            >
              <div>
                <Crop54Rounded style={{ transform: "rotate(90deg)" }} />{" "}
                <div>2:3</div>
              </div>
            </NeutralButton>
            <NeutralButton
              onClick={() => onSetAspect(4 / 5)}
              color={aspect === 4 / 5 ? "info" : undefined}
            >
              <div>
                <Crop32Rounded style={{ transform: "rotate(90deg)" }} />{" "}
                <div>4:5</div>
              </div>
            </NeutralButton>
            <NeutralButton
              onClick={() => onSetAspect(1)}
              color={aspect === 1 ? "info" : undefined}
            >
              <div>
                <CropSquare />
                <div>1:1</div>
              </div>
            </NeutralButton>
            <NeutralButton
              onClick={() => onSetAspect(5 / 4)}
              color={aspect === 5 / 4 ? "info" : undefined}
            >
              <div>
                <Crop32Rounded />
                <div>5:4</div>
              </div>
            </NeutralButton>
            <NeutralButton
              onClick={() => onSetAspect(3 / 2)}
              color={aspect === 3 / 2 ? "info" : undefined}
            >
              <div>
                <Crop54Rounded />
                <div>3:2</div>
              </div>
            </NeutralButton>
          </ButtonGroup>
          <ButtonGroup display="block">
            <NeutralButton onClick={onRotateLeft}>
              <RotateLeftRounded />
            </NeutralButton>
            <NeutralButton onClick={onRotateRight}>
              <RotateRightRounded />
            </NeutralButton>
            <NeutralButton onClick={onStartResize}>
              {realWidth} x {realHeight}
            </NeutralButton>
          </ButtonGroup>
        </Controls>
        <Popover
          anchorEl={resizeMenuAnchorEl}
          keepMounted
          open={Boolean(resizeMenuAnchorEl)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          onClose={onCancelResize}
        >
          <ColumnStack padding={1} maxWidth={300} spacing={1}>
            <Body2>
              <Translate id="CropImageDialog.resizeInstructions" />
            </Body2>
            <DimensionField
              label="global.width"
              value={resizeWidth}
              onChange={onChangeResizeWidth}
            />
            <DimensionField
              label="global.height"
              value={resizeHeight}
              onChange={onChangeResizeHeight}
            />
            <Box textAlign="right" width="100%">
              <PrimaryButton
                disabled={!resizeHeight || !resizeWidth}
                onClick={onConfirmResize}
              >
                Confirm
              </PrimaryButton>
            </Box>
          </ColumnStack>
        </Popover>
      </>
    )}
  </Root>
);

export default CropImageWidget;
