// @flow
import * as React from "react";
import useEventTargetState from "../../../hooks/useElementState";
import { Menu, Paper } from "@mui/material";
import type { Children } from "../../../reactTypes";

const DEFAULT_ORIGIN = { vertical: "top", horizontal: "right" };

type Options = {
  anchor?: typeof DEFAULT_ORIGIN,
  transform?: typeof DEFAULT_ORIGIN,
  children?: Children | (() => Children),
  closeOnClick?: boolean,
  PaperProps?: React.ElementProps<typeof Paper>,
  MenuListProps?: {
    dense?: boolean,
  },
};

/**
 * Gives a popup behaviour to a Component.
 * It can now accept children as menu items.
 */
export const withPopupMenu =
  <Props>(
    Component: React.ComponentType<Props>,
    options?: Options
  ): React.ComponentType<{
    ...Props,
    children?: Children,
  }> =>
  ({ children, ...props }) => {
    const [anchorEl, captureEl, clearEl] = useEventTargetState();
    const optChildren = options?.children;
    const open = Boolean(anchorEl);
    return (
      <>
        <Component {...props} onClick={captureEl}>
          {typeof optChildren === "function" ? optChildren() : optChildren}
        </Component>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={options?.anchor ?? DEFAULT_ORIGIN}
          transformOrigin={options?.transform ?? DEFAULT_ORIGIN}
          open={open}
          onClose={clearEl}
          MenuListProps={options?.MenuListProps}
          slotProps={{
            paper: options?.PaperProps,
          }}
          onClick={open && options?.closeOnClick ? clearEl : undefined}
        >
          {children}
        </Menu>
      </>
    );
  };

export default withPopupMenu;
