// @flow
import * as React from "react";
import { TextField } from "@mui/material";
import type {
  FormField,
  OnChangeField,
  StringFormFieldDef,
} from "../../../../hooks/useForm";
import { useFormFieldError } from "../../../../hooks/useForm";
import type { Props as TextFieldProps } from "../../../../stubs/mui/TextField";

type Props = {
  ...TextFieldProps,
  formField: FormField<StringFormFieldDef>,
  onChange: OnChangeField<StringFormFieldDef>,
};

const FormTextInput: React.ComponentType<Props> = ({
  formField,
  onChange,
  ...props
}) => {
  const helperText = useFormFieldError(formField) ?? formField.helperText;
  return (
    <TextField
      value={formField.value?.toString()}
      onChange={(e) => onChange(formField.fromString(e.currentTarget.value))}
      helperText={helperText}
      error={!!formField.error}
      name={formField.name}
      {...props}
    />
  );
};

export default FormTextInput;
