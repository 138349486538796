// @flow
import * as React from "react";
import { useStoreActions } from "../../store/store";
import { getFirstDiagnosticErrorDescription } from "../../util/diagnostics.util";
import PostBuilderContext from "../../contexts/postBuilder";
import * as snacks from "../../models/alerts.model";
import usePostDiagnostic from "./usePostDiagnostic";

const usePostValidator = (): (() => boolean) => {
  const actions = useStoreActions();

  const { channel } = React.useContext(PostBuilderContext);
  const postDiagnostics = usePostDiagnostic(channel);

  return React.useCallback(() => {
    const firstError = getFirstDiagnosticErrorDescription(postDiagnostics);
    if (firstError && channel) {
      const { msg, data } = firstError;
      actions.snacks.append(
        snacks.localWarning({
          message: msg,
          messageData: data(channel),
        })
      );
    }

    return !firstError;
  }, [postDiagnostics, actions, channel]);
};

export default usePostValidator;
