// @flow
import * as React from "react";
import { Skeleton, Typography } from "@mui/material";
import type { Children, StyledElementType } from "../../../reactTypes";
import { withDefaultProps } from "../utils/hoc";
import { styled } from "@mui/material/styles";
import type { CSSSize } from "../../../types";

export type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline";

export type TextProps = {
  id?: string,
  style?: Object,
  width?: CSSSize,
  flexGrow?: number | string,
  fontWeight?: "normal" | "bold" | "initial" | string,
  color?:
    | "primary"
    | "secondary"
    | "default"
    | "textPrimary"
    | "textSecondary"
    | string,
  /**
   * Set the text-align on the component.
   * @default 'inherit'
   */
  align?: "inherit" | "left" | "center" | "right" | "justify",
  /**
   * The content of the component.
   */
  children?: Children,
  /**
   * If `true`, the text will have a bottom margin.
   * @default false
   */
  gutterBottom?: boolean,
  /**
   * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
   *
   * Note that text overflow can only happen with block or inline-block level elements
   * (the element needs to have a width in order to overflow).
   * @default false
   */
  noWrap?: boolean,
  /**
   * If `true`, the element will be a paragraph element.
   * @default false
   */
  paragraph?: boolean,
  variant?: Variant,
  skeleton?: boolean,
  display?: "inline" | "block" | "inline-block",
  component?: React.ElementType,
  textAlign?: "left" | "right" | "center" | "justify",
  sx?: Object,
};

export const Text: React.ComponentType<TextProps> = React.forwardRef(
  ({ skeleton, ...props }, ref) =>
    skeleton ? (
      <Skeleton variant="text" ref={ref} animation={false} />
    ) : (
      <Typography ref={ref} {...props} />
    )
);

export const Caption: React.ComponentType<TextProps> = withDefaultProps<
  TextProps,
  "variant"
>(Text, { variant: "caption" });
export const Body2: React.ComponentType<TextProps> = withDefaultProps<
  TextProps,
  "variant"
>(Text, { variant: "body2" });
export const Body1: React.ComponentType<TextProps> = withDefaultProps<
  TextProps,
  "variant"
>(Text, { variant: "body1" });
export const Subheader: React.ComponentType<TextProps> = withDefaultProps<
  TextProps,
  "variant" | "fontWeight"
>(Text, { variant: "h6", fontWeight: "bold" });
export const Header: React.ComponentType<TextProps> = withDefaultProps<
  TextProps,
  "variant" | "fontWeight"
>(Text, { variant: "h5", fontWeight: "bold" });
export const Subtitle: React.ComponentType<TextProps> = withDefaultProps<
  TextProps,
  "variant" | "fontWeight"
>(Text, { variant: "h4", fontWeight: "bold" });
export const Title: React.ComponentType<TextProps> = withDefaultProps<
  TextProps,
  "variant" | "fontWeight"
>(Text, { variant: "h3", fontWeight: "bold" });
export const Jumbo: React.ComponentType<TextProps> = withDefaultProps<
  TextProps,
  "variant" | "fontWeight"
>(Text, { variant: "h2", fontWeight: "bold" });

export const AlignCenter: StyledElementType<"div"> = styled("div")`
  text-align: center;
`;
