// @flow
import * as React from "react";
import type {
  PostMetric,
  PostMetricsCollection,
} from "../../../models/analytics.model";
import { InlineRowStack, SpaceBetweenRow } from "../../lib/layout/stacks";
import { toPairs } from "lodash";
import { POST_METRICS } from "../../../config/metrics.config";
import type { AnalyticsMetric } from "../../../models/analytics.model";
import type { MetricInfo } from "../../../config/metrics.config";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { Body2 } from "../../lib/display/Text";
import Numeral from "../../lib/display/Numeral";
import Translate from "../../lib/display/Translate";

type Props = {
  overview: PostMetricsCollection,
};

type MetricProps = {
  descriptor: MetricInfo,
  metric: AnalyticsMetric,
};

const MetricRoot = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 1),
  gap: theme.spacing(0.5),
  display: "flex",
  flexDirection: "column",
  width: 175,
}));

const Metric: React.ComponentType<MetricProps> = ({ metric, descriptor }) => (
  <MetricRoot>
    <SpaceBetweenRow>
      <Body2 fontWeight="bold" color="textSecondary">
        <Translate id={descriptor.name} />
      </Body2>
      <Numeral
        value={metric.delta}
        display="percent"
        variant="body1"
        fontWeight="bold"
        colored
      />
    </SpaceBetweenRow>
    <SpaceBetweenRow>
      <Numeral
        value={metric.value}
        display="compact"
        variant="h5"
        fontWeight="bold"
      />
      <Numeral
        value={metric.delta}
        display="absolute"
        variant="body1"
        fontWeight="bold"
        colored
      />
    </SpaceBetweenRow>
  </MetricRoot>
);

const PostOverviewWidget: React.ComponentType<Props> = ({ overview }) => (
  <InlineRowStack>
    {toPairs(overview).map(
      ([name, metric]: [PostMetric, AnalyticsMetric]) =>
        metric.value !== null && (
          <Metric key={name} descriptor={POST_METRICS[name]} metric={metric} />
        )
    )}
  </InlineRowStack>
);

export default PostOverviewWidget;
