// @flow
import * as React from "react";
import FreeformFeedbackWidget from "./FreeformFeedbackWidget";
import {
  checkBugDescription,
  checkBugTitle,
} from "../../../util/validation.util";
import { FormActions, useForm } from "../../../hooks/useForm";
import { MBYTE } from "../../../lib/files.lib";
import { API as backend } from "../../../services/backend.service";
import { useStoreActions } from "../../../store/store";
import * as snacks from "../../../models/alerts.model";
import type { FreeformBackFormDef } from "./FreeformFeedbackWidget";
import type { MediaItem } from "../../lib/inputs/MediaInput/useHeadlessMediaInput";

const FORM_DEF: FreeformBackFormDef = {
  title: { type: "string", validate: checkBugTitle, maxLength: 100 },
  description: {
    type: "string",
    validate: checkBugDescription,
    maxLength: 2048,
  },
  images: {
    type: "media",
    mediaType: "image",
    maxItems: 2,
    maxItemSize: 20 * MBYTE,
  },
};

const FORM_INITIAL: {
  title: string,
  description: string,
  images: MediaItem[],
} = {
  title: "",
  description: "",
  images: [],
};

const FreeformFeedbackWidgetContainer: React.ComponentType<{}> = () => {
  const form = useForm<FreeformBackFormDef>(FORM_DEF, FORM_INITIAL);
  const actions = useStoreActions();

  const handleConfirm = () => {
    if (FormActions.validate(form)) {
      const payload = FormActions.collect(form.state);
      return backend.feedback
        .postFreeformFeedback({
          ...payload,
          images: payload.images.map((item) => item.src),
        })
        .then(() => {
          FormActions.reset(form.set, FORM_INITIAL);
          return actions.snacks.append(
            snacks.localSuccess({ message: "FreeformFeedbackForm.onSuccess" })
          );
        });
    }
  };

  return <FreeformFeedbackWidget form={form} onConfirm={handleConfirm} />;
};

export default FreeformFeedbackWidgetContainer;
