// @flow
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { getBackToResourceListRelPath } from "../config/routes.config";

const useNavigateBackToResourceList = (isEditOrView: boolean): (() => void) => {
  const navigate = useNavigate();
  return React.useCallback(
    () =>
      navigate(getBackToResourceListRelPath(isEditOrView), {
        relative: "path",
      }),
    [navigate, isEditOrView]
  );
};

export default useNavigateBackToResourceList;
