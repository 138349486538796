// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import type { Children } from "../../reactTypes";

const PageRoot: React.ComponentType<{
  children?: Children,
  id?: string,
}> = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    overflowY: "visible",
  },
}));

export default PageRoot;
