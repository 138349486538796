// @flow
import type {
  Email,
  URLStr,
  Moment,
  Timestamp,
  ModelID,
  Inexact,
} from "../types";
import type { UserPreferences } from "./userPreferences.model";
import type { LimitSet } from "./limitSet.model";
import { toMoment } from "../lib/time.lib";

export type UserDisplay = {
  first_name: string,
  last_name: string,
  avatar: ?URLStr,
};

/** Stands for a user of the Bobcaat API. */
export type User = {
  ...UserDisplay,
  email: Email,
  preferences: UserPreferences,
  limits: LimitSet,
  referral_code: string,
  metamorph: boolean,
  last_login_at: ?Moment,
  is_child?: false,
};

export type ChildUser = {
  ...User,
  id: ModelID,
  is_child: true,
  restricted_portfolios: ?(ModelID[]),
};

export type APIUser = {
  ...Omit<User, "last_login_at">,
  last_login_at: Timestamp,
};

export type APIChildUser = {
  ...Omit<ChildUser, "last_login_at">,
  last_login_at: Timestamp,
};

export const fromAPIResult = <T: { last_login_at: ?Moment, ... }>(user: {
  ...Omit<T, "last_login_at">,
  last_login_at: Timestamp,
}): T => ({
  ...user,
  last_login_at: toMoment(user.last_login_at),
});

export const getInitials = (user: Inexact<UserDisplay>): string =>
  user.first_name[0] + user.last_name[0];

export const getFullName = (user: Inexact<UserDisplay>): string =>
  `${user.first_name} ${user.last_name}`;
