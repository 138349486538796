// @flow
import * as React from "react";
import { ClickAwayListener, Grow, Popper } from "@mui/material";
import type { Children, SyntheticEventListener } from "../../../reactTypes";
import useEventTargetState from "../../../hooks/useElementState";
import { styled } from "@mui/material/styles";

export type PopperProps = {
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top",
};

const Root = styled("div")(({ theme }) => ({
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
}));

/**
 * Gives a popper behaviour to a Component.
 */
export const usePopup = (
  children?: Children,
  popperProps?: PopperProps
): [SyntheticEventListener, React.Node] => {
  const [anchorEl, captureEl, clearEl] = useEventTargetState();
  return [
    !!anchorEl ? clearEl : captureEl,
    <Popper anchorEl={anchorEl} open={!!anchorEl} {...popperProps}>
      <ClickAwayListener onClickAway={clearEl}>
        <Grow in={!!anchorEl}>
          <Root>{children}</Root>
        </Grow>
      </ClickAwayListener>
    </Popper>,
  ];
};

export default usePopup;
