// @flow
import { API as backend } from "./backend.service";
import type { AsyncAction, AsyncVoidAction } from "./types";
import type { ModelID } from "../types";
import type {
  CommentResponse,
  CreateCommentParams,
  EditCommentParams,
} from "./backend.service/engagement";
import type { StoreActions } from "../store/store";

const handleCommentResponse =
  (actions: StoreActions) => (response: CommentResponse) => {
    actions.engagement.comments.addOrReplace(response.comment);
    actions.engagement.summaries.insert(response.summary);
  };

export const addComment: AsyncAction<
  Promise<any>,
  ModelID,
  CreateCommentParams,
  ?() => any
> = (actions) => (postId, comment) => {
  actions.engagement.lock();
  return backend.engagement
    .addComment(postId, comment)
    .then(handleCommentResponse(actions))
    .finally(actions.engagement.unlock);
};

export const editComment: AsyncAction<
  Promise<any>,
  EditCommentParams,
  ?() => void
> = (actions) => (comment) => {
  actions.engagement.lock();
  return backend.engagement
    .editComment(comment)
    .then(handleCommentResponse(actions))
    .finally(actions.engagement.unlock);
};

export const getPostComments: AsyncVoidAction<ModelID> =
  (actions) => (postId) =>
    backend.engagement
      .getComments(postId)
      .then((comments) =>
        actions.engagement.comments.setBucket(postId, comments)
      );

export const deleteComment: AsyncAction<Promise<void>, ModelID> =
  (actions) => (commentId) => {
    actions.engagement.lock();
    return backend.engagement
      .deleteComment(commentId)
      .then(handleCommentResponse(actions))
      .finally(actions.engagement.unlock);
  };

export const likeComment: AsyncAction<Promise<void>, ModelID> =
  (actions) => (commentId) =>
    backend.engagement
      .likeComment(commentId)
      .then(handleCommentResponse(actions));

export const unlikeComment: AsyncAction<Promise<void>, ModelID> =
  (actions) => (commentId) =>
    backend.engagement
      .unlikeComment(commentId)
      .then(handleCommentResponse(actions));

export const setCommentLiked: AsyncAction<Promise<void>, ModelID, boolean> =
  (actions) => (commentId, liked) =>
    liked ? likeComment(actions)(commentId) : unlikeComment(actions)(commentId);

export const markPostCommentsAsRead: AsyncVoidAction<ModelID> =
  (actions) => (postId) =>
    backend.engagement.markPostCommentsAsRead(postId).then((result) => {
      actions.engagement.comments.setBucket(
        result.summary.post_id,
        result.comments
      );
      actions.engagement.summaries.insert(result.summary);
    });

export const getSocialActionSummaries: AsyncVoidAction<ModelID> =
  (actions) => (portfolioId) =>
    backend.engagement
      .getSocialActionSummaries({ portfolioId })
      .then(actions.engagement.summaries.replaceAll);

export const getPostSocialActionSummary: AsyncVoidAction<ModelID> =
  (actions) => (postId) =>
    backend.engagement
      .getPostSocialActionSummary(postId)
      .then(actions.engagement.summaries.extend);
