// @flow
import type { Integer, ModelID, Timestamp } from "../types";
import moment from "moment";
import { Moment } from "../types";

export type PostComment = {
  id: ModelID,
  post_id: ModelID,
  parent_id: ?ModelID,
  message: string,
  like_count: Integer,
  read: boolean,
  liked: boolean,
  reply_id: ?ModelID,
  author_username: string,
  author_native_id: string,
  created_at: Moment,
};

export type APIPostComment = {
  ...Omit<PostComment, "created_at">,
  created_at: Timestamp,
};

export const fromAPIResult = (comment: APIPostComment): PostComment => ({
  ...comment,
  created_at: moment.unix(comment.created_at),
});
