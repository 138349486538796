// @flow
import * as React from "react";
import type { Props as FilePickerProps } from "../pickers/FilePicker";
import FilePicker from "../pickers/FilePicker";
import { LargeNeutralButton } from "../buttons";
import Translate from "../../display/Translate";
import { Body1 } from "../../display/Text";
import { styled } from "@mui/material/styles";

type Props = Omit<FilePickerProps, "children">;

const Root = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    border: `dashed 2px ${theme.palette.divider}`,
    borderRadius: 8,
    padding: theme.spacing(4),
    minHeight: 150,
  },
  [theme.breakpoints.down("sm")]: {
    "& .sm-up": {
      display: "none",
    },
  },
}));

const DropZone: React.ComponentType<Props> = (props) => (
  <Root>
    <div className="sm-up">
      <Body1 color="textSecondary">
        <Translate id="global.dropFilesHere" />
      </Body1>
      <Body1 color="textSecondary">
        <Translate id="global.or" />
      </Body1>
    </div>
    <FilePicker {...props}>
      <LargeNeutralButton fullWidth>
        <Translate id="global.selectFiles" />
      </LargeNeutralButton>
    </FilePicker>
  </Root>
);

export default DropZone;
