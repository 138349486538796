// @flow
import useLocationParams from "../../../hooks/useLocationParams";
import { UserToken, VisitorToken } from "../../../lib/session.lib";
import { API as backend } from "../../../services/backend.service";
import useInterval from "../../../hooks/useInterval";
import interval from "../../../lib/interval.lib";
import { useStoreActions } from "../../../store/store";
import { reload } from "../../../services/global.service";
import useWindowEventListener from "../../../hooks/useWindowEventListener";
import useOneTimeEffect from "../../../hooks/useOneTimeEffect";
import { AxiosError } from "axios";
import type { Callback } from "../../../types";
import noop from "lodash/noop";

const onTokenError = (e: AxiosError<any, any>) => {
  if (e.code === "ERR_NETWORK") {
  } else {
    UserToken.unset();
  }
};

const renewToken = (onSuccess: Callback = noop) =>
  backend.auth
    .renewToken()
    .then((token) => {
      UserToken.set(token.token);
    })
    .then(onSuccess)
    .catch(onTokenError);

const useTokenManager = () => {
  const storeActions = useStoreActions();
  const { t: token } = useLocationParams(["t"]);

  // Regularly renew the token and unset it if it fails.
  useInterval(renewToken, interval.hour.as.milliseconds);
  // Listen to the token manager and force a disconnect if the user
  // token is unset.
  useWindowEventListener(
    UserToken.unsetEvent,
    storeActions.global.forceDisconnect
  );

  // Whennever the user is logged in, discard any visitor token.
  // There should only be one at a time.
  useWindowEventListener(UserToken.setEvent, VisitorToken.unset);

  // Only do this once at loading time.
  useOneTimeEffect(() => {
    if (token) {
      // Extract token, pop it from URL and check it.
      UserToken.set(token);
      window.history.replaceState("", "", "/");
      backend.auth.checkToken().then(reload(storeActions)).catch(onTokenError);
    } else if (UserToken.isValid()) {
      // Verify the token is still valid by renewing it immediately.
      renewToken(reload(storeActions));
    } else {
      // IsValid only checks token presence so if it is missing at
      // this point just force a disconnect.
      storeActions.global.forceDisconnect();
    }
  });
};

export default useTokenManager;
