// @flow
import * as React from "react";
import AnalyticsControls from "../../../components/widgets/AnalyticsControls";
import AccountAnalyticsWidget from "../../../components/widgets/AccountAnalyticsWidget";
import PostAnalyticsWidget from "../../../components/widgets/PostAnalyticsWidget";
import { Stack } from "@mui/material";
import useIsSmallOrSmaller from "../../../hooks/useIsSmallOrSmaller";
import type { BobcaatAccount } from "../../../models/account.model";
import type { Portfolio } from "../../../models/portfolio.model";
import useIsSmall from "../../../hooks/useIsSmall";

type Props = {
  accounts: BobcaatAccount[],
  portfolio: Portfolio,
};

const AnalyticsPage: React.ComponentType<Props> = ({ portfolio, accounts }) => {
  const isSmallOrSmaller = useIsSmallOrSmaller();
  const isSmallButNotMobile = useIsSmall();
  return (
    <>
      <Stack
        alignItems={
          isSmallOrSmaller && !isSmallButNotMobile ? "stretch" : "flex-start"
        }
        direction={isSmallOrSmaller ? "column" : "row"}
      >
        <AnalyticsControls
          portfolio={portfolio}
          accounts={accounts}
          direction={isSmallButNotMobile ? "row" : "column"}
        />
        <AccountAnalyticsWidget accounts={accounts} />
      </Stack>
      <PostAnalyticsWidget accounts={accounts} />
    </>
  );
};

export default AnalyticsPage;
