// @flow
import * as React from "react";
import LoginWidget from "./LoginWidget";
import type { LoginFormDef, Props as LoginProps } from "./LoginWidget";
import type { Validator } from "../../../util/validation.util";
import { FormActions, useForm } from "../../../hooks/useForm";
import useSwitch from "../../../hooks/useSwitch";

type Props = {
  ...Omit<LoginProps, "onConfirm" | "onResetPassword" | "loading" | "form">,
  checkUsername?: Validator,
  checkPassword?: Validator,
  onValidCredentials: (username: string, password: string) => Promise<any>,
  onResetPassword?: (username: string) => any,
};

const FORM_INITIAL = { username: "", password: "" };

const LoginWidgetContainer: React.ComponentType<Props> = ({
  checkUsername,
  onValidCredentials,
  onResetPassword,
  checkPassword = () => [],
  ...props
}) => {
  const formDef: LoginFormDef = React.useMemo(
    () => ({
      username: { type: "string", validate: checkUsername },
      password: { type: "string", validate: checkPassword },
    }),
    [checkPassword, checkUsername]
  );
  const form = useForm<LoginFormDef>(formDef, FORM_INITIAL);
  const [locked, lock, unlock] = useSwitch();

  const handleConfirm = () => {
    if (FormActions.validate(form)) {
      lock();
      const credentials = FormActions.collect(form.state);
      onValidCredentials(credentials.username, credentials.password).finally(
        unlock
      );
    }
  };

  const handleResetPassword = () => {
    if (onResetPassword) {
      onResetPassword(form.state.username.value);
    }
  };

  return (
    <LoginWidget
      form={form}
      onConfirm={handleConfirm}
      onResetPassword={onResetPassword ? handleResetPassword : undefined}
      loading={locked}
      {...props}
    />
  );
};

export default LoginWidgetContainer;
