// @flow
import * as React from "react";
import type { Props as ReorderPostsDialogProps } from "./ReorderPostsDialog";
import ReorderPostsDialog from "./ReorderPostsDialog";
import type { Callback } from "../../../../../types";
import { cast, Moment } from "../../../../../types";
import { useStoreActions } from "../../../../../store/store";
import * as postService from "../../../../../services/post.service";
import moment from "moment/moment";
import type { Post } from "../../../../../models/post.model";

type Props = {
  ...Pick<ReorderPostsDialogProps, "posts">,
  onClose: Callback,
  onUpdatePost: (Post) => any,
};

const ReorderPostsDialogContainer: React.ComponentType<Props> = ({
  onClose,
  posts,
  onUpdatePost,
}) => {
  const storeActions = useStoreActions();
  const handleSubmit = React.useCallback(() => {
    return postService.reschedulePosts(storeActions)({
      posts: posts
        .filter((post) => !!post.schedule_time)
        .map((post) => ({
          id: post.id,
          // at this point posts have a schedule time,
          // as it should be but also enforced by the check above
          // so cast away
          schedule_time: cast<Moment>(post.schedule_time).unix(),
        })),
    });
  }, [posts, storeActions]);

  const handleChangeDate = React.useCallback(
    (post: Post, d: ?Moment) => {
      onUpdatePost({ ...post, schedule_time: d?.seconds(0).milliseconds(0) });
    },
    [onUpdatePost]
  );

  const handleChangeTime = React.useCallback(
    (post: Post, d: ?Moment) => {
      const baseDate = post.schedule_time ?? moment();
      onUpdatePost({
        ...post,
        schedule_time: d?.year(baseDate.year()).dayOfYear(baseDate.dayOfYear()),
      });
    },
    [onUpdatePost]
  );

  return (
    <ReorderPostsDialog
      open={posts.length > 0}
      posts={posts}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onDateChange={handleChangeDate}
      onTimeChange={handleChangeTime}
    />
  );
};

export default ReorderPostsDialogContainer;
