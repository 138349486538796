// @flow
import * as React from "react";
import type { BobcaatAccount } from "../../models/account.model";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import type { LockActions, LockState } from "./lib/lock.state";
import { useLockState } from "./lib/lock.state";

const accountReducer = sequenceReducer<BobcaatAccount>({ key: "id" });

export type AccountState = {
  ...LockState,
  accounts: SequenceState<BobcaatAccount>,
};
export type AccountActions = {
  ...LockActions,
  ...SequenceActions<BobcaatAccount>,
};

export const useAccountReducer = (): [AccountState, AccountActions] => {
  const [accounts, dispatch] = useSequenceReducer(accountReducer);
  // initially locked, because we need an initial load before it is ready.
  const [locked, lockActions] = useLockState(true);
  const baseActions = React.useMemo(
    () => makeSequenceActions(dispatch),
    [dispatch]
  );
  const actions = React.useMemo(
    () => ({
      ...baseActions,
      ...lockActions,
    }),
    [baseActions, lockActions]
  );
  return [{ accounts, locked }, actions];
};
