// @flow
import * as React from "react";
import type { Channel } from "../../models/channels/channel.model";
import ChannelIcon from "../lib/display/icons/ChannelIcon";
import type { Portfolio } from "../../models/portfolio.model";
import { PortfolioAvatar, UserAvatar } from "./avatars";
import type { User } from "../../models/user.model";
import type { PostApprovalStatuses } from "../../models/postApproval.model";
import {
  CancelRounded,
  CheckRounded,
  DashboardRounded,
  EditRounded,
  GroupRounded,
  LocationOnRounded,
  QuestionMarkRounded,
  TimerRounded,
} from "@mui/icons-material";
import type { ThemeColor } from "../../stubs/mui/theming";
import type { SubChannel } from "../../models/subChannel.model";
import Avatar from "../lib/display/Avatar";
import { SvgIcon } from "@mui/material";
import googleMyBusiness from "../../models/channels/googleMyBusiness.model";
import facebook from "../../models/channels/facebook.model";
import pinterest from "../../models/channels/pinterest.model";
import type { TargetSpecs } from "../widgets/postCreator/SelectPostTargetWidget/types";
import type { BobcaatAccount } from "../../models/account.model";
import type { Palette } from "../../models/userPreferences.model";
import { BiColorBox } from "../lib/display/ColorBox";
import type { ThemeOption } from "../../util/themes.util";
import type { EngagementRoute } from "../widgets/EngagementHubAccounts/EngagementHubAccounts";

interface AdornmentProps<T> {
  value: ?T;
  disabled?: boolean;
}

export const AccountAdornment: React.ComponentType<
  AdornmentProps<BobcaatAccount>
> = ({ value, disabled }) =>
  value ? (
    <ChannelIcon
      channel={value.channel}
      size="small"
      color={disabled ? "disabled" : "channel"}
    />
  ) : null;

export const ChannelAdornment: React.ComponentType<AdornmentProps<Channel>> = ({
  value,
}) =>
  value ? (
    <ChannelIcon channel={value.slug} color="channel" size="small" />
  ) : null;

export const TargetSpecAdornment: React.ComponentType<
  AdornmentProps<TargetSpecs>
> = ({ value, disabled }) =>
  value ? (
    <ChannelIcon
      channel={value.account.channel}
      color={disabled ? "disabled" : "channel"}
      size="small"
    />
  ) : null;

export const EngagementRouteAdornment: React.ComponentType<
  AdornmentProps<EngagementRoute>
> = ({ value, disabled }) =>
  value ? (
    <ChannelIcon
      channel={value.account.channel}
      color={disabled ? "disabled" : "channel"}
      size="small"
    />
  ) : null;

export const PortfolioAdornment: React.ComponentType<
  AdornmentProps<Portfolio>
> = ({ value }) =>
  value ? <PortfolioAvatar portfolio={value} size={24} /> : null;

export const UserAdornment: React.ComponentType<AdornmentProps<User>> = ({
  value,
}) => <UserAvatar user={value} size={24} />;

const POST_APPROVAL_STATUS_ICONS: {
  [PostApprovalStatuses]: React.ComponentType<any>,
} = {
  pending: TimerRounded,
  approved: CheckRounded,
  edits_required: EditRounded,
  rejected: CancelRounded,
};

const POST_APPROVAL_STATUS_COLORS: {
  [PostApprovalStatuses]: ThemeColor,
} = {
  pending: "info",
  approved: "success",
  edits_required: "warning",
  rejected: "error",
};

export const getPostApprovalStatusColor = (
  status: PostApprovalStatuses
): ThemeColor => POST_APPROVAL_STATUS_COLORS[status];

export const PostApprovalStatusAdornment: React.ComponentType<
  AdornmentProps<PostApprovalStatuses>
> = ({ value }) => {
  value = value ?? "pending";
  const Icon = POST_APPROVAL_STATUS_ICONS[value];
  return <Icon size="small" color={getPostApprovalStatusColor(value)} />;
};

const SUBCHANNEL_TYPE_ICONS: Record<string, typeof SvgIcon> = {
  [googleMyBusiness.subchannelTypes[0].slug]: LocationOnRounded,
  [facebook.subchannelTypes[0].slug]: GroupRounded,
  [pinterest.subchannelTypes[0].slug]: DashboardRounded,
};

export const SubchannelAdornment: React.ComponentType<
  AdornmentProps<SubChannel>
> = ({ value }) => {
  if (!value) return <QuestionMarkRounded />;
  const DefaultIcon = SUBCHANNEL_TYPE_ICONS[value.type];
  return value.cover ? <Avatar src={value.cover} /> : <DefaultIcon />;
};

export const PaletteAdornment: React.ComponentType<AdornmentProps<Palette>> = ({
  value,
}) =>
  value ? (
    <BiColorBox
      width={24}
      height={24}
      color={
        typeof value.palette.primary === "string"
          ? value.palette.primary
          : value.palette.primary.main
      }
      color2={
        typeof value.palette.secondary === "string"
          ? value.palette.secondary
          : value.palette.secondary.main
      }
    />
  ) : null;

export const ThemeAdornment: React.ComponentType<AdornmentProps<ThemeOption>> =
  ({ value }) => (value ? <value.Icon htmlColor={value.color} /> : null);
