// @flow
import * as React from "react";
import type { Options as ConfirmDialogOptions } from "./withConfirmDialog";
import useSwitch from "../../../hooks/useSwitch";
import flow from "lodash/flow";
import noop from "lodash/noop";
import ConfirmDialog from "./ConfirmDialog";
import type { Callback } from "../../../types";
import { cast } from "../../../types";

const useConfirmDialog = <ConfirmArg = void>(
  options?: ConfirmDialogOptions | ((?ConfirmArg) => ConfirmDialogOptions),
  onClick?: (ConfirmArg) => any,
  onOpen?: Callback = noop,
  onClose?: Callback = noop
): [(ConfirmArg) => any, React.Node] => {
  const [confirmArgs, setConfirmArgs] = React.useState<?ConfirmArg>();
  const [open, setOpen, setClosed] = useSwitch();
  // Save the confirm arg when receiving them.
  const handleOpen = React.useCallback(
    (args: ConfirmArg) => {
      setConfirmArgs(args);
      setOpen();
    },
    [setOpen]
  );

  if (!options || !onClick) {
    return [onClick ?? noop, null];
  }

  const opts = typeof options === "function" ? options(confirmArgs) : options;

  return [
    handleOpen,
    <ConfirmDialog
      onClose={flow(onClose, setClosed)}
      open={open}
      onConfirm={() => onClick(cast<ConfirmArg>(confirmArgs))}
      {...opts.DialogProps}
    />,
  ];
};

export default useConfirmDialog;
