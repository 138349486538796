// @flow
import * as React from "react";
import type { PostAttachment } from "../../../models/post.model";
import PostBuilderContext from "../../../contexts/postBuilder";
import useAttachmentsToMediaItemsAdapter from "./useAttachmentsToMediaItemsAdapter";

const useBuilderAttachmentsToMediaItemsAdapter = (): ReturnType<
  typeof useAttachmentsToMediaItemsAdapter
> => {
  const { post, onUpdatePost } = React.useContext(PostBuilderContext);

  const handleUpdateAttachments = React.useCallback(
    (attachments: PostAttachment[]) => onUpdatePost({ attachments }),
    [onUpdatePost]
  );

  return useAttachmentsToMediaItemsAdapter(
    post.attachments,
    handleUpdateAttachments,
    post.thumbnail_video_offset
  );
};

export default useBuilderAttachmentsToMediaItemsAdapter;
