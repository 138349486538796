// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { BarChartRounded } from "@mui/icons-material";
import { Body1 } from "../../lib/display/Text";
import type { I18nKey } from "../../../types";
import useTranslate from "../../../hooks/useTranslate";

type Props = {
  text: I18nKey,
};

const StyledBarChartRounded = styled(BarChartRounded)(({ theme }) => ({
  margin: theme.spacing(1.5),
}));

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "40vh",
  border: `solid 1px ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const AnalyticsPlaceholder: React.ComponentType<Props> = ({ text }) => {
  const t = useTranslate();
  return (
    <Root>
      <StyledBarChartRounded fontSize="small" color="inherit" />
      <Body1 color="textSecondary">{t(text)}</Body1>
    </Root>
  );
};

export default AnalyticsPlaceholder;
