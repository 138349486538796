// @flow
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import SelfReplyInput from "./SelfReplyInput";
import { NeutralButton } from "../../../lib/inputs/buttons";
import Translate from "../../../lib/display/Translate";
import type {
  SelfReply,
  SelfReplyAttachment,
} from "../../../../models/post.model";
import type { Callback } from "../../../../types";
import type { Channel } from "../../../../models/channels/channel.model";
import useTranslate from "../../../../hooks/useTranslate";
import {
  Collapse,
  IconButton,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { PostCreatorCollapsibleSection } from "../../../layout/PostCreatorSection";
import { Body1, Body2 } from "../../../lib/display/Text";
import {
  InlineRowStack,
  RowStack,
  SpaceBetweenRow,
} from "../../../lib/layout/stacks";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import SelfReplyPreview from "../../../display/SelfReplyPreview";
import { styled } from "@mui/material/styles";

type Props = {
  isEdit?: boolean,
  selfReplies: SelfReply[],
  activeReplyIndex: number,
  onChangeReplyContent: (idx: number, content: string) => any,
  onChangeReplyAttachments: (
    idx: number,
    attachments: SelfReplyAttachment[]
  ) => any,
  onAddSelfReply: Callback,
  channel: Channel,
  onSetActiveReplyIndex: (idx: number) => any,
  onDeleteSelfReply: (idx: number) => any,
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    padding: 0,
  },
}));

const AddReplyButtonContainer = styled(RowStack)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const ThreadWidget: React.ComponentType<Props> = ({
  isEdit,
  selfReplies,
  activeReplyIndex,
  onChangeReplyContent,
  onChangeReplyAttachments,
  onAddSelfReply,
  channel,
  onSetActiveReplyIndex,
  onDeleteSelfReply,
}) => {
  const t = useTranslate();
  return (
    !!channel?.features?.content?.selfReplies && (
      <Collapse in={selfReplies.length > 0}>
        <PostCreatorCollapsibleSection
          title="ThreadBuilderDialog.title"
          defaultCollapsed={isEdit}
          slotProps={{
            head: {
              children: selfReplies.length > 0 && (
                <Body2>
                  {t("global.countItems", { count: selfReplies.length })}
                </Body2>
              ),
            },
          }}
        >
          <Body2>{t("ThreadBuilderDialog.description")}</Body2>
          <StyledStepper
            activeStep={activeReplyIndex ?? 0}
            orientation="vertical"
          >
            {selfReplies.map((reply, idx) => (
              <Step key={idx} active>
                <StepLabel>
                  <SpaceBetweenRow alignItems="center">
                    <Body1 fontWeight="bold">
                      <Translate
                        id="global.replyNumber"
                        data={{ number: idx + 1 }}
                      />
                    </Body1>
                    <InlineRowStack>
                      {idx !== activeReplyIndex && (
                        <IconButton onClick={() => onSetActiveReplyIndex(idx)}>
                          <EditRounded />
                        </IconButton>
                      )}
                      <IconButton
                        color="error"
                        onClick={() => onDeleteSelfReply(idx)}
                      >
                        <DeleteRounded />
                      </IconButton>
                    </InlineRowStack>
                  </SpaceBetweenRow>
                </StepLabel>
                <StepContent>
                  {idx === activeReplyIndex ? (
                    <SelfReplyInput
                      idx={idx}
                      channel={channel}
                      onChangeReplyContent={onChangeReplyContent}
                      onChangeReplyAttachments={onChangeReplyAttachments}
                      selfReply={reply}
                    />
                  ) : (
                    <Paper variant="outlined">
                      <SelfReplyPreview selfReply={reply} />
                    </Paper>
                  )}
                </StepContent>
              </Step>
            ))}
          </StyledStepper>
          <AddReplyButtonContainer justifyContent="flex-end">
            <NeutralButton
              disabled={
                selfReplies.length > 0 &&
                isEmpty(last(selfReplies).content) &&
                isEmpty(last(selfReplies).attachments)
              }
              onClick={onAddSelfReply}
            >
              <Translate id="ThreadBuilderDialog.addReply" />
            </NeutralButton>
          </AddReplyButtonContainer>
        </PostCreatorCollapsibleSection>
      </Collapse>
    )
  );
};

export default ThreadWidget;
