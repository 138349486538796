// @flow
import * as React from "react";
import type { PortfolioSummaryAccount } from "../models/portfolioSummary.model";
import { getSummaryAccountsCounts } from "../models/portfolioSummary.model";

const useAccountsSummaryCounts = (
  accounts: PortfolioSummaryAccount[]
): ReturnType<typeof getSummaryAccountsCounts> => {
  return React.useMemo(() => getSummaryAccountsCounts(accounts), [accounts]);
};

export default useAccountsSummaryCounts;
