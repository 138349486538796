// @flow
import * as React from "react";
import { alpha, OutlinedInput, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RowStack } from "../../../lib/layout/stacks";
import type { Callback } from "../../../../types";
import EmojiPicker from "../../../lib/inputs/pickers/EmojiPicker";
import useIsMobile from "../../../../hooks/useIsMobile";
import { SendRounded, SentimentSatisfiedRounded } from "@mui/icons-material";
import type { Children, CSSProps } from "../../../../reactTypes";
import {
  SmallPrimaryButton,
  SmallTextButton,
} from "../../../lib/inputs/buttons";
import useTranslate from "../../../../hooks/useTranslate";
import { grey } from "@mui/material/colors";

export type Props = {
  loading?: boolean,
  empty?: boolean,
  text: string,
  onChangeText: (string) => any,
  onSubmit: Callback,
  actions?: Children,
  children?: Children,
  ...CSSProps,
};

const Root = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const Input = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: alpha(grey[400], theme.palette.action.activatedOpacity),
  flexGrow: 1,
}));

const MessageInput: React.ComponentType<Props> = React.forwardRef(
  (
    {
      loading,
      empty,
      text,
      onChangeText,
      onSubmit,
      actions,
      children,
      ...props
    },
    ref
  ) => {
    const inputElRef = React.useRef<?HTMLInputElement>();
    const isMobile = useIsMobile();
    const t = useTranslate();

    // Submit on Ctrl+Enter
    const handleKeyDown = React.useCallback(
      (e: SyntheticKeyboardEvent<>) =>
        e.keyCode === 13 && e.ctrlKey && onSubmit(),
      [onSubmit]
    );

    return (
      <Root {...props} ref={ref}>
        <RowStack>
          <Input
            size="small"
            disabled={loading}
            inputRef={inputElRef}
            value={text}
            onKeyDown={handleKeyDown}
            onChange={(e) => onChangeText(e.currentTarget.value)}
            placeholder={t("global.reply")}
            margin="none"
            maxRows={2}
            multiline
          />
          {!isMobile && (
            <EmojiPicker
              inputEl={inputElRef.current}
              onChangeInputValue={onChangeText}
            >
              <SmallTextButton loading={loading}>
                <SentimentSatisfiedRounded />
              </SmallTextButton>
            </EmojiPicker>
          )}
          {actions}
          <SmallPrimaryButton
            onClick={onSubmit}
            loading={loading}
            disabled={empty}
          >
            <SendRounded />
          </SmallPrimaryButton>
        </RowStack>
        {children}
      </Root>
    );
  }
);

export default MessageInput;
