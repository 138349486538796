// @flow
import * as React from "react";
import { useStore } from "../store/store";
import { Outlet, useParams } from "react-router-dom";
import type { StoreActions, StoreState } from "../store/store";
import type { ModelID } from "../types";
import { useNavigate } from "react-router-dom";
import * as snacks from "../models/alerts.model";
import { useEffect } from "react";
import noop from "lodash/noop";
import PleaseWaitProgress from "../components/feedback/PleaseWaitProgress";
import { styled } from "@mui/material/styles";
import type { User } from "../models/user.model";
import type { SupportsID } from "../models/base.model";

export type CreateScopeReturn<Resource> = [
  () => ?Resource,
  React.ComponentType<empty>
];

const Progress = styled(PleaseWaitProgress)(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  flexGrow: 0,
  flexShrink: 1,
}));

/**
 * Create a scope component.
 * Scope components capture resources from URL parameters and put them
 * into a context for nested usage.
 */
const createResourceScope = <K: string, Resource: SupportsID>(
  key: K,
  // undefined means existence unknown, null means missing.
  selector: (state: StoreState, id: ModelID) => Resource | null | void,
  onLoaded: (Resource, StoreActions, ?User) => any = noop
): CreateScopeReturn<Resource> => {
  const Context = React.createContext<?Resource>();
  /**
   * The hook to use the resource.
   */
  const useResource = (): ?Resource => React.useContext(Context);
  const checkModelID = (modelId: ?ModelID): Promise<ModelID> =>
    modelId ? Promise.resolve(modelId) : Promise.reject();

  /**
   * The scoped component.
   */
  const ScopeComponent = () => {
    const resourceId = useParams<{ [K]: string }>()[key];
    const modelId = Number(resourceId);
    const [state, actions] = useStore();
    const navigate = useNavigate();

    // The resource may already exist at mount time, call the selector
    // once to init state.
    const maybeResource = modelId ? selector(state, modelId) : undefined;
    const [resource, setResource] = React.useState<?Resource>(maybeResource);
    // If resource exist at load time, we assume that its onLoaded
    // function does not need to be called either.
    const lastLoadedResourceIdRef = React.useRef(0);

    // Whenever modelID changes (only truly mutable dependency)
    // Try refreshing the resource.
    useEffect(() => {
      checkModelID(modelId)
        .then((modelId) => selector(state, modelId))
        .then((res) => {
          setResource(res);
          if (res === null) {
            return Promise.reject();
          }
        })
        .catch(() => {
          actions.snacks.append(
            snacks.localWarning({ message: "global.notFound" })
          );
          navigate("..", { replace: true });
        });
    }, [state, modelId, navigate, actions]);

    // If the resource has changed, then trigger onLoaded, unless
    // loading has already occured.
    useEffect(() => {
      if (!!resource && lastLoadedResourceIdRef.current !== resource.id) {
        lastLoadedResourceIdRef.current = resource.id;
        onLoaded && onLoaded(resource, actions, state.profile.currentUser);
      }
    }, [resource, actions, state.profile.currentUser]);

    if (!resource) {
      return <Progress />;
    }

    return (
      <Context.Provider value={resource}>
        <Outlet />
      </Context.Provider>
    );
  };

  return [useResource, ScopeComponent];
};

export default createResourceScope;
