// @flow
import * as React from "react";
import AttachmentsEditorDialog from "./AttachmentsEditorDialog";
import type { Callback, I18nKey } from "../../../types";
import PostBuilderContext from "../../../contexts/postBuilder";
import type { PostAttachment } from "../../../models/post.model";
import { splice } from "../../../lib/lodashex.lib";
import AttachmentUserTagsEditor from "./AttachmentUserTagsEditor";

type Props = {
  title: I18nKey,
  onClose: Callback,
  AttachmentEditorComponent: typeof AttachmentUserTagsEditor,
};

const AttachmentsEditorDialogContainer: React.ComponentType<Props> = ({
  title,
  onClose,
  AttachmentEditorComponent,
}) => {
  const [activePage, setActivePage] = React.useState(0);
  const {
    post: { attachments },
    onUpdatePost,
  } = React.useContext(PostBuilderContext);
  const [editedAttachments, setEditedAttachments] =
    React.useState<PostAttachment[]>(attachments);
  const activeAttachment = React.useMemo(
    () => editedAttachments[activePage],
    [editedAttachments, activePage]
  );

  const handleSubmit = () => {
    onUpdatePost({
      attachments: attachments.map(
        (attachment) =>
          editedAttachments.find((att) => att.id === attachment.id) ??
          attachment
      ),
    });
  };

  React.useEffect(() => {
    setEditedAttachments(attachments);
  }, [attachments]);

  const handleChangeAttachment = (newAttachment: PostAttachment) => {
    setEditedAttachments((oldAttachments) =>
      splice(oldAttachments, activePage, 1, [newAttachment])
    );
  };

  return (
    <AttachmentsEditorDialog
      title={title}
      activePage={activePage}
      onSetActivePage={setActivePage}
      onClose={onClose}
      onSubmit={handleSubmit}
      attachments={attachments}
    >
      {activeAttachment && (
        <AttachmentEditorComponent
          attachment={activeAttachment}
          onChangeAttachment={handleChangeAttachment}
        />
      )}
    </AttachmentsEditorDialog>
  );
};

export default AttachmentsEditorDialogContainer;
