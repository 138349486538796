// @flow

import type { ActionTypes } from "../models/channels/googleMyBusiness.model";
import type { I18nKey } from "../types";

type GMBActionType = {
  value: ActionTypes,
  name: I18nKey,
  description: I18nKey,
};

const BOOK: GMBActionType = {
  value: "BOOK",
  name: "refer.channels.gmb.actionTypes.book.name",
  description: "refer.channels.gmb.actionTypes.book.description",
};

const ORDER: GMBActionType = {
  value: "ORDER",
  name: "refer.channels.gmb.actionTypes.order.name",
  description: "refer.channels.gmb.actionTypes.order.description",
};

const SHOP: GMBActionType = {
  value: "SHOP",
  name: "refer.channels.gmb.actionTypes.shop.name",
  description: "refer.channels.gmb.actionTypes.shop.description",
};

const LEARN_MORE: GMBActionType = {
  value: "LEARN_MORE",
  name: "refer.channels.gmb.actionTypes.learnMore.name",
  description: "refer.channels.gmb.actionTypes.learnMore.description",
};

const SIGN_UP: GMBActionType = {
  value: "SIGN_UP",
  name: "refer.channels.gmb.actionTypes.signUp.name",
  description: "refer.channels.gmb.actionTypes.signUp.description",
};

export const CALL: GMBActionType = {
  value: "CALL",
  name: "refer.channels.gmb.actionTypes.call.name",
  description: "refer.channels.gmb.actionTypes.call.description",
};

const all: GMBActionType[] = [BOOK, ORDER, SHOP, LEARN_MORE, SIGN_UP, CALL];

export const getValue = (v: GMBActionType): ActionTypes => v.value;
export const getName = (v: GMBActionType): I18nKey => v.name;

export default all;
