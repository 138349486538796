// @flow
import * as React from "react";
import { alpha, Toolbar } from "@mui/material";
import type { I18nKey } from "../../../../types";
import { styled } from "@mui/material/styles";
import useTranslate from "../../../../hooks/useTranslate";
import { Subheader } from "../Text";
import type { RowId, TableSelection } from "./base";
import type { TableToolbarActionConfig } from "./TableAction";
import type { CSSProps } from "../../../../reactTypes";

export type Props<K = RowId> = {
  title?: I18nKey,
  selection: TableSelection<K>,
  actions?: TableToolbarActionConfig<K>[],
  ...CSSProps,
  variant?: "dense" | "regular",
};

const ResponsiveToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== "numSelected",
})(({ theme, numSelected }) => ({
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(1, 2, 1, 2),
  },
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  transition: "background-color ease 0.3s",
  ...(numSelected > 0 && {
    backgroundColor: alpha(
      theme.palette.secondary.main,
      theme.palette.action.activatedOpacity
    ),
  }),
}));

const TableToolbar = <K = RowId>({
  title,
  selection,
  actions,
  ...props
}: Props<K>): React.Node => {
  const t = useTranslate();
  return (
    <ResponsiveToolbar numSelected={selection.selectedCount} {...props}>
      <Subheader component="div" fontWeight="bold" flexGrow={1}>
        {selection.selectedCount > 0
          ? t("global.countSelected", { count: selection.selectedCount })
          : title
          ? t(title)
          : ""}
      </Subheader>
      {actions &&
        actions.map(({ Component, ...actionProps }) => (
          <Component selection={selection} {...actionProps} />
        ))}
    </ResponsiveToolbar>
  );
};

export default TableToolbar;
