// @flow
import ajax from "../../lib/ajax.lib";
import type { ModelID, Timestamp } from "../../types";
import type { APIMessage, Message } from "../../models/message.model";
import { fromAPIResult as messageFromAPIResult } from "../../models/message.model";
import type {
  APIConversationSummary,
  ConversationSummary,
} from "../../models/conversationSummary.model";
import { fromAPIResult as conversationSummaryFromAPIResult } from "../../models/conversationSummary.model";
import type {
  APIConversation,
  Conversation,
} from "../../models/conversation.model";
import { fromAPIResult as conversationFromAPIResult } from "../../models/conversation.model";
import type {
  APINoContentResult,
  APIResult,
  OrderByDirection,
  Pagination,
} from "./types";
import type { RefAttachmentPayload } from "../attachment.service";
import { uploadFileAttachments } from "../attachment.service";
import type { LocalAttachment } from "../../models/attachment.model";

export type MessageParams = {
  text: string,
  media: LocalAttachment[],
};

export type CreateMessageParams = {
  ...MessageParams,
};

type ProcessedMessageParams = {
  ...CreateMessageParams,
  media: RefAttachmentPayload[],
};

const prepareMessageParams = async (message: CreateMessageParams) => ({
  text: message.text,
  media: await uploadFileAttachments(message.media, true),
});

export const addMessage = (
  conversationId: ModelID,
  params: MessageParams
): Promise<Message[]> =>
  prepareMessageParams(params)
    .then((processedParams) =>
      ajax.post<ProcessedMessageParams, APIResult<APIMessage[]>>(
        `/messaging/conversations/${conversationId}/messages`,
        processedParams
      )
    )
    .then((resp) => resp.data.data.map(messageFromAPIResult));

export const getMessages = (
  conversationId: ModelID,
  direction: OrderByDirection,
  reference: Timestamp,
  limit?: number = 20
): Promise<Message[]> =>
  ajax
    .get<APIResult<APIMessage[]>>(
      `/messaging/conversations/${conversationId}/messages?order=${direction}&date=${reference}&limit=${limit}`
    )
    .then((resp) => resp.data.data.map(messageFromAPIResult));

export const markConversationAsRead = (
  conversationId: ModelID
): Promise<APINoContentResult> =>
  ajax.post<void, APINoContentResult>(
    `/messaging/conversations/${conversationId}/read`
  );

export const getConversationSummaries = ({
  page,
  pagesize,
  portfolioId,
}: {
  ...Pagination,
  portfolioId: ModelID,
}): Promise<ConversationSummary[]> =>
  ajax
    .get<APIResult<APIConversationSummary[]>>(
      `/portfolio/${portfolioId}/conversations/summary?page=${
        page ?? 1
      }&pagesize=${pagesize ?? 200}`
    )
    .then((response) =>
      response.data.data.map(conversationSummaryFromAPIResult)
    );

export const getConversations = ({
  page,
  pagesize,
  portfolioId,
}: {
  ...Pagination,
  portfolioId: ModelID,
}): Promise<Conversation[]> =>
  ajax
    .get<APIResult<APIConversation[]>>(
      `portfolio/${portfolioId}/conversations?page=${page ?? 1}&pagesize=${
        pagesize ?? 200
      }`
    )
    .then((response) => response.data.data.map(conversationFromAPIResult));

export const getConversationSummary = (
  conversationId: ModelID
): Promise<ConversationSummary> =>
  ajax
    .get<APIResult<APIConversationSummary>>(
      `/messaging/conversations/${conversationId}/summary`
    )
    .then((response) => conversationSummaryFromAPIResult(response.data.data));
