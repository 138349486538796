// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import {
  PagePanel,
  PrimaryPagePanel,
} from "../../../components/layout/PagePanel";
import { useScopedPortfolio } from "../../../scopes/scopes";
import { PostFiltersContextProvider } from "../../../contexts/postFilters";
import {
  useAccountsLocked,
  usePortfolioChannelAccount,
} from "../../../store/selectors";
import VizPlannerControls from "../../../components/widgets/VizPlannerControls";
import VizPlannerWidget from "../../../components/widgets/VizPlannerWidget";
import { Alert } from "@mui/material";
import Translate from "../../../components/lib/display/Translate";

const VizPlannerPage: React.ComponentType<{}> = () => {
  const pf = useScopedPortfolio();
  const igAccount = usePortfolioChannelAccount(pf?.id, "instagram");
  const accountsLocked = useAccountsLocked();
  const route = routes.app.nested.portfolios.nested.vizPlanner;
  const cannotUse = !accountsLocked && !igAccount;

  const override = React.useMemo(
    () => ({
      accountIds: !!igAccount ? [igAccount.id] : [],
    }),
    [igAccount]
  );

  return (
    <PostFiltersContextProvider override={override}>
      <PrimaryPagePanel size="single" route={route} align="flex-start">
        {cannotUse ? (
          <div>
            <Alert severity="warning">
              <Translate id="global.noAccountForVizPlanner" />
            </Alert>
          </div>
        ) : (
          <VizPlannerControls />
        )}
      </PrimaryPagePanel>
      {!cannotUse && (
        <PagePanel size="fill" variant="secondary">
          <VizPlannerWidget />
        </PagePanel>
      )}
    </PostFiltersContextProvider>
  );
};

export default VizPlannerPage;
