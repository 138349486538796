// @flow
import type { FindIteratee, Integer, ModelID, Timestamp } from "../types";
import { Moment } from "../types";
import moment from "moment";

export type ConversationSummary = {
  conversation_id: ModelID,
  updated_at: Moment,
  unread_messages: Integer,
  head: string,
};

export type APIConversationSummary = {
  ...Omit<ConversationSummary, "updated_at">,
  updated_at: Timestamp,
};

export const hasUnreadMessages: FindIteratee<ConversationSummary> = (s) =>
  s.unread_messages > 0;

export const fromAPIResult = (
  summary: APIConversationSummary
): ConversationSummary => ({
  ...summary,
  updated_at: moment.unix(summary.updated_at),
});
