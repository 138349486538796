// @flow
import * as React from "react";
import { IconButton, Paper } from "@mui/material";
import type { SyntheticEventListener } from "../../../reactTypes";
import { Body2 } from "./Text";
import { styled } from "@mui/material/styles";

const Root = styled(Paper)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
}));

const Content = styled(Body2)(({ theme }) => ({
  textOverflow: "ellipsis",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  fontWeight: "bold",
  flexGrow: 1,
  color: theme.palette.primary.dark,
}));

export type Props = {
  title?: React.Node,
  actionIcon?: React.Node,
  onActionClick: SyntheticEventListener,
  disabled?: boolean,
  children?: string,
};

const ActionText: React.ComponentType<Props> = ({
  children,
  actionIcon,
  onActionClick,
  disabled,
  title,
}) => (
  <Root variant="outlined">
    {title && <Body2 fontWeight="bold">{title}</Body2>}
    <Content>{children}</Content>
    <IconButton disabled={disabled} onClick={onActionClick}>
      {actionIcon}
    </IconButton>
  </Root>
);

export default ActionText;
