// @flow
import * as React from "react";
import type { Props as ConfirmDialogProps } from "./ConfirmDialog";
import type { Callback } from "../../../types";
import useConfirmDialog from "./useConfirmDialog";

export type Options = {
  DialogProps: Omit<ConfirmDialogProps<>, "open" | "onConfirm" | "onClose">,
};

/**
 * Gives a popup behaviour to a Component.
 * It can now accept children as menu items.
 */
export const withConfirmDialog =
  <Props, ConfirmArg = void>(
    Component: React.ComponentType<Props>,
    options: Options
  ): (React.ComponentType<{
    ...Props,
    onClick: (ConfirmArg) => any,
    onOpen?: Callback,
    onClose?: Callback,
  }>) =>
  ({ onClick, onOpen, onClose, ...props }) => {
    const [onComponentClick, dlg] = useConfirmDialog(
      options,
      onClick,
      onOpen,
      onClose
    );
    return (
      <>
        <Component {...props} onClick={onComponentClick} />
        {dlg}
      </>
    );
  };

export default withConfirmDialog;
