// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import {
  useScopedHashtagGroup,
  useScopedPortfolio,
} from "../../../scopes/scopes";
import { SecondaryPagePanel } from "../../../components/layout/PagePanel";
import HashtagGroupWidget from "../../../components/widgets/HashtagGroupWidget";
import useNavigateBackToResourceList from "../../../hooks/useNavigateBackToResourceList";

const HashtagGroupsPage: React.ComponentType<empty> = () => {
  const pf = useScopedPortfolio();
  const hashtagGroup = useScopedHashtagGroup();
  const navigateBack = useNavigateBackToResourceList(!!hashtagGroup);
  const hashtagsRoute = routes.app.nested.portfolios.nested.hashtags;

  if (!pf) {
    // For the type checker. Should not happen.
    return false;
  }

  return (
    <SecondaryPagePanel
      route={
        hashtagGroup
          ? hashtagsRoute.nested.hashtagGroup.nested.edit
          : hashtagsRoute.nested.add
      }
      size="dense"
      onClose={navigateBack}
    >
      <HashtagGroupWidget hashtagGroup={hashtagGroup} portfolio={pf} />
    </SecondaryPagePanel>
  );
};

export default HashtagGroupsPage;
