// @flow
import * as React from "react";
import type { PreferencesTabFormDef } from "./PreferencesSection";
import PreferencesSection from "./PreferencesSection";
import { useCurrentUser } from "../../../../store/selectors";
import { FormActions, useForm } from "../../../../hooks/useForm";
import { difference } from "../../../../lib/lodashex.lib";
import * as profileService from "../../../../services/profile.service";
import useSwitch from "../../../../hooks/useSwitch";
import { useStoreActions } from "../../../../store/store";
import palettes, {
  BOBCAAT_PALETTE,
  CUSTOM_PALETTE,
} from "../../../../config/palettes.config";
import { en_gb, getLocale } from "../../../../config/locales.config";
import { getOptionFromSlug } from "../../../../util/themes.util";

const FORM_FIELDS: PreferencesTabFormDef = {
  locale: {
    type: "object",
  },
  theme: {
    type: "object",
  },
  palette: {
    type: "object",
  },
};

const PreferencesSectionContainer: React.ComponentType<{}> = () => {
  const user = useCurrentUser();
  const actions = useStoreActions();
  const [editing, onBeginEdit, onEndEdit] = useSwitch(false);
  const initial = React.useMemo(
    () => ({
      locale: getLocale(user?.preferences.locale) ?? en_gb,
      theme: getOptionFromSlug(user?.preferences.theme ?? "light"),
      palette:
        // Try to find the existing palette.
        palettes.find(
          (palette) =>
            palette.slug === user?.preferences.palette?.slug &&
            // have to force this otherwise having a custom palette
            // makes the dialog open automatically.
            palette.slug !== CUSTOM_PALETTE.slug
        ) ??
        // Or use the custom one.
        user?.preferences.palette ??
        // Or use the default one.
        BOBCAAT_PALETTE,
    }),
    [user?.preferences]
  );
  const form = useForm(FORM_FIELDS, initial);

  // Can't react to user updates on local at least, because beat
  // is broken and that would reset the palette.

  const handleCancel = () => {
    onEndEdit();
    FormActions.reset(form.set, initial);
  };

  const handleConfirm = () => {
    if (user && FormActions.validate(form)) {
      const rawUpdates = { ...FormActions.collect(form.state) };
      const initial = {
        locale: user.preferences.locale,
        theme: user.preferences.theme,
      };
      const updates = {
        locale: rawUpdates.locale.slug,
        theme: rawUpdates.theme.slug,
      };
      return profileService
        .updateCurrentUser(actions)(user, {
          ...difference(initial, updates),
          // Let palette escape the "full difference", which might lead
          // in bugs if only one of the colours is changed.
          palette: form.state.palette.value,
        })
        .then(onEndEdit);
    }
  };
  return (
    <PreferencesSection
      editing={editing}
      onBeginEdit={onBeginEdit}
      onCancelEdit={handleCancel}
      onCompleteEdit={handleConfirm}
      form={form}
      user={user}
    />
  );
};

export default PreferencesSectionContainer;
