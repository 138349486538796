// @flow
import * as React from "react";
import type { Props as SelectProps } from "../Select";
import Select from "../Select";
import type {
  FormField,
  ObjectFormFieldDef,
  OnChangeField,
} from "../../../../hooks/useForm";
import { useFormFieldError } from "../../../../hooks/useForm";

type Props<T> = {
  ...SelectProps<T>,
  formField: FormField<ObjectFormFieldDef>,
  onChange: OnChangeField<ObjectFormFieldDef>,
};

const FormSelect = <T>({
  formField,
  onChange,
  ...props
}: Props<T>): React.Node => {
  const helperText = useFormFieldError(formField) ?? formField.helperText;
  return (
    <Select
      value={formField.value}
      onChange={onChange}
      helperText={helperText}
      error={!!formField.error}
      name={formField.name}
      {...props}
    />
  );
};

export default FormSelect;
