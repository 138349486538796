// @flow
import * as React from "react";
import type { Pixels } from "../../../types";
import type { Children, CSSProps } from "../../../reactTypes";
import { styled } from "@mui/material/styles";
import type { Theme } from "../../../stubs/mui/theming";

type AnchorOrigin = {
  horizontal?: "left" | "right",
  vertical?: "top" | "bottom",
};

type AnchorOffset = {
  horizontal?: Pixels,
  vertical?: Pixels,
};

type Props = {
  anchorOrigin?: AnchorOrigin,
  offset?: AnchorOffset,
  children?: Children,
  ...CSSProps,
};

/**
 * Inset adorment require a relative parent container.
 */
const InsetAdornment: React.ComponentType<Props> = styled("span", {
  shouldForwardProp: (prop) => !["offset", "anchorOrigin"].includes(prop),
})(({ theme, anchorOrigin = {}, offset = {} }: { ...Props, theme: Theme }) => {
  const vSpacing = theme.spacing(offset.vertical ?? 0.5);
  const hSpacing = theme.spacing(offset.horizontal ?? 0.5);
  return {
    position: "absolute",
    zIndex: 1,
    top: anchorOrigin.vertical === "bottom" ? undefined : vSpacing,
    right: anchorOrigin.horizontal === "left" ? undefined : hSpacing,
    bottom: anchorOrigin.vertical === "bottom" ? vSpacing : undefined,
    left: anchorOrigin.horizontal === "left" ? hSpacing : undefined,
  };
});

export default InsetAdornment;
