// @flow
import * as React from "react";
import { PostCreatorCollapsibleSection } from "../../layout/PostCreatorSection";
import { Collapse } from "@mui/material";
import PostBuilderContext from "../../../contexts/postBuilder";
import { Body2 } from "../../lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import RichContentInput from "../../inputs/RichContentInput";
import useInputCharLimitHelper from "../../../hooks/useInputCharLimitHelper";
import useIsMobile from "../../../hooks/useIsMobile";
import { SmallNeutralButton } from "../../lib/inputs/buttons";
import { SpaceBetweenRow } from "../../lib/layout/stacks";

const FirstCommentWidget: React.ComponentType<{}> = () => {
  const { post, onUpdatePost, channel } = React.useContext(PostBuilderContext);
  const t = useTranslate();
  const isMobile = useIsMobile();

  const handleSetComment = React.useCallback(
    (first_comment: ?string) => onUpdatePost({ first_comment }),
    [onUpdatePost]
  );

  const [onChangeComment, helperFactory, isError] = useInputCharLimitHelper({
    // Currently, our first comment is limited to 2048 characters.
    maxLength: Math.min(channel?.contentSpecs.maxChars ?? 9999, 2048),
    onChange: handleSetComment,
  });

  return (
    channel?.features?.commentManagement && (
      <Collapse in={typeof post.first_comment === "string"}>
        <PostCreatorCollapsibleSection
          title="global.firstComment"
          defaultCollapsed={!!post.id}
        >
          <SpaceBetweenRow marginBottom={1} alignItems="center">
            <Body2 color="textSecondary">
              {t("PostCreator.firstCommentMessage")}
            </Body2>

            <SmallNeutralButton onClick={() => handleSetComment(null)}>
              {t("global.delete")}
            </SmallNeutralButton>
          </SpaceBetweenRow>
          <RichContentInput
            fieldProps={{
              placeholder: t("PostCreator.commentPlaceholder"),
              value: post.first_comment ?? "",
              onChange: (e) => onChangeComment(e.target.value),
              error: isError(post.content),
              helperText: helperFactory(post.content),
              multiline: true,
              minRows: 3,
              maxRows: 6,
            }}
            onInsertEmoji={isMobile ? undefined : handleSetComment}
            onInsertHashtagGroup={handleSetComment}
          />
        </PostCreatorCollapsibleSection>
      </Collapse>
    )
  );
};

export default FirstCommentWidget;
