// @flow
import * as React from "react";
import type { ModelID } from "../../types";
import RouterLink from "../lib/navigation/RouterLink";
import routes, { getRoutePath } from "../../config/routes.config";
import { styled } from "@mui/material/styles";

type Props = {
  title?: ?string,
  postId: ModelID,
  portfolioId: ModelID,
};

const Handle = styled(RouterLink)(({ theme }) => ({
  borderRadius: 4,
  padding: theme.spacing(0.4, 1),
  border: `solid 1px ${theme.palette.primary.main}`,
}));

const PostHandle: React.ComponentType<Props> = ({ postId, portfolioId }) => {
  return (
    <Handle
      variant="body2"
      to={getRoutePath(
        routes.app.nested.portfolios.nested.creator.nested.post.nested.view,
        { portfolioId, postId }
      )}
    >
      {postId.toString(16)}
    </Handle>
  );
};

export default PostHandle;
