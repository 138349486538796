// @flow
import * as React from "react";
import MiniApprovalRequestsWidget from "./MiniApprovalRequestsWidget";
import usePaginationState from "../../../hooks/usePaginationState";
import type { ApprovalRequest } from "../../../models/approvalRequest.model";
import { pageOf } from "../../../lib/lodashex.lib";
import { useApprovalRequestsLocked } from "../../../store/selectors";

type Props = {
  approvalRequests: ApprovalRequest[],
  pageSize?: number,
  onSelect: (ApprovalRequest) => any,
};

const MiniApprovalRequestsWidgetContainer: React.ComponentType<Props> = ({
  approvalRequests,
  pageSize = 3,
  onSelect,
}) => {
  const pages = Math.ceil(approvalRequests.length / pageSize) || 1;
  const approvalRequestsLocked = useApprovalRequestsLocked();
  const [page, onPreviousPage, onNextPage] = usePaginationState(pages);
  const pageItems = pageOf(approvalRequests, page, pageSize);

  return (
    <MiniApprovalRequestsWidget
      onSelect={onSelect}
      locked={approvalRequestsLocked}
      page={page}
      pages={pages}
      approvalRequests={pageItems}
      onPreviousPage={onPreviousPage}
      onNextPage={onNextPage}
      totalCount={approvalRequests.length}
    />
  );
};

export default MiniApprovalRequestsWidgetContainer;
