// @flow
import * as React from "react";
import type { Milliseconds } from "../types";

const useInterval = (
  callback: () => void,
  delay: Milliseconds,
  onlyOnWindowFocus?: boolean
) => {
  const savedCallback = React.useRef<() => void>(callback);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    const tick = () => {
      if (!onlyOnWindowFocus || document.hasFocus()) {
        savedCallback.current();
      }
    };
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, onlyOnWindowFocus]);
};

export default useInterval;
