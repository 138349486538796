// @flow
import * as React from "react";
import routes from "../../../config/routes.config";
import { PrimaryPagePanel } from "../../../components/layout/PagePanel";
import InviteAFriendWidget from "../../../components/widgets/InviteAFriendWidget";

const InviteAFriendPage: React.ComponentType<empty> = () => {
  return (
    <PrimaryPagePanel route={routes.app.nested.referral}>
      <InviteAFriendWidget />
    </PrimaryPagePanel>
  );
};

export default InviteAFriendPage;
