// @flow
import * as React from "react";
import PostStatusInput from "./PostStatusInput";
import DisableAutopublishSetting from "./DisableAutopublishSetting";
import PostScheduleWidget from "./PostScheduleWidget";
import { PostCreatorCollapsibleSection } from "../../layout/PostCreatorSection";
import PostBuilderContext from "../../../contexts/postBuilder";

const PostScheduleAndStatusWidget: React.ComponentType<{}> = () => {
  const {
    post: { id, subchannel_id },
    channel,
  } = React.useContext(PostBuilderContext);
  return (
    !!channel &&
    (subchannel_id || !channel.requires?.subchannel) && (
      <PostCreatorCollapsibleSection
        title="global.scheduling"
        defaultCollapsed={!!id}
      >
        <PostStatusInput />
        <PostScheduleWidget />
        <DisableAutopublishSetting />
      </PostCreatorCollapsibleSection>
    )
  );
};

export default PostScheduleAndStatusWidget;
