// @flow
import * as React from "react";
import { useRef } from "react";
import { FixedSizeList as FixedSizeListImpl } from "react-window";
import type { SupportsID } from "../../../models/base.model";
import { Grow, LinearProgress, List, Paper } from "@mui/material";
import { RoundedSkeletonListItem } from "../display/listItems";
import NoResults from "../../display/NoResults";
import { styled } from "@mui/material/styles";
import type { InitStatus } from "../../../store/reducers/lib/init.state";

type ListItemComponent<T: SupportsID, P: Object = {}> = React.ComponentType<{
  item: T,
  style: Object,
  ...P,
}>;

type Props<T: SupportsID, P: Object = {}> = {
  itemSize?: number,
  items: T[],
  itemProps?: P,
  height?: ?number,
  ItemComponent: ListItemComponent<T, P>,
};

type RendererFactoryParams<T: SupportsID, P: Object> = {
  data: { items: T[], ...P },
  index: number,
  style: Object,
};

/**
 * Tested on a MacBook Pro 13". Max number of items visible at a time.
 * @type {number}
 */
const DEFAULT_VISIBLE_ITEMS = 7;

const rendererFactory =
  <T: SupportsID, P: Object>(
    ItemComponent: ListItemComponent<T, P>
  ): ((RendererFactoryParams<T, P>) => React.Node) =>
  ({ data: { items, ...props }, index, style }) => {
    const item = items[index];
    return <ItemComponent key={item.id} item={item} style={style} {...props} />;
  };

const FixedSizeList = <T: SupportsID, P: Object = {}>({
  height,
  ItemComponent,
  itemSize = 75,
  itemProps,
  items,
}: Props<T, P>): React.Node => (
  <FixedSizeListImpl
    height={height ?? DEFAULT_VISIBLE_ITEMS * itemSize}
    itemCount={items.length}
    itemData={{ items, ...itemProps }}
    itemSize={itemSize}
    width="100%"
  >
    {React.useMemo(() => rendererFactory(ItemComponent), [ItemComponent])}
  </FixedSizeListImpl>
);

const AutoSizeRoot = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "height",
})(({ theme, height }) => ({
  flexGrow: 1,
  fleShrink: 1,
  overflow: "hidden",
  minWidth: 280,
  height,
  [theme.breakpoints.down("sm")]: {
    borderRadius: 0,
    border: `solid 1px ${theme.palette.divider}`,
  },
}));

type AutoSizeProps<T: SupportsID, P: Object = {}> = {
  ...Omit<Props<T, P>, "height">,
  itemsInitStatus?: InitStatus,
};

export const AutoSizeList = <T: SupportsID, P: Object = {}>({
  items,
  itemsInitStatus = "all",
  ...props
}: AutoSizeProps<T, P>): React.Node => {
  const paperRef = useRef<?HTMLElement>();
  return (
    <Grow in>
      <AutoSizeRoot
        ref={paperRef}
        // When the initial height of the paper is captured,
        // it will be forced here and because it is overflow="hidden"
        // it won't grow with its content afterward.
        height={paperRef.current?.clientHeight}
      >
        {itemsInitStatus === "some" && <LinearProgress />}
        <List dense>
          {itemsInitStatus === "none" && (
            <>
              <RoundedSkeletonListItem />
              <RoundedSkeletonListItem />
              <RoundedSkeletonListItem />
            </>
          )}
          {itemsInitStatus === "all" && items.length === 0 && <NoResults />}
          {itemsInitStatus !== "none" && items.length > 0 && (
            <FixedSizeList
              height={
                paperRef.current
                  ? /* take into account the List padding */
                    paperRef.current.clientHeight - 16
                  : undefined
              }
              items={items}
              {...props}
            />
          )}
        </List>
      </AutoSizeRoot>
    </Grow>
  );
};

export default FixedSizeList;
