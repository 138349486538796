// @flow
import {
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors as useSensorsImpl,
} from "@dnd-kit/core";

type SensorOptions = {
  activationConstraint: {
    /**
     * Delay in milliseconds before activation.
     */
    delay?: number,
    /**
     * Represents the distance, in pixels, of motion that is tolerated
     * before the drag operation is aborted.
     */
    tolerance?: number,
    /**
     * Represents the distance, in pixels, by which the input needs to be
     * moved before a drag start event is emitted.
     */
    distance?: number,
  },
};

const DEFAULT_TOUCH_OPTIONS: SensorOptions = {
  activationConstraint: {
    /**
     * Delay in milliseconds before activation.
     */
    delay: 500,
    /**
     * Represents the distance, in pixels, of motion that is tolerated
     * before the drag operation is aborted.
     */
    tolerance: 5,
  },
};

const DEFAULT_POINTER_OPTIONS: SensorOptions = {
  activationConstraint: {
    distance: 10,
  },
};

export type Params = {
  touch?: ?SensorOptions,
  mouse?: ?SensorOptions,
};

const useSensors = (params?: Params): { sensor: any, options: any } => {
  const mouseSensor = useSensor(
    MouseSensor,
    params?.mouse ?? DEFAULT_POINTER_OPTIONS
  );
  const touchSensor = useSensor(
    TouchSensor,
    params?.touch ?? DEFAULT_TOUCH_OPTIONS
  );

  return useSensorsImpl(touchSensor, mouseSensor);
};

export default useSensors;
