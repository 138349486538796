// @flow
import { useEffect, useState } from "react";
import { API as backend } from "../../../services/backend.service";
import type { CardDetails } from "../../../models/subscription.model";

const useCurrentCardDetails = (): ?CardDetails => {
  const [cardDetails, setCardDetails] = useState<?CardDetails>(undefined);

  useEffect(() => {
    backend.subscription
      .getCurrentCardDetails()
      .then(setCardDetails)
      .catch(console.log);
  }, []);

  return cardDetails;
};

export default useCurrentCardDetails;
