// @flow
import * as React from "react";
import type { PostFiltersContextType } from "../../../contexts/postFilters";
import { Body1, Body2 } from "../../lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MediaInput from "../../lib/inputs/MediaInput/MediaInput";
import type { MediaItem } from "../../lib/inputs/MediaInput/useHeadlessMediaInput";
import instagram from "../../../models/channels/instagram.model";
import { getAccepts, getMaxSize } from "../../../models/channels";
import type { ValidationError } from "../../../util/validation.util";
import { ColumnStack } from "../../lib/layout/stacks";

type Props = {
  onMediaError: (?ValidationError) => any,
  onAddMedia: (MediaItem[]) => any,
  mediaError: ?ValidationError,
  filters: PostFiltersContextType,
};

const VizPlannerControls: React.ComponentType<Props> = ({
  filters,
  onMediaError,
  onAddMedia,
  mediaError,
}) => {
  const t = useTranslate();
  return (
    <>
      <Body1>{t("VizPlannerSection.description")}</Body1>
      <Body2 color="textSecondary">{t("VizPlannerSection.datesLabel")}</Body2>
      <ColumnStack>
        <DatePicker
          label={t("global.minDate")}
          value={filters.fromDate}
          onChange={filters.onSetFromDate}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => filters.onSetFromDate(null),
            },
          }}
        />
        <DatePicker
          label={t("global.maxDate")}
          value={filters.toDate}
          onChange={filters.onSetToDate}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => filters.onSetToDate(null),
            },
          }}
        />
      </ColumnStack>
      <MediaInput
        error={!!mediaError}
        items={[]}
        maxSize={getMaxSize(instagram)}
        maxFiles={10}
        accept={getAccepts(instagram)}
        onChange={onAddMedia}
        onError={onMediaError}
        helperText={
          mediaError ? t(mediaError.error, mediaError.data) : undefined
        }
        forceImagesAsJpeg={instagram.contentSpecs.media.image?.pngAsJpeg}
      />
    </>
  );
};

export default VizPlannerControls;
