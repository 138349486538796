// @flow
import * as React from "react";
import type { Severity } from "../../../models/alerts.model";
import type { Children, CSSProps } from "../../../reactTypes";
import { propMap } from "../utils/props";
import {
  CheckCircleRounded,
  ErrorRounded,
  InfoRounded,
  WarningRounded,
} from "@mui/icons-material";
import { Body2 } from "./Text";
import ColoredChip from "./ColoredChip";

type Props = {
  severity: Severity,
  icon?: boolean | React.ComponentType<{ fontSize?: string, color?: string }>,
  children?: Children,
  slots?: {
    text?: React.ComponentType<{ children: Children }>,
  },
  onClick?: (e: SyntheticEvent<>) => any,
  dense?: boolean,
  ...CSSProps,
};

const SEVERITY_ICONS: Record<Severity, React.ComponentType<any>> = {
  success: CheckCircleRounded,
  warning: WarningRounded,
  error: ErrorRounded,
  info: InfoRounded,
};

const getIcon = propMap(SEVERITY_ICONS, "info");

const StatusChip: React.ComponentType<Props> = React.forwardRef(
  ({ severity, children, icon = true, slots = {}, ...props }, ref) => {
    const Icon = typeof icon === "object" ? icon : icon && getIcon(severity);
    const Text = slots.text ?? Body2;
    return (
      <ColoredChip paletteColor={severity} {...props} ref={ref}>
        {Icon && <Icon fontSize="inherit" color="inherit" />}
        <Text>{children}</Text>
      </ColoredChip>
    );
  }
);

export default StatusChip;
