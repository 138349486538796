// @flow
import uniqueId from "lodash/uniqueId";

/**
 * Specifies severity of messages and alerts.
 */
export type Severity = "error" | "info" | "warning" | "success";

/**
 * A snack to display to the user right away, and that should
 * go after a while.
 */
export type Snack = {
  id: number,
  severity: Severity,
  message: string,
  messageData?: Object,
  translate?: boolean,
};

/**
 * A much bigger alert that requires interaction to close.
 */
export type ModalAlert = {
  severity: Severity,
  title: string,
  message: string,
  translate?: boolean,
  titleData?: Object,
  messageData?: Object,
  messageComponents?: Object,
};

type SnackFunc = (Pick<Snack, "message" | "messageData">) => Snack;

const createSnack =
  (severity: Severity, translate?: boolean): SnackFunc =>
  (params) => ({ ...params, severity, translate, id: uniqueId() });

export const info: SnackFunc = createSnack("info");
export const warning: SnackFunc = createSnack("warning");
export const success: SnackFunc = createSnack("success");
export const error: SnackFunc = createSnack("error");
export const localInfo: SnackFunc = createSnack("info", true);
export const localWarning: SnackFunc = createSnack("warning", true);
export const localSuccess: SnackFunc = createSnack("success", true);
export const localError: SnackFunc = createSnack("error", true);

type CreateModalAlertParams = Omit<ModalAlert, "severity" | "translate">;
type ModalAlertFunc = (CreateModalAlertParams) => ModalAlert;

const createModalAlert =
  (severity: Severity, translate?: boolean): ModalAlertFunc =>
  (params) => ({ ...params, severity, translate });

export const modalInfo: ModalAlertFunc = createModalAlert("info");
export const modalWarning: ModalAlertFunc = createModalAlert("warning");
export const modalSuccess: ModalAlertFunc = createModalAlert("success");
export const modalError: ModalAlertFunc = createModalAlert("error");
export const modalLocalInfo: ModalAlertFunc = createModalAlert("info", true);
export const modalLocalWarning: ModalAlertFunc = createModalAlert(
  "warning",
  true
);
export const modalLocalSuccess: ModalAlertFunc = createModalAlert(
  "success",
  true
);
export const modalLocalError: ModalAlertFunc = createModalAlert("error", true);
