// @flow
import * as React from "react";
import { Body2 } from "../lib/display/Text";

type Props = {
  title?: ?string,
};

const PostTitle: React.ComponentType<Props> = ({ title }) => {
  return title && <Body2 fontWeight="bold">{title}</Body2>;
};

export default PostTitle;
