// @flow
import * as React from "react";
import { API as backend } from "../../../../services/backend.service";
import type { SetupIntent } from "../../../../models/subscription.model";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import type { Children } from "../../../../reactTypes";
import noop from "lodash/noop";
import * as env from "../../../../config/env.config";
import { useTheme } from "@mui/material";

type Props = {
  children?: Children,
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(env.STRIPE_API_PUBLIC_KEY);
const ELEMENT_FONT =
  "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap";

const StripeContext: React.ComponentType<Props> = ({ children }) => {
  const [setupIntent, setSetupIntent] = React.useState<?SetupIntent>(null);
  const theme = useTheme();

  React.useEffect(() => {
    backend.subscription
      .setupCheckout()
      .then(setSetupIntent)
      // Can throw if token is invalid. That will be handled via
      // automatic redirection by the token manager. Here we just
      // silence there error rather than sending it to Sentry.
      .catch(noop);
  }, []);

  return setupIntent ? (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: setupIntent.client_secret,
        fonts: [{ cssSrc: ELEMENT_FONT }],
        appearance: {
          theme: theme.palette.mode === "dark" ? "night" : undefined,
        },
      }}
    >
      {children}
    </Elements>
  ) : (
    children
  );
};

export default StripeContext;
