// @flow
import * as React from "react";
import ConversationPreview from "./ConversationPreview";
import MessagesPreview from "./MessagesPreview";
import type { Callback } from "../../../../types";
import type { LocalAttachment } from "../../../../models/attachment.model";
import { styled } from "@mui/material/styles";
import { SecondaryPagePanel } from "../../../layout/PagePanel";
import routes from "../../../../config/routes.config";
import RichMessageInput from "../RichMessageInput";
import type { Conversation } from "../../../../models/conversation.model";

type Props = {
  noMessageLoadedYet?: boolean,
  conversation: Conversation,
  onSendNewMessage: (string, LocalAttachment[]) => any,
  onClose: Callback,
};

const Root = styled(SecondaryPagePanel)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    minWidth: 450,
    maxWidth: 600,
  },
}));

const ConversationEngagementEditor: React.ComponentType<Props> = ({
  conversation,
  onSendNewMessage,
  onClose,
}) => (
  <Root
    size="single"
    onClose={onClose}
    contentKey={conversation.id}
    route={
      routes.app.nested.portfolios.nested.engagement.nested.account.nested.posts
        .nested.post
    }
    absoluteHeader
  >
    <ConversationPreview conversation={conversation} />
    <MessagesPreview conversation={conversation} />
    <RichMessageInput onSubmit={onSendNewMessage} />
  </Root>
);

export default ConversationEngagementEditor;
