// @flow
import * as React from "react";
import { localTimezone } from "../../../models/timezone.model";
import CalendarWidget from "./CalendarWidget";
import { PostFiltersContextProvider } from "../../../contexts/postFilters";
import {
  getOptionFromValue,
  timezoneOptions,
} from "../../../util/timezoneOptions.util";
import type { Portfolio } from "../../../models/portfolio.model";
import useLocationPostFilters from "../../../hooks/useLocationPostFilters";

type Props = {
  portfolio: Portfolio,
};

const CalendarWidgetContainer: React.ComponentType<Props> = ({ portfolio }) => {
  const [timezone, setTimezone] = React.useState(
    getOptionFromValue(localTimezone()?.id) ?? timezoneOptions[0]
  );
  const initial = useLocationPostFilters();

  return (
    <PostFiltersContextProvider initial={initial}>
      <CalendarWidget
        timezone={timezone}
        onSetTimezone={setTimezone}
        portfolio={portfolio}
      />
    </PostFiltersContextProvider>
  );
};

export default CalendarWidgetContainer;
