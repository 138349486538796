// @flow
import * as React from "react";
import CropImageWidget from "./CropImageWidget";
import type { Props as CropImageWidgetProps } from "./CropImageWidget";
import {
  getImageSize,
  resizeImage,
  rotateLeft,
  rotateRight,
  fileToImageDataURL,
} from "../../../lib/images.lib";
import { isDataURL, urlToDataURL } from "../../../lib/files.lib";
import isEmpty from "lodash/isEmpty";
import type { DataURI, URLStr } from "../../../types";

export type CropImageSrc = File | DataURI | URLStr;

const ensureDataURL = async (srcImage: CropImageSrc) => {
  // 1: srcImage is a File. Convert it to data URL.
  if (srcImage instanceof File) return await fileToImageDataURL(srcImage);
  // 2: srcImage is an URL. Convert it to a data URL
  if (!isDataURL(srcImage)) return await urlToDataURL(srcImage);
  // 3: srcImage is a data URL. Do nothing.
  return srcImage;
};

type Props = {
  src?: CropImageSrc,
  onImageCropped: (DataURI) => any,
  slotProps?: CropImageWidgetProps["slotProps"],
};

const CropImageWidgetContainer: React.ComponentType<Props> = ({
  src,
  onImageCropped,
  ...props
}) => {
  const [wholeImage, setWholeImage] = React.useState<?DataURI>(
    typeof src === "string" ? src : null
  );
  const [aspect, setAspect] = React.useState<?number>();
  const [wholeWidth, setWholeWidth] = React.useState(0);
  const [wholeHeight, setWholeHeight] = React.useState(0);
  const [resizeMenuAnchorEl, setResizeMenuAnchorEl] =
    React.useState<?EventTarget>(null);
  const [resizeWidth, setResizeWidth] = React.useState(0);
  const [resizeHeight, setResizeHeight] = React.useState(0);

  React.useEffect(() => {
    // isEmpty returns True for a File.
    // So make sure we test strings only.
    if (!src || (typeof src === "string" && isEmpty(src))) {
      return;
    }

    ensureDataURL(src).then((srcImageDataURL) => {
      setAspect(undefined);
      setWholeImage(srcImageDataURL);

      getImageSize(srcImageDataURL).then(({ width, height }) => {
        setWholeWidth(width);
        setResizeWidth(width);
        setWholeHeight(height);
        setResizeHeight(height);
      });
    });
  }, [src]);

  const handleRotateLeft = () => {
    if (wholeImage) {
      rotateLeft(wholeImage).then(setWholeImage);
    }
  };
  const handleRotateRight = () => {
    if (wholeImage) {
      rotateRight(wholeImage).then(setWholeImage);
    }
  };

  const handleChangeResizeWidth = (e: SyntheticEvent<HTMLInputElement>) => {
    const newWidth = parseInt(e.currentTarget.value);
    setResizeWidth(newWidth);
    setResizeHeight(Math.round((newWidth * wholeHeight) / wholeWidth));
  };

  const handleChangeResizeHeight = (e: SyntheticEvent<HTMLInputElement>) => {
    const newHeight = parseInt(e.currentTarget.value);
    setResizeHeight(newHeight);
    setResizeWidth(Math.round((newHeight * wholeHeight) / wholeWidth));
  };

  const handleCancelResize = () => {
    setResizeHeight(wholeHeight);
    setResizeWidth(wholeWidth);
    setResizeMenuAnchorEl(null);
  };

  const handleResize = () => {
    if (!wholeImage) {
      return;
    }
    resizeImage(wholeImage, resizeWidth, resizeHeight).then(setWholeImage);
    setWholeWidth(resizeWidth);
    setWholeHeight(resizeHeight);
    setResizeHeight(resizeHeight);
    setResizeWidth(resizeWidth);
    setResizeMenuAnchorEl(null);
  };

  return (
    <CropImageWidget
      src={wholeImage}
      onCropComplete={onImageCropped}
      onRotateLeft={handleRotateLeft}
      onRotateRight={handleRotateRight}
      aspect={aspect}
      onSetAspect={setAspect}
      realHeight={wholeHeight}
      realWidth={wholeWidth}
      resizeMenuAnchorEl={resizeMenuAnchorEl}
      onStartResize={(e: SyntheticEvent<>) => setResizeMenuAnchorEl(e.target)}
      onCancelResize={handleCancelResize}
      onConfirmResize={handleResize}
      resizeHeight={resizeHeight}
      resizeWidth={resizeWidth}
      onChangeResizeWidth={handleChangeResizeWidth}
      onChangeResizeHeight={handleChangeResizeHeight}
      {...props}
    />
  );
};

export default CropImageWidgetContainer;
