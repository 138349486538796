// @flow
import * as React from "react";
import type { User } from "../models/user.model";
import HashTagIcon from "../components/lib/display/icons/Hashtag";
import {
  AppsRounded,
  AssignmentInd,
  AssignmentTurnedInRounded,
  BarChartRounded,
  CalendarTodayRounded,
  CreateRounded,
  DashboardRounded,
  ErrorRounded,
  FacebookRounded,
  FeedbackRounded,
  InfoRounded,
  PersonAddRounded,
  Settings,
  SettingsRounded,
  ThumbsUpDown,
  WarningRounded,
} from "@mui/icons-material";
import type { EventType } from "./eventTypes.config";
import * as eventTypes from "./eventTypes.config";
import type { StringDict } from "../types";
import AppPage from "../pages/AppPage";
import StyleGuidePage from "../pages/StyleGuidePage";
import LoginPage from "../pages/LoginPage";
import PasswordResetPage from "../pages/PasswordResetPage";
import IntegrationLoginPage from "../pages/IntegrationLoginPage";
import { isProd } from "./env.config";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import uniqueId from "lodash/uniqueId";
import values from "lodash/values";
import AppDashboardPage from "../pages/AppPage/DashboardPage";
import {
  AccountScope,
  ApprovalRequestScope,
  ConversationScope,
  HashtagGroupScope,
  PortfolioScope,
  PostScope,
} from "../scopes/scopes";
import AddEditPortfolioPage from "../pages/AppPage/AddEditPortfolioPage";
import InviteAFriendPage from "../pages/AppPage/InviteAFriendPage";
import FreeformFeedbackPage from "../pages/AppPage/FreeformFeedbackPage";
import HashtagGroupsPage from "../pages/AppPage/HashtagGroupsPage";
import AddEditHashtagGroupPage from "../pages/AppPage/HashtagGroupsPage/AddEditHashtagGroupPage";
import MetamorphPage from "../pages/AppPage/MetamorphPage";
import AccountsPage from "../pages/AppPage/AccountsPage";
import { makeURLPath } from "../lib/url.lib";
import CalendarPage from "../pages/AppPage/CalendarPage";
import SettingsPage from "../pages/AppPage/SettingsPage";
import AccountTab from "../pages/AppPage/SettingsPage/AccountTab";
import SubscriptionTab from "../pages/AppPage/SettingsPage/SubscriptionTab";
import FreezeOrDeleteTab from "../pages/AppPage/SettingsPage/FreezeOrDeleteTab";
import PlanAndAddOnsPage from "../pages/AppPage/PlanAndAddOnsPage";
import ChangePlanTab from "../components/widgets/settings/ChangePlanTab";
import ManageAddOnsWidget from "../components/widgets/ManageAddOnsWidget";
import CreatorPage from "../pages/AppPage/CreatorPage";
import ApprovalRequestsPage from "../pages/AppPage/ApprovalRequestsPage";
import AddEditapprovalRequestPage from "../pages/AppPage/AddEditapprovalRequestPage";
import ReviewApprovalRequestPage from "../pages/ReviewApprovalRequestPage";
import ViewPostPage from "../pages/AppPage/CreatorPage/ViewPostPage";
import VizPlannerPage from "../pages/AppPage/VizPlannerPage";
import EngagementHubPage from "../pages/AppPage/EngagementHubPage";
import EngagementHubPosts from "../components/widgets/EngagementHubPosts";
import EngagementHubConversations from "../components/widgets/EngagementHubConversations";
import CommentEngagementEditor from "../components/widgets/EngagementEditors/CommentEngagementEditor";
import ConversationEngagementEditor from "../components/widgets/EngagementEditors/ConversationEngagementEditor";
import { AddPostPage, EditPostPage } from "../pages/AppPage/AddEditPostPage";
import AnalyticsPage from "../pages/AppPage/AnalyticsPage";
import HomePage from "../pages/AppPage/HomePage";
import type { TFunction } from "../hooks/useTranslate";
import type { PortfolioSummary } from "../models/portfolioSummary.model";
import {
  getSummaryAccountsCounts,
  summaryAccountHasUnreadEngagement,
} from "../models/portfolioSummary.model";
import some from "lodash/some";
import { isIOS } from "../lib/platform.lib";

export type BrowserRoute = {
  /** The absolute or relative path of the resource **/
  path?: string,
  /** Whether the route must be exact or not. **/
  exact?: boolean,
  /** The component to render. */
  ElementType: string | React.ComponentType<any>,
  /** The optional nested routes */
  nested?: StringDict<BrowserRoute>,
  /** The event to record on a successful navigation. */
  navigationEvent?: EventType,
  /** The short name of the route. */
  label?: string,
  /** The description of the route. */
  subtitle?: string,
  /** The icon of the route. */
  Icon?: React.ComponentType<any>,
  /** If disabled, a route may be visible in navigation but routing is not mounted. */
  disabled?: (?User) => boolean,
  /** If hidden, A route is not visible in navigation but may be mounted. */
  hidden?: (?User) => boolean,
  /** Whether this is the default route for the level. */
  default?: boolean,
  /** A unique ID. Internal and added after declaration. */
  id?: string,
  /** The parent route. Added after declaration. */
  parent?: BrowserRoute,
  /** If the route should be prefixed based on the portfolio summary. */
  getPortfolioSummaryMarker?: (PortfolioSummary) => React.Node,
  /** realtive path to the most meaningful parent route. */
  prevPath?: string,
};

const ICON_SX_MIXIN = {
  fontSize: 15,
  marginLeft: 0.3,
  verticalAlign: "text-top",
};

const I_ICON = <InfoRounded sx={ICON_SX_MIXIN} color="info" />;
const W_ICON = <WarningRounded sx={ICON_SX_MIXIN} color="warning" />;
const E_ICON = <ErrorRounded sx={ICON_SX_MIXIN} color="error" />;

const routes = {
  app: {
    default: true,
    path: "",
    ElementType: AppPage,
    nested: {
      home: {
        path: "/",
        default: true,
        ElementType: HomePage,
        label: "routes.home.label",
        subtitle: "routes.home.subtitle",
      },
      addPortfolio: {
        path: "portfolios/add",
        exact: true,
        label: "routes.addPortfolio.label",
        subtitle: "routes.addPortfolio.subtitle",
        ElementType: AddEditPortfolioPage,
        prevPath: "../../..",
      },
      portfolios: {
        path: "portfolios/:portfolioId",
        ElementType: PortfolioScope,
        nested: {
          dashboard: {
            path: "dashboard",
            prevPath: "../../..",
            default: true,
            Icon: DashboardRounded,
            label: "routes.dashboard.label",
            subtitle: "routes.dashboard.subtitle",
            ElementType: AppDashboardPage,
            navigationEvent: eventTypes.NAVIGATED_TO_DASHBOARD,
          },
          socialAccounts: {
            path: "accounts",
            prevPath: "../dashboard",
            Icon: FacebookRounded,
            label: "routes.socialAccounts.label",
            subtitle: "routes.socialAccounts.subtitle",
            ElementType: AccountsPage,
            getPortfolioSummaryMarker: (
              summary: PortfolioSummary
            ): React.Node => {
              const counts = getSummaryAccountsCounts(summary.accounts);
              if (counts.errors || counts.expired) return E_ICON;
              if (counts.expires_soon) return W_ICON;
              return null;
            },
          },
          creator: {
            path: "creator",
            prevPath: "../dashboard",
            Icon: CreateRounded,
            label: "routes.editor.label",
            subtitle: "routes.editor.subtitle",
            ElementType: CreatorPage,
            navigationEvent: eventTypes.NAVIGATED_TO_CREATOR,
            getPortfolioSummaryMarker: (
              summary: PortfolioSummary
            ): React.Node => (summary.posts.length > 0 ? E_ICON : null),
            nested: {
              post: {
                path: ":postId",
                hidden: (): boolean => true,
                ElementType: PostScope,
                nested: {
                  view: {
                    path: "view",
                    ElementType: ViewPostPage,
                  },
                },
              },
            },
          },
          addPost: {
            path: "creator/add",
            prevPath: "..",
            exact: true,
            hidden: (): boolean => true,
            label: "routes.addPost.label",
            subtitle: "routes.addPost.subtitle",
            ElementType: AddPostPage,
          },
          post: {
            path: "creator/:postId",
            prevPath: "..",
            hidden: (): boolean => true,
            ElementType: PostScope,
            nested: {
              edit: {
                path: "edit",
                label: "routes.editPost.label",
                subtitle: "routes.editPost.subtitle",
                ElementType: EditPostPage,
              },
            },
          },
          calendar: {
            path: "calendar",
            prevPath: "../dashboard",
            Icon: CalendarTodayRounded,
            label: "routes.calendar.label",
            subtitle: "routes.calendar.subtitle",
            ElementType: CalendarPage,
            navigationEvent: eventTypes.NAVIGATED_TO_CALENDAR,
          },
          analytics: {
            path: "analytics",
            prevPath: "../dashboard",
            disabled: (user: ?User): boolean => !user?.limits.can_use_analytics,
            Icon: BarChartRounded,
            label: "routes.analytics.label",
            subtitle: "routes.analytics.subtitle",
            ElementType: AnalyticsPage,
            navigationEvent: eventTypes.NAVIGATED_TO_ANALYTICS,
          },
          hashtags: {
            path: "hashtags",
            prevPath: "../dashboard",
            disabled: (user: ?User): boolean =>
              !user?.limits.can_use_hashtag_manager,
            Icon: HashTagIcon,
            label: "routes.hashtags.label",
            subtitle: "routes.hashtags.subtitle",
            ElementType: HashtagGroupsPage,
            navigationEvent: eventTypes.NAVIGATED_TO_HASHTAG_MANAGER,
            nested: {
              add: {
                path: "add",
                label: "routes.addHashtagGroup.label",
                subtitle: "routes.addHashtagGroup.subtitle",
                ElementType: AddEditHashtagGroupPage,
              },
              hashtagGroup: {
                path: ":hashtagGroupId",
                ElementType: HashtagGroupScope,
                nested: {
                  edit: {
                    path: "edit",
                    label: "routes.editHashtagGroup.label",
                    subtitle: "routes.editHashtagGroup.subtitle",
                    ElementType: AddEditHashtagGroupPage,
                  },
                },
              },
            },
          },
          engagement: {
            path: "engagement",
            prevPath: "../dashboard",
            disabled: (user: ?User): boolean =>
              !user?.limits.can_use_comment_manager,
            Icon: FeedbackRounded,
            label: "routes.engagement.label",
            subtitle: "routes.engagement.subtitle",
            ElementType: EngagementHubPage,
            navigationEvent: eventTypes.NAVIGATED_TO_ENGAGEMENT_HUB,
            getPortfolioSummaryMarker: (
              summary: PortfolioSummary
            ): React.Node =>
              some(summary.accounts, summaryAccountHasUnreadEngagement)
                ? I_ICON
                : null,
            nested: {
              account: {
                path: ":accountId",
                ElementType: AccountScope,
                nested: {
                  conversations: {
                    path: "conversations",
                    label: "global.messages",
                    ElementType: EngagementHubConversations,
                    nested: {
                      conversation: {
                        path: ":conversationId",
                        ElementType: ConversationScope,
                        nested: {
                          messages: {
                            path: "messages",
                            ElementType: ConversationEngagementEditor,
                          },
                        },
                      },
                    },
                  },
                  posts: {
                    path: "posts",
                    label: "global.comments",
                    ElementType: EngagementHubPosts,
                    nested: {
                      post: {
                        path: ":postId",
                        ElementType: PostScope,
                        nested: {
                          comments: {
                            path: "comments",
                            ElementType: CommentEngagementEditor,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          addApprovalRequest: {
            path: "approvals/add",
            prevPath: "..",
            disabled: (user: ?User): boolean =>
              !user?.limits.can_use_post_approval,
            hidden: (): boolean => true,
            label: "routes.addApprovalRequest.label",
            subtitle: "routes.addApprovalRequest.subtitle",
            ElementType: AddEditapprovalRequestPage,
          },
          approvalRequest: {
            path: "approvals/:approvalRequestId",
            hidden: (): boolean => true,
            disabled: (user: ?User): boolean =>
              !user?.limits.can_use_post_approval,
            ElementType: ApprovalRequestScope,
            nested: {
              edit: {
                path: "edit",
                prevPath: "../..",
                disabled: (user: ?User): boolean =>
                  !user?.limits.can_use_post_approval,
                hidden: (): boolean => true,
                label: "routes.editApprovalRequest.label",
                subtitle: "routes.editApprovalRequest.subtitle",
                ElementType: AddEditapprovalRequestPage,
              },
            },
          },
          approvals: {
            path: "approvals",
            prevPath: "../dashboard",
            disabled: (user: ?User): boolean =>
              !user?.limits.can_use_post_approval,
            Icon: AssignmentTurnedInRounded,
            label: "routes.postApproval.label",
            subtitle: "routes.postApproval.subtitle",
            ElementType: ApprovalRequestsPage,
            navigationEvent: eventTypes.NAVIGATED_TO_POST_APPROVAL,
          },

          vizPlanner: {
            path: "viz-planner",
            prevPath: "../dashboard",
            disabled: (user: ?User): boolean =>
              !user?.limits.can_use_viz_planner,
            Icon: AppsRounded,
            label: "routes.vizPlanner.label",
            subtitle: "routes.vizPlanner.subtitle",
            ElementType: VizPlannerPage,
            navigationEvent: eventTypes.NAVIGATED_TO_VIZ_PLANNER,
          },
          settings: {
            path: "edit",
            prevPath: "../dashboard",
            Icon: SettingsRounded,
            label: "routes.editPortfolio.label",
            subtitle: "routes.editPortfolio.subtitle",
            ElementType: AddEditPortfolioPage,
          },
        },
      },
      planAndAddons: {
        // Odd one: For the user we want a path inside
        // subscription, but in terms of UX it needs the full page
        // space, not just a tab.
        path: "settings/subscription/manage",
        prevPath: "../..",
        label: "routes.planAndAddons.label",
        ElementType: PlanAndAddOnsPage,
        nested: {
          plan: {
            path: "plan",
            default: true,
            label: "global.plan",
            ElementType: ChangePlanTab,
          },
          addOns: {
            path: "addons",
            label: "global.addOns",
            ElementType: ManageAddOnsWidget,
          },
        },
      },
      settings: {
        path: "settings",
        prevPath: "../..",
        Icon: Settings,
        label: "routes.settings.label",
        subtitle: "routes.settings.subtitle",
        ElementType: SettingsPage,
        nested: {
          account: {
            path: "account",
            default: true,
            label: "routes.settingsAccount.label",
            ElementType: AccountTab,
          },
          subscription: {
            path: "subscription",
            label: "routes.settingsSubscription.label",
            ElementType: SubscriptionTab,
            hidden: (user: ?User): boolean => isIOS || !!user?.is_child,
            disabled: (user: ?User): boolean => isIOS || !!user?.is_child,
          },
          freezeAccount: {
            path: "freeze",
            label: "routes.freezeOrDelete.label",
            ElementType: FreezeOrDeleteTab,
            hidden: (user: ?User): boolean => isIOS || !!user?.is_child,
            disabled: (user: ?User): boolean => isIOS || !!user?.is_child,
          },
        },
      },
      feedback: {
        path: "contact",
        prevPath: "..",
        Icon: ThumbsUpDown,
        label: "routes.freeformFeedback.label",
        subtitle: "routes.freeformFeedback.subtitle",
        ElementType: FreeformFeedbackPage,
      },
      referral: {
        path: "referral",
        prevPath: "..",
        Icon: PersonAddRounded,
        label: "routes.referFriend.label",
        subtitle: "routes.referFriend.subtitle",
        ElementType: InviteAFriendPage,
      },
      metamorph: {
        path: "metamorph",
        prevPath: "..",
        disabled: (user: ?User): boolean => !user?.metamorph,
        hidden: (user: ?User): boolean => !user?.metamorph,
        Icon: AssignmentInd,
        label: "routes.metamorph.label",
        ElementType: MetamorphPage,
      },
    },
  },
  styleguide: {
    path: "styleguide",
    ElementType: StyleGuidePage,
    hidden: (): boolean => true,
    disabled: (): boolean => isProd(),
  },
  // help: {
  //   path: "help",
  //   Icon: HelpRounded,
  //   label: "routes.help.label",
  //   ElementType: "div",
  //   navigationEvent: eventTypes.NAVIGATED_TO_HELP,
  // },
  login: {
    path: "login",
    exact: true,
    ElementType: LoginPage,
  },
  resetPassword: {
    path: "login/reset/:resetToken",
    ElementType: PasswordResetPage,
  },
  addIntegration: {
    path: "integrations/:app",
    ElementType: IntegrationLoginPage,
  },
  approval: {
    path: "approval/:hashId",
    label: "routes.reviewApprovalRequest.label",
    subtitle: "routes.reviewApprovalRequest.subtitle",
    ElementType: ReviewApprovalRequestPage,
  },
};

type AbsPathRegistry = Record<string, string>;

const makeAbsRoutePath =
  (
    parentPath: string,
    parentRoute?: BrowserRoute
  ): ((AbsPathRegistry, BrowserRoute) => AbsPathRegistry) =>
  (register, route) => {
    const path = isEmpty(route.path)
      ? parentPath
      : `${parentPath}/${trim(route.path, "/") ?? ""}`;
    const routeId = (route.id = uniqueId());
    route.parent = parentRoute;
    register[route.id] = path;
    _ROUTES_REGISTRY[routeId] = route;
    values(route.nested ?? {}).reduce(makeAbsRoutePath(path, route), register);
    return register;
  };

const _ROUTES_REGISTRY: StringDict<BrowserRoute> = {};
const _ABS_PATHS_REGISTRY = values(routes).reduce(makeAbsRoutePath(""), {});

export const getRouteById = (id: string): BrowserRoute => _ROUTES_REGISTRY[id];

export const getRoutePath = (
  route: BrowserRoute,
  params?: StringDict<any> = {},
  queryArgs?: StringDict<string>
): string => {
  const baseURL = Object.entries(params).reduce(
    (res, [key, value]) => res.replace(`:${key}`, value),
    _ABS_PATHS_REGISTRY[route.id] ?? ""
  );

  return queryArgs ? makeURLPath(baseURL, queryArgs) : baseURL;
};

const recurGetRouteForLocation = (
  path: string,
  route: BrowserRoute
): ?BrowserRoute => {
  if (typeof route.path !== "string") return null;

  // consume the route.
  // a level is valid in case of an exact match or if it is a
  // parameter.
  // You can't consume the root route (/) so guard against that.
  if (route.path && route.path !== "/") {
    for (const level of route.path.split("/")) {
      const isLevelParam = level.startsWith(":");
      if (isLevelParam && path === "") {
        // a param is expected but there is nothing to consume.
        return null;
      }
      if (!path.startsWith(level) && !isLevelParam) {
        // No match.
        return null;
      }
      const nextSlash = path.indexOf("/");
      path = nextSlash >= 0 ? path.substring(nextSlash + 1) : "";
    }
  }

  // if after consumption there is nothing left we are good.
  if (path === "") {
    return route;
  }
  // Otherwise check nested routes.
  for (const subRoute of values(route.nested)) {
    const subResult = recurGetRouteForLocation(path, subRoute);
    if (subResult) return subResult;
  }
  return null;
};

export const getRouteForLocation = (path: string): ?BrowserRoute => {
  path = trim(path, "/");
  for (const route of values(routes)) {
    const match = recurGetRouteForLocation(path, route);
    if (match) return match;
  }
  return null;
};
/**
 * Return true is `parentRoute` is a direct or indirect parent route of `route`.
 */
export const isParentRouteOf = (
  parentRoute: BrowserRoute,
  route: ?BrowserRoute
): boolean =>
  !!route?.parent &&
  (route.parent === parentRoute || isParentRouteOf(parentRoute, route.parent));

export const isAscendingRouteOf = (
  parentRoute: BrowserRoute,
  route: ?BrowserRoute
): boolean => route === parentRoute || isParentRouteOf(parentRoute, route);

export const getBackToResourceListRelPath = (isEdit: boolean): string =>
  // either list/<resId>/edit or list/add
  isEdit ? "../.." : "..";

export const getNestedRoutes = (route: BrowserRoute): BrowserRoute[] =>
  values(route.nested) ?? [];

export const isRouteEnabled =
  (user: ?User): ((BrowserRoute) => boolean) =>
  (route) =>
    !user || !route.disabled || !route.disabled(user);
export const isRouteVisible =
  (user: ?User, accountsCount: number): ((BrowserRoute) => boolean) =>
  (route) =>
    (!route.hidden || !route.hidden(user)) &&
    // When no accounts are connected, users can only see the accounts
    // route to lead them to connecting an account.
    (accountsCount > 0 ||
      route === routes.app.nested.portfolios.nested.socialAccounts);

export const makeGetRouteAdornedLabel =
  (t: TFunction, summary: ?PortfolioSummary): ((BrowserRoute) => React.Node) =>
  (route) => {
    if (!route.getPortfolioSummaryMarker || !summary) return t(route.label);
    const marker = route.getPortfolioSummaryMarker(summary);
    return marker ? (
      <>
        {t(route.label)}
        {marker}
      </>
    ) : (
      t(route.label)
    );
  };

export default routes;
