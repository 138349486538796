// @flow
import * as React from "react";
import type { ModalAlert } from "../../models/alerts.model";
import useTemporaryNavigation from "../../hooks/useTemporaryNavigation";

export type GlobalActions = {
  openModalAlert: (ModalAlert) => void,
  onSetDrawerOpen: (boolean) => void,
  closeModalAlert: () => void,
  forceDisconnect: () => void,
};

export type GlobalState = {
  modalAlert: ?ModalAlert,
  disconnected: boolean,
  drawerOpen: boolean,
};

export const useGlobalReducer = (): [GlobalState, GlobalActions] => {
  const [modalAlert, setModalAlert] = React.useState<?ModalAlert>(null);
  const [disconnected, setDisconnected] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(!useTemporaryNavigation());

  const actions = React.useMemo(
    () => ({
      openModalAlert: setModalAlert,
      closeModalAlert: () => setModalAlert(null),
      forceDisconnect: () => setDisconnected(true),
      onSetDrawerOpen: setDrawerOpen,
    }),
    []
  );

  return [{ modalAlert, disconnected, drawerOpen }, actions];
};
