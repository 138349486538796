// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { SpaceBetweenRow } from "../../lib/layout/stacks";
import { Body2, Header } from "../../lib/display/Text";
import type {
  AnalyticsReport,
  EntityMetric,
} from "../../../models/analytics.model";
import {
  getDailyAverage,
  isEntityMetricDefined,
} from "../../../models/analytics.model";
import useTranslate from "../../../hooks/useTranslate";
import EntityMetricsGraph from "./EntityMetricsGraph";
import {
  getPageMetricOptionLabel,
  getPageMetricOptionValue,
  PAGE_METRICS_OPTIONS,
} from "../../../config/metrics.config";
import Select from "../../lib/inputs/Select";
import Numeral from "../../lib/display/Numeral";
import Translate from "../../lib/display/Translate";
import type { I18nKey } from "../../../types";
import { Box, Paper, Stack } from "@mui/material";
import useIsSmallOrSmaller from "../../../hooks/useIsSmallOrSmaller";

type Props = {
  report: AnalyticsReport,
  selectedMetric: EntityMetric,
  onChangeSelectedMetric: (EntityMetric) => any,
};

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(1),
  flexGrow: 1,
  flexShrink: 1,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0),
  },
}));

const EntitySummaryMetricRoot = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  alignItems: "center",
  justifyContent: "center",
  minWidth: 120,
}));

const EntitySummaryMetric = ({
  value,
  label,
}: {
  value: ?number,
  label: I18nKey,
}) => (
  <EntitySummaryMetricRoot>
    <Numeral variant="h4" display="compact" value={value} />
    <Body2 fontWeight="bold" color="textSecondary" align="center">
      <Translate id={label} />
    </Body2>
  </EntitySummaryMetricRoot>
);

const AccountAnalyticsWidget: React.ComponentType<Props> = ({
  report,
  selectedMetric,
  onChangeSelectedMetric,
}) => {
  const isSmallOrSmaller = useIsSmallOrSmaller();
  const t = useTranslate();
  const options = React.useMemo(
    () =>
      PAGE_METRICS_OPTIONS.filter((opt) =>
        isEntityMetricDefined(report.entity_overview, opt.value)
      ),
    [report.entity_overview]
  );
  return (
    <Root>
      <SpaceBetweenRow alignItems="center">
        <Header>{t("AnalyticsSection.AnalyticsTab.pageOverview")}</Header>
        {options.length > 0 && (
          <Select
            size="small"
            id="select-entity-metric"
            width={200}
            value={options.find((opt) => opt.value === selectedMetric) ?? ""}
            multiple={false}
            options={options}
            getOptionLabel={getPageMetricOptionLabel}
            getOptionValue={getPageMetricOptionValue}
            onChange={(opt) => opt && onChangeSelectedMetric(opt.value)}
            translateLabel
          />
        )}
      </SpaceBetweenRow>
      <Stack direction={isSmallOrSmaller ? "column" : "row"}>
        <Box height="40vh" flexGrow={1}>
          <EntityMetricsGraph report={report} metric={selectedMetric} />
        </Box>
        <Stack
          spacing={4}
          direction={isSmallOrSmaller ? "row" : "column"}
          justifyContent="center"
        >
          <EntitySummaryMetric
            value={getDailyAverage(
              report.current_entity_dailies,
              selectedMetric
            )}
            label="global.dailyAverage"
          />
          <EntitySummaryMetric
            value={report.entity_overview[selectedMetric]?.value}
            label="global.total"
          />
        </Stack>
      </Stack>
    </Root>
  );
};

export default AccountAnalyticsWidget;
