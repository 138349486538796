// @flow
import * as React from "react";
import { LargePrimaryButton } from "../../lib/inputs/buttons";
import {
  ColumnStack,
  ResponsiveInlineRowStack,
  RowGroup,
} from "../../lib/layout/stacks";
import useTranslate from "../../../hooks/useTranslate";
import { Collapse } from "@mui/material";
import PostFilterInputs from "../../inputs/PostFilterInputs";
import { usePortfolioAccounts } from "../../../store/selectors";
import type { Callback, ModelID } from "../../../types";
import PostFiltersContext from "../../../contexts/postFilters";
import type { PostStatus } from "../../../models/post.model";
import { ALL_STATUSES } from "../../../models/post.model";
import { styled } from "@mui/material/styles";
import DebouncedSearchInput from "../../lib/inputs/DebouncedSearchInput";

export type Props = {
  portfolioId?: ModelID,
  statusOptions?: $ReadOnlyArray<PostStatus>,
  onComposeClick: Callback,
  noCompose?: boolean,
  size?: "medium" | "small",
};

const ComposeButton = styled(LargePrimaryButton)(({ theme }) => ({
  minWidth: 160,
  [theme.breakpoints.down("sm")]: {
    minWidth: "initial",
  },
}));

const PostSearchBar: React.ComponentType<Props> = ({
  portfolioId,
  statusOptions = ALL_STATUSES,
  noCompose,
  size,
  onComposeClick,
}) => {
  const t = useTranslate();
  const accounts = usePortfolioAccounts(portfolioId);
  const [showFilters, setShowFilters] = React.useState(false);
  const { onSetSearch, activeFilters } = React.useContext(PostFiltersContext);
  return (
    <ColumnStack spacing={size === "small" ? 1 : 2}>
      <RowGroup>
        <DebouncedSearchInput
          fullWidth
          onToggleFilter={() => setShowFilters((prev) => !prev)}
          filterSelected={showFilters}
          onChange={onSetSearch}
          activeFilters={activeFilters}
          size={size}
        />
        {!noCompose && (
          <ComposeButton onClick={onComposeClick}>
            {t("global.compose")}
          </ComposeButton>
        )}
      </RowGroup>
      <Collapse in={showFilters} timeout={200} unmountOnExit>
        <ResponsiveInlineRowStack alignItems="center">
          <PostFilterInputs
            accounts={accounts}
            size="small"
            statusOptions={statusOptions}
          />
        </ResponsiveInlineRowStack>
      </Collapse>
    </ColumnStack>
  );
};

export default PostSearchBar;
