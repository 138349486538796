// @flow
import * as React from "react";

/**
 * Attach an event listener to the window, as a mounting effect.
 * The listener is removed when the holding component is unmounted.
 */
const useWindowEventListener = (event: string, listener: () => any) => {
  React.useEffect(() => {
    window.addEventListener(event, listener);
    return () => {
      window.removeEventListener(event, listener);
    };
  }, [event, listener]);
};

export default useWindowEventListener;
