// @flow
import * as React from "react";
import SearchInput from "./SearchInput";
import type { Props as SearchInputProps } from "./SearchInput";
import useDebouncedOnChange from "../../../hooks/useDebouncedOnChange";

type Props = {
  ...SearchInputProps,
  onChange: (value: string) => any,
};

const DebouncedSearchInput: React.ComponentType<Props> = ({
  onChange,
  ...props
}) => <SearchInput onChange={useDebouncedOnChange(onChange, 400)} {...props} />;

export default DebouncedSearchInput;
