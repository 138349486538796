// @flow
import * as React from "react";
import instagram from "../../../../models/channels/instagram.model";
import type {
  OAuthEntityDetails,
  OAuthTarget,
} from "../../../../services/channels/binding.service";
import type { Channel } from "../../../../models/channels/channel.model";
import type { Callback, Slug } from "../../../../types";
import Dialog from "../../../lib/feedback/Dialog";
import Translate from "../../../lib/display/Translate";
import {
  Alert,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Avatar from "../../../lib/display/Avatar";
import { Subheader } from "../../../lib/display/Text";
import { BusinessRounded, PersonRounded } from "@mui/icons-material";
import ChannelIcon from "../../../lib/display/icons/ChannelIcon";
import { RowStack } from "../../../lib/layout/stacks";
import NoResults from "../../../display/NoResults";
import useTranslate from "../../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";

type Props = {
  refresh?: boolean,
  channel: Channel,
  entities?: OAuthEntityDetails[],
  user: ?OAuthTarget,
  onSelect: (OAuthTarget) => any,
  onClose: Callback,
};

const SpacedList = styled(List)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

type EntityListItemProps = {
  entity: OAuthTarget,
  onClick: Callback,
  selected: boolean,
  Icon: React.ComponentType<{}>,
  channel: Slug,
};

const EntityListItem: React.ComponentType<EntityListItemProps> = ({
  entity,
  onClick,
  selected,
  Icon,
  channel,
}) => {
  const isNotLinkedToIGAccount =
    channel === instagram.slug && !entity.managed_entity_id;
  return (
    <ListItemButton
      onClick={onClick}
      selected={selected}
      disabled={isNotLinkedToIGAccount}
    >
      {entity.cover ? (
        <ListItemAvatar>
          <Avatar src={entity.cover} />
        </ListItemAvatar>
      ) : (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={entity.name}
        primaryTypographyProps={{ color: "textPrimary" }}
        secondaryTypographyProps={
          isNotLinkedToIGAccount ? { color: "error" } : undefined
        }
        secondary={
          isNotLinkedToIGAccount ? (
            <Translate id="DashboardSection.AccountCard.SelectEntityDialog.isNotLinkedToIGAccount" />
          ) : (
            entity.id
          )
        }
      />
    </ListItemButton>
  );
};

const SelectEntityDialog: React.ComponentType<Props> = ({
  refresh,
  channel,
  entities,
  onSelect,
  onClose,
  user,
}) => {
  entities = entities ?? [];
  const value = entities?.[0] ?? user;
  const t = useTranslate();
  return (
    <Dialog
      open
      name="select-entity"
      onClose={onClose}
      title={
        <RowStack>
          <ChannelIcon channel={channel.slug} color="channel" />
          {t(
            refresh
              ? "DashboardSection.AccountCard.SelectEntityDialog.refreshTitle"
              : "DashboardSection.AccountCard.SelectEntityDialog.title"
          )}
        </RowStack>
      }
      maxWidth="md"
    >
      {user && (
        <>
          <Subheader>
            {t(
              "DashboardSection.AccountCard.SelectEntityDialog.personalAccountTitle"
            )}
          </Subheader>
          <SpacedList>
            <EntityListItem
              channel={channel.slug}
              entity={user}
              Icon={PersonRounded}
              selected={value === user}
              onClick={() => onSelect(user)}
            />
          </SpacedList>
          <Subheader>
            {t(
              "DashboardSection.AccountCard.SelectEntityDialog.businessAccountsTitle"
            )}
          </Subheader>
        </>
      )}
      {entities.length > 0 ? (
        <Alert severity="info">
          <Translate id={`refer.channels.${channel.slug}.pickEntityMessage`} />
        </Alert>
      ) : (
        <NoResults />
      )}
      <List>
        {channel.features?.businessAccount &&
          entities.map((entity) => (
            <EntityListItem
              key={entity.id}
              channel={channel.slug}
              entity={entity}
              Icon={BusinessRounded}
              selected={value === entity}
              onClick={() => onSelect(entity)}
            />
          ))}
      </List>
    </Dialog>
  );
};

export default SelectEntityDialog;
