// @flow
import type { Portfolio } from "../models/portfolio.model";
import { API as backend } from "./backend.service";
import * as snacks from "../models/alerts.model";
import type { AsyncAction, AsyncVoidAction } from "./types";
import type { ModelID, Transient } from "../types";

export const addPortfolio: AsyncAction<
  Promise<Portfolio>,
  Omit<Transient<Portfolio>, "display_order" | "public_hash" | "initials">
> = (actions) => (portfolio) =>
  backend.portfolio.addPortfolio(portfolio).then((resultPortfolio) => {
    actions.portfolio.append(resultPortfolio);
    actions.snacks.append(
      snacks.localSuccess({ message: "store.portfolio.created" })
    );
    return resultPortfolio;
  });

export const editPortfolio: AsyncAction<Promise<void>, Partial<Portfolio>> =
  (actions) => (portfolio) => {
    actions.portfolio.lock();
    return backend.portfolio
      .editPortfolio(portfolio)
      .then(actions.portfolio.replace)
      .then(() => {
        actions.snacks.append(
          snacks.localSuccess({ message: "store.portfolio.updated" })
        );
      })
      .finally(actions.portfolio.unlock);
  };

export const getPortfolios: AsyncVoidAction<void> = (actions) => () => {
  actions.portfolio.lock();
  return backend.portfolio
    .getPortfolios()
    .then(actions.portfolio.replaceAll)
    .finally(actions.portfolio.unlock);
};

export const getPortfolioSummaries: AsyncVoidAction<void> = (actions) => () => {
  return backend.portfolio
    .getPortfolioSummaries()
    .then(actions.portfolio.summaries.replaceAll);
};

export const deletePortfolio: AsyncAction<Promise<void>, ModelID> =
  (actions) => (portfolioId) =>
    backend.portfolio.deletePortfolio(portfolioId).then(() => {
      actions.portfolio.remove(portfolioId);
      actions.snacks.append(
        snacks.localSuccess({ message: "store.portfolio.deleted" })
      );
    });
