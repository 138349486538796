// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Backdrop, Collapse, Paper, Portal } from "@mui/material";
import { Body1 } from "../lib/display/Text";
import type { Callback, I18nKey } from "../../types";
import useTranslate from "../../hooks/useTranslate";
import { InlineRowStack } from "../lib/layout/stacks";
import { SmallNeutralButton, SmallPrimaryButton } from "../lib/inputs/buttons";
import type { Children } from "../../reactTypes";
import useSwitch from "../../hooks/useSwitch";
import PleaseWaitProgress from "../feedback/PleaseWaitProgress";

export type Props = {
  headless?: boolean,
  title?: I18nKey,
  editing?: boolean,
  onBeginEdit?: Callback,
  onCompleteEdit?: Callback,
  onCancelEdit?: Callback,
  children?: Children,
  disabled?: boolean,
  collapsed?: boolean,
  loading?: boolean,
  actionId?: I18nKey,
  submitId?: I18nKey,
  Component?: React.ElementType,
};

export const SettingsSectionRoot: typeof Paper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "editing",
})(({ theme, editing }) => ({
  padding: theme.spacing(2),
  overflow: "hidden",
  position: "relative",
  zIndex: editing ? theme.zIndex.drawer + 2 : undefined,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    borderRadius: 0,
  },
}));

const SectionHeadRoot = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 8,
  marginBottom: theme.spacing(1),
}));

const FocusBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  zIndex: theme.zIndex.drawer + 1,
  // Important! Otherwise you won't be able to scroll if the
  // cursor is outside the section.
  pointerEvents: "none",
}));

type SectionHeadProps = {
  title: React.Node,
  children?: Children,
};

export const SettingsSectionHead: React.ComponentType<SectionHeadProps> = ({
  title,
  children,
}) => (
  <SectionHeadRoot>
    <Body1 fontWeight="bold">{title}</Body1>
    <InlineRowStack>{children}</InlineRowStack>
  </SectionHeadRoot>
);

const SettingsSection: React.ComponentType<Props> = ({
  headless,
  title,
  editing = false,
  onBeginEdit,
  onCancelEdit,
  onCompleteEdit,
  children,
  disabled,
  collapsed,
  loading,
  actionId,
  submitId,
  Component = "form",
}) => {
  const t = useTranslate();
  const [locked, setLocked, setUnlocked] = useSwitch(false);
  const handleSubmit = React.useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (onCompleteEdit) {
        setLocked();
        // Use Promise.resolve to ensure a Promise result.
        Promise.resolve(onCompleteEdit()).finally(setUnlocked);
      }
    },
    [onCompleteEdit, setLocked, setUnlocked]
  );

  return (
    <>
      <Portal>
        <FocusBackdrop open={editing} />
      </Portal>
      <SettingsSectionRoot
        component={Component}
        onSubmit={handleSubmit}
        editing={editing}
      >
        {!headless && (
          <SettingsSectionHead title={t(title)}>
            {onCancelEdit && editing && (
              <SmallNeutralButton onClick={onCancelEdit} loading={locked}>
                {t("global.cancel")}
              </SmallNeutralButton>
            )}
            {onCompleteEdit && editing && (
              <SmallPrimaryButton
                type="submit"
                disabled={disabled}
                loading={locked}
              >
                {t(submitId ?? "global.save")}
              </SmallPrimaryButton>
            )}
            {onBeginEdit && !editing && (
              <SmallNeutralButton onClick={onBeginEdit}>
                {t(actionId ?? "global.update")}
              </SmallNeutralButton>
            )}
          </SettingsSectionHead>
        )}
        <Collapse in={!collapsed} unmountOnExit>
          <div>{loading ? <PleaseWaitProgress /> : children}</div>
        </Collapse>
      </SettingsSectionRoot>
    </>
  );
};

export default SettingsSection;
