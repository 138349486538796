// @flow
import * as React from "react";
import routes, {
  getRouteById,
  getRoutePath,
  isRouteVisible,
} from "../../../config/routes.config";
import {
  NO_XS_HORIZ_MARGIN,
  PrimaryPagePanel,
} from "../../../components/layout/PagePanel";
import { Alert, Stack, Tab, Tabs } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import values from "lodash/values";
import { Body1 } from "../../../components/lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import { StickToTheTop } from "../../../components/lib/layout/placement";
import useCurrentRoute from "../../../hooks/useCurrentRoute";
import ThemeProvider from "../../../contexts/theme";
import useIsMobile from "../../../hooks/useIsMobile";
import { isIOS } from "../../../lib/platform.lib";
import { useCurrentUser } from "../../../store/selectors";

const ContentContainer = styled("div")({
  maxWidth: 1000,
  flexGrow: 1,
});

/**
 * Override all inputs to show as regular text when they are readonly.
 */
const localThemeComponents = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        "&.Mui-readOnly": {
          backgroundColor: "transparent !important",
          ".MuiAutocomplete-popupIndicator": {
            visibility: "hidden",
          },
        },
      },
    },
  },
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const SettingsPage: React.ComponentType<empty> = () => {
  const t = useTranslate();
  const tabsRoute = routes.app.nested.settings;
  const currentRoute = useCurrentRoute();
  const onNavigate = useNavigate();
  const value = currentRoute === tabsRoute ? false : currentRoute?.id ?? false;
  const isMobile = useIsMobile();
  const user = useCurrentUser();

  return (
    // Override the current theme with local settings for inputs.
    <ThemeProvider components={localThemeComponents}>
      <PrimaryPagePanel size="fill" route={tabsRoute}>
        <Stack
          alignItems={isMobile ? "stretch" : "flex-start"}
          direction={isMobile ? "column" : "row"}
          className={NO_XS_HORIZ_MARGIN}
        >
          <StickToTheTop minWidth={160}>
            <Tabs
              orientation={isMobile ? "horizontal" : "vertical"}
              value={value}
              onChange={(e, routeId: string) =>
                onNavigate(getRoutePath(getRouteById(routeId)))
              }
              indicatorColor="transparent"
              variant="scrollable"
            >
              {values(tabsRoute.nested)
                .filter(isRouteVisible(user, 1))
                .map((route) => (
                  <Tab
                    key={route.id}
                    value={route.id}
                    label={<Body1 fontWeight="bold">{t(route.label)}</Body1>}
                  />
                ))}
            </Tabs>
          </StickToTheTop>
          <ContentContainer>
            <Outlet />
          </ContentContainer>
        </Stack>
        {isIOS && (
          <StyledAlert severity="info">
            {t("Page.SettingsSection.moreOptionsOnTheWebApp")}
          </StyledAlert>
        )}
      </PrimaryPagePanel>
    </ThemeProvider>
  );
};

export default SettingsPage;
