// @flow
import * as React from "react";
import useSwitch from "../../../hooks/useSwitch";
import { useRemainingAccountCapacity } from "../../../store/selectors";
import PlanUpgradeInviteDialog from "../PlanUpgradeInviteDialog";

const LimitExceededActionRequiredDialogContainer: React.ComponentType<{}> =
  () => {
    const [forceClose, setForceClose] = useSwitch();
    const [remainingAccountCapacity, accountLimit] =
      useRemainingAccountCapacity();
    const open = !forceClose && remainingAccountCapacity < 0;

    return (
      <PlanUpgradeInviteDialog
        open={open}
        onClose={setForceClose}
        title={"LimitExceededActionRequiredDialog.title"}
        message={"UpgradeRequiredDialog.upgradeRequired"}
        messageData={{ accountLimit }}
        cancelLabel={"global.close"}
        acceptLabel={"LimitExceededActionRequiredDialog.upgradeNow"}
      />
    );
  };

export default LimitExceededActionRequiredDialogContainer;
