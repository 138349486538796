// @flow
import * as React from "react";
import useSwitch from "../../../hooks/useSwitch";
import type { URLStr } from "../../../types";
import type { LinkPreview as APILinkPreview } from "../../../services/backend.service/misc";
import * as misc from "../../../services/backend.service/misc";
import memoize from "lodash/memoize";

export type Preview = {
  title?: ?string,
  image?: ?URLStr,
};

type ReturnValue = [Preview, (url: string) => void, boolean];

const requestPreview: (URLStr) => Promise<APILinkPreview> = memoize(
  misc.previewLink
);

const useLinkPreview = (controlled?: ?Preview): ReturnValue => {
  const [locked, lock, unlock] = useSwitch();
  const [preview, setPreview] = React.useState<Preview>({});

  const refreshPreview = React.useCallback(
    (url: string) => {
      lock();
      requestPreview(url)
        .then((preview) => {
          setPreview((prev) => ({
            title: preview.title || prev.title,
            image: preview.image || prev.image,
          }));
        })
        .finally(unlock);
    },
    [lock, unlock]
  );
  const effectivePreview = {
    title: controlled?.title ?? preview.title,
    image: controlled?.image ?? preview.image,
  };
  return [effectivePreview, refreshPreview, locked];
};

export default useLinkPreview;
