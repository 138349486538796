// @flow
import * as React from "react";
import { RowStack } from "../../lib/layout/stacks";
import { Box } from "@mui/material";
import Translate from "../../lib/display/Translate";
import { Body1, Caption } from "../../lib/display/Text";
import { styled } from "@mui/material/styles";
import StripePaymentInput from "../../lib/inputs/StripePaymentInput";
import Section from "../../layout/Section";
import { useGuardedElements } from "../../lib/display/stripe/useGuardedElements";
import PleaseWaitProgress from "../../feedback/PleaseWaitProgress";
import Stripe from "../../lib/display/icons/Stripe";

type Props = {
  isAdd?: boolean,
};

const SafetyBlock = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const PaymentDetailsWidget: React.ComponentType<Props> = ({ isAdd }) => {
  // Check readiness.
  if (!useGuardedElements()) return <PleaseWaitProgress />;

  return (
    <Section
      title={
        isAdd
          ? "Page.SettingsSection.PaymentDetailsTab.addPaymentDetails"
          : "Page.SettingsSection.PaymentDetailsTab.changePaymentDetails"
      }
    >
      {isAdd && (
        <Box marginTop={2}>
          <Body1 fontWeight="bold">
            <Translate id="Page.SubscriptionPanel.noChargeDuringTrial" />
          </Body1>
        </Box>
      )}
      <StripePaymentInput />
      <SafetyBlock>
        <RowStack alignItems="center">
          <Stripe />
          <Body1 fontWeight="bold">
            <Translate id="Page.SettingsSection.PaymentDetailsTab.safetyAlertTitle" />
          </Body1>
        </RowStack>
        <Caption color="textSecondary">
          <Translate id="Page.SettingsSection.PaymentDetailsTab.safetyAlertBody" />
        </Caption>
      </SafetyBlock>
    </Section>
  );
};

export default PaymentDetailsWidget;
