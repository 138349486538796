// @flow
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import transform from "lodash/transform";
import without from "lodash/without";
import words from "lodash/words";
import type { FindIteratee } from "../types";

/**
 * Returns an object which contains all attributes of "updates" which are missing or different from
 * the base object. Beware: The check is shallow.
 * @param base The base to compare.
 * @param updates The updates to compare.
 * @return An object with only the differences (and the adds) in updates object.
 */
export const difference = <T: Object>(
  base: T,
  updates: Partial<T>
): Partial<T> =>
  transform(updates, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      if (isObject(value) && isObject(base[key])) {
        result[key] = difference(base[key], value);
      } else {
        result[key] = value;
      }
    }
  });

/**
 * @param array The array to work on. It will not be modified.
 * @param index An integer that specifies at what position to add/remove items,
 * Use negative values to specify the position from the end of the array
 * @param [howmany]  Optional. The number of items to be removed. If set to 0,
 * no items will be removed
 * @param items Optional. The new item(s) to be added to the array
 * @return The new array.
 */
export const splice = <T>(
  array: $ReadOnlyArray<T>,
  index: number,
  howmany: number = 0,
  items: $ReadOnlyArray<T> = []
): T[] => [...array.slice(0, index), ...items, ...array.slice(index + howmany)];

/**
 * Replace an item in an array, using the specified iteratee.
 *
 * @param array The array to work on. It will not be modified.
 * @param findBy The iteratee to find the item to replace.
 * @param item The item to insert.
 * @param appendIfMissing What to do if the item to replace is missing.
 *                       `true` will append the item. `false` will discard it.
 * @return The new array.
 */
export const replaceItem = <T>(
  array: T[],
  findBy: FindIteratee<T>,
  item: T,
  appendIfMissing: boolean = false
): T[] => {
  const index = array.findIndex(findBy);
  if (index >= 0) return splice(array, index, 1, [item]);
  if (appendIfMissing) return [...array, item];
  return array;
};

/**
 * Applies updates to an item in an array, using the specified iteratee.
 *
 * @param array The array to work on. It will not be modified.
 * @param findBy The iteratee to find the item to patch.
 * @param updates The updates to apply.
 * @return The new array.
 */
export const patchItem = <T>(
  array: T[],
  findBy: FindIteratee<T>,
  updates: Partial<T>
): T[] => {
  const index = array.findIndex(findBy);
  return index >= 0
    ? splice(array, index, 1, [{ ...array[index], ...updates }])
    : array;
};

/**
 * Remove an item in an array, using the specified iteratee.
 * This method return the original array if the specified item
 * could not be found.
 *
 * @param array The array to work on. It will not be modified.
 * @param findBy The iteratee to find the item to remove.
 * @return The new array.
 */
export const removeItemBy = <T>(array: T[], findBy: FindIteratee<T>): T[] => {
  const index = array.findIndex(findBy);
  if (index >= 0) return splice(array, index, 1);
  return array;
};

/**
 * Returns an array whose values are the same as the original array but where missing
 * indexes have been filled with provided value.
 */
export const fillGaps = <T>(array: T[], minLength: number, value: T): T[] =>
  array.length >= minLength
    ? array
    : array.concat(
        new Array<T>(minLength - array.length).fill(
          value,
          0,
          minLength - array.length
        )
      );

export const bound = (min: number, max: number, val: number): number =>
  val > max ? max : val < min ? min : val;

/**
 * Appends or removes the provided item based on its existence in the array.
 */
export const appendOrRemove = <T>(array: $ReadOnlyArray<T>, item: T): T[] =>
  array.includes(item) ? without(array, item) : [...array, item];

/**
 * Polyfill for the modulo.
 */
export const mod = (a: number, n: number): number => ((a % n) + n) % n;

/**
 * Returns a new array where the specified item as been moved to a new index.
 * @param iterable the iterable to work on.
 * @param removeAt The index at which to remove the item.
 * @param insertAt The index at which to insert it back.
 */
export const reorderItem = <T>(
  iterable: Iterable<T>,
  removeAt: number,
  insertAt: number
): T[] => {
  const result = Array.from(iterable);
  const [removed] = result.splice(removeAt, 1);
  return splice(result, insertAt, 0, [removed]);
};

/**
 * Return the initials of each word of the sentence.
 * @param sentence
 * @return
 */
export const initials = (sentence: string): string[] =>
  words(sentence).map((word) => word[0].toUpperCase());

/**
 * Return an array, optionally wrapping the provided argument if it is not
 * an array already.
 * @param itemOrArray
 */
export const ensureArray = <T>(itemOrArray: T | T[]): T[] =>
  // $FlowIgnore
  Array.isArray(itemOrArray) ? itemOrArray : [itemOrArray];

/**
 * Creates an array excluding a single occurence of the provided value.
 * @param array The array to filter.
 * @param val The value to exclude.
 * @return Returns the new array of filtered values
 */
export const removeItem = <T>(array: $ReadOnlyArray<T>, val: T): T[] =>
  splice(array, array.indexOf(val), 1);

/**
 * Creates an array excluding a single occurence of the provided value.
 * @param array The array to filter.
 * @param index The index of the value to exclude.
 * @return Returns the new array of filtered values
 */
export const withoutIndex = <T>(array: $ReadOnlyArray<T>, index: number): T[] =>
  splice(array, index, 1);

export const divmod = (x: number, y: number): [number, number] => [
  Math.floor(x / y),
  mod(x, y),
];

/**
 * Create a functor that returns the specified attribute when called with
 * an object.
 * @param attr
 */
export const getAttr =
  <T: Object>(attr: $Keys<T>): ((T) => $Values<T>) =>
  (obj) =>
    obj[attr];

export const findOne = <T>(
  iterable: $ReadOnlyArray<T>,
  findBy: FindIteratee<T>
): T => {
  const found = iterable.find(findBy);
  if (found) return found;
  throw Error("Found nothing but expected to find at least one.");
};

/**
 * Returns a function that prepends specified string to a value.
 * @param s {string}
 */
export const prepend =
  (s: string): ((string) => string) =>
  (v) =>
    s + v.toString();
/**
 * Returns a function that appends specified string to a value.
 * @param s {string}
 */
export const append =
  (s: string): ((string) => string) =>
  (v) =>
    v.toString() + s;

export const capitalise = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const pageOf = <T>(array: T[], page: number, pagesize: number): T[] =>
  array.slice((page - 1) * pagesize, page * pagesize);
