// @flow
import * as React from "react";
import { API as backend } from "../../services/backend.service";
import LoginWidget from "../../components/widgets/LoginWidget";
import { localError } from "../../models/alerts.model";
import useAjaxErrorInterceptor from "../../hooks/useAjaxInterceptor";
import { useStoreActions } from "../../store/store";
import { checkCreatePassword } from "../../util/validation.util";
import { useParams } from "react-router-dom";
import { Center } from "../../components/lib/layout/placement";
import useTranslate from "../../hooks/useTranslate";

const PasswordResetPageContainer: React.ComponentType<{}> = () => {
  useAjaxErrorInterceptor();
  const { resetToken } = useParams<{ resetToken: string }>();
  const t = useTranslate();
  const storeActions = useStoreActions();

  const handleConfirm = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      storeActions.snacks.append(
        localError({ message: "ResetPasswordPage.passwordMismatch" })
      );
      return Promise.reject();
    }
    return backend.auth.resetPassword({ password, reset_token: resetToken });
  };

  return (
    <Center>
      <LoginWidget
        confirmMessageId="global.reset"
        title={t("ResetPasswordPage.title")}
        onValidCredentials={handleConfirm}
        prompt={t("ResetPasswordPage.subtitle")}
        usernameType="password"
        checkPassword={checkCreatePassword}
        checkUsername={checkCreatePassword}
        noSignupInvite
      />
    </Center>
  );
};

export default PasswordResetPageContainer;
