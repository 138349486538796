// @flow
import { MBYTE } from "../../lib/files.lib";
import type { Channel } from "./channel.model";
import {
  login,
  wasLoginCancelled,
} from "../../services/channels/linkedin.service";

const linkedin: Channel = {
  name: "Linkedin",
  slug: "linkedin",
  color: "#047BB5",

  features: {
    businessAccount: true,
    personnalAccount: true,
    analytics: {
      personal: false,
      post: true,
    },
    liveEdit: {
      textOnly: true,
    },
    commentManagement: {
      likes: true,
    },
    content: {
      thumbnail: {
        link: true,
        document: true,
      },
    },
  },

  requires: {
    extractLink: true,
  },

  contentSpecs: {
    maxChars: 3000,
    media: {
      image: {
        mimetypes: ["image/png", "image/jpeg", "image/gif"],
        maxSize: 8 * MBYTE,
        maxCount: 9,
      },
      gif: {
        maxCount: 9,
        maxSize: 8 * MBYTE,
      },
      document: {
        mimetypes: ["application/pdf"],
        maxCount: 1,
      },
      video: {
        mimetypes: [
          "video/quicktime",
          "video/mp4",
          "video/mov",
          "video/x-ms-asf",
          "video/x-msvideo" /* avi */,
          "video/x-flv",
          "video/mpeg" /* MPEG-1, MPEG-4*/,
          "video/x-matroska" /* MKV */,
          "video/webm",
          "video/H264",
          "video/x-ms-wmv",
        ],
        maxSize: 200 * MBYTE,
        maxCount: 1,
      },
      overall: {
        maxCount: 9,
        oneMediaTypeOnly: true,
      },
    },
  },

  surfaces: [],
  subchannelTypes: [],
  wasLoginCancelled,
  login,
};

export default linkedin;
