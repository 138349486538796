// @flow
import { API as backend } from "./backend.service";
import * as snacks from "../models/alerts.model";
import type { AsyncVoidAction } from "./types";
import type { ModelID } from "../types";
import { Moment } from "../types";
import type { ExportFormat } from "./backend.service/analytics";
import noop from "lodash/noop";

type GetAccountAnalyticsParams = {
  portfolioId: ModelID,
  accountId: ModelID,
  start: Moment,
  end: Moment,
};

type GetPortfolioAnalyticsParams = {
  portfolioId: ModelID,
  start: Moment,
  end: Moment,
};

type ExportAnalyticsParams = {
  ...GetPortfolioAnalyticsParams,
  accountIds: ModelID[],
  format: ExportFormat,
  onSuccess?: () => any,
};

export const getAnalytics: AsyncVoidAction<GetAccountAnalyticsParams> =
  (actions) => (params) => {
    actions.analytics.lock();
    backend.analytics
      .getAnalytics(
        params.portfolioId,
        params.accountId,
        params.start,
        params.end
      )
      .then(actions.analytics.addReplace)
      .finally(actions.analytics.unlock);
  };

export const exportAnalytics: AsyncVoidAction<ExportAnalyticsParams> =
  (actions) => (params) => {
    actions.analytics.lock();
    backend.analytics
      .downloadAnalytics(
        params.portfolioId,
        params.accountIds,
        params.start,
        params.end,
        params.format
      )
      .then(params.onSuccess ?? noop)
      .catch((error) => actions.snacks.append(snacks.error(error)))
      .finally(actions.analytics.unlock);
  };
