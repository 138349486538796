// @flow
import * as React from "react";
import type { State } from "../reactTypes";

/**
 * Same as useState, but state is persisted in localStorage.
 * @template T
 * @param {string} key  The key to save the state.
 * @param {T} [initial]  Optional initial value for the state, if nothing is persisted yet.
 * @return {[T, function(T): void]}
 */
const usePersistentState = <T>(key: string, initial: T): State<T> => {
  const stored = localStorage.getItem(key);
  const [value, setValue] = React.useState<T>(
    stored ? JSON.parse(stored) : initial
  );
  const onSetValue = React.useCallback(
    (val: T) => {
      setValue(val);
      localStorage.setItem(key, JSON.stringify(val) ?? "");
    },
    [key]
  );

  return [value, onSetValue];
};

export default usePersistentState;
