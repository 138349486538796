// @flow
import * as React from "react";
import type { PasswordTabFormDef } from "./PasswordSection";
import PasswordSection from "./PasswordSection";
import { useCurrentUser } from "../../../../store/selectors";
import { FormActions, useForm } from "../../../../hooks/useForm";
import { difference } from "../../../../lib/lodashex.lib";
import * as profileService from "../../../../services/profile.service";
import useSwitch from "../../../../hooks/useSwitch";
import { useStoreActions } from "../../../../store/store";

const FORM_FIELDS: PasswordTabFormDef = {
  current_password: {
    type: "string",
  },
  new_password: {
    type: "string",
  },
};

const FORM_INITIAL = {
  current_password: "",
  new_password: "",
};

const PasswordSectionContainer: React.ComponentType<{}> = () => {
  const user = useCurrentUser();
  const actions = useStoreActions();
  const [editing, onBeginEdit, onEndEdit] = useSwitch(false);
  const form = useForm(FORM_FIELDS, FORM_INITIAL);

  const handleCancel = () => {
    onEndEdit();
    FormActions.reset(form.set, FORM_INITIAL);
  };

  const handleConfirm = () => {
    if (user && FormActions.validate(form)) {
      const updates = { ...FormActions.collect(form.state) };
      return profileService
        .updateCurrentUser(actions)(user, difference(FORM_INITIAL, updates))
        .then(onEndEdit);
    }
  };
  return (
    <PasswordSection
      editing={editing}
      onBeginEdit={onBeginEdit}
      onCancelEdit={handleCancel}
      onCompleteEdit={handleConfirm}
      form={form}
    />
  );
};

export default PasswordSectionContainer;
