// @flow
import * as React from "react";
import type { Moment } from "../../../types";

type Props = {
  value: ?Moment,
  fmt?: string,
};

const LocaleDate: React.ComponentType<Props> = ({ value, fmt = "l" }) =>
  value?.format(fmt) ?? "";

export default LocaleDate;
