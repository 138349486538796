// @flow
import * as React from "react";
import PleaseWaitProgress from "../../../../feedback/PleaseWaitProgress";
import {
  Alert,
  Checkbox,
  Collapse,
  FormControlLabel,
  Link,
  Stack,
} from "@mui/material";
import Avatar from "../../../../lib/display/Avatar";
import StdListItem from "../../../../lib/display/listItems";
import useTranslate from "../../../../../hooks/useTranslate";
import Select from "../../../../lib/inputs/Select";
import type {
  CreatorInfoError,
  MixedCreatorInfo,
  TiktokExtras,
} from "../../../../../models/channels/tiktok.model";
import {
  ColumnStack,
  ResponsiveInlineRowStack,
} from "../../../../lib/layout/stacks";
import SwitchField from "../../../../lib/inputs/SwitchField";
import Section from "../../../../layout/Section";
import { Body2 } from "../../../../lib/display/Text";
import useIsMobile from "../../../../../hooks/useIsMobile";
import without from "lodash/without";
import Translate from "../../../../lib/display/Translate";
import type { TFunction } from "../../../../../hooks/useTranslate";

type Props = {
  creatorInfo: ?MixedCreatorInfo,
  error?: boolean,
  extras: TiktokExtras,
  onChangeExtras: (TiktokExtras) => any,
  variant: "video" | "images",
};

const TiktokCreatorInfoErrorAlert = ({
  error,
}: {
  error: CreatorInfoError,
}) => {
  const t = useTranslate();
  return (
    <Alert severity="warning">
      {t(`refer.channels.tiktok.errors.${error.code}`)}
    </Alert>
  );
};

const CONTROL_WIDTH = {
  sx: undefined, // full width as per container,
  sm: 350,
};

type VideoContentDisclosureAlertProps = {
  extras: Props["extras"],
  t: TFunction,
};

const VideoContentDisclosureAlert = ({
  extras,
  t,
}: VideoContentDisclosureAlertProps) => {
  let msg = "";
  if (!extras.brand_content_toggle && extras.brand_organic_toggle) {
    msg = "TiktokContentEditor.promotionalContentLabel";
  }
  if (extras.brand_content_toggle) {
    msg = "TiktokContentEditor.paidPartnershipLabel";
  }
  return (
    <Collapse in={extras.brand_content_toggle || extras.brand_organic_toggle}>
      <Alert severity="info">{t(msg)}</Alert>
    </Collapse>
  );
};

const AgreementConfirmation = ({ extras }: Pick<Props, "extras">) => {
  const t = useTranslate();
  return (
    <Body2 color="textSecondary">
      <Translate
        t={t}
        id={
          extras.brand_content_toggle
            ? "refer.channels.tiktok.agreement.brandedContent"
            : "refer.channels.tiktok.agreement.regular"
        }
        components={{
          l1: (
            <Link
              href="https://www.tiktok.com/legal/page/global/bc-policy/en"
              target="_blank"
            />
          ),
          l2: (
            <Link
              href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
              target="_blank"
            />
          ),
        }}
      />
    </Body2>
  );
};

const TiktokContentEditor: React.ComponentType<Props> = ({
  creatorInfo,
  error,
  extras,
  onChangeExtras,
  variant,
}) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  if (error)
    return (
      <Alert severity="error">
        <Translate t={t} id="TiktokContentEditor.errorFetchingCreatorInfo" />
      </Alert>
    );
  if (!creatorInfo) return <PleaseWaitProgress />;
  if (creatorInfo.error)
    return <TiktokCreatorInfoErrorAlert error={creatorInfo} />;
  return (
    <ColumnStack spacing={3}>
      <Stack direction={isMobile ? "column" : "row"}>
        <StdListItem
          avatar={<Avatar src={creatorInfo.creator_avatar_url} />}
          primary={creatorInfo.creator_nickname}
          secondary={t("TiktokContentEditor.targetAccountSecondary")}
        />
        <Select
          id="select-privacy-level"
          multiple={false}
          width={CONTROL_WIDTH}
          translateLabel
          value={extras.privacy_level ?? ""}
          label={t("TiktokContentEditor.whoCanViewThis")}
          onChange={(privacy_level) =>
            onChangeExtras({ ...extras, privacy_level })
          }
          getOptionLabel={(v) => t(`refer.channels.tiktok.privacyLevel.${v}`)}
          options={
            extras.brand_content_toggle
              ? without(creatorInfo.privacy_level_options, "SELF_ONLY")
              : creatorInfo.privacy_level_options
          }
        />
      </Stack>
      <Section
        title="TiktokContentEditor.allowUsersToSectionTitle"
        titleVariant="body1"
      >
        <ResponsiveInlineRowStack spacing={4}>
          <FormControlLabel
            control={<Checkbox />}
            disabled={creatorInfo.comment_disabled}
            label={t("TiktokContentEditor.comment")}
            checked={!extras.disable_comment}
            onChange={(e, checked) =>
              onChangeExtras({ ...extras, disable_comment: !checked })
            }
          />
          {variant === "images" && (
            <FormControlLabel
              control={<Checkbox />}
              label={t("TiktokContentEditor.autoAddMusic")}
              checked={!!extras.auto_add_music}
              onChange={(e, checked) =>
                onChangeExtras({ ...extras, auto_add_music: checked })
              }
            />
          )}
          {variant === "video" && (
            <>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!!creatorInfo.duet_disabled}
                label={t("TiktokContentEditor.duet")}
                checked={!extras.disable_duet}
                onChange={(e, checked) =>
                  onChangeExtras({ ...extras, disable_duet: !checked })
                }
              />
              <FormControlLabel
                control={<Checkbox />}
                disabled={!!creatorInfo.stitch_disabled}
                label={t("TiktokContentEditor.stitch")}
                checked={!extras.disable_stitch}
                onChange={(e, checked) =>
                  onChangeExtras({ ...extras, disable_stitch: !checked })
                }
              />
            </>
          )}
        </ResponsiveInlineRowStack>
      </Section>
      <div>
        <SwitchField
          slotProps={{ typography: { fontWeight: "bold" } }}
          label={t("TiktokContentEditor.disclosePublicationContent")}
          checked={!!extras.commercialContent}
          onChange={(e, checked) =>
            onChangeExtras({
              ...extras,
              commercialContent: checked,
              brand_organic_toggle: false,
              brand_content_toggle: false,
            })
          }
        >
          <ColumnStack spacing={2}>
            <VideoContentDisclosureAlert extras={extras} t={t} />
            <div>
              <FormControlLabel
                control={<Checkbox />}
                checked={!!extras.brand_organic_toggle}
                label={t("TiktokContentEditor.yourBrand")}
                onChange={(e, checked) =>
                  onChangeExtras({ ...extras, brand_organic_toggle: checked })
                }
              />
              <Body2 color="textSecondary" sx={{ marginLeft: 4 }}>
                {t("TiktokContentEditor.yourBrandDescription")}
              </Body2>
            </div>
            <div>
              <FormControlLabel
                control={<Checkbox />}
                checked={!!extras.brand_content_toggle}
                label={t("TiktokContentEditor.brandedContent")}
                onChange={(e, checked) =>
                  onChangeExtras({
                    ...extras,
                    brand_content_toggle: checked,
                    // Branded content does not work with
                    // private levels.
                    privacy_level:
                      checked && !!extras.privacy_level
                        ? "PUBLIC_TO_EVERYONE"
                        : extras.privacy_level,
                  })
                }
              />
              <Body2 color="textSecondary" sx={{ marginLeft: 4 }}>
                {t("TiktokContentEditor.brandedContentDescription")}
              </Body2>
            </div>
          </ColumnStack>
        </SwitchField>
        {!extras.commercialContent && (
          <Body2 color="textSecondary">
            {t("TiktokContentEditor.commercialContentDescription")}
          </Body2>
        )}
      </div>
      <AgreementConfirmation extras={extras} />
    </ColumnStack>
  );
};

export default TiktokContentEditor;
