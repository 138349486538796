// @flow
import * as React from "react";
import type { Post } from "../../models/post.model";
import LinkPreview from "../lib/display/LinkPreview";
import AttachmentsGallery from "./AttachmentsGallery";

type Props = {
  post: Post,
};
const PostMediaPreview: React.ComponentType<Props> = ({ post }) => {
  if (!!post.attachments?.length) {
    return (
      <AttachmentsGallery
        videoOffset={post.thumbnail_video_offset}
        attachments={post.attachments}
      />
    );
  }
  if (post.link)
    return (
      <LinkPreview
        link={post.link}
        preview={
          post.thumbnail_url
            ? {
                image: post.thumbnail_url,
                title: post.thumbnail_title,
              }
            : undefined
        }
      />
    );

  return null;
};

export default PostMediaPreview;
