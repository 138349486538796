// @flow
import * as React from "react";
import type { I18nKey } from "../../../../types";
import { cast } from "../../../../types";
import type { CSSProps } from "../../../../reactTypes";
import type { Props as GridToolbarProps } from "./TableToolbar";
import {
  Checkbox,
  Paper,
  TableCell,
  TableRow as TableRowImpl,
} from "@mui/material";
import type { TFunction } from "../../../../hooks/useTranslate";
import useTranslate from "../../../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import type { Selection } from "../../../../hooks/useSelection";
import { useSelection } from "../../../../hooks/useSelection";
import type { TableToolbarActionConfig } from "./TableAction";
import type { Theme } from "../../../../stubs/mui/theming";
import { NO_XS_HORIZ_MARGIN } from "../../../layout/PagePanel";

export type CellComponent<
  DataItem,
  CellProps: Object = void
> = React.ComponentType<{
  row: DataItem,
  cellProps?: CellProps,
}>;
export type RowId = string | number;

export type Column<DataItem, CellProps: Object = void> = {
  headCellProps?: React.ElementProps<typeof TableCell>,
  bodyCellProps?: React.ElementProps<typeof TableCell>,
  align?: "left" | "right" | "center",
  label: I18nKey,
  dataKey?: $Keys<DataItem>,
  getData?: (DataItem) => React.Node,
  Component?: CellComponent<DataItem, CellProps>,
};

export type Row<DataItem> = DataItem;

export type DataTableProps<
  DataItem,
  K: RowId = RowId,
  CellProps: Object = void
> = {
  columns: Column<DataItem, CellProps>[],
  rows: Row<DataItem>[],
  loading?: boolean,
  selectable?: boolean,
  defaultSelection?: Iterable<K>,
  onChangeSelection?: ($ReadOnlySet<K>) => any,
  // getRowId defaults to getting the "id" attribute.
  getRowId?: (DataItem) => K,
  actions?: TableToolbarActionConfig<K>[],
  cellProps?: CellProps,
  slotProps?: {
    paper?: { ...CSSProps, ... },
    tableContainer?: { ...CSSProps, ... },
    toolbar?: Pick<
      GridToolbarProps<K>,
      "title" | "className" | "style" | "variant"
    >,
    table?: { ...CSSProps, ... },
    tableRow?: { ...CSSProps, alternate?: boolean, ... },
  },
};

export type TableSelection<K = RowId> = {
  selection: $ReadOnlySet<K>,
  selectedCount: number,
  clear: Selection<K>["clear"],
};

export type DataTableParams<DataItem, K = RowId> = {
  getFixedHeaderContent: () => React.Node,
  getRowContent: (number, Row<DataItem>) => React.Node,
  selection: TableSelection<K>,
  rows: Row<DataItem>[],
  getRowId: (DataItem) => K,
};

export const TableRow: typeof TableRowImpl = styled(TableRowImpl, {
  shouldForwardProp: (prop) => prop !== "alternate",
})(({ theme, alternate }) => ({
  // this is necessary, to avoid rows overlapping header content
  // when scrolled.
  backgroundColor: theme.palette.background.paper,
  ...(alternate && {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }),
}));

export const TableRootPaper: typeof Paper = styled(Paper)`
  width: 100%;
  overflow: auto;
`;

const makeGetRowContent = <DataItem: Object, CellProps>(
  columns: Column<DataItem, CellProps>[],
  cellProps?: CellProps,
  onSetSelected?: (DataItem, boolean) => any,
  isSelected: (DataItem) => boolean
): ((number, Row<DataItem>) => React.Node) => {
  return (rowIndex, row) => {
    return (
      <React.Fragment>
        {onSetSelected && (
          <CheckBoxTableCell padding="none">
            <Checkbox
              color="primary"
              checked={isSelected(row)}
              onChange={(e) => onSetSelected(row, e.target.checked)}
            />
          </CheckBoxTableCell>
        )}
        {columns.map(
          ({
            label,
            align = "left",
            dataKey,
            getData,
            Component,
            bodyCellProps,
          }) => (
            <TableCell key={label} align={align} {...bodyCellProps}>
              {dataKey ? (
                row[dataKey]
              ) : getData ? (
                getData(row)
              ) : Component ? (
                <Component row={row} cellProps={cellProps} />
              ) : (
                row
              )}
            </TableCell>
          )
        )}
      </React.Fragment>
    );
  };
};

const CheckBoxTableCell = styled(TableCell)`
  width: 42px;
`;

const makeGetFixedHeaderContent =
  <DataItem, CellProps>(
    t: TFunction,
    columns: Column<DataItem, CellProps>[],
    onSetAllSelected?: (boolean) => any,
    allSelected: ?boolean
  ): (() => React.Node) =>
  () =>
    (
      <TableRow>
        {onSetAllSelected && (
          <CheckBoxTableCell variant="head" padding="none">
            <Checkbox
              color="primary"
              checked={!!allSelected}
              indeterminate={typeof allSelected === "undefined"}
              onChange={(e) => onSetAllSelected(e.target.checked)}
              align="center"
            />
          </CheckBoxTableCell>
        )}
        {columns.map(({ label, align = "left", headCellProps }) => (
          <TableCell
            variant="head"
            key={label}
            align={align}
            {...headCellProps}
          >
            {t(label)}
          </TableCell>
        ))}
      </TableRow>
    );

const defaultGetRowId = <DataItem, K>(row: DataItem): K =>
  cast<{ id: K }>(row)["id"];

export const useDataTableParams = <
  DataItem,
  K: RowId = RowId,
  CellProps: Object = void
>(
  props: Omit<DataTableProps<DataItem, K, CellProps>, "slotProps">
): DataTableParams<DataItem, K> => {
  const t = useTranslate();
  const { extend, clear, selectedCount, setSelected, isSelected, selection } =
    useSelection<K>(props.defaultSelection, props.onChangeSelection);
  const {
    rows,
    columns,
    selectable,
    getRowId = defaultGetRowId,
    cellProps,
  } = props;
  const handleSetAllSelected = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        extend(rows.map(getRowId));
      } else {
        clear();
      }
    },
    [getRowId, extend, clear, rows]
  );

  const getFixedHeaderContent = makeGetFixedHeaderContent<DataItem, CellProps>(
    t,
    columns,
    selectable ? handleSetAllSelected : undefined,
    selectedCount === rows.length || (selectedCount === 0 ? false : undefined)
  );

  const getRowContent = React.useMemo(
    () =>
      makeGetRowContent(
        columns,
        cellProps,
        selectable
          ? (row, selected) => setSelected(getRowId(row), selected)
          : undefined,
        (row) => isSelected(getRowId(row))
      ),
    [cellProps, columns, setSelected, isSelected, getRowId, selectable]
  );

  return {
    getFixedHeaderContent,
    getRowContent,
    selection: { selection, selectedCount, clear },
    rows,
    getRowId,
  };
};

export const DEFAULT_PAPER_SLOT_PROPS = {
  className: NO_XS_HORIZ_MARGIN,
  sx: (theme: Theme): Object => ({
    flexGrow: 1,
    overflow: "hidden",
    // on mobile, our tables are full width, so no border
    // and a light divider at the top.
    borderRadius: { xs: 0, sm: theme.shape.borderRadius / 8 },
    borderTop: { xs: `solid 1px ${theme.palette.divider}`, sm: "none" },
  }),
};
