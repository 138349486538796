// @flow
import * as React from "react";
import ColoredChip from "../lib/display/ColoredChip";
import { CommentRounded } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import useTranslate from "../../hooks/useTranslate";

const FirstCommentChip: React.ComponentType<{}> = React.memo(() => (
  <Tooltip title={useTranslate()("global.firstComment")}>
    <ColoredChip paletteColor="firstComment">
      <CommentRounded fontSize="inherit" color="inherit" />
    </ColoredChip>
  </Tooltip>
));

export default FirstCommentChip;
