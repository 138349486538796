// @flow
import * as React from "react";
import PostOverviewWidget from "./PostOverviewWidget";
import PostFiltersContext from "../../../contexts/postFilters";
import head from "lodash/head";
import {
  useAccountPosts,
  useAnalyticsReport,
  usePostsInitialised,
} from "../../../store/selectors";
import { ColumnStack } from "../../lib/layout/stacks";
import useTranslate from "../../../hooks/useTranslate";
import { Header } from "../../lib/display/Text";
import type { BobcaatAccount } from "../../../models/account.model";
import { byId } from "../../../models/base.model";
import { getChannel } from "../../../models/channels";
import { Alert, AlertTitle } from "@mui/material";
import { PostBreakdownTable } from "../PostsTable/PostsTable";

type Props = {
  accounts: BobcaatAccount[],
};

const PostAnalyticsWidgetContainer: React.ComponentType<Props> = ({
  accounts,
}) => {
  const t = useTranslate();
  const { accountIds } = React.useContext(PostFiltersContext);
  const accountId = head(accountIds);
  const analyticsReport = useAnalyticsReport(accountId);
  const account = accounts.find(byId(accountId));
  const channel = getChannel(account?.channel);
  const posts = useAccountPosts(accountId);
  const postsInitalised = usePostsInitialised();

  if (!channel?.features?.analytics?.post || !analyticsReport) return null;
  const noData = analyticsReport.posts_overview.count.value === 0;

  return (
    <ColumnStack marginBottom={1}>
      <Header>{t("AnalyticsSection.AnalyticsTab.postsOverview")}</Header>
      {noData ? (
        <Alert severity="info">
          <AlertTitle>
            {t("AnalyticsSection.AnalyticsTab.noPost.title")}
          </AlertTitle>
          {t("AnalyticsSection.AnalyticsTab.noPost.subtitle")}
        </Alert>
      ) : (
        <>
          <PostOverviewWidget overview={analyticsReport.posts_overview} />
          <PostBreakdownTable
            breakdown={analyticsReport.posts_breakdown}
            posts={posts}
            loading={postsInitalised !== "all"}
          />
        </>
      )}
    </ColumnStack>
  );
};

export default PostAnalyticsWidgetContainer;
