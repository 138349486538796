// @flow
import * as React from "react";
import type { Moment } from "../../types";
import range from "lodash/range";
import moment from "moment";
import MiniWeekCalendar from "./MiniWeekCalendar";
import { getAnchorStartOfTargetMonth } from "../../util/calendar.util";

type Props = {
  anchor: Moment,
  getBadge: (day: Moment) => ?React.Node,
  onDayClick?: (Moment) => any,
};

const MAX_WEEKS_IN_MONTH = 5;

const MiniMonthCalendar: React.ComponentType<Props> = ({
  anchor,
  getBadge,
  onDayClick,
}) => {
  // the current month may not be the montf of the anchor, since we align
  // it to a start of week.
  const month = getAnchorStartOfTargetMonth(anchor).month();
  return (
    <div>
      {range(MAX_WEEKS_IN_MONTH).map((offset) => {
        const day = moment(anchor).add(offset, "week");
        if (day.month() > month) return null;
        return (
          <MiniWeekCalendar
            key={offset}
            anchor={day}
            getBadge={getBadge}
            onDayClick={onDayClick}
            month={month}
          />
        );
      })}
    </div>
  );
};

export default MiniMonthCalendar;
