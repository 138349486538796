// @flow
import * as React from "react";
import { ColumnStack } from "../../../lib/layout/stacks";
import type { Props as MainTargetWidgetProps } from "./MainTargetWidget";
import MainTargetWidget from "./MainTargetWidget";
import type { Props as SecondaryTargetWidgetProps } from "./SecondaryTargetsWidget";
import SecondaryTargetsWidget from "./SecondaryTargetsWidget";
import { Fade } from "@mui/material";

type Props = {
  mainTargetProps: MainTargetWidgetProps,
  secondaryTargetProps: SecondaryTargetWidgetProps,
};

const SelectPostTargetWidget: React.ComponentType<Props> = ({
  mainTargetProps,
  secondaryTargetProps,
}) => (
  <ColumnStack>
    <MainTargetWidget {...mainTargetProps} />
    <Fade in={!!mainTargetProps.selected} unmountOnExit>
      <SecondaryTargetsWidget {...secondaryTargetProps} />
    </Fade>
  </ColumnStack>
);

export default SelectPostTargetWidget;
