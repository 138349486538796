// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import type { Amount, CurrencyCode } from "../../types";
import { Caption, Subtitle } from "../lib/display/Text";
import { formatPrice } from "../../config/currencies.config";
import Translate from "../lib/display/Translate";

type Props = {
  price: Amount,
  currency: CurrencyCode,
  suspended?: boolean,
};

const Root = styled("div")`
  display: inline-block;
`;

const PaidPrice: React.ComponentType<Props> = ({
  price,
  currency,
  suspended,
}) => (
  <Root>
    <Subtitle
      style={suspended ? { textDecoration: "line-through" } : undefined}
      color={suspended ? "textSecondary" : undefined}
    >
      {formatPrice(currency, price)}
    </Subtitle>
    <Caption color="textSecondary">
      <Translate id="global.monthly" />
    </Caption>
  </Root>
);

export default PaidPrice;
