// @flow
import * as React from "react";
import { ResponsiveLine } from "@nivo/line";
import type {
  AnalyticsReport,
  EntityMetric,
  EntityMetricsDailyCollection,
} from "../../../models/analytics.model";
import keys from "lodash/keys";
import { PAGE_METRICS } from "../../../config/metrics.config";
import { toDateString } from "../../../lib/time.lib";
import moment from "moment";
import type { Timestamp } from "../../../types";
import { Moment } from "../../../types";
import type { TFunction } from "../../../hooks/useTranslate";
import useTranslate from "../../../hooks/useTranslate";
import { alpha, useTheme } from "@mui/material";
import AnalyticsPlaceholder from "./AnalyticsPlaceholder";
import { usePartialTheme } from "@nivo/core";
import type { Theme } from "../../../stubs/mui/theming";
import { grey } from "@mui/material/colors";
import numeral from "numeral";
import { FORMATS } from "../../lib/display/Numeral";

type Props = {
  report: AnalyticsReport,
  metric: EntityMetric,
};

type GraphPoint = {
  x: string | Date | number,
  y: number,
};

type GraphSeries = {
  id: string,
  metric: EntityMetric,
  variant: "previous" | "current",
  color?: string,
  data: GraphPoint[],
};

type GraphData = GraphSeries[];

const toSeries = (
  t: TFunction,
  metric: EntityMetric,
  variant: GraphSeries["variant"],
  points: EntityMetricsDailyCollection[],
  origin: Timestamp
): GraphSeries => ({
  id: t(`global.${variant}`),
  metric,
  variant,
  data: points.map((point) => ({
    x: point.day - origin,
    y: point[metric] ?? null,
  })),
});

const makeData = (
  t: TFunction,
  previous: EntityMetricsDailyCollection[],
  current: EntityMetricsDailyCollection[],
  previousOrigin: Timestamp,
  currentOrgin: Timestamp
): GraphData => {
  const metrics = keys(PAGE_METRICS);
  return [
    ...metrics.map((metric) =>
      toSeries(t, metric, "previous", previous, previousOrigin)
    ),
    ...metrics.map((metric) =>
      toSeries(t, metric, "current", current, currentOrgin)
    ),
  ];
};

const useNivoTheme = (theme: Theme) => {
  return usePartialTheme(
    theme.palette.mode === "light"
      ? {}
      : {
          text: {
            fill: theme.palette.text.primary,
          },
          grid: {
            line: {
              stroke: theme.palette.divider,
            },
          },
          tooltip: {
            container: {
              background: theme.palette.background.paper,
            },
          },
        }
  );
};

const formatTimestamp = (origin: Moment) => (d: Timestamp) =>
  toDateString(moment.unix(d).add(origin));

const formatYAxis = (v: number) => numeral(v).format(FORMATS.compact);

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const EntityMetricsGraph: React.ComponentType<Props> = ({ report, metric }) => {
  const t = useTranslate();
  const theme = useTheme();
  const nivoTheme = useNivoTheme(theme);
  const previousColor = alpha(grey[400], 0.5);
  const currentColor = theme.palette.info.main;

  const [colors, setColors] = React.useState<[string, string]>([
    previousColor,
    currentColor,
  ]);
  const allMetricsData = React.useMemo(
    () =>
      makeData(
        t,
        report.previous_entity_dailies,
        report.current_entity_dailies,
        report.previous_interval.start.unix(),
        report.current_interval.start.unix()
      ),
    [t, report]
  );

  const data = React.useMemo(
    () => allMetricsData.filter((series) => series.metric === metric),
    [allMetricsData, metric]
  );

  if (data.length < 2 || data[1].data.length === 0) {
    return <AnalyticsPlaceholder text={"global.noAnalyticData"} />;
  }

  return (
    <ResponsiveLine
      data={data}
      enableArea
      theme={nivoTheme}
      areaOpacity={0.1}
      colors={colors}
      colorBy="index"
      // enough margin to show the legends.
      margin={{ top: 24, right: 120, bottom: 64, left: 48 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        reverse: false,
      }}
      yFormat=" >-.0f"
      xFormat={formatTimestamp(report.current_interval.start)}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        legendOffset: 36,
        legendPosition: "middle",
        format: formatTimestamp(report.current_interval.start),
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: -40,
        legendPosition: "middle",
        format: formatYAxis,
      }}
      pointSize={5}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          onClick: (d) => {
            if (d.id === data[0].id) {
              setColors((prevColors) => [
                prevColors[0] === "transparent" ? previousColor : "transparent",
                prevColors[1],
              ]);
            } else {
              setColors((prevColors) => [
                prevColors[0],
                prevColors[1] === "transparent" ? currentColor : "transparent",
              ]);
            }
          },
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default EntityMetricsGraph;
