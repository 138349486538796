// @flow
import type { ModelID, Timestamp, URLStr } from "../types";
import { Moment } from "../types";
import { getAttr } from "../lib/lodashex.lib";
import { byNativeID } from "./base.model";
import moment from "moment/moment";

export type Interlocutor = {
  id: ModelID,
  native_id: string,
  name: ?string,
  avatar: ?URLStr,
};

export type Conversation = {
  id: ModelID,
  interlocutors: Interlocutor[],
  account_id: ModelID,
  created_at: Moment,
  portfolio_id: ModelID,
};

export const getConversationTitle = (conversation: ?Conversation): string =>
  conversation?.interlocutors.map(getAttr<Interlocutor>("name")).join(", ") ??
  "";

export const getInterlocutor = (
  conversation: ?Conversation,
  sender_id: Interlocutor["native_id"]
): ?Interlocutor => conversation?.interlocutors.find(byNativeID(sender_id));

export type APIConversation = {
  ...Omit<APIConversation, "updatecreated_atd_at">,
  created_at: Timestamp,
};

export const fromAPIResult = (conversation: APIConversation): Conversation => ({
  ...conversation,
  created_at: moment.unix(conversation.created_at),
});
