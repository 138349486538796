// @flow
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import { addScript } from "./lib/html.lib";
import * as GooglePicker from "./components/lib/inputs/pickers/GooglePicker";
import { StoreProvider } from "./store/store";
import "./localization";
import * as env from "./config/env.config";
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

// Avoid warnings.
window.gapi = {};
window.google = {};
const GAID = env.GOOGLE_ANALYTICS_ID;

if (env.isProd()) {
  addScript({
    async: true,
    src: `https://www.googletagmanager.com/gtag/js?id=${GAID}`,
  });
  addScript({
    textContent: `window.dataLayer = window.dataLayer || []; function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GAID}');`,
  });
}

// Init logs sink.
if (env.ENABLE_SENTRY) {
  // Configure sentryOnLoad before adding the Loader Script
  window.sentryOnLoad = function () {
    Sentry.init({
      environment: env.STAGE,
      debug: !env.isProd(),
    });
  };
  addScript({
    crossOrigin: "anonymous",
    src: "https://js.sentry-cdn.com/1b57e00dd202410ebc7f65b6732521da.min.js",
  });
}

// Google Identity Services
addScript({
  async: true,
  defer: true,
  src: "https://accounts.google.com/gsi/client",
  onload: () => {
    window.google.accounts.id.initialize({
      client_id: env.GOOGLE_CLIENT_ID,
      context: "use",
    });
    GooglePicker.initTokenClient();
  },
});

// Other Google services.
addScript({
  src: "https://apis.google.com/js/api.js",
  async: true,
  defer: true,
  onload: () => {
    window.gapi.load("picker");
    window.gapi.load("client", GooglePicker.initClient);
  },
});

// Safari does not support scroll into view.
elementScrollIntoViewPolyfill();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StoreProvider>
      <App />
    </StoreProvider>
  </React.StrictMode>
);
