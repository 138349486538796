// @flow
import * as React from "react";
import {
  NO_XS_HORIZ_MARGIN,
  PagePanel,
} from "../../components/layout/PagePanel";
import { ReviewApprovalRequestContext } from "../../contexts/reviewApprovalRequest";
import { byId } from "../../models/base.model";
import { ColumnStack } from "../../components/lib/layout/stacks";
import PostApprovalWidget from "../../components/widgets/PostApprovalWidget";
import PleaseWaitProgress from "../../components/feedback/PleaseWaitProgress";
import groupBy from "lodash/groupBy";
import toPairs from "lodash/toPairs";
import { getTimeDayUnix } from "../../models/post.model";
import { Header } from "../../components/lib/display/Text";
import LocaleDate from "../../components/lib/display/LocaleDate";
import moment from "moment";
import { styled } from "@mui/material/styles";

const DayHeader = styled(Header)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
}));

const DetailsPanel: React.ComponentType<{}> = () => {
  const { posts, postApprovals, onUpdatePostApproval, accounts } =
    React.useContext(ReviewApprovalRequestContext);

  const postsByDay = groupBy(posts, getTimeDayUnix);

  return (
    <PagePanel variant="primary" color="default">
      {toPairs(postsByDay).map(([dayTs, postsOfDay]) => (
        <React.Fragment key={dayTs}>
          <DayHeader id={dayTs}>
            <LocaleDate fmt="LL" value={moment.unix(dayTs)} />
          </DayHeader>
          <ColumnStack
            spacing={3}
            className={NO_XS_HORIZ_MARGIN}
            marginBottom={1}
          >
            {postsOfDay.map((post) => {
              const postApproval = postApprovals.find(
                (pa) => pa.post_id === post.id
              );
              if (!postApproval) return <PleaseWaitProgress />;
              const account = accounts.find(byId(post.account_id));
              if (!account) return <PleaseWaitProgress />;
              return (
                <PostApprovalWidget
                  key={postApproval.post_id}
                  post={post}
                  account={account}
                  postApproval={postApproval}
                  onUpdatePostApproval={onUpdatePostApproval}
                />
              );
            })}
          </ColumnStack>
        </React.Fragment>
      ))}
    </PagePanel>
  );
};

export default DetailsPanel;
