// @flow
import * as React from "react";
import SelectPostTargetWidget from "./SelectPostTargetWidget";
import { useScopedPortfolio } from "../../../../scopes/scopes";
import PostBuilderContext from "../../../../contexts/postBuilder";
import { usePortfolioAccounts } from "../../../../store/selectors";
import type { BobcaatAccount } from "../../../../models/account.model";
import { getAccountChannel } from "../../../../models/account.model";
import type { ContentTypes } from "../../../../models/contentTypes.model";
import type { SubchannelType } from "../../../../models/channels/channel.model";
import type { ModelID } from "../../../../types";
import type { TargetSpecs } from "./types";
import { CoreMainTargetWidget } from "./MainTargetWidget";

const makeTarget = (
  account: BobcaatAccount,
  contentType: ContentTypes,
  subchannelType?: SubchannelType
): TargetSpecs => ({
  key: `${account.id}-${contentType}-${subchannelType?.slug ?? ""}`,
  account,
  channel: getAccountChannel(account),
  subchannelType,
  contentType,
  label: subchannelType
    ? `refer.channels.${account.channel}.subchannelTypes.${subchannelType.slug}.name`
    : `global.${contentType}`,
});

const generatePrimaryPostTargets = function* (accounts: BobcaatAccount[]) {
  for (const account of accounts) {
    const channel = getAccountChannel(account);
    if (channel.surfaces.length === 0 && !channel.requires?.subchannel) {
      yield makeTarget(account, "post");
    } else {
      for (const surface of channel.surfaces) {
        yield makeTarget(account, surface.contentType);
      }
    }
    for (const subType of channel.subchannelTypes) {
      yield makeTarget(account, "post", subType);
    }
  }
};

const generateSecondaryPostTargets = function* (
  accounts: BobcaatAccount[],
  primaryAccountId: ?ModelID,
  disableAutopublish: ?boolean
) {
  if (disableAutopublish) return;
  for (const account of accounts) {
    if (account.id !== primaryAccountId) {
      const channel = getAccountChannel(account);
      if (!channel.requires?.subchannel) {
        yield makeTarget(account, "post");
      }
    }
  }
};

const usePrimaryPostTargets = (accounts: BobcaatAccount[]): TargetSpecs[] => {
  return React.useMemo(
    () => [...generatePrimaryPostTargets(accounts)],
    [accounts]
  );
};

const useSecondaryPostTargets = (
  accounts: BobcaatAccount[],
  primaryAccountId: ?ModelID,
  disableAutopublish: ?boolean
): TargetSpecs[] => {
  return React.useMemo(
    () => [
      ...generateSecondaryPostTargets(
        accounts,
        primaryAccountId,
        disableAutopublish
      ),
    ],
    [accounts, primaryAccountId, disableAutopublish]
  );
};

const findTarget = (
  targets: TargetSpecs[],
  accountId: ModelID,
  contentType: ContentTypes
): ?TargetSpecs =>
  // Works for now but a bit flaky: there could be several subchannelTypes
  // with the same content type. It is just not the case for now.
  targets.find(
    (t) => t.account.id === accountId && t.contentType === contentType
  );

const SelectPostTargetWidgetContainer: React.ComponentType<{
  variant?: ?"quick",
}> = ({ variant }) => {
  const portfolio = useScopedPortfolio();
  const accounts = usePortfolioAccounts(portfolio?.id);
  const { onSetDestination, subchannel, onSetSubchannel, post, onUpdatePost } =
    React.useContext(PostBuilderContext);
  const primaryTargets = usePrimaryPostTargets(accounts);
  const secondaryTargets = useSecondaryPostTargets(
    accounts,
    post.account_id,
    post.disable_autopublish
  );

  const selectedTarget = findTarget(
    primaryTargets,
    post.account_id,
    post.content_type
  );

  const mainTargetProps = {
    onSelect: (target: TargetSpecs) =>
      onSetDestination(target.account, target.contentType),
    targets: primaryTargets,
    selected: selectedTarget,
    subchannel: subchannel,
    onSelectSubchannel: onSetSubchannel,
  };
  if (variant === "quick") {
    return <CoreMainTargetWidget {...mainTargetProps} />;
  }

  return (
    <SelectPostTargetWidget
      mainTargetProps={mainTargetProps}
      secondaryTargetProps={{
        targets: secondaryTargets,
        selected: post.secondary_account_ids,
        onSetSecondaryAccountIds: (secondary_account_ids) =>
          onUpdatePost({ secondary_account_ids }),
      }}
    />
  );
};

export default SelectPostTargetWidgetContainer;
