// @flow
import type {
  ModelID,
  Timestamp,
  NumDict,
  StringDict,
  GroupBy,
  Moment,
} from "../types";
import moment from "moment";
import "moment-timezone";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { cast } from "../types";

export type Timezone = {
  id: ModelID,
  offset: number,
  name: string,
};

export const TIMEZONES: Timezone[] = [
  { id: 1, name: "Europe/London", offset: 0 },
  { id: 2, name: "Africa/Monrovia", offset: 0 },
  { id: 3, name: "Europe/Berlin", offset: 1 },
  { id: 4, name: "Europe/Prague", offset: 1 },
  { id: 5, name: "Europe/Paris", offset: 1 },
  { id: 6, name: "Europe/Belgrade", offset: 1 },
  { id: 7, name: "Africa/Lagos", offset: 1 },
  { id: 8, name: "Europe/Istanbul", offset: 2 },
  { id: 9, name: "Africa/Cairo", offset: 2 },
  { id: 10, name: "Africa/Harare", offset: 2 },
  { id: 11, name: "Europe/Riga", offset: 2 },
  { id: 12, name: "Asia/Jerusalem", offset: 2 },
  { id: 13, name: "Europe/Minsk", offset: 2 },
  { id: 14, name: "Africa/Windhoek", offset: 2 },
  { id: 15, name: "Asia/Baghdad", offset: 3 },
  { id: 16, name: "Asia/Kuwait", offset: 3 },
  { id: 17, name: "Europe/Moscow", offset: 3 },
  { id: 18, name: "Africa/Nairobi", offset: 3 },
  { id: 19, name: "Asia/Tehran", offset: 3.5 },
  { id: 20, name: "Asia/Muscat", offset: 4 },
  { id: 21, name: "Asia/Baku", offset: 4 },
  { id: 22, name: "Asia/Tbilisi", offset: 4 },
  { id: 23, name: "Asia/Yerevan", offset: 4 },
  { id: 24, name: "Asia/Kabul", offset: 4.5 },
  { id: 25, name: "Asia/Yekaterinburg", offset: 5 },
  { id: 26, name: "Asia/Tashkent", offset: 5 },
  { id: 27, name: "Asia/Calcutta", offset: 5.5 },
  { id: 28, name: "Asia/Kathmandu", offset: 5.75 },
  { id: 29, name: "Asia/Novosibirsk", offset: 6 },
  { id: 30, name: "Asia/Almaty", offset: 6 },
  { id: 31, name: "Asia/Colombo", offset: 6 },
  { id: 32, name: "Asia/Rangoon", offset: 6.5 },
  { id: 33, name: "Asia/Bangkok", offset: 7 },
  { id: 34, name: "Asia/Krasnoyarsk", offset: 7 },
  { id: 35, name: "Asia/Shanghai", offset: 8 },
  { id: 36, name: "Asia/Irkutsk", offset: 8 },
  { id: 37, name: "Asia/Singapore", offset: 8 },
  { id: 38, name: "Australia/Perth", offset: 8 },
  { id: 39, name: "Asia/Taipei", offset: 8 },
  { id: 40, name: "Asia/Tokyo", offset: 9 },
  { id: 41, name: "Asia/Seoul", offset: 9 },
  { id: 42, name: "Asia/Yakutsk", offset: 9 },
  { id: 43, name: "Australia/Adelaide", offset: 9.5 },
  { id: 44, name: "Australia/Darwin", offset: 9.5 },
  { id: 45, name: "Australia/Brisbane", offset: 10 },
  { id: 46, name: "Australia/Sydney", offset: 10 },
  { id: 47, name: "Pacific/Guam", offset: 10 },
  { id: 48, name: "Australia/Hobart", offset: 10 },
  { id: 49, name: "Asia/Vladivostok", offset: 10 },
  { id: 50, name: "Pacific/Guadalcanal", offset: 11 },
  { id: 51, name: "Pacific/Auckland", offset: 12 },
  { id: 52, name: "Pacific/Fiji", offset: 12 },
  { id: 53, name: "Pacific/Tongatapu", offset: 13 },
  { id: 54, name: "Atlantic/Azores", offset: -1 },
  { id: 55, name: "Atlantic/Cape_Verde", offset: -1 },
  { id: 56, name: "Atlantic/South_Georgia", offset: -2 },
  { id: 57, name: "America/Sao_Paulo", offset: -3 },
  { id: 58, name: "America/Argentina/Buenos_Aires", offset: -3 },
  { id: 59, name: "America/Godthab", offset: -3 },
  { id: 60, name: "America/St_Johns", offset: -3.5 },
  { id: 61, name: "America/Halifax", offset: -4 },
  { id: 62, name: "America/La_Paz", offset: -4 },
  { id: 63, name: "America/Cuiaba", offset: -4 },
  { id: 64, name: "America/Santiago", offset: -4 },
  { id: 65, name: "America/Bogota", offset: -5 },
  { id: 66, name: "America/New_York", offset: -5 },
  { id: 67, name: "America/Indiana/Indianapolis", offset: -5 },
  { id: 68, name: "America/Costa_Rica", offset: -6 },
  { id: 69, name: "America/Chicago", offset: -6 },
  { id: 70, name: "America/Monterrey", offset: -6 },
  { id: 71, name: "America/Edmonton", offset: -6 },
  { id: 72, name: "America/Phoenix", offset: -7 },
  { id: 73, name: "America/Chihuahua", offset: -7 },
  { id: 74, name: "America/Denver", offset: -7 },
  { id: 75, name: "America/Tijuana", offset: -8 },
  { id: 76, name: "America/Anchorage", offset: -9 },
  { id: 77, name: "Pacific/Honolulu", offset: -10 },
  { id: 78, name: "Pacific/Api", offset: -11 },
];

export const getContinent = (tz: Timezone): string =>
  tz.name.substring(0, tz.name.indexOf("/"));
export const getCity = (tz: Timezone): string =>
  tz.name.substring(tz.name.lastIndexOf("/") + 1);

export const TIMEZONES_BY_NAME: StringDict<Timezone> = keyBy(TIMEZONES, "name");
export const TIMEZONES_BY_CONTINENT: GroupBy<string, Timezone> = groupBy(
  TIMEZONES,
  getContinent
);
export const TIMEZONES_BY_ID: NumDict<Timezone> = keyBy(TIMEZONES, "id");

const __localeTimeZone = moment.tz.zone(moment.tz.guess());
let __reducedTimeZone: Timezone | void | null = null;

/**
 *
 * @return {string}
 */
const localTimezoneName = () => {
  /*
    Finding the right timezone is tricky. There is no official list of TIMEZONES browsers support.
    The only thing we can be sure of is there in the form <Region>/<Town>.
    In our database, we don't store them all. We just keep a subset of all major ones.

    That means we need a strategy to reduce a custom one to one of ours. The current strategy is:
    1. Look for exact match by name (main TIMEZONES)
    2. Find one that contains the custom town in its description.
    3. Pick the first one with same offset in same region.
    4. Pick London.
   */
  if (!__reducedTimeZone) {
    __reducedTimeZone = TIMEZONES_BY_NAME[__localeTimeZone.name];
    if (!__reducedTimeZone) {
      let [region, town] = __localeTimeZone.name.split("/");
      __reducedTimeZone = TIMEZONES.find((tz: Timezone) =>
        tz.name.includes(town)
      );
      if (!__reducedTimeZone) {
        const offset = __localeTimeZone.offsets[0] / 60;
        const timezonesForRegion = TIMEZONES_BY_CONTINENT[region];
        if (timezonesForRegion) {
          __reducedTimeZone = timezonesForRegion.find(
            (tz) => tz.offset === offset
          );
        }
      }
      if (!__reducedTimeZone) {
        __reducedTimeZone = TIMEZONES[0];
      }
    }
  }
  return __reducedTimeZone.name;
};

export const localTimezone = (): Timezone =>
  cast<Timezone>(TIMEZONES.find((tz) => tz.name === localTimezoneName()));

export const getTimezone = (id: ?ModelID): ?Timezone =>
  id ? TIMEZONES_BY_ID[id] : null;

/**
 *
 * @param time {Moment}
 * @param timezoneId {number}
 * @return {number}
 */
export const applyTimezone = (
  time: ?Moment,
  timezoneId: ?ModelID
): ?Timestamp =>
  !time
    ? time
    : timezoneId
    ? // Use "true" not to convert but to force a timezone on a specific time.
      time.tz(TIMEZONES_BY_ID[timezoneId].name, true).unix()
    : time.unix();

export default TIMEZONES;
