// @flow
import * as React from "react";
import {
  PagePanel,
  PrimaryPagePanel,
} from "../../../components/layout/PagePanel";
import routes, { getRoutePath } from "../../../config/routes.config";
import MiniPostsCalendarWidget from "../../../components/widgets/MiniPostsCalendarWidget";
import type { Post } from "../../../models/post.model";
import type { InitStatus } from "../../../store/reducers/lib/init.state";
import { useNavigate } from "react-router-dom";
import type { Portfolio } from "../../../models/portfolio.model";
import moment from "moment";
import { toParamDateString } from "../../../lib/time.lib";
import UpcomingPostsWidgetContainer from "../../../components/widgets/UpcomingPostsWidget";
import useRedirectToEditPost from "../../../hooks/useRedirectToEditPost";
import type { User } from "../../../models/user.model";
import MiniApprovalRequestsWidget from "../../../components/widgets/MiniApprovalRequestsWidget";
import type { ApprovalRequest } from "../../../models/approvalRequest.model";
import InboxWidget from "../../../components/widgets/InboxWidget";
import QuickPostWidget from "../../../components/widgets/QuickPostWidget";
import { styled } from "@mui/material/styles";
import useIsMobile from "../../../hooks/useIsMobile";

type Props = {
  portfolio: Portfolio,
  approvalRequests: ApprovalRequest[],
  posts: Post[],
  user: User,
  postsInitialised: InitStatus,
};

const InsightsPanelContent: React.ComponentType<Props> = ({
  posts,
  portfolio,
  user,
  approvalRequests,
}) => {
  const navigate = useNavigate();
  const redirectToEditPost = useRedirectToEditPost(portfolio.id);
  return (
    <>
      <MiniPostsCalendarWidget
        posts={posts}
        onDayClick={(day) =>
          navigate(
            getRoutePath(
              routes.app.nested.portfolios.nested.creator,
              { portfolioId: portfolio.id },
              {
                from: toParamDateString(day),
                to: toParamDateString(moment(day).add(1, "day")),
              }
            )
          )
        }
      />
      <UpcomingPostsWidgetContainer
        posts={posts}
        onSelect={(p) => redirectToEditPost(p.id)}
      />
      {user.limits.can_use_post_approval && (
        <MiniApprovalRequestsWidget
          pageSize={2}
          approvalRequests={approvalRequests}
          onSelect={(request) =>
            navigate(
              getRoutePath(
                routes.app.nested.portfolios.nested.approvalRequest.nested.edit,
                { portfolioId: portfolio.id, approvalRequestId: request.id }
              )
            )
          }
        />
      )}
      {user.limits.can_use_comment_manager && (
        <InboxWidget
          portfolioId={portfolio.id}
          onSelect={() =>
            navigate(
              getRoutePath(
                routes.app.nested.portfolios.nested.engagement,
                {
                  portfolioId: portfolio.id,
                },
                { unreadItemsOnly: "1" }
              )
            )
          }
        />
      )}
    </>
  );
};

const InsightsPanel: React.ComponentType<Props> = (props) => (
  <PagePanel size="dense" variant="secondary">
    <InsightsPanelContent {...props} />
  </PagePanel>
);

const Root = styled("div")({
  display: "flex",
  flexGrow: 1,
});

const DashboardPage: React.ComponentType<Props> = (props) => {
  const isMobile = useIsMobile();
  return (
    // This root guarantees a horizontal layout even on tablet,
    // which the parent may not guarantee.
    <Root>
      <PrimaryPagePanel
        align="flex-start"
        size={isMobile ? "fill" : "large"}
        route={routes.app.nested.portfolios.nested.dashboard}
        subtitleData={{ portfolio: props.portfolio.name }}
      >
        {isMobile ? (
          <InsightsPanelContent {...props} />
        ) : (
          <QuickPostWidget portfolioId={props.portfolio.id} />
        )}
      </PrimaryPagePanel>
      {!isMobile && <InsightsPanel {...props} />}
    </Root>
  );
};

export default DashboardPage;
