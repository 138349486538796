// @flow
import * as React from "react";
import Sticker from "./Sticker";
import { useTheme } from "@mui/material";
import type { StickerProps } from "./Sticker";

type Props = {
  ...Omit<StickerProps, "color">,
  Icon: React.ComponentType<any>,
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "warning"
    | "success",
};

const IconInSticker: React.ComponentType<Props> = React.memo(
  ({ Icon, color = "secondary", size, ...props }) => {
    const theme = useTheme();
    const iconSize = theme.const.iconSize[size ?? "medium"] - 10;
    return (
      <Sticker
        color={theme.palette[color]?.light ?? color}
        size={size}
        {...props}
      >
        <Icon
          htmlColor={theme.palette[color]?.dark ?? color}
          style={{ width: iconSize, height: iconSize }}
        />
      </Sticker>
    );
  }
);

export default IconInSticker;
